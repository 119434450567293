import { useCallback, useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'

import { localStorageKeys } from '@/constants/keys'
import { Locale } from '@/constants/locales'
import { getUserLocale, loadMessages } from '@/lib/localization'

import { LocaleContext } from './LocaleContext'

type Props = {
  children: React.ReactNode
}

export const LocaleProvider = ({ children }: Props) => {
  const [locale, setLocale] = useState<Locale>(
    (window.localStorage.getItem(localStorageKeys.locale) as Locale) ||
      getUserLocale(),
  )

  useEffect(() => {
    document.documentElement.lang = locale
  }, [locale])

  const changeLocale = useCallback((locale: string) => {
    window.localStorage.setItem(localStorageKeys.locale, locale)

    setLocale(locale as Locale)
  }, [])

  return (
    <IntlProvider
      messages={loadMessages(locale)}
      locale={locale}
      defaultLocale="en"
    >
      <LocaleContext.Provider value={{ locale, changeLocale }}>
        {children}
      </LocaleContext.Provider>
    </IntlProvider>
  )
}
