import { useMemo } from 'react'
import { useMutation } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { RECIPIENTS_ROUTE, TASKS_ROUTE } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { iconByRecipientType } from '@/features/Transactions'
import { useBusinessRole } from '@/hooks/useBusinessRole'
import { useErrorToast } from '@/hooks/useErrorToast'
import { queryClient } from '@/lib/queryClient'
import { getFullName } from '@/lib/typography'
import { removeEmptyFormFields } from '@/lib/utils'
import { CountryWithFlag, GoBackButton, Widget } from '@/shared/components'
import {
  Button,
  Details,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'
import { ApiError } from '@/types/global'
import { Role } from '@/types/roles'

import { createRecipient } from '../../api'
import { CreatedRecipient, EntityType } from '../../types'
import { RecipientPaymentDetails } from '../RecipientPaymentDetails'

type Props = {
  recipient?: CreatedRecipient
  onBack: () => void
}

export const RecipientReviewStep = ({ recipient, onBack }: Props) => {
  const navigate = useNavigate()
  const intl = useIntl()
  const notifyError = useErrorToast()

  const Icon =
    iconByRecipientType[recipient?.entityType ?? EntityType.INDIVIDUAL]

  const { role, isPaymentOps } = useBusinessRole()

  const { mutateAsync, isPending } = useMutation({
    mutationFn: createRecipient,
  })

  const recipientName = useMemo(() => {
    switch (recipient?.entityType) {
      case EntityType.INDIVIDUAL:
        return getFullName(recipient)

      case EntityType.BUSINESS:
        return recipient.legalName

      default:
        return ''
    }
  }, [recipient])

  const handleSubmit = async () => {
    if (!recipient) {
      return
    }

    try {
      removeEmptyFormFields(recipient)

      const { data } = await mutateAsync(recipient)

      switch (role) {
        case Role.PAYMENT_OPS:
          await queryClient.invalidateQueries({
            queryKey: [queryKeys.getUserTasks],
          })
          toast.success(
            intl.formatMessage({
              id: 'recipient.task.submitted',
              defaultMessage:
                'New recipient request is submitted and pending approval',
            }),
          )

          navigate(TASKS_ROUTE)

          break

        default:
          await queryClient.invalidateQueries({
            queryKey: [queryKeys.getRecipients],
          })

          toast.success(
            intl.formatMessage({
              id: 'recipient.new.added',
              defaultMessage: 'Recipient added!',
            }),
            {
              description: intl.formatMessage(
                {
                  id: 'recipient.new.added.description',
                  defaultMessage:
                    '{name} has been added to your recipients list',
                },
                {
                  name: data.nickname,
                },
              ),
            },
          )
          navigate(`${RECIPIENTS_ROUTE}?id=${data.id}`)
      }
    } catch (error) {
      if (error instanceof Error) {
        const apiError = error as ApiError

        if (apiError.response?.data?.status === 409) {
          const id = apiError.response?.data.details?.beneficiaryId

          if (id) {
            navigate(`${RECIPIENTS_ROUTE}?id=${id}`)
            toast.info(
              intl.formatMessage({
                defaultMessage: 'Beneficiary already exists',
                id: 'error.backend.beneficiaryAlreadyExists',
              }),
            )

            return
          }
        }

        notifyError(error)
      }
    }
  }

  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            id="recipient.add.review.title"
            defaultMessage="Review the new recipient details"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          {isPaymentOps ? (
            <FormattedMessage
              id="recipient.add.review.subtitle.paymentOps"
              defaultMessage="Make sure all the details are correct and send the request for an Admin to review"
            />
          ) : (
            <FormattedMessage
              id="recipient.add.review.subtitle"
              defaultMessage="Make sure all the details are correct before adding your new recipient"
            />
          )}
        </Typography>

        <div className="p-6" />

        <div className="flex flex-col gap-8">
          <Widget
            title={
              <FormattedMessage
                id="recipient.recipientDetails"
                defaultMessage="Recipient details"
              />
            }
          >
            <Details>
              <Details.Label>
                <FormattedMessage
                  defaultMessage="Bank country"
                  id="label.bankCountry"
                />
              </Details.Label>

              {recipient ? (
                <CountryWithFlag country={recipient.country} />
              ) : null}
            </Details>
            {recipient ? (
              <Details>
                <Details.Label>
                  <FormattedMessage
                    defaultMessage="Recipient type"
                    id="label.recipientType"
                  />
                </Details.Label>

                <div className="flex items-center gap-1">
                  <div className="relative flex size-4 shrink-0 items-center justify-center rounded bg-neutral-gray-1000 p-0.5">
                    <Icon className="size-3 text-white" />
                  </div>

                  <Typography>
                    <FormattedMessage
                      defaultMessage="{entity, select, BUSINESS {Business} INDIVIDUAL {Individual} other {}}"
                      id="recipients.entity"
                      values={{ entity: recipient.entityType }}
                    />
                  </Typography>
                </div>
              </Details>
            ) : null}

            {recipient ? (
              <Details>
                <Details.Label>
                  <FormattedMessage
                    defaultMessage="Recipient nickname"
                    id="label.recipientNickname"
                  />
                </Details.Label>

                <Details.Value>{recipient.nickname}</Details.Value>
              </Details>
            ) : null}

            {recipient ? (
              <Details>
                <Details.Label>
                  {(() => {
                    switch (recipient.entityType) {
                      case EntityType.INDIVIDUAL:
                        return (
                          <FormattedMessage
                            defaultMessage="Name"
                            id="label.name"
                          />
                        )

                      case EntityType.BUSINESS:
                        return (
                          <FormattedMessage
                            defaultMessage="Legal Name"
                            id="label.legalName"
                          />
                        )

                      default:
                        return ''
                    }
                  })()}
                </Details.Label>

                <Details.Value>{recipientName}</Details.Value>
              </Details>
            ) : null}
          </Widget>

          {recipient?.email || recipient?.localPhoneNumber ? (
            <Widget
              title={
                <FormattedMessage
                  id="recipient.contactDetails"
                  defaultMessage="Contact details"
                />
              }
            >
              {recipient?.email ? (
                <Details>
                  <Details.Label>
                    <FormattedMessage
                      defaultMessage="Contact email"
                      id="label.contactEmail"
                    />
                  </Details.Label>

                  <Details.Value>{recipient.email}</Details.Value>
                </Details>
              ) : null}

              {recipient?.localPhoneNumber ? (
                <Details>
                  <Details.Label>
                    <FormattedMessage
                      defaultMessage="Contact phone number"
                      id="label.contactPhoneNumber"
                    />
                  </Details.Label>

                  <Details.Value>
                    {formatPhoneNumberIntl(
                      recipient.internationalPhonePrefix +
                        recipient.localPhoneNumber,
                    )}
                  </Details.Value>
                </Details>
              ) : null}
            </Widget>
          ) : null}

          <Widget
            title={
              <FormattedMessage
                id="label.paymentDetails"
                defaultMessage="Payment details"
              />
            }
          >
            {recipient ? (
              <RecipientPaymentDetails recipient={recipient} />
            ) : null}
          </Widget>
        </div>

        <StickyContainer>
          <Button
            loading={isPending}
            disabled={isPending}
            width="full"
            onClick={handleSubmit}
          >
            {isPaymentOps ? (
              <FormattedMessage
                defaultMessage="Request approval to add new recipient"
                id="action.requestApprovalToAddRecipient"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Add {name} as recipient"
                id="action.addAsRecipient"
                values={{ name: recipient?.nickname }}
              />
            )}
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
