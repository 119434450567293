import { useEffect, useState } from 'react'

import { Sheet, SheetContent } from '@/shared/ui'

import { CreditCycleScreen } from './CreditCycleScreen'
import { StatementsBaseScreen } from './StatementsBaseScreen'

export type ScreenType = 'credit-cycle' | 'statements'

type Props = {
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  type: string | null
}

export const StatementsSidebar = ({ isOpen, onOpenChange, type }: Props) => {
  const [screen, setScreen] = useState<ScreenType>('statements')

  useEffect(() => {
    if (!isOpen) {
      setScreen('statements')
    }
  }, [isOpen])

  return (
    <Sheet open={isOpen} onOpenChange={onOpenChange}>
      <SheetContent>
        {(() => {
          switch (screen) {
            case 'statements':
              return <StatementsBaseScreen type={type} setScreen={setScreen} />

            case 'credit-cycle':
              return <CreditCycleScreen setScreen={setScreen} />

            default:
              return null
          }
        })()}
      </SheetContent>
    </Sheet>
  )
}
