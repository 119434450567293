import { useIntl } from 'react-intl'

import { User } from '@/shared/icons/solid'
import { Typography } from '@/shared/ui'

import { useDocumentsList } from '../../hooks/useDocumentsList'
import { CompanyOwnershipStep, OnboardingStepName } from '../../types'
import { StepDetails } from '../StepDetails'

type Props = {
  stepDetails?: CompanyOwnershipStep['stepDetails']
}

export const CompanyOwnershipReview = ({ stepDetails }: Props) => {
  const intl = useIntl()

  const documentKeys: Record<string, string> = {
    SHARE_REGISTRY: intl.formatMessage({
      id: 'onboarding.step.companyOwnership.shareRegistry',
      defaultMessage: 'Share registry',
    }),
  }

  const documentsDetails = useDocumentsList(
    stepDetails?.documents ?? [],
    documentKeys,
  )

  if (!stepDetails) {
    return null
  }

  const { beneficialOwners } = stepDetails

  const mappedKeys: Record<string, string | undefined> = {
    beneficialOwners: intl.formatMessage({
      id: 'onboarding.step.companyOwnership.beneficialOwners',
      defaultMessage: 'Beneficial owners',
    }),
  }

  const mappedValues: Record<string, React.ReactNode> = {
    beneficialOwners: (
      <div className="flex flex-col justify-end gap-2">
        {beneficialOwners?.map((owner, index) => (
          <div key={index} className="flex items-center justify-end gap-2">
            <User className="h-5 w-5" />
            <Typography>
              {owner.firstName} {owner.lastName} {owner.secondLastName}
            </Typography>
          </div>
        ))}
      </div>
    ),
  }

  const details = [
    {
      key: mappedKeys['beneficialOwners'],
      value: mappedValues['beneficialOwners'],
    },
  ]

  return (
    <StepDetails
      step={OnboardingStepName.COMPANY_OWNERSHIP}
      title={intl.formatMessage({
        id: 'onboarding.step.ownership',
        defaultMessage: 'Ownership',
      })}
      details={[...details, ...documentsDetails]}
    />
  )
}
