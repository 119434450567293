import { useMemo } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { Big } from 'big.js'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { z } from 'zod'

import { getRatesWithFees, RatesWithFeesResponse } from '@/api'
import { countryCodeToCountryData } from '@/constants/countries'
import { Currency } from '@/constants/currency'
import { RECIPIENTS_ROUTE } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { getAccount } from '@/features/Accounts/api'
import { Account } from '@/features/Accounts/types'
import { getSingleRecipient } from '@/features/Recipients/api'
import { Recipient } from '@/features/Recipients/types'
import { useSearchParamsValue } from '@/hooks/useSearchParamsValue'
import { getFlagUrl } from '@/lib/images'
import { formatCurrency, formatRate } from '@/lib/money'
import { getRecipientAccountNumber } from '@/lib/recipient'
import { maskValue } from '@/lib/typography'
import { AccountSelect, CurrencyFlag, GoBackButton } from '@/shared/components'
import { ArrowDown, ArrowTrendUp } from '@/shared/icons/outline'
import {
  AnimatedFormLabel,
  Button,
  Card,
  Form,
  FormControl,
  FormField,
  FormItem,
  MoneyInput,
  Skeleton,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'
import { CountryCode } from '@/types/country'

import { RequestData } from '../Send'

const SEND_FORM_ID = 'send-form-id'

const accountSchema = z
  .object({
    isUSRecipient: z.boolean().optional(),
    fromBalance: z.string(),
    rate: z.number(),
    requestId: z.string(),
    walletId: z.string().min(1),
    amountIn: z.string(),
    amountOut: z.string(),
    currencyIn: z.nativeEnum(Currency),
    currencyOut: z.nativeEnum(Currency),
    selection: z.enum(['in', 'out']),
  })
  .refine(
    (data) => {
      return data.walletId
        ? Big(data.fromBalance).gte(
            data.amountIn === '' ? 0 : Big(data.amountIn),
          )
        : true
    },
    { message: 'validation.balance.insufficient', path: ['amountIn'] },
  )
  .refine(
    (data) => {
      switch (data.currencyOut) {
        case Currency.MXN: {
          if (data.amountOut === '') {
            return false
          }

          if (data.isUSRecipient) {
            return true
          }

          return Big(data.amountOut).gte(50)
        }

        default:
          return true
      }
    },
    { message: 'validation.balance.MXN.min', path: ['amountOut'] },
  )
  .refine(
    (data) => {
      if (data.isUSRecipient) {
        if (data.amountIn === '') {
          return false
        }

        return Big(data.amountIn).gte(1)
      }

      return true
    },
    { message: 'validation.balance.USDC.min', path: ['amountIn'] },
  )

type AccountSchema = z.infer<typeof accountSchema>

type Props = {
  recipient: string
  onBack: () => void
  onContinue: (data: RequestData) => void
}

export const AmountScreen = ({ onBack, onContinue, recipient }: Props) => {
  const intl = useIntl()
  const [from, recipientParam] = useSearchParamsValue(['from', 'recipient'])
  const location = useLocation()

  const [accountQuery, recipientQuery, ratesResponse] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getAccount],
        queryFn: getAccount,
        select: (data: AxiosResponse<Account>) => data?.data,
      },
      {
        queryKey: [queryKeys.getSingleRecipient, recipient],
        queryFn: () => getSingleRecipient({ id: recipient ?? '' }),
        select: (data: AxiosResponse<Recipient>) => data.data,
        enabled: !!recipient,
      },
      {
        queryKey: [queryKeys.getFXRates, Currency.MXN],
        queryFn: () =>
          getRatesWithFees({
            from: Currency.USDC,
            to: Currency.MXN,
          }),
        select: (data: AxiosResponse<RatesWithFeesResponse>) => data.data,
        refetchInterval: 10 * 1000,
      },
    ],
  })

  const isUSRecipient = useMemo(
    () => recipientQuery.data?.country === CountryCode.US,
    [recipientQuery.data?.country],
  )

  const walletBySearchParams = useMemo(
    () => accountQuery.data?.wallets.find((wallet) => wallet.id === from),
    [accountQuery.data?.wallets, from],
  )

  const form = useForm<AccountSchema>({
    mode: 'onChange',
    resolver: zodResolver(accountSchema),
    values: {
      isUSRecipient,
      rate: ratesResponse.data?.fxRate ?? 0,
      fromBalance: walletBySearchParams?.balance
        ? Big(walletBySearchParams.balance).toFixed()
        : Big(0).toFixed(),
      walletId: walletBySearchParams?.id ?? '',
      currencyIn: Currency.USDC,
      currencyOut: Currency.MXN,
      amountIn: '',
      amountOut: '',
      selection: 'in',
      requestId: '',
    },
  })

  const onSubmit: SubmitHandler<AccountSchema> = async (data) => {
    const { isUSRecipient, fromBalance, selection, ...rest } = data

    switch (selection) {
      case 'in': {
        return onContinue({
          ...rest,
          beneficiaryId: recipient,
          amount: data.amountIn,
          currency: data.currencyIn,
          currencyOut: data.currencyOut,
          requestId: crypto.randomUUID(),
        })
      }

      case 'out': {
        return onContinue({
          ...rest,
          beneficiaryId: recipient,
          amount: data.amountOut,
          currency: data.currencyOut,
          currencyOut: data.currencyIn,
          requestId: crypto.randomUUID(),
        })
      }

      default:
        return
    }
  }

  return (
    <>
      {recipientParam ? (
        <GoBackButton to={location.state?.from ?? RECIPIENTS_ROUTE} />
      ) : (
        <GoBackButton onClick={onBack} />
      )}

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            defaultMessage="How much do you want to send?"
            id="send.selectAmount.title"
          />
        </Typography>

        <div className="p-2" />

        {recipientQuery.data ? (
          <div className="flex items-center justify-center gap-3">
            <img
              src={getFlagUrl(
                countryCodeToCountryData[recipientQuery.data.country].flagCode,
              )}
              className="h-3 w-3"
              alt={intl.formatMessage(
                {
                  id: `country.flag.alt`,
                  defaultMessage:
                    '{country, select, MEX {Mexican flag} USA {American flag} other {}}',
                },
                {
                  country: recipientQuery.data.country,
                },
              )}
            />

            <Typography>{recipientQuery.data.nickname}</Typography>

            <Typography>
              {maskValue(getRecipientAccountNumber(recipientQuery.data))}
            </Typography>
          </div>
        ) : (
          <Skeleton className="mx-auto h-[22px] w-48" />
        )}

        <div className="p-6" />

        <Form {...form}>
          <form
            id={SEND_FORM_ID}
            className="w-full"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <FormField
              control={form.control}
              name="walletId"
              render={({ field }) => {
                return (
                  <AccountSelect
                    value={field.value}
                    accounts={accountQuery.data?.wallets}
                    showLabel
                    label={intl.formatMessage({
                      id: 'label.payingFrom',
                      defaultMessage: 'Paying from',
                    })}
                    onChange={(v) => {
                      field.onChange(v)

                      const selected = accountQuery.data?.wallets.find(
                        (wallet) => wallet.id === v,
                      )

                      if (selected) {
                        form.setValue(
                          'fromBalance',
                          Big(selected.balance).toFixed(),
                        )

                        if (form.getValues('amountIn') !== '') {
                          form.trigger('amountIn')
                        }
                      }
                    }}
                  />
                )
              }}
            />

            <div className="p-2" />

            {!recipientQuery.isPending ? (
              <FormField
                control={form.control}
                name="amountIn"
                render={({ field }) => (
                  <FormItem>
                    <Card size="input" className="flex">
                      <CurrencyFlag
                        currency={isUSRecipient ? Currency.USD : Currency.USDC}
                      />

                      <FormControl>
                        <MoneyInput
                          currency={Currency.USD}
                          placeholder={intl.formatMessage(
                            {
                              id: 'send.selectAmount.amountIn',
                              defaultMessage:
                                '{isUSRecipient, select, true {Recipient gets} false {You pay} other {}}',
                            },
                            { isUSRecipient },
                          )}
                          className="text-right"
                          value={field.value}
                          onChange={(value) => {
                            field.onChange(value)

                            const amountOut =
                              value !== ''
                                ? Big(value)
                                    .times(ratesResponse.data?.fxRate ?? 0)
                                    .toFixed(2)
                                : ''

                            form.setValue('amountOut', amountOut)
                            form.trigger('amountOut')

                            form.setValue('selection', 'in')
                          }}
                        />
                      </FormControl>
                      <AnimatedFormLabel align="end">
                        <FormattedMessage
                          id="send.selectAmount.amountIn"
                          defaultMessage="{isUSRecipient, select, true {Recipient gets} false {You pay} other {}}"
                          values={{ isUSRecipient }}
                        />
                      </AnimatedFormLabel>
                    </Card>
                  </FormItem>
                )}
              />
            ) : (
              <Skeleton className="h-[54px] w-full" />
            )}

            {!isUSRecipient && !recipientQuery.isPending ? (
              <div className="relative z-10 mx-auto -mb-5 -mt-5 flex h-14 w-14 items-center justify-center rounded-full border-[8px] border-neutral-gray-100 bg-white transition-colors ">
                <ArrowDown className="h-5 w-5" />
              </div>
            ) : null}

            <FormField
              control={form.control}
              name="amountOut"
              render={({ field }) => (
                <FormItem>
                  {!isUSRecipient && !recipientQuery.isPending ? (
                    <Card size="input" className="flex">
                      <CurrencyFlag
                        countryCode={CountryCode.MX}
                        currency={Currency.MXN}
                      />

                      <FormControl>
                        <MoneyInput
                          currency={Currency.USD}
                          placeholder={intl.formatMessage({
                            id: 'send.selectAmount.amountOut',
                            defaultMessage: 'Recipient gets',
                          })}
                          className="text-right"
                          value={field.value}
                          onChange={(value) => {
                            field.onChange(value)

                            form.trigger('amountOut')

                            const amountIn =
                              value !== ''
                                ? Big(value)
                                    .div(ratesResponse.data?.fxRate ?? 1)
                                    .toFixed(2)
                                : ''

                            form.setValue('amountIn', amountIn)
                            form.trigger('amountIn')
                            form.setValue('selection', 'out')
                          }}
                        />
                      </FormControl>

                      <AnimatedFormLabel align="end">
                        <FormattedMessage
                          id="send.selectAmount.amountOut"
                          defaultMessage="Recipient gets"
                        />
                      </AnimatedFormLabel>
                    </Card>
                  ) : null}
                </FormItem>
              )}
            />

            <div className="p-2" />

            {!isUSRecipient && !recipientQuery.isPending ? (
              <div className="flex w-full items-center gap-1">
                <ArrowTrendUp />
                <div className="flex items-center gap-1">
                  <Typography>1 {formatCurrency(Currency.USDC)} =</Typography>
                  {ratesResponse.isPending ? (
                    <Skeleton className="h-6 w-24 rounded-md" />
                  ) : (
                    <Typography>
                      {formatRate(ratesResponse.data?.fxRate)}{' '}
                      {formatCurrency(Currency.MXN)}
                    </Typography>
                  )}
                </div>
              </div>
            ) : null}
          </form>
        </Form>

        <StickyContainer>
          <Button
            width="full"
            form={SEND_FORM_ID}
            disabled={
              Object.values(form.formState.errors).length > 0 ||
              !form.formState.isValid
            }
            onClick={form.handleSubmit(onSubmit)}
            type="submit"
          >
            <FormattedMessage
              defaultMessage="Save & Continue"
              id="action.saveAndContinue"
            />
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
