import { forwardRef, Ref, type SVGProps } from 'react'

const SvgSettings = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <rect
      width={369}
      height={1253}
      x={-155.5}
      y={-801.5}
      stroke="#9747FF"
      strokeDasharray="10 5"
      rx={4.5}
    />
    <g fill={props.color || `currentColor`}>
      <path d="M8.75 12a3.25 3.25 0 1 1 6.5 0 3.25 3.25 0 0 1-6.5 0" />
      <path
        fillRule="evenodd"
        d="M11.46 1.839a.75.75 0 0 1 1.08 0L15.111 4.5h3.638a.75.75 0 0 1 .75.75v3.638l2.662 2.573a.75.75 0 0 1 0 1.078L19.5 15.112v3.638a.75.75 0 0 1-.75.75h-3.638l-2.573 2.662a.75.75 0 0 1-1.078 0L8.888 19.5H5.25a.75.75 0 0 1-.75-.75v-3.638l-2.661-2.573a.75.75 0 0 1 0-1.078L4.5 8.888V5.25a.75.75 0 0 1 .75-.75h3.638zM12 7.25a4.75 4.75 0 1 0 0 9.5 4.75 4.75 0 0 0 0-9.5"
        clipRule="evenodd"
      />
    </g>
  </svg>
)
const ForwardRef = forwardRef(SvgSettings)
export default ForwardRef
