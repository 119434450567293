import { api } from '@/lib/api'

export type SignUpResponse = {
  signupId: string
}

export type SignUpRequest = {
  firstName: string
  lastName: string
  email: string
  legalName: string
  countryOfIncorporation: string
  localPhoneNumber: string
  internationalPhonePrefix: string
  taxId: string
  taxIdType: string
  secondLastName?: string
}

export function signUp(data: SignUpRequest) {
  return api.post<SignUpResponse>('/v1/signup', data)
}
