import * as React from 'react'

import { parseInputDate } from '@/lib/date'
import { cn } from '@/lib/utils'

import { X } from '../icons/outline'

import { Button, Input } from '.'

export interface DateInputProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'onChange' | 'value'
  > {
  onClear?: () => void
  onChange: (value: string) => void
  value: string
}

export const DateInput = React.forwardRef<HTMLInputElement, DateInputProps>(
  ({ className, ...props }, ref) => {
    return (
      <div className="relative">
        <Input
          variant="search"
          accept="[0-9\/]+"
          placeholder="dd/mm/yy"
          className={cn('h-auto bg-neutral-gray-100 p-2', className)}
          ref={ref}
          value={props.value}
          onChange={(e) => {
            const formatted = parseInputDate(e.target.value)

            props.onChange(formatted)
          }}
        />

        {props.onClear && props.value.length > 0 ? (
          <Button
            className="absolute right-2 top-2"
            variant="ghost"
            size="inline"
          >
            <X
              className="h-5 w-5 text-neutral-gray-400"
              onClick={props.onClear}
            />
          </Button>
        ) : null}
      </div>
    )
  },
)

DateInput.displayName = 'DateInput'
