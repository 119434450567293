import { format, parse } from 'date-fns'

import { AccountStatement } from '@/features/Accounts/types'
import { api } from '@/lib/api'
import { DATE_FROM_FILTER_NAME, DATE_TO_FILTER_NAME } from '@/shared/components'

type GetAccountStatementsRequest = {
  walletId: string
  params: Record<string, string>
}

export function getAccountStatements({
  walletId,
  params,
}: GetAccountStatementsRequest) {
  const searchParams = new URLSearchParams(params)

  if (params[DATE_FROM_FILTER_NAME]) {
    searchParams.set(
      DATE_FROM_FILTER_NAME,
      format(
        parse(
          params[DATE_FROM_FILTER_NAME],
          'dd/LL/yy',
          new Date().toUTCString(),
        ),
        'yyyy-MM-dd',
      ),
    )
  }

  if (params[DATE_TO_FILTER_NAME]) {
    searchParams.set(
      DATE_TO_FILTER_NAME,
      format(
        parse(
          params[DATE_TO_FILTER_NAME],
          'dd/LL/yy',
          new Date().toUTCString(),
        ),
        'yyyy-MM-dd',
      ),
    )
  }

  return api.get<AccountStatement[]>(
    `/v1/me/statements/${walletId}?${searchParams}`,
  )
}
