import { forwardRef, Ref, type SVGProps } from 'react'

const SvgPaperPlain = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path fill="#F5F5F5" d="M0 0h24v24H0z" />
    <path
      fill={props.color || `currentColor`}
      fillRule="evenodd"
      d="M18.483 19.79v-.002l.018-.043L21.5 4.625v-.048c0-.377-.14-.706-.442-.903-.265-.173-.57-.185-.784-.169-.226.017-.439.074-.586.12a3 3 0 0 0-.24.088l-.013.005-16.72 6.56H2.71a2 2 0 0 0-.149.062c-.09.041-.215.104-.341.19-.215.148-.624.496-.555 1.049.057.457.372.747.585.898a2 2 0 0 0 .435.234l.01.004.007.002 2.926.985q-.016.276.057.555l1.465 5.559a1.5 1.5 0 0 0 2.834.196l2.288-2.446 3.929 3.012.056.024c.357.156.69.206.995.164s.547-.17.728-.315a1.74 1.74 0 0 0 .492-.634l.007-.018.003-.006zM7.135 13.875a.3.3 0 0 1 .13-.33l9.921-6.3s.584-.355.563 0c0 0 .104.062-.209.353-.296.278-7.071 6.819-7.757 7.48a.3.3 0 0 0-.077.136L8.6 19.434z"
      clipRule="evenodd"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgPaperPlain)
export default ForwardRef
