import { api } from '@/lib/api'

import { TaskState } from '../types'

export function cancelTask({ id }: { id: string }) {
  return api.post<{
    id: string
    state: TaskState.CANCELLED
  }>(`/v1/my/business-tasks/${id}/cancel`)
}
