import { useEffect, useMemo, useRef, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { FormattedMessage } from 'react-intl'

import { Card, slideInFromBottomAnimation, Typography } from '@/shared/ui'

import { ChartMixed } from '../icons/outline'

const EMPTY_CHART_COLUMNS = [
  280, 140, 230, 230, 276, 253, 246, 151, 183, 276, 238, 281, 281, 182, 131,
]

type Props = {
  show: boolean
}

export const EmptyChart = ({ show }: Props) => {
  const chartRef = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState(0)

  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    setWidth(chartRef.current?.offsetWidth ?? 0)
  }, [width])

  useEffect(() => {
    const ref = chartRef.current

    window.addEventListener('resize', () => {
      setWidth(ref?.offsetWidth ?? 0)
    })

    return () => {
      window.removeEventListener('resize', () => {
        setWidth(ref?.offsetWidth ?? 0)
      })
    }
  })

  const emptyChartCols = useMemo(() => {
    const widthWithoutPadding = width - 32 * 2

    const columnsCount = Math.ceil(widthWithoutPadding / 64)

    let currentIndex = 1

    const columns = []

    for (let i = 0; i < columnsCount; i++) {
      columns.push(EMPTY_CHART_COLUMNS[currentIndex])

      currentIndex = (currentIndex + 1) % EMPTY_CHART_COLUMNS.length
    }

    return columns
  }, [width])

  return (
    <AnimatePresence mode="wait">
      <div ref={chartRef} className="w-full">
        {show && (
          <>
            <div className="p-3" />
            <motion.div
              variants={slideInFromBottomAnimation}
              initial="hidden"
              animate="visible"
              exit="exit"
              className="relative flex w-full items-end justify-around rounded-xl bg-neutral-gray-100 pt-6"
            >
              {emptyChartCols.map((height, i) => (
                <div
                  key={i}
                  style={{ height }}
                  className="w-16 rounded-t-sm bg-neutral-gray-200"
                />
              ))}

              <Card
                className="absolute left-[50%] top-[50%] z-50 
          translate-x-[-50%] translate-y-[-50%] p-3"
              >
                <div className="flex flex-col items-center justify-center gap-3">
                  <ChartMixed />

                  <Typography bold>
                    <FormattedMessage
                      id="label.comingSoon"
                      defaultMessage="Coming soon"
                    />
                  </Typography>

                  <Typography className="max-w-64 text-center">
                    <FormattedMessage
                      id="chart.startTransacting"
                      defaultMessage="Start transacting to see how your balance changes over time"
                    />
                  </Typography>
                </div>
              </Card>
            </motion.div>
          </>
        )}
      </div>
    </AnimatePresence>
  )
}
