import { useMemo, useState } from 'react'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  RowSelectionState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import { FormattedMessage, useIntl } from 'react-intl'

import { isSmallScreen } from '@/constants/breakpoints'
import { useMediaQuery } from '@/hooks/useMediaQuery'
import { getImage } from '@/lib/images'
import { cn } from '@/lib/utils'
import {
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Typography,
} from '@/shared/ui'

import { TableLoader } from './TableLoader'

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
  disabled?: boolean
  isLoading: boolean
  loaderOptions?: { rows?: number }
  emptyComponent?: React.ReactNode
  initialRows?: RowSelectionState
  emptyState?: {
    image?: string
    title?: string | React.ReactNode
    description?: string | React.ReactNode
  }
  onRowClick?: (row: TData) => void
  onRowSelect?: (data: TData[]) => void
  selectable?: boolean
}

export const DataTable = <TData, TValue>({
  columns,
  data,
  disabled,
  emptyComponent,
  emptyState,
  initialRows = {},
  isLoading,
  loaderOptions = {},
  onRowClick,
  onRowSelect,
  selectable,
}: DataTableProps<TData, TValue>) => {
  const intl = useIntl()
  const isMobile = useMediaQuery(isSmallScreen)

  const smallScreenLoaderOptions = {
    rows: 5,
  }

  const [rowSelection, setRowSelection] = useState(initialRows)

  const [sorting, setSorting] = useState<SortingState>([])

  const selectableColumn: ColumnDef<TData> = useMemo(
    () => ({
      id: 'select',
      header: ({ table }) => (
        <Checkbox
          className="mr-4 mt-1"
          onClick={(e) => e.stopPropagation()}
          checked={
            table.getIsAllPageRowsSelected() ||
            (table.getIsSomePageRowsSelected() && 'indeterminate')
          }
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label={intl.formatMessage({
            id: 'action.selectAll',
            defaultMessage: 'Select all',
          })}
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          onClick={(e) => e.stopPropagation()}
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label={intl.formatMessage({
            id: 'action.select',
            defaultMessage: 'Select',
          })}
        />
      ),
    }),
    [intl],
  )

  const table = useReactTable({
    data,
    columns: selectable ? [selectableColumn, ...columns] : columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: (updateFn) => {
      if (typeof updateFn === 'function') {
        const selectedRows = updateFn(rowSelection)
        const selectedData = data.filter((_, index) => !!selectedRows[index])

        onRowSelect?.(selectedData)
      }
      setRowSelection(updateFn)
    },
    state: {
      sorting,
      rowSelection,
    },
  })

  return (
    <Card
      size="none"
      className={cn('', disabled && 'pointer-events-none bg-neutral-gray-200')}
    >
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>

        <TableBody>
          {!!table.getRowModel().rows?.length && !isLoading ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                role={onRowClick ? 'button' : undefined}
                tabIndex={onRowClick ? 0 : undefined}
                onClick={() => onRowClick?.(row.original)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onRowClick?.(row.original)
                  }
                }}
                key={row.id}
                data-row={onRowClick ? 'clickable' : undefined}
                data-state={row.getIsSelected() && 'selected'}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <TableCell
                      style={{
                        width:
                          cell.column.columnDef.size === 150
                            ? 'auto'
                            : (cell.column.columnDef.size ?? undefined),
                      }}
                      key={cell.id}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  )
                })}
              </TableRow>
            ))
          ) : isLoading ? (
            <TableLoader
              {...(isMobile ? smallScreenLoaderOptions : loaderOptions)}
              table={table}
              selectable={selectable}
            />
          ) : (
            <TableRow data-state="empty">
              <TableCell
                colSpan={columns.length}
                className="h-24 cursor-default"
              >
                {emptyComponent ?? (
                  <div className="flex items-center gap-6">
                    <img
                      src={getImage({
                        name: emptyState?.image ?? 'empty-search',
                      })}
                      alt=""
                      aria-hidden
                      className="size-12"
                    />
                    <div className="flex flex-col gap-1">
                      <Typography bold>
                        {emptyState?.title ?? (
                          <FormattedMessage
                            id="search.noResults"
                            defaultMessage="No data found!"
                          />
                        )}
                      </Typography>
                      <Typography>
                        {emptyState?.description ?? (
                          <FormattedMessage
                            id="search.noResults.description"
                            defaultMessage="Please try to adjust your search criteria"
                          />
                        )}
                      </Typography>
                    </div>
                  </div>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Card>
  )
}
