import './setup-sentry'

import { QueryClientProvider } from '@tanstack/react-query'
import { polyfillCountryFlagEmojis } from 'country-flag-emoji-polyfill'
import { IntercomProvider } from 'react-use-intercom'

import { AppRouter } from '@/features/AppRouter'
import { queryClient } from '@/lib/queryClient'

import '@/global.css'

import { APIProvider } from '@vis.gl/react-google-maps'

import { Toaster, TooltipProvider } from '@/shared/ui'

import { INTERCOM_APP_ID } from './constants/intercom'
import { isDevelopment, isProduction } from './lib/utils'
import { AuthProvider } from './providers/AuthProvider'
import { LocaleProvider } from './providers/LocaleProvider'

polyfillCountryFlagEmojis()

export function App() {
  return (
    <LocaleProvider>
      <IntercomProvider
        shouldInitialize={isProduction() || isDevelopment()}
        appId={INTERCOM_APP_ID}
      >
        <AuthProvider>
          <QueryClientProvider client={queryClient}>
            <TooltipProvider>
              <APIProvider apiKey={import.meta.env.VITE_GOOGLE_PLACES_API_KEY}>
                <AppRouter />
              </APIProvider>
            </TooltipProvider>

            <Toaster
              richColors
              closeButton
              position="top-center"
              toastOptions={{
                duration: 4000,
                classNames: {
                  toast: 'group-[.toaster]:pointer-events-auto',
                },
              }}
            />
          </QueryClientProvider>
        </AuthProvider>
      </IntercomProvider>
    </LocaleProvider>
  )
}
