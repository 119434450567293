import { usePermissions } from '@/hooks/usePermissions'
import { Permission } from '@/types/permissions'

type Props = {
  children: React.ReactNode
  permissions: Permission[]
}

export const WithPermissions = ({ children, permissions }: Props) => {
  const businessUserPermissions = usePermissions()

  const hasPermissions = permissions.some((permission) =>
    businessUserPermissions?.includes(permission),
  )

  if (!hasPermissions) {
    return null
  }

  return <>{children}</>
}
