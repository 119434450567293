import { Outlet } from 'react-router-dom'

import { Sidebar } from './components/Sidebar'

export const Layout = () => {
  return (
    <div className="grid grid-cols-[3rem_1fr] gap-4 md:grid-cols-[4rem_1fr] md:gap-12">
      <Sidebar />

      <main className="col-start-2 mx-auto w-full max-w-7xl py-6 pr-4 md:py-12 md:pr-12">
        <Outlet />
      </main>
    </div>
  )
}
