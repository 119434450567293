import { useMemo } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { useIntl } from 'react-intl'

import { isSmallScreen } from '@/constants/breakpoints'
import { SingleTransaction } from '@/features/Transactions/types'
import { useMediaQuery } from '@/hooks/useMediaQuery'
import {
  DateCell,
  SingleAmountCell,
  SingleMethodCell,
  SingleTransactionCell,
  WalletCell,
} from '@/shared/components'
import { DataTableColumnHeader } from '@/shared/ui'

export const useCashbackTransactionColumns =
  (): ColumnDef<SingleTransaction>[] => {
    const isMobile = useMediaQuery(isSmallScreen)

    const intl = useIntl()

    const mobileColumns: ColumnDef<SingleTransaction>[] = useMemo(
      () => [
        {
          accessorKey: 'name',
          cell: ({ row }) => (
            <SingleTransactionCell transaction={row.original} />
          ),
          header: ({ column }) => (
            <DataTableColumnHeader
              column={column}
              title={intl.formatMessage({
                id: 'label.description',
                defaultMessage: 'Description',
              })}
            />
          ),
        },
        {
          accessorKey: 'baseAmount',
          cell: ({ row }) => <SingleAmountCell transaction={row.original} />,
          header: ({ column }) => (
            <DataTableColumnHeader
              column={column}
              title={intl.formatMessage({
                id: 'label.amount',
                defaultMessage: 'Amount',
              })}
            />
          ),
        },
      ],
      [intl],
    )

    const desktopColumns: ColumnDef<SingleTransaction>[] = useMemo(
      () => [
        {
          accessorKey: 'name',
          cell: ({ row }) => (
            <SingleTransactionCell transaction={row.original} />
          ),
          header: ({ column }) => (
            <DataTableColumnHeader
              column={column}
              title={intl.formatMessage({
                id: 'label.description',
                defaultMessage: 'Description',
              })}
            />
          ),
          size: 300,
        },
        {
          accessorKey: 'walletId',
          cell: ({ row }) => <WalletCell transaction={row.original} />,
          header: ({ column }) => (
            <DataTableColumnHeader
              column={column}
              title={intl.formatMessage({
                id: 'label.account',
                defaultMessage: 'Account',
              })}
            />
          ),
          size: 160,
        },
        {
          accessorKey: 'transactionType',
          cell: ({ row }) => <SingleMethodCell transaction={row.original} />,
          header: ({ column }) => (
            <DataTableColumnHeader
              column={column}
              title={intl.formatMessage({
                id: 'label.method',
                defaultMessage: 'Method',
              })}
            />
          ),
          size: 130,
        },
        {
          accessorKey: 'createdOn',
          cell: ({ row }) => <DateCell transaction={row.original} />,
          header: ({ column }) => (
            <DataTableColumnHeader
              column={column}
              title={intl.formatMessage({
                id: 'label.date',
                defaultMessage: 'Date',
              })}
            />
          ),
          size: 140,
        },

        {
          accessorKey: 'baseAmount',
          cell: ({ row }) => <SingleAmountCell transaction={row.original} />,
          header: ({ column }) => (
            <DataTableColumnHeader
              column={column}
              title={intl.formatMessage({
                id: 'label.amount',
                defaultMessage: 'Amount',
              })}
            />
          ),
          size: 120,
        },
      ],
      [intl],
    )

    return isMobile ? mobileColumns : desktopColumns
  }
