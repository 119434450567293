import { RouteObject } from 'react-router-dom'

import {
  BULK_PAYMENT_ROUTE,
  BULK_PAYMENT_TRANSACTIONS_ROUTE,
} from '@/constants/paths'

import { BulkPayments } from './BulkPayments'
import { BulkTransactions } from './BulkTransactions'

export const bulkPaymentsRoutes: RouteObject[] = [
  {
    path: BULK_PAYMENT_ROUTE,
    element: <BulkPayments />,
  },
]

export const bulkTransactionsRoutes: RouteObject[] = [
  {
    path: BULK_PAYMENT_TRANSACTIONS_ROUTE,
    element: <BulkTransactions />,
  },
]
