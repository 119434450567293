import { forwardRef, Ref, type SVGProps } from 'react'

const SvgPhonePad = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 19 18"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill={props.color || `currentColor`}
      d="M5.355 1.2a6.3 6.3 0 0 0-1.377 0 .83.83 0 0 0-.732.726 6 6 0 0 0 0 1.399.83.83 0 0 0 .732.725c.454.051.923.051 1.377 0a.83.83 0 0 0 .732-.725 6 6 0 0 0 0-1.4.83.83 0 0 0-.732-.725M9.855 1.2a6.3 6.3 0 0 0-1.377 0 .83.83 0 0 0-.732.726 6 6 0 0 0 0 1.399.83.83 0 0 0 .732.725c.454.051.923.051 1.377 0a.83.83 0 0 0 .732-.725 6 6 0 0 0 0-1.4.83.83 0 0 0-.732-.725M14.355 1.2a6.3 6.3 0 0 0-1.377 0 .83.83 0 0 0-.732.726 6 6 0 0 0 0 1.399.83.83 0 0 0 .732.725c.454.051.923.051 1.377 0a.83.83 0 0 0 .732-.725 6 6 0 0 0 0-1.4.83.83 0 0 0-.732-.725M5.355 5.325a6.3 6.3 0 0 0-1.377 0 .83.83 0 0 0-.732.726 6 6 0 0 0 0 1.399.83.83 0 0 0 .732.725c.454.051.923.051 1.377 0a.83.83 0 0 0 .732-.725 6 6 0 0 0 0-1.4.83.83 0 0 0-.732-.725M9.855 5.325a6.3 6.3 0 0 0-1.377 0 .83.83 0 0 0-.732.726 6 6 0 0 0 0 1.399.83.83 0 0 0 .732.725c.454.051.923.051 1.377 0a.83.83 0 0 0 .732-.725 6 6 0 0 0 0-1.4.83.83 0 0 0-.732-.725M14.355 5.325a6.3 6.3 0 0 0-1.377 0 .83.83 0 0 0-.732.726 6 6 0 0 0 0 1.399.83.83 0 0 0 .732.725c.454.051.923.051 1.377 0a.83.83 0 0 0 .732-.725 6 6 0 0 0 0-1.4.83.83 0 0 0-.732-.725M5.355 9.45a6.3 6.3 0 0 0-1.377 0 .83.83 0 0 0-.732.726 6 6 0 0 0 0 1.399.83.83 0 0 0 .732.725c.454.051.923.051 1.377 0a.83.83 0 0 0 .732-.725 6 6 0 0 0 0-1.4.83.83 0 0 0-.732-.725M9.855 9.45a6.3 6.3 0 0 0-1.377 0 .83.83 0 0 0-.732.726 6 6 0 0 0 0 1.399.83.83 0 0 0 .732.725c.454.051.923.051 1.377 0a.83.83 0 0 0 .732-.725 6 6 0 0 0 0-1.4.83.83 0 0 0-.732-.725M9.855 13.575a6.2 6.2 0 0 0-1.377 0 .83.83 0 0 0-.732.726 6 6 0 0 0 0 1.399.83.83 0 0 0 .732.725c.454.051.923.051 1.377 0a.83.83 0 0 0 .732-.725 6 6 0 0 0 0-1.4.83.83 0 0 0-.732-.725M14.355 9.45a6.3 6.3 0 0 0-1.377 0 .83.83 0 0 0-.732.726 6 6 0 0 0 0 1.399.83.83 0 0 0 .732.725c.454.051.923.051 1.377 0a.83.83 0 0 0 .732-.725 6 6 0 0 0 0-1.4.83.83 0 0 0-.732-.725"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgPhonePad)
export default ForwardRef
