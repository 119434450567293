import { FormattedMessage } from 'react-intl'

import { cardTypes } from '@/constants/cardTypes'
import { getImage } from '@/lib/images'
import { ChevronRight } from '@/shared/icons/outline'
import { Badge, Button, Card, SlideInScreen, Typography } from '@/shared/ui'

import { CardType } from '../../types'

type Props = {
  onContinue: (cardType: CardType) => void
}

export const CardTypeStep = ({ onContinue }: Props) => {
  return (
    <SlideInScreen>
      <Typography text="center" variant="h3">
        <FormattedMessage
          defaultMessage="Create a card"
          id="cards.create.title"
        />
      </Typography>

      <div className="p-2" />

      <Typography text="center">
        <FormattedMessage
          defaultMessage="Customize the card to fit your needs"
          id="cards.create.desc"
        />
      </Typography>

      <div className="p-6" />

      <div className="flex flex-col gap-4">
        {cardTypes.map((card) => {
          return (
            <Card
              key={card.title.id}
              size="upload"
              className="flex w-full items-center justify-between"
              asChild
              scalable
            >
              <Button
                size="inline"
                variant="ghost"
                onClick={() => {
                  onContinue(card.type)
                }}
              >
                <div className="flex items-center gap-4">
                  <img
                    src={getImage({
                      name: card.imageName,
                      category: 'cards',
                    })}
                    className="h-8 shrink-0 object-cover"
                    alt=""
                  />

                  <div className="flex flex-col">
                    <div className="flex gap-2">
                      <Typography bold>
                        <FormattedMessage {...card.title} />
                      </Typography>
                      {card.label && (
                        <Badge variant="neutral">
                          <FormattedMessage
                            id="label.free"
                            defaultMessage="Free"
                          />
                        </Badge>
                      )}
                    </div>
                    <Typography className="whitespace-break-spaces text-neutral-gray-600">
                      <FormattedMessage {...card.description} />
                    </Typography>
                  </div>
                </div>

                <ChevronRight className="size-8 shrink-0 text-neutral-gray-600" />
              </Button>
            </Card>
          )
        })}
      </div>
    </SlideInScreen>
  )
}
