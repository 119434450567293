import { forwardRef, Ref, type SVGProps } from 'react'

const SvgEnvelope = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill={props.color || `currentColor`}
      d="M19.25 9.03a.75.75 0 1 0 1.5-.054zm-3.536-4.028v.75h.025zm-6.428 0-.025.75h.025zM4.25 8.976a.75.75 0 1 0 1.5.053zm16.5.026a.75.75 0 0 0-1.5 0zm-.75 6 .75.027v-.027zm-4.286 4 .025-.75h-.025zm-6.428 0v-.75h-.025zm-4.286-4h-.75v.027zm.75-6a.75.75 0 0 0-1.5 0zm14.628.648a.75.75 0 1 0-.756-1.295zm-5.642 2.427-.378-.647-.007.003zm-4.472 0 .385-.644-.007-.003zM5.378 8.355a.75.75 0 1 0-.756 1.295zm15.372.62a4.9 4.9 0 0 0-5.061-4.722l.05 1.499a3.4 3.4 0 0 1 3.511 3.277zm-5.036-4.723H9.286v1.5h6.428zm-6.403 0a4.9 4.9 0 0 0-5.06 4.724l1.499.053a3.4 3.4 0 0 1 3.51-3.277zm9.939 4.75v6h1.5v-6zm0 5.974a3.4 3.4 0 0 1-3.51 3.277l-.051 1.499a4.9 4.9 0 0 0 5.06-4.723zm-3.536 3.276H9.286v1.5h6.428zm-6.453 0a3.4 3.4 0 0 1-3.511-3.276l-1.5.053a4.9 4.9 0 0 0 5.061 4.723zm-3.511-3.25v-6h-1.5v6zm13.872-6.647-5.264 3.075.756 1.295 5.264-3.075zm-5.27 3.078a3.61 3.61 0 0 1-3.703 0l-.77 1.288a5.11 5.11 0 0 0 5.242 0zm-3.71-.003L5.378 8.355 4.622 9.65l5.264 3.075z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgEnvelope)
export default ForwardRef
