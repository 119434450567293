import { useEffect, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { z } from 'zod'

import { googlePlacesFields } from '@/constants/google'
import { GooglePlacesParsedAddress } from '@/lib/address'
import {
  AddressAutocompleteField,
  CardAddressFields,
  GoBackButton,
} from '@/shared/components'
import {
  Button,
  Form,
  FormField,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'
import { CountryCode, ISO2CountryCode } from '@/types/country'

const formFields = [
  'addressStreet',
  'addressStreetNumber',
  'addressApartment',
  'addressReferences',
  'addressDistrict',
  'addressCity',
  'addressState',
  'addressPostCode',
  'addressCountry',
] as const

const googleAddressSchema = z.object({
  googlePlacesAddress: z.string(),
})

const teamCardAddressSchema = z.object({
  addressStreet: z.string().min(1, {
    message: 'validation.addressStreet.required',
  }),
  addressStreetNumber: z.string().min(1, {
    message: 'validation.addressStreetNumber.required',
  }),
  addressApartment: z.string().optional(),
  addressReferences: z.string().optional(),
  addressDistrict: z.string().min(1, {
    message: 'validation.addressDistrict.required',
  }),
  addressCity: z.string().min(1, {
    message: 'validation.addressCity.required',
  }),
  addressState: z.string().min(1, {
    message: 'validation.addressState.required',
  }),
  addressPostCode: z.string().min(1, {
    message: 'validation.addressZipCode.required',
  }),
  addressCountry: z.string().min(1, {
    message: 'validation.addressCountry.required',
  }),
  internationalPhonePrefix: z.string().min(1, {
    message: 'validation.phoneCode.required',
  }),
  localPhoneNumber: z
    .string()
    .min(1, {
      message: 'validation.phoneNumber.required',
    })
    .refine(isValidPhoneNumber, {
      message: 'validation.phoneNumber.invalid',
    }),
})

export type TeamCardAddressSchema = z.infer<typeof teamCardAddressSchema>
export type GoogleAddressSchema = z.infer<typeof googleAddressSchema>

const CARD_ADDRESS_FORM_ID = 'card-address-form-id'

type Props = {
  onBack: () => void
  onContinue: (data: TeamCardAddressSchema) => void
  teamCardAddressDetails?: TeamCardAddressSchema
}

export const TeamCardAddressStep = ({
  onBack,
  onContinue,
  teamCardAddressDetails,
}: Props) => {
  const [inputView, setInputView] = useState<'auto' | 'manual'>('auto')
  const intl = useIntl()

  const form = useForm<TeamCardAddressSchema>({
    mode: 'onChange',
    resolver: zodResolver(teamCardAddressSchema),
    values: {
      addressStreet: teamCardAddressDetails?.addressStreet ?? '',
      addressStreetNumber: teamCardAddressDetails?.addressStreetNumber ?? '',
      addressApartment: teamCardAddressDetails?.addressApartment ?? '',
      addressReferences: teamCardAddressDetails?.addressReferences ?? '',
      addressDistrict: teamCardAddressDetails?.addressDistrict ?? '',
      addressCity: teamCardAddressDetails?.addressCity ?? '',
      addressState: teamCardAddressDetails?.addressState ?? '',
      addressPostCode: teamCardAddressDetails?.addressPostCode ?? '',
      addressCountry: CountryCode.MX,
      localPhoneNumber: teamCardAddressDetails?.localPhoneNumber ?? '',
      internationalPhonePrefix:
        teamCardAddressDetails?.internationalPhonePrefix ?? '+52',
    },
  })

  const googleForm = useForm<GoogleAddressSchema>({
    mode: 'onChange',
    resolver: zodResolver(googleAddressSchema),
    defaultValues: { googlePlacesAddress: '' },
  })

  useEffect(() => {
    if (teamCardAddressDetails?.addressStreet) {
      setInputView('manual')
    }
  }, [teamCardAddressDetails])

  const onSubmit: SubmitHandler<TeamCardAddressSchema> = (data) => {
    onContinue(data)
  }

  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            id="card.create.addressStep.title"
            defaultMessage="Where should we send the card?"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            id="team.card.create.addressStep.subtitle"
            defaultMessage="Review the delivery address carefully. You will not be able to edit it after its shipped!"
          />
        </Typography>

        <div className="p-6" />

        {inputView === 'auto' ? (
          <Form key={inputView} {...googleForm}>
            <form id={CARD_ADDRESS_FORM_ID} className="w-full">
              <FormField
                control={googleForm.control}
                name="googlePlacesAddress"
                render={({ field }) => (
                  <AddressAutocompleteField
                    restrictedCountries={[ISO2CountryCode.MX]}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    name={field.name}
                    disabled={field.disabled}
                    placeholder={intl.formatMessage({
                      id: 'label.searchAddress',
                      defaultMessage: 'Search address',
                    })}
                    onManualClick={() => setInputView('manual')}
                    onGoogleAddressSelected={(address) => {
                      formFields
                        .filter(
                          (field) =>
                            field !== 'addressApartment' &&
                            field !== 'addressReferences',
                        )
                        .forEach((field, index) => {
                          form.setValue(
                            field,
                            address[
                              googlePlacesFields[
                                index
                              ] as keyof GooglePlacesParsedAddress
                            ],
                          )
                        })

                      setInputView('manual')
                    }}
                  />
                )}
              />
            </form>
          </Form>
        ) : (
          <Form key={inputView} {...form}>
            <form id={CARD_ADDRESS_FORM_ID} className="w-full">
              <CardAddressFields form={form} />
            </form>
          </Form>
        )}

        <StickyContainer>
          <Button
            width="full"
            disabled={
              !form.formState.isValid ||
              Object.values(form.getValues()).every((value) => !value)
            }
            form={CARD_ADDRESS_FORM_ID}
            onClick={form.handleSubmit(onSubmit)}
            type="submit"
          >
            <FormattedMessage
              defaultMessage="Save & Continue"
              id="action.saveAndContinue"
            />
          </Button>

          {inputView === 'manual' && (
            <>
              <div className="p-1" />
              <Button
                type="button"
                onClick={() => {
                  setInputView('auto')
                  googleForm.setValue('googlePlacesAddress', '')
                }}
                width="full"
                className="text-center font-semibold text-primary"
                variant="ghost"
              >
                <FormattedMessage
                  defaultMessage="Search for my address"
                  id="onboarding.businessAddress.searchForAddress"
                />
              </Button>
            </>
          )}
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
