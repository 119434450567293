import { createPortal } from 'react-dom'
import { Toaster as Sonner } from 'sonner'

type ToasterProps = React.ComponentProps<typeof Sonner>

const Toaster = ({ ...props }: ToasterProps) => {
  const toastComponent = (
    <Sonner
      className="toaster group"
      toastOptions={{
        classNames: {
          toast:
            'group toast group-[.toaster]:bg-white group-[.toaster]:text-neutral-gray-900 group-[.toaster]:border-neutral-200 group-[.toaster]:shadow-lg',
          description: 'group-[.toast]:text-neutral-gray-600',
          actionButton:
            'group-[.toast]:bg-neutral-gray-900 group-[.toast]:text-neutral-gray-100',
          cancelButton:
            'group-[.toast]:bg-neutral-gray-100 group-[.toast]:text-neutral-gray-600',
        },
      }}
      {...props}
    />
  )

  return createPortal(toastComponent, document.body)
}

export { Toaster }
