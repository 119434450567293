export const MOCK_COUNTRY_CODES = [
  {
    name: 'Argentina',
    nameToShow: 'Argentina',
    flag: '\uD83C\uDDE6\uD83C\uDDF7',
    internationalPhonePrefix: '+54',
    isoCode: 'ARG',
  },
  {
    name: 'Colombia',
    nameToShow: 'Colombia',
    flag: '\uD83C\uDDE8\uD83C\uDDF4',
    internationalPhonePrefix: '+57',
    isoCode: 'COL',
  },
  {
    name: 'Mexico',
    nameToShow: 'Mexico',
    flag: '\uD83C\uDDF2\uD83C\uDDFD',
    internationalPhonePrefix: '+52',
    isoCode: 'MEX',
  },
  {
    name: 'United States of America',
    nameToShow: 'USA',
    flag: '\uD83C\uDDFA\uD83C\uDDF8',
    internationalPhonePrefix: '+1',
    isoCode: 'USA',
  },
]
