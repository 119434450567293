import { forwardRef, Ref, type SVGProps } from 'react'

const SvgBag = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill="#5c5c77"
      d="M5 9.3h.75v-.01zm1.75-1.8v-.75h-.01zm10.5 0 .011-.75h-.01zM19 9.3l-.75-.01v.01zm0 8.1h-.75v.01zM15.5 21v.75h.011zm-7 0-.01.75h.01zM5 17.4l.75.01v-.01zm.75-8.11a1.026 1.026 0 0 1 1.011-1.04l-.022-1.5A2.526 2.526 0 0 0 4.25 9.31zm1-1.04h10.5v-1.5H6.75zm10.49 0a1.026 1.026 0 0 1 1.01 1.04l1.5.02a2.526 2.526 0 0 0-2.489-2.56zm1.01 1.05v8.1h1.5V9.3zm0 8.11a2.8 2.8 0 0 1-2.76 2.84l.021 1.5a4.3 4.3 0 0 0 4.24-4.36zm-2.75 2.84h-7v1.5h7zm-6.989 0a2.8 2.8 0 0 1-2.76-2.84l-1.5-.02a4.3 4.3 0 0 0 4.239 4.36zm-2.76-2.85V9.3h-1.5v8.1z"
    />
    <path
      fill="#5c5c77"
      d="M14.75 10.2a.75.75 0 0 0 1.5 0zm.75-3.6-.75-.01v.01zM12 3l.011-.75h-.021zM8.5 6.6h.75v-.01zm-.75 3.6a.75.75 0 0 0 1.5 0zm8.5 0V6.6h-1.5v3.6zm0-3.59a4.3 4.3 0 0 0-4.239-4.36l-.021 1.5a2.8 2.8 0 0 1 2.76 2.84zm-4.26-4.36a4.3 4.3 0 0 0-4.24 4.36l1.5-.02a2.8 2.8 0 0 1 2.761-2.84zM7.75 6.6v3.6h1.5V6.6z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgBag)
export default ForwardRef
