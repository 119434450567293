import { api } from '@/lib/api'

type RemoveFileParams = {
  id: string
  fileName: string
}

export function removeFile({ id, fileName }: RemoveFileParams) {
  return api.delete<void>(`/v1/me/bulk-payment-files/${id}/name/${fileName}`)
}
