import { FormattedMessage } from 'react-intl'

import { GoBackButton } from '@/shared/components'
import { ChevronRight } from '@/shared/icons/outline'
import { Building, User } from '@/shared/icons/solid'
import { Button, Card, SlideInScreen, Typography } from '@/shared/ui'
import { Icon } from '@/types/global'

import { EntityType } from '../../types'

const dataByEntityType: Record<EntityType, Icon> = {
  [EntityType.BUSINESS]: Building,
  [EntityType.INDIVIDUAL]: User,
}

type Props = {
  onBack: () => void
  onContinue: (entity: EntityType) => void
}

export const RecipientEntityStep = ({ onBack, onContinue }: Props) => {
  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            defaultMessage="Recipient type"
            id="label.recipientType"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            defaultMessage="Who are you paying?"
            id="recipients.create.whoYouPay"
          />
        </Typography>

        <div className="p-5" />

        <div className="flex flex-col gap-3">
          {Object.values(EntityType).map((entity) => {
            const Icon = dataByEntityType[entity]

            return (
              <Card
                size="upload"
                className="flex w-full items-center justify-between"
                key={entity}
                scalable
                asChild
              >
                <Button
                  size="inline"
                  variant="ghost"
                  onClick={() => onContinue(entity)}
                >
                  <div className="flex items-center gap-4">
                    <div className="flex size-9 items-center justify-center rounded-md bg-neutral-gray-100 p-0.5">
                      <Icon />
                    </div>
                    <div className="flex flex-col">
                      <Typography bold>
                        <FormattedMessage
                          defaultMessage="{entity, select, BUSINESS {Business} INDIVIDUAL {Individual} other {}}"
                          id="recipients.entity"
                          values={{ entity }}
                        />
                      </Typography>
                      <Typography className="text-neutral-gray-600">
                        <FormattedMessage
                          defaultMessage="{entity, select, BUSINESS {Add an entity as new recipient} INDIVIDUAL {Add a person as new recipient} other {}}"
                          id="recipients.entity.description"
                          values={{ entity }}
                        />
                      </Typography>
                    </div>
                  </div>

                  <ChevronRight />
                </Button>
              </Card>
            )
          })}
        </div>
      </SlideInScreen>
    </>
  )
}
