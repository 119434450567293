import { useMemo } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { useIntl } from 'react-intl'

import { isSmallScreen } from '@/constants/breakpoints'
import { useMediaQuery } from '@/hooks/useMediaQuery'
import { DataTableColumnHeader } from '@/shared/ui'

import { Task } from '../../types'
import { SubmittedOnCell } from '../SubmittedOnCell'

import { RequestedByCell } from './RequestedByCell'
import { TaskActionsCell } from './TaskActionsCell'
import { TaskDescriptionCell } from './TaskDescriptionCell'

type Props = {
  onAction: (id: string) => void
}

export const useTasksColumns = ({ onAction }: Props): ColumnDef<Task>[] => {
  const isMobile = useMediaQuery(isSmallScreen)

  const intl = useIntl()

  const mobileColumns: ColumnDef<Task>[] = useMemo(
    () => [
      {
        accessorKey: 'type',
        cell: ({ row }) => <TaskDescriptionCell task={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.description',
              defaultMessage: 'Description',
            })}
          />
        ),
      },
      {
        id: 'actions',
        cell: ({ row }) => (
          <TaskActionsCell task={row.original} onAction={onAction} />
        ),
      },
    ],
    [intl, onAction],
  )

  const desktopColumns: ColumnDef<Task>[] = useMemo(
    () => [
      {
        accessorKey: 'createdOn',
        cell: ({ row }) => <SubmittedOnCell task={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.submittedOn',
              defaultMessage: 'Submitted on',
            })}
          />
        ),
      },
      {
        id: 'createdByFirstName',
        accessorKey: 'createdByFirstName',
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.requestedBy',
              defaultMessage: 'Requested by',
            })}
          />
        ),
        cell: ({ row }) => <RequestedByCell task={row.original} />,
      },
      {
        accessorKey: 'type',
        cell: ({ row }) => <TaskDescriptionCell task={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.description',
              defaultMessage: 'Description',
            })}
          />
        ),
      },
      {
        id: 'actions',
        cell: ({ row }) => (
          <TaskActionsCell onAction={onAction} task={row.original} />
        ),
      },
    ],
    [intl, onAction],
  )

  return isMobile ? mobileColumns : desktopColumns
}
