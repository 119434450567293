import { Fragment } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { Card, Checkbox, Skeleton, Typography } from '@/shared/ui'

import { CSVTransaction } from '../types'

import { CSVTransactionRow } from './CSVTransactionRow'

type Props = {
  selectedTransactions: string[]
  transactions: CSVTransaction[]
  onSelect?: (idx: string[]) => void
}

export const CSVTransactionsList = ({
  transactions,
  selectedTransactions,
  onSelect,
}: Props) => {
  const intl = useIntl()
  const allIdx = transactions.map((tx) => tx.id)

  if (transactions.length === 0) {
    return (
      <Card size="medium">
        <FormattedMessage
          id="bulkPayments.selectTransactionsTab.valid.noTransactions"
          defaultMessage="No recipients were imported"
        />
      </Card>
    )
  }

  return (
    <div className="flex flex-col">
      <div className="flex justify-between px-6">
        <div className="flex items-center gap-4">
          <Checkbox
            type="button"
            aria-label={intl.formatMessage({
              defaultMessage: 'Select all transactions',
              id: 'bulkPayment.selectAllTransactions',
            })}
            checked={selectedTransactions.length === transactions.length}
            onCheckedChange={() => {
              const allSelected =
                selectedTransactions.length === transactions.length

              onSelect?.(allSelected ? [] : allIdx)
            }}
          />
          <Typography className="text-neutral-gray-600">
            <FormattedMessage
              id="bulkPayments.selectTransactionsTab.total"
              defaultMessage="Recipients • {count} total"
              values={{ count: transactions.length }}
            />
          </Typography>
        </div>
        <Typography className="text-neutral-gray-600">
          <FormattedMessage
            id="bulkPayments.selectTransactionsTab.amount"
            defaultMessage="Payment amount"
          />
        </Typography>
      </div>
      <div className="p-1" />

      <Card>
        {transactions.map((transaction, index) => (
          <Fragment key={transaction.id}>
            <CSVTransactionRow
              selectedTransactions={selectedTransactions}
              onSelect={onSelect}
              transaction={transaction}
            />
            {index === transactions.length - 1 ? null : (
              <div className="border-b border-neutral-gray-300" />
            )}
          </Fragment>
        ))}
      </Card>
    </div>
  )
}

const CSVTransactionsListSkeleton = () => {
  return (
    <>
      <Card>
        <div className="flex items-center justify-between">
          <div className="flex w-full flex-col gap-1 py-2">
            <Skeleton className="h-[18px] w-1/3" />
            <Skeleton className="h-[18px] w-1/3" />
          </div>
          <Skeleton className="h-[18px] w-1/3" />
        </div>
        <div className="border-b border-neutral-gray-300" />
        <div className="flex items-center justify-between">
          <div className="flex w-full flex-col gap-1 py-2">
            <Skeleton className="h-[18px] w-1/3" />
            <Skeleton className="h-[18px] w-1/3" />
          </div>
          <Skeleton className="h-[18px] w-1/3" />
        </div>
      </Card>
    </>
  )
}

CSVTransactionsList.Skeleton = CSVTransactionsListSkeleton
