import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

import { DisplayableType, Transaction } from '@/features/Transactions/types'

import { useSearchParamsValue } from './useSearchParamsValue'
import { useSidebar } from './useSidebar'

const TX_QUERY_KEY = 'tx'
const TX_TYPE_QUERY_KEY = 'type'

export function useTransactionUtils() {
  const [searchParams, setSearchParams] = useSearchParams()

  const [transactionIdFromQuery, transactionTypeFromQuery] =
    useSearchParamsValue([TX_QUERY_KEY, TX_TYPE_QUERY_KEY])

  const [showDetails, setShowDetails] = useSidebar({ key: TX_QUERY_KEY })

  const params = Object.fromEntries(
    [...searchParams].filter(
      ([key]) => key !== TX_QUERY_KEY && key !== TX_TYPE_QUERY_KEY,
    ),
  )

  const openSidebar = useCallback(
    (tx: Transaction) => {
      setSearchParams(
        (params) => {
          switch (tx.displayableType) {
            case DisplayableType.SINGLE: {
              params.set(TX_QUERY_KEY, tx.id)
              params.set(TX_TYPE_QUERY_KEY, DisplayableType.SINGLE)
              break
            }

            case DisplayableType.BULK: {
              params.set(TX_QUERY_KEY, tx.groupId)
              params.set(TX_TYPE_QUERY_KEY, DisplayableType.BULK)
              break
            }

            default: {
              break
            }
          }

          return params
        },
        {
          preventScrollReset: true,
        },
      )
    },
    [setSearchParams],
  )

  const closeSidebar = useCallback(() => {
    setShowDetails((s) => !s)
    setSearchParams(
      (params) => {
        params.delete(TX_QUERY_KEY)
        params.delete(TX_TYPE_QUERY_KEY)
        return params
      },
      { preventScrollReset: true },
    )
  }, [setSearchParams, setShowDetails])

  return {
    closeSidebar,
    openSidebar,
    params,
    isSidebarOpen: showDetails,
    transactionIdFromQuery,
    transactionTypeFromQuery: transactionTypeFromQuery as
      | DisplayableType
      | undefined,
  }
}
