import { api } from '@/lib/api'

import {
  LimitPeriodType,
  MovementPermission,
  TeamMemberTransferPermission,
} from '../types'

type CreateTransferPermissionsRequest = {
  id: string
  type: MovementPermission
  limitPeriodType?: LimitPeriodType
  periodTransferLimitAmount?: string
  singleTransferLimitAmount?: string
}

export function createTransferPermissions(
  data: CreateTransferPermissionsRequest,
) {
  const { id, ...rest } = data

  return api.post<TeamMemberTransferPermission>(
    `/v1/me/business-users/${id}/transfer-permissions`,
    rest,
  )
}
