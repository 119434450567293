import { zodResolver } from '@hookform/resolvers/zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { z } from 'zod'

import { GoBackButton, OptionalTag } from '@/shared/components'
import {
  AnimatedFormLabel,
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  Input,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'
import { Role } from '@/types/roles'

const TEAM_MEMBER_DETAILS_FORM = 'team-member-details-form-id'

const teamMemberDetailsSchema = z.object({
  firstName: z.string().min(1, {
    message: 'validation.firstName.required',
  }),
  lastName: z.string().min(1, {
    message: 'validation.lastName.required',
  }),
  secondLastName: z.string().optional(),
  title: z.string().optional(),
  email: z.string().email({
    message: 'validation.email.invalid',
  }),
})

export type TeamMemberSchema = z.infer<typeof teamMemberDetailsSchema>

type Props = {
  teamMemberDetails?: TeamMemberSchema
  role?: Role
  onBack: () => void
  onContinue: (data: TeamMemberSchema) => void
}

export const TeamMemberDetailsStep = ({
  teamMemberDetails,
  role,
  onBack,
  onContinue,
}: Props) => {
  const intl = useIntl()

  const form = useForm<TeamMemberSchema>({
    mode: 'onChange',
    resolver: zodResolver(teamMemberDetailsSchema),
    values: {
      firstName: teamMemberDetails?.firstName ?? '',
      lastName: teamMemberDetails?.lastName ?? '',
      secondLastName: teamMemberDetails?.secondLastName ?? '',
      title: teamMemberDetails?.title ?? '',
      email: teamMemberDetails?.email ?? '',
    },
  })

  const onSubmit: SubmitHandler<TeamMemberSchema> = (data) => {
    onContinue(data)
  }

  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            id="teamMember.details.title"
            defaultMessage="Who is the new {role}"
            values={{
              role: (
                <FormattedMessage
                  id="team.role.label"
                  defaultMessage="{role, select, ADMIN {Admin} PAYMENT_OPS {Payment Ops} CARD_USER {Card User} READ_ONLY {Read Only} other {}}"
                  values={{ role }}
                />
              ),
            }}
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            id="teamMember.details.description"
            defaultMessage="Enter full name and work email to send over the invite"
          />
        </Typography>

        <div className="p-6" />

        <Form {...form}>
          <form
            id={TEAM_MEMBER_DETAILS_FORM}
            className="w-full"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <div className="flex flex-col gap-3">
              <FormField
                control={form.control}
                name="firstName"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        autoComplete="given-name"
                        placeholder={intl.formatMessage({
                          defaultMessage: 'First name',
                          id: 'label.firstName',
                        })}
                        {...field}
                      />
                    </FormControl>
                    <AnimatedFormLabel>
                      <FormattedMessage
                        defaultMessage="First name"
                        id="label.firstName"
                      />
                    </AnimatedFormLabel>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        autoComplete="family-name"
                        placeholder={intl.formatMessage({
                          defaultMessage: 'Last name',
                          id: 'label.lastName',
                        })}
                        {...field}
                      />
                    </FormControl>
                    <AnimatedFormLabel>
                      <FormattedMessage
                        defaultMessage="Last name"
                        id="label.lastName"
                      />
                    </AnimatedFormLabel>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="secondLastName"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        autoComplete="additional-name"
                        placeholder={intl.formatMessage({
                          defaultMessage: 'Second last name',
                          id: 'label.secondLastName',
                        })}
                        {...field}
                      />
                    </FormControl>
                    <AnimatedFormLabel>
                      <FormattedMessage
                        defaultMessage="Second last name"
                        id="label.secondLastName"
                      />
                    </AnimatedFormLabel>

                    {field.value === '' && <OptionalTag />}
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="title"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        placeholder={intl.formatMessage({
                          defaultMessage: 'Title',
                          id: 'label.title',
                        })}
                        {...field}
                      />
                    </FormControl>
                    <AnimatedFormLabel>
                      <FormattedMessage
                        defaultMessage="Title"
                        id="label.title"
                      />
                    </AnimatedFormLabel>

                    {field.value === '' && <OptionalTag />}
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        autoComplete="email"
                        placeholder={intl.formatMessage({
                          defaultMessage: 'Work email',
                          id: 'label.workEmail',
                        })}
                        {...field}
                      />
                    </FormControl>
                    <AnimatedFormLabel>
                      <FormattedMessage
                        defaultMessage="Work email"
                        id="label.workEmail"
                      />
                    </AnimatedFormLabel>
                  </FormItem>
                )}
              />
            </div>
          </form>
        </Form>

        <StickyContainer>
          <Button
            width="full"
            form={TEAM_MEMBER_DETAILS_FORM}
            disabled={!form.formState.isValid}
            onClick={form.handleSubmit(onSubmit)}
            type="submit"
          >
            <FormattedMessage
              id="action.saveAndContinue"
              defaultMessage="Save & Continue"
            />
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
