import { Currency } from '@/constants/currency'

export enum Period {
  Day = '1D',
  Week = '1W',
  Month = '1M',
  SixMonths = '6M',
  Year = '1Y',
  FiveYears = '5Y',
}

export type AxisProps = {
  textAnchor: 'middle' | 'start' | 'end'
  verticalAnchor: 'start' | 'middle' | 'end'
  orientation: 'top' | 'bottom' | 'left' | 'right'
  width: number
  height: number
  x: number
  y: number
  className: string
  stroke: string
  fill: string
  index: number
  payload: {
    coordinate: number
    value: number
    index: number
    offset: number
    tickCoord: number
    isShow: boolean
  }
  visibleTicksCount: number
}

export type ChartFXRate = {
  ask: number
  mid: number
  bid: number
  baseCurrency: Currency
  book: string
  createdAt: string
  id: string
  localCurrency: Currency
}

export type ChartFXRateWithTimestamp = Omit<ChartFXRate, 'createdAt'> & {
  createdAt: number
}
