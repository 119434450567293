import { forwardRef, Ref, type SVGProps } from 'react'

const SvgDownload = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill={props.color || `currentColor`}
      fillRule="evenodd"
      d="M5 16.25a.75.75 0 0 1 .75.75v2c0 .138.112.25.25.25h12a.25.25 0 0 0 .25-.25v-2a.75.75 0 0 1 1.5 0v2A1.75 1.75 0 0 1 18 20.75H6A1.75 1.75 0 0 1 4.25 19v-2a.75.75 0 0 1 .75-.75"
      clipRule="evenodd"
    />
    <path
      fill={props.color || `currentColor`}
      d="M10.738 3.75a.99.99 0 0 0-.988.906 37 37 0 0 0-.082 5.27q-.37.02-.74.047l-1.49.109a.76.76 0 0 0-.585 1.167 15.6 15.6 0 0 0 4.032 4.258l.597.429a.89.89 0 0 0 1.036 0l.597-.429a15.6 15.6 0 0 0 4.032-4.258.76.76 0 0 0-.585-1.167l-1.49-.109a42 42 0 0 0-.74-.047 37 37 0 0 0-.081-5.27.99.99 0 0 0-.989-.906z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgDownload)
export default ForwardRef
