import { forwardRef, Ref, type SVGProps } from 'react'

const SvgCompressArrows = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      stroke={props.color || `currentColor`}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m6.629 10.011 3.034.367a.63.63 0 0 0 .715-.715l-.367-3.034a.713.713 0 0 0-1.177-.462L7.5 7.5 6.166 8.834a.713.713 0 0 0 .463 1.177M17.371 13.989l-3.034-.367a.63.63 0 0 0-.715.715l.367 3.034a.713.713 0 0 0 1.177.462L16.5 16.5l1.334-1.334a.713.713 0 0 0-.463-1.177M10.011 17.371l.367-3.034a.63.63 0 0 0-.715-.715l-3.034.367a.713.713 0 0 0-.462 1.177L7.5 16.5l1.334 1.334a.713.713 0 0 0 1.177-.463M13.989 6.629l-.367 3.034a.63.63 0 0 0 .715.715l3.034-.367a.713.713 0 0 0 .462-1.177L16.5 7.5l-1.334-1.334a.713.713 0 0 0-1.177.463"
      clipRule="evenodd"
    />
    <path
      fill={props.color || `currentColor`}
      d="M6.97 8.03a.75.75 0 0 0 1.06-1.06zM3.53 2.47a.75.75 0 0 0-1.06 1.06zm13.5 13.5a.75.75 0 1 0-1.06 1.06zm3.44 5.56a.75.75 0 1 0 1.06-1.06zm-12.44-4.5a.75.75 0 1 0-1.06-1.06zm-5.56 3.44a.75.75 0 1 0 1.06 1.06zm13.5-13.5a.75.75 0 0 0 1.06 1.06zm5.56-3.44a.75.75 0 0 0-1.06-1.06zM8.03 6.97l-4.5-4.5-1.06 1.06 4.5 4.5zm7.94 10.06 4.5 4.5 1.06-1.06-4.5-4.5zm-9-1.06-4.5 4.5 1.06 1.06 4.5-4.5zm10.06-7.94 4.5-4.5-1.06-1.06-4.5 4.5z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgCompressArrows)
export default ForwardRef
