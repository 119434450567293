import { useLocale } from '@/providers/LocaleProvider'
import { Card, Switch, Typography } from '@/shared/ui'

type Props = {
  description: React.ReactNode
  title: React.ReactNode
  value: boolean
  onChange: (value: boolean) => void
}

export const ConfirmActivityField = ({
  value,
  onChange,
  description,
  title,
}: Props) => {
  const { locale } = useLocale()

  return (
    <Card size="upload" className="flex items-center justify-between">
      <div className="flex flex-col">
        <Typography>{title}</Typography>
        <Typography variant="body-small" className="text-neutral-gray-600">
          {description}
        </Typography>
      </div>

      <Switch locale={locale} checked={value} onCheckedChange={onChange} />
    </Card>
  )
}
