import { FormattedMessage } from 'react-intl'

import { Card, CardState } from '@/features/Cards'
import { calculateProgress, formatMoney } from '@/lib/money'
import { Progress, Typography } from '@/shared/ui'

type Props = {
  card: Card
}

export const CardLimitCell = ({ card }: Props) => {
  const progress = calculateProgress({
    value: card.cardLimit?.usedAmount,
    total: card.cardLimit?.limitAmount,
  })

  const isTerminated = card.state === CardState.TERMINATED

  if (isTerminated) {
    return null
  }

  return (
    <div className="flex flex-col justify-center gap-2">
      <div className="flex justify-between">
        <Typography>{formatMoney(card.cardLimit?.usedAmount ?? 0)}</Typography>
        <Typography className="text-neutral-gray-600">
          {formatMoney(card.cardLimit?.limitAmount ?? 0)} /{' '}
          <FormattedMessage
            id="card.table.limit.duration"
            defaultMessage="{limitType, select, DAILY {Day} WEEKLY {Week} MONTHLY {Month} YEARLY {Year} other {}}"
            values={{
              limitType: card.cardLimit?.limitType,
            }}
          />
        </Typography>
      </div>
      <Progress value={progress} />
    </div>
  )
}
