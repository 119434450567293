import {
  Transaction,
  TransactionSearchParams,
} from '@/features/Transactions/types'
import { api } from '@/lib/api'

import { handleTransactionQueryParams } from '../utils'

export function getTransactions(
  params: TransactionSearchParams = {},
) {
  const searchQuery = handleTransactionQueryParams(params)

  return api.get<Transaction[]>(`/v2/me/transactions/search${searchQuery}`)
}
