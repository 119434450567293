import { FormattedMessage } from 'react-intl'

import { GoBackButton } from '@/shared/components'
import { SlideInScreen, Typography } from '@/shared/ui'
import { Country } from '@/types/country'

import { CreatedRecipient, EntityType } from '../../types'
import { RecipientForm } from '../RecipientForm'

type Props = {
  country: Country['code']
  entityType: EntityType
  recipient?: CreatedRecipient
  onBack: () => void
  onContinue: (recipient: CreatedRecipient) => void
}

export const RecipientDetailsStep = ({
  country,
  entityType,
  recipient,
  onBack,
  onContinue,
}: Props) => {
  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            defaultMessage="Account details"
            id="label.accountDetails"
          />
        </Typography>
        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            defaultMessage="Enter payment details for your recipient"
            id="recipient.add.accountDetails.subtitle"
          />
        </Typography>

        <div className="p-3" />

        <RecipientForm
          country={country}
          entityType={entityType}
          recipient={recipient}
          onContinue={onContinue}
        />
      </SlideInScreen>
    </>
  )
}
