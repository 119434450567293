import { forwardRef, Ref, type SVGProps } from 'react'

const SvgSquareCheck = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill="#E9E9E9"
      d="M7.316 3.769a42.5 42.5 0 0 1 9.367 0c1.16.13 2.177.756 2.82 1.667L11.5 13.439l-2.47-2.47a.75.75 0 1 0-1.06 1.061l3 3a.75.75 0 0 0 1.06 0l8.115-8.115q.034.164.054.334c.37 3.157.37 6.346 0 9.503-.215 1.837-1.69 3.275-3.516 3.48a42.5 42.5 0 0 1-9.367 0c-1.826-.205-3.3-1.643-3.516-3.48a41 41 0 0 1 0-9.503c.215-1.837 1.69-3.276 3.516-3.48"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgSquareCheck)
export default ForwardRef
