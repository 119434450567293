import { StatusVariant } from '@/shared/components'

export const onboardingAddressFieldsOrder = [
  'ADDRESS_STREET',
  'ADDRESS_STREET_NUMBER',
  'ADDRESS_DISTRICT',
  'ADDRESS_CITY',
  'ADDRESS_STATE',
  'ADDRESS_POST_CODE',
  'ADDRESS_COUNTRY',
] as const

export const badgeVariantByVerificationStatus: Record<string, StatusVariant> = {
  NOT_STARTED: 'neutral',
  IN_PROGRESS: 'info',
  COMPLETED: 'active',
  REJECTED: 'failed',
}
