import { FormattedMessage } from 'react-intl'

import { Typography } from '../ui'

export const OptionalTag = () => {
  return (
    <div className="absolute right-0 top-4 flex items-center pr-3">
      <Typography className="text-neutral-gray-600">
        <FormattedMessage defaultMessage="Optional" id="label.optional" />
      </Typography>
    </div>
  )
}
