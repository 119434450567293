import { Contractor } from '@/features/Contractors/types'

import { ContractorStateBadge } from '../../../../components/ContractorStateBadge'

type Props = {
  contractor: Contractor
}

export const ContractorStateCell = ({ contractor }: Props) => {
  return (
    <div className="flex items-center">
      <ContractorStateBadge state={contractor.state} />
    </div>
  )
}
