import { useMemo } from 'react'
import { useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'

import { getBusinessIdentity } from '@/api'
import { DISABLED_BUSINESS_IDS } from '@/constants/ids'
import { queryKeys } from '@/constants/queryKeys'
import { BusinessIdentity } from '@/types/business'
import { Permission } from '@/types/permissions'
import { Role } from '@/types/roles'

import { useBusinessRole } from './useBusinessRole'
import { useBusinessUser } from './useBusinessUser'

const CONTRACTORS_BUSINESS_IDS = ['c002df21-b54c-4ace-8f54-52b4d3a2a2bb']

export enum Feature {
  HOME = 'home',
  ACCOUNTS = 'accounts',
  CARDS = 'cards',
  RECIPIENTS = 'recipients',
  TRANSACTIONS = 'transactions',
  TEAM = 'team',
  TASKS = 'tasks',
  ACTIONS = 'actions',
  CONTRACTORS = 'contractors',
}

export function useFeatures(features: Feature[]) {
  const { role } = useBusinessRole()
  const businessUser = useBusinessUser()

  const [businessQuery] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getBusinessIdentity],
        queryFn: getBusinessIdentity,
        select: (data: AxiosResponse<BusinessIdentity>) => data.data,
      },
    ],
  })

  const hasCardsEnabled = useMemo(() => {
    if (!businessQuery.data) {
      return false
    }

    return !DISABLED_BUSINESS_IDS.includes(businessQuery.data.id)
  }, [businessQuery.data])

  const hasContractorsEnabled = useMemo(() => {
    if (!businessQuery.data) {
      return false
    }

    return CONTRACTORS_BUSINESS_IDS.includes(businessQuery.data.id)
  }, [businessQuery.data])

  const enabledByFeatureMap = useMemo(() => {
    if (!role) {
      return {
        [Feature.HOME]: true,
        [Feature.ACCOUNTS]: true,
        [Feature.CARDS]: false,
        [Feature.RECIPIENTS]: true,
        [Feature.TRANSACTIONS]: true,
        [Feature.TEAM]: false,
        [Feature.TASKS]: false,
        [Feature.ACTIONS]: true,
        [Feature.CONTRACTORS]: false,
      }
    }

    const BASE_ACCESS_ROLES = [Role.ADMIN, Role.READ_ONLY, Role.PAYMENT_OPS]

    return {
      [Feature.HOME]: BASE_ACCESS_ROLES.includes(role),
      [Feature.ACCOUNTS]: BASE_ACCESS_ROLES.includes(role),
      [Feature.CARDS]: hasCardsEnabled,
      [Feature.RECIPIENTS]: BASE_ACCESS_ROLES.includes(role),
      [Feature.TRANSACTIONS]: BASE_ACCESS_ROLES.includes(role),
      [Feature.TEAM]: [Role.ADMIN].includes(role),
      [Feature.TASKS]: [Role.ADMIN, Role.PAYMENT_OPS].includes(role),
      [Feature.ACTIONS]: !!businessUser?.permissions.includes(
        Permission.TRANSFERS,
      ),
      [Feature.CONTRACTORS]: hasContractorsEnabled,
    }
  }, [role, hasCardsEnabled, businessUser?.permissions, hasContractorsEnabled])

  return features.map((feature) => enabledByFeatureMap[feature])
}
