import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router-dom'

import { DASHBOARD_ROUTE } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { getAccount } from '@/features/Accounts/api'
import { formatCurrency, formatMoney } from '@/lib/money'
import { AccountIcon, GoBackButton } from '@/shared/components'
import { ChevronRight } from '@/shared/icons/outline'
import { Button, Card, Skeleton, SlideInScreen, Typography } from '@/shared/ui'

type Props = {
  onSelect: (account: string) => void
}

export const SelectAccountScreen = ({ onSelect }: Props) => {
  const location = useLocation()
  const { data, isPending } = useQuery({
    queryKey: [queryKeys.getAccount],
    queryFn: getAccount,
    select: (data) => data.data,
  })

  useEffect(() => {
    if (data?.wallets && data.wallets.length === 1) {
      const [wallet] = data.wallets

      onSelect(wallet.id)
    }
  }, [data?.wallets, onSelect])

  return (
    <>
      <GoBackButton to={location.state?.from ?? DASHBOARD_ROUTE} />

      <SlideInScreen>
        <Typography className="mx-auto max-w-sm" text="center" variant="h3">
          <FormattedMessage
            defaultMessage="Which account do you want to add balance to?"
            id="addBalance.title"
          />
        </Typography>

        <div className="p-6" />

        <div className="flex w-full flex-col justify-center gap-3">
          {isPending ? (
            <>
              {Array.from({ length: 3 }).map((_, index) => (
                <Skeleton key={index} className="h-[56px] w-full" />
              ))}
            </>
          ) : (
            data?.wallets?.map((wallet) => (
              <Card
                scalable
                asChild
                size="medium"
                key={wallet.id}
                className="grid grid-cols-3 items-center"
              >
                <Button
                  width="full"
                  size="inline"
                  variant="ghost"
                  onClick={() => onSelect(wallet.id)}
                >
                  <div className="flex items-center gap-2">
                    <AccountIcon id={wallet.id} />

                    <Typography>{wallet.label}</Typography>
                  </div>
                  <div className="justify-self-center">
                    <Typography>
                      {formatMoney(wallet.balance)}{' '}
                      {formatCurrency(wallet.currency)}
                    </Typography>
                  </div>
                  <div className="justify-self-end">
                    <ChevronRight />
                  </div>
                </Button>
              </Card>
            ))
          )}
        </div>
      </SlideInScreen>
    </>
  )
}
