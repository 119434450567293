import { defineMessage, MessageDescriptor } from 'react-intl'

import { CardType } from '@/features/Cards/types'

type CardTypeItem = {
  title: MessageDescriptor
  description: MessageDescriptor
  label: boolean
  imageName: string
  type: CardType
}

export const cardTypes: CardTypeItem[] = [
  {
    title: defineMessage({
      id: 'cards.create.virtual.title',
      defaultMessage: 'Virtual card',
    }),
    description: defineMessage({
      id: 'cards.create.virtual.description',
      defaultMessage:
        'Get it instantly for free. Ideal for subscriptions and e-commerce',
    }),
    label: true,
    imageName: 'virtual',
    type: CardType.VIRTUAL,
  },
  {
    title: defineMessage({
      id: 'cards.create.physical.title',
      defaultMessage: 'Physical card',
    }),
    description: defineMessage({
      id: 'cards.create.physical.desc',
      defaultMessage: 'Pay for corporate expenses at the best FX rates',
    }),
    label: true,
    imageName: 'physical',
    type: CardType.PHYSICAL,
  },
]
