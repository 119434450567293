import { FormattedMessage, useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'

import { COUNTRIES } from '@/constants/countries'
import { RECIPIENTS_ROUTE } from '@/constants/paths'
import { getFlagUrl } from '@/lib/images'
import { GoBackButton } from '@/shared/components'
import { ChevronRight } from '@/shared/icons/outline'
import { Button, Card, SlideInScreen, Typography } from '@/shared/ui'
import { Country } from '@/types/country'

type Props = {
  onContinue: (country: Country['code']) => void
}

export const RecipientCountryStep = ({ onContinue }: Props) => {
  const location = useLocation()
  const intl = useIntl()

  return (
    <>
      <GoBackButton to={location.state?.from ?? RECIPIENTS_ROUTE} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            defaultMessage="Recipient bank country"
            id="recipients.create.title"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            defaultMessage="Where are you sending the money to?"
            id="recipients.create.subtitle"
          />
        </Typography>

        <div className="p-6" />

        <div className="flex flex-col gap-3">
          {COUNTRIES.map((country) => {
            return (
              <Card
                size="upload"
                className="flex w-full items-center justify-between"
                key={country.code}
                asChild
                scalable
              >
                <Button
                  size="inline"
                  variant="ghost"
                  onClick={() => {
                    onContinue(country.code)
                  }}
                >
                  <div className="flex items-center gap-4">
                    <img
                      src={getFlagUrl(country.flagCode)}
                      className="size-7 shrink-0"
                      alt={intl.formatMessage(
                        {
                          id: 'country.flag.alt',
                          defaultMessage:
                            '{country, select, MEX {Mexican flag} USA {American flag} other {}}',
                        },
                        {
                          country: country.code,
                        },
                      )}
                    />

                    <div className="flex flex-col">
                      <Typography bold>
                        <FormattedMessage
                          id="country.name"
                          defaultMessage="{country, select, MEX {Mexico} USA {United States} ARG {Argentina} BRA {Brazil} COL {Colombia} CHL {Chile} URY {Uruguay} ESP {Spain} PRT {Portugal} other {}}"
                          values={{ country: country.code }}
                        />
                      </Typography>
                      <Typography className="text-neutral-gray-600">
                        <FormattedMessage
                          id="recipients.paymentMethodByCountry.description"
                          defaultMessage="{country, select, MEX {Send SPEI transfer} USA {Send Wire or ACH transfers} other {}}"
                          values={{ country: country.code }}
                        />
                      </Typography>
                    </div>
                  </div>

                  <ChevronRight />
                </Button>
              </Card>
            )
          })}
        </div>
      </SlideInScreen>
    </>
  )
}
