import { FormattedMessage } from 'react-intl'

import { Typography } from '@/shared/ui'
import { CountryCode } from '@/types/country'

import { Recipient } from '../../types'

type Props = {
  recipient: Recipient
}

export const PaymentMethodCell = ({ recipient }: Props) => {
  switch (recipient.country) {
    case CountryCode.MX:
      return (
        <div className="flex items-center">
          <Typography>SPEI</Typography>
        </div>
      )

    case CountryCode.US:
      return (
        <div className="flex items-center">
          <Typography>
            <FormattedMessage
              id="paymentMethod.label"
              defaultMessage="{method, select, ACH {ACH} WIRE {Wire} other {}}"
              values={{ method: recipient.localInformation.paymentMethod }}
            />
          </Typography>
        </div>
      )

    default:
      return null
  }
}
