import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import { CREATE_CARD_ROUTE } from '@/constants/paths'
import { getImage } from '@/lib/images'
import { WithPermissions } from '@/shared/components'
import { Plus } from '@/shared/icons/outline'
import { Button, Card, Typography } from '@/shared/ui'
import { Permission } from '@/types/permissions'

export const EmptyCardsWidget = () => {
  return (
    <Card
      className="flex w-full flex-wrap items-center justify-between gap-3 px-6 py-8 pr-6 md:flex-nowrap md:gap-0 md:py-0 md:pl-0"
      size="none"
    >
      <div className="flex flex-wrap gap-8">
        <img
          className="w-32 object-contain"
          src={getImage({
            name: 'business-cards',
            category: 'cards',
          })}
          alt=""
          aria-hidden={true}
        />
        <div className="flex flex-col justify-center gap-1">
          <Typography bold>
            <FormattedMessage
              id="dashboard.cards.empty"
              defaultMessage="You don't have any cards yet!"
            />
          </Typography>
          <Typography>
            <FormattedMessage
              id="dashboard.cards.empty.subtitle"
              defaultMessage="Create a card to get started"
            />
          </Typography>
        </div>
      </div>

      <WithPermissions permissions={[Permission.CARDS_MANAGEMENT]}>
        <Button leftIcon={<Plus className="size-5" />} asChild>
          <Link to={CREATE_CARD_ROUTE}>
            <FormattedMessage
              id="action.createACard"
              defaultMessage="Create a card"
            />
          </Link>
        </Button>
      </WithPermissions>
    </Card>
  )
}
