import { forwardRef, Ref, type SVGProps } from 'react'

const SvgEllipsis = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 18 19"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill={props.color || `currentColor`}
      stroke={props.color || `currentColor`}
      strokeWidth={1.429}
      d="M4.09 9.5a.41.41 0 1 1 .82 0 .41.41 0 0 1-.82 0Zm4.5 0a.41.41 0 1 1 .82 0 .41.41 0 0 1-.82 0Zm4.5 0a.41.41 0 1 1 .82 0 .41.41 0 0 1-.82 0Z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgEllipsis)
export default ForwardRef
