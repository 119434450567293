import { forwardRef, Ref, type SVGProps } from 'react'

const SvgCalculator = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      stroke={props.color || `currentColor`}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M8.111 19A3.11 3.11 0 0 1 5 15.889V8.111A3.11 3.11 0 0 1 8.111 5h7.778A3.11 3.11 0 0 1 19 8.111v7.778A3.11 3.11 0 0 1 15.889 19z"
      clipRule="evenodd"
    />
    <path
      stroke={props.color || `currentColor`}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M7.333 8.889v1.555c0 .43.348.778.778.778h7.778a.78.78 0 0 0 .778-.778V8.889a.78.78 0 0 0-.778-.778H8.111a.78.78 0 0 0-.778.778"
      clipRule="evenodd"
    />
    <path
      fill={props.color || `currentColor`}
      d="M15.111 12.806a.75.75 0 0 0 0 1.5zm1.556 1.5a.75.75 0 0 0 0-1.5zm-5.444-1.5a.75.75 0 0 0 0 1.5zm1.555 1.5a.75.75 0 0 0 0-1.5zm-5.444-1.5a.75.75 0 0 0 0 1.5zm1.555 1.5a.75.75 0 0 0 0-1.5zm-1.556.833a.75.75 0 0 0 0 1.5zm1.556 1.5a.75.75 0 0 0 0-1.5zm2.333-1.5a.75.75 0 0 0 0 1.5zm5.444 1.5a.75.75 0 0 0 0-1.5zm-1.555-2.333h1.556v-1.5h-1.556zm-3.888 0h1.555v-1.5h-1.555zm-3.889 0h1.555v-1.5H7.334zm-.001 2.333h1.556v-1.5H7.333zm3.889 0h5.444v-1.5h-5.444z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgCalculator)
export default ForwardRef
