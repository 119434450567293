import { defineMessage, FormattedMessage, MessageDescriptor } from 'react-intl'

import { ProgressButton } from '@/shared/components'
import { Search } from '@/shared/icons/outline'
import {
  Card,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  SlideInScreen,
  Typography,
} from '@/shared/ui'

const highRiskActivitiesMessages: Record<string, MessageDescriptor> = {
  BANK_AND_NOT_BANK_FINANCIAL_INSTITUTIONS: defineMessage({
    id: 'highRiskActivities.bankAndNotBankFinancialInstitutions',
    defaultMessage: 'Bank and non-bank financial institutions',
  }),
  CRYPTOCURRENCIES_STABLECOINS_AND_DIGITAL_ASSETS: defineMessage({
    id: 'highRiskActivities.cryptocurrenciesStablecoinsAndDigitalAssets',
    defaultMessage: 'Cryptocurrencies, stablecoins and digital assets',
  }),
  DECENTRALIZED_AUTONOMOUS_ORGANISATIONS: defineMessage({
    id: 'highRiskActivities.decentralizedAutonomousOrganizations',
    defaultMessage: 'Decentralized autonomous organizations (DAOs)',
  }),
  MONEY_SERVICE_BUSINESSES: defineMessage({
    id: 'highRiskActivities.moneyServiceBusinesses',
    defaultMessage: 'Money services businesses',
  }),
  PHARMACEUTICALS: defineMessage({
    id: 'highRiskActivities.pharmaceuticals',
    defaultMessage: 'Pharmaceuticals',
  }),
  PROFESSIONAL_SERVICE_PROVIDERS: defineMessage({
    id: 'highRiskActivities.professionalServiceProviders',
    defaultMessage: 'Professional service providers',
  }),
  THIRD_PARTY_PAYMENT_PROCESSORS_AND_TRANSMITTERS: defineMessage({
    id: 'highRiskActivities.thirdPartyPaymentProcessorsAndTransmitters',
    defaultMessage: 'Third-party payment processors and transmitters',
  }),
}

type Props = {
  activities: string[]
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
}

export const HighRiskActivitiesScreen = ({
  activities,
  isOpen,
  onOpenChange,
}: Props) => {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent hideCloseButton variant="fullscreen">
        <DialogTitle />
        <DialogDescription />
        <ProgressButton progress={0} onClick={() => onOpenChange(false)} />

        <SlideInScreen>
          <Typography text="center" variant="h3">
            <FormattedMessage
              defaultMessage="High-risk activities"
              id="highRiskActivities.screen.title"
            />
          </Typography>

          <div className="p-2" />

          <Typography text="center">
            <FormattedMessage
              defaultMessage="Read carefully through the list to answer the question about your company's activity"
              id="highRiskActivities.list.subtitle"
            />
          </Typography>

          <div className="p-6" />

          <Card size="upload" className="flex flex-col gap-2">
            <div className="flex gap-2">
              <Search className="size-5" />
              <Typography bold>
                <FormattedMessage
                  defaultMessage="High-risk activities"
                  id="highRiskActivities.screen.title"
                />
              </Typography>
            </div>
            <ul className="list-disc pl-[50px] text-neutral-gray-600">
              {activities.map((key) => (
                <li key={key}>
                  <Typography>
                    <FormattedMessage {...highRiskActivitiesMessages[key]} />
                  </Typography>
                </li>
              ))}
            </ul>
          </Card>
        </SlideInScreen>
      </DialogContent>
    </Dialog>
  )
}
