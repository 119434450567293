import { Skeleton, SlideInLeft } from '@/shared/ui'

export const CardDetailsLoader = () => {
  return (
    <SlideInLeft>
      <div className="flex justify-between">
        <Skeleton className="h-[33px] w-36" />
      </div>

      <div className="p-4" />
      <div className="flex flex-col gap-3">
        <div className="flex justify-between">
          <Skeleton className="h-[21px] w-36" />
          <Skeleton className="h-[21px] w-36" />
        </div>

        <Skeleton className="h-1.5 w-full" />
      </div>

      <div className="p-4" />

      <div className="flex flex-col justify-center">
        <Skeleton className="mx-auto h-40 w-64" />

        <div className="p-2" />

        <div className="mx-auto flex gap-3">
          <Skeleton className="h-8 w-32" />
          <Skeleton className="h-8 w-32" />
        </div>
      </div>
    </SlideInLeft>
  )
}
