import { forwardRef, Ref, type SVGProps } from 'react'

const SvgNavigation = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M19.894 12.82c.417-.234.601-.671.606-1.06.005-.39-.17-.838-.6-1.067l-.307-.165a77.4 77.4 0 0 0-14.56-5.986c-.538-.158-.984.141-1.199.498-.213.356-.264.86.008 1.291l2.925 4.632c.363.574.358 1.388-.029 1.99l-3.04 4.727a1.23 1.23 0 0 0-.031 1.29c.217.37.673.652 1.206.479a82.3 82.3 0 0 0 14.71-6.455z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgNavigation)
export default ForwardRef
