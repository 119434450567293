import { useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'

import { passwordMessages } from '@/constants/messages'
import { cn } from '@/lib/utils'
import { EyeOnOffButton } from '@/shared/components'
import { Check } from '@/shared/icons/outline'
import {
  AnimatedFormLabel,
  FormControl,
  FormField,
  FormItem,
  Input,
  Typography,
} from '@/shared/ui'

import {
  hasLowerCaseCharacter,
  hasNumber,
  hasSpecialCharacter,
  hasUpperCaseCharacter,
  PASSWORD_MIN_LENGTH,
} from '../validations'

import { PasswordSchema } from './PasswordForm'

type Props = {
  form: UseFormReturn<PasswordSchema>
}

export const PasswordFields = ({ form }: Props) => {
  const intl = useIntl()
  const [showPassword, setShowPassword] = useState(false)

  return (
    <div className="flex flex-col gap-3">
      <FormField
        control={form.control}
        name="password"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Input
                autoComplete="new-password"
                type={showPassword ? 'text' : 'password'}
                placeholder={intl.formatMessage({
                  defaultMessage: 'Password',
                  id: 'label.password',
                })}
                {...field}
              />
            </FormControl>
            <AnimatedFormLabel>
              <FormattedMessage defaultMessage="Password" id="label.password" />
            </AnimatedFormLabel>

            <EyeOnOffButton
              show={showPassword}
              onClick={() => setShowPassword((prev) => !prev)}
            />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="confirmPassword"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Input
                autoComplete="new-password"
                type={showPassword ? 'text' : 'password'}
                placeholder={intl.formatMessage({
                  defaultMessage: 'Confirm password',
                  id: 'label.confirmPassword',
                })}
                {...field}
              />
            </FormControl>
            <AnimatedFormLabel>
              <FormattedMessage
                defaultMessage="Confirm password"
                id="label.confirmPassword"
              />
            </AnimatedFormLabel>

            <PasswordRules password={form.getValues('password')} />
          </FormItem>
        )}
      />
    </div>
  )
}

export const PasswordRules = ({ password }: { password: string }) => {
  const rules = [
    {
      key: 'minLength',
      isValid: password.length >= PASSWORD_MIN_LENGTH,
    },
    {
      key: 'upperCase',
      isValid: hasUpperCaseCharacter(password),
    },
    {
      key: 'lowerCase',
      isValid: hasLowerCaseCharacter(password),
    },
    {
      key: 'hasNumber',
      isValid: hasNumber(password),
    },
    {
      key: 'hasSpecialCharacter',
      isValid: hasSpecialCharacter(password),
    },
  ] as const

  return (
    <ol className="flex list-inside flex-col pl-4 pt-4 text-sm text-neutral-gray-800 transition-all">
      {rules.map((rule) => (
        <li
          key={rule.key}
          className={cn(
            `flex h-6 items-center gap-1`,
            rule.isValid && 'font-bold text-primary',
          )}
        >
          <Typography>
            <FormattedMessage {...passwordMessages[rule.key]} />
          </Typography>
          {rule.isValid && <Check className="h-4 w-4" />}
        </li>
      ))}
    </ol>
  )
}
