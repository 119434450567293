import { useMemo } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { useIntl } from 'react-intl'

import { isSmallScreen } from '@/constants/breakpoints'
import { useMediaQuery } from '@/hooks/useMediaQuery'
import { DataTableColumnHeader } from '@/shared/ui'
import { CountryCode } from '@/types/country'

import { Recipient } from '../../types'
import { getRecipientTitle } from '../../utils'

import { AccountCell } from './AccountCell'
import { CountryCell } from './CountryCell'
import { NameCell } from './NameCell'
import { PaymentMethodCell } from './PaymentMethodCell'
import { RecipientNicknameCell } from './RecipientNicknameCell'
import { SendCell } from './SendCell'

export const useRecipientsColumns = (): ColumnDef<Recipient>[] => {
  const isMobile = useMediaQuery(isSmallScreen)
  const intl = useIntl()

  const mobileColumns: ColumnDef<Recipient>[] = useMemo(
    () => [
      {
        accessorKey: 'nickname',
        cell: ({ row }) => <RecipientNicknameCell recipient={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.nickname',
              defaultMessage: 'Nickname',
            })}
          />
        ),
      },
      {
        id: 'send',
        cell: ({ row }) => <SendCell recipient={row.original} />,
      },
    ],
    [intl],
  )

  const desktopColumns: ColumnDef<Recipient>[] = useMemo(
    () => [
      {
        accessorKey: 'nickname',
        cell: ({ row }) => <RecipientNicknameCell recipient={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.nickname',
              defaultMessage: 'Nickname',
            })}
          />
        ),
      },
      {
        id: 'recipientName',
        accessorFn: getRecipientTitle,
        cell: ({ row }) => <NameCell recipient={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.name',
              defaultMessage: 'Name',
            })}
          />
        ),
        size: 170,
      },
      {
        accessorKey: 'country',
        cell: ({ row }) => <CountryCell recipient={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.country',
              defaultMessage: 'Country',
            })}
          />
        ),
        size: 145,
      },
      {
        id: 'method',
        accessorFn: (row) => {
          switch (row.country) {
            case CountryCode.US:
              return row.localInformation.paymentMethod

            case CountryCode.MX:
              return 'SPEI'

            default:
              return undefined
          }
        },
        cell: ({ row }) => <PaymentMethodCell recipient={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.method',
              defaultMessage: 'Method',
            })}
          />
        ),
        size: 110,
      },
      {
        id: 'accountNumber',
        accessorFn: (row) => {
          switch (row.country) {
            case CountryCode.US:
              return row.localInformation.accountNumber.slice(-4)

            case CountryCode.MX:
              return row.localInformation.clabe.slice(-4)

            default:
              return undefined
          }
        },
        cell: ({ row }) => <AccountCell recipient={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.account',
              defaultMessage: 'Account',
            })}
          />
        ),
        size: 110,
      },

      {
        id: 'send',
        cell: ({ row }) => <SendCell recipient={row.original} />,
        size: 90,
      },
    ],
    [intl],
  )

  return isMobile ? mobileColumns : desktopColumns
}
