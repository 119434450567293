import { useIntl } from 'react-intl'
import { useSearchParams } from 'react-router-dom'

import { DATE_FROM_FILTER_NAME, DATE_TO_FILTER_NAME } from './data'
import { FilterBadge } from './FilterBadge'

export const DateBadge = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const intl = useIntl()

  return (
    <>
      {searchParams.get(DATE_FROM_FILTER_NAME) && (
        <FilterBadge
          onClick={() => {
            setSearchParams(
              (params) => {
                params.delete(DATE_FROM_FILTER_NAME)

                return params
              },
              { preventScrollReset: true },
            )
          }}
          name={intl.formatMessage({
            id: 'label.dateFrom',
            defaultMessage: 'Date: From',
          })}
        >
          {searchParams.get(DATE_FROM_FILTER_NAME)}
        </FilterBadge>
      )}
      {searchParams.get(DATE_TO_FILTER_NAME) && (
        <FilterBadge
          onClick={() => {
            setSearchParams(
              (params) => {
                params.delete(DATE_TO_FILTER_NAME)

                return params
              },
              { preventScrollReset: true },
            )
          }}
          name={intl.formatMessage({
            id: 'label.dateTo',
            defaultMessage: 'Date: To',
          })}
        >
          {searchParams.get(DATE_TO_FILTER_NAME)}
        </FilterBadge>
      )}
    </>
  )
}
