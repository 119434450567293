import { useMemo, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'

import { queryKeys } from '@/constants/queryKeys'
import { cn } from '@/lib/utils'
import { ChevronDown } from '@/shared/icons/outline'
import {
  Button,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  FormControl,
  FormItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  Typography,
} from '@/shared/ui'

import { getCompanyTypes } from '../api/getCompanyTypes'

type Props = {
  country?: string
  onSelect: (value: string) => void
  placeholder?: string
  value?: string
}

export const CompanyTypeCombobox = ({
  country,
  onSelect,
  placeholder,
  value,
}: Props) => {
  const intl = useIntl()
  const [open, setOpen] = useState(false)

  const companyTypesQuery = useQuery({
    queryKey: [queryKeys.getCompanyTypes, country],
    queryFn: () => getCompanyTypes(country),
    select: (data) => data.data,
    enabled: !!country,
  })

  const selected = useMemo(
    () => companyTypesQuery.data?.find((country) => country === value),
    [companyTypesQuery.data, value],
  )

  return (
    <FormItem className="flex flex-col">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <FormControl>
            <Button
              disabled={!country || companyTypesQuery.isFetching}
              width="full"
              type="button"
              variant="outline"
              role="combobox"
              className={cn(
                'h-[54px] justify-between rounded-lg px-4 font-normal hover:bg-white',
              )}
            >
              {selected ? (
                <div className="flex flex-col items-start">
                  <Typography
                    className="text-neutral-gray-600"
                    variant="body-tiny"
                  >
                    {placeholder}
                  </Typography>
                  <Typography>{selected}</Typography>
                </div>
              ) : (
                <Typography className="text-neutral-gray-600">
                  {placeholder}
                </Typography>
              )}

              {companyTypesQuery.isFetching ? (
                <Spinner />
              ) : (
                <ChevronDown className="shrink-0 text-neutral-gray-600" />
              )}
            </Button>
          </FormControl>
        </PopoverTrigger>
        <PopoverContent align="start" className="w-full p-0 md:w-[600px]">
          <Command
            filter={(value, search) => {
              const searchValue = search.toLowerCase().trim()

              if (
                value.toLowerCase().includes(searchValue.trim().toLowerCase())
              ) {
                return 1
              }

              return 0
            }}
          >
            <CommandList>
              <CommandInput
                placeholder={intl.formatMessage({
                  id: 'placeholder.searchCompanyType',
                  defaultMessage: 'Search company type',
                })}
              />
              <CommandEmpty>
                <FormattedMessage
                  id="placeholder.search.noCompanyTypeFound"
                  defaultMessage="No company type found"
                />
              </CommandEmpty>
              <CommandGroup>
                {companyTypesQuery.data?.map((type) => (
                  <CommandItem
                    key={type}
                    disabled={type === value}
                    className="h-[54px]"
                    value={type}
                    onSelect={() => {
                      onSelect(type)
                      setOpen((open) => !open)
                    }}
                  >
                    <Typography>{type}</Typography>
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </FormItem>
  )
}
