import { forwardRef, Ref, type SVGProps } from 'react'

const SvgDoubleRight = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill={props.color || `currentColor`}
      d="M10.277 18.462a.75.75 0 1 0 1.046 1.076zM18 12l.523.538a.75.75 0 0 0 0-1.076zm-6.677-7.538a.75.75 0 1 0-1.046 1.076zM5.477 17.295a.75.75 0 0 0 1.046 1.076zM12 12l.523.538a.75.75 0 0 0 0-1.076zM6.523 5.63a.75.75 0 1 0-1.046 1.075zm4.8 13.908 7.2-7-1.046-1.076-7.2 7zm7.2-8.076-7.2-7-1.046 1.076 7.2 7zm-12 6.909 6-5.833-1.046-1.076-6 5.833zm6-6.909-6-5.833-1.046 1.076 6 5.833z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgDoubleRight)
export default ForwardRef
