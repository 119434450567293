import { forwardRef, Ref, type SVGProps } from 'react'

const SvgCart = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill="#5c5c77"
      d="m8.072 19.99.003-.75zm-.755-.76-.75-.001zm.758-.756.003-.75h-.003zm.008.758v.003l-.002.003-.003.002h-.003l-.007 1.5a1.51 1.51 0 0 0 1.515-1.498zm-.008.008h-.003l-.003-.002-.002-.003v-.003l-1.5-.003a1.51 1.51 0 0 0 1.501 1.511zm-.008-.008v-.003l.002-.003.003-.002h.003v-1.5c-.832 0-1.506.673-1.508 1.505zm.005-.008h.004l.004.003.002.004.001.004 1.5.004a1.51 1.51 0 0 0-1.505-1.515zM13.381 19.237l.75.005v-.003zm-.761.753.003-.75zm-.755-.76-.75-.001zm.758-.756.003-.75h-.003zm.008.758v.003l-.002.003-.003.002h-.003l-.007 1.5a1.51 1.51 0 0 0 1.515-1.498zm-.008.008h-.003l-.003-.002-.002-.003v-.003l-1.5-.003a1.51 1.51 0 0 0 1.501 1.511zm-.008-.008v-.003l.002-.003.003-.002h.003v-1.5c-.832 0-1.506.673-1.508 1.505zm.005-.008h.004l.004.003.002.004.001.004 1.5.004a1.51 1.51 0 0 0-1.505-1.515zM14.731 14.263l.065-.747-.065-.003zm2.756-1.729-.699-.273-.002.006zm.929-2.382.699.272.014-.039zm.511-1.563-.701-.265-.012.032zm.063-.523.744-.091-.005-.035zm-11.752-.72v-.75a.75.75 0 0 0-.748.797zm.434 6.917-.748.047a.75.75 0 0 0 .748.703zm6.994.747a3.45 3.45 0 0 0 3.522-2.21l-1.402-.533a1.95 1.95 0 0 1-1.99 1.249zm3.52-2.204.929-2.382-1.398-.545-.929 2.382zm.943-2.421.51-1.563-1.425-.466-.51 1.563zm.5-1.53a1.85 1.85 0 0 0 .105-.88l-1.488.182a.35.35 0 0 1-.02.167zm.1-.915a1.53 1.53 0 0 0-.439-.867 1.63 1.63 0 0 0-.77-.4c-.449-.104-1.026-.077-1.435-.077v1.5c.268 0 .466-.005.67 0 .197.003.33.017.422.038.088.02.093.039.074.02-.027-.025-.013-.036 0 .038zm-2.644-1.344H7.238v1.5h9.847zM6.49 7.393l.434 6.917 1.497-.094-.434-6.917zm1.182 7.62h7.059v-1.5H7.672z"
    />
    <path
      fill="#5c5c77"
      d="M6.531 7.597a.75.75 0 1 0 1.414-.5zm.051-2.103-.75-.01a.8.8 0 0 0 .043.26zm-.139-.347.537-.524zM6.1 5l.01-.75H6.1zM5 4.25a.75.75 0 1 0 0 1.5zm2.724 10.761a.75.75 0 1 0-.104-1.496zm-.052 1.778-.052.748.052.002zm7.117.75a.75.75 0 0 0 0-1.5zM7.945 7.097l-.656-1.853-1.414.5.656 1.853zm-.613-1.594a1.24 1.24 0 0 0-.352-.88L5.907 5.671a.26.26 0 0 1-.075-.186zm-.352-.88a1.24 1.24 0 0 0-.87-.373l-.02 1.5a.26.26 0 0 1-.183-.08zM6.1 4.25H5v1.5h1.1zm1.52 9.265a2.016 2.016 0 0 0-1.877 2.011h1.5c0-.271.21-.496.48-.515zm-1.877 2.011c0 1.06.82 1.938 1.877 2.011l.104-1.496a.516.516 0 0 1-.48-.515zm1.929 2.013h7.117v-1.5H7.672z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgCart)
export default ForwardRef
