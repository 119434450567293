import {
  CardCategory,
  iconByCardCategory,
  sizeByCardCategory,
} from '@/features/Cards'
import { cn, parseAdditionalDetails } from '@/lib/utils'

import { CreditCard } from '../icons/outline'

type Props = {
  additionalDetails?: string
}

export const CardTransactionIcon = ({ additionalDetails }: Props) => {
  const parsedAdditionalDetails = parseAdditionalDetails<{
    merchantCategory?: string
    merchantLogoUrl?: string
  }>(additionalDetails)

  if (parsedAdditionalDetails?.merchantLogoUrl) {
    return (
      <img
        className="size-5 rounded-full object-contain"
        src={parsedAdditionalDetails.merchantLogoUrl}
        alt=""
      />
    )
  }

  if (parsedAdditionalDetails?.merchantCategory) {
    const Icon =
      iconByCardCategory[
        parsedAdditionalDetails.merchantCategory as CardCategory
      ]

    return (
      <Icon
        className={cn(
          'size-4',
          sizeByCardCategory[
            parsedAdditionalDetails.merchantCategory as CardCategory
          ],
        )}
      />
    )
  }

  return <CreditCard className="size-4" />
}
