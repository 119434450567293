import { Fragment } from 'react'
import { Big } from 'big.js'
import { FormattedMessage } from 'react-intl'

import { Currency } from '@/constants/currency'
import { formatCurrency, formatMoney } from '@/lib/money'
import { Card, Typography } from '@/shared/ui'

import { CSVTransaction } from '../types'

import { CSVTransactionRow } from './CSVTransactionRow'

type Props = {
  currency: Currency
  transactions: CSVTransaction[]
}

export const ReviewSummaryList = ({ currency, transactions }: Props) => {
  if (transactions.length === 0) {
    return null
  }

  const total = transactions.reduce((acc, transaction) => {
    return acc.plus(transaction.paymentAmount)
  }, Big(0))

  return (
    <div className="flex flex-col">
      <div className="flex justify-between px-2">
        <Typography className="text-neutral-gray-600">
          <FormattedMessage
            id="bulkPayments.reviewTransactions.transfers"
            defaultMessage="{currency} transfers • {count}"
            values={{ currency, count: transactions.length }}
          />
        </Typography>

        <Typography className="text-neutral-gray-600">
          {formatMoney(total.toFixed(2))} {formatCurrency(currency)}
        </Typography>
      </div>
      <div className="p-1" />

      <Card>
        {transactions.map((transaction, index) => (
          <Fragment key={transaction.id}>
            <CSVTransactionRow
              selectedTransactions={[]}
              transaction={transaction}
            />
            {index === transactions.length - 1 ? null : (
              <div className="border-b border-neutral-gray-300" />
            )}
          </Fragment>
        ))}
      </Card>
    </div>
  )
}
