import { defineMessage, FormattedMessage, MessageDescriptor } from 'react-intl'

import { ProgressButton } from '@/shared/components'
import { Lock } from '@/shared/icons/solid'
import {
  Card,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  SlideInScreen,
  Typography,
} from '@/shared/ui'

const prohibitedActivitiesMessages: Record<string, MessageDescriptor> = {
  ADULT_ENTERTAINMENT: defineMessage({
    id: 'prohibitedActivities.adultEntertainment',
    defaultMessage: 'Adult entertainment',
  }),
  BETTING_HOUSES_CASINOS_INTERNET_GAMBLING: defineMessage({
    id: 'prohibitedActivities.bettingHousesCasinosInternetGambling',
    defaultMessage: 'Betting houses / Casinos / Internet gambling',
  }),
  CHARITIES_NON_PROFITS: defineMessage({
    id: 'prohibitedActivities.charitiesNonProfits',
    defaultMessage: 'Charities / Non-profits',
  }),
  GAMING: defineMessage({
    id: 'prohibitedActivities.gaming',
    defaultMessage: 'Gaming',
  }),
  GOVERNMENTAL_ENTITIES: defineMessage({
    id: 'prohibitedActivities.governmentalEntities',
    defaultMessage: 'Governmental entities',
  }),
  JEWELRY_AND_PRECIOUS_STONES: defineMessage({
    id: 'prohibitedActivities.jewelryAndPreciousStones',
    defaultMessage: 'Jewelry and precious stones',
  }),
  MARIJUANA: defineMessage({
    id: 'prohibitedActivities.marijuana',
    defaultMessage: 'Marijuana',
  }),
  NUCLEAR_POWER_PLANTS: defineMessage({
    id: 'prohibitedActivities.nuclearPowerPlants',
    defaultMessage: 'Nuclear power plants',
  }),
  PRIVATE_ATMS: defineMessage({
    id: 'prohibitedActivities.privateAtms',
    defaultMessage: 'Private ATMs',
  }),
  WEAPONS: defineMessage({
    id: 'prohibitedActivities.weapons',
    defaultMessage: 'Weapons',
  }),
}

type Props = {
  activities: string[]
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
}

export const ProhibitedActivitiesScreen = ({
  activities,
  isOpen,
  onOpenChange,
}: Props) => {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent hideCloseButton variant="fullscreen">
        <DialogTitle />
        <DialogDescription />
        <ProgressButton progress={0} onClick={() => onOpenChange(false)} />

        <SlideInScreen>
          <Typography text="center" variant="h3">
            <FormattedMessage
              defaultMessage="Prohibited activities"
              id="prohibitedActivities.list.title"
            />
          </Typography>

          <div className="p-2" />

          <Typography text="center">
            <FormattedMessage
              defaultMessage="Read carefully through the list to answer the question about your company's activity"
              id="highRiskActivities.screen.subtitle"
            />
          </Typography>

          <div className="p-6" />

          <Card size="upload" className="flex flex-col gap-2">
            <div className="flex gap-2">
              <Lock className="size-5" />
              <Typography bold>
                <FormattedMessage
                  defaultMessage="Prohibited activities"
                  id="prohibitedActivities.list.title"
                />
              </Typography>
            </div>
            <ul className="list-disc pl-[50px] text-neutral-gray-600">
              {activities.map((key) => (
                <li key={key}>
                  <Typography>
                    <FormattedMessage {...prohibitedActivitiesMessages[key]} />
                  </Typography>
                </li>
              ))}
            </ul>
          </Card>
        </SlideInScreen>
      </DialogContent>
    </Dialog>
  )
}
