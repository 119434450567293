import { forwardRef, Ref, type SVGProps } from 'react'

const SvgUsers = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      stroke={props.color || `currentColor`}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M14.375 7.375a2.375 2.375 0 1 1-4.75 0 2.375 2.375 0 0 1 4.75 0M16.75 15.775c0 1.8-2.127 3.267-4.75 3.267s-4.75-1.463-4.75-3.267 2.127-3.266 4.75-3.266 4.75 1.462 4.75 3.266M19.4 9.553a1.425 1.425 0 1 1-1.425-1.4c.78-.007 1.418.62 1.425 1.4M4.6 9.553a1.425 1.425 0 1 0 1.425-1.4 1.413 1.413 0 0 0-1.425 1.4"
      clipRule="evenodd"
    />
    <path
      fill={props.color || `currentColor`}
      d="M18.717 17.362a.75.75 0 1 0-.134 1.494zm2.783-1.587.745.082a.8.8 0 0 0 0-.164zm-2.917-3.08a.75.75 0 1 0 .134 1.494zM5.417 18.856a.75.75 0 1 0-.134-1.494zM2.5 15.775l-.746-.082a.8.8 0 0 0 0 .164zm2.783-1.586a.75.75 0 1 0 .134-1.494zm13.3 4.667a3.38 3.38 0 0 0 3.663-3l-1.491-.163a1.88 1.88 0 0 1-2.038 1.669zm3.663-3.163a3.38 3.38 0 0 0-3.663-2.998l.134 1.494a1.88 1.88 0 0 1 2.038 1.668zM5.282 17.362a1.88 1.88 0 0 1-2.037-1.669l-1.492.164a3.38 3.38 0 0 0 3.663 2.999zm-2.037-1.505a1.88 1.88 0 0 1 2.037-1.668l.134-1.494a3.38 3.38 0 0 0-3.663 2.998z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgUsers)
export default ForwardRef
