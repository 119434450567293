import { api } from '@/lib/api'
import { Role } from '@/types/roles'

import { InvitedTeamMember } from '../types'

type InviteBusinessUserRequest = {
  role: Role
  firstName: string
  lastName: string
  secondLastName?: string
  userTitle?: string
  email: string
  otp?: string
}

export function inviteBusinessUser(data: InviteBusinessUserRequest) {
  return api.post<InvitedTeamMember>(`/v1/me/business-user-invitations`, data)
}
