import { getImage } from '@/lib/images'

import { Card, Typography } from '../ui'

type Props = {
  children: React.ReactNode
  image?: React.ReactNode
}

export const EmptyCard = ({ children }: Props) => {
  return (
    <Card className="flex w-full items-center justify-between" size="medium">
      <div className="flex flex-nowrap gap-8">
        <img
          className="w-12 object-contain"
          src={getImage({ name: 'empty-search' })}
          alt=""
          aria-hidden
        />
        <div className="flex flex-col gap-1">{children}</div>
      </div>
    </Card>
  )
}

const Title = ({ children }: Props) => {
  return <Typography bold>{children}</Typography>
}

const Description = ({ children }: Props) => {
  return <Typography>{children}</Typography>
}

EmptyCard.Title = Title
EmptyCard.Description = Description
