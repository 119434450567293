import { forwardRef, Ref, type SVGProps } from 'react'

const SvgFileUser = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill={props.color || `currentColor`}
      d="M12 9a2 2 0 1 0 0 4 2 2 0 0 0 0-4M8 17.5a3 3 0 0 1 3-3h2a3 3 0 0 1 3 3 1 1 0 0 1-1 1H9a1 1 0 0 1-1-1"
    />
    <path
      fill={props.color || `currentColor`}
      fillRule="evenodd"
      d="M7 2.25A2.75 2.75 0 0 0 4.25 5v14A2.75 2.75 0 0 0 7 21.75h10A2.75 2.75 0 0 0 19.75 19V8.198a1.75 1.75 0 0 0-.328-1.02L16.408 2.98a1.75 1.75 0 0 0-1.421-.73zM5.75 5c0-.69.56-1.25 1.25-1.25h7.25v4.397c0 .414.336.75.75.75h3.25V19c0 .69-.56 1.25-1.25 1.25H7c-.69 0-1.25-.56-1.25-1.25z"
      clipRule="evenodd"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgFileUser)
export default ForwardRef
