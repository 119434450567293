import { forwardRef, Ref, type SVGProps } from 'react'

const SvgBag = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill={props.color || `currentColor`}
      fillRule="evenodd"
      d="M7.25 5.461v1.42l-1.694.138a2.61 2.61 0 0 0-2.367 2.184q-.062.387-.114.775a.3.3 0 0 0 .169.308l.077.036c5.429 2.57 11.93 2.57 17.358 0l.077-.036a.3.3 0 0 0 .168-.308 27 27 0 0 0-.113-.775 2.61 2.61 0 0 0-2.367-2.184l-1.694-.137v-1.42a1.75 1.75 0 0 0-1.49-1.731l-1.22-.183a13.8 13.8 0 0 0-4.08 0l-1.22.183a1.75 1.75 0 0 0-1.49 1.73m6.567-.43a12.3 12.3 0 0 0-3.634 0l-1.22.183a.25.25 0 0 0-.213.247v1.315a57 57 0 0 1 6.5 0V5.461a.25.25 0 0 0-.213-.247z"
      clipRule="evenodd"
    />
    <path
      fill={props.color || `currentColor`}
      d="M21.118 12.07a.2.2 0 0 0-.282-.17c-5.571 2.467-12.101 2.467-17.672 0a.2.2 0 0 0-.282.17c-.102 1.912 0 3.832.307 5.727a2.61 2.61 0 0 0 2.367 2.185l1.872.15c3.043.246 6.1.246 9.144 0l1.872-.15a2.61 2.61 0 0 0 2.367-2.185c.306-1.895.41-3.815.307-5.726"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgBag)
export default ForwardRef
