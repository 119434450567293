import { api } from '@/lib/api'

type RatesWithFeesRequest = {
  from: string
  to: string
}

export type RatesWithFeesResponse = {
  fxRate: number
}

export function getRatesWithFees({ from, to }: RatesWithFeesRequest) {
  return api.get<RatesWithFeesResponse>(
    `/v1/me/withdrawal-quotes/from/${from}/to/${to}`,
  )
}
