import { FormattedMessage } from 'react-intl'

import { cn } from '@/lib/utils'

import { Download } from '../icons/solid'
import { Spinner, Typography } from '../ui'

type Props = {
  isDragActive: boolean
  files: string[]
  isPending: boolean
}

export const DragAndDropContainer = ({
  isDragActive,
  files,
  isPending,
}: Props) => {
  return (
    <div
      className={cn(
        'flex h-[84px] w-full cursor-pointer items-center justify-center rounded-lg bg-neutral-gray-100 p-3 transition-all hover:bg-neutral-gray-200',
        {
          'border border-dashed border-neutral-gray-600':
            isDragActive || files.length > 0,
          'bg-neutral-gray-200': isDragActive,
        },
      )}
    >
      <div className="flex items-center gap-2 text-neutral-gray-800">
        {isPending ? (
          <Spinner className="size-5" />
        ) : (
          <Download className="size-5" />
        )}
        <Typography>
          <FormattedMessage
            id="uploadFileField.dragOrClick"
            defaultMessage="Drag and drop or click to upload"
          />
        </Typography>
      </div>
    </div>
  )
}
