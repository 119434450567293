import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'
import { toast } from 'sonner'

import { queryKeys } from '@/constants/queryKeys'
import { deleteTeamMember } from '@/features/Team'
import { TeamMember } from '@/features/Team/types'
import { useErrorToast } from '@/hooks/useErrorToast'
import { hasOTPRequiredError } from '@/lib/error'
import { queryClient } from '@/lib/queryClient'
import { getFullName } from '@/lib/typography'
import { Button } from '@/shared/ui'

import { DeleteDialog } from '../DeleteDialog'
import { OTPDialog } from '../OTPDialog'

type Props = {
  onDelete: () => void
  member?: TeamMember
}

export const DeleteTeamMemberAction = ({ onDelete, member }: Props) => {
  const [isDeleting, setIsDeleting] = useState(false)
  const [isOTPDialogOpen, setShowOTPDialog] = useState(false)
  const notifyError = useErrorToast()
  const intl = useIntl()

  const { mutateAsync, isError, isPending, isSuccess } = useMutation({
    mutationFn: deleteTeamMember,
  })

  const onDeleteTeamMember = async (otp?: string) => {
    if (!member?.id) {
      toast.error(
        intl.formatMessage({
          id: 'team.member.details.error.missingId',
          defaultMessage: 'Missing team member ID',
        }),
      )
      return
    }

    try {
      await mutateAsync({
        id: member.id,
        otp,
      })

      await queryClient.invalidateQueries({
        queryKey: [queryKeys.getTeamMembers],
      })

      await queryClient.invalidateQueries({
        queryKey: [queryKeys.getTeamMember, member?.id],
      })

      toast.success(
        intl.formatMessage({
          id: 'team.member.details.terminated',
          defaultMessage: 'Team member terminated',
        }),
      )

      onDelete()
    } catch (error) {
      if (error instanceof Error) {
        if (hasOTPRequiredError(error)) {
          setShowOTPDialog(true)
          setIsDeleting(false)
          return
        }

        notifyError(error)
      }
    }
  }

  return (
    <>
      <Button
        width="full"
        onClick={() => setIsDeleting(true)}
        type="button"
        variant="ghost"
        className="text-primary-error"
      >
        <FormattedMessage
          defaultMessage="Delete team member"
          id="action.deleteTeamMember"
        />
      </Button>

      <DeleteDialog
        isOpen={isDeleting}
        onOpenChange={setIsDeleting}
        isPending={isPending}
        onDelete={() => onDeleteTeamMember()}
        title={
          <FormattedMessage
            defaultMessage="Delete {name}?"
            id="teamMember.delete.dialog.title"
            values={{
              name: member?.firstName,
            }}
          />
        }
        description={
          <FormattedMessage
            defaultMessage="Are you sure you want to delete {fullName}?"
            id="teamMember.delete.dialog.deleteCard"
            values={{
              fullName: getFullName(member),
            }}
          />
        }
        descriptionAdditional={
          <FormattedMessage
            defaultMessage="The user will be terminated permanently"
            id="teamMember.delete.dialog.userTerminated"
          />
        }
      />

      <OTPDialog
        isOpen={isOTPDialogOpen}
        onOpenChange={setShowOTPDialog}
        isPending={isPending || isSuccess}
        isError={isError}
        title={intl.formatMessage({
          id: 'teamMember.delete.dialog.otp.title',
          defaultMessage: 'Enter verification code to confirm deletion',
        })}
        description={intl.formatMessage(
          {
            id: 'teamMember.delete.dialog.otp.description',
            defaultMessage:
              'We have sent you an email with a verification code. Enter it to permanently terminate {fullName}',
          },
          { fullName: getFullName(member) },
        )}
        onContinue={(otp) => {
          onDeleteTeamMember(otp)
        }}
      />
    </>
  )
}
