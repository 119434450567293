import {
  CashbackTransaction,
  TransactionSearchParams,
} from '@/features/Transactions/types'
import { api } from '@/lib/api'

import { handleTransactionQueryParams } from '../utils'

export function getCashbackTransactions({
  id,
  params = {},
}: {
  id: string
  params?: TransactionSearchParams
}) {
  const searchQuery = handleTransactionQueryParams(params)

  return api.get<CashbackTransaction>(
    `v1/me/cashbacks/transaction/${id}${searchQuery}`,
  )
}
