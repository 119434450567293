import { useIntl } from 'react-intl'

import { Badge, Typography } from '@/shared/ui'

import { csvParseErrorMessages } from '../constants'
import { CSVTransaction } from '../types'
import { getName } from '../utils'

import { CSVTransactionDetailsRow } from './CSVTransactionDetailsRow'

type Props = {
  transaction: CSVTransaction
  selectedTransactions?: string[]
  onSelect?: (idx: string[]) => void
}

export const CSVFailedTransactionRow = ({ transaction }: Props) => {
  const intl = useIntl()

  const errorMessage = csvParseErrorMessages[transaction.failureReasons[0]]
    ? intl.formatMessage(csvParseErrorMessages[transaction.failureReasons[0]])
    : intl.formatMessage({
        id: 'csvParseError.unknownError',
        defaultMessage: 'Unknown error',
      })

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-4">
        <div className="flex flex-col gap-1 py-2">
          <Typography bold>
            {getName(transaction.paymentDetails) ?? (
              <span className="inline-flex" />
            )}
          </Typography>

          <CSVTransactionDetailsRow transaction={transaction} />
        </div>
      </div>

      {transaction.failureReasons.length > 0 ? (
        <Badge variant="destructive">{errorMessage}</Badge>
      ) : null}
    </div>
  )
}
