import { forwardRef, Ref, type SVGProps } from 'react'

const SvgArrowLeft = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill={props.color || `currentColor`}
      d="M11.03 8.53a.75.75 0 1 0-1.06-1.06l-4 4a.75.75 0 0 0 0 1.06l4 4a.75.75 0 1 0 1.06-1.06l-2.72-2.72H18a.75.75 0 0 0 0-1.5H8.31z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgArrowLeft)
export default ForwardRef
