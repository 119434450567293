import { useMutation } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'
import { toast } from 'sonner'

import { queryKeys } from '@/constants/queryKeys'
import { useErrorToast } from '@/hooks/useErrorToast'
import { queryClient } from '@/lib/queryClient'
import { removeEmptyFormFields } from '@/lib/utils'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
  Button,
  Skeleton,
  SlideInLeft,
  Typography,
} from '@/shared/ui'

import { editRecipient } from '../api'
import { CreatedRecipient, CreatedRecipientWithId, Recipient } from '../types'

import { ScreenType } from './RecipientDetailsSidebar'
import { RecipientForm } from './RecipientForm'

type Props = {
  onClose: () => void
  recipient?: Recipient
  setScreen: (screen: ScreenType) => void
}

export const EditRecipient = ({ onClose, recipient, setScreen }: Props) => {
  const notifyError = useErrorToast()
  const intl = useIntl()

  const { mutateAsync, isPending, isSuccess } = useMutation({
    mutationFn: editRecipient,
  })

  const onEditRecipient = async (data: CreatedRecipientWithId) => {
    try {
      removeEmptyFormFields(data)

      await mutateAsync(data)

      await queryClient.invalidateQueries({
        queryKey: [queryKeys.getRecipients],
      })

      await queryClient.invalidateQueries({
        queryKey: [queryKeys.getSingleRecipient, data.id],
      })

      toast.success(
        intl.formatMessage({
          id: 'recipient.edit.success',
          defaultMessage: 'Recipient edited successfully',
        }),
      )

      setScreen('base')
    } catch (error) {
      if (error instanceof Error) {
        notifyError(error)
      }
    }
  }

  return (
    <SlideInLeft className="flex h-full flex-col">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbLink asChild>
            <Button
              onClick={() => setScreen('base')}
              variant="ghost"
              size="inline"
            >
              {recipient ? (
                <Typography>{recipient.nickname}</Typography>
              ) : (
                <Skeleton className="h-6 w-36" />
              )}
            </Button>
          </BreadcrumbLink>

          <BreadcrumbSeparator />

          <BreadcrumbItem>
            <Typography>
              <FormattedMessage
                id="recipient.sidebar.edit"
                defaultMessage="Edit recipient"
              />
            </Typography>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <div className="p-4" />

      <Typography variant="h3">
        <FormattedMessage
          id="recipient.sidebar.edit"
          defaultMessage="Edit recipient"
        />
      </Typography>

      <div className="p-2" />

      <Typography>
        <FormattedMessage
          id="recipient.sidebar.edit.description"
          defaultMessage="Modify the recipient's details. Make sure you review it carefully"
        />
      </Typography>

      <div className="p-4" />

      {recipient ? (
        <RecipientForm
          isPending={isPending || isSuccess}
          layout="edit"
          recipient={recipient as CreatedRecipient}
          country={recipient.country}
          entityType={recipient.entityType}
          onDelete={() => {
            setScreen('base')
            onClose()
          }}
          onContinue={(data) =>
            onEditRecipient({
              ...data,
              id: recipient.id,
            })
          }
        />
      ) : null}
    </SlideInLeft>
  )
}
