import { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { useCountryCodes } from '@/hooks/useCountryCodes'
import { ProgressButton } from '@/shared/components'
import { Lock } from '@/shared/icons/solid'
import {
  Card,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  SlideInScreen,
  Typography,
} from '@/shared/ui'

type Props = {
  countries: string[]
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
}

export const ProhibitedCountriesScreen = ({
  countries,
  isOpen,
  onOpenChange,
}: Props) => {
  const countriesQuery = useCountryCodes({ showAllCountries: true })

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent hideCloseButton variant="fullscreen">
        <DialogTitle />
        <DialogDescription />
        <ProgressButton progress={0} onClick={() => onOpenChange(false)} />

        <SlideInScreen>
          <Typography text="center" variant="h3">
            <FormattedMessage
              defaultMessage="High risk and prohibited countries"
              id="prohibitedCountries.screen.title"
            />
          </Typography>

          <div className="p-2" />

          <Typography text="center">
            <FormattedMessage
              defaultMessage="Read carefully through the list to answer the question about your company's activity"
              id="highRiskActivities.screen.subtitle"
            />
          </Typography>

          <div className="p-6" />

          <Card size="upload" className="flex flex-col gap-2">
            <div className="flex gap-2">
              <Lock className="size-5" />
              <Typography bold>
                <FormattedMessage
                  defaultMessage="Prohibited countries"
                  id="prohibitedCountries.list.title"
                />
              </Typography>
            </div>
            <ul className="list-disc pl-[50px] text-neutral-gray-600">
              {countries.map((key) => {
                const country = countriesQuery.data?.find(
                  (c) => c.isoCode === key,
                )

                return (
                  <Fragment key={key}>
                    <li className="flex gap-1">
                      {country?.flag}
                      <Typography>{country?.nameToShow}</Typography>
                    </li>
                  </Fragment>
                )
              })}
            </ul>
          </Card>

          <div className="p-8" />
        </SlideInScreen>
      </DialogContent>
    </Dialog>
  )
}
