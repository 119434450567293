import { forwardRef, Ref, type SVGProps } from 'react'

const SvgDownload = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill={props.color || `currentColor`}
      fillRule="evenodd"
      d="M10.738 3.25c-.774 0-1.419.591-1.487 1.362a37 37 0 0 0-.107 4.845l-.253.018-1.49.108a1.26 1.26 0 0 0-.97 1.935 16.1 16.1 0 0 0 4.163 4.395l.596.429a1.39 1.39 0 0 0 1.62 0l.596-.429a16 16 0 0 0 4.163-4.395 1.26 1.26 0 0 0-.97-1.935l-1.49-.108-.253-.018c.07-1.615.034-3.234-.107-4.845a1.49 1.49 0 0 0-1.487-1.362zm-.056 6.865a35.6 35.6 0 0 1 .063-5.365h2.51a35.6 35.6 0 0 1 .064 5.365.75.75 0 0 0 .711.796q.486.024.97.06l1.081.079a14.6 14.6 0 0 1-3.55 3.645l-.53.381-.532-.381a14.6 14.6 0 0 1-3.55-3.645L9 10.97q.485-.035.97-.059a.75.75 0 0 0 .712-.796"
      clipRule="evenodd"
    />
    <path
      fill={props.color || `currentColor`}
      d="M5.75 17a.75.75 0 0 0-1.5 0v2c0 .966.784 1.75 1.75 1.75h12A1.75 1.75 0 0 0 19.75 19v-2a.75.75 0 0 0-1.5 0v2a.25.25 0 0 1-.25.25H6a.25.25 0 0 1-.25-.25z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgDownload)
export default ForwardRef
