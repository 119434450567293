import { api } from '@/lib/api'

export type SignInRequest = {
  identityId: string
  otp: string
}

type SignInResponse = {
  refreshToken: string
}

export function signIn(data: SignInRequest) {
  return api.post<SignInResponse>('/v1/signin', data)
}
