import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useIntl } from 'react-intl'

import { getIdentity } from '@/api'
import { queryKeys } from '@/constants/queryKeys'
import { Typography } from '@/shared/ui'

import { OnboardingMember, OnboardingMemberRole } from '../../types'

type Props = {
  member: OnboardingMember
}

export const TaskCell = ({ member }: Props) => {
  const intl = useIntl()

  const userQuery = useQuery({
    queryKey: [queryKeys.getIdentity],
    queryFn: getIdentity,
    select: (data) => data.data,
  })

  const isUser = useMemo(
    () => member.email === userQuery.data?.email,
    [member.email, userQuery.data?.email],
  )

  const taskByRole = useMemo(() => {
    if (isUser) {
      return intl.formatMessage({
        id: 'identityValidation.task.user',
        defaultMessage: `Verify your identity. You will need your valid legal ID!`,
      })
    }

    if (member.roles.includes(OnboardingMemberRole.LEGAL_REPRESENTATIVE)) {
      return intl.formatMessage(
        {
          id: 'identityValidation.task.legalRep',
          defaultMessage:
            'Share the link with {name} to validate identity. They will need a valid legal ID!',
        },
        {
          name: member.firstName,
        },
      )
    }

    if (member.roles.includes(OnboardingMemberRole.BENEFICIAL_OWNER)) {
      return intl.formatMessage(
        {
          id: 'identityValidation.task.owner',
          defaultMessage: `Upload a picture of {name}'s ID or invite {name} to do it via email`,
        },
        {
          name: member.firstName,
        },
      )
    }
  }, [intl, isUser, member.firstName, member.roles])

  return (
    <div className="flex items-center pr-4">
      <Typography className="text-neutral-gray-600">{taskByRole}</Typography>
    </div>
  )
}
