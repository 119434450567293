import { forwardRef, Ref, type SVGProps } from 'react'

const SvgArrowTrendUp = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill={props.color || `currentColor`}
      d="M15 7.75a.75.75 0 0 0 0 1.5zm4 1.5a.75.75 0 0 0 0-1.5zm.75-.75a.75.75 0 0 0-1.5 0zm-1.5 4a.75.75 0 0 0 1.5 0zm1.28-3.47a.75.75 0 0 0-1.06-1.06zM12 15.5l-.53.53a.75.75 0 0 0 1.06 0zm-3-3 .53-.53a.75.75 0 0 0-1.06 0zm-4.53 3.47a.75.75 0 1 0 1.06 1.06zM15 9.25h4v-1.5h-4zm3.25-.75v4h1.5v-4zm.22-.53-7 7 1.06 1.06 7-7zm-5.94 7-3-3-1.06 1.06 3 3zm-4.06-3-4 4 1.06 1.06 4-4z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgArrowTrendUp)
export default ForwardRef
