import { CreatedRecipient, EntityType, Recipient } from '../types'

export function getRecipientName(recipient?: Recipient | CreatedRecipient) {
  if (!recipient) {
    return undefined
  }

  if (
    recipient.entityType === EntityType.INDIVIDUAL &&
    recipient.firstName &&
    recipient.lastName
  ) {
    return `${recipient.firstName} ${recipient.lastName}`
  }

  if (recipient.email) {
    return recipient.email
  }

  return undefined
}

export function getRecipientTitle(recipient?: Recipient | CreatedRecipient) {
  if (!recipient) {
    return undefined
  }

  switch (recipient.entityType) {
    case EntityType.INDIVIDUAL:
      return getRecipientName(recipient)

    case EntityType.BUSINESS:
      return recipient.legalName
        ? recipient.legalName
        : getRecipientName(recipient)

    default:
      return undefined
  }
}

export function isValidClabe(clabe: string) {
  return clabe.length === 18
}

export function isValidRoutingNumber(routingNumber: string) {
  return routingNumber.length === 9
}
