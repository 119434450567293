import { api } from '@/lib/api'

import { Card } from '../types'

type TerminateCardRequest = {
  id: string
  otp?: string
}

export function terminateCard(data: TerminateCardRequest) {
  return api.post<Card>(
    `/v1/me/cards/${data.id}/terminate`,
    data.otp ? { otp: data.otp } : {},
  )
}
