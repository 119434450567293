import { api } from '@/lib/api'
import { STATUS_FILTER_NAME, TEAM_ROLE_FILTER_NAME } from '@/shared/components'

import { TeamMember } from '../types'

type SearchParams = {
  [STATUS_FILTER_NAME]?: string
  [TEAM_ROLE_FILTER_NAME]?: string
}

export function getTeamMembers(params: SearchParams = {}) {
  const searchParams = new URLSearchParams(params)
  const searchQuery = searchParams.size > 0 ? '?' + searchParams.toString() : ''

  return api.get<TeamMember[]>(`/v1/me/business-users/search${searchQuery}`)
}
