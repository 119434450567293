import { useQuery } from '@tanstack/react-query'

import { queryKeys } from '@/constants/queryKeys'
import { getAccount } from '@/features/Accounts/api'
import { Card, CardState } from '@/features/Cards'
import { cn } from '@/lib/utils'
import { AccountIcon } from '@/shared/components'
import { Skeleton, Typography } from '@/shared/ui'

type Props = {
  card: Card
}

export const CardWalletCell = ({ card }: Props) => {
  const { data, isPending } = useQuery({
    queryKey: [queryKeys.getAccount],
    queryFn: getAccount,
    select: (data) =>
      data?.data.wallets.find((wallet) => wallet.id === card.walletId),
  })

  const isFailed = card.state === CardState.TERMINATED

  return (
    <div className="flex max-w-36 items-center gap-2">
      {isPending ? (
        <Skeleton className="h-8 w-36" />
      ) : (
        <>
          <AccountIcon id={data?.id ?? ''} />
          <Typography
            title={data?.label}
            className={cn('line-clamp-1', isFailed && 'line-through')}
          >
            {data?.label}
          </Typography>
        </>
      )}
    </div>
  )
}
