import { FormattedMessage } from 'react-intl'

import { Recipient } from '@/features/Recipients/types'
import { Details } from '@/shared/ui'
import { CountryCode } from '@/types/country'

type Props = {
  recipient?: Recipient
}

export const ReviewPaymentDetails = ({ recipient }: Props) => {
  switch (recipient?.country) {
    case CountryCode.MX:
      return (
        <Details>
          <Details.Label>
            <FormattedMessage defaultMessage="CLABE" id="label.clabe" />
          </Details.Label>
          {recipient ? (
            <Details.Value masked>
              {recipient.localInformation.clabe}
            </Details.Value>
          ) : (
            <Details.Skeleton />
          )}
        </Details>
      )

    case CountryCode.US:
      return (
        <>
          <Details>
            <Details.Label>
              <FormattedMessage
                defaultMessage="ABA / Routing number"
                id="label.routingNumber"
              />
            </Details.Label>
            {recipient ? (
              <Details.Value>
                {recipient.localInformation.routingNumber}
              </Details.Value>
            ) : (
              <Details.Skeleton />
            )}
          </Details>
          <Details>
            <Details.Label>
              <FormattedMessage
                defaultMessage="Account number"
                id="label.accountNumber"
              />
            </Details.Label>
            {recipient ? (
              <Details.Value masked>
                {recipient.localInformation.accountNumber}
              </Details.Value>
            ) : (
              <Details.Skeleton />
            )}
          </Details>
        </>
      )

    default:
      return null
  }
}
