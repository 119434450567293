import { Currency } from '@/constants/currency'
import {
  ACCOUNTS_FILTER_NAME,
  CARDS_ID_FILTER_NAME,
  CURRENCY_FILTER_NAME,
  DATE_FROM_FILTER_NAME,
  DATE_TO_FILTER_NAME,
  SEARCH_FILTER_NAME,
  STATUS_FILTER_NAME,
  TYPE_FILTER_NAME,
} from '@/shared/components'

export enum DisplayableType {
  BULK = 'BULK',
  SINGLE = 'SINGLE',
}

export enum TransactionStatus {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  REVERTED = 'REVERTED',
  BLOCKED = 'BLOCKED',
}

export enum BulkTransactionStatus {
  COMPLETED = 'COMPLETED',
  INCOMPLETE = 'INCOMPLETE',
  PENDING = 'PENDING',
}

export enum TransactionType {
  CARD_PAYMENT = 'CARD_PAYMENT',
  DEPOSIT = 'DEPOSIT',
  INTERNAL_SWAP_DEPOSIT = 'INTERNAL_SWAP_DEPOSIT',
  INTERNAL_SWAP_WITHDRAW = 'INTERNAL_SWAP_WITHDRAW',
  REWARD = 'REWARD',
  FEE = 'FEE',
  CARD_PAYMENT_REFUND = 'CARD_PAYMENT_REFUND',
  WITHDRAW = 'WITHDRAW',
  WITHDRAW_REFUND = 'WITHDRAW_REFUND',
  CARD_PAYMENT_CHARGEBACK = 'CARD_PAYMENT_CHARGEBACK',
  CASHBACK = 'CASHBACK',
  INCOMING_DIRECT_DEBIT = 'INCOMING_DIRECT_DEBIT',
}

export type SingleTransaction = {
  displayableType: DisplayableType.SINGLE
  accountId: string
  // BE returns it as string cause it can contain any amount of additional data, use parseAdditionalDetails helper
  additionalDetails: string
  baseAmount: number
  baseCurrency: Currency
  createdOn: string
  description: string
  feeAmount: number
  feeCurrency: Currency
  fxRate: number
  id: string
  localAmount: number
  localCurrency: Currency
  name: string
  provider: string
  transactionStatus: TransactionStatus
  transactionType: TransactionType
  postTransactionBalance?: number | null
  relatedTransactionId?: string
  groupId?: string
  completedAt?: string | null
  providerRef?: string
  updatedOn: string
  walletId: string
  visible?: boolean
}

export type BulkTransaction = {
  displayableType: DisplayableType.BULK
  accountId: string
  bulkTransactionLabel: string
  bulkTransactionStatus: BulkTransactionStatus
  createdOn: string
  failedTransactions: SingleTransaction[]
  groupId: string
  localCurrencies: Currency[]
  totalBaseAmount: number
  totalBaseCurrency: Currency
  totalFeeAmount: number
  totalFeeCurrency: Currency
  transactions: SingleTransaction[]
  walletId: string
}

export type Transaction = SingleTransaction | BulkTransaction

export type TransactionSearchParams = {
  [ACCOUNTS_FILTER_NAME]?: string
  [TYPE_FILTER_NAME]?: string
  [CURRENCY_FILTER_NAME]?: string
  [STATUS_FILTER_NAME]?: string
  [DATE_FROM_FILTER_NAME]?: string
  [DATE_TO_FILTER_NAME]?: string
  [CARDS_ID_FILTER_NAME]?: string
  [SEARCH_FILTER_NAME]?: string
}

export type CashbackTransaction = {
  id: string
  businessId: string
  walletId: string
  accountId: string
  transactionId: string
  total: { amount: number; currency: Currency }
  totalCardPayment: { amount: number; currency: Currency }
  eligibleTransactions: SingleTransaction[]
  paymentDate: string
  standardRate: number
  standardCashback: { amount: number; currency: Currency }
}
