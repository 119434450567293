import { api } from '@/lib/api'

export type UpdatePasswordRequest = {
  identityId: string
  otp: string
  newPassword: string
}

export function updatePassword(data: UpdatePasswordRequest) {
  return api.post<void>('/v1/password-change', data)
}
