import { forwardRef, Ref, type SVGProps } from 'react'

const SvgArrowCross = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill={props.color || `currentColor`}
      d="M14.828 4.75a.75.75 0 0 0 0 1.5zM19 6.25a.75.75 0 0 0 0-1.5zm.53-.22a.75.75 0 0 0-1.06-1.06zM4.47 18.97a.75.75 0 1 0 1.06 1.06zM19.75 5.5a.75.75 0 0 0-1.5 0zm-1.5 4.172a.75.75 0 0 0 1.5 0zm-3.422 9.078a.75.75 0 0 0 0 1.5zM19 20.25a.75.75 0 0 0 0-1.5zm-.53-.22a.75.75 0 1 0 1.06-1.06zM5.53 4.97a.75.75 0 0 0-1.06 1.06zM18.25 19.5a.75.75 0 0 0 1.5 0zm1.5-4.172a.75.75 0 0 0-1.5 0zM14.828 6.25H19v-1.5h-4.172zm3.642-1.28-14 14 1.06 1.06 14-14zm-.22.53v4.172h1.5V5.5zm-3.422 14.75H19v-1.5h-4.172zm4.702-1.28-14-14-1.06 1.06 14 14zm.22.53v-4.172h-1.5V19.5z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgArrowCross)
export default ForwardRef
