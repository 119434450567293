import forge from 'node-forge'

import { sessionStorageKeys } from '@/constants/keys'

export function hashSHA256Fingerprint(fingerprint: string) {
  return crypto.subtle.digest('SHA-256', new TextEncoder().encode(fingerprint))
}

export async function generateFingerprint() {
  const { language, languages } = navigator

  const fingerprint = {
    language,
    languages,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  }

  const hash = await hashSHA256Fingerprint(JSON.stringify(fingerprint))

  const response = Array.from(new Uint8Array(hash))

  const hashed = response.map((b) => b.toString(16).padStart(2, '0')).join('')

  return hashed
}

export function cleanupPublicKey(key: string) {
  return key
    .replace(/-----BEGIN PUBLIC KEY-----/, '')
    .replace(/-----END PUBLIC KEY-----/, '')
    .replace(/\s+/g, '')
}

export async function generateRSAKeyPairForCards() {
  const publicKeyFromStorage = sessionStorage.getItem(
    sessionStorageKeys.cardsDetailsPublicKey,
  )

  const privateKeyFromStorage = sessionStorage.getItem(
    sessionStorageKeys.cardsDetailsPrivateKey,
  )

  if (publicKeyFromStorage && privateKeyFromStorage) {
    return
  }

  const rsa = forge.pki.rsa

  rsa.generateKeyPair({ bits: 2048, workers: 2 }, (err, pair) => {
    if (err) {
      console.error(err)
      return
    }

    const publicKey = forge.pki.publicKeyToPem(pair.publicKey)
    const privateKey = forge.pki.privateKeyToPem(pair.privateKey)

    sessionStorage.setItem(sessionStorageKeys.cardsDetailsPublicKey, publicKey)
    sessionStorage.setItem(
      sessionStorageKeys.cardsDetailsPrivateKey,
      privateKey,
    )
  })
}
