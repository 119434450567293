import { useEffect, useState } from 'react'

import { Sheet, SheetContent } from '@/shared/ui'

import { Card, CardState } from '../types'

import { BaseCardDetails } from './BaseCardDetails'
import { CardDelivery } from './CardDelivery'
import { CardDetailsLoader } from './CardDetailsLoader'
import { CardInformation } from './CardInformation'
import { CardPIN } from './CardPIN'
import { CardStatements } from './CardStatements'
import { EditCard } from './EditCard'

export type ScreenType =
  | { type: 'base' }
  | { type: 'info' }
  | { type: 'terms' }
  | { type: 'statements' }
  | { type: 'edit' }
  | { type: 'pin'; pin?: string }
  | { type: 'delivery' }

type Props = {
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  card?: Card
}

export const CardDetailsSidebar = ({ isOpen, onOpenChange, card }: Props) => {
  const [screen, setScreen] = useState<ScreenType>()

  useEffect(() => {
    if (!card) {
      return
    }

    if (card?.state === CardState.CREATED) {
      setScreen({ type: 'delivery' })
      return
    }

    setScreen({ type: 'base' })
  }, [card, card?.state])

  useEffect(() => {
    if (!isOpen) {
      setScreen(undefined)
    }
  }, [isOpen])

  return (
    <Sheet open={isOpen} onOpenChange={onOpenChange}>
      <SheetContent>
        {(() => {
          switch (screen?.type) {
            case 'base':
              return <BaseCardDetails card={card} setScreen={setScreen} />

            case 'info':
              return <CardInformation card={card} setScreen={setScreen} />

            case 'statements':
              return <CardStatements card={card} setScreen={setScreen} />

            case 'edit':
              return <EditCard card={card} setScreen={setScreen} />

            case 'pin':
              return (
                <CardPIN pin={screen.pin} card={card} setScreen={setScreen} />
              )

            case 'delivery':
              return <CardDelivery card={card} setScreen={setScreen} />

            default:
              return <CardDetailsLoader />
          }
        })()}
      </SheetContent>
    </Sheet>
  )
}
