import { forwardRef, Ref, type SVGProps } from 'react'

const SvgDolarapp = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 12 10"
    role="img"
    ref={ref}
    {...props}
  >
    <g fill="#1F1F1F" clipPath="url(#dolarapp_svg__a)">
      <path d="M11.994.588c0-.185-.055-.3-.325-.3-2.16 0-4.373.498-5.48.974v.83c.947-.424 2.322-.762 3.759-.922.044.278.166.538.351.748.286.327.702.421.918.447l.003 3.426a1.5 1.5 0 0 0-.915.458c-.366.372-.407.864-.407 1.062-2.623.428-3.965 1.22-4.148 1.024-.264.26-.538.534-.386.727.415.524 1.621-.818 6.335-1.175.22-.017.3-.09.3-.29 0-.001-.005-5.384-.005-7.01" />
      <path d="M6.104.496C5.875.246 4.452-.14.207 1.571A.33.33 0 0 0 0 1.865S.004 6.644.004 8.6c0 .263.176.323.41.249C1.76 8.42 3.539 7.688 4.917 7.604c.794-.048 1.125.013 1.207.137a.01.01 0 0 0 .02-.007v-.587c0-.289-.527-.363-1.33-.296-.87.073-1.922.357-2.663.586a1.07 1.07 0 0 0-.554-.747 1.36 1.36 0 0 0-.815-.156c0-1.113-.002-2.335-.003-3.25.224-.09.623-.291.855-.644a1.9 1.9 0 0 0 .295-.88c1.088-.377 2.364-.738 3.273-.738.774 0 .908.14.987.239 0-.26.034-.636-.085-.765" />
      <path d="M6.192 2.79s-.01-.256-.01-.565c0-.296-.26-.4-.466-.411-.972-.059-1.219 1.12-1.19 2.069.025.858.29 1.331.762 1.683.478.355.863.18.89 1.296.836-.005 1.528-1.044 1.518-2.218-.008-1.06-.656-1.855-1.504-1.854" />
    </g>
    <defs>
      <clipPath id="dolarapp_svg__a">
        <path fill="#fff" d="M0 0h12v9.461H0z" />
      </clipPath>
    </defs>
  </svg>
)
const ForwardRef = forwardRef(SvgDolarapp)
export default ForwardRef
