import { useEffect } from 'react'
import * as Sentry from '@sentry/react'
import { AxiosError, isAxiosError } from 'axios'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

import { isProduction } from './lib/utils'

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  tracesSampleRate: 0.1,
  tracePropagationTargets: [
    /^https:\/\/api\.business\.dolarapp\.com/,
    /^https:\/\/api\.business\.dolarapp\.dev/,
  ],
  beforeSend: (event, hint) => {
    if (!isProduction()) {
      return null
    }

    handleSentryBeforeSend(event, hint)

    return event
  },

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
})

function handleSentryBeforeSend(event: Sentry.Event, hint: Sentry.EventHint) {
  addAxiosContextRecursive(event, hint?.originalException)
}

function addAxiosContextRecursive(event: Sentry.Event, error: unknown) {
  if (isAxiosError(error)) {
    addAxiosContext(event, error)
  } else if (error instanceof Error) {
    addAxiosContextRecursive(event, error)
  }
}

function addAxiosContext(event: Sentry.Event, error: AxiosError) {
  if (error.response) {
    const contexts = { ...event.contexts }
    contexts.Axios = { Response: error.response.data }
    event.contexts = contexts
  }
}
