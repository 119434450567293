import { Currency } from '@/constants/currency'
import {
  BulkTransaction,
  BulkTransactionStatus,
  DisplayableType,
  SingleTransaction,
  Transaction,
  TransactionStatus,
  TransactionType,
} from '@/features/Transactions/types'

export const SEND_TRANSACTION: SingleTransaction = {
  displayableType: DisplayableType.SINGLE,
  id: 'cc205b8e-6d99-4926-8d35-30d658fa34ce',
  walletId: '0ceaf1d2-20b6-4de0-8e74-ad514f0df81c',
  accountId: '64eb0105-8484-4f38-ba20-91c27159ee2c',
  transactionType: TransactionType.WITHDRAW,
  transactionStatus: TransactionStatus.COMPLETED,
  description: '',
  name: 'Test account 1',
  provider: 'SYNAPSE',
  baseAmount: -1000,
  baseCurrency: Currency.USDC,
  localAmount: -1000,
  localCurrency: Currency.USD,
  feeAmount: 0,
  feeCurrency: Currency.USDC,
  fxRate: 1,
  visible: true,
  additionalDetails:
    '{"transferType":"INTERNAL","beneficiaryId":"aa8be62e-b6e3-4d61-9c52-9118871db8b1","deviceIp":"193.42.98.98","beneficiaryAccountNumber":"9817123411","beneficiaryRoutingNumber":"084106768","beneficiaryPaymentMethod":"WIRE"}',
  createdOn: '2024-02-23T12:17:30.494684',
  updatedOn: '2024-02-23T12:20:00.373863',
}

export const MOCKED_TRANSACTIONS: Transaction[] = [
  {
    displayableType: DisplayableType.BULK,
    groupId: 'f530af46-ef8e-4eeb-8c19-fd3f08459381',
    walletId: 'f2c1bb53-4d86-4cb7-aa48-8438b3ed489d',
    accountId: '64eb0105-8484-4f38-ba20-91c27159ee2c',
    createdOn: '2024-03-20T15:20:28.981802',
    bulkTransactionStatus: BulkTransactionStatus.INCOMPLETE,
    bulkTransactionLabel: 'Contractors Marzo',
    totalBaseAmount: -788.3054519438,
    totalBaseCurrency: Currency.USDC,
    totalFeeAmount: 0,
    totalFeeCurrency: Currency.USDC,
    localCurrencies: [Currency.MXN, Currency.USD],
    transactions: [
      {
        displayableType: DisplayableType.SINGLE,
        id: '5ed4a18a-63c2-43d5-85f9-74459ce4845e',
        walletId: 'f2c1bb53-4d86-4cb7-aa48-8438b3ed489d',
        accountId: '64eb0105-8484-4f38-ba20-91c27159ee2c',
        transactionType: TransactionType.WITHDRAW,
        transactionStatus: TransactionStatus.COMPLETED,
        description: 'SERVICIOS ABRIL',
        name: 'Alala',
        baseAmount: -238.4927259719,
        baseCurrency: Currency.USDC,
        localAmount: -4000,
        localCurrency: Currency.MXN,
        feeAmount: 0,
        feeCurrency: Currency.USDC,
        fxRate: 16.772,
        provider: 'ARCUS',
        providerRef: '6893c26d-361e-42b5-8e03-fb594794674f',
        postTransactionBalance: 4954.2095181161,
        groupId: 'f530af46-ef8e-4eeb-8c19-fd3f08459381',
        additionalDetails:
          '{"transferType":"EXTERNAL","beneficiaryId":"53a70110-cc08-43fb-8200-cb10c1346de3","deviceIp":"190.193.35.151","beneficiaryClabe":"002010077777777771","transferLabel":"Contractors Marzo","claveRastreo":"202403209070671E2KNZJ3T8YQMPQ"}',
        completedAt: '2024-03-20T20:00:02.079667',
        createdOn: '2024-03-20T15:20:31.153476',
        updatedOn: '2024-03-20T20:00:02.079667',
      },
      {
        displayableType: DisplayableType.SINGLE,
        id: '9776164f-0ec6-4a1d-bb67-0537270c398f',
        walletId: 'f2c1bb53-4d86-4cb7-aa48-8438b3ed489d',
        accountId: '64eb0105-8484-4f38-ba20-91c27159ee2c',
        transactionType: TransactionType.WITHDRAW,
        transactionStatus: TransactionStatus.COMPLETED,
        description: 'SERVICIOS ABRIL',
        name: 'Consultorias SA de CV',
        baseAmount: -238.4927259719,
        baseCurrency: Currency.USDC,
        localAmount: -4000,
        localCurrency: Currency.MXN,
        feeAmount: 0,
        feeCurrency: Currency.USDC,
        fxRate: 16.772,
        provider: 'ARCUS',
        providerRef: 'e6dfecb4-1177-4720-a7dc-8bce6787b147',
        postTransactionBalance: 4954.2095181161,
        groupId: 'f530af46-ef8e-4eeb-8c19-fd3f08459381',
        additionalDetails:
          '{"transferType":"EXTERNAL","beneficiaryId":"8576559f-c58e-464b-b9af-caa2f50f7bfc","deviceIp":"190.193.35.151","beneficiaryClabe":"002010077777777771","transferLabel":"Contractors Marzo","claveRastreo":"2024032090706H113AVQXQ9S48ARZ"}',
        completedAt: '2024-03-20T20:00:01.294679',
        createdOn: '2024-03-20T15:20:30.70494',
        updatedOn: '2024-03-20T20:00:01.294679',
      },
    ],
    failedTransactions: [
      {
        displayableType: DisplayableType.SINGLE,
        id: '95bde314-39af-412c-b8ae-d4a5183545ee',
        walletId: 'f2c1bb53-4d86-4cb7-aa48-8438b3ed489d',
        accountId: '64eb0105-8484-4f38-ba20-91c27159ee2c',
        transactionType: TransactionType.WITHDRAW,
        transactionStatus: TransactionStatus.FAILED,
        description: '',
        name: 'Sebastien Fernandez',
        baseAmount: -119.2642499404,
        baseCurrency: Currency.USDC,
        localAmount: -2000.3,
        localCurrency: Currency.MXN,
        feeAmount: 0,
        feeCurrency: Currency.USDC,
        fxRate: 16.772,
        provider: 'ARCUS',
        providerRef: '1369e568-c964-43f7-8085-2f71104bd4a1',
        postTransactionBalance: null,
        groupId: 'f530af46-ef8e-4eeb-8c19-fd3f08459381',
        additionalDetails:
          '{"transferType":"EXTERNAL","beneficiaryId":"39a967f1-782a-4582-ab2e-e2287e96c7b8","deviceIp":"190.193.35.151","beneficiaryClabe":"032180000118359719","transferLabel":"Contractors Marzo","failReason":"INVALID_ACCOUNT"}',
        completedAt: null,
        createdOn: '2024-03-20T15:20:28.981802',
        updatedOn: '2024-03-20T15:22:00.581617',
      },
    ],
  },
  {
    displayableType: DisplayableType.SINGLE,
    id: 'cc205b8e-6d99-4926-8d35-30d658fa34ce',
    walletId: '0ceaf1d2-20b6-4de0-8e74-ad514f0df81c',
    accountId: '64eb0105-8484-4f38-ba20-91c27159ee2c',
    transactionType: TransactionType.WITHDRAW,
    transactionStatus: TransactionStatus.COMPLETED,
    description: '',
    name: 'Test account 1',
    provider: 'SYNAPSE',
    baseAmount: -1000,
    baseCurrency: Currency.USDC,
    localAmount: -1000,
    localCurrency: Currency.USD,
    feeAmount: 0,
    feeCurrency: Currency.USDC,
    fxRate: 1,
    visible: true,
    additionalDetails:
      '{"transferType":"INTERNAL","beneficiaryId":"aa8be62e-b6e3-4d61-9c52-9118871db8b1","deviceIp":"193.42.98.98","beneficiaryAccountNumber":"9817123411","beneficiaryRoutingNumber":"084106768","beneficiaryPaymentMethod":"WIRE"}',
    createdOn: '2024-02-23T12:17:30.494684',
    updatedOn: '2024-02-23T12:20:00.373863',
  },
  {
    displayableType: DisplayableType.SINGLE,
    id: 'f9e69d85-f8c8-4da9-8aed-d73f82922244',
    walletId: '0ceaf1d2-20b6-4de0-8e74-ad514f0df81c',
    accountId: '64eb0105-8484-4f38-ba20-91c27159ee2c',
    transactionType: TransactionType.WITHDRAW,
    transactionStatus: TransactionStatus.COMPLETED,
    description: '',
    name: 'Test account 2',
    provider: 'SYNAPSE',
    baseAmount: -696.96,
    baseCurrency: Currency.USDC,
    localAmount: -696.96,
    localCurrency: Currency.USD,
    feeAmount: 0,
    feeCurrency: Currency.USDC,
    fxRate: 1,
    visible: true,
    additionalDetails:
      '{"transferType":"INTERNAL","beneficiaryId":"fb061a90-cf5c-4beb-a709-137acd6778eb","deviceIp":"80.82.25.50","beneficiaryAccountNumber":"9817123411","beneficiaryRoutingNumber":"084106768","beneficiaryPaymentMethod":"ACH"}',
    createdOn: '2024-02-22T12:31:21.291232',
    updatedOn: '2024-02-22T12:34:00.222448',
  },
  {
    displayableType: DisplayableType.SINGLE,
    id: '05add299-7ed0-4eda-9ac1-a7f81766811d',
    walletId: '0ceaf1d2-20b6-4de0-8e74-ad514f0df81c',
    accountId: '64eb0105-8484-4f38-ba20-91c27159ee2c',
    transactionType: TransactionType.WITHDRAW,
    transactionStatus: TransactionStatus.FAILED,
    description: '',
    name: 'Test account 3',
    provider: 'ARCUS',
    baseAmount: -12,
    baseCurrency: Currency.USDC,
    localAmount: -204.276,
    localCurrency: Currency.MXN,
    feeAmount: 0,
    feeCurrency: Currency.USDC,
    fxRate: 17.023,
    visible: true,
    additionalDetails:
      '{"transferType":"EXTERNAL","beneficiaryId":"f73ba286-955f-4908-83f1-758f481c27da","deviceIp":"80.82.25.50","beneficiaryClabe":"706180132010134087","claveRastreo":"","failReason":"Destination Account is not available"}',
    createdOn: '2024-02-22T12:22:05.896923',
    updatedOn: '2024-02-22T12:25:02.817752',
  },
  {
    displayableType: DisplayableType.SINGLE,
    id: 'eac46a2b-f479-48c6-8173-e948782a0771',
    walletId: '0ceaf1d2-20b6-4de0-8e74-ad514f0df81c',
    accountId: '64eb0105-8484-4f38-ba20-91c27159ee2c',
    transactionType: TransactionType.WITHDRAW,
    transactionStatus: TransactionStatus.COMPLETED,
    description: 'mooooore',
    name: 'Test account 4',
    provider: 'SYNAPSE',
    baseAmount: -1000,
    baseCurrency: Currency.USDC,
    localAmount: -1000,
    localCurrency: Currency.USD,
    feeAmount: 0,
    feeCurrency: Currency.USDC,
    fxRate: 1,
    visible: true,
    additionalDetails:
      '{"transferType":"INTERNAL","beneficiaryId":"aa8be62e-b6e3-4d61-9c52-9118871db8b1","deviceIp":"91.236.87.136","beneficiaryAccountNumber":"9817123411","beneficiaryRoutingNumber":"084106768","beneficiaryPaymentMethod":"WIRE"}',
    createdOn: '2024-02-21T18:04:18.403816',
    updatedOn: '2024-02-21T18:07:00.358798',
  },
  {
    displayableType: DisplayableType.SINGLE,
    id: 'b51e88cc-a9d7-4e0b-83c5-e6ab92c0e20a',
    walletId: '0ceaf1d2-20b6-4de0-8e74-ad514f0df81c',
    accountId: '64eb0105-8484-4f38-ba20-91c27159ee2c',
    transactionType: TransactionType.WITHDRAW,
    transactionStatus: TransactionStatus.COMPLETED,
    description: 'moooore',
    name: 'Test account 5',
    provider: 'SYNAPSE',
    baseAmount: -100,
    baseCurrency: Currency.USDC,
    localAmount: -100,
    localCurrency: Currency.USD,
    feeAmount: 0,
    feeCurrency: Currency.USDC,
    fxRate: 1,
    visible: true,
    additionalDetails:
      '{"transferType":"INTERNAL","beneficiaryId":"aa8be62e-b6e3-4d61-9c52-9118871db8b1","deviceIp":"91.236.87.136","beneficiaryAccountNumber":"9817123411","beneficiaryRoutingNumber":"084106768","beneficiaryPaymentMethod":"WIRE"}',
    createdOn: '2024-02-21T18:03:56.185319',
    updatedOn: '2024-02-21T18:06:00.366403',
  },
  {
    displayableType: DisplayableType.SINGLE,
    id: '19fee6af-3e2b-4de9-ad5a-abf92b2ce480',
    walletId: '0ceaf1d2-20b6-4de0-8e74-ad514f0df81c',
    accountId: '64eb0105-8484-4f38-ba20-91c27159ee2c',
    transactionType: TransactionType.WITHDRAW,
    transactionStatus: TransactionStatus.COMPLETED,
    description: 'more',
    name: 'Test account 6',
    provider: 'SYNAPSE',
    baseAmount: -10,
    baseCurrency: Currency.USDC,
    localAmount: -10,
    localCurrency: Currency.USD,
    feeAmount: 0,
    feeCurrency: Currency.USDC,
    fxRate: 1,
    visible: true,
    additionalDetails:
      '{"transferType":"INTERNAL","beneficiaryId":"aa8be62e-b6e3-4d61-9c52-9118871db8b1","deviceIp":"91.236.87.136","beneficiaryAccountNumber":"9817123411","beneficiaryRoutingNumber":"084106768","beneficiaryPaymentMethod":"WIRE"}',
    createdOn: '2024-02-21T18:03:24.330942',
    updatedOn: '2024-02-21T18:06:00.322114',
  },
  {
    displayableType: DisplayableType.SINGLE,
    id: '8445ca08-dd5d-49f6-9d52-2f27bc729e94',
    walletId: '0ceaf1d2-20b6-4de0-8e74-ad514f0df81c',
    accountId: '64eb0105-8484-4f38-ba20-91c27159ee2c',
    transactionType: TransactionType.WITHDRAW,
    transactionStatus: TransactionStatus.COMPLETED,
    description: '',
    name: 'Test account 7',
    provider: 'SYNAPSE',
    baseAmount: -1,
    baseCurrency: Currency.USDC,
    localAmount: -1,
    localCurrency: Currency.USD,
    feeAmount: 0,
    feeCurrency: Currency.USDC,
    fxRate: 1,
    visible: true,
    additionalDetails:
      '{"transferType":"INTERNAL","beneficiaryId":"aa8be62e-b6e3-4d61-9c52-9118871db8b1","deviceIp":"91.236.87.136","beneficiaryAccountNumber":"9817123411","beneficiaryRoutingNumber":"084106768","beneficiaryPaymentMethod":"WIRE"}',
    createdOn: '2024-02-21T17:57:53.481888',
    updatedOn: '2024-02-21T18:02:00.407292',
  },
  {
    displayableType: DisplayableType.SINGLE,
    id: '1c7700cb-eb39-41fa-b1df-8a761a71824d',
    walletId: '0ceaf1d2-20b6-4de0-8e74-ad514f0df81c',
    accountId: '64eb0105-8484-4f38-ba20-91c27159ee2c',
    transactionType: TransactionType.WITHDRAW,
    transactionStatus: TransactionStatus.COMPLETED,
    description: 'Yo!',
    name: 'Test account 8',
    provider: 'SYNAPSE',
    baseAmount: -2000.1,
    baseCurrency: Currency.USDC,
    localAmount: -2000.1,
    localCurrency: Currency.USD,
    feeAmount: 0,
    feeCurrency: Currency.USDC,
    fxRate: 1,
    visible: true,
    additionalDetails:
      '{"transferType":"INTERNAL","beneficiaryId":"aa8be62e-b6e3-4d61-9c52-9118871db8b1","deviceIp":"91.236.87.136","beneficiaryAccountNumber":"9817123411","beneficiaryRoutingNumber":"084106768","beneficiaryPaymentMethod":"WIRE"}',
    createdOn: '2024-02-21T15:41:54.971583',
    updatedOn: '2024-02-21T17:12:03.974337',
  },
  {
    displayableType: DisplayableType.SINGLE,
    id: '490cbcdd-c1f4-4412-b907-7fdea409be2e',
    walletId: '0ceaf1d2-20b6-4de0-8e74-ad514f0df81c',
    accountId: '64eb0105-8484-4f38-ba20-91c27159ee2c',
    transactionType: TransactionType.INTERNAL_SWAP_DEPOSIT,
    transactionStatus: TransactionStatus.FAILED,
    description: '',
    name: 'Test account 9',
    provider: 'DOLARAPP',
    baseAmount: 11,
    baseCurrency: Currency.USDC,
    localAmount: 11,
    localCurrency: Currency.USDC,
    feeAmount: 0,
    feeCurrency: Currency.USDC,
    fxRate: 1,
    visible: true,
    additionalDetails: '{}',
    createdOn: '2024-02-21T11:53:28.672959',
    updatedOn: '2024-02-21T11:53:28.672959',
  },
  {
    displayableType: DisplayableType.SINGLE,
    id: '7202d5c5-a23f-4e36-aa0c-449a87c4055e',
    walletId: '0ceaf1d2-20b6-4de0-8e74-ad514f0df81c',
    accountId: '64eb0105-8484-4f38-ba20-91c27159ee2c',
    transactionType: TransactionType.INTERNAL_SWAP_WITHDRAW,
    transactionStatus: TransactionStatus.FAILED,
    description: '',
    name: 'Test account 10',
    provider: 'DOLARAPP',
    baseAmount: -11,
    baseCurrency: Currency.USDC,
    localAmount: -11,
    localCurrency: Currency.USDC,
    feeAmount: 0,
    feeCurrency: Currency.USDC,
    fxRate: 1,
    visible: true,
    additionalDetails: '{}',
    createdOn: '2024-02-21T11:53:28.672847',
    updatedOn: '2024-02-21T11:53:28.672847',
  },
  {
    accountId: '64eb0105-8484-4f38-ba20-91c27159ee2c',
    additionalDetails:
      '{"merchantCategory":"HEALTH","merchantLogoUrl":"https://cdn.dolarapp.com/merchant/logo/updates/spotify.png","cardId":"c6be52b9-1b0c-4d39-9ef2-cdf2bc30d54b","cardIdentityId":"00283379-242d-4cae-8e12-2d8fd831d9fa","cardType":"VIRTUAL","cardLastFourDigits":"5688","mxnRate":"18.0560000000"}',
    baseAmount: -55,
    baseCurrency: Currency.USDC,
    completedAt: null,
    createdOn: '2024-06-24T12:42:14.711255',
    description: 'Spotify',
    displayableType: DisplayableType.SINGLE,
    feeAmount: 0,
    feeCurrency: Currency.USDC,
    fxRate: 1.0909090909,
    id: '1e194b66-521f-445c-97ac-841ebeb1b8f3',
    localAmount: -60,
    localCurrency: Currency.USD,
    name: 'Spotify Usa',
    postTransactionBalance: null,
    provider: 'TUTUKA',
    providerRef: '105001',
    transactionStatus: TransactionStatus.PENDING,
    transactionType: TransactionType.CARD_PAYMENT,
    updatedOn: '2024-06-24T12:42:14.711255',
    walletId: '0ceaf1d2-20b6-4de0-8e74-ad514f0df81c',
  },
]

export const MOCKED_BULK_TRANSACTION: BulkTransaction =
  MOCKED_TRANSACTIONS.find(
    (tx) => tx.displayableType === DisplayableType.BULK,
  ) as BulkTransaction
