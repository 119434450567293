import { cva } from 'class-variance-authority'

export const buttonVariants = cva(
  'flex gap-2 shrink-0 items-center justify-center font-semibold whitespace-nowrap ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-neutral-gray-600 focus-visible:ring-offset-1 disabled:cursor-not-allowed rounded-lg',
  {
    variants: {
      variant: {
        default:
          'bg-neutral-gray-1000 text-white hover:bg-neutral-gray-1000/90 disabled:bg-neutral-gray-200 disabled:text-neutral-gray-400 font-bold',
        outline:
          'bg-white hover:bg-neutral-gray-100 hover:text-neutral-gray-900 disabled:opacity-50',
        secondary:
          'bg-neutral-gray-100 hover:bg-neutral-gray-200/80 disabled:bg-neutral-gray-200 disabled:text-neutral-gray-400',
        tertiary:
          'bg-neutral-gray-200 hover:bg-neutral-gray-400/30 disabled:bg-neutral-gray-200 disabled:text-neutral-gray-400',
        ghost: 'hover:text-neutral-gray-800 disabled:opacity-50',
        link: 'text-primary hover:text-primary-dark cursor-pointer text-sm font-normal disabled:opacity-50',
      },
      size: {
        xs: 'px-2.5 py-1.5 text-xs h-8',
        sm: 'px-3 py-2 text-sm h-9',
        md: 'px-3 py-2.5 text-sm min-w-32 h-10',
        lg: 'px-4 py-2.5 text-base h-11',
        xl: 'px-[18px] py-3 text-base h-12',
        '2xl': 'px-6 py-4 text-lg h-15',
        icon: 'size-8',
        inline: 'inline-flex text-sm font-normal',
      },
      width: {
        fit: 'w-fit',
        full: 'w-full',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'md',
      width: 'fit',
    },
    compoundVariants: [
      {
        size: 'icon',
        className: 'size-8',
      },
    ],
  },
)
