import { Typography } from '@/shared/ui'

import { Recipient } from '../../types'
import { getRecipientTitle } from '../../utils'

type Props = {
  recipient: Recipient
}

export const NameCell = ({ recipient }: Props) => {
  return (
    <div className="flex max-w-40 items-center gap-2">
      <Typography title={getRecipientTitle(recipient)} className="line-clamp-1">
        {getRecipientTitle(recipient)}
      </Typography>
    </div>
  )
}
