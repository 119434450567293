import { forwardRef, Ref, type SVGProps } from 'react'

const SvgMedicalKit = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill="#5c5c77"
      d="M12.75 11.99a.75.75 0 1 0-1.5 0zm-1.5 2a.75.75 0 1 0 1.5 0zm1.5 0a.75.75 0 1 0-1.5 0zm-1.5 2a.75.75 0 1 0 1.5 0zm.75-1.25a.75.75 0 1 0 0-1.5zm-2-1.5a.75.75 0 1 0 0 1.5zm2 0a.75.75 0 1 0 0 1.5zm2 1.5a.75.75 0 1 0 0-1.5zm-2.75-2.75v2h1.5v-2zm0 2v2h1.5v-2zm.75-.75h-2v1.5h2zm0 1.5h2v-1.5h-2zM8.25 8a.75.75 0 0 0 1.5 0zm2.25-2.598.375.65zm3 0-.375.65zM14.25 8a.75.75 0 1 0 1.5 0zm-5.221.75a.75.75 0 1 0-.058-1.5zM5.03 11.5l-.74-.122-.004.026zM5 11.99h.75v-.002zm0 4h-.75v.029zm4.308 4v-.75h-.027zm5.384 0 .027-.75h-.027zm4.308-4 .75.029v-.029zm0-4h-.75zm-.031-.486.744-.096-.004-.025zm-3.94-4.253a.75.75 0 0 0-.058 1.498zM9 7.25a.75.75 0 0 0 0 1.5zm6 1.5a.75.75 0 0 0 0-1.5zM9.75 8a2.25 2.25 0 0 1 1.125-1.949l-.75-1.299A3.75 3.75 0 0 0 8.25 8zm1.125-1.949a2.25 2.25 0 0 1 2.25 0l.75-1.299a3.75 3.75 0 0 0-3.75 0zm2.25 0A2.25 2.25 0 0 1 14.25 8h1.5a3.75 3.75 0 0 0-1.875-3.248zm-4.154 1.2a4.934 4.934 0 0 0-4.68 4.127l1.48.244A3.434 3.434 0 0 1 9.029 8.75zm-4.684 4.153a5 5 0 0 0-.037.588l1.5-.004q0-.196.025-.392zm-.037.586v4h1.5v-4zm0 4.029a4.91 4.91 0 0 0 5.085 4.72L9.28 19.24a3.41 3.41 0 0 1-3.532-3.279zm5.058 4.721h5.384v-1.5H9.308zm5.357 0a4.91 4.91 0 0 0 5.084-4.721l-1.498-.058a3.41 3.41 0 0 1-3.532 3.28zm5.085-4.75v-4h-1.5v4zm0-4q0-.292-.037-.582l-1.488.192q.025.195.025.39zm-.04-.607a4.934 4.934 0 0 0-4.681-4.132l-.058 1.498a3.434 3.434 0 0 1 3.258 2.876zM9 8.75h6v-1.5H9z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgMedicalKit)
export default ForwardRef
