import { useMemo } from 'react'
import * as React from 'react'
import { useRifm } from 'rifm'

import { cn } from '@/lib/utils'

import { Input } from '.'

const MAX_INPUT_LENGTH = 18

export interface MoneyInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  currency?: string
  locale?: string
  onChange?: (value: string) => void
}

export const MoneyInput = React.forwardRef<HTMLInputElement, MoneyInputProps>(
  ({ currency, locale = 'en-US', className, ...props }, ref) => {
    const { accept, reverseFormatNumber, format } = useMemo(() => {
      const groupSeparator = ','
      const decimalSeparator = '.'

      const formatValue = (value: string) => {
        const [whole, decimal] = value.split('.')

        const formattedWhole = new Intl.NumberFormat(
          locale,
          currency
            ? {
                style: 'currency',
                currency,
                maximumFractionDigits: 0,
              }
            : {
                maximumFractionDigits: 0,
              },
        ).format(Number(whole))

        if (decimal !== undefined) {
          const limitedDecimal = decimal.substring(0, 2)

          return `${formattedWhole}.${limitedDecimal}`
        }

        return formattedWhole
      }

      return {
        accept: new RegExp(`[\\d${decimalSeparator}]`, 'g'),
        reverseFormatNumber: (inputValue: string) => {
          let reversedValue = inputValue

          reversedValue = reversedValue.replace(
            new RegExp(`\\${groupSeparator}`, 'g'),
            '',
          )

          reversedValue = reversedValue.replace(
            new RegExp(`\\${decimalSeparator}`, 'g'),
            '.',
          )

          reversedValue = reversedValue.replace(/(?:\..*)\./g, '')
          reversedValue = reversedValue.replace(/[^\d.]/g, '')

          return reversedValue
        },
        format: (inputValue: string) => {
          return inputValue ? formatValue(inputValue) : ''
        },
      }
    }, [locale, currency])

    const rifm = useRifm({
      accept,
      value: format(String(props.value)),
      format: (next) => format(reverseFormatNumber(next)),
      onChange: (next) => props.onChange?.(reverseFormatNumber(next)),
    })

    return (
      <Input
        type="text"
        inputMode="decimal"
        maxLength={MAX_INPUT_LENGTH}
        className={cn(
          'px-0 text-base font-bold disabled:bg-white disabled:opacity-50',
          className,
        )}
        ref={ref}
        {...props}
        {...rifm}
      />
    )
  },
)

MoneyInput.displayName = 'MoneyInput'
