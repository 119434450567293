import { forwardRef, Ref, type SVGProps } from 'react'

const SvgNotes = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 22 22"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      stroke={props.color || `currentColor`}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.35}
      d="M8.577 3.8h4.97q.076 0 .153.01a3.87 3.87 0 0 1 3.6 3.847v5.786a3.87 3.87 0 0 1-3.877 3.857H8.577A3.87 3.87 0 0 1 4.7 13.442V7.657A3.87 3.87 0 0 1 8.577 3.8"
      clipRule="evenodd"
    />
    <path
      fill={props.color || `currentColor`}
      d="M17.3 8.332a.675.675 0 1 0 0-1.35zm-3.6-.675h-.675c0 .373.302.675.675.675zm.675-3.847a.675.675 0 1 0-1.35 0zM12.8 11.675a.675.675 0 1 0 0-1.35zm-5.4-1.35a.675.675 0 1 0 0 1.35zm2.7-1.35a.675.675 0 1 0 0-1.35zm-2.7-1.35a.675.675 0 0 0 0 1.35zm6.3 6.75a.675.675 0 1 0 0-1.35zm-6.3-1.35a.675.675 0 1 0 0 1.35zm9.9-6.043h-3.6v1.35h3.6zm-2.925.675V3.81h-1.35v3.847zM12.8 10.325H7.4v1.35h5.4zm-2.7-2.7H7.4v1.35h2.7zm3.6 5.4H7.4v1.35h6.3z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgNotes)
export default ForwardRef
