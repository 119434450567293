import { api } from '@/lib/api'
import { PaymentLimitType } from '@/types/limits'

import { Card } from '../types'

type UpdateCardRequest = {
  cardId: string
  walletId?: string
  nickname?: string
  cardLimit?: {
    limitAmount: string
    limitType: PaymentLimitType
  }
}

export function updateCard(data: UpdateCardRequest) {
  return api.patch<Card>(`/v1/me/cards`, data)
}
