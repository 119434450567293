import forge from 'node-forge'

import { sessionStorageKeys } from '@/constants/keys'
import { cleanupPublicKey } from '@/lib/fingerprint'
import { parseAdditionalDetails } from '@/lib/utils'

import { UnmaskedCard } from '../types'

import { getEncryptedCardDetails } from './getEncryptedCardDetails'
import { storeCardEncryptionKey } from './storeCardEncryptionKey'

export async function getUnmaskedCardDetails({ id }: { id: string }) {
  const publicKey = sessionStorage.getItem(
    sessionStorageKeys.cardsDetailsPublicKey,
  )

  const privateKey = sessionStorage.getItem(
    sessionStorageKeys.cardsDetailsPrivateKey,
  )

  if (!publicKey || !privateKey) {
    console.error('Missing public key')

    return
  }

  await storeCardEncryptionKey({
    publicKeyPem: cleanupPublicKey(publicKey),
  })

  const response = await getEncryptedCardDetails({ id })

  const { encryptedCardDetails, encryptedKey, iv } = response.data

  const keyAsBytes = forge.util.hexToBytes(encryptedKey)

  const forgedPrivateKey = forge.pki.privateKeyFromPem(privateKey ?? '')

  const passwordAES = forgedPrivateKey.decrypt(keyAsBytes, 'RSA-OAEP', {
    md: forge.md.sha256.create(),
    mgf1: { md: forge.md.sha1.create() },
  })

  const decipher = forge.cipher.createDecipher('AES-CBC', passwordAES)

  decipher.start({
    iv: forge.util.createBuffer(forge.util.hexToBytes(iv)).getBytes(),
  })

  decipher.update(
    forge.util.createBuffer(forge.util.hexToBytes(encryptedCardDetails)),
  )

  const result = decipher.finish()

  if (result) {
    return parseAdditionalDetails<UnmaskedCard>(decipher.output.toString())
  }

  return undefined
}
