import { useIntl } from 'react-intl'
import { toast } from 'sonner'

export function useCopyToClipboard() {
  const intl = useIntl()

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text)
    } catch (err) {
      console.error(err)

      toast.error(
        intl.formatMessage({
          id: 'toast.error.copy.message',
          defaultMessage: 'Failed to copy to clipboard',
        }),
        { duration: 1000 },
      )
    }

    toast.success(
      intl.formatMessage({
        id: 'toast.success.copy.message',
        defaultMessage: 'Copied to clipboard',
      }),
      { duration: 1000 },
    )
  }

  return copyToClipboard
}
