import { api } from '@/lib/api'

import { IdentityValidation } from '../types'

type StartValidationParams = {
  email: string
}

export function restartIdentityValidation({ email }: StartValidationParams) {
  return api.put<IdentityValidation>(`/v1/onboardings/validations`, { email })
}
