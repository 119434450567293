import { forwardRef, Ref, type SVGProps } from 'react'

const SvgSoda = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill="#5c5c77"
      d="M6.5 7v-.75a.75.75 0 0 0-.742.861zm.744 4.963-.742.111zM7.4 13l.742-.111zm.589 3.926.744-.097-.002-.014zm.011.149-.75.013v.015zM10 19v-.75zm4 0v-.75zm1.995-1.851.748.056V17.2zm.028-.3.742.111zM16.6 13l-.742-.111zm.9-6 .742.111a.75.75 0 0 0-.742-.861zm-11.742.111.744 4.963 1.484-.222-.744-4.963zm.744 4.964.156 1.037 1.484-.224-.156-1.037zm.156 1.036.59 3.926 1.483-.222-.59-3.926zm.587 3.912.005.065 1.5-.026a2 2 0 0 0-.017-.233zm.006.08A2.75 2.75 0 0 0 10 19.75l-.002-1.5a1.25 1.25 0 0 1-1.25-1.203zM10 19.75h4v-1.5h-4zm4 0a2.75 2.75 0 0 0 2.743-2.545l-1.496-.112A1.25 1.25 0 0 1 14 18.25zm2.743-2.549c.008-.112.012-.177.022-.24l-1.484-.223c-.02.136-.028.271-.034.359zm.022-.24.577-3.85-1.484-.222-.577 3.849zm.577-3.85.9-6-1.484-.222-.9 6zM17.5 6.25h-11v1.5h11zM11.25 13c0-.403.124-.742.29-.963s.336-.287.46-.287v-1.5c-.704 0-1.284.384-1.66.888-.378.504-.59 1.165-.59 1.862zm.75-1.25c.124 0 .294.066.46.287s.29.56.29.963h1.5c0-.697-.212-1.358-.59-1.862-.376-.504-.956-.888-1.66-.888zm.75 1.25c0 .403-.124.742-.29.963s-.336.287-.46.287v1.5c.704 0 1.284-.384 1.66-.888.378-.504.59-1.165.59-1.862zM12 14.25c-.124 0-.294-.066-.46-.287s-.29-.56-.29-.963h-1.5c0 .697.212 1.358.59 1.862.376.504.956.888 1.66.888z"
    />
    <path
      fill="#5c5c77"
      d="M6.5 7.75a.75.75 0 1 0 0-1.5zM5 6.25a.75.75 0 0 0 0 1.5zm6.288.513a.75.75 0 0 0 1.424.474zm1.484-2.079.712.237zM13.721 4v.75zM15 4.75a.75.75 0 0 0 0-1.5zm2.5 1.5a.75.75 0 0 0 0 1.5zm1.5 1.5a.75.75 0 0 0 0-1.5zm-8.912 5.877a.75.75 0 1 0 .824-1.254zm-3.1-1.254a.75.75 0 1 0 .824 1.254zm6.822-.056a.75.75 0 1 0-.62 1.366zm3.347 1.185a.75.75 0 0 0-1.114-1.004zM6.5 6.25H5v1.5h1.5zm6.212.987.772-2.316-1.424-.474-.772 2.316zm.772-2.316a.25.25 0 0 1 .237-.171v-1.5a1.75 1.75 0 0 0-1.66 1.197zm.237-.171H15v-1.5h-1.279zm3.779 3H19v-1.5h-1.5zm-6.588 4.623a3.58 3.58 0 0 0-3.924 0l.824 1.254a2.08 2.08 0 0 1 2.276 0zm2.278 1.31c.421.191 1.097.42 1.791.488.659.065 1.569.006 2.176-.67l-1.114-1.003c-.113.125-.386.232-.915.18a4.5 4.5 0 0 1-1.318-.36z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgSoda)
export default ForwardRef
