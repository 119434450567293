import { Currency } from '@/constants/currency'

export enum ContractorPaymentType {
  FIXED_RATE = 'FIXED_RATE',
  PAY_AS_YOU_GO = 'PAY_AS_YOU_GO',
}

export enum ContractorState {
  WAITING_FOR_CONTRACTOR = 'WAITING_FOR_CONTRACTOR',
  ACTION_NEEDED = 'ACTION_NEEDED',
  ACTIVE = 'ACTIVE',
}

export type Contractor = {
  id: string
  firstName: string
  lastName: string
  secondLastName: string
  email: string
  contractorRole: string
  countryOfCitizenship: null | string
  paymentType: ContractorPaymentType
  startDate: string
  lastPaymentDate: null | string
  state: ContractorState
}

export enum ContractDateType {
  LAST_BUSINESS_DAY_OF_PERIOD = 'LAST_BUSINESS_DAY_OF_PERIOD',
  LAST_FRIDAY_OF_PERIOD = 'LAST_FRIDAY_OF_PERIOD',
  CUSTOM_DAY_OF_MONTH = 'CUSTOM_DAY_OF_MONTH',
  CUSTOM_DAY_OF_WEEK = 'CUSTOM_DAY_OF_WEEK',
}

export enum FirstCycleMethod {
  FULL_AMOUNT = 'FULL_AMOUNT',
  CUSTOM_AMOUNT = 'CUSTOM_AMOUNT',
  NO_PAYMENT = 'NO_PAYMENT',
  PRO_RATA = 'PRO_RATA',
}

enum ContractorPaymentFrequency {
  WEEKLY = 'WEEKLY',
  BIWEEKLY = 'BIWEEKLY',
  SEMIMONTHLY = 'SEMIMONTHLY',
  MONTHLY = 'MONTHLY',
}

export type ContractorPaymentInformation = {
  currency: Currency
  amount: number
  frequency: ContractorPaymentFrequency
  dayType: ContractDateType
  day?: number
  firstCycleMethod: FirstCycleMethod
  firstCycleAmount?: number
}

export type Contract = {
  id: string
  state: ContractorState
  paymentType: ContractorPaymentType
  agreementType: 'CUSTOM'
  contractorRole: string
  scopeOfWork: string
  startDate: string
  endDate: null | string
  createdByBusinessUserId: string
  paymentDetails: ContractorPaymentInformation
}

export type ContractorDetails = {
  id: string
  state: ContractorState
  taxDetails: {
    countryOfTaxResidence?: string
    taxId?: string
    taxIdType?: string
    taxForm?: string
  }
  personalDetails: {
    firstName: string
    lastName: string
    secondLastName?: string
    email: string
    internationalPhonePrefix: string
    localPhoneNumber: string
    dateOfBirth?: string
    countryOfCitizenship?: string
    residentialAddress?: {
      addressStreetNumber: string
      addressStreet: string
      addressDistrict: string
      addressCity: string
      addressState: string
      addressPostCode: string
      addressCountry: string
    }
  }
  contracts: Contract[]
}
