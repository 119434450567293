import { useMemo } from 'react'
import { useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { generatePath, useParams, useSearchParams } from 'react-router-dom'

import { ONBOARDING_STEP } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { Loader, ProgressButton } from '@/shared/components'
import { FullScreen } from '@/shared/ui'

import { getOnboardingStepDetails } from './api/getOnboardingStepDetails'
import { restartIdentityValidation } from './api/restartIdentityValidation'
import { startIdentityValidation } from './api/startIdentityValidation'
import { QRScreen } from './components/QRScreen'
import { UploadDocsScreen } from './components/UploadDocsScreen'
import {
  IdentityValidation,
  IdentityValidationStep,
  OnboardingMemberState,
  OnboardingStepDetails,
  OnboardingStepName,
} from './types'

export const ValidateIdentityScreen = () => {
  const { type } = useParams<{ type?: string }>()
  const [searchParams] = useSearchParams()

  const email = decodeURIComponent(searchParams.get('email') ?? '')
  const status = searchParams.get('status')

  const [memberQuery, startValidationQuery, restartValidationQuery] =
    useQueries({
      queries: [
        {
          queryKey: [
            queryKeys.getOnboardingStepDetails,
            OnboardingStepName.IDENTITY_VALIDATION,
          ],
          queryFn: () =>
            getOnboardingStepDetails(OnboardingStepName.IDENTITY_VALIDATION),
          select: (data: AxiosResponse<OnboardingStepDetails>) =>
            (data.data as IdentityValidationStep).stepDetails.members?.find(
              (member) => member.email === email,
            ),
        },
        {
          queryKey: [queryKeys.startIdentityValidation, email],
          queryFn: () => startIdentityValidation({ email }),
          enabled: !!email && status === OnboardingMemberState.NOT_STARTED,
          select: (data: AxiosResponse<IdentityValidation>) => data.data,
        },
        {
          queryKey: [queryKeys.restartIdentityValidation, email],
          queryFn: () => restartIdentityValidation({ email }),
          enabled: !!email && status === OnboardingMemberState.REJECTED,
          select: (data: AxiosResponse<IdentityValidation>) => data.data,
        },
      ],
    })

  const link = useMemo(() => {
    return (
      restartValidationQuery.data?.validationLink ??
      startValidationQuery.data?.validationLink ??
      memberQuery.data?.validationLink ??
      ''
    )
  }, [memberQuery.data, startValidationQuery.data, restartValidationQuery.data])

  if (
    memberQuery.isFetching ||
    startValidationQuery.isFetching ||
    restartValidationQuery.isFetching
  ) {
    return <Loader />
  }

  return (
    <FullScreen>
      <ProgressButton
        progress={50}
        to={generatePath(ONBOARDING_STEP, {
          step: OnboardingStepName.IDENTITY_VALIDATION,
        })}
      />

      {(() => {
        switch (type) {
          case 'qr':
            return <QRScreen link={link} />
          case 'email':
            return <UploadDocsScreen link={link} member={memberQuery.data} />
          default:
            return null
        }
      })()}
    </FullScreen>
  )
}
