import { FormattedMessage } from 'react-intl'

import {
  badgeVariantByBulkTxStatus,
  badgeVariantByTxStatus,
} from '@/features/Transactions/constants'
import { DisplayableType, Transaction } from '@/features/Transactions/types'

import { StatusBadge } from '../StatusBadge'

type Props = {
  transaction: Transaction
}

export const StateCell = ({ transaction }: Props) => {
  switch (transaction.displayableType) {
    case DisplayableType.SINGLE:
      return (
        <div className="flex items-center">
          <StatusBadge
            variant={badgeVariantByTxStatus[transaction.transactionStatus]}
            title={
              <FormattedMessage
                id="transaction.status"
                defaultMessage="{status, select, FAILED {Failed} BLOCKED {Blocked} REVERTED {Reverted} PENDING {Pending} COMPLETED {Completed} other {}}"
                values={{ status: transaction.transactionStatus }}
              />
            }
          />
        </div>
      )

    case DisplayableType.BULK:
      return (
        <div className="flex items-center">
          <StatusBadge
            variant={
              badgeVariantByBulkTxStatus[transaction.bulkTransactionStatus]
            }
            title={
              <FormattedMessage
                id="transaction.bulk.status"
                defaultMessage="{status, select, INCOMPLETE {Incomplete} PENDING {Pending} COMPLETED {Completed} other {}}"
                values={{ status: transaction.bulkTransactionStatus }}
              />
            }
          />
        </div>
      )

    default:
      return null
  }
}
