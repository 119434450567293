import { useEffect, useMemo, useState } from 'react'
import { useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { AnimatePresence } from 'framer-motion'
import { generatePath, useParams } from 'react-router-dom'

import { getIdentity } from '@/api'
import { ONBOARDING_STEP } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { Loader, ProgressButton } from '@/shared/components'
import { FullScreen } from '@/shared/ui'
import { Identity } from '@/types/business'

import {
  getBeneficialOwner,
  getOnboardingConfig,
  getOnboardingStepDetails,
} from './api'
import {
  OwnerAddressStep,
  OwnerDetailsStep,
  OwnerReviewStep,
  SelectOwnerTypeStep,
} from './components/NewOwnerSteps'
import {
  CompanyOwner,
  LegalRepresentativeStep,
  OnboardingConfig,
  OnboardingStepDetails,
  OnboardingStepName,
  OwnerType,
} from './types'

enum Step {
  Select = 'select',
  Details = 'details',
  Address = 'address',
  Review = 'review',
}

type State =
  | { type: Step.Select }
  | {
      type: Step.Details
      ownerType?: OwnerType
      data?: Omit<CompanyOwner, 'id'>
    }
  | { type: Step.Address; data: Omit<CompanyOwner, 'id'> }
  | { type: Step.Review; data: Omit<CompanyOwner, 'id'> }

export const CreateOwner = () => {
  const [activeStep, setActiveStep] = useState<State>({
    type: Step.Select,
  })

  const [ownerType, setOwnerType] = useState<OwnerType>()

  const { ownerId } = useParams<{ ownerId?: string }>()

  const [
    userQuery,
    onboardingConfigQuery,
    legalRepresentativeQuery,
    ownerQuery,
  ] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getIdentity],
        queryFn: getIdentity,
        select: (data: AxiosResponse<Identity>) => data?.data,
      },
      {
        queryKey: [
          queryKeys.getOnboardingConfig,
          OnboardingStepName.COMPANY_OWNERSHIP,
        ],
        queryFn: getOnboardingConfig,
        staleTime: 0,
        select: (data: AxiosResponse<OnboardingConfig>) =>
          data.data.steps.find(
            (s) => s.name === OnboardingStepName.COMPANY_OWNERSHIP,
          ),
      },
      {
        queryKey: [
          queryKeys.getOnboardingStepDetails,
          OnboardingStepName.LEGAL_REPRESENTATIVE,
        ],
        queryFn: () =>
          getOnboardingStepDetails(OnboardingStepName.LEGAL_REPRESENTATIVE),
        select: (data: AxiosResponse<OnboardingStepDetails>) =>
          data.data as LegalRepresentativeStep,
      },
      {
        queryKey: [queryKeys.getBeneficialOwner, ownerId],
        queryFn: () => getBeneficialOwner({ ownerId: ownerId ?? '' }),
        enabled: !!ownerId,
        select: (data: AxiosResponse<CompanyOwner>) => data.data,
      },
    ],
  })

  useEffect(() => {
    const ownerEmail = ownerQuery.data?.email

    const legalRepresentativeEmail =
      legalRepresentativeQuery.data?.stepDetails?.legalRepresentative?.email

    const userEmail = userQuery.data?.email

    if (!ownerQuery.data || !legalRepresentativeQuery.data || !userQuery.data) {
      return
    }

    const ownerFields = [
      ownerQuery.data.firstName,
      ownerQuery.data.lastName,
      ownerQuery.data.internationalPhonePrefix,
      ownerQuery.data.localPhoneNumber,
    ]

    const legalRepresentativeFields = [
      legalRepresentativeQuery.data?.stepDetails?.legalRepresentative
        ?.firstName,
      legalRepresentativeQuery.data?.stepDetails?.legalRepresentative?.lastName,
      legalRepresentativeQuery.data?.stepDetails?.legalRepresentative
        ?.internationalPhonePrefix,
      legalRepresentativeQuery.data?.stepDetails?.legalRepresentative
        ?.localPhoneNumber,
    ]

    const areFieldsIdentical = ownerFields.every(
      (field, index) => field === legalRepresentativeFields[index],
    )

    if (ownerEmail === legalRepresentativeEmail) {
      if (ownerEmail === userEmail) {
        setOwnerType(OwnerType.USER_LEGAL_REPRESENTATIVE_AND_OWNER)
      } else {
        setOwnerType(OwnerType.OTHER_LEGAL_REPRESENTATIVE_AND_OWNER)
      }
    } else {
      if (ownerEmail === userEmail) {
        setOwnerType(OwnerType.USER_OWNER)
      } else {
        setOwnerType(OwnerType.NEW_OWNER)
      }
    }

    if (ownerEmail === legalRepresentativeEmail && areFieldsIdentical) {
      setActiveStep({
        type: Step.Address,
        data: ownerQuery.data,
      })
    } else {
      setActiveStep({
        type: Step.Details,
        data: ownerQuery.data,
      })
    }
  }, [
    legalRepresentativeQuery.data,
    ownerQuery.data,
    userQuery.data,
    userQuery.data?.email,
  ])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [activeStep])

  const progressByStep = useMemo(() => {
    switch (activeStep.type) {
      case Step.Select:
        return 25
      case Step.Details:
        return 50
      case Step.Address:
        return 75
      case Step.Review:
        return 100
    }
  }, [activeStep.type])

  if (
    userQuery.isPending ||
    onboardingConfigQuery.isPending ||
    legalRepresentativeQuery.isPending ||
    ownerQuery.isFetching
  ) {
    return <Loader />
  }

  return (
    <FullScreen>
      <ProgressButton
        progress={progressByStep}
        to={generatePath(ONBOARDING_STEP, {
          step: OnboardingStepName.COMPANY_OWNERSHIP,
        })}
      />

      <AnimatePresence mode="wait">
        {activeStep.type === Step.Select ? (
          <SelectOwnerTypeStep
            onContinue={(type) => {
              setOwnerType(type)

              switch (type) {
                case OwnerType.USER_OWNER:
                  setActiveStep({
                    type: Step.Details,
                  })
                  break

                case OwnerType.OTHER_LEGAL_REPRESENTATIVE_AND_OWNER:
                case OwnerType.USER_LEGAL_REPRESENTATIVE_AND_OWNER:
                  setActiveStep({
                    type: Step.Address,
                    data: {
                      ...legalRepresentativeQuery.data?.stepDetails
                        ?.legalRepresentative,
                    },
                  })
                  break

                case OwnerType.NEW_OWNER:
                  setActiveStep({
                    type: Step.Details,
                  })
                  break

                default:
                  break
              }
            }}
          />
        ) : null}

        {activeStep.type === Step.Details ? (
          <OwnerDetailsStep
            onBack={() => {
              setActiveStep({
                type: Step.Select,
              })
            }}
            config={onboardingConfigQuery.data}
            owner={ownerQuery.data}
            data={activeStep.data}
            ownerType={ownerType}
            onContinue={(data) => setActiveStep({ type: Step.Address, data })}
          />
        ) : null}

        {activeStep.type === Step.Address ? (
          <OwnerAddressStep
            config={onboardingConfigQuery.data}
            data={activeStep.data}
            ownerType={ownerType}
            onBack={() =>
              ownerType === OwnerType.OTHER_LEGAL_REPRESENTATIVE_AND_OWNER ||
              ownerType === OwnerType.USER_LEGAL_REPRESENTATIVE_AND_OWNER
                ? setActiveStep({ type: Step.Select })
                : setActiveStep({ type: Step.Details, data: activeStep.data })
            }
            owner={ownerQuery.data}
            onContinue={(data) => {
              setActiveStep({ type: Step.Review, data })
            }}
          />
        ) : null}

        {activeStep.type === Step.Review ? (
          <OwnerReviewStep
            data={activeStep.data}
            onBack={() =>
              setActiveStep({ type: Step.Address, data: activeStep.data })
            }
            ownerId={ownerQuery.data?.id}
          />
        ) : null}
      </AnimatePresence>
    </FullScreen>
  )
}
