import { FormattedMessage } from 'react-intl'

import { Contractor } from '@/features/Contractors/types'
import { getImage } from '@/lib/images'
import { Widget } from '@/shared/components'
import { Button, Typography } from '@/shared/ui'

type Props = {
  contractor?: Contractor
}

export const ContractorToDos = ({ contractor }: Props) => {
  console.log(contractor)

  return (
    <Widget
      title={<FormattedMessage defaultMessage="To-do's" id="label.toDos" />}
    >
      <div className="flex items-center justify-between">
        <div className="flex flex-wrap gap-8">
          <img
            className="w-8 object-contain"
            src={getImage({
              name: 'todo-list',
              category: 'contractors',
            })}
            alt=""
            aria-hidden={true}
          />
          <div className="flex flex-col justify-center gap-1">
            <Typography bold>
              <FormattedMessage
                id="contractors.toDo.reviewAndSign.title"
                defaultMessage="Review and sign contract"
              />
            </Typography>
            <Typography>
              <FormattedMessage
                id="contractors.toDo.reviewAndSign.subtitle"
                defaultMessage="You need to review and sign the contract"
              />
            </Typography>
          </div>
        </div>

        <Button>
          <FormattedMessage
            id="actions.reviewAndSign"
            defaultMessage="Review & Sign"
          />
        </Button>
      </div>
    </Widget>
  )
}
