import { forwardRef, Ref, type SVGProps } from 'react'

const SvgTv = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill="#5c5c77"
      d="M10.269 17.5v-.75h-.014zm3.462 0 .014-.75h-.014zm2.769-2.667.75.014v-.014zm0-2.666h.75v-.014zM13.731 9.5v.75h.014zm-3.462 0-.014.75h.014zM7.5 12.167l-.75-.014v.014zm0 2.667h-.75v.014zm2.769 3.416h3.462v-1.5h-3.462zm3.448 0a3.47 3.47 0 0 0 3.533-3.403l-1.5-.028a1.97 1.97 0 0 1-2.005 1.931zm3.533-3.417v-2.666h-1.5v2.666zm0-2.68a3.47 3.47 0 0 0-3.533-3.403l.028 1.5a1.97 1.97 0 0 1 2.005 1.931zM13.73 8.75h-3.462v1.5h3.462zm-3.448 0a3.47 3.47 0 0 0-3.533 3.403l1.5.028a1.97 1.97 0 0 1 2.005-1.931zM6.75 12.167v2.667h1.5v-2.667zm0 2.681a3.47 3.47 0 0 0 3.533 3.402l-.028-1.5a1.97 1.97 0 0 1-2.005-1.93z"
    />
    <path
      fill="#5c5c77"
      d="M9.65 3.625a.75.75 0 0 0-1.3.75zm.432 3.75a.75.75 0 1 0 1.3-.75zm5.568-3a.75.75 0 0 0-1.3-.75zm-3.032 2.25a.75.75 0 1 0 1.3.75zm.65-.375a.75.75 0 1 0 0 1.5zm1.424.75.002-.75h-.002zM19 11.333l-.75-.002v.002zm0 4.333h-.75v.002zm-1.256 3.06-.532-.53zM14.692 20v.75h.002zm-5.384 0-.002.75h.002zM5 15.667l.75.002v-.002zm0-4.334h.75v-.002zM9.308 7v-.75h-.002zm1.424.75a.75.75 0 0 0 0-1.5zm2.536 0a.75.75 0 0 0 0-1.5zm-2.536-1.5a.75.75 0 1 0 0 1.5zM8.35 4.375l1.732 3 1.3-.75-1.732-3zm6-.75-1.732 3 1.3.75 1.732-3zM13.268 7.75h1.424v-1.5h-1.424zm1.422 0a3.57 3.57 0 0 1 3.56 3.58l1.5.005a5.07 5.07 0 0 0-5.056-5.085zm3.56 3.583v4.333h1.5v-4.333zm0 4.335a3.57 3.57 0 0 1-1.038 2.528l1.064 1.058a5.07 5.07 0 0 0 1.474-3.59zm-1.038 2.528a3.57 3.57 0 0 1-2.522 1.054l.004 1.5a5.07 5.07 0 0 0 3.582-1.496zm-2.52 1.054H9.308v1.5h5.384zm-5.382 0a3.57 3.57 0 0 1-3.56-3.58l-1.5-.005a5.07 5.07 0 0 0 5.056 5.085zm-3.56-3.583v-4.334h-1.5v4.334zm0-4.336A3.57 3.57 0 0 1 9.31 7.75l-.004-1.5a5.07 5.07 0 0 0-5.056 5.085zM9.308 7.75h1.424v-1.5H9.308zm3.96-1.5h-2.536v1.5h2.536z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgTv)
export default ForwardRef
