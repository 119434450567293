import { api } from '@/lib/api'

import { BulkPaymentTaskDetails } from '../types'

export function getBulkPaymentTaskDetails({
  bulkPaymentId,
}: {
  bulkPaymentId: string
}) {
  return api.get<BulkPaymentTaskDetails>(
    `/v1/me/bulk-payment-quotes/${bulkPaymentId}/approval`,
  )
}
