import { Column } from '@tanstack/react-table'

import { cn } from '@/lib/utils'

import { SortDown, SortUp, SortUpDown } from '../icons/outline'

import { Button } from './Button'
import { Typography } from './Typography'

interface DataTableColumnHeaderProps<TData, TValue>
  extends React.HTMLAttributes<HTMLDivElement> {
  column: Column<TData, TValue>
  title: string
  align?: 'start' | 'center' | 'end'
}

export function DataTableColumnHeader<TData, TValue>({
  align = 'start',
  column,
  title,
  className,
}: DataTableColumnHeaderProps<TData, TValue>) {
  if (!column.getCanSort()) {
    return (
      <div
        className={cn(
          'flex items-center',
          {
            'justify-start': align === 'start',
            'justify-center': align === 'center',
            'justify-end': align === 'end',
          },
          className,
        )}
      >
        <Typography className="text-neutral-gray-800" variant="body-small">
          {title}
        </Typography>
      </div>
    )
  }

  return (
    <div
      className={cn(
        '-mr-1 flex items-center',
        {
          'justify-start': align === 'start',
          'justify-center': align === 'center',
          'justify-end': align === 'end',
        },
        className,
      )}
    >
      <Button
        variant="ghost"
        size="inline"
        className="h-8 font-semibold text-neutral-gray-800 hover:text-neutral-gray-900"
        onClick={column.getToggleSortingHandler()}
      >
        <Typography variant="body-small">{title}</Typography>
        {column.getIsSorted() === 'desc' ? (
          <SortDown className="size-4" />
        ) : column.getIsSorted() === 'asc' ? (
          <SortUp className="size-4" />
        ) : (
          <SortUpDown className="size-4" />
        )}
      </Button>
    </div>
  )
}
