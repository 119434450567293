import { useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link, useLocation, useSearchParams } from 'react-router-dom'

import {
  ADD_RECIPIENTS_ROUTE,
  BULK_PAYMENT_ROUTE,
  SEND_ROUTE,
} from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { useSearchInput } from '@/hooks/useSearchInput'
import { useSearchParamsValue } from '@/hooks/useSearchParamsValue'
import { useSidebar } from '@/hooks/useSidebar'
import { MoveMoneyWidget, WithPermissions } from '@/shared/components'
import { Plus } from '@/shared/icons/outline'
import { BulkArrows, Navigation } from '@/shared/icons/solid'
import { Button, DataTable, SearchInput, Typography } from '@/shared/ui'
import { Permission } from '@/types/permissions'

import { getRecipients, getSingleRecipient } from './api'
import { EmptyRecipients } from './components/EmptyRecipients'
import { RecipientDetailsSidebar } from './components/RecipientDetailsSidebar'
import { useRecipientsColumns } from './components/RecipientsTable'
import { Recipient } from './types'

export const Recipients = () => {
  const intl = useIntl()
  const location = useLocation()
  const columns = useRecipientsColumns()
  const [searchParams, setSearchParams] = useSearchParams()

  const [search, setSearch, handleSearchQuery] = useSearchInput()

  const params = Object.fromEntries(
    [...searchParams].filter(([key]) => key !== 'id'),
  )

  const [recipientId] = useSearchParamsValue(['id'])

  const [allRecipientsQuery, singleRecipient] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getRecipients, params],
        queryFn: () => getRecipients(params),
        select: (data: AxiosResponse<Recipient[]>) => data.data,
        staleTime: 0,
      },
      {
        queryKey: [queryKeys.getSingleRecipient, recipientId],
        queryFn: () => getSingleRecipient({ id: recipientId ?? '' }),
        select: (data: AxiosResponse<Recipient>) => data.data,
        enabled: !!recipientId,
      },
    ],
  })

  const [showDetails, setShowDetails] = useSidebar({ key: 'id' })

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full flex-wrap justify-between gap-6">
        <div className="flex flex-col gap-2">
          <Typography variant="h3">
            <FormattedMessage
              id="dashboard.recipients.title"
              defaultMessage="Recipients"
            />
          </Typography>
          <Typography className="text-neutral-gray-600">
            <FormattedMessage
              id="dashboard.recipients.subtitle"
              defaultMessage="Make simple or bulk payments to any of your saved recipients, or add new ones"
            />
          </Typography>
        </div>

        <MoveMoneyWidget />
      </div>

      <div className="p-4" />

      <div className="flex flex-wrap gap-3 md:flex-nowrap">
        <SearchInput
          value={search}
          onChange={(value) => {
            setSearch(value)
            handleSearchQuery(value)
          }}
        />
        <WithPermissions permissions={[Permission.TRANSFERS]}>
          <Button
            size="md"
            asChild
            leftIcon={<Navigation className="size-5" />}
            variant="tertiary"
          >
            <Link to={SEND_ROUTE} state={{ from: location }}>
              <FormattedMessage id="action.send" defaultMessage="Send" />
            </Link>
          </Button>
        </WithPermissions>
        <WithPermissions permissions={[Permission.TRANSFERS]}>
          <Button
            leftIcon={<BulkArrows className="size-4" />}
            size="md"
            asChild
            variant="tertiary"
          >
            <Link to={BULK_PAYMENT_ROUTE}>
              <FormattedMessage
                id="action.bulkPayment"
                defaultMessage="Bulk payment"
              />
            </Link>
          </Button>
        </WithPermissions>
        <WithPermissions permissions={[Permission.TRANSFERS]}>
          <Button
            leftIcon={<Plus className="size-5" />}
            size="md"
            asChild
            variant="tertiary"
          >
            <Link to={ADD_RECIPIENTS_ROUTE}>
              <FormattedMessage
                id="action.newRecipient"
                defaultMessage="New recipient"
              />
            </Link>
          </Button>
        </WithPermissions>
      </div>

      <div className="p-3" />

      {allRecipientsQuery.data?.length === 0 &&
      Object.keys(params).length === 0 ? (
        <EmptyRecipients />
      ) : (
        <DataTable
          emptyState={{
            title: intl.formatMessage({
              id: 'recipients.search.notFound',
              defaultMessage: 'No recipients found',
            }),
            description: intl.formatMessage({
              id: 'recipients.search.notFound.description',
              defaultMessage:
                'There were no recipients that matched your search criteria',
            }),
          }}
          loaderOptions={{ rows: 5 }}
          isLoading={allRecipientsQuery.isPending}
          columns={columns}
          data={allRecipientsQuery.data ?? []}
          onRowClick={(recipient) => {
            setSearchParams(
              (params) => {
                params.set('id', recipient.id)
                return params
              },
              { preventScrollReset: true },
            )
          }}
        />
      )}

      <RecipientDetailsSidebar
        recipient={singleRecipient.data}
        isOpen={showDetails}
        onOpenChange={() => {
          setShowDetails((d) => !d)
          setSearchParams(
            (params) => {
              params.delete('id')
              return params
            },
            { preventScrollReset: true },
          )
        }}
      />
    </div>
  )
}
