import { forwardRef, Ref, type SVGProps } from 'react'

const SvgArrowDown = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill={props.color || `currentColor`}
      d="M17.543 14.267a.75.75 0 1 0-1.086-1.034zm-6.086 4.216a.75.75 0 1 0 1.086 1.034zm0 1.034a.75.75 0 1 0 1.086-1.034zm-3.914-6.284a.75.75 0 1 0-1.086 1.034zM11.25 19a.75.75 0 0 0 1.5 0zm1.5-14a.75.75 0 0 0-1.5 0zm3.707 8.233-5 5.25 1.086 1.034 5-5.25zm-3.914 5.25-5-5.25-1.086 1.034 5 5.25zm.207.517V5h-1.5v14z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgArrowDown)
export default ForwardRef
