import { useState } from 'react'
import { AnimatePresence } from 'framer-motion'

import { useSearchParamsValue } from '@/hooks/useSearchParamsValue'

import { PasswordForm } from './components/PasswordForm'
import { UserForm } from './components/UserForm'

enum Step {
  User = 'user',
  Password = 'password',
}

type StepState =
  | { type: Step.User }
  | { type: Step.Password; signupId?: string; otp?: string; email?: string }

export const SignUp = () => {
  const [email, signupId, otp] = useSearchParamsValue(['email', 'id', 'otp'])

  const [activeStep] = useState<StepState>(() =>
    email && signupId && otp
      ? {
          type: Step.Password,
          email,
          signupId,
          otp: otp,
        }
      : { type: Step.User },
  )

  return (
    <AnimatePresence mode="wait">
      {activeStep.type === Step.User ? <UserForm /> : null}

      {activeStep.type === Step.Password ? (
        <PasswordForm
          email={decodeURI(email ?? '')}
          verificationCode={otp}
          signupId={signupId}
        />
      ) : null}
    </AnimatePresence>
  )
}
