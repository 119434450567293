import { FormattedMessage } from 'react-intl'

import { DisplayableType, Transaction } from '@/features/Transactions/types'

import { Typography } from '../../ui'

import { SingleMethodCell } from './SingleMethodCell'

type Props = {
  transaction: Transaction
}

export const MethodCell = ({ transaction }: Props) => {
  switch (transaction.displayableType) {
    case DisplayableType.SINGLE:
      return <SingleMethodCell transaction={transaction} />
    case DisplayableType.BULK:
      return (
        <div className="flex items-center">
          <Typography>
            <FormattedMessage
              id="transaction.method.bulkTransfer"
              defaultMessage="Bulk payment"
            />
          </Typography>
        </div>
      )
    default:
      return null
  }
}
