import { forwardRef, Ref, type SVGProps } from 'react'

const SvgEnvelopeOpen = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      stroke={props.color || `currentColor`}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9.02 18.997a3.87 3.87 0 0 1-3.988-3.733V11.53l-.005-1.866a1.52 1.52 0 0 1 .832-1.654l4.045-2.413a4.05 4.05 0 0 1 4.234 0l4.045 2.416c.62.3.96.978.832 1.654l-.005 1.867v3.733a3.87 3.87 0 0 1-3.99 3.73z"
      clipRule="evenodd"
    />
    <path
      fill={props.color || `currentColor`}
      d="M5.405 9.017a.75.75 0 1 0-.757 1.295zm4.532 3.517.385-.644-.007-.004zm4.167 0-.379-.648-.006.004zm5.288-2.222a.75.75 0 1 0-.757-1.295zm-5.553-.587a.75.75 0 0 0 .364-1.456zm-1.818-1.228.182-.728a.75.75 0 0 0-.364 0zm-2.182-.228a.75.75 0 1 0 .364 1.456zm-5.19 2.043 4.91 2.87.756-1.296-4.91-2.87zm4.903 2.866a4.81 4.81 0 0 0 4.937 0l-.77-1.288a3.31 3.31 0 0 1-3.397 0zm4.93.003 4.91-2.87-.757-1.294-4.91 2.87zm-.28-4.912-2-.5-.363 1.456 2 .5zm-2.363-.5-2 .5.364 1.456 2-.5z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgEnvelopeOpen)
export default ForwardRef
