import { RouteObject } from 'react-router-dom'

import {
  CONTRACTORS_ALL_ROUTE,
  CONTRACTORS_DETAILS_ROUTE,
  CONTRACTORS_HISTORICAL_PAYMENTS_ROUTE,
  CONTRACTORS_OVERVIEW_ROUTE,
  CONTRACTORS_ROUTE,
} from '@/constants/paths'

import { Contractors } from './Contractors'
import {
  AllContractors,
  ContractorsOverview,
  HistoricalPayments,
} from './features'
import { ContractorDetails } from './features/ContractorDetails/ContractorDetails'

export const contractorsRoutes: RouteObject[] = [
  {
    path: CONTRACTORS_ROUTE,
    element: <Contractors />,
    children: [
      {
        index: true,
        element: <ContractorsOverview />,
      },
      {
        path: CONTRACTORS_OVERVIEW_ROUTE,
        element: <ContractorsOverview />,
      },
      {
        path: CONTRACTORS_HISTORICAL_PAYMENTS_ROUTE,
        element: <HistoricalPayments />,
      },
      {
        path: CONTRACTORS_ALL_ROUTE,
        element: <AllContractors />,
      },
    ],
  },
  {
    path: CONTRACTORS_DETAILS_ROUTE,
    element: <ContractorDetails />,
  },
]
