import * as Sentry from '@sentry/react'
import axios from 'axios'
import { jwtDecode } from 'jwt-decode'

import { sessionStorageKeys } from '@/constants/keys'
import { BASE_API_DEV, BASE_API_PROD } from '@/constants/urls'
import { refreshToken } from '@/features/Auth/api'

import { generateFingerprint } from './fingerprint'
import { isDevelopment } from './utils'

const SENTRY_SKIP_STATUS_CODES = [400, 401, 403, 404, 409, 413]

const AUTH_HEADER = 'authorization'

export const api = axios.create({
  baseURL: isDevelopment() ? BASE_API_DEV : BASE_API_PROD,
})

api.interceptors.request.use(async (config) => {
  const accessToken = sessionStorage.getItem(sessionStorageKeys.accessToken)

  const deviceFingerprint = await generateFingerprint()

  config.headers['Device-Fingerprint'] = deviceFingerprint

  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`

    const decoded = jwtDecode(accessToken)

    const expires = decoded.exp ? decoded.exp * 1000 : 0

    // If the token expires in less than 5 minutes, refresh it
    if (expires - Date.now() < 5 * 60 * 1000) {
      refreshToken().then((response) => {
        const auth = response.headers.get(AUTH_HEADER)

        if (auth) {
          const [, token] = auth.split(' ')

          sessionStorage.setItem(sessionStorageKeys.accessToken, token)
        }
      })
    }
  }

  return config
})

api.interceptors.response.use(
  (response) => {
    const auth = response.headers[AUTH_HEADER]

    if (auth) {
      const [, token] = auth.split(' ')

      sessionStorage.setItem(sessionStorageKeys.accessToken, token)
    }

    return response
  },
  (error) => {
    if (
      error.response &&
      !SENTRY_SKIP_STATUS_CODES.includes(error.response.status)
    ) {
      Sentry.captureException(error)
    }

    return Promise.reject(error)
  },
)
