import { forwardRef, Ref, type SVGProps } from 'react'

const SvgSortBy = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      stroke={props.color || `currentColor`}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M10.69 7.85a1.834 1.834 0 1 0-3.054-2.032A1.834 1.834 0 0 0 10.69 7.85M10.69 18.165a1.834 1.834 0 1 0-3.054-2.032 1.834 1.834 0 0 0 3.054 2.032M13.31 13.007a1.834 1.834 0 1 1 3.054-2.032 1.834 1.834 0 0 1-3.054 2.032"
      clipRule="evenodd"
    />
    <path
      fill={props.color || `currentColor`}
      d="M11 6.076a.75.75 0 0 0 0 1.5zm8 1.5a.75.75 0 0 0 0-1.5zm-11.673 0a.75.75 0 0 0 0-1.5zM5 6.076a.75.75 0 0 0 0 1.5zm6 10.316a.75.75 0 0 0 0 1.5zm8 1.5a.75.75 0 0 0 0-1.5zm-11.673 0a.75.75 0 0 0 0-1.5zM5 16.392a.75.75 0 0 0 0 1.5zm8-3.658a.75.75 0 0 0 0-1.5zm-8-1.5a.75.75 0 0 0 0 1.5zm11.673 0a.75.75 0 0 0 0 1.5zm2.327 1.5a.75.75 0 0 0 0-1.5zm-8-5.158h8v-1.5h-8zm-3.673-1.5H5v1.5h2.327zM11 17.892h8v-1.5h-8zm-3.673-1.5H5v1.5h2.327zM13 11.234H5v1.5h8zm3.673 1.5H19v-1.5h-2.327z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgSortBy)
export default ForwardRef
