import { useEffect, useState } from 'react'

export function useCoordinatesFromAddress(address: string) {
  const [coordinates, setCoordinates] =
    useState<google.maps.LatLngLiteral | null>(null)

  useEffect(() => {
    const geocoder = new google.maps.Geocoder()

    geocoder.geocode(
      {
        address,
      },
      (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results) {
            const { lat, lng } = results[0].geometry.location

            setCoordinates({
              lat: lat(),
              lng: lng(),
            })
          }
        }
      },
    )
  }, [address])

  return coordinates
}
