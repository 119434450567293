import { BusinessIdentity, BusinessState, Identity } from '@/types/business'
import { CountryCode, CountryTaxId } from '@/types/country'

export const IDENTITY_MOCK_RESPONSE: Identity = {
  id: 'baa98df2-0831-4ac9-954f-346de5b66f41',
  email: 'john.doe@test.com',
  state: 'ACTIVE',
  firstName: 'John',
  lastName: 'Doe',
  phoneNumber: {
    internationalPhonePrefix: '+52',
    localPhoneNumber: '5512341234',
  },
}

export const BUSINESS_IDENTITY_MOCK_RESPONSE: BusinessIdentity = {
  countryOfIncorporation: CountryCode.MX,
  id: 'c002df21-b54c-4ace-8f54-52b4d3a2a2bb',
  legalName: 'John Doe AAA.',
  taxId: 'EBC451231AAA',
  taxIdType: CountryTaxId.MX,
  state: BusinessState.ACTIVE,
  addressCity: 'Mexico City',
  addressMunicipality: 'Mexico City',
  addressNeighborhood: 'Colonia del Valle',
  addressPostalCode: '03100',
  addressState: 'CDMX',
  addressStreet: 'Avenida Insurgentes Sur',
  addressStreetNumber: '123',
  dateOfIncorporation: '2020-01-01',
}

export const BUSINESS_IDENTITY_MOCK_ONBOARDING: BusinessIdentity = {
  countryOfIncorporation: CountryCode.MX,
  id: '826f70e8-88c3-4fdd-9514-66b3a9c6e47e',
  legalName: 'John Doe AAA.',
  taxId: 'EBC451231AAA',
  taxIdType: CountryTaxId.MX,
  state: BusinessState.ONBOARDING,
  addressCity: 'Mexico City',
  addressMunicipality: 'Mexico City',
  addressNeighborhood: 'Colonia del Valle',
  addressPostalCode: '03100',
  addressState: 'CDMX',
  addressStreet: 'Avenida Insurgentes Sur',
  addressStreetNumber: '123',
  dateOfIncorporation: '2020-01-01',
}
