import { defineMessage, FormattedMessage, MessageDescriptor } from 'react-intl'

import { formatDate } from '@/lib/date'
import { getImage } from '@/lib/images'
import { cn } from '@/lib/utils'
import { Check } from '@/shared/icons/outline'
import { Card, Typography } from '@/shared/ui'

import { CardDelivery, CardDeliveryState, Card as CardType } from '../types'

const DELIVERY_STATUSES = [
  CardDeliveryState.CREATED,
  CardDeliveryState.SHIPPED,
  CardDeliveryState.DELIVERED,
]

const messageByState: Record<CardDeliveryState, MessageDescriptor> = {
  [CardDeliveryState.CREATED]: defineMessage({
    id: 'cards.physical.delivery.ordered',
    defaultMessage: 'Ordered',
  }),
  [CardDeliveryState.SHIPPED]: defineMessage({
    id: 'cards.physical.delivery.shipped',
    defaultMessage: 'Shipped',
  }),
  [CardDeliveryState.DELIVERED]: defineMessage({
    id: 'cards.physical.delivery.delivered',
    defaultMessage: 'Delivered',
  }),
}

const dateTypeByState: Record<
  CardDeliveryState,
  'createdAt' | 'shippedAt' | 'deliveredAt'
> = {
  [CardDeliveryState.CREATED]: 'createdAt',
  [CardDeliveryState.SHIPPED]: 'shippedAt',
  [CardDeliveryState.DELIVERED]: 'deliveredAt',
}

const titleByState: Record<CardDeliveryState, MessageDescriptor> = {
  [CardDeliveryState.CREATED]: defineMessage({
    id: 'cards.physical.delivery.inProduction',
    defaultMessage: 'Card in production',
  }),
  [CardDeliveryState.SHIPPED]: defineMessage({
    id: 'cards.physical.delivery.cardShipped',
    defaultMessage: 'Card is on its way',
  }),

  [CardDeliveryState.DELIVERED]: defineMessage({
    id: 'cards.physical.delivery.cardDelivered',
    defaultMessage: 'Card has been delivered!',
  }),
}

type Props = {
  card?: CardType
  deliveryDetails?: CardDelivery
}

export const CardDeliveryProgress = ({ card, deliveryDetails }: Props) => {
  return (
    <Card size="medium" className="flex flex-col">
      <div className="flex items-center gap-4">
        <img
          className="h-6 w-8 object-contain"
          src={getImage({
            name: 'card-physical',
            category: 'cards',
          })}
          alt={card?.type}
          aria-hidden
        />
        {deliveryDetails?.status ? (
          <Typography variant="body-base" bold>
            <FormattedMessage {...titleByState[deliveryDetails?.status]} />
          </Typography>
        ) : null}
      </div>
      <div className="p-3" />

      <div className="grid grid-cols-3 gap-3">
        {DELIVERY_STATUSES.map((state, index) => {
          const deliveryStatusIndex = DELIVERY_STATUSES.indexOf(
            deliveryDetails?.status ?? CardDeliveryState.CREATED,
          )

          const isActiveState = deliveryDetails?.status === state

          return (
            <div key={state} className="flex flex-col">
              <div
                className={cn(
                  'h-2 w-full overflow-hidden rounded-full bg-neutral-gray-200',
                  {
                    'bg-neutral-gray-900': deliveryStatusIndex >= index,
                    'animate-pulse': isActiveState,
                  },
                )}
              />
              <div className="p-1" />
              <div className="flex items-center gap-1">
                <Typography
                  variant="body-small"
                  bold
                  className="text-neutral-gray-600"
                >
                  <FormattedMessage {...messageByState[state]} />
                </Typography>
                {isActiveState && (
                  <Check className="size-4 text-neutral-gray-600" />
                )}
              </div>
              <Typography
                variant="body-small"
                className="text-neutral-gray-600"
              >
                {deliveryDetails?.[dateTypeByState[state]]
                  ? formatDate(
                      deliveryDetails[dateTypeByState[state]] ?? '',
                      'dd LLL',
                    )
                  : '-'}
              </Typography>
            </div>
          )
        })}
      </div>
    </Card>
  )
}
