import { RouteObject } from 'react-router-dom'

import {
  ACCOUNT_TRANSACTIONS_ROUTE,
  ACCOUNTS_ROUTE,
  CREATE_ACCOUNT_ROUTE,
  SINGLE_ACCOUNT_ROUTE,
} from '@/constants/paths'

import { AccountDetails } from './AccountDetails'
import { Accounts } from './Accounts'
import { AccountTransactions } from './AccountTransactions'
import { CreateAccount } from './CreateAccount'

export const accountsRoutes: RouteObject[] = [
  {
    path: ACCOUNTS_ROUTE,
    element: <Accounts />,
  },
  {
    path: SINGLE_ACCOUNT_ROUTE,
    element: <AccountDetails />,
  },
  {
    path: ACCOUNT_TRANSACTIONS_ROUTE,
    element: <AccountTransactions />,
  },
]

export const accountActionsRoutes: RouteObject[] = [
  {
    path: CREATE_ACCOUNT_ROUTE,
    element: <CreateAccount />,
  },
]
