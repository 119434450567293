import { OnboardingMember } from '../../types'
import { VerificationBadge } from '../VerificationBadge'

type Props = {
  member: OnboardingMember
}

export const VerificationStateCell = ({ member }: Props) => {
  return (
    <div className="flex items-center">
      <VerificationBadge status={member.state} />
    </div>
  )
}
