import { useEffect, useMemo, useRef } from 'react'
import { useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link, useSearchParams } from 'react-router-dom'

import { CREATE_CARD_ROUTE } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { useBusinessRole } from '@/hooks/useBusinessRole'
import { useBusinessUser } from '@/hooks/useBusinessUser'
import { useSearchInput } from '@/hooks/useSearchInput'
import { useSearchParamsValue } from '@/hooks/useSearchParamsValue'
import { useSidebar } from '@/hooks/useSidebar'
import { generateRSAKeyPairForCards } from '@/lib/fingerprint'
import {
  AccountsBadge,
  AccountsFilter,
  ActiveFilters,
  CardholderBadge,
  CardholderFilter,
  CardStateBadge,
  CardStateFilter,
  FiltersWidget,
  MoveMoneyWidget,
  STATUS_FILTER_NAME,
  useCardsColumns,
  WithPermissions,
} from '@/shared/components'
import { Plus } from '@/shared/icons/outline'
import { Button, DataTable, SearchInput, Typography } from '@/shared/ui'
import { Permission } from '@/types/permissions'

import { getAllCards, getSingleCard, getUserCards } from './api'
import { CardDetailsSidebar } from './components/CardDetailsSidebar'
import { EmptyCardsWidget } from './components/EmptyCardsWidget'
import { Card, CardState } from './types'

export const Cards = () => {
  const intl = useIntl()
  const columns = useCardsColumns()
  const [search, setSearch, handleSearchQuery] = useSearchInput()
  const [searchParams, setSearchParams] = useSearchParams()
  const showTerminatedFilterOnceRef = useRef(false)

  const { isAdmin } = useBusinessRole()
  const businessUser = useBusinessUser()

  const [cardId] = useSearchParamsValue(['id'])

  const params = Object.fromEntries(
    [...searchParams].filter(([key]) => key !== 'id'),
  )

  const [allCardsQuery, userCardsQuery, singleCardQuery] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getAllCards, params],
        queryFn: () => getAllCards(params),
        select: (data: AxiosResponse<Card[]>) => data?.data,
        enabled: isAdmin,
      },
      {
        queryKey: [queryKeys.getUserCards, params],
        queryFn: () => getUserCards(params),
        select: (data: AxiosResponse<Card[]>) => data?.data,
        enabled: !isAdmin,
      },
      {
        queryKey: [queryKeys.getSingleCard, cardId],
        queryFn: () => getSingleCard({ id: cardId ?? '' }),
        select: (data: AxiosResponse<Card>) => data?.data,
        enabled: !!cardId && !!businessUser?.identityId,
      },
    ],
  })

  const cards = useMemo(
    () => allCardsQuery.data ?? userCardsQuery.data,
    [allCardsQuery.data, userCardsQuery.data],
  )

  useEffect(() => {
    generateRSAKeyPairForCards()
  }, [])

  useEffect(() => {
    if (!showTerminatedFilterOnceRef.current && cards) {
      const hasTerminatedCards = cards.some(
        (card) => card.state === CardState.TERMINATED,
      )

      const hasCardFilterParams = Object.keys(params).length > 0

      if (hasCardFilterParams) {
        showTerminatedFilterOnceRef.current = true

        return
      }

      if (hasTerminatedCards) {
        const defaultStatuses = [
          CardState.ACTIVE,
          CardState.CREATED,
          CardState.BLOCKED,
        ]

        setSearchParams(
          (params) => {
            params.set(STATUS_FILTER_NAME, defaultStatuses.join(','))
            showTerminatedFilterOnceRef.current = true
            return params
          },
          { preventScrollReset: true },
        )
      }
    }
  }, [allCardsQuery.data, cards, params, setSearchParams])

  const [showDetails, setShowDetails] = useSidebar({ key: 'id' })

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full flex-wrap justify-between gap-6">
        <div className="flex flex-col gap-2">
          <Typography variant="h3">
            <FormattedMessage
              id="dashboard.cards.title"
              defaultMessage="Cards"
            />
          </Typography>
          <Typography className="text-neutral-gray-600">
            <FormattedMessage
              id="dashboard.cards.subtitle"
              defaultMessage="Create and manage cards for your corporate spending"
            />
          </Typography>
        </div>

        <MoveMoneyWidget />
      </div>

      <div className="p-4" />

      <div className="flex flex-wrap gap-3 md:flex-nowrap">
        <SearchInput
          value={search}
          onChange={(value) => {
            setSearch(value)
            handleSearchQuery(value)
          }}
        />
        <FiltersWidget>
          <CardholderFilter />
          <AccountsFilter />
          <CardStateFilter />
        </FiltersWidget>
        <WithPermissions permissions={[Permission.CARDS_MANAGEMENT]}>
          <Button
            leftIcon={<Plus className="size-5" />}
            asChild
            variant="tertiary"
          >
            <Link to={CREATE_CARD_ROUTE}>
              <FormattedMessage
                id="action.createCard"
                defaultMessage="Create card"
              />
            </Link>
          </Button>
        </WithPermissions>
      </div>

      <div className="p-3" />

      <ActiveFilters>
        <CardholderBadge />
        <AccountsBadge />
        <CardStateBadge />
      </ActiveFilters>

      {cards?.length === 0 && Object.keys(params).length === 0 ? (
        <EmptyCardsWidget />
      ) : (
        <DataTable
          emptyState={{
            title: intl.formatMessage({
              id: 'cards.search.notFound',
              defaultMessage: 'No cards found',
            }),
            description: intl.formatMessage({
              id: 'cards.search.notFound.description',
              defaultMessage:
                'There were no cards that matched your search criteria',
            }),
          }}
          loaderOptions={{ rows: 3 }}
          isLoading={allCardsQuery.isFetching || userCardsQuery.isFetching}
          columns={columns}
          data={cards ?? []}
          onRowClick={(card) => {
            setSearchParams(
              (params) => {
                params.set('id', card.id)
                return params
              },
              { preventScrollReset: true },
            )
          }}
        />
      )}

      <CardDetailsSidebar
        card={singleCardQuery.data}
        isOpen={showDetails}
        onOpenChange={() => {
          setShowDetails((d) => !d)
          setSearchParams(
            (params) => {
              params.delete('id')
              return params
            },
            { preventScrollReset: true },
          )
        }}
      />
    </div>
  )
}
