import { Currency } from '@/constants/currency'

export enum PaymentLimitType {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export type PaymentLimit = {
  businessId: string
  periodTransferLimit?: {
    limitType: PaymentLimitType
    limitAmount: number
    usedAmount: number
    limitCurrency: Currency
    periodStartDate: string
    periodResetDate: string
  }
}
