import { api } from '@/lib/api'

type ScheduleBulkPaymentRequest = {
  bulkPaymentId: string
  walletId: string
  shouldNotifyRecipients: boolean
  label?: string
  selectedPayments: string[]
  otp?: string
}

export function scheduleBulkPayment(data: ScheduleBulkPaymentRequest) {
  const { bulkPaymentId, ...rest } = data

  return api.post<void>(`/v1/me/bulk-payments/${bulkPaymentId}/schedule`, rest)
}
