import { CountryWithFlag } from '@/shared/components'

import { Recipient } from '../../types'

type Props = {
  recipient: Recipient
}

export const CountryCell = ({ recipient }: Props) => {
  return (
    <div className="flex items-center">
      <CountryWithFlag country={recipient.country} />
    </div>
  )
}
