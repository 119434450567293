import { useQuery } from '@tanstack/react-query'
import { FormattedMessage } from 'react-intl'
import { generatePath, useSearchParams } from 'react-router-dom'

import { CARDS_TRANSACTIONS_ROUTE } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { getTransactions } from '@/features/Transactions/api'
import { SingleTransaction } from '@/features/Transactions/types'
import { getImage } from '@/lib/images'
import {
  CARDS_ID_FILTER_NAME,
  TransactionsShortList,
} from '@/shared/components'
import { Card, Skeleton, Typography } from '@/shared/ui'

export const CardTransactionShortList = () => {
  const [searchParams] = useSearchParams()
  const cardId = searchParams.get('id')

  const cardTransactionsQuery = useQuery({
    queryKey: [queryKeys.getCardTransactions, cardId],
    queryFn: () =>
      getTransactions({ [CARDS_ID_FILTER_NAME]: cardId ?? undefined }),
    select: (data) => data?.data as SingleTransaction[],
    enabled: !!cardId,
  })

  if (cardTransactionsQuery.isPending) {
    return (
      <>
        <div className="p-3" />
        <div className="flex flex-col">
          <div className="flex justify-between">
            <Skeleton className="h-6 w-36" />
            <Skeleton className="h-6 w-36" />
          </div>

          <div className="p-1" />

          <Card size="large" className="flex flex-col gap-6">
            <div className="flex justify-between">
              <Skeleton className="h-6 w-36" />
              <Skeleton className="h-6 w-36" />
            </div>
            <div className="flex justify-between">
              <Skeleton className="h-6 w-36" />
              <Skeleton className="h-6 w-36" />
            </div>
          </Card>
        </div>
      </>
    )
  }

  if (cardTransactionsQuery.data?.length === 0) {
    return (
      <Card
        className="relative flex w-full items-center justify-between overflow-hidden bg-white"
        size="large"
      >
        <div className="flex flex-col gap-1">
          <Typography bold>
            <FormattedMessage
              id="cards.search.noTransactions"
              defaultMessage="You don't have any transactions yet!"
            />
          </Typography>
          <Typography>
            <FormattedMessage
              id="cards.search.noTransactions.description"
              defaultMessage="Start spending and see your transactions here"
            />
          </Typography>
        </div>
        <img
          className="absolute -bottom-6 right-8 w-40"
          src={getImage({
            name: 'empty-card-transactions',
            category: 'cards',
          })}
          alt=""
          aria-hidden={true}
        />
      </Card>
    )
  }

  return (
    <>
      <div className="p-3" />

      <TransactionsShortList
        transactions={cardTransactionsQuery.data ?? []}
        path={generatePath(CARDS_TRANSACTIONS_ROUTE, {
          id: cardId,
        })}
        limit={4}
      />
    </>
  )
}
