import { motion } from 'framer-motion'
import { useIntl } from 'react-intl'

import { X } from '../../icons/outline'
import { Filter } from '../../icons/solid'
import { Button, Typography } from '../../ui'

type Props = {
  children: React.ReactNode
  name: string
  onClick: () => void
}

export const FilterBadge = ({ children, onClick, name }: Props) => {
  const intl = useIntl()
  return (
    <motion.div
      initial={{ opacity: 0, x: -10 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -10 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      className="flex items-center gap-1 rounded-lg bg-neutral-gray-200 p-2"
    >
      <Filter className="h-4 w-4" />

      <Typography>{name}</Typography>
      <Typography>=</Typography>

      <Typography>{children}</Typography>

      <Button
        variant="ghost"
        size="inline"
        className="size-4 shrink-0"
        aria-label={intl.formatMessage({
          id: 'action.deleteFilter',
          defaultMessage: 'Delete filter',
        })}
        onClick={onClick}
      >
        <X />
      </Button>
    </motion.div>
  )
}
