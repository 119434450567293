import { createContext } from 'react'

import { Locale } from '@/constants/locales'

type State = {
  locale: Locale
  changeLocale: (locale: string) => void
}

export const LocaleContext = createContext<State | undefined>(undefined)
