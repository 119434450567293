import { forwardRef, Ref, type SVGProps } from 'react'

const SvgSortUp = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      stroke={props.color || `currentColor`}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m8.245 13.186 2.866-3.79a.942.942 0 0 1 1.535 0l3.133 3.79a1.117 1.117 0 0 1-.768 1.81h-6a1.117 1.117 0 0 1-.766-1.81"
      clipRule="evenodd"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgSortUp)
export default ForwardRef
