import { FormattedMessage } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'

import { ADD_TEAM_MEMBER_ROUTE } from '@/constants/paths'
import { TeamMember } from '@/features/Team/types'
import { getFullName } from '@/lib/typography'
import { cn } from '@/lib/utils'
import { BusinessUser } from '@/types/business'
import { Permission } from '@/types/permissions'

import { Plus } from '../icons/outline'
import {
  Badge,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Typography,
} from '../ui'

import { WithPermissions } from './WithPermissions'

type Props = {
  businessUser?: BusinessUser
  value: string
  onChange: (v: string) => void
  user?: TeamMember
  users?: TeamMember[]
  label: string
  showLabel?: boolean
  hasError?: boolean
}

export const UserSelect = ({
  businessUser,
  user,
  users,
  label,
  onChange,
  value,
  hasError = false,
  showLabel = false,
}: Props) => {
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <Select
      disabled={users?.length === 0}
      value={value}
      onValueChange={(v) => {
        if (v === 'new-team-member-action') {
          navigate(ADD_TEAM_MEMBER_ROUTE, {
            state: { from: location },
          })
          return
        }

        onChange(v)
      }}
    >
      <SelectTrigger
        className={cn('', {
          'border border-primary-error': hasError,
        })}
      >
        <>
          {user ? (
            <div className="flex flex-col items-start">
              {showLabel ? (
                <Typography
                  className={cn('', { 'text-primary-error': hasError })}
                  variant="body-tiny"
                >
                  {label}
                </Typography>
              ) : null}
              <Typography className="text-neutral-gray-900">
                {getFullName(user)}
              </Typography>
            </div>
          ) : (
            <SelectValue placeholder={label} />
          )}
        </>
      </SelectTrigger>

      <SelectContent>
        <WithPermissions permissions={[Permission.TRANSFERS]}>
          <SelectItem
            value="new-team-member-action"
            className="flex items-center gap-3 px-2 py-3"
          >
            <div className="flex items-center gap-1">
              <Plus className="size-5 text-primary" />
              <Typography className="font-semibold text-primary">
                <FormattedMessage
                  id="action.addTeamMember"
                  defaultMessage="Add team member"
                />
              </Typography>
            </div>
          </SelectItem>
        </WithPermissions>

        {users?.map((user) => (
          <SelectItem
            disabled={user.identityId === value}
            key={user.identityId}
            value={user.identityId}
            className="flex items-center gap-3 px-2 py-3"
          >
            <Typography className="text-neutral-gray-900">
              {getFullName(user)}
            </Typography>
            {businessUser?.identityId === user.identityId && (
              <Badge className="capitalize" variant="neutral">
                <Typography bold variant="body-small">
                  <FormattedMessage id="label.you" defaultMessage="You" />
                </Typography>
              </Badge>
            )}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
