import { FormattedMessage } from 'react-intl'

import { useBusinessRole } from '@/hooks/useBusinessRole'
import { getImage } from '@/lib/images'
import { Card, Typography } from '@/shared/ui'

export const EmptyTasks = () => {
  const { isAdmin } = useBusinessRole()

  return (
    <Card
      className="flex w-full flex-wrap items-center justify-between gap-3 md:flex-nowrap md:gap-0"
      size="large"
    >
      <div className="flex flex-wrap items-center gap-8">
        <img
          className="w-24 object-contain"
          src={getImage({ category: 'tasks', name: 'empty-tasks' })}
          alt=""
          aria-hidden
        />
        <div className="flex flex-col gap-1">
          <Typography bold>
            {isAdmin ? (
              <FormattedMessage
                id="tasks.completed.title"
                defaultMessage="You are all done reviewing your tasks!"
              />
            ) : (
              <FormattedMessage
                id="tasks.ops.completed.title"
                defaultMessage="You don't have any pending requests!"
              />
            )}
          </Typography>
          <Typography>
            {isAdmin ? (
              <FormattedMessage
                id="tasks.completed.subtitle"
                defaultMessage="We will notify you over email if a new request comes in"
              />
            ) : (
              <FormattedMessage
                id="tasks.ops.completed.subtitle"
                defaultMessage="We will send you an email if any request is pending for more than 48hs"
              />
            )}
          </Typography>
        </div>
      </div>
    </Card>
  )
}
