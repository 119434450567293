import { forwardRef, Ref, type SVGProps } from 'react'

const SvgChartTrendUp = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill="#5c5c77"
      d="M4.614 8.357a.75.75 0 0 0 .772 1.286zM10 6l.45-.6a.75.75 0 0 0-.836-.043zm4 3-.45.6a.75.75 0 0 0 .919-.014zm5.469-3.414a.75.75 0 1 0-.938-1.172zM17.25 19a.75.75 0 0 0 1.5 0zm1.5-8a.75.75 0 0 0-1.5 0zm-9.5 8a.75.75 0 0 0 1.5 0zm1.5-8a.75.75 0 0 0-1.5 0zm2.5 8a.75.75 0 0 0 1.5 0zm1.5-5a.75.75 0 0 0-1.5 0zm-9.5 5a.75.75 0 0 0 1.5 0zm1.5-5a.75.75 0 0 0-1.5 0zM5.386 9.643l5-3-.772-1.286-5 3zM9.55 6.6l4 3 .9-1.2-4-3zm4.919 2.986 5-4-.938-1.172-5 4zM18.75 19v-8h-1.5v8zm-8 0v-8h-1.5v8zm4 0v-5h-1.5v5zm-8 0v-5h-1.5v5z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgChartTrendUp)
export default ForwardRef
