import { BankAccount } from '@/features/Accounts/types'
import { Card, Skeleton } from '@/shared/ui'

import { BankDetails } from './BankDetails'

type Props = {
  accountBanks: BankAccount[]
  walletId?: string
}

export const CountryBankDetails = ({ accountBanks, walletId }: Props) => {
  return (
    <>
      {accountBanks.map((details, index) => (
        <BankDetails key={index} details={details} walletId={walletId} />
      ))}
    </>
  )
}

const CountryDetailsSkeleton = ({ rows = 3 }: { rows?: number }) => {
  return (
    <div>
      <div className="flex items-center gap-3">
        <Skeleton className="h-[32px] w-32" />
      </div>

      <div className="p-1" />
      <Card className="flex flex-col gap-4" size="medium">
        {Array.from({ length: rows }).map((_, index) => (
          <div key={index} className="flex w-full justify-between">
            <Skeleton className="h-[21px] w-32" />
            <Skeleton className="h-[21px] w-32" />
          </div>
        ))}

        <Skeleton className="mx-auto h-[21px] w-48" />
      </Card>
    </div>
  )
}

CountryBankDetails.Skeleton = CountryDetailsSkeleton
