import { forwardRef, Ref, type SVGProps } from 'react'

const SvgInvoice = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 22 22"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill={props.color || `currentColor`}
      fillRule="evenodd"
      d="M17.3 19.55H5.6a3.15 3.15 0 0 1-3.15-3.15V4.649c0-.96.95-1.57 1.786-1.28q.18.063.349.181l.157.113a2.26 2.26 0 0 0 2.621-.004 3.17 3.17 0 0 1 3.673 0c.784.56 1.84.562 2.622.004l.157-.113c.894-.638 2.135 0 2.135 1.099v6.801h3.15c.373 0 .675.302.675.675v4.95A2.475 2.475 0 0 1 17.3 19.55m-1.125-6.75v4.275a1.125 1.125 0 0 0 2.25 0V12.8zM12.35 8.975a.675.675 0 0 0-.675-.675h-5.4a.675.675 0 0 0 0 1.35h5.4a.675.675 0 0 0 .675-.675m-.9 2.7a.675.675 0 0 0-.675-.675h-4.5a.675.675 0 1 0 0 1.35h4.5a.675.675 0 0 0 .675-.675m.225 2.025a.675.675 0 0 1 0 1.35h-5.4a.675.675 0 1 1 0-1.35z"
      clipRule="evenodd"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgInvoice)
export default ForwardRef
