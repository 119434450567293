import { api } from '@/lib/api'

type RatesRequest = {
  from: string
  to: string
}

export type FXRatesResponse = {
  symbol: string
  bidRate: number // buy rate
  askRate: number // sell rate
  midRate: number
  rateChangeToday: number
  rateChangeTodayPercentage: number
}

export function getFXRates({ from, to }: RatesRequest) {
  return api.get<FXRatesResponse>(`/v1/me/fx-rate?ticker=${from}_${to}`)
}
