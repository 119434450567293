import { FormattedMessage } from 'react-intl'

import { badgeVariantByCardState } from '@/features/Cards/constants'
import { Card } from '@/features/Cards/types'

import { StatusBadge } from '../StatusBadge'

type Props = {
  card: Card
}

export const CardStateCell = ({ card }: Props) => {
  return (
    <div className="flex items-center">
      <StatusBadge
        variant={badgeVariantByCardState[card.state]}
        title={
          <FormattedMessage
            id="card.state.label"
            defaultMessage="{state, select, ACTIVE {Active} CREATED {Inactive} BLOCKED {Frozen} TERMINATED {Terminated} other {}}"
            values={{ state: card.state }}
          />
        }
      />
    </div>
  )
}
