import { api } from '@/lib/api'

import { CardDelivery } from '../types'

export async function getCardDeliveryDetails({
  cardDeliveryId,
}: {
  cardDeliveryId: string
}) {
  return api.get<CardDelivery>(`/v1/me/card-deliveries/${cardDeliveryId}`)
}
