import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { FormattedMessage } from 'react-intl'
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { Link, useParams } from 'react-router-dom'

import { CONTRACTORS_ALL_ROUTE } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { getOnboardingAddress } from '@/lib/address'
import { getFullName } from '@/lib/typography'
import { CountryName, MoveMoneyWidget, Widget } from '@/shared/components'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
  Button,
  Details,
  MotionDiv,
  Skeleton,
  Typography,
} from '@/shared/ui'

import { getContractor } from '../../api'

import { ContractorToDos } from './components/ContractorToDos'
import { ContractsSection } from './components/ContractsSection'

export const ContractorDetails = () => {
  const { id: contractorId } = useParams()

  const contractorQuery = useQuery({
    queryKey: [queryKeys.getContractor, contractorId],
    queryFn: () => getContractor({ id: contractorId ?? '' }),
    select: (data) => data.data,
    enabled: !!contractorId,
  })

  const latestContract = useMemo(() => {
    if (contractorQuery.data) {
      return contractorQuery.data.contracts[0]
    }

    return null
  }, [contractorQuery.data])

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full flex-wrap justify-between gap-6">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbLink asChild>
              <Button variant="ghost" size="inline" asChild>
                <Link to={CONTRACTORS_ALL_ROUTE}>
                  <FormattedMessage
                    id="dashboard.contractors.title"
                    defaultMessage="Contractors"
                  />
                </Link>
              </Button>
            </BreadcrumbLink>

            <BreadcrumbSeparator />

            <BreadcrumbItem>
              {contractorQuery.isPending ? (
                <Skeleton className="h-[21px] w-36" />
              ) : (
                <Typography>
                  {getFullName(contractorQuery.data?.personalDetails)}
                </Typography>
              )}
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>

        <MoveMoneyWidget />
      </div>

      <div className="p-4" />

      <div className="flex justify-between">
        <div className="flex flex-col gap-2">
          {contractorQuery.isPending ? (
            <Skeleton className="h-[33px] w-36" />
          ) : (
            <Typography variant="h3">
              {getFullName(contractorQuery.data?.personalDetails)}
            </Typography>
          )}
          {contractorQuery.isPending ? (
            <Skeleton className="h-[21px] w-36" />
          ) : (
            <Typography className="text-neutral-gray-600">
              {latestContract?.contractorRole}
            </Typography>
          )}
        </div>
      </div>

      <div className="p-4" />

      <MotionDiv className="grid grid-cols-3 gap-8">
        <div className="flex flex-col gap-8">
          <Widget
            title={
              <FormattedMessage
                defaultMessage="Personal information"
                id="label.personalInformation"
              />
            }
          >
            <Details>
              <Details.Label>
                <FormattedMessage defaultMessage="Email" id="label.email" />
              </Details.Label>
              {contractorQuery.data ? (
                <Details.Value>
                  {contractorQuery.data.personalDetails.email}
                </Details.Value>
              ) : (
                <Details.Skeleton />
              )}
            </Details>
            <Details>
              <Details.Label>
                <FormattedMessage
                  defaultMessage="Phone number"
                  id="label.phoneNumber"
                />
              </Details.Label>
              {contractorQuery.data ? (
                <Details.Value>
                  {formatPhoneNumberIntl(
                    contractorQuery.data.personalDetails
                      .internationalPhonePrefix +
                      contractorQuery.data.personalDetails.localPhoneNumber,
                  )}
                </Details.Value>
              ) : (
                <Details.Skeleton />
              )}
            </Details>
            <Details>
              <Details.Label>
                <FormattedMessage
                  defaultMessage="Birthday"
                  id="label.birthday"
                />
              </Details.Label>
              {contractorQuery.data ? (
                <Details.Value>
                  {contractorQuery.data.personalDetails.dateOfBirth ?? '-'}
                </Details.Value>
              ) : (
                <Details.Skeleton />
              )}
            </Details>
            <Details>
              <Details.Label>
                <FormattedMessage
                  defaultMessage="Country of citizenship"
                  id="label.countryOfCitizenship"
                />
              </Details.Label>
              {contractorQuery.data ? (
                <CountryName
                  countryCode={
                    contractorQuery.data.personalDetails.countryOfCitizenship
                  }
                />
              ) : (
                <Details.Skeleton />
              )}
            </Details>
            <Details>
              <Details.Label>
                <FormattedMessage id="label.address" defaultMessage="Address" />
              </Details.Label>
              {contractorQuery.data ? (
                <Details.Value className="whitespace-break-spaces text-right">
                  {contractorQuery.data.personalDetails.residentialAddress
                    ? getOnboardingAddress(
                        contractorQuery.data.personalDetails.residentialAddress,
                      )
                    : '-'}
                </Details.Value>
              ) : (
                <Details.Skeleton />
              )}
            </Details>
          </Widget>
          <Widget
            title={
              <FormattedMessage
                defaultMessage="Tax information"
                id="label.taxInformation"
              />
            }
          >
            <Details>
              <Details.Label>
                <FormattedMessage
                  defaultMessage="Tax residence"
                  id="label.taxResidence"
                />
              </Details.Label>

              {contractorQuery.data ? (
                <CountryName
                  countryCode={
                    contractorQuery.data.taxDetails.countryOfTaxResidence
                  }
                />
              ) : (
                <Details.Skeleton />
              )}
            </Details>
            <Details>
              <Details.Label>
                <FormattedMessage
                  defaultMessage="Tax identification number"
                  id="label.taxIdentificationNumber"
                />
              </Details.Label>
              {contractorQuery.data ? (
                <Details.Value>
                  {contractorQuery.data.taxDetails.taxId ?? '-'}
                </Details.Value>
              ) : (
                <Details.Skeleton />
              )}
            </Details>
            <Details>
              <Details.Label>
                <FormattedMessage
                  defaultMessage="Tax form"
                  id="label.taxForm"
                />
              </Details.Label>
              {contractorQuery.data ? (
                <Details.Value>
                  {contractorQuery.data.taxDetails.taxForm ?? '-'}
                </Details.Value>
              ) : (
                <Details.Skeleton />
              )}
            </Details>
          </Widget>
        </div>
        <div className="col-span-2 flex flex-col gap-8">
          <ContractorToDos />

          <ContractsSection contractor={contractorQuery.data} />
        </div>
      </MotionDiv>
    </div>
  )
}
