import * as React from 'react'
import * as SwitchPrimitives from '@radix-ui/react-switch'
import { FormattedMessage } from 'react-intl'

import { Locale } from '@/constants/locales'
import { cn } from '@/lib/utils'

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> & {
    className?: string
    checked?: boolean
    locale?: Locale
  }
>(({ className, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={cn(
      'peer relative inline-flex h-8 w-[88px] shrink-0 cursor-pointer items-center rounded-lg bg-neutral-gray-100 transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-neutral-gray-600 focus-visible:ring-offset-1 focus-visible:ring-offset-white disabled:cursor-not-allowed disabled:opacity-50',
      className,
    )}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      className={cn(
        'pointer-events-none relative block h-[28px] w-[42px] rounded-lg bg-white shadow-switch ring-0 transition-transform data-[state=checked]:translate-x-[43px] data-[state=unchecked]:translate-x-[3px]',
      )}
    />

    <span
      className={cn('absolute left-[16px] text-xs', {
        'text-neutral-gray-600': props.checked,
        'text-neutral-gray-900': !props.checked,
      })}
    >
      <FormattedMessage id="label.no" defaultMessage="No" />
    </span>
    <span
      className={cn('absolute right-[14px] text-xs', {
        'text-neutral-gray-600': !props.checked,
        'text-neutral-gray-900': props.checked,
        'right-[19px]': props.locale === Locale.ES,
      })}
    >
      <FormattedMessage id="label.yes" defaultMessage="Yes" />
    </span>
  </SwitchPrimitives.Root>
))
Switch.displayName = SwitchPrimitives.Root.displayName

export { Switch }
