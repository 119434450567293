import { forwardRef, Ref, type SVGProps } from 'react'

const SvgBulkArrows = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 20 21"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill={props.color || `currentColor`}
      stroke="#000"
      strokeLinecap="round"
      strokeWidth={0.5}
      d="M8.75 3.309a.483.483 0 0 1 0-.67.453.453 0 0 1 .653 0l2.462 2.526c.18.185.18.485 0 .67L9.403 8.36a.453.453 0 0 1-.652 0 .483.483 0 0 1 0-.67l1.673-1.717H4.462A.47.47 0 0 1 4 5.5c0-.262.207-.474.462-.474h5.962zM14.75 8.309a.48.48 0 0 1 0-.67.453.453 0 0 1 .653 0l2.462 2.526c.18.185.18.485 0 .67l-2.462 2.526a.453.453 0 0 1-.652 0 .48.48 0 0 1 0-.67l1.673-1.717h-5.962A.47.47 0 0 1 10 10.5c0-.262.207-.474.461-.474h5.963zM8.75 13.309a.483.483 0 0 1 0-.67.453.453 0 0 1 .653 0l2.462 2.526c.18.185.18.485 0 .67L9.403 18.36a.453.453 0 0 1-.652 0 .483.483 0 0 1 0-.67l1.673-1.717H4.462A.47.47 0 0 1 4 15.5c0-.262.207-.474.462-.474h5.962z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgBulkArrows)
export default ForwardRef
