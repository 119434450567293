import { api } from '@/lib/api'

import { TaskState } from '../types'

export function approveTask({ id, otp }: { id: string; otp?: string }) {
  return api.post<{
    id: string
    state: TaskState.APPROVED
  }>(`/v1/me/business-tasks/${id}/approve`, { otp })
}
