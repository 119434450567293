import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { FormattedMessage } from 'react-intl'
import { useSearchParams } from 'react-router-dom'

import { queryKeys } from '@/constants/queryKeys'
import { getTeamMembers } from '@/features/Team'
import { TeamState } from '@/features/Team/types'
import { useBusinessRole } from '@/hooks/useBusinessRole'
import { useBusinessUser } from '@/hooks/useBusinessUser'
import { getFullName } from '@/lib/typography'
import { Permission } from '@/types/permissions'

import { Filter, User } from '../../icons/solid'
import {
  Button,
  DropdownMenuCheckboxItem,
  DropdownMenuGroup,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  Spinner,
} from '../../ui'

import { IDENTITY_FILTER_NAME } from './data'

export const CardholderFilter = () => {
  const { isAdmin } = useBusinessRole()
  const businessUser = useBusinessUser()

  const teamMembersQuery = useQuery({
    queryKey: [queryKeys.getTeamMembers],
    queryFn: () => getTeamMembers(),
    select: (data) =>
      data?.data.filter(
        (member) =>
          member.permissions.includes(Permission.CARDS_ACCESS) &&
          member.state !== TeamState.TERMINATED,
      ),
    enabled: isAdmin,
  })

  const [searchParams, setSearchParams] = useSearchParams()

  const members = useMemo(() => {
    if (teamMembersQuery.data) {
      return teamMembersQuery.data
    }

    if (businessUser) {
      return [businessUser]
    }

    return []
  }, [teamMembersQuery.data, businessUser])

  function handleSearchParams(value: string) {
    setSearchParams(
      (params) => {
        const typeParams = params.get(IDENTITY_FILTER_NAME)

        if (!typeParams) {
          params.set(IDENTITY_FILTER_NAME, value)
          return params
        }

        if (typeParams === value) {
          params.delete(IDENTITY_FILTER_NAME)
          return params
        }

        if (!typeParams.includes(value)) {
          const newType = `${typeParams},${value}`
          params.set(IDENTITY_FILTER_NAME, newType)
          return params
        }

        const newType = typeParams
          .split(',')
          .filter((type) => type !== value)
          .join(',')

        params.set(IDENTITY_FILTER_NAME, newType)

        return params
      },
      {
        preventScrollReset: true,
      },
    )
  }

  return (
    <DropdownMenuGroup>
      <DropdownMenuSub>
        <DropdownMenuSubTrigger asChild>
          <Button
            className="h-10 w-full justify-between font-normal hover:text-neutral-gray-900 focus-visible:ring-transparent"
            variant="ghost"
          >
            <span className="flex items-center gap-2">
              <User className="size-4" />
              <FormattedMessage
                id="label.cardholder"
                defaultMessage="Cardholder"
              />
            </span>
            {searchParams.get(IDENTITY_FILTER_NAME) && (
              <Filter className="size-4" />
            )}
          </Button>
        </DropdownMenuSubTrigger>
        <DropdownMenuPortal>
          <DropdownMenuSubContent
            alignOffset={0}
            className="max-h-96 w-52 overflow-y-auto"
          >
            <DropdownMenuCheckboxItem
              onSelect={(event) => event.preventDefault()}
              checked={searchParams.get(IDENTITY_FILTER_NAME) === null}
              onCheckedChange={() =>
                setSearchParams(
                  (params) => {
                    params.delete(IDENTITY_FILTER_NAME)
                    return params
                  },
                  { preventScrollReset: true },
                )
              }
            >
              <FormattedMessage
                id="label.selectAll"
                defaultMessage="Select all"
              />
            </DropdownMenuCheckboxItem>
            {teamMembersQuery.isFetching ? (
              <div className="flex h-10 items-center justify-center">
                <Spinner />
              </div>
            ) : (
              members?.map((member) => (
                <DropdownMenuCheckboxItem
                  key={member.identityId}
                  onSelect={(event) => event.preventDefault()}
                  checked={searchParams
                    .get(IDENTITY_FILTER_NAME)
                    ?.includes(member.identityId)}
                  onCheckedChange={() => handleSearchParams(member.identityId)}
                >
                  {getFullName(member)}
                </DropdownMenuCheckboxItem>
              ))
            )}
          </DropdownMenuSubContent>
        </DropdownMenuPortal>
      </DropdownMenuSub>
    </DropdownMenuGroup>
  )
}
