import { http, HttpResponse } from 'msw'

import { BASE_API_DEV } from '@/constants/urls'

import { TaskState } from '../../types'

import { MOCK_WITHDRAWAL_TASK_DETAILS, MOCKED_TASKS } from './tasks.mocks'

export const tasksHandlers = [
  http.get(`${BASE_API_DEV}/v1/me/business-tasks/search`, async () => {
    return HttpResponse.json(MOCKED_TASKS)
  }),

  http.get(`${BASE_API_DEV}/v1/me/business-tasks/count`, async () => {
    return HttpResponse.json({ pendingTasksCount: 1 })
  }),

  http.get(`${BASE_API_DEV}/v1/my/business-tasks/search`, async () => {
    return HttpResponse.json(MOCKED_TASKS)
  }),

  http.get(`${BASE_API_DEV}/v1/me/business-tasks/:id`, async () => {
    return HttpResponse.json(MOCKED_TASKS[0])
  }),

  http.post(`${BASE_API_DEV}/v1/my/business-tasks/:id/cancel`, async () => {
    return HttpResponse.json({
      id: '0a12c153-9286-4407-8f3d-a658fef98c22',
      state: TaskState.CANCELLED,
    })
  }),

  http.post(`${BASE_API_DEV}/v1/me/business-tasks/:id/decline`, async () => {
    return HttpResponse.json({
      id: '0a12c153-9286-4407-8f3d-a658fef98c22',
      state: TaskState.DECLINED,
    })
  }),

  http.post(`${BASE_API_DEV}/v1/me/business-tasks/:id/approve`, async () => {
    return HttpResponse.json({
      id: '0a12c153-9286-4407-8f3d-a658fef98c22',
      state: TaskState.APPROVED,
    })
  }),

  http.post(`${BASE_API_DEV}/v1/me/business-tasks/bulk-approve`, async () => {
    return HttpResponse.json([
      {
        id: '0a12c153-9286-4407-8f3d-a658fef98c22',
        state: TaskState.APPROVED,
      },
      {
        id: '0a12c153-9286-4407-8f3d-a658fef98c22',
        state: TaskState.APPROVED,
      },
    ])
  }),

  http.get(`${BASE_API_DEV}/v1/me/withdrawal-quotes/:id/approval`, async () => {
    return HttpResponse.json(MOCK_WITHDRAWAL_TASK_DETAILS)
  }),
]
