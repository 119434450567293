import { OTP_ERROR } from '@/constants/errors'
import { ApiError } from '@/types/global'

export function isAPIError(error: Error): error is ApiError {
  return (error as ApiError).response !== undefined
}

export function hasOTPRequiredError(error: Error) {
  if (isAPIError(error)) {
    if (error?.response?.data?.error === OTP_ERROR) {
      return true
    }
  }

  return false
}
