import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { toast } from 'sonner'

import { backendErrorMessages, errorMessages } from '@/constants/messages'
import { ApiError } from '@/types/global'

function handleSpecificErrorCodes(error: ApiError) {
  const code = error.response?.data?.error

  switch (code) {
    case 'IDENTITY_LOCKED_ERROR':
      return {
        accountLockedForMinutes:
          error.response?.data?.details?.accountLockedForMinutes,
      }

    default:
      return {}
  }
}

export function useErrorToast() {
  const intl = useIntl()

  return useCallback(
    (error: ApiError) => {
      const errorMessage = intl.formatMessage(
        errorMessages['validation.generic.error'],
      )

      const errorCode = error.response?.data?.error

      if (errorCode) {
        if (backendErrorMessages[errorCode] === undefined) {
          if (error.response?.data?.description) {
            return toast.error(error.response.data.description)
          }

          return toast.error(errorMessage)
        }

        const backendErrorMessage = intl.formatMessage(
          backendErrorMessages[errorCode],
          {
            ...handleSpecificErrorCodes(error),
          },
        )

        return toast.error(backendErrorMessage)
      }

      return toast.error(errorMessage)
    },
    [intl],
  )
}
