import { Big } from 'big.js'

import { Currency } from '@/constants/currency'

export function formatMoney(amount: number | string, currency = Currency.USD) {
  const amountAsNumber =
    typeof amount === 'string' ? Big(amount).toNumber() : amount

  if (Number.isNaN(amountAsNumber)) {
    return '$0'
  }

  const decimalFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  })

  const nonDecimalFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  const numberToFixed = Number(Big(amountAsNumber).toFixed(2))

  const hasDecimal = numberToFixed % 1 !== 0

  if (hasDecimal) {
    return decimalFormatter.format(numberToFixed)
  }

  return nonDecimalFormatter.format(numberToFixed)
}

export function formatCurrency(currency?: Currency): Currency {
  switch (currency) {
    case Currency.USDC:
      return 'USDc' as Currency

    default:
      return currency as Currency
  }
}

export function formatAmount(amount: {
  amount: number | string
  currency: Currency
}) {
  return formatMoney(amount.amount) + ' ' + formatCurrency(amount.currency)
}

export function formatRate(rate?: number | string) {
  return Big(rate ?? 0).toFixed(4)
}

export function formatPercentage(percentage?: number | string) {
  return `${Big(percentage ?? 0).toFixed(2)}%`
}

export function calculateProgress({
  value,
  total,
}: {
  value?: number | string
  total?: number | string
}) {
  if (!value || !total) {
    return 0
  }
  return new Big(value).div(total).times(100).toNumber()
}
