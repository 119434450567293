import { useCallback } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Big } from 'big.js'

import { queryKeys } from '@/constants/queryKeys'
import { getPaymentLimit } from '@/features/Team'
import { MovementPermission } from '@/features/Team/types'

export function useCheckUserPaymentLimit() {
  const paymentLimitQuery = useQuery({
    queryKey: [queryKeys.getPaymentLimit],
    queryFn: () => getPaymentLimit(),
    select: (data) => data.data,
  })

  const hasCrossedLimit = useCallback(
    (amount?: number | string) => {
      if (!paymentLimitQuery.data || !amount) {
        return false
      }

      const { periodTransferLimit, singleTransferLimit, type } =
        paymentLimitQuery.data

      if (type === MovementPermission.ALWAYS_REQUIRE_APPROVAL) {
        return true
      }

      if (!periodTransferLimit || !singleTransferLimit) {
        return false
      }

      return (
        type === MovementPermission.REQUIRE_APPROVAL_ABOVE_THE_LIMIT &&
        (Big(amount)
          .plus(periodTransferLimit.usedAmount)
          .gt(periodTransferLimit.limitAmount) ||
          Big(amount).gt(singleTransferLimit.limitAmount))
      )
    },
    [paymentLimitQuery.data],
  )

  return hasCrossedLimit
}
