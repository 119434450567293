import { defineMessage, defineMessages, MessageDescriptor } from 'react-intl'

import { Permission } from '@/types/permissions'

type MessageBody = {
  defaultMessage: string
  id: string
}

export type DefineMessages<T extends string | number | symbol = string> =
  Record<T, MessageBody>

export const backendErrorMessages: DefineMessages = defineMessages({
  OTP_EXPIRED: {
    defaultMessage: 'Your verification code has expired',
    id: 'error.backend.otp.expired',
  },
  VERIFICATION_CODE_EXPIRED: {
    defaultMessage: 'Your verification link has expired',
    id: 'error.backend.verificationCode.expired',
  },
  PASSWORD_CHANGE_FORBIDDEN: {
    defaultMessage: 'Unable to change password',
    id: 'error.backend.passwordChange.forbidden',
  },
  GENERATE_OTP_FORBIDDEN: {
    defaultMessage: 'Unable to generate one time password',
    id: 'error.backend.generateOtp.forbidden',
  },
  VERIFY_OTP_FORBIDDEN: {
    defaultMessage: 'Unable to verify one time password',
    id: 'error.backend.verifyOtp.forbidden',
  },
  UNABLE_TO_AUTHORIZE: {
    defaultMessage: 'You have entered an incorrect username or password',
    id: 'error.backend.unableToAuthorize',
  },
  EMPTY_FILE_ERROR: {
    defaultMessage: 'File can not be empty',
    id: 'error.backend.emptyFile',
  },
  INVALID_FILE_EXTENSION_ERROR: {
    defaultMessage: 'This file extension is not supported',
    id: 'error.backend.invalidFileExtension',
  },
  FILE_ALREADY_EXIST_ERROR: {
    defaultMessage: 'This file has already been uploaded',
    id: 'error.backend.fileAlreadyExist',
  },
  MISSING_FILES_ERROR: {
    defaultMessage: 'Some required files are missing',
    id: 'error.backend.missingFiles',
  },
  UNSUCCESSFUL_UPLOAD_ERROR: {
    defaultMessage: 'Some files were not correctly uploaded',
    id: 'error.backend.unsuccessfulUpload',
  },
  BENEFICIARY_ALREADY_EXISTS: {
    defaultMessage: 'Beneficiary already exists',
    id: 'error.backend.beneficiaryAlreadyExists',
  },
  NOT_ENOUGH_BALANCE: {
    defaultMessage: 'Not enough balance',
    id: 'error.backend.notEnoughBalance',
  },
  TOO_LOW_WITHDRAW_AMOUNT: {
    defaultMessage: 'Amount is too low to withdraw',
    id: 'error.backend.tooLowWithdrawAmount',
  },
  ONE_TIME_PASSWORD_REQUIRED: {
    defaultMessage:
      'This action requires verification code. Please check your e-mail',
    id: 'error.backend.oneTimePasswordRequired',
  },
  INVALID_ROUTING_NUMBER: {
    defaultMessage: 'Invalid ABA / routing number',
    id: 'error.backend.invalidRoutingNumber',
  },
  INVALID_PHONE_NUMBER: {
    defaultMessage: 'Phone number is not correct',
    id: 'error.backend.invalidPhoneNumber',
  },
  INVALID_EMAIL: {
    defaultMessage: 'Email is not correct',
    id: 'error.backend.invalidEmail',
  },
  NOT_ELIGIBLE_FOR_SIGNUP: {
    defaultMessage: 'Not eligible for signup',
    id: 'error.backend.notEligibleForSignup',
  },
  UNABLE_TO_CREATE_ACCOUNT: {
    defaultMessage: 'Unable to create account',
    id: 'error.backend.unableToCreateAccount',
  },
  VERIFICATION_FAILED: {
    defaultMessage: 'Unable to verify account',
    id: 'error.backend.verificationFailed',
  },
  TAX_ID_INVALID: {
    defaultMessage: 'Tax ID is invalid',
    id: 'error.backend.taxIdInvalid',
  },
  TAX_ID_TYPE_INVALID: {
    defaultMessage: 'Tax ID type is invalid',
    id: 'error.backend.taxIdTypeInvalid',
  },
  COUNTRY_OF_INCORPORATION_NOT_SUPPORTED: {
    defaultMessage: 'Country of incorporation is not supported',
    id: 'error.backend.countryOfIncorporationNotSupported',
  },
  FORBIDDEN_ACTION: {
    defaultMessage: 'This action is forbidden',
    id: 'error.backend.forbiddenAction',
  },
  BULK_PAYMENTS_FILE_ALREADY_UPLOADED: {
    defaultMessage:
      'File with this name was already uploaded. Please rename your file',
    id: 'error.backend.bulkPayments.fileAlreadyUploaded',
  },
  BULK_PAYMENTS_CSV_EMPTY_ERROR: {
    defaultMessage:
      'Unable to retrieve information from the CSV file. Please ensure that your CSV contains headers from the template and at least 1 row with data',
    id: 'error.backend.bulkPayments.csvEmptyError',
  },
  DUPLICATE_BENEFICIAL_OWNER: {
    defaultMessage: 'Such beneficial owner already exists!',
    id: 'error.backend.duplicateBeneficialOwner',
  },
  MISSING_BENEFICIAL_OWNER_DOCUMENTS: {
    defaultMessage: 'Missing beneficial owner documents',
    id: 'error.backend.missingBeneficialOwnerDocuments',
  },
  INVALID_ACCOUNT_NUMBER_ERROR: {
    defaultMessage: 'Account number is not valid!',
    id: 'error.backend.invalidAccountNumber',
  },
  BUSINESS_USER_ALREADY_EXISTS_ERROR: {
    defaultMessage: 'User already exists',
    id: 'error.backend.businessUserAlreadyExists',
  },
  IDENTITY_LOCKED_ERROR: {
    defaultMessage:
      "Your account is locked. You've reached the maximum number of access attempts. Please reset your password",
    id: 'error.backend.accountIsLocked',
  },
  INSUFFICIENT_FUNDS_TO_ORDER_CARD_ERROR: {
    defaultMessage:
      'The account that was selected has insufficient funds to order a physical card',
    id: 'error.backend.insufficientFundsToOrderCard',
  },
  MAX_NUMBER_OF_CARDS_REACHED_ERROR: {
    defaultMessage:
      'Maximum number of cards reached. If you need additional cards, please contact support',
    id: 'error.backend.maxNumberOfCardsReached',
  },
})

export const errorMessages: DefineMessages = defineMessages({
  'validation.email.invalid': {
    defaultMessage: 'Must be a valid email',
    id: 'validation.email.invalid',
  },
  'validation.email.required': {
    defaultMessage: 'Email is required',
    id: 'validation.email.required',
  },
  'validation.password.required': {
    defaultMessage: 'Password is required',
    id: 'validation.password.required',
  },

  'validation.password.minLength': {
    defaultMessage: 'Password must be at least 12 characters',
    id: 'validation.password.minLength',
  },
  'validation.password.upperCase': {
    defaultMessage: 'Password must contain at least one uppercase character',
    id: 'validation.password.upperCase',
  },
  'validation.password.lowerCase': {
    defaultMessage: 'Password must contain at least one lowercase character',
    id: 'validation.password.lowerCase',
  },
  'validation.password.hasNumber': {
    defaultMessage: 'Password must contain at least one number',
    id: 'validation.password.hasNumber',
  },
  'validation.password.hasSpecialCharacter': {
    defaultMessage: 'Password must contain at least one special character',
    id: 'validation.password.hasSpecialCharacter',
  },
  'validation.confirmPassword.required': {
    defaultMessage: 'Confirm password is required',
    id: 'validation.confirmPassword.required',
  },
  'validation.password.match': {
    defaultMessage: 'Passwords must match',
    id: 'validation.confirmPassword.match',
  },

  'validation.generic.error': {
    defaultMessage: 'Something went wrong. Please try again',
    id: 'validation.generic.error',
  },
  'validation.firstName.required': {
    defaultMessage: 'Please provide your first name',
    id: 'validation.firstName.required',
  },
  'validation.lastName.required': {
    defaultMessage: 'Please provide your last name',
    id: 'validation.lastName.required',
  },
  'validation.phoneCode.required': {
    defaultMessage: 'Please select your phone code',
    id: 'validation.phoneCode.required',
  },
  'validation.phoneNumber.required': {
    defaultMessage: 'Please provide your phone number',
    id: 'validation.phoneNumber.required',
  },
  'validation.phoneNumber.invalid': {
    defaultMessage: 'Please provide a valid phone number',
    id: 'validation.phoneNumber.invalid',
  },
  'validation.terms.required': {
    defaultMessage:
      'Only the company’s legal representatives can open the account',
    id: 'validation.terms.required',
  },
  'validation.legalName.required': {
    defaultMessage: 'Please provide your legal name',
    id: 'validation.legalName.required',
  },
  'validation.nickname.required': {
    defaultMessage: 'Please provide a nickname',
    id: 'validation.nickname.required',
  },
  'validation.country.required': {
    defaultMessage: 'Please select a country',
    id: 'validation.country.required',
  },
  'validation.taxId.required': {
    defaultMessage: 'Please provide your tax ID',
    id: 'validation.taxId.required',
  },
  'validation.taxId.length': {
    defaultMessage: 'Tax ID must be 12 characters',
    id: 'validation.taxId.length',
  },
  'validation.taxId.invalid': {
    defaultMessage: 'Please provide a valid tax ID',
    id: 'validation.taxId.invalid',
  },
  'validation.taxId.type': {
    defaultMessage: 'Please provide your tax ID type',
    id: 'validation.taxId.type',
  },
  'validation.otp.required': {
    defaultMessage: 'Verification code is required',
    id: 'validation.opt.required',
  },
  'validation.otp.length': {
    defaultMessage: 'Verification code must be 6 characters long',
    id: 'validation.otp.length',
  },
  'validation.balance.insufficient': {
    defaultMessage: 'Insufficient balance',
    id: 'validation.balance.insufficient',
  },
  'validation.balance.MXN.min': {
    defaultMessage: 'Minimum amount: 50 MXN',
    id: 'validation.balance.MXN.min',
  },
  'validation.balance.USDC.min': {
    defaultMessage: 'Minimum amount: 1 USDc',
    id: 'validation.balance.USDC.min',
  },
  'validation.accountName.required': {
    defaultMessage: 'Name is required',
    id: 'validation.accountName.required',
  },
  'validation.accountName.exists': {
    defaultMessage: 'Name already in use',
    id: 'validation.accountName.exists',
  },
  'validation.clabe.invalid': {
    defaultMessage: 'Please provide a valid CLABE',
    id: 'validation.clabe.invalid',
  },
  'validation.paymentMethod.required': {
    defaultMessage: 'Please select a payment method',
    id: 'validation.paymentMethod.required',
  },
  'validation.accountNumber.required': {
    defaultMessage: 'Please provide an account number',
    id: 'validation.accountNumber.required',
  },
  'validation.routingNumber.required': {
    defaultMessage: 'Please provide an ABA / routing number',
    id: 'validation.routingNumber.required',
  },
  'validation.addressStreet.required': {
    defaultMessage: 'Please provide an address',
    id: 'validation.addressStreet.required',
  },
  'validation.addressStreetNumber.required': {
    defaultMessage: 'Please provide a street number',
    id: 'validation.addressStreetNumber.required',
  },
  'validation.addressDistrict.required': {
    defaultMessage: 'Please provide a neighborhood or district',
    id: 'validation.addressDistrict.required',
  },
  'validation.addressCity.required': {
    defaultMessage: 'Please provide a city',
    id: 'validation.addressCity.required',
  },
  'validation.addressCountry.required': {
    defaultMessage: 'Please provide a country',
    id: 'validation.addressCountry.required',
  },
  'validation.addressState.required': {
    defaultMessage: 'Please provide a state',
    id: 'validation.addressState.required',
  },
  'validation.addressZipCode.required': {
    defaultMessage: 'Please provide a postal code',
    id: 'validation.addressZipCode.required',
  },
  'validation.file.wrongFormat': {
    defaultMessage: 'This file format is not supported',
    id: 'validation.file.wrongFormat',
  },
  'validation.cvs.transactions.required': {
    defaultMessage: 'Please select at least one transaction',
    id: 'validation.cvs.transactions.required',
  },
  'validation.dateOfIncorporation.notMatchingRFC': {
    defaultMessage: 'Does not match RFC',
    id: 'validation.dateOfIncorporation.notMatchingRFC',
  },
  'validation.dateOfIncorporation.invalidDate': {
    defaultMessage: 'Please provide a valid date',
    id: 'validation.dateOfIncorporation.invalidDate',
  },
  'validation.url.invalid': {
    defaultMessage: 'Please provide a valid URL',
    id: 'validation.url.invalid',
  },
  'validation.cardNumber.required': {
    defaultMessage: 'Please provide a card number',
    id: 'validation.cardNumber.required',
  },
  'validation.cardNumber.lastFour': {
    defaultMessage: 'Please provide last 4 digits of card number',
    id: 'validation.cardNumber.lastFour',
  },
  'validation.pin.required': {
    defaultMessage: 'Please provide a 4-digit PIN',
    id: 'validation.pin.required',
  },
  'validation.balance.minAmount': {
    defaultMessage: 'Amount must be greater than 0',
    id: 'validation.balance.minAmount',
  },
  'validation.singleTransactionLimit.greaterThanCycleLimit': {
    defaultMessage: 'Cannot be greater than cycle limit',
    id: 'validation.singleTransactionLimit.greaterThanCycleLimit',
  },
  'validation.pin.match': {
    defaultMessage: 'Pins must match',
    id: 'validation.pin.match',
  },
})

export const passwordMessages: DefineMessages<
  'minLength' | 'upperCase' | 'lowerCase' | 'hasNumber' | 'hasSpecialCharacter'
> = defineMessages({
  minLength: {
    defaultMessage: 'At least 12 characters',
    id: 'password.rule.minLength',
  },
  upperCase: {
    defaultMessage: 'At least 1 uppercase character',
    id: 'password.rule.upperCase',
  },
  lowerCase: {
    defaultMessage: 'At least 1 lowercase character',
    id: 'password.rule.lowerCase',
  },
  hasNumber: {
    defaultMessage: 'At least 1 number',
    id: 'password.rule.hasNumber',
  },
  hasSpecialCharacter: {
    defaultMessage: 'At least 1 special character',
    id: 'password.rule.hasSpecialCharacter',
  },
})

export const termsMessages: DefineMessages<
  'terms' | 'dolarAppTerms' | 'dolarAppPrivacy' | 'bridgeTerms'
> = defineMessages({
  terms: {
    defaultMessage:
      'By clicking “Save & Continue” you accept the {dolarAppTerms}, {dolarAppPrivacy}, and {bridgeTerms}',
    id: 'signUp.termsAndConditions',
  },
  dolarAppTerms: {
    defaultMessage: 'Terms and Conditions of DolarApp',
    id: 'signUp.dolarApp.terms',
  },
  dolarAppPrivacy: {
    defaultMessage: 'Privacy Policy of DolarApp',
    id: 'signUp.dolarApp.privacy',
  },
  bridgeTerms: {
    defaultMessage: 'Terms of Service and Privacy Policy of Bridge',
    id: 'signUp.bridge.terms',
  },
})

export const activityTypesMessages: DefineMessages = defineMessages({
  PRIMARY_SECTOR_AGRICULTURE: {
    defaultMessage: 'Primary Sector • Agriculture',
    id: 'activityTypes.primarySector.agriculture',
  },
  PRIMARY_SECTOR_LIVESTOCK_FARMING: {
    defaultMessage: 'Primary Sector • Livestock farming',
    id: 'activityTypes.primarySector.livestockFarming',
  },
  PRIMARY_SECTOR_EXPLOITATION: {
    defaultMessage:
      'Primary Sector • Exploitation of Other Animals (Poultry, Beekeeping, etc.)',
    id: 'activityTypes.primarySector.exploitation',
  },
  PRIMARY_SECTOR_FORESTRY: {
    defaultMessage: 'Primary Sector • Forestry',
    id: 'activityTypes.primarySector.forestry',
  },
  PRIMARY_SECTOR_FISHING: {
    defaultMessage: 'Primary Sector • Fishing',
    id: 'activityTypes.primarySector.fishing',
  },
  MINING_MINING_OF_OTHER_RESOURCES: {
    defaultMessage: 'Mining • Mining of other resources',
    id: 'activityTypes.mining.miningOfOtherResources',
  },
  MINING_PETROLEUM_AND_GAS: {
    defaultMessage: 'Mining • Petroleum and Gas Related Activities',
    id: 'activityTypes.mining.petroleumAndGas',
  },
  MINING_GOLD: {
    defaultMessage: 'Mining • Gold',
    id: 'activityTypes.mining.gold',
  },
  MINING_SILVER: {
    defaultMessage: 'Mining • Silver',
    id: 'activityTypes.mining.silver',
  },
  MINING_PRECIOUS_STONES: {
    defaultMessage: 'Mining • Precious Stones',
    id: 'activityTypes.mining.preciousStones',
  },
  ELECTRICITY_WATER_GAS: {
    defaultMessage:
      'Electricity Generation, Transmission and Distribution, Water and Gas Supply',
    id: 'activityTypes.electricityWaterGas',
  },
  CONSTRUCTION_OTHER_CONSTRUCTION: {
    defaultMessage: 'Construction • Other Construction • Related Activities',
    id: 'activityTypes.construction.otherConstruction',
  },
  CONSTRUCTION_BUILDINGS: {
    defaultMessage: 'Construction • Residential and Non • Residential Building',
    id: 'activityTypes.construction.buildings',
  },
  CONSTRUCTION_CIVIL_ENGINEERING_WORKS: {
    defaultMessage: 'Construction • Civil Engineering Works',
    id: 'activityTypes.construction.civilEngineeringWorks',
  },
  CONSTRUCTION_SPECIALIZED_CONSTRUCTION_WORKS: {
    defaultMessage: 'Construction • Specialized Construction Works',
    id: 'activityTypes.construction.specializedConstructionWorks',
  },
  CONSTRUCTION_ARMORING_SERVICES_FOR_BUILDINGS: {
    defaultMessage:
      'Construction • Partial or Total Armoring Services for Buildings',
    id: 'activityTypes.construction.armoringServicesForBuildings',
  },
  INDUSTRIALS_FOOD: {
    defaultMessage: 'Industrials • Food',
    id: 'activityTypes.industrials.food',
  },
  INDUSTRIALS_BEVERAGES_AND_TOBACCO: {
    defaultMessage: 'Industrials • Beverages and Tobacco',
    id: 'activityTypes.industrials.beveragesAndTobacco',
  },
  INDUSTRIALS_TEXTILE_PRODUCTS: {
    defaultMessage: 'Industrials • Textile Products',
    id: 'activityTypes.industrials.textileProducts',
  },
  INDUSTRIALS_CLOTHING: {
    defaultMessage: 'Industrials • Clothing',
    id: 'activityTypes.industrials.clothing',
  },
  INDUSTRIALS_WOOD: {
    defaultMessage: 'Industrials • Wood',
    id: 'activityTypes.industrials.wood',
  },
  INDUSTRIALS_PAPER: {
    defaultMessage: 'Industrials • Paper',
    id: 'activityTypes.industrials.paper',
  },
  INDUSTRIALS_PRINTING_AND_RELATED_INDUSTRIES: {
    defaultMessage: 'Industrials • Printing and Related Industries',
    id: 'activityTypes.industrials.printingAndRelatedIndustries',
  },
  INDUSTRIALS_PETROLEUM_AND_COAL: {
    defaultMessage: 'Industrials • Petroleum and Coal Derivative Products',
    id: 'activityTypes.industrials.petroleumAndCoal',
  },
  INDUSTRIALS_CHEMICALS: {
    defaultMessage: 'Industrials • Chemicals',
    id: 'activityTypes.industrials.chemicals',
  },
  INDUSTRIALS_PLASTIC_AND_RUBBER: {
    defaultMessage: 'Industrials • Plastic and Rubber',
    id: 'activityTypes.industrials.plasticAndRubber',
  },
  INDUSTRIALS_MINERAL_BASED_PRODUCTS: {
    defaultMessage:
      'Industrials • Non•Metallic Mineral • Based Products (Pottery, Porcelain, Glass, Cement)',
    id: 'activityTypes.industrials.mineralBasedProducts',
  },
  INDUSTRIALS_BASIC_METALS: {
    defaultMessage: 'Industrials • Basic Metals',
    id: 'activityTypes.industrials.basicMetals',
  },
  INDUSTRIALS_METAL_PRODUCTS: {
    defaultMessage:
      'Industrials • Metal Products (Tools, Utensils, Structures, Parts)',
    id: 'activityTypes.industrials.metalProducts',
  },
  INDUSTRIALS_MACHINERY_AND_EQUIPMENT: {
    defaultMessage: 'Industrials • Machinery and Equipment',
    id: 'activityTypes.industrials.machineryAndEquipment',
  },
  INDUSTRIALS_COMPUTING_AND_ELECTRONIC_EQUIPMENT: {
    defaultMessage:
      'Industrials • Computing, Communication, Measurement and Other Electronic Equipment, Components and Accessories',
    id: 'activityTypes.industrials.computingAndElectronicEquipment',
  },
  INDUSTRIALS_ELECTRICAL_ACCESSORIES: {
    defaultMessage:
      'Industrials • Electrical Accessories, Appliances and Electric Power Generation Equipment',
    id: 'activityTypes.industrials.electricalAccessories',
  },
  INDUSTRIALS_NON_MOTORIZED_TRANSPORT_EQUIPMENT: {
    defaultMessage: 'Industrials • Non•Motorized Transport Equipment',
    id: 'activityTypes.industrials.nonMotorizedTransportEquipment',
  },
  INDUSTRIALS_AUTOMOTIVE: {
    defaultMessage: 'Industrials • Automotive',
    id: 'activityTypes.industrials.automotive',
  },
  INDUSTRIALS_ARMORED_VEHICLES_AND_EQUIPMENT: {
    defaultMessage:
      'Industrials • Armored Vehicles and Equipment for Vehicle Armoring',
    id: 'activityTypes.industrials.armoredVehiclesAndEquipment',
  },
  INDUSTRIALS_FURNITURE_MATTRESSES_AND_BLINDS: {
    defaultMessage: 'Industrials • Furniture, Mattresses and Blinds',
    id: 'activityTypes.industrials.furnitureMattressesAndBlinds',
  },
  INDUSTRIALS_OTHER_MANUFACTURING_INDUSTRIES: {
    defaultMessage: 'Industrials • Other Manufacturing Industries',
    id: 'activityTypes.industrials.otherManufacturingIndustries',
  },
  INDUSTRIALS_GOLD_AND_PRECIOUS_JEWELRY: {
    defaultMessage:
      'Industrials • Gold and Precious Stone Jewelry and Ornaments',
    id: 'activityTypes.industrials.goldAndPreciousJewelry',
  },
  INDUSTRIALS_NON_PRECIOUS_JEWELRY: {
    defaultMessage:
      'Industrials • Jewelry Made of Non•Precious Metals and Stones and Other Materials',
    id: 'activityTypes.industrials.nonPreciousJewelry',
  },
  COMMERCE_GROCERIES_FOOD_BEVERAGES_AND_TOBACCO: {
    defaultMessage: 'Commerce • Groceries, Food, Beverages, Ice and Tobacco',
    id: 'activityTypes.commerce.groceriesFoodBeveragesAndTobacco',
  },
  COMMERCE_PHARMACEUTICALS_PERFUMERY_HOUSEHOLD_APPLIANCES: {
    defaultMessage:
      'Commerce • Pharmaceuticals, Perfumery, Leisure Articles, Household Appliances and White Goods',
    id: 'activityTypes.commerce.pharmaceuticalsPerfumeryHouseholdAppliances',
  },
  COMMERCE_AGRICULTURAL_INDUSTRIAL_AND_WASTE_MATERIALS: {
    defaultMessage:
      'Commerce • Agricultural and Forest Raw Materials, Industrial Materials, and Waste Materials',
    id: 'activityTypes.commerce.agriculturalIndustrialAndWasteMaterials',
  },
  COMMERCE_MACHINERY_COMPUTER_AND_GENERAL_USE_FURNITURE: {
    defaultMessage:
      'Commerce • Machinery, Computer Equipment and General Use Furniture',
    id: 'activityTypes.commerce.machineryComputerAndGeneralUseFurniture',
  },
  COMMERCE_SELF_SERVICE_AND_DEPARTMENT_STORES: {
    defaultMessage: 'Commerce • Self-Service and Department Stores',
    id: 'activityTypes.commerce.selfServiceAndDepartmentStores',
  },
  COMMERCE_TEXTILES_CLOTHING_ACCESSORIES_AND_FOOTWEAR: {
    defaultMessage:
      'Commerce • Textiles, Costume Jewelry, Clothing Accessories and Footwear',
    id: 'activityTypes.commerce.textilesClothingAccessoriesAndFootwear',
  },
  COMMERCE_STATIONERY: {
    defaultMessage: 'Commerce • Stationery',
    id: 'activityTypes.commerce.stationery',
  },
  COMMERCE_JEWELRY_AND_WATCHES: {
    defaultMessage: 'Commerce • Jewelry and Watches',
    id: 'activityTypes.commerce.jewelryAndWatches',
  },
  COMMERCE_INTERIOR_DECORATION_ITEMS: {
    defaultMessage: 'Commerce • Interior Decoration Items',
    id: 'activityTypes.commerce.interiorDecorationItems',
  },
  COMMERCE_ANTIQUES_AND_ARTWORK: {
    defaultMessage: 'Commerce • Antiques and Artwork',
    id: 'activityTypes.commerce.antiquesAndArtwork',
  },
  COMMERCE_HARDWARE_PAINTS_AND_GLASS: {
    defaultMessage: 'Commerce • Hardware, Paints and Glass',
    id: 'activityTypes.commerce.hardwarePaintsAndGlass',
  },
  COMMERCE_NEW_VEHICLES: {
    defaultMessage: 'Commerce • New Vehicles',
    id: 'activityTypes.commerce.newVehicles',
  },
  COMMERCE_USED_VEHICLES: {
    defaultMessage: 'Commerce • Used Vehicles',
    id: 'activityTypes.commerce.usedVehicles',
  },
  COMMERCE_VEHICLE_PARTS_AND_ACCESSORIES: {
    defaultMessage: 'Commerce • Vehicle Parts and Accessories',
    id: 'activityTypes.commerce.vehiclePartsAndAccessories',
  },
  COMMERCE_FUELS_AND_LUBRICANTS: {
    defaultMessage: 'Commerce • Fuels and Lubricants',
    id: 'activityTypes.commerce.fuelsAndLubricants',
  },
  COMMERCE_THROUGH_INTERNET_CATALOGS_TELEVISION: {
    defaultMessage:
      'Commerce • Through Internet, Printed Catalogs, Television and Similar',
    id: 'activityTypes.commerce.throughInternetCatalogsTelevision',
  },
  TRANSPORTATION_AIR: {
    defaultMessage: 'Transportation • Air',
    id: 'activityTypes.transportation.air',
  },
  TRANSPORTATION_RAIL: {
    defaultMessage: 'Transportation • Rail',
    id: 'activityTypes.transportation.rail',
  },
  TRANSPORTATION_MARITIME_AND_FLUVIAL: {
    defaultMessage: 'Transportation • Maritime and Fluvial',
    id: 'activityTypes.transportation.maritimeAndFluvial',
  },
  TRANSPORTATION_LAND: {
    defaultMessage: 'Transportation • Land',
    id: 'activityTypes.transportation.land',
  },
  TRANSPORTATION_PIPELINE_TRANSPORT_AND_DISTRIBUTION: {
    defaultMessage: 'Transportation • Pipeline Transport and Distribution',
    id: 'activityTypes.transportation.pipelineTransportAndDistribution',
  },
  TRANSPORTATION_TRANSPORT_RELATED_SERVICES: {
    defaultMessage: 'Transportation • Transport Related Services',
    id: 'activityTypes.transportation.transportRelatedServices',
  },
  TRANSPORTATION_CUSTOMS_AGENCY_SERVICES: {
    defaultMessage: 'Transportation • Customs Agency Services',
    id: 'activityTypes.transportation.customsAgencyServices',
  },
  TRANSPORTATION_TRANSPORT_AND_CUSTODY_OF_VALUES_SERVICES: {
    defaultMessage:
      'Transportation • Transport and/or Custody of Values Services',
    id: 'activityTypes.transportation.transportAndCustodyOfValuesServices',
  },
  POSTAL_SERVICES: {
    defaultMessage: 'Postal Services',
    id: 'activityTypes.postalServices',
  },
  COURIER_AND_PARCEL_SERVICES: {
    defaultMessage: 'Courier and Parcel Services',
    id: 'activityTypes.courierAndParcelServices',
  },
  STORAGE_SERVICES: {
    defaultMessage: 'Storage Services',
    id: 'activityTypes.storageServices',
  },
  MASS_MEDIA_INFORMATION: {
    defaultMessage: 'Mass Media Information',
    id: 'activityTypes.massMedia.information',
  },
  ELECTRONIC_INFORMATION_PROCESSING_AND_WEB_SERVICES: {
    defaultMessage: 'Electronic Information Processing and Other Web Services',
    id: 'activityTypes.electronicInformationProcessingAndWebServices',
  },
  FINANCIAL_SERVICES_CENTRAL_BANK: {
    defaultMessage: 'Financial Services • Central Bank',
    id: 'activityTypes.financialServices.centralBank',
  },
  FINANCIAL_SERVICES_MULTIPLE_BANKING: {
    defaultMessage: 'Financial Services • Multiple Banking',
    id: 'activityTypes.financialServices.multipleBanking',
  },
  FINANCIAL_SERVICES_DEVELOPMENT_BANKING: {
    defaultMessage: 'Financial Services • Development Banking',
    id: 'activityTypes.financialServices.developmentBanking',
  },
  FINANCIAL_SERVICES_FINANCIAL_FUNDS_AND_TRUSTS: {
    defaultMessage: 'Financial Services • Financial Funds and Trusts',
    id: 'activityTypes.financialServices.financialFundsAndTrusts',
  },
  FINANCIAL_SERVICES_CREDIT_UNIONS: {
    defaultMessage: 'Financial Services • Credit Unions',
    id: 'activityTypes.financialServices.creditUnions',
  },
  FINANCIAL_SERVICES_POPULAR_SAVINGS_BANKS: {
    defaultMessage: 'Financial Services • Popular Savings Banks',
    id: 'activityTypes.financialServices.popularSavingsBanks',
  },
  FINANCIAL_SERVICES_OTHER_NON_STOCK_FINANCIAL_AND_CREDIT_INSTITUTIONS: {
    defaultMessage:
      'Financial Services • Other Non•Stock Financial Intermediaries and Credit Institutions',
    id: 'activityTypes.financialServices.otherNonStockFinancialAndCreditInstitutions',
  },
  FINANCIAL_SERVICES_OTHER_SAVINGS_AND_LOAN_INSTITUTIONS: {
    defaultMessage: 'Financial Services • Other Savings and Loan Institutions',
    id: 'activityTypes.financialServices.otherSavingsAndLoanInstitutions',
  },
  FINANCIAL_SERVICES_SELF_FINANCING_COMPANIES: {
    defaultMessage: 'Financial Services • Self-Financing Companies',
    id: 'activityTypes.financialServices.selfFinancingCompanies',
  },
  FINANCIAL_SERVICES_MUTUAL_AID_SOCIETIES: {
    defaultMessage: 'Financial Services • Mutual Aid Societies',
    id: 'activityTypes.financialServices.mutualAidSocieties',
  },
  FINANCIAL_SERVICES_PAWNSHOPS: {
    defaultMessage: 'Financial Services • Pawnshops',
    id: 'activityTypes.financialServices.pawnshops',
  },
  FINANCIAL_SERVICES_MULTIPLE_OBJECT_FINANCIAL_COMPANIES: {
    defaultMessage: 'Financial Services • Multiple Object Financial Companies',
    id: 'activityTypes.financialServices.multipleObjectFinancialCompanies',
  },
  FINANCIAL_SERVICES_STOCK_EXCHANGE_HOUSES: {
    defaultMessage: 'Financial Services • Stock Exchange Houses',
    id: 'activityTypes.financialServices.stockExchangeHouses',
  },
  FINANCIAL_SERVICES_EXCHANGE_HOUSES_AND_FOREIGN_EXCHANGE_CENTERS: {
    defaultMessage:
      'Financial Services • Exchange Houses and Foreign Exchange Centers',
    id: 'activityTypes.financialServices.exchangeHousesAndForeignExchangeCenters',
  },
  FINANCIAL_SERVICES_MONEY_TRANSMITTERS: {
    defaultMessage: 'Financial Services • Money Transmitters',
    id: 'activityTypes.financialServices.moneyTransmitters',
  },
  FINANCIAL_SERVICES_OTHER_SERVICES_RELATED_TO_STOCK_EXCHANGE_INTERMEDIATION: {
    defaultMessage:
      'Financial Services • Other Services Related to Stock Exchange Intermediation',
    id: 'activityTypes.financialServices.otherServicesRelatedToStockExchangeIntermediation',
  },
  FINANCIAL_SERVICES_INSURANCE_COMPANIES: {
    defaultMessage: 'Financial Services • Insurance Companies',
    id: 'activityTypes.financialServices.insuranceCompanies',
  },
  FINANCIAL_SERVICES_SURETY_COMPANIES: {
    defaultMessage: 'Financial Services • Surety Companies',
    id: 'activityTypes.financialServices.suretyCompanies',
  },
  FINANCIAL_SERVICES_INSURANCE_AND_SURETY_AGENTS_ADJUSTERS_AND_MANAGERS: {
    defaultMessage:
      'Financial Services • Insurance and Surety Agents, Adjusters and Managers',
    id: 'activityTypes.financialServices.insuranceAndSuretyAgentsAdjustersAndManagers',
  },
  FINANCIAL_SERVICES_PENSION_FUND_MANAGEMENT: {
    defaultMessage: 'Financial Services • Pension Fund Management',
    id: 'activityTypes.financialServices.pensionFundManagement',
  },
  FINANCIAL_SERVICES_TRAVELERS_CHEQUES_ISSUANCE: {
    defaultMessage: `Financial Services • Traveler's Cheques Issuance and/or Marketing`,
    id: 'activityTypes.financialServices.travelersChequesIssuance',
  },
  OTHER_NON_FINANCIAL_CREDIT_INTERMEDIATION_COMPANIES: {
    defaultMessage: 'Other Non-Financial Credit Intermediation Companies',
    id: 'activityTypes.otherNonFinancialCreditIntermediationCompanies',
  },
  REAL_ESTATE_AND_RENTAL_SERVICES_WITHOUT_INTERMEDIATION: {
    defaultMessage:
      'Real Estate and Rental Services • Real Estate Rental without Intermediation',
    id: 'activityTypes.realEstateAndRentalServices.withoutIntermediation',
  },
  REAL_ESTATE_AND_RENTAL_SERVICES_AGENCIES_AND_BROKERS: {
    defaultMessage:
      'Real Estate and Rental Services • Real Estate Agencies and Brokers',
    id: 'activityTypes.realEstateAndRentalServices.agenciesAndBrokers',
  },
  REAL_ESTATE_AND_RENTAL_SERVICES_FOR_MOVABLE_PROPERTY_EXCEPT_VEHICLES: {
    defaultMessage:
      'Real Estate and Rental Services • Rental Services for Movable Property Except Vehicles',
    id: 'activityTypes.realEstateAndRentalServices.forMovablePropertyExceptVehicles',
  },
  REAL_ESTATE_AND_RENTAL_SERVICES_VEHICLE_RENTAL: {
    defaultMessage: 'Real Estate and Rental Services • Vehicle Rental',
    id: 'activityTypes.realEstateAndRentalServices.vehicleRental',
  },
  REAL_ESTATE_AND_RENTAL_SERVICES_FOR_PATENTS_AND_FRANCHISES: {
    defaultMessage:
      'Real Estate and Rental Services • Rental Services for Registered Trademarks, Patents, and Franchises',
    id: 'activityTypes.realEstateAndRentalServices.forPatentsAndFranchises',
  },
  PROFESSIONAL_AND_TECHNICAL_SERVICES_LEGAL: {
    defaultMessage: 'Professional and Technical Services • Legal Services',
    id: 'activityTypes.professionalAndTechnicalServices.legal',
  },
  PROFESSIONAL_AND_TECHNICAL_SERVICES_BROKERAGE: {
    defaultMessage:
      'Professional and Technical Services • Notary and Public Brokerage Services',
    id: 'activityTypes.professionalAndTechnicalServices.brokerage',
  },
  PROFESSIONAL_AND_TECHNICAL_SERVICES_ACCOUNTING_AND_AUDITING: {
    defaultMessage:
      'Professional and Technical Services • Accounting and Auditing Services',
    id: 'activityTypes.professionalAndTechnicalServices.accountingAndAuditing',
  },
  PROFESSIONAL_AND_TECHNICAL_SERVICES_ARCHITECTURE_ENGINEERING: {
    defaultMessage:
      'Professional and Technical Services • Architecture, Engineering, and Related Activities',
    id: 'activityTypes.professionalAndTechnicalServices.architectureEngineering',
  },
  PROFESSIONAL_AND_TECHNICAL_SERVICES_COMPUTER_SYSTEMS_DESIGN: {
    defaultMessage:
      'Professional and Technical Services • Computer Systems Design and Related Services',
    id: 'activityTypes.professionalAndTechnicalServices.computerSystemsDesign',
  },
  PROFESSIONAL_AND_TECHNICAL_SERVICES_SCIENTIFIC_AND_TECHNICAL_CONSULTING: {
    defaultMessage:
      'Professional and Technical Services • Administrative, Scientific, and Technical Consulting',
    id: 'activityTypes.professionalAndTechnicalServices.scientificAndTechnicalConsulting',
  },
  PROFESSIONAL_AND_TECHNICAL_SERVICES_SCIENTIFIC_RESEARCH_AND_DEVELOPMENT: {
    defaultMessage:
      'Professional and Technical Services • Scientific Research and Development',
    id: 'activityTypes.professionalAndTechnicalServices.scientificResearchAndDevelopment',
  },
  PROFESSIONAL_AND_TECHNICAL_SERVICES_ADVERTISING_AND_RELATED_ACTIVITIES: {
    defaultMessage:
      'Professional and Technical Services • Advertising and Related Activities',
    id: 'activityTypes.professionalAndTechnicalServices.advertisingAndRelatedActivities',
  },
  PROFESSIONAL_AND_TECHNICAL_SERVICES_OTHER_OUTSOURCING_SERVICES: {
    defaultMessage:
      'Professional and Technical Services • Other Outsourcing Services',
    id: 'activityTypes.professionalAndTechnicalServices.otherOutsourcingServices',
  },
  BUSINESS_SUPPORT_SERVICES_BUSINESS_MANAGEMENT_SERVICES: {
    defaultMessage: 'Business Support Services • Business Management Services',
    id: 'activityTypes.businessSupportServices.businessManagementServices',
  },
  BUSINESS_SUPPORT_SERVICES_COLLECTION_AGENCIES_INVESTIGATION_AGENCIES: {
    defaultMessage:
      'Business Support Services • Collection Agencies, Investigation Agencies',
    id: 'activityTypes.businessSupportServices.collectionAgenciesInvestigationAgencies',
  },
  BUSINESS_SUPPORT_SERVICES_PROTECTION_AND_SECURITY_SERVICES: {
    defaultMessage:
      'Business Support Services • Protection and Security Services',
    id: 'activityTypes.businessSupportServices.protectionAndSecurityServices',
  },
  BUSINESS_SUPPORT_SERVICES_CLEANING_AND_MAINTENANCE_SERVICES: {
    defaultMessage:
      'Business Support Services • Cleaning and Maintenance Services',
    id: 'activityTypes.businessSupportServices.cleaningAndMaintenanceServices',
  },
  BUSINESS_SUPPORT_SERVICES_OTHER_SERVICES: {
    defaultMessage: 'Business Support Services • Other Services',
    id: 'activityTypes.businessSupportServices.otherServices',
  },
  EDUCATIONAL_SERVICES: {
    defaultMessage: 'Educational Services',
    id: 'activityTypes.educationalServices',
  },
  HEALTH_AND_SOCIAL_ASSISTANCE_SERVICES: {
    defaultMessage: 'Health and Social Assistance Services',
    id: 'activityTypes.healthAndSocialAssistanceServices',
  },
  CULTURAL_AND_SPORTS_RECREATION_SERVICES: {
    defaultMessage:
      'Cultural and Sports Recreation Services and Other Recreational Services',
    id: 'activityTypes.culturalAndSportsRecreationServices',
  },
  RECREATION_SERVICES_MUSEUMS_AND_SIMILAR_ATTRACTIONS: {
    defaultMessage:
      'Recreation Services • Museums, Historic Sites, Zoos, and Similar Attractions',
    id: 'activityTypes.recreationServices.museumsAndSimilarAttractions',
  },
  RECREATION_SERVICES_ELECTRONIC_GAMING_HOUSES: {
    defaultMessage: 'Recreation Services • Electronic Gaming Houses',
    id: 'activityTypes.recreationServices.electronicGamingHouses',
  },
  RECREATION_SERVICES_CASINOS_OR_BETTING_CENTERS: {
    defaultMessage: 'Recreation Services • Casinos or Betting Centers',
    id: 'activityTypes.recreationServices.casinosOrBettingCenters',
  },
  RECREATION_SERVICES_LOTTERY_TICKETS_AND_DRAWS: {
    defaultMessage:
      'Recreation Services • Sale of Lottery Tickets, Sports Forecasts or Other Draws',
    id: 'activityTypes.recreationServices.lotteryTicketsAndDraws',
  },
  TEMPORARY_ACCOMMODATION_AND_FOOD_AND_BEVERAGE_PREPARATION_SERVICES: {
    defaultMessage:
      'Temporary Accommodation and Food and Beverage Preparation Services',
    id: 'activityTypes.temporaryAccommodationAndFoodAndBeveragePreparationServices',
  },
  REPAIR_AND_MAINTENANCE_SERVICES: {
    defaultMessage:
      'Repair and Maintenance Services • Industrial, Commercial, Service and Automotive Equipment',
    id: 'activityTypes.repairAndMaintenanceServices',
  },
  PERSONAL_FUNERAL_PARKING_PHOTO_SERVICES: {
    defaultMessage:
      'Personal Services • Laundries, Funeral Services, Public Baths, Parking Services, Photo Developing and Printing',
    id: 'activityTypes.personal.funeralParkingPhotoServices',
  },
  ASSOCIATIONS_PRODUCERS_TRADERS_AND_SERVICE_PROVIDERS_ORGANIZATIONS: {
    defaultMessage:
      'Associations • Producers, Traders, and Service Providers Organizations',
    id: 'activityTypes.associations.producersTradersAndServiceProvidersOrganizations',
  },
  ASSOCIATIONS_LABOR_AND_UNION_ORGANIZATIONS: {
    defaultMessage: 'Associations • Labor and Union Organizations',
    id: 'activityTypes.associations.laborAndUnionOrganizations',
  },
  ASSOCIATIONS_PROFESSIONAL_ORGANIZATIONS: {
    defaultMessage: 'Associations • Professional Organizations',
    id: 'activityTypes.associations.professionalOrganizations',
  },
  ASSOCIATIONS_RECREATIONAL_ACTIVITIES_ORGANIZATIONS: {
    defaultMessage: 'Associations • Recreational Activities Organizations',
    id: 'activityTypes.associations.recreationalActivitiesOrganizations',
  },
  ASSOCIATIONS_RELIGIOUS_ORGANIZATIONS: {
    defaultMessage: 'Associations • Religious Organizations',
    id: 'activityTypes.associations.religiousOrganizations',
  },
  ASSOCIATIONS_POLITICAL_ORGANIZATIONS: {
    defaultMessage: 'Associations • Political Organizations',
    id: 'activityTypes.associations.politicalOrganizations',
  },
  ASSOCIATIONS_CIVIL_ORGANIZATIONS: {
    defaultMessage: 'Associations • Civil Organizations',
    id: 'activityTypes.associations.civilOrganizations',
  },
  INTERNATIONAL_AND_EXTRATERRITORIAL_ORGANIZATIONS: {
    defaultMessage: 'International and Extraterritorial Organizations',
    id: 'activityTypes.internationalAndExtraterritorialOrganizations',
  },
  PUBLIC_SECTOR_FEDERAL_EXECUTIVE_POWER: {
    defaultMessage: 'Public Sector • Federal Executive Power',
    id: 'activityTypes.publicSector.federalExecutivePower',
  },
  PUBLIC_SECTOR_STATE_AND_FEDERAL_DISTRICT_EXECUTIVE_POWER: {
    defaultMessage:
      'Public Sector • State and Federal District Executive Power',
    id: 'activityTypes.publicSector.stateAndFederalDistrictExecutivePower',
  },
  PUBLIC_SECTOR_MUNICIPAL_AND_DELEGATIONAL_EXECUTIVE_POWER: {
    defaultMessage:
      'Public Sector • Municipal and Delegational Executive Power',
    id: 'activityTypes.publicSector.municipalAndDelegationalExecutivePower',
  },
  PUBLIC_SECTOR_FEDERAL_JUDICIAL_POWER: {
    defaultMessage: 'Public Sector • Federal Judicial Power',
    id: 'activityTypes.publicSector.federalJudicialPower',
  },
  PUBLIC_SECTOR_STATE_AND_FEDERAL_DISTRICT_JUDICIAL_POWER: {
    defaultMessage: 'Public Sector • State and Federal District Judicial Power',
    id: 'activityTypes.publicSector.stateAndFederalDistrictJudicialPower',
  },
  PUBLIC_SECTOR_FEDERAL_LEGISLATIVE_POWER: {
    defaultMessage: 'Public Sector • Federal Legislative Power',
    id: 'activityTypes.publicSector.federalLegislativePower',
  },
  PUBLIC_SECTOR_STATE_AND_FEDERAL_DISTRICT_LEGISLATIVE_POWER: {
    defaultMessage:
      'Public Sector • State and Federal District Legislative Power',
    id: 'activityTypes.publicSector.stateAndFederalDistrictLegislativePower',
  },
  PUBLIC_SECTOR_AUTONOMOUS_BODIES_AND_PARA_STATE_ENTITIES: {
    defaultMessage: 'Public Sector • Autonomous Bodies and Para-State Entities',
    id: 'activityTypes.publicSector.autonomousBodiesAndParaStateEntities',
  },
})

export const sourceOfFundsMessages: Record<string, MessageDescriptor> = {
  BUSINESS_LOANS: defineMessage({
    id: 'sourceOfFunds.businessLoans',
    defaultMessage: 'Business loans',
  }),
  BUSINESS_REVENUE: defineMessage({
    id: 'sourceOfFunds.businessRevenue',
    defaultMessage: 'Business revenue',
  }),
  EQUITY_FUNDING: defineMessage({
    id: 'sourceOfFunds.equityFunding',
    defaultMessage: 'Equity funding',
  }),
  GRANTS: defineMessage({
    id: 'sourceOfFunds.grants',
    defaultMessage: 'Grants',
  }),
  INVESTMENT_PROCEEDS: defineMessage({
    id: 'sourceOfFunds.investmentProceeds',
    defaultMessage: 'Investment proceeds',
  }),
  LEGAL_SETTLEMENT: defineMessage({
    id: 'sourceOfFunds.legalSettlement',
    defaultMessage: 'Legal settlement',
  }),
  RETIREMENT_PENSION: defineMessage({
    id: 'sourceOfFunds.retirementPension',
    defaultMessage: 'Retirement pension',
  }),
  SALE_OF_ASSETS: defineMessage({
    id: 'sourceOfFunds.saleOfAssets',
    defaultMessage: 'Sale of assets',
  }),
  TAX_REFUND: defineMessage({
    id: 'sourceOfFunds.taxRefund',
    defaultMessage: 'Tax refund',
  }),
  THIRD_PARTY_FUNDS: defineMessage({
    id: 'sourceOfFunds.thirdPartyFunds',
    defaultMessage: 'Third party funds',
  }),
}

export const teamPermissionsMessages: Record<
  Permission,
  Record<'title' | 'description', MessageDescriptor>
> = {
  [Permission.TEAM_MANAGEMENT]: {
    title: defineMessage({
      id: 'team.permissions.teamManagement',
      defaultMessage: 'Team management',
    }),
    description: defineMessage({
      id: 'team.permissions.teamManagement.description',
      defaultMessage: 'Can invite, block, edit and delete team members',
    }),
  },
  [Permission.REQUEST_APPROVALS]: {
    title: defineMessage({
      id: 'team.permissions.approveRequests',
      defaultMessage: 'Approve requests',
    }),
    description: defineMessage({
      id: 'team.permissions.approveRequests.description',
      defaultMessage: 'Can approve new recipients and money transfers',
    }),
  },
  [Permission.CARDS_MANAGEMENT]: {
    title: defineMessage({
      id: 'team.permissions.cardsCreation',
      defaultMessage: 'Cards creation',
    }),
    description: defineMessage({
      id: 'team.permissions.cardsCreation.description',
      defaultMessage:
        'Can create corporate cards for them and other team members',
    }),
  },
  [Permission.TRANSFERS]: {
    title: defineMessage({
      id: 'team.permissions.moneyMovement',
      defaultMessage: 'Money movement',
    }),
    description: defineMessage({
      id: 'team.permissions.moneyMovement.description',
      defaultMessage: 'Can make or request money transfers',
    }),
  },
  [Permission.CARDS_ACCESS]: {
    title: defineMessage({
      id: 'team.permissions.cardsAccess',
      defaultMessage: 'Cards access',
    }),
    description: defineMessage({
      id: 'team.permissions.cardsAccess.description',
      defaultMessage: 'Can be granted and manage their own corporate cards',
    }),
  },
  [Permission.READ_ACCESS]: {
    title: defineMessage({
      id: 'team.permissions.readingPermissions',
      defaultMessage: 'Reading permissions',
    }),
    description: defineMessage({
      id: 'team.permissions.readingPermissions.description',
      defaultMessage: 'Can access the entire account as viewer',
    }),
  },
}
