import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import { ACCOUNTS_ROUTE } from '@/constants/paths'
import { Wallet } from '@/features/Accounts/types'
import { Button } from '@/shared/ui'

type Props = {
  wallet: Wallet
}

export const DetailsButtonCell = ({ wallet }: Props) => {
  return (
    <div className="flex items-center justify-end">
      <Button
        asChild
        onClick={(e) => e.stopPropagation()}
        size="xs"
        variant="secondary"
      >
        <Link preventScrollReset to={`${ACCOUNTS_ROUTE}?id=${wallet.id}`}>
          <FormattedMessage
            id="action.seeDetails"
            defaultMessage="See details"
          />
        </Link>
      </Button>
    </div>
  )
}
