import { FormattedMessage } from 'react-intl'

import { MovementPermission } from '@/features/Team/types'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Typography,
} from '@/shared/ui'

type Props = {
  disabled?: boolean
  value?: string
  onChange: (v: string) => void
}

export const MoneyMovementSelectField = ({
  disabled,
  onChange,
  value,
}: Props) => {
  return (
    <Select disabled={disabled} onValueChange={onChange} defaultValue={value}>
      <SelectTrigger>
        {value ? (
          <div className="flex flex-col items-start">
            <Typography variant="body-tiny">
              <FormattedMessage
                id="label.moneyMovement"
                defaultMessage="Money movement"
              />
            </Typography>
            <Typography className="text-neutral-gray-900">
              <FormattedMessage
                defaultMessage="{movementPermission, select, REQUIRE_APPROVAL_ABOVE_THE_LIMIT {One admin approval above a limit} ALWAYS_REQUIRE_APPROVAL {One admin approval} NO_APPROVAL_REQUIRED {No approval required} other {}}"
                id="teamMember.create.review.moneyMovement.subtitle"
                values={{ movementPermission: value }}
              />
            </Typography>
          </div>
        ) : (
          <SelectValue
            placeholder={
              <FormattedMessage
                id="label.moneyMovement"
                defaultMessage="Money movement"
              />
            }
          >
            <FormattedMessage
              id="label.moneyMovement"
              defaultMessage="Money movement"
            />
          </SelectValue>
        )}
      </SelectTrigger>

      <SelectContent>
        {Object.values(MovementPermission).map((permission) => (
          <SelectItem
            disabled={permission === value}
            hideChecked
            className="flex h-[54px] flex-col items-start"
            key={permission}
            value={permission}
          >
            <Typography>
              <FormattedMessage
                defaultMessage="{movementPermission, select, REQUIRE_APPROVAL_ABOVE_THE_LIMIT {One admin approval above a limit} ALWAYS_REQUIRE_APPROVAL {One admin approval} NO_APPROVAL_REQUIRED {No approval required} other {}}"
                id="teamMember.create.review.moneyMovement.subtitle"
                values={{ movementPermission: permission }}
              />
            </Typography>
            <Typography className="text-neutral-gray-600">
              <FormattedMessage
                defaultMessage="{movementPermission, select, REQUIRE_APPROVAL_ABOVE_THE_LIMIT {Require Admin approval after reaching the limit} ALWAYS_REQUIRE_APPROVAL {Always require one Admin approval} NO_APPROVAL_REQUIRED {No approval required} other {}}"
                id="teamMember.create.review.moneyMovement.description"
                values={{ movementPermission: permission }}
              />
            </Typography>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
