import { useEffect, useMemo } from 'react'
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom'

import {
  ACCOUNT_TRANSACTIONS_ROUTE,
  ACCOUNTS_ROUTE,
  ADD_BALANCE_ROUTE,
  ADD_RECIPIENTS_ROUTE,
  ADD_TEAM_MEMBER_ROUTE,
  BULK_PAYMENT_ROUTE,
  BULK_PAYMENT_TRANSACTIONS_ROUTE,
  CARDS_ROUTE,
  CARDS_TRANSACTIONS_ROUTE,
  CREATE_ACCOUNT_ROUTE,
  CREATE_CARD_ROUTE,
  DASHBOARD_ROUTE,
  RECIPIENTS_ROUTE,
  REVIEW_TASK_ROUTE,
  SEND_ROUTE,
  SINGLE_ACCOUNT_ROUTE,
  TASKS_ROUTE,
  TEAM_ROUTE,
  TRANSACTIONS_ROUTE,
  TRANSFER_ROUTE,
} from '@/constants/paths'
import { useBusinessRole } from '@/hooks/useBusinessRole'
import { Feature, useFeatures } from '@/hooks/useFeatures'
import { Loader } from '@/shared/components'

type Props = {
  children: React.ReactNode
}

export const PermissionsChecker = ({ children }: Props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { isCardUser, isPending } = useBusinessRole()

  const [
    homeEnabled,
    tasksEnabled,
    accountsEnabled,
    cardsEnabled,
    recipientsEnabled,
    transactionsEnabled,
    teamEnabled,
    transferActionsEnabled,
  ] = useFeatures([
    Feature.HOME,
    Feature.TASKS,
    Feature.ACCOUNTS,
    Feature.CARDS,
    Feature.RECIPIENTS,
    Feature.TRANSACTIONS,
    Feature.TEAM,
    Feature.ACTIONS,
  ])

  const redirectRoute = useMemo(() => {
    if (isCardUser) {
      return CARDS_ROUTE
    }

    return DASHBOARD_ROUTE
  }, [isCardUser])

  const pathToPermissionsMap = useMemo(
    () => [
      {
        paths: [CARDS_ROUTE, CARDS_TRANSACTIONS_ROUTE, CREATE_CARD_ROUTE],
        disabled: !cardsEnabled,
      },
      {
        paths: [TEAM_ROUTE, ADD_TEAM_MEMBER_ROUTE],
        disabled: !teamEnabled,
      },
      {
        paths: [DASHBOARD_ROUTE],
        disabled: !homeEnabled,
      },
      {
        paths: [RECIPIENTS_ROUTE, ADD_RECIPIENTS_ROUTE],
        disabled: !recipientsEnabled,
      },
      {
        paths: [
          TRANSACTIONS_ROUTE,
          BULK_PAYMENT_ROUTE,
          BULK_PAYMENT_TRANSACTIONS_ROUTE,
        ],
        disabled: !transactionsEnabled,
      },
      {
        paths: [TASKS_ROUTE, REVIEW_TASK_ROUTE],
        disabled: !tasksEnabled,
      },
      {
        paths: [
          ACCOUNTS_ROUTE,
          CREATE_ACCOUNT_ROUTE,
          SINGLE_ACCOUNT_ROUTE,
          ACCOUNT_TRANSACTIONS_ROUTE,
        ],
        disabled: !accountsEnabled,
      },
      {
        paths: [ADD_BALANCE_ROUTE, TRANSFER_ROUTE, SEND_ROUTE],
        disabled: !transferActionsEnabled,
      },
    ],
    [
      accountsEnabled,
      cardsEnabled,
      homeEnabled,
      recipientsEnabled,
      tasksEnabled,
      teamEnabled,
      transactionsEnabled,
      transferActionsEnabled,
    ],
  )

  useEffect(() => {
    const matchPermission = pathToPermissionsMap.find((permission) =>
      permission.paths.some((path) =>
        matchRoutes([{ path }], location.pathname),
      ),
    )

    if (matchPermission?.disabled) {
      navigate(redirectRoute, { replace: true })
    }
  }, [location.pathname, navigate, pathToPermissionsMap, redirectRoute])

  return isPending ? <Loader /> : <>{children}</>
}
