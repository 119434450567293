import { useMemo } from 'react'
import { useMutation, useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { FormattedMessage } from 'react-intl'
import { generatePath, useNavigate } from 'react-router-dom'

import { ONBOARDING_STEP } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { useErrorToast } from '@/hooks/useErrorToast'
import { useKeyPress } from '@/hooks/useKeyPress'
import { queryClient } from '@/lib/queryClient'
import { GoBackButton } from '@/shared/components'
import {
  Button,
  DataTable,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'

import { getOnboardingStepDetails, submitOnboardingStep } from '../api'
import { useIdentityColumns } from '../components/IdentityTable'
import { OnboardingLoader } from '../components/OnboardingLoader'
import {
  IdentityValidationStep as IdentityValidationStepType,
  OnboardingMemberRole,
  OnboardingMemberState,
  OnboardingStep,
  OnboardingStepConfig,
  OnboardingStepDetails,
  OnboardingStepName,
} from '../types'

type Props = {
  config?: OnboardingStepConfig
  steps: OnboardingStep[]
}

export const IdentityValidationStep = ({ config, steps }: Props) => {
  const columns = useIdentityColumns()
  const notifyError = useErrorToast()
  const navigate = useNavigate()

  const { mutateAsync, isPending } = useMutation({
    mutationFn: submitOnboardingStep,
  })

  const [validationStepDetailsQuery] = useQueries({
    queries: [
      {
        queryKey: [
          queryKeys.getOnboardingStepDetails,
          OnboardingStepName.IDENTITY_VALIDATION,
        ],
        staleTime: 0,
        refetchInterval: 15 * 1000,
        queryFn: () =>
          getOnboardingStepDetails(OnboardingStepName.IDENTITY_VALIDATION),
        select: (data: AxiosResponse<OnboardingStepDetails>) =>
          (data.data as IdentityValidationStepType)?.stepDetails?.members?.sort(
            (a, b) => {
              if (a.roles.includes(OnboardingMemberRole.LEGAL_REPRESENTATIVE)) {
                return -1
              }

              if (b.roles.includes(OnboardingMemberRole.LEGAL_REPRESENTATIVE)) {
                return 1
              }

              return 0
            },
          ) as IdentityValidationStepType['stepDetails']['members'],
      },
    ],
  })

  const getPreviousStep = () => {
    const currentStepIndex = steps.findIndex(
      (step) => step.name === config?.name,
    )

    return steps[currentStepIndex - 1]
  }

  const hasAllIdentitiesValidated = useMemo(() => {
    if (!validationStepDetailsQuery.data) {
      return false
    }
    return validationStepDetailsQuery.data?.every(
      (member) => member.state === OnboardingMemberState.COMPLETED,
    )
  }, [validationStepDetailsQuery.data])

  const filteredColumns = useMemo(() => {
    if (hasAllIdentitiesValidated) {
      return columns.filter((column) => column.id !== 'action')
    }

    return columns
  }, [hasAllIdentitiesValidated, columns])

  const submitStep = async () => {
    if (!config?.name) {
      return
    }

    try {
      await mutateAsync({
        stepName: OnboardingStepName.IDENTITY_VALIDATION,
        stepDetails: {},
      })

      const currentStepIndex = steps.findIndex(
        (step) => step.name === config.name,
      )

      const nextStep = steps[currentStepIndex + 1]

      await queryClient.refetchQueries({
        queryKey: [queryKeys.getOnboardingState],
      })

      queryClient.invalidateQueries({
        queryKey: [queryKeys.getOnboardingStepDetails, config.name],
      })

      navigate(generatePath(ONBOARDING_STEP, { step: nextStep.name }))
    } catch (error) {
      if (error instanceof Error) {
        notifyError(error)
      }
    }
  }

  useKeyPress('Enter', () => {
    if (hasAllIdentitiesValidated) {
      submitStep()
    }
  })

  if (validationStepDetailsQuery.isPending) {
    return <OnboardingLoader />
  }

  return (
    <>
      <GoBackButton
        className="hidden md:left-80 md:flex"
        to={generatePath(ONBOARDING_STEP, {
          step: getPreviousStep()?.name ?? '',
        })}
      />

      <SlideInScreen className="h-[70vh] max-w-4xl">
        <Typography variant="h3" text="center">
          <FormattedMessage
            defaultMessage="Identity validation"
            id="onboarding.identityValidation.title"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            defaultMessage="The following key individuals need to validate their identity. Follow the steps below to continue"
            id="onboarding.identityValidation.subtitle"
          />
        </Typography>

        <div className="p-6" />

        <DataTable
          loaderOptions={{ rows: 2 }}
          columns={filteredColumns}
          data={validationStepDetailsQuery.data ?? []}
          isLoading={validationStepDetailsQuery.isPending}
        />
      </SlideInScreen>
      <StickyContainer className="mx-auto max-w-xl">
        <Button
          width="full"
          onClick={submitStep}
          disabled={isPending || !hasAllIdentitiesValidated}
        >
          <FormattedMessage
            defaultMessage="Save & Continue"
            id="action.saveAndContinue"
          />
        </Button>
      </StickyContainer>
    </>
  )
}
