import { forwardRef, Ref, type SVGProps } from 'react'

const SvgCalendar = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path fill="#F5F5F5" d="M0 0h24v24H0z" />
    <path fill="#fff" d="M-322-102h430v1315h-430z" />
    <rect
      width={369}
      height={1253}
      x={-291.5}
      y={-53.5}
      stroke="#9747FF"
      strokeDasharray="10 5"
      rx={4.5}
    />
    <g fill={props.color || `currentColor`}>
      <path d="M7.75 4a.75.75 0 0 0-1.5 0v1.816a3.375 3.375 0 0 0-2.872 2.899l-.087.653-.042.332a.493.493 0 0 0 .492.55H20.26a.493.493 0 0 0 .492-.55q-.02-.166-.042-.332l-.087-.653a3.375 3.375 0 0 0-2.872-2.899V4a.75.75 0 0 0-1.5 0v1.668a48 48 0 0 0-8.5 0zM20.945 12.226a.494.494 0 0 0-.496-.476H3.551a.494.494 0 0 0-.496.476c-.059 1.807.05 3.619.33 5.41a3.01 3.01 0 0 0 2.678 2.532l1.193.118c3.155.31 6.333.31 9.488 0l1.193-.118a3.01 3.01 0 0 0 2.678-2.532c.28-1.791.389-3.603.33-5.41" />
    </g>
  </svg>
)
const ForwardRef = forwardRef(SvgCalendar)
export default ForwardRef
