import { Navigate, Outlet, RouteObject } from 'react-router-dom'

import {
  DASHBOARD_ROUTE,
  LOGGING_OUT_ROUTE,
  ROOT_ROUTE,
  SIGNING_IN_ROUTE,
} from '@/constants/paths'
import { Loader } from '@/shared/components'

import { accountActionsRoutes, accountsRoutes } from '../Accounts'
import { bulkPaymentsRoutes, bulkTransactionsRoutes } from '../BulkPayments'
import { cardsRoutes, cardTransactionsRoutes, createCardRoutes } from '../Cards'
import { contractorsRoutes } from '../Contractors'
import { moveMoneyRoutes } from '../MoveMoney'
import { onboardingRoutes } from '../Onboarding'
import { addRecipientRoutes, recipientsRoutes } from '../Recipients'
import { reviewTasksRoutes, tasksRoutes } from '../Tasks/routes'
import { addTeamMemberRoutes, teamRoutes } from '../Team'
import { cashbackTransactionsRoutes, transactionsRoutes } from '../Transactions'

import { BusinessStateChecker } from './BusinessStateChecker'
import { Home } from './Home'
import { Layout } from './Layout'
import { PermissionsChecker } from './PermissionsChecker'
import { RoleChecker } from './RoleChecker'

export const dashboardRoutes: RouteObject[] = [
  {
    path: ROOT_ROUTE,
    element: <Navigate to={SIGNING_IN_ROUTE} />,
  },
  {
    path: SIGNING_IN_ROUTE,
    element: <RoleChecker />,
  },
  {
    path: LOGGING_OUT_ROUTE,
    element: <Loader />,
  },
  {
    path: DASHBOARD_ROUTE,
    element: (
      <BusinessStateChecker>
        <PermissionsChecker>
          <Layout />
        </PermissionsChecker>
      </BusinessStateChecker>
    ),
    children: [
      {
        index: true,
        element: <Home />,
      },
      // /dashboard prefixed routes
      ...accountsRoutes,
      ...bulkTransactionsRoutes,
      ...cardsRoutes,
      ...cardTransactionsRoutes,
      ...cashbackTransactionsRoutes,
      ...contractorsRoutes,
      ...recipientsRoutes,
      ...tasksRoutes,
      ...teamRoutes,
      ...transactionsRoutes,
    ],
  },
  {
    element: (
      <BusinessStateChecker>
        <PermissionsChecker>
          <Outlet />
        </PermissionsChecker>
      </BusinessStateChecker>
    ),
    children: [
      ...accountActionsRoutes,
      ...addRecipientRoutes,
      ...addTeamMemberRoutes,
      ...bulkPaymentsRoutes,
      ...createCardRoutes,
      ...moveMoneyRoutes,
      ...reviewTasksRoutes,
    ],
  },
  ...onboardingRoutes,
]
