import { Currency } from '@/constants/currency'
import { Period } from '@/types/recharts'

import { PriceChartRatesResponse } from '../getPriceChartRates'

export const PRICE_CHART_RATES: PriceChartRatesResponse = {
  period: Period.Day,
  localCurrency: Currency.MXN,
  baseCurrency: Currency.USD,
  startDate: '2024-05-22T17:20:00',
  endDate: '2024-05-23T17:23:00.108074',
  fxRates: [
    {
      id: '5aa0700c-17b5-4f32-96d3-037fb6621ba3',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.7727,
      bid: 16.438,
      mid: 16.60535,
      createdAt: '2024-05-22T17:20:00',
    },
    {
      id: '5ef37a0b-e9d6-4ece-a1b1-1c7ef4ced200',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.7838,
      bid: 16.4419,
      mid: 16.61285,
      createdAt: '2024-05-22T17:30:00',
    },
    {
      id: '5f061737-03fd-49ac-b32b-351a485c636a',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.7899,
      bid: 16.4538,
      mid: 16.62185,
      createdAt: '2024-05-22T17:40:00',
    },
    {
      id: '0d9cc9af-dcb3-4677-a9d3-006b6a335e42',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8061,
      bid: 16.4617,
      mid: 16.6339,
      createdAt: '2024-05-22T17:50:00',
    },
    {
      id: '38fb3bb7-bb87-412b-86f2-85f5f1b0beb4',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8,
      bid: 16.4568,
      mid: 16.6284,
      createdAt: '2024-05-22T18:00:00',
    },
    {
      id: 'bed61e09-4090-46a6-84f8-645a712c777f',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8111,
      bid: 16.4696,
      mid: 16.64035,
      createdAt: '2024-05-22T18:10:00',
    },
    {
      id: 'b02df85a-0276-41e3-9f47-7c82e67edbdd',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8414,
      bid: 16.4944,
      mid: 16.6679,
      createdAt: '2024-05-22T18:20:00',
    },
    {
      id: '13d4dccf-e356-44a4-bf58-6743f17ff19c',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8535,
      bid: 16.5073,
      mid: 16.6804,
      createdAt: '2024-05-22T18:30:00',
    },
    {
      id: '6bbaf537-0660-47bd-9095-2e449f976b3b',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8465,
      bid: 16.5063,
      mid: 16.6764,
      createdAt: '2024-05-22T18:40:00',
    },
    {
      id: '0c8f39d3-4890-4578-be1b-4b48915074df',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8475,
      bid: 16.4993,
      mid: 16.6734,
      createdAt: '2024-05-22T18:50:00',
    },
    {
      id: 'efed3396-bcc5-41f4-bb04-14507daf96e4',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8283,
      bid: 16.4884,
      mid: 16.65835,
      createdAt: '2024-05-22T19:00:00',
    },
    {
      id: '681b4c56-7dc9-4ba1-aed3-9b68d6d45500',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8374,
      bid: 16.4914,
      mid: 16.6644,
      createdAt: '2024-05-22T19:10:00',
    },
    {
      id: '23e7a736-bff8-48dc-9473-011f66a48edd',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8354,
      bid: 16.4964,
      mid: 16.6659,
      createdAt: '2024-05-22T19:20:00',
    },
    {
      id: 'b2fbe320-77cd-4201-9156-f8e0441c4a68',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8333,
      bid: 16.4875,
      mid: 16.6604,
      createdAt: '2024-05-22T19:30:00',
    },
    {
      id: 'f9f754f6-f718-4b5a-b8cc-b87170797c21',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8303,
      bid: 16.4845,
      mid: 16.6574,
      createdAt: '2024-05-22T19:40:00',
    },
    {
      id: 'abe25dfc-b4f0-4afa-beb9-a60bf77da673',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8293,
      bid: 16.4845,
      mid: 16.6569,
      createdAt: '2024-05-22T19:50:00',
    },
    {
      id: '56932cf1-85cd-4417-b242-74184c9b15e1',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8212,
      bid: 16.4746,
      mid: 16.6479,
      createdAt: '2024-05-22T20:00:00',
    },
    {
      id: '05ac4242-d6cd-46c9-9b45-ecd8ae9d44a6',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8141,
      bid: 16.4686,
      mid: 16.64135,
      createdAt: '2024-05-22T20:10:00',
    },
    {
      id: 'ce96e9a3-79d3-4bab-9c96-c2cac010d544',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8172,
      bid: 16.4766,
      mid: 16.6469,
      createdAt: '2024-05-22T20:20:00',
    },
    {
      id: '95bc6c3c-a4b8-48e8-9dd8-5d50c470447b',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8242,
      bid: 16.4795,
      mid: 16.65185,
      createdAt: '2024-05-22T20:30:00',
    },
    {
      id: '862492ac-a28c-45f1-98f4-5b4ae7d4bca4',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8242,
      bid: 16.4815,
      mid: 16.65285,
      createdAt: '2024-05-22T20:40:00',
    },
    {
      id: '1afa1a02-99fc-4d2f-abdf-66d217227786',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8273,
      bid: 16.4865,
      mid: 16.6569,
      createdAt: '2024-05-22T20:50:00',
    },
    {
      id: '984322b2-2ce0-4b2a-8d2d-e0e6cb2fb3fb',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8263,
      bid: 16.4835,
      mid: 16.6549,
      createdAt: '2024-05-22T21:00:00',
    },
    {
      id: 'b0be5a4d-3cc9-4b8e-8c45-9d64690de723',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8152,
      bid: 16.4716,
      mid: 16.6434,
      createdAt: '2024-05-22T21:10:00',
    },
    {
      id: '40f72a03-8730-42e6-a776-60974585d238',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8192,
      bid: 16.4756,
      mid: 16.6474,
      createdAt: '2024-05-22T21:20:00',
    },
    {
      id: '8b95bdc7-c7ea-4644-bfd3-489c71e52da4',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8232,
      bid: 16.4756,
      mid: 16.6494,
      createdAt: '2024-05-22T21:30:00',
    },
    {
      id: '887440c7-483b-46b2-8cb9-5b767c3a40c6',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8222,
      bid: 16.4776,
      mid: 16.6499,
      createdAt: '2024-05-22T21:40:00',
    },
    {
      id: 'f2607ed7-ee3e-4f82-a613-0b6623443f2a',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8192,
      bid: 16.4795,
      mid: 16.64935,
      createdAt: '2024-05-22T21:50:00',
    },
    {
      id: 'deaedaec-2180-468f-b123-9c76aa7121b4',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8172,
      bid: 16.4716,
      mid: 16.6444,
      createdAt: '2024-05-22T22:00:00',
    },
    {
      id: '586f065a-cb79-4f64-a58f-b2219a14b831',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8141,
      bid: 16.4686,
      mid: 16.64135,
      createdAt: '2024-05-22T22:10:00',
    },
    {
      id: '545fb503-8b60-4ced-b48c-8c6154916392',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8162,
      bid: 16.4696,
      mid: 16.6429,
      createdAt: '2024-05-22T22:20:00',
    },
    {
      id: '459414f2-eaa9-4068-9c0c-d5e9671a0148',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.803,
      bid: 16.4647,
      mid: 16.63385,
      createdAt: '2024-05-22T22:30:00',
    },
    {
      id: '11fe1a1b-d3ad-4ec8-9627-495b1b970788',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8182,
      bid: 16.4736,
      mid: 16.6459,
      createdAt: '2024-05-22T22:40:00',
    },
    {
      id: '38f2bb1e-582b-4587-8be1-5afe34d834b3',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8162,
      bid: 16.4726,
      mid: 16.6444,
      createdAt: '2024-05-22T22:50:00',
    },
    {
      id: '2faa4a4f-c6ae-44d4-9633-a17ceb1306a9',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8162,
      bid: 16.4746,
      mid: 16.6454,
      createdAt: '2024-05-22T23:00:00',
    },
    {
      id: '6b446a50-2aaf-4485-b709-cc6eee5332e2',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8172,
      bid: 16.4716,
      mid: 16.6444,
      createdAt: '2024-05-22T23:10:00',
    },
    {
      id: '2ab9352f-d875-4d52-ab0e-cb241a6e85c8',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8192,
      bid: 16.4746,
      mid: 16.6469,
      createdAt: '2024-05-22T23:20:00',
    },
    {
      id: 'eb7f0f1b-d2df-4583-87e1-5cf79d81f792',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8172,
      bid: 16.4736,
      mid: 16.6454,
      createdAt: '2024-05-22T23:30:00',
    },
    {
      id: '5d0b1df8-6fab-4a06-b48f-46860573cf97',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8172,
      bid: 16.4726,
      mid: 16.6449,
      createdAt: '2024-05-22T23:40:00',
    },
    {
      id: 'cdedde1e-b42c-4fca-adca-e7b5d4f0ee9b',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8172,
      bid: 16.4726,
      mid: 16.6449,
      createdAt: '2024-05-22T23:50:00',
    },
    {
      id: 'e69416a2-d87c-4a4d-868e-36951d0406a7',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8222,
      bid: 16.4786,
      mid: 16.6504,
      createdAt: '2024-05-23T00:00:00',
    },
    {
      id: '8e34dd1c-7d7a-46d6-88a5-a0cd785bacf8',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8162,
      bid: 16.4746,
      mid: 16.6454,
      createdAt: '2024-05-23T00:10:00',
    },
    {
      id: 'cb3ae16f-0c36-4488-971d-c62b86d75a84',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8121,
      bid: 16.4736,
      mid: 16.64285,
      createdAt: '2024-05-23T00:20:00',
    },
    {
      id: '01c7a1a7-8365-4e20-94f9-24b4e347e9b6',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8162,
      bid: 16.4736,
      mid: 16.6449,
      createdAt: '2024-05-23T00:30:00',
    },
    {
      id: '323ab87c-2805-4b89-b113-d18ca162a163',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8162,
      bid: 16.4726,
      mid: 16.6444,
      createdAt: '2024-05-23T00:40:00',
    },
    {
      id: 'f7f611a3-0004-479d-a0ca-1ad751ff3eb8',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8141,
      bid: 16.4746,
      mid: 16.64435,
      createdAt: '2024-05-23T00:50:00',
    },
    {
      id: 'eec643e9-d801-4809-b14a-a9a5131c59b7',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8212,
      bid: 16.4756,
      mid: 16.6484,
      createdAt: '2024-05-23T01:00:00',
    },
    {
      id: 'c7dae147-38f1-4142-aee6-a9e6c5d9f185',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8172,
      bid: 16.4726,
      mid: 16.6449,
      createdAt: '2024-05-23T01:10:00',
    },
    {
      id: '403cd7d8-2fac-4869-9ebf-76d377b5f82b',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8141,
      bid: 16.4686,
      mid: 16.64135,
      createdAt: '2024-05-23T01:20:00',
    },
    {
      id: '228344f9-8388-4524-9533-a2dc0575178b',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8222,
      bid: 16.4756,
      mid: 16.6489,
      createdAt: '2024-05-23T01:30:00',
    },
    {
      id: 'cda174f6-e312-4437-bdfe-fa67ef77890b',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8212,
      bid: 16.4766,
      mid: 16.6489,
      createdAt: '2024-05-23T01:40:00',
    },
    {
      id: '07f55d9f-f930-4e04-8e2d-372bc3f7ef5b',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8212,
      bid: 16.4825,
      mid: 16.65185,
      createdAt: '2024-05-23T01:50:00',
    },
    {
      id: '3b89cdab-77d6-4709-8025-9e520a3a2e0c',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8293,
      bid: 16.4825,
      mid: 16.6559,
      createdAt: '2024-05-23T02:00:00',
    },
    {
      id: '39ce9feb-e698-48f4-ba56-fdcc7d87171b',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8283,
      bid: 16.4815,
      mid: 16.6549,
      createdAt: '2024-05-23T02:10:00',
    },
    {
      id: 'fa20ea3c-b42e-433b-a1af-53d96baa9eaa',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8333,
      bid: 16.4855,
      mid: 16.6594,
      createdAt: '2024-05-23T02:20:00',
    },
    {
      id: 'e6534726-4749-4e87-86c9-8b54b426f498',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8333,
      bid: 16.4875,
      mid: 16.6604,
      createdAt: '2024-05-23T02:30:00',
    },
    {
      id: 'fb5e394c-8e14-4fed-b183-cbb6e37cff1d',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8222,
      bid: 16.4766,
      mid: 16.6494,
      createdAt: '2024-05-23T02:40:00',
    },
    {
      id: '8a93a524-0a85-4f82-9230-545794f6309c',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8111,
      bid: 16.4706,
      mid: 16.64085,
      createdAt: '2024-05-23T02:50:00',
    },
    {
      id: '98f13c8c-10e2-467b-8ff3-428365cef234',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8091,
      bid: 16.4706,
      mid: 16.63985,
      createdAt: '2024-05-23T03:00:00',
    },
    {
      id: '0437ac8f-cd97-41dc-9be4-5fe3460670b9',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8081,
      bid: 16.4637,
      mid: 16.6359,
      createdAt: '2024-05-23T03:10:00',
    },
    {
      id: '64ea63b3-3824-41fa-b145-2f2543652af7',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.802,
      bid: 16.4637,
      mid: 16.63285,
      createdAt: '2024-05-23T03:20:00',
    },
    {
      id: '3672a84e-d39a-4020-b5a2-7c8e6e20bc12',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.799,
      bid: 16.4558,
      mid: 16.6274,
      createdAt: '2024-05-23T03:30:00',
    },
    {
      id: 'b1117ca2-2216-40c7-9c89-c1491ac3d59c',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8051,
      bid: 16.4617,
      mid: 16.6334,
      createdAt: '2024-05-23T03:40:00',
    },
    {
      id: '4b994713-e31c-4911-9ffb-22ddd53af18f',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8051,
      bid: 16.4617,
      mid: 16.6334,
      createdAt: '2024-05-23T03:50:00',
    },
    {
      id: '3f074496-fe75-4055-b54e-8423f9dc387a',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8,
      bid: 16.4607,
      mid: 16.63035,
      createdAt: '2024-05-23T04:00:00',
    },
    {
      id: '22324efc-a823-4d6b-9efc-745c60ab8611',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.798,
      bid: 16.4588,
      mid: 16.6284,
      createdAt: '2024-05-23T04:10:00',
    },
    {
      id: '0aba4118-5392-4825-80c1-39d9bbff16bd',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.803,
      bid: 16.4597,
      mid: 16.63135,
      createdAt: '2024-05-23T04:20:00',
    },
    {
      id: '02c5370d-5aaf-4151-9558-79a9a6e1efc4',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.802,
      bid: 16.4637,
      mid: 16.63285,
      createdAt: '2024-05-23T04:30:00',
    },
    {
      id: '4b51f1af-7f4b-45d5-98f1-1ea0e1550093',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8081,
      bid: 16.4696,
      mid: 16.63885,
      createdAt: '2024-05-23T04:40:00',
    },
    {
      id: '2e8424e6-8882-4532-8b42-0560eec734b7',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8111,
      bid: 16.4716,
      mid: 16.64135,
      createdAt: '2024-05-23T04:50:00',
    },
    {
      id: 'ebc9a326-cdbf-4db1-9905-c1271719adc9',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8121,
      bid: 16.4677,
      mid: 16.6399,
      createdAt: '2024-05-23T05:00:00',
    },
    {
      id: '2b0369ca-f6d2-4cbf-830e-379b40f7b1ac',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8182,
      bid: 16.4736,
      mid: 16.6459,
      createdAt: '2024-05-23T05:10:00',
    },
    {
      id: '28ab75c3-fe50-4341-a256-604f0e227c8e',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8202,
      bid: 16.4746,
      mid: 16.6474,
      createdAt: '2024-05-23T05:20:00',
    },
    {
      id: '93796cf9-9b51-446a-a2fc-cda871322483',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8172,
      bid: 16.4716,
      mid: 16.6444,
      createdAt: '2024-05-23T05:30:00',
    },
    {
      id: '984dcc79-e8c9-47e8-82d2-4fc6c91d1c01',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8242,
      bid: 16.4766,
      mid: 16.6504,
      createdAt: '2024-05-23T05:40:00',
    },
    {
      id: '60f90f93-f439-4816-adbf-209ded0c23af',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8273,
      bid: 16.4795,
      mid: 16.6534,
      createdAt: '2024-05-23T05:50:00',
    },
    {
      id: '78c6ecd2-4daa-45b8-928b-2c79d48c4358',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8212,
      bid: 16.4786,
      mid: 16.6499,
      createdAt: '2024-05-23T06:00:00',
    },
    {
      id: '69e653fe-4bc0-462a-a0c3-28dfde247c96',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8101,
      bid: 16.4657,
      mid: 16.6379,
      createdAt: '2024-05-23T06:10:00',
    },
    {
      id: 'ad0fe2bc-a3fc-4f6a-bcdc-660e7f776471',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8061,
      bid: 16.4607,
      mid: 16.6334,
      createdAt: '2024-05-23T06:20:00',
    },
    {
      id: 'f52547e4-b5e0-42b9-9fc0-36baf7ab1d12',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.804,
      bid: 16.4597,
      mid: 16.63185,
      createdAt: '2024-05-23T06:30:00',
    },
    {
      id: '48a015b6-5d81-4eb3-895b-459124b80188',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.801,
      bid: 16.4588,
      mid: 16.6299,
      createdAt: '2024-05-23T06:40:00',
    },
    {
      id: '93515d29-b893-4403-a7de-548a870228f7',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8051,
      bid: 16.4647,
      mid: 16.6349,
      createdAt: '2024-05-23T06:50:00',
    },
    {
      id: 'e8b6030a-1901-4afd-a681-085ee90a4110',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8061,
      bid: 16.4647,
      mid: 16.6354,
      createdAt: '2024-05-23T07:00:00',
    },
    {
      id: '04f289aa-b672-450d-af75-a4736106820e',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8182,
      bid: 16.4815,
      mid: 16.64985,
      createdAt: '2024-05-23T07:10:00',
    },
    {
      id: 'f89dd18f-603f-4d5e-8bdf-f64fd539467f',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8283,
      bid: 16.4865,
      mid: 16.6574,
      createdAt: '2024-05-23T07:20:00',
    },
    {
      id: '67803e04-0fee-498a-a7fa-0f4213cb7445',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8263,
      bid: 16.4835,
      mid: 16.6549,
      createdAt: '2024-05-23T07:30:00',
    },
    {
      id: '8eac4cf8-796a-49da-88e1-692fb4851fed',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8192,
      bid: 16.4835,
      mid: 16.65135,
      createdAt: '2024-05-23T07:40:00',
    },
    {
      id: 'e53776bf-fa13-4753-bf87-d85fa25fb869',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8242,
      bid: 16.4795,
      mid: 16.65185,
      createdAt: '2024-05-23T07:50:00',
    },
    {
      id: '10ca144b-9dbc-4342-9dd2-db4930656edb',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8222,
      bid: 16.4786,
      mid: 16.6504,
      createdAt: '2024-05-23T08:00:00',
    },
    {
      id: 'cf7d4992-48d1-4073-8f89-e924491321ee',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8273,
      bid: 16.4815,
      mid: 16.6544,
      createdAt: '2024-05-23T08:10:00',
    },
    {
      id: '8a475dd6-d9e0-435e-ac1f-d967163d88e3',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8212,
      bid: 16.4805,
      mid: 16.65085,
      createdAt: '2024-05-23T08:20:00',
    },
    {
      id: '19a999bf-1544-4a9f-88e7-495ceb1057a5',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8232,
      bid: 16.4786,
      mid: 16.6509,
      createdAt: '2024-05-23T08:30:00',
    },
    {
      id: '55410047-9188-4ab0-a07c-c68023c97d6b',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8192,
      bid: 16.4746,
      mid: 16.6469,
      createdAt: '2024-05-23T08:40:00',
    },
    {
      id: '56631873-6e69-4e11-9a8a-c9b6465b4e31',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8131,
      bid: 16.4677,
      mid: 16.6404,
      createdAt: '2024-05-23T08:50:00',
    },
    {
      id: '592b056d-0889-4277-ae3f-3b28e669b592',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8111,
      bid: 16.4686,
      mid: 16.63985,
      createdAt: '2024-05-23T09:00:00',
    },
    {
      id: '2151c4a9-b118-4e10-aa4f-cbce814c9737',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8121,
      bid: 16.4667,
      mid: 16.6394,
      createdAt: '2024-05-23T09:10:00',
    },
    {
      id: '45d6c139-ba65-41ae-9c93-84def4c9137c',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8111,
      bid: 16.4686,
      mid: 16.63985,
      createdAt: '2024-05-23T09:20:00',
    },
    {
      id: '88d0fca6-0d06-4d3e-9e12-0d15bca4e1c3',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8111,
      bid: 16.4677,
      mid: 16.6394,
      createdAt: '2024-05-23T09:30:00',
    },
    {
      id: 'aac8e780-a963-4ae3-a388-5f51e697ddec',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8111,
      bid: 16.4637,
      mid: 16.6374,
      createdAt: '2024-05-23T09:40:00',
    },
    {
      id: 'e1160b48-aee9-4971-8b8a-aca134969f6b',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.8111,
      bid: 16.4637,
      mid: 16.6374,
      createdAt: '2024-05-23T09:50:00',
    },
    {
      id: '3c747c2b-9b89-44b4-aeb7-09f1d30a9e3a',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.797,
      bid: 16.4498,
      mid: 16.6234,
      createdAt: '2024-05-23T10:00:00',
    },
    {
      id: 'c43d3f9d-02a8-4e1c-941f-b86c2c2f09e0',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.7929,
      bid: 16.4449,
      mid: 16.6189,
      createdAt: '2024-05-23T10:10:00',
    },
    {
      id: 'fb0304f6-cb81-4047-8d20-47550b11fb02',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.7889,
      bid: 16.4479,
      mid: 16.6184,
      createdAt: '2024-05-23T10:20:00',
    },
    {
      id: '1e0fa0c6-e627-4603-b478-b02a1a5c48fd',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.7889,
      bid: 16.4479,
      mid: 16.6184,
      createdAt: '2024-05-23T10:30:00',
    },
    {
      id: '6acedff3-6ed3-4f2f-9cd0-b29dd49614da',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.7889,
      bid: 16.4419,
      mid: 16.6154,
      createdAt: '2024-05-23T10:40:00',
    },
    {
      id: 'bb106271-fa27-492a-9199-28bc4a4a4676',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.7848,
      bid: 16.4399,
      mid: 16.61235,
      createdAt: '2024-05-23T10:50:00',
    },
    {
      id: 'e1416677-9ad4-411d-a624-43318edd5b7f',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.7818,
      bid: 16.4399,
      mid: 16.61085,
      createdAt: '2024-05-23T11:00:00',
    },
    {
      id: 'd540a8af-6f7c-4f71-839f-4eaa09c8589d',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.7869,
      bid: 16.4518,
      mid: 16.61935,
      createdAt: '2024-05-23T11:10:00',
    },
    {
      id: 'fd346ac5-f123-4233-9567-6028bbd9b4ad',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.7919,
      bid: 16.4538,
      mid: 16.62285,
      createdAt: '2024-05-23T11:20:00',
    },
    {
      id: '6c3234ac-2463-4ad2-bd6e-9eeedecd1bc0',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.799,
      bid: 16.4548,
      mid: 16.6269,
      createdAt: '2024-05-23T11:30:00',
    },
    {
      id: 'b74fdffd-2020-40f4-8fcc-1c452fe9b48c',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.7949,
      bid: 16.4508,
      mid: 16.62285,
      createdAt: '2024-05-23T11:40:00',
    },
    {
      id: '969ab5d9-17e8-46b7-b59d-cd24a117ea1c',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.7929,
      bid: 16.4508,
      mid: 16.62185,
      createdAt: '2024-05-23T11:50:00',
    },
    {
      id: '9c006cae-1fae-473e-b0ec-79f2faad83d1',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.7828,
      bid: 16.4399,
      mid: 16.61135,
      createdAt: '2024-05-23T12:00:00',
    },
    {
      id: '41e20565-eadc-4f00-aba1-2f0b5751da30',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.7778,
      bid: 16.431,
      mid: 16.6044,
      createdAt: '2024-05-23T12:10:00',
    },
    {
      id: '02dd228e-53c6-4a87-8cc9-ba061d54961c',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.7717,
      bid: 16.433,
      mid: 16.60235,
      createdAt: '2024-05-23T12:20:00',
    },
    {
      id: 'e98ac107-b686-49eb-884c-11791588412b',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.7778,
      bid: 16.434,
      mid: 16.6059,
      createdAt: '2024-05-23T12:30:00',
    },
    {
      id: 'c7387ad7-bf93-4a5a-b377-9de0f42052c2',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.7828,
      bid: 16.438,
      mid: 16.6104,
      createdAt: '2024-05-23T12:40:00',
    },
    {
      id: 'cbe9b2bb-62b0-4ee1-b836-1666ca7c3a24',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.7727,
      bid: 16.438,
      mid: 16.60535,
      createdAt: '2024-05-23T12:50:00',
    },
    {
      id: 'b7c0875d-d1ed-49e3-b6e7-6b9af8638751',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.7859,
      bid: 16.4439,
      mid: 16.6149,
      createdAt: '2024-05-23T13:00:00',
    },
    {
      id: 'ec4fa3c0-258f-4f7b-bcee-2f6f107145b8',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.7818,
      bid: 16.4399,
      mid: 16.61085,
      createdAt: '2024-05-23T13:10:00',
    },
    {
      id: 'e7811804-e62d-446f-adf8-2b17e5cdb9db',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.7848,
      bid: 16.4419,
      mid: 16.61335,
      createdAt: '2024-05-23T13:20:00',
    },
    {
      id: '7c711826-7928-40bc-a3f7-2d11f008855e',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.7848,
      bid: 16.437,
      mid: 16.6109,
      createdAt: '2024-05-23T13:30:00',
    },
    {
      id: 'c883ac71-51d1-4784-9663-24c672c33ac4',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.7929,
      bid: 16.4488,
      mid: 16.62085,
      createdAt: '2024-05-23T13:40:00',
    },
    {
      id: '08c4988d-a41a-48b1-af43-cffd2f87f477',
      book: 'usd_mxn',
      baseCurrency: Currency.USD,
      localCurrency: Currency.MXN,
      ask: 16.802,
      bid: 16.4538,
      mid: 16.6279,
      createdAt: '2024-05-23T13:50:00',
    },
  ],
}
