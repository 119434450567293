import { forwardRef, Ref, type SVGProps } from 'react'

const SvgCloudDownload = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill={props.color || `currentColor`}
      d="M6.939 15.753a.75.75 0 1 0-.06-1.499zM4 12.336l-.75-.023v.058zm2.183-2.583.148.736a.75.75 0 0 0 .602-.736zm0-.084-.75-.035v.035zm2.908-2.666-.03.75h.031zm1.067.185-.254.706a.75.75 0 0 0 .92-.362zm3.66-2.185-.014.75h.045zm4 3.667h.75v-.033zm-.166 1.05-.713-.232a.75.75 0 0 0 .606.975zM20 12.336l.75.035v-.06zm-2.879 1.918a.75.75 0 1 0-.06 1.499zm-5.871 4.75a.75.75 0 0 0 1.5 0zm1.5-6a.75.75 0 0 0-1.5 0zm-1.28 5.47a.75.75 0 1 0 1.06 1.06zm3.06-.94a.75.75 0 1 0-1.06-1.06zm-3.06 2a.75.75 0 1 0 1.06-1.06zm-.94-3.06a.75.75 0 1 0-1.06 1.06zm-3.651-2.22a2.05 2.05 0 0 1-2.13-1.953l-1.498.07a3.55 3.55 0 0 0 3.688 3.382zm-2.13-1.894a1.97 1.97 0 0 1 1.582-1.871l-.296-1.47a3.47 3.47 0 0 0-2.785 3.294zm2.184-2.607V9.67h-1.5v.083zm0-.048A2.05 2.05 0 0 1 9.06 7.753l.06-1.499a3.55 3.55 0 0 0-3.687 3.38zm2.16-1.952c.276 0 .55.047.811.141l.508-1.411a3.9 3.9 0 0 0-1.322-.23zm1.732-.221a3.28 3.28 0 0 1 2.979-1.779l.028-1.5a4.78 4.78 0 0 0-4.34 2.592zm3.024-1.78a3.094 3.094 0 0 1 3.22 2.952l1.498-.067a4.594 4.594 0 0 0-4.78-4.383zm3.219 2.919c0 .278-.043.553-.13.817l1.427.464c.135-.414.203-.847.203-1.282zm.477 1.792a1.99 1.99 0 0 1 1.705 1.9l1.5-.053a3.49 3.49 0 0 0-2.991-3.332zm1.706 1.838a2.05 2.05 0 0 1-2.13 1.953l-.06 1.499a3.55 3.55 0 0 0 3.688-3.382zm-6.501 6.702v-6h-1.5v6zm-.22.53 2-2-1.06-1.06-2 2zm0-1.06-2-2-1.06 1.06 2 2z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgCloudDownload)
export default ForwardRef
