import { motion } from 'framer-motion'

import { cn } from '@/lib/utils'

import { slideFromLeftAnimation } from './animations'

type Props = {
  children: React.ReactNode
  className?: string
}

export const SlideInLeft = ({ children, className }: Props) => {
  return (
    <motion.div
      variants={slideFromLeftAnimation}
      initial="hidden"
      animate="visible"
      exit="exit"
      className={cn('', className)}
    >
      {children}
    </motion.div>
  )
}
