import { useIntl } from 'react-intl'
import { useSearchParams } from 'react-router-dom'

import { TransactionStatus } from '@/features/Transactions/types'

import { STATUS_FILTER_NAME } from './data'
import { FilterBadge } from './FilterBadge'

export const StateBadge = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const intl = useIntl()

  if (!searchParams.get(STATUS_FILTER_NAME)) {
    return null
  }

  return (
    <FilterBadge
      onClick={() => {
        setSearchParams(
          (params) => {
            params.delete(STATUS_FILTER_NAME)

            return params
          },
          { preventScrollReset: true },
        )
      }}
      name={intl.formatMessage({
        id: 'label.status',
        defaultMessage: 'Status',
      })}
    >
      {searchParams
        .get(STATUS_FILTER_NAME)
        ?.split(',')
        .map((option) => {
          const value = Object.values(TransactionStatus).find(
            (o) => o === option,
          )

          return intl.formatMessage(
            {
              id: 'transaction.status',
              defaultMessage:
                '{status, select, FAILED {Failed} BLOCKED {Blocked} REVERTED {Reverted} PENDING {Pending} COMPLETED {Completed} other {}}',
            },
            { status: value },
          )
        })
        .join(', ')}
    </FilterBadge>
  )
}
