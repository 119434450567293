import { UseFormReturn } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'

import { getCountryISO3byISO2 } from '@/lib/country'
import {
  AnimatedFormLabel,
  FormControl,
  FormField,
  FormItem,
  Input,
  PhoneNumberInput,
} from '@/shared/ui'

import { CountryCodeField } from './CountryCodeField'
import { CountryCombobox } from './CountryCombobox'
import { OptionalTag } from './OptionalTag'
import { Widget } from './Widget'

const MAX_ADDRESS_STREET_LENGTH = 20

type Props = {
  form: UseFormReturn<{
    addressStreet: string
    addressStreetNumber: string
    addressApartment?: string
    addressReferences?: string
    addressDistrict: string
    addressCity: string
    addressState: string
    addressPostCode: string
    addressCountry: string
    localPhoneNumber: string
    internationalPhonePrefix: string
  }>
}

export const CardAddressFields = ({ form }: Props) => {
  const intl = useIntl()

  return (
    <div className="flex flex-col gap-6">
      <Widget
        title={
          <FormattedMessage
            id="label.addressDetails"
            defaultMessage="Address details"
          />
        }
        variant="form"
      >
        <FormField
          control={form.control}
          name="addressStreet"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  autoComplete="address-line1"
                  placeholder={intl.formatMessage({
                    id: 'label.street',
                    defaultMessage: 'Street',
                  })}
                  {...field}
                />
              </FormControl>
              <AnimatedFormLabel>
                <FormattedMessage defaultMessage="Street" id="label.street" />
              </AnimatedFormLabel>
            </FormItem>
          )}
        />

        <div className="grid grid-cols-2 gap-3">
          <FormField
            control={form.control}
            name="addressStreetNumber"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    maxLength={MAX_ADDRESS_STREET_LENGTH}
                    autoComplete="address-line2"
                    placeholder={intl.formatMessage({
                      id: 'label.streetNumber',
                      defaultMessage: 'Street number',
                    })}
                    {...field}
                  />
                </FormControl>
                <AnimatedFormLabel>
                  <FormattedMessage
                    defaultMessage="Street number"
                    id="label.streetNumber"
                  />
                </AnimatedFormLabel>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="addressApartment"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    autoComplete="address-line3"
                    placeholder={intl.formatMessage({
                      id: 'label.aptFloorDoor',
                      defaultMessage: 'Apt., floor, door, etc.',
                    })}
                    {...field}
                  />
                </FormControl>
                <AnimatedFormLabel>
                  <FormattedMessage
                    defaultMessage="Apt., floor, door, etc."
                    id="label.aptFloorDoor"
                  />
                </AnimatedFormLabel>

                {field.value === '' && <OptionalTag />}
              </FormItem>
            )}
          />
        </div>

        <FormField
          control={form.control}
          name="addressReferences"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  placeholder={intl.formatMessage({
                    id: 'label.addressReferences',
                    defaultMessage: 'Address references',
                  })}
                  {...field}
                />
              </FormControl>
              <AnimatedFormLabel>
                <FormattedMessage
                  id="label.addressReferences"
                  defaultMessage="Address references"
                />
              </AnimatedFormLabel>

              {field.value === '' && <OptionalTag />}
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="addressDistrict"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  placeholder={intl.formatMessage({
                    id: 'label.neighborhoodOrDistrict',
                    defaultMessage: 'Neighborhood or district',
                  })}
                  {...field}
                />
              </FormControl>
              <AnimatedFormLabel>
                <FormattedMessage
                  id="label.neighborhoodOrDistrict"
                  defaultMessage="Neighborhood or district"
                />
              </AnimatedFormLabel>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="addressCity"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  key="addressCity"
                  autoComplete="address-level2"
                  placeholder={intl.formatMessage({
                    id: 'label.city',
                    defaultMessage: 'City',
                  })}
                  {...field}
                />
              </FormControl>
              <AnimatedFormLabel>
                <FormattedMessage defaultMessage="City" id="label.city" />
              </AnimatedFormLabel>
            </FormItem>
          )}
        />

        <div className="grid grid-cols-2 gap-3">
          <FormField
            control={form.control}
            name="addressState"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    autoComplete="address-level1"
                    placeholder={intl.formatMessage({
                      id: 'label.stateOrProvince',
                      defaultMessage: 'State or province',
                    })}
                    {...field}
                  />
                </FormControl>
                <AnimatedFormLabel>
                  <FormattedMessage
                    defaultMessage="State or province"
                    id="label.stateOrProvince"
                  />
                </AnimatedFormLabel>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="addressPostCode"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    autoComplete="postal-code"
                    placeholder={intl.formatMessage({
                      id: 'label.postCode',
                      defaultMessage: 'Post code',
                    })}
                    {...field}
                  />
                </FormControl>
                <AnimatedFormLabel>
                  <FormattedMessage
                    defaultMessage="Post code"
                    id="label.postCode"
                  />
                </AnimatedFormLabel>
              </FormItem>
            )}
          />
        </div>

        <FormField
          control={form.control}
          name="addressCountry"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <CountryCombobox
                  disabled
                  placeholder={intl.formatMessage({
                    id: 'label.country',
                    defaultMessage: 'Country',
                  })}
                  onSelect={(value) => {
                    field.onChange(value.valueAsCode)
                  }}
                  value={getCountryISO3byISO2(field.value)}
                />
              </FormControl>
            </FormItem>
          )}
        />
      </Widget>

      <Widget
        title={
          <FormattedMessage
            id="label.contactDetails"
            defaultMessage="Contact details"
          />
        }
        variant="form"
      >
        <div className="flex gap-3">
          <FormField
            control={form.control}
            name="internationalPhonePrefix"
            render={({ field }) => {
              return (
                <CountryCodeField
                  value={field.value}
                  onSelect={(value) =>
                    form.setValue('internationalPhonePrefix', value)
                  }
                />
              )
            }}
          />

          <FormField
            control={form.control}
            name="localPhoneNumber"
            render={({ field }) => (
              <FormItem className="relative flex-1">
                <FormControl>
                  <PhoneNumberInput
                    phonePrefix={form.watch('internationalPhonePrefix')}
                    placeholder={intl.formatMessage({
                      defaultMessage: 'Contact phone number',
                      id: 'label.contactPhoneNumber',
                    })}
                    {...field}
                  />
                </FormControl>
                <AnimatedFormLabel>
                  <FormattedMessage
                    defaultMessage="Contact phone number"
                    id="label.contactPhoneNumber"
                  />
                </AnimatedFormLabel>
              </FormItem>
            )}
          />
        </div>
      </Widget>
    </div>
  )
}
