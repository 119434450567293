import { UseFormReturn } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'

import { CountryCodeField, OptionalTag, Widget } from '@/shared/components'
import {
  AnimatedFormLabel,
  FormControl,
  FormField,
  FormItem,
  Input,
  PhoneNumberInput,
} from '@/shared/ui'

type ContactDetailsFieldsFormPart = {
  email?: string
  internationalPhonePrefix?: string
  localPhoneNumber?: string
}

type Props<T extends ContactDetailsFieldsFormPart> = {
  form: T extends ContactDetailsFieldsFormPart ? UseFormReturn<T> : never
}

export const ContactDetailsFields = <T extends ContactDetailsFieldsFormPart>({
  form,
}: Props<T>) => {
  const intl = useIntl()

  return (
    <Widget
      title={
        <FormattedMessage
          id="recipient.contactDetails"
          defaultMessage="Contact details"
        />
      }
      variant="form"
    >
      <FormField
        control={form.control}
        name="email"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Input
                autoComplete="email"
                placeholder={intl.formatMessage({
                  id: 'label.contactEmail',
                  defaultMessage: 'Contact email',
                })}
                {...field}
              />
            </FormControl>
            <AnimatedFormLabel>
              <FormattedMessage
                id="label.contactEmail"
                defaultMessage="Contact email"
              />
            </AnimatedFormLabel>

            {field.value === '' && <OptionalTag />}
          </FormItem>
        )}
      />

      <div className="flex items-stretch gap-3">
        <FormField
          control={form.control}
          name="internationalPhonePrefix"
          render={({ field }) => {
            return (
              <CountryCodeField
                value={field.value}
                onSelect={(value) =>
                  form.setValue('internationalPhonePrefix', value)
                }
              />
            )
          }}
        />

        <FormField
          control={form.control}
          name="localPhoneNumber"
          render={({ field }) => (
            <FormItem className="relative flex-1">
              <FormControl>
                <PhoneNumberInput
                  phonePrefix={form.watch('internationalPhonePrefix') ?? ''}
                  placeholder={intl.formatMessage({
                    id: 'label.contactPhoneNumber',
                    defaultMessage: 'Contact phone number',
                  })}
                  {...field}
                />
              </FormControl>
              <AnimatedFormLabel>
                <FormattedMessage
                  id="label.contactPhoneNumber"
                  defaultMessage="Contact phone number"
                />
              </AnimatedFormLabel>

              {field.value === '' && <OptionalTag />}
            </FormItem>
          )}
        />
      </div>
    </Widget>
  )
}
