import { forwardRef, Ref, type SVGProps } from 'react'

const SvgGear = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      stroke={props.color || `currentColor`}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M14.502 12a2 2 0 1 1-4 0 2 2 0 0 1 4 0"
      clipRule="evenodd"
    />
    <path
      stroke={props.color || `currentColor`}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m7.768 18.1-2.515-5.287a1.58 1.58 0 0 1 0-1.717l2.499-5.204A2.02 2.02 0 0 1 9.463 5h6.078a2.02 2.02 0 0 1 1.711.892l2.494 5.2a1.58 1.58 0 0 1 0 1.717l-2.51 5.291a2.02 2.02 0 0 1-1.72.9H9.487a2.02 2.02 0 0 1-1.719-.9"
      clipRule="evenodd"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgGear)
export default ForwardRef
