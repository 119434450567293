import { cn, generateColorFromString } from '@/lib/utils'

import { Bank } from '../icons/outline'

type Variant = 'middle' | 'large'

export const AccountIcon = ({
  id,
  variant = 'large',
}: {
  id: string
  variant?: Variant
}) => {
  return (
    <div
      style={{
        backgroundColor: generateColorFromString(id),
      }}
      className={cn(
        'flex shrink-0 items-center justify-center rounded-[5px] p-0.5',
        {
          'h-5 w-5': variant === 'large',
          'h-4 w-4': variant === 'middle',
        },
      )}
    >
      <Bank className="size-4 shrink-0 text-white" />
    </div>
  )
}
