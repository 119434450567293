import { cn } from '@/lib/utils'

type Props = {
  children: React.ReactNode
  className?: string
}

export const FullScreen = ({ children, className }: Props) => {
  return (
    <main
      className={cn(
        'relative flex min-h-screen w-full flex-col items-center px-6 pt-28',
        className,
      )}
    >
      {children}
    </main>
  )
}
