import { RecipientPaymentDetails } from '../types'

export function getName(recipient: RecipientPaymentDetails) {
  if (!recipient) {
    return null
  }

  if (recipient.firstName && recipient.lastName) {
    return `${recipient.firstName} ${recipient.lastName}`
  }

  if (recipient.legalName) {
    return recipient.legalName
  }

  return null
}
