import { RouteObject } from 'react-router-dom'

import { ADD_RECIPIENTS_ROUTE, RECIPIENTS_ROUTE } from '@/constants/paths'

import { AddRecipient } from './AddRecipient'
import { Recipients } from './Recipients'

export const recipientsRoutes: RouteObject[] = [
  {
    path: RECIPIENTS_ROUTE,
    element: <Recipients />,
  },
]

export const addRecipientRoutes: RouteObject[] = [
  {
    path: ADD_RECIPIENTS_ROUTE,
    element: <AddRecipient />,
  },
]
