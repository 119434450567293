import { forwardRef, Ref, type SVGProps } from 'react'

const SvgGlobe = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path fill="#F5F5F5" d="M0 0h24v24H0z" />
    <rect
      width={369}
      height={1253}
      x={-257.5}
      y={-1005.5}
      stroke="#9747FF"
      strokeDasharray="10 5"
      rx={4.5}
    />
    <g fill={props.color || `currentColor`}>
      <path d="M18.955 3.106a.75.75 0 0 0-1.06-1.061l-.708.707A1.27 1.27 0 0 0 17.15 4.5a8.25 8.25 0 0 1-6.146 13.75h-.006c-1.968 0-3.935-.7-5.497-2.1a1.27 1.27 0 0 0-1.748.037l-.707.707a.75.75 0 0 0 1.06 1.061l.551-.55a9.7 9.7 0 0 0 5.594 2.316v1.529H8.5a.75.75 0 0 0 0 1.5h5a.75.75 0 0 0 0-1.5h-1.75v-1.529a9.7 9.7 0 0 0 6.144-2.827c3.63-3.629 3.8-9.407.51-13.238z" />
      <path
        fillRule="evenodd"
        d="M4.5 10a6.5 6.5 0 1 1 13 0 6.5 6.5 0 0 1-13 0M13 6.25a2.75 2.75 0 1 0 0 5.5 2.75 2.75 0 0 0 0-5.5M6.25 12.5a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0"
        clipRule="evenodd"
      />
    </g>
  </svg>
)
const ForwardRef = forwardRef(SvgGlobe)
export default ForwardRef
