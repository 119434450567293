import { useCallback, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Big } from 'big.js'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { toast } from 'sonner'
import { z } from 'zod'

import { Currency } from '@/constants/currency'
import { queryKeys } from '@/constants/queryKeys'
import {
  createTransferPermissions,
  editTeamMemberDetails,
  getTeamMemberPaymentLimit,
} from '@/features/Team'
import {
  LimitPeriodType,
  MovementPermission,
  TeamMember,
} from '@/features/Team/types'
import { useErrorToast } from '@/hooks/useErrorToast'
import { hasOTPRequiredError } from '@/lib/error'
import { queryClient } from '@/lib/queryClient'
import { getFullName } from '@/lib/typography'
import { cn, removeEmptyFormFields } from '@/lib/utils'
import {
  AnimatedFormLabel,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
  Button,
  Card,
  Form,
  FormControl,
  FormField,
  FormItem,
  Input,
  MoneyInput,
  Skeleton,
  SlideInLeft,
  StickyContainer,
  Typography,
} from '@/shared/ui'
import { Role } from '@/types/roles'

import { CurrencyFlag } from '../CurrencyFlag'
import { LimitTypeSelect } from '../LimitTypeSelect'
import { OptionalTag } from '../OptionalTag'
import { OTPDialog } from '../OTPDialog'
import { Widget } from '../Widget'

import { DeleteTeamMemberAction } from './DeleteTeamMemberAction'
import {
  MoneyMovementSelectField,
  RoleSelectField,
} from './EditTeamMemberFields'
import { ScreenType } from './TeamDetailsSidebar'

const EDIT_TEAM_MEMBER_FORM = `edit-team-member-form`

const DEFAULT_VALUES: EditTeamMemberSchema = {
  role: '' as Role,
  limitTypePeriod: undefined,
  moneyMovementPermissions: undefined,
  periodTransferLimitAmount: '',
  singleTransferLimitAmount: '',
  firstName: '',
  lastName: '',
  secondLastName: '',
  userTitle: '',
  email: '',
}

const editTeamMemberSchema = z
  .object({
    role: z.nativeEnum(Role),
    limitTypePeriod: z.nativeEnum(LimitPeriodType).optional(),
    moneyMovementPermissions: z.nativeEnum(MovementPermission).optional(),
    periodTransferLimitAmount: z.string().optional(),
    singleTransferLimitAmount: z.string().optional(),

    firstName: z.string().min(1, {
      message: 'validation.firstName.required',
    }),
    lastName: z.string().min(1, {
      message: 'validation.lastName.required',
    }),
    secondLastName: z.string().optional(),
    userTitle: z.string().optional(),
    email: z.string().email({
      message: 'validation.email.required',
    }),
  })
  .refine(
    (data) => {
      const {
        periodTransferLimitAmount = '0',
        role,
        singleTransferLimitAmount = '0',
        moneyMovementPermissions,
      } = data

      if (role !== Role.PAYMENT_OPS) {
        return true
      }

      if (
        role === Role.PAYMENT_OPS &&
        moneyMovementPermissions !==
          MovementPermission.REQUIRE_APPROVAL_ABOVE_THE_LIMIT
      ) {
        return true
      }

      if (
        singleTransferLimitAmount === '0' &&
        periodTransferLimitAmount === '0'
      ) {
        return false
      }

      if (
        Big(
          singleTransferLimitAmount === '' ? 0 : singleTransferLimitAmount,
        ).gt(periodTransferLimitAmount === '' ? 0 : periodTransferLimitAmount)
      ) {
        return false
      }

      return true
    },
    {
      message: 'validation.singleTransactionLimit.greaterThanCycleLimit',
      path: ['singleTransferLimitAmount'],
    },
  )
  .refine(
    (data) => {
      const {
        role,
        singleTransferLimitAmount = '0',
        moneyMovementPermissions,
      } = data

      if (role !== Role.PAYMENT_OPS) {
        return true
      }

      if (
        role === Role.PAYMENT_OPS &&
        moneyMovementPermissions !==
          MovementPermission.REQUIRE_APPROVAL_ABOVE_THE_LIMIT
      ) {
        return true
      }

      return Big(
        singleTransferLimitAmount === '' ? 0 : singleTransferLimitAmount,
      ).gt(0)
    },
    {
      message: 'validation.balance.minAmount',
      path: ['singleTransferLimitAmount'],
    },
  )
  .refine(
    (data) => {
      const {
        role,
        periodTransferLimitAmount = '0',
        moneyMovementPermissions,
      } = data

      if (role !== Role.PAYMENT_OPS) {
        return true
      }

      if (
        role === Role.PAYMENT_OPS &&
        moneyMovementPermissions !==
          MovementPermission.REQUIRE_APPROVAL_ABOVE_THE_LIMIT
      ) {
        return true
      }

      return Big(
        periodTransferLimitAmount === '' ? 0 : periodTransferLimitAmount,
      ).gt(0)
    },
    {
      message: 'validation.balance.minAmount',
      path: ['periodTransferLimitAmount'],
    },
  )
  .refine((data) => {
    const { role, moneyMovementPermissions } = data

    if (role !== Role.PAYMENT_OPS) {
      return true
    }

    return moneyMovementPermissions !== undefined
  })
  .refine(
    (data) => {
      const { role, moneyMovementPermissions, limitTypePeriod } = data

      if (role !== Role.PAYMENT_OPS) {
        return true
      }

      if (
        moneyMovementPermissions ===
        MovementPermission.REQUIRE_APPROVAL_ABOVE_THE_LIMIT
      ) {
        return !!limitTypePeriod
      }

      return true
    },
    {
      message: 'validation.balance.minAmount',
      path: ['limitPeriodType'],
    },
  )

type EditTeamMemberSchema = z.infer<typeof editTeamMemberSchema>

type Props = {
  member?: TeamMember
  setScreen: (screen: ScreenType) => void
}

export const EditTeamDetails = ({ member, setScreen }: Props) => {
  const [isOTPDialogOpen, setOTPDialogOpen] = useState(false)

  const paymentLimitQuery = useQuery({
    queryKey: [queryKeys.getTeamMemberPaymentLimit, member?.id],
    queryFn: () => getTeamMemberPaymentLimit({ id: member?.id ?? '' }),
    select: (data) => data.data,
  })

  const intl = useIntl()
  const notifyError = useErrorToast()

  const {
    mutateAsync: editTeamMember,
    isPending: editPending,
    isError: editError,
    isSuccess: editSuccess,
  } = useMutation({
    mutationFn: editTeamMemberDetails,
  })

  const {
    mutateAsync: updatePermissions,
    isPending: updatePermissionsPending,
    isSuccess: updatePermissionsSuccess,
  } = useMutation({
    mutationFn: createTransferPermissions,
  })

  const form = useForm<EditTeamMemberSchema>({
    mode: 'onChange',
    resolver: zodResolver(editTeamMemberSchema),
    defaultValues: DEFAULT_VALUES,
    values: {
      role: member?.role ?? ('' as Role),
      email: member?.email ?? '',
      firstName: member?.firstName ?? '',
      lastName: member?.lastName ?? '',
      secondLastName: member?.secondLastName ?? '',
      userTitle: member?.userTitle ?? '',
      moneyMovementPermissions:
        paymentLimitQuery.data?.type === MovementPermission.NO_LIMIT
          ? MovementPermission.NO_APPROVAL_REQUIRED
          : paymentLimitQuery.data?.type,
      limitTypePeriod: paymentLimitQuery.data?.periodTransferLimit?.limitType,
      periodTransferLimitAmount: paymentLimitQuery.data?.periodTransferLimit
        ?.limitAmount
        ? Big(
            paymentLimitQuery.data?.periodTransferLimit?.limitAmount,
          ).toString()
        : '',
      singleTransferLimitAmount: paymentLimitQuery.data?.singleTransferLimit
        ?.limitAmount
        ? Big(
            paymentLimitQuery.data?.singleTransferLimit?.limitAmount,
          ).toString()
        : '',
    },
  })

  const isPending =
    editPending ||
    updatePermissionsPending ||
    updatePermissionsSuccess ||
    editSuccess

  const onEditTeamMember = useCallback(
    async (data: EditTeamMemberSchema, otp?: string) => {
      if (!member?.id) {
        toast.error(
          intl.formatMessage({
            id: 'team.member.details.error.missingId',
            defaultMessage: 'Missing team member ID',
          }),
        )
        return
      }

      removeEmptyFormFields(data)

      const {
        role,
        firstName,
        lastName,
        secondLastName,
        userTitle,
        ...moneyMovement
      } = data

      try {
        await editTeamMember({
          id: member?.id,
          role,
          firstName,
          lastName,
          secondLastName,
          userTitle,
          otp,
        })

        if (moneyMovement.moneyMovementPermissions) {
          await updatePermissions({
            id: member?.id,
            type: moneyMovement.moneyMovementPermissions,
            limitPeriodType: moneyMovement.limitTypePeriod,
            periodTransferLimitAmount: moneyMovement.periodTransferLimitAmount,
            singleTransferLimitAmount: moneyMovement.singleTransferLimitAmount,
          })
        }

        await queryClient.invalidateQueries({
          queryKey: [queryKeys.getTeamMembers],
        })

        await queryClient.invalidateQueries({
          queryKey: [queryKeys.getTeamMemberPaymentLimit, member?.id],
        })

        await queryClient.invalidateQueries({
          queryKey: [queryKeys.getTeamMember, member?.id],
        })

        setOTPDialogOpen(false)

        toast.success(
          intl.formatMessage({
            id: 'team.member.details.updated',
            defaultMessage: 'Team member details updated',
          }),
        )

        setScreen('base')
      } catch (error) {
        if (error instanceof Error) {
          if (hasOTPRequiredError(error)) {
            setOTPDialogOpen(true)
            return
          }

          notifyError(error)
        }
      }
    },
    [
      member?.id,
      intl,
      editTeamMember,
      setScreen,
      updatePermissions,
      notifyError,
    ],
  )

  const onSubmit: SubmitHandler<EditTeamMemberSchema> = async (data) => {
    onEditTeamMember(data)
  }

  const hasDisabledFields = form.watch('role') !== Role.PAYMENT_OPS

  const disabledFieldsForPaymentOps = !!(
    form.watch('role') === Role.PAYMENT_OPS &&
    form.watch('moneyMovementPermissions') !== undefined &&
    form.watch('moneyMovementPermissions') !==
      MovementPermission.REQUIRE_APPROVAL_ABOVE_THE_LIMIT
  )

  return (
    <SlideInLeft className="flex h-full flex-col">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbLink asChild>
            <Button
              onClick={() => setScreen('base')}
              variant="ghost"
              size="inline"
            >
              {member ? (
                <Typography>{getFullName(member)}</Typography>
              ) : (
                <Skeleton className="h-6 w-36" />
              )}
            </Button>
          </BreadcrumbLink>

          <BreadcrumbSeparator />

          <BreadcrumbItem>
            <Typography>
              <FormattedMessage
                id="team.sidebar.editMember"
                defaultMessage="Edit profile details"
              />
            </Typography>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <div className="p-4" />

      <Form {...form}>
        <form id={EDIT_TEAM_MEMBER_FORM} onSubmit={form.handleSubmit(onSubmit)}>
          <Widget
            title={
              <FormattedMessage
                id="team.sidebar.roleAndPermissions"
                defaultMessage="Role & permissions"
              />
            }
            variant="form"
          >
            <FormField
              control={form.control}
              name="role"
              render={({ field }) => (
                <FormItem>
                  <RoleSelectField
                    onChange={(value) => {
                      if (value === Role.ADMIN) {
                        form.setValue('limitTypePeriod', undefined)
                        form.setValue('periodTransferLimitAmount', '')
                        form.setValue('singleTransferLimitAmount', '')

                        form.clearErrors([
                          'periodTransferLimitAmount',
                          'singleTransferLimitAmount',
                        ])
                      }

                      field.onChange(value)
                    }}
                    value={field.value}
                  />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="moneyMovementPermissions"
              render={({ field }) => (
                <FormItem>
                  <MoneyMovementSelectField
                    disabled={hasDisabledFields}
                    onChange={(v) => {
                      if (
                        v !==
                        MovementPermission.REQUIRE_APPROVAL_ABOVE_THE_LIMIT
                      ) {
                        form.setValue('limitTypePeriod', undefined)
                        form.setValue('periodTransferLimitAmount', '')
                        form.setValue('singleTransferLimitAmount', '')

                        form.clearErrors([
                          'periodTransferLimitAmount',
                          'singleTransferLimitAmount',
                        ])
                      }

                      field.onChange(v)
                    }}
                    value={field.value}
                  />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="limitTypePeriod"
              render={({ field }) => (
                <FormItem>
                  <LimitTypeSelect
                    disabled={hasDisabledFields || disabledFieldsForPaymentOps}
                    value={field.value}
                    onChange={(v) => {
                      field.onChange(v)
                    }}
                  />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="periodTransferLimitAmount"
              render={({ field }) => (
                <FormItem>
                  <Card
                    size="input"
                    className={cn(
                      'flex',
                      (hasDisabledFields || disabledFieldsForPaymentOps) &&
                        'cursor-not-allowed opacity-50',
                    )}
                  >
                    <CurrencyFlag />
                    <FormControl>
                      <MoneyInput
                        disabled={
                          hasDisabledFields || disabledFieldsForPaymentOps
                        }
                        currency={Currency.USD}
                        placeholder={intl.formatMessage({
                          defaultMessage: 'Aggregate amount',
                          id: 'label.aggregateAmount',
                        })}
                        className="text-right"
                        value={field.value}
                        onChange={field.onChange}
                      />
                    </FormControl>

                    <AnimatedFormLabel align="end">
                      <FormattedMessage
                        defaultMessage="Aggregate amount"
                        id="label.aggregateAmount"
                      />
                    </AnimatedFormLabel>
                  </Card>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="singleTransferLimitAmount"
              render={({ field }) => (
                <FormItem>
                  <Card
                    size="input"
                    className={cn(
                      'flex',
                      (hasDisabledFields || disabledFieldsForPaymentOps) &&
                        'cursor-not-allowed opacity-50',
                    )}
                  >
                    <CurrencyFlag />
                    <FormControl>
                      <MoneyInput
                        disabled={
                          hasDisabledFields || disabledFieldsForPaymentOps
                        }
                        currency={Currency.USD}
                        placeholder={intl.formatMessage({
                          defaultMessage: 'Single transaction amount',
                          id: 'label.singleTransactionAmount',
                        })}
                        className="text-right"
                        value={field.value}
                        onChange={field.onChange}
                      />
                    </FormControl>

                    <AnimatedFormLabel align="end">
                      <FormattedMessage
                        defaultMessage="Single transaction amount"
                        id="label.singleTransactionAmount"
                      />
                    </AnimatedFormLabel>
                  </Card>
                </FormItem>
              )}
            />
          </Widget>

          <div className="p-4" />

          <Widget
            title={
              <FormattedMessage
                id="teamMember.create.reviewStep.memberDetails"
                defaultMessage="Team member details"
              />
            }
            variant="form"
          >
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      autoComplete="given-name"
                      placeholder={intl.formatMessage({
                        defaultMessage: 'First name',
                        id: 'label.firstName',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      defaultMessage="First name"
                      id="label.firstName"
                    />
                  </AnimatedFormLabel>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      autoComplete="family-name"
                      placeholder={intl.formatMessage({
                        defaultMessage: 'Last name',
                        id: 'label.lastName',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      defaultMessage="Last name"
                      id="label.lastName"
                    />
                  </AnimatedFormLabel>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="secondLastName"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      autoComplete="additional-name"
                      placeholder={intl.formatMessage({
                        defaultMessage: 'Second last name',
                        id: 'label.secondLastName',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      defaultMessage="Second last name"
                      id="label.secondLastName"
                    />
                  </AnimatedFormLabel>

                  {field.value === '' && <OptionalTag />}
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="userTitle"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      placeholder={intl.formatMessage({
                        defaultMessage: 'Title',
                        id: 'label.title',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage defaultMessage="Title" id="label.title" />
                  </AnimatedFormLabel>

                  {field.value === '' && <OptionalTag />}
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      disabled
                      autoComplete="email"
                      placeholder={intl.formatMessage({
                        defaultMessage: 'Work email',
                        id: 'label.workEmail',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      defaultMessage="Work email"
                      id="label.workEmail"
                    />
                  </AnimatedFormLabel>
                </FormItem>
              )}
            />
          </Widget>
        </form>
      </Form>

      <StickyContainer className="flex flex-col gap-3">
        <Button
          width="full"
          form={EDIT_TEAM_MEMBER_FORM}
          loading={isPending || form.formState.isSubmitting}
          onClick={form.handleSubmit(onSubmit)}
          disabled={
            !form.formState.isValid ||
            !form.formState.isDirty ||
            form.formState.isSubmitting ||
            isPending
          }
          type="submit"
        >
          <FormattedMessage
            defaultMessage="Save changes"
            id="action.saveChanges"
          />
        </Button>

        <DeleteTeamMemberAction
          member={member}
          onDelete={() => {
            setScreen('base')
          }}
        />
      </StickyContainer>

      <OTPDialog
        isOpen={isOTPDialogOpen}
        onOpenChange={setOTPDialogOpen}
        isPending={isPending || form.formState.isSubmitting}
        isError={editError}
        title={intl.formatMessage({
          id: 'teamMember.edit.otp.title',
          defaultMessage: 'Enter verification code to confirm editing',
        })}
        description={intl.formatMessage(
          {
            id: 'teamMember.edit.otp.description',
            defaultMessage:
              'We have sent you an email with a verification code. Enter it to edit {name}',
          },
          { name: member?.firstName },
        )}
        onContinue={(otp) => {
          onEditTeamMember(form.getValues(), otp)
        }}
      />
    </SlideInLeft>
  )
}
