import { api } from '@/lib/api'

import { TeamMember } from '../types'

type UnblockTeamMemberRequest = {
  id: string
  otp?: string
}

export function unblockTeamMember({ id, otp }: UnblockTeamMemberRequest) {
  return api.post<TeamMember>(`/v1/me/business-users/${id}/unblock`, { otp })
}
