import { forwardRef, Ref, type SVGProps } from 'react'

const SvgArrowUp = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill={props.color || `currentColor`}
      d="M6.457 9.733a.75.75 0 1 0 1.086 1.034zm6.086-4.216a.75.75 0 1 0-1.086-1.034zm0-1.034a.75.75 0 1 0-1.086 1.034zm3.914 6.284a.75.75 0 1 0 1.086-1.034zM12.75 5a.75.75 0 0 0-1.5 0zm-1.5 14a.75.75 0 0 0 1.5 0zm-3.707-8.233 5-5.25-1.086-1.034-5 5.25zm3.914-5.25 5 5.25 1.086-1.034-5-5.25zM11.25 5v14h1.5V5z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgArrowUp)
export default ForwardRef
