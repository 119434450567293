import { useEffect, useRef } from 'react'

type Timer = ReturnType<typeof setTimeout>

export function useDebounce<T extends (...args: never[]) => ReturnType<T>>(
  func: T,
  delay = 300,
) {
  const timer = useRef<Timer>()

  useEffect(() => {
    return () => {
      if (!timer.current) return
      clearTimeout(timer.current)
    }
  }, [])

  const debouncedFunction = ((...args: Parameters<T>) => {
    const newTimer = setTimeout(() => {
      func(...args)
    }, delay)
    clearTimeout(timer.current)
    timer.current = newTimer
  }) as T

  return debouncedFunction
}
