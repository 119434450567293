import { useQuery } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'

import { Currency } from '@/constants/currency'
import { queryKeys } from '@/constants/queryKeys'
import { formatAmount, formatMoney } from '@/lib/money'
import { Widget } from '@/shared/components'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
  Button,
  Details,
  SlideInLeft,
  Typography,
} from '@/shared/ui'

import { getStatementsCreditCycleInfo } from '../api'

import { ScreenType } from './StatementsSidebar'

type Props = {
  setScreen: (screen: ScreenType) => void
}

export const CreditCycleScreen = ({ setScreen }: Props) => {
  const { id: walletId } = useParams<{ id: string }>()
  const intl = useIntl()

  const creditCycleQuery = useQuery({
    queryKey: [queryKeys.getStatementsCreditCycleInfo, walletId],
    queryFn: () => getStatementsCreditCycleInfo({ id: walletId ?? '' }),
    select: (data) => data.data,
    enabled: !!walletId,
  })

  return (
    <SlideInLeft className="flex h-full flex-col">
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbLink asChild>
            <Button
              onClick={() => setScreen('statements')}
              variant="ghost"
              size="inline"
            >
              <FormattedMessage
                id="label.creditStatements"
                defaultMessage="Credit statements"
              />
            </Button>
          </BreadcrumbLink>

          <BreadcrumbSeparator />

          <BreadcrumbItem>
            <Typography>
              <FormattedMessage
                id="label.cycleInformation"
                defaultMessage="Cycle information"
              />
            </Typography>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <div className="p-4" />

      <div className="flex flex-col gap-8">
        <Widget
          title={
            <FormattedMessage
              id="label.currentCycleDetails"
              defaultMessage="Current cycle details"
            />
          }
        >
          <Details>
            <Details.Label>
              <FormattedMessage
                defaultMessage="Total balance"
                id="label.totalBalance"
              />
            </Details.Label>
            {creditCycleQuery.isPending ? (
              <Details.Skeleton />
            ) : (
              <Details.Value>
                {formatMoney(creditCycleQuery.data?.totalBalance ?? 0)}
              </Details.Value>
            )}
          </Details>
          <Details>
            <Details.Label>
              <FormattedMessage
                defaultMessage="Credit cycle"
                id="label.creditCycle"
              />
            </Details.Label>
            {creditCycleQuery.isPending ? (
              <Details.Skeleton />
            ) : (
              <Details.Value>
                {formatMoney(creditCycleQuery.data?.cycleCredit ?? 0)}
              </Details.Value>
            )}
          </Details>
          <Details>
            <Details.Label>
              <FormattedMessage
                defaultMessage="Available balance"
                id="label.availableBalance"
              />
            </Details.Label>
            {creditCycleQuery.isPending ? (
              <Details.Skeleton />
            ) : (
              <Details.Value>
                {formatMoney(creditCycleQuery.data?.availableBalance ?? 0)}
              </Details.Value>
            )}
          </Details>
        </Widget>

        <Widget
          title={
            <FormattedMessage
              id="label.paymentCycleDetails"
              defaultMessage="Payment cycle details"
            />
          }
        >
          <Details>
            <Details.Label>
              <FormattedMessage
                defaultMessage="Start date"
                id="label.startDate"
              />
            </Details.Label>
            {creditCycleQuery.isPending ? (
              <Details.Skeleton />
            ) : (
              <Details.Value>
                {intl.formatDate(creditCycleQuery.data?.startDate ?? '', {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                  timeZone: 'UTC',
                })}
              </Details.Value>
            )}
          </Details>
          <Details>
            <Details.Label>
              <FormattedMessage defaultMessage="End date" id="label.endDate" />
            </Details.Label>
            {creditCycleQuery.isPending ? (
              <Details.Skeleton />
            ) : (
              <Details.Value>
                {intl.formatDate(creditCycleQuery.data?.endDate ?? '', {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                  timeZone: 'UTC',
                })}
              </Details.Value>
            )}
          </Details>
          <Details>
            <Details.Label>
              <FormattedMessage defaultMessage="Due date" id="label.dueDate" />
            </Details.Label>
            {creditCycleQuery.isPending ? (
              <Details.Skeleton />
            ) : (
              <Details.Value>
                {intl.formatDate(creditCycleQuery.data?.dueDate ?? '', {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                  timeZone: 'UTC',
                })}
              </Details.Value>
            )}
          </Details>
          <Details>
            <Details.Label>
              <FormattedMessage
                defaultMessage="Days in cycle"
                id="label.daysInCycle"
              />
            </Details.Label>
            {creditCycleQuery.isPending ? (
              <Details.Skeleton />
            ) : (
              <Details.Value>
                <FormattedMessage
                  defaultMessage="{days, plural, =0 {} one {# day} other {# days}}"
                  id="label.totalDays"
                  values={{ days: creditCycleQuery.data?.daysInCycle }}
                />
              </Details.Value>
            )}
          </Details>
        </Widget>

        <Widget
          title={
            <FormattedMessage
              id="label.creditInformation"
              defaultMessage="Credit information"
            />
          }
        >
          <Details>
            <Details.Label>
              <FormattedMessage
                defaultMessage="Outstanding debt"
                id="label.outstandingDebt"
              />
            </Details.Label>
            {creditCycleQuery.isPending ? (
              <Details.Skeleton />
            ) : (
              <Details.Value>
                {formatAmount({
                  amount: creditCycleQuery.data?.outstandingDebt ?? 0,
                  currency: creditCycleQuery.data?.currency ?? Currency.USDC,
                })}
              </Details.Value>
            )}
          </Details>
          <Details>
            <Details.Label>
              <FormattedMessage defaultMessage="Debt APR" id="label.debtAPR" />
            </Details.Label>

            {creditCycleQuery.isPending ? (
              <Details.Skeleton />
            ) : (
              <Details.Value>
                {creditCycleQuery.data?.debtAPR ?? 0}%
              </Details.Value>
            )}
          </Details>
        </Widget>
      </div>

      <div className="p-4" />
    </SlideInLeft>
  )
}
