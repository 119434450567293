import { useQuery } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'

import { getBusinessIdentity } from '@/api'
import { queryKeys } from '@/constants/queryKeys'
import { AccountType, BankAccount } from '@/features/Accounts/types'
import { getBusinessAddress, getFullAddress } from '@/lib/address'
import { Card, Details, Typography } from '@/shared/ui'

type Props = {
  details: BankAccount
}

export const CountryFields = ({ details }: Props) => {
  const intl = useIntl()

  const businessQuery = useQuery({
    queryKey: [queryKeys.getBusinessIdentity],
    queryFn: getBusinessIdentity,
    select: (data) => data.data,
  })

  const businessAddress = getBusinessAddress(businessQuery.data, intl)

  switch (details.type) {
    case AccountType.WIRE: {
      return null
    }

    case AccountType.ACH: {
      return (
        <Card className="flex flex-col gap-4" size="medium">
          <Details>
            <Details.Label>
              <FormattedMessage
                id="label.beneficiaryName"
                defaultMessage="Beneficiary name"
              />
            </Details.Label>
            {details?.holderDetails ? (
              <Details.Value copyable>
                {details.holderDetails.entityName}
              </Details.Value>
            ) : (
              <Details.Skeleton />
            )}
          </Details>

          <Details>
            <Details.Label>
              <FormattedMessage
                id="label.routingNumber"
                defaultMessage="ABA / Routing number"
              />
            </Details.Label>
            {details?.accountDetails ? (
              <Details.Value copyable>
                {details.accountDetails.bankIdentifier.value}
              </Details.Value>
            ) : (
              <Details.Skeleton />
            )}
          </Details>
          <Details>
            <Details.Label>
              <FormattedMessage
                id="label.accountNumber"
                defaultMessage="Account number"
              />
            </Details.Label>
            {details?.accountDetails ? (
              <Details.Value copyable>
                {details.accountDetails.accountIdentifier.value}
              </Details.Value>
            ) : (
              <Details.Skeleton />
            )}
          </Details>
          <Details>
            <Details.Label>
              <FormattedMessage id="label.bank" defaultMessage="Bank" />
            </Details.Label>
            {details?.holderDetails ? (
              <Details.Value copyable>
                {details.holderDetails.bankName}
              </Details.Value>
            ) : (
              <Details.Skeleton />
            )}
          </Details>
          {businessAddress ? (
            <Details>
              <Details.Label>
                <FormattedMessage
                  id="label.beneficiaryAddress"
                  defaultMessage="Beneficiary address"
                />
              </Details.Label>
              <Details.Value
                className="whitespace-break-spaces text-right"
                copyable
              >
                {businessAddress}
              </Details.Value>
            </Details>
          ) : null}
          {getFullAddress(details.holderDetails.bankAddress, intl) ? (
            <Details>
              <Details.Label>
                <FormattedMessage
                  id="label.bankAddress"
                  defaultMessage="Bank address"
                />
              </Details.Label>

              <Details.Value
                className="whitespace-break-spaces text-right"
                copyable
              >
                {getFullAddress(details.holderDetails.bankAddress, intl)}
              </Details.Value>
            </Details>
          ) : null}

          <div className="flex flex-col">
            <Typography className="text-neutral-gray-600" text="center">
              <FormattedMessage
                id="account.details.bankDetails.wire.info"
                defaultMessage="Wire transfers arrive within the same business day"
              />
            </Typography>
            <Typography className="text-neutral-gray-600" text="center">
              <FormattedMessage
                id="account.details.bankDetails.ach.info"
                defaultMessage="ACH transfers arrive within 1 to 3 business days"
              />
            </Typography>
          </div>
        </Card>
      )
    }

    case AccountType.LOCAL_MX: {
      return (
        <Card className="flex flex-col gap-4" size="medium">
          <Details>
            <Details.Label>
              <FormattedMessage
                id="label.beneficiaryName"
                defaultMessage="Beneficiary name"
              />
            </Details.Label>
            {details?.holderDetails ? (
              <Details.Value copyable>
                {details.holderDetails.entityName}
              </Details.Value>
            ) : (
              <Details.Skeleton />
            )}
          </Details>
          <Details>
            <Details.Label>
              <FormattedMessage id="label.clabe" defaultMessage="CLABE" />
            </Details.Label>
            {details?.accountDetails ? (
              <Details.Value copyable>
                {details.accountDetails.accountIdentifier.value}
              </Details.Value>
            ) : (
              <Details.Skeleton />
            )}
          </Details>
          <Details>
            <Details.Label>
              <FormattedMessage id="label.bank" defaultMessage="Bank" />
            </Details.Label>
            {details?.holderDetails ? (
              <Details.Value copyable>
                {details.holderDetails.bankName}
              </Details.Value>
            ) : (
              <Details.Skeleton />
            )}
          </Details>

          <Typography className="text-neutral-gray-600" text="center">
            <FormattedMessage
              id="account.details.bankDetails.info"
              defaultMessage="{type, select, USA_LOCAL_WIRE {Arrives same day, or next day if sent after banking hours} USA_LOCAL_ACH {Arrives within 3-6 business days} MEX_LOCAL {Arrives within minutes} other {}}"
              values={{ type: AccountType.LOCAL_MX }}
            />
          </Typography>
        </Card>
      )
    }

    default:
      return null
  }
}
