import { RouteObject } from 'react-router-dom'

import { ADD_TEAM_MEMBER_ROUTE, TEAM_ROUTE } from '@/constants/paths'

import { AddTeamMember } from './AddTeamMember'
import { Team } from './Team'

export const teamRoutes: RouteObject[] = [
  {
    path: TEAM_ROUTE,
    element: <Team />,
  },
]

export const addTeamMemberRoutes: RouteObject[] = [
  {
    path: ADD_TEAM_MEMBER_ROUTE,
    element: <AddTeamMember />,
  },
]
