import { Fragment, useMemo } from 'react'
import { useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { FormattedMessage } from 'react-intl'
import { Link, useLocation, useSearchParams } from 'react-router-dom'

import { ADD_RECIPIENTS_ROUTE, DASHBOARD_ROUTE } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { getRecipients } from '@/features/Recipients/api'
import { Recipient } from '@/features/Recipients/types'
import { getTransactions } from '@/features/Transactions/api'
import { DisplayableType, Transaction } from '@/features/Transactions/types'
import { useSearchInput } from '@/hooks/useSearchInput'
import { parseAdditionalDetails } from '@/lib/utils'
import { EmptyCard, GoBackButton, Widget } from '@/shared/components'
import { Plus } from '@/shared/icons/outline'
import { Button, SearchInput, SlideInScreen, Typography } from '@/shared/ui'

import { RecipientCard } from './RecipientCard'

type Props = {
  onSelect: (recipient: string) => void
}

export const SelectRecipientScreen = ({ onSelect }: Props) => {
  const location = useLocation()

  const [searchParams] = useSearchParams()

  const [search, setSearch, handleSearchQuery] = useSearchInput()

  const params = Object.fromEntries(
    [...searchParams].filter(([key]) => key !== 'id'),
  )

  const [allRecipientsQuery, allTransactions] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getRecipients, params],
        queryFn: () => getRecipients(params),
        select: (data: AxiosResponse<Recipient[]>) => data.data,
        staleTime: 0,
      },

      {
        queryKey: [queryKeys.getTransactions],
        queryFn: () => getTransactions(),
        select: (data: AxiosResponse<Transaction[]>) => data?.data,
        staleTime: 0,
      },
    ],
  })

  const recentRecipients = useMemo(() => {
    return (
      allTransactions.data
        ?.map((tx) => {
          if (tx.displayableType === DisplayableType.SINGLE) {
            return parseAdditionalDetails<{ beneficiaryId?: string }>(
              tx.additionalDetails,
            )?.beneficiaryId
          }

          return null
        })
        .filter(Boolean)
        .filter((v, i, a) => a.indexOf(v) === i) ?? []
    )
      .map((id) => {
        const recipient = allRecipientsQuery.data?.find((r) => r.id === id)

        if (!recipient) return null

        return recipient
      })
      .filter(Boolean)
  }, [allTransactions.data, allRecipientsQuery.data])

  return (
    <>
      <GoBackButton to={location.state?.from ?? DASHBOARD_ROUTE} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            defaultMessage="Who do you want to pay?"
            id="send.selectRecipient.title"
          />
        </Typography>

        <div className="p-6" />

        <div className="flex flex-wrap gap-4 md:flex-nowrap">
          <SearchInput
            value={search}
            onChange={(value) => {
              setSearch(value)
              handleSearchQuery(value)
            }}
          />

          <Button size="sm" leftIcon={<Plus className="size-5" />} asChild>
            <Link to={ADD_RECIPIENTS_ROUTE} state={{ from: location }}>
              <FormattedMessage
                defaultMessage="New recipient"
                id="action.newRecipient"
              />
            </Link>
          </Button>
        </div>

        <div className="p-6" />

        <Widget
          className="py-2"
          gap="none"
          title={<FormattedMessage id="label.recent" defaultMessage="Recent" />}
        >
          {recentRecipients.length > 0 ? (
            recentRecipients.slice(0, 5).map((recipient, index) => {
              const lastItem =
                recentRecipients.length < 5
                  ? recentRecipients.indexOf(recipient) ===
                    recentRecipients.length - 1
                  : index === 4

              if (!recipient) return null

              return (
                <Fragment key={recipient.id}>
                  <RecipientCard recipient={recipient} onSelect={onSelect} />
                  {!lastItem && (
                    <div className="border-b bg-neutral-gray-200" />
                  )}
                </Fragment>
              )
            })
          ) : allRecipientsQuery.isPending || allTransactions.isPending ? (
            <RecipientCard.Skeleton />
          ) : (
            <EmptyCard>
              <EmptyCard.Title>
                <FormattedMessage
                  defaultMessage="No recent recipients"
                  id="send.selectRecipient.noRecent"
                />
              </EmptyCard.Title>
              <EmptyCard.Description>
                <FormattedMessage
                  id="recipients.search.notFound.description"
                  defaultMessage="There were no recipients that matched your search criteria"
                />
              </EmptyCard.Description>
            </EmptyCard>
          )}
        </Widget>

        <div className="p-6" />

        <Widget
          gap="none"
          className="py-2"
          title={
            <FormattedMessage
              id="label.allRecipients"
              defaultMessage="All recipients"
            />
          }
        >
          {allRecipientsQuery.data?.length ? (
            allRecipientsQuery.data.map((recipient) => {
              const lastItem =
                allRecipientsQuery.data.indexOf(recipient) ===
                allRecipientsQuery.data.length - 1

              return (
                <Fragment key={recipient.id}>
                  <RecipientCard recipient={recipient} onSelect={onSelect} />
                  {!lastItem && (
                    <div className="border-b bg-neutral-gray-200"></div>
                  )}
                </Fragment>
              )
            })
          ) : allRecipientsQuery.isPending || allTransactions.isPending ? (
            <RecipientCard.Skeleton />
          ) : (
            <EmptyCard>
              <EmptyCard.Title>
                <FormattedMessage
                  id="recipients.search.notFound"
                  defaultMessage="No recipients found"
                />
              </EmptyCard.Title>
              <EmptyCard.Description>
                <FormattedMessage
                  id="recipients.search.notFound.description"
                  defaultMessage="There were no recipients that matched your search criteria"
                />
              </EmptyCard.Description>
            </EmptyCard>
          )}
        </Widget>
      </SlideInScreen>
    </>
  )
}
