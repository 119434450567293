import { useCallback, useEffect, useMemo, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery } from '@tanstack/react-query'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { generatePath, useNavigate } from 'react-router-dom'
import { z } from 'zod'

import { ONBOARDING_CREATE_OWNER, ONBOARDING_STEP } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { useErrorToast } from '@/hooks/useErrorToast'
import { queryClient } from '@/lib/queryClient'
import { GoBackButton, Widget } from '@/shared/components'
import { Plus } from '@/shared/icons/outline'
import {
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'

import { getOnboardingStepDetails, submitOnboardingStep } from '../api'
import { BeneficialOwner } from '../components/BeneficialOwner'
import { OnboardingDocumentField } from '../components/OnboardingDocumentField'
import { OnboardingLoader } from '../components/OnboardingLoader'
import {
  CompanyOwner,
  CompanyOwnershipStep as CompanyOwnershipStepType,
  OnboardingStep,
  OnboardingStepConfig,
  OnboardingStepName,
} from '../types'

const companyOwnershipStepSchema = z.object({
  SHARE_REGISTRY: z.array(z.any()),
})

type CompanyOwnershipStepSchema = z.infer<typeof companyOwnershipStepSchema>

const DEFAULT_VALUES: CompanyOwnershipStepSchema = {
  SHARE_REGISTRY: [new File([], '')],
}

const COMPANY_OWNERSHIP_FORM_ID = 'company-ownership-form-id'

type Props = {
  config?: OnboardingStepConfig
  steps: OnboardingStep[]
}

export const CompanyOwnershipStep = ({ config, steps }: Props) => {
  const notifyError = useErrorToast()
  const intl = useIntl()
  const navigate = useNavigate()

  const [owners, setOwners] = useState<CompanyOwner[]>([])

  const { mutateAsync, isPending, isSuccess } = useMutation({
    mutationFn: submitOnboardingStep,
  })

  const form = useForm<CompanyOwnershipStepSchema>({
    mode: 'onChange',
    resolver: zodResolver(companyOwnershipStepSchema),
    defaultValues: DEFAULT_VALUES,
  })

  const stepDetailsQuery = useQuery({
    queryKey: [queryKeys.getOnboardingStepDetails, config?.name],
    queryFn: () => getOnboardingStepDetails(config?.name ?? ''),
    select: (data) => data.data,

    enabled: !!config?.name,
  })

  useEffect(() => {
    if (stepDetailsQuery.data?.stepDetails) {
      const details = stepDetailsQuery.data
        ?.stepDetails as CompanyOwnershipStepType['stepDetails']

      setOwners(details.beneficialOwners ?? [])
    }
  }, [stepDetailsQuery.data?.stepDetails])

  const onSubmit: SubmitHandler<CompanyOwnershipStepSchema> =
    useCallback(async () => {
      const documents = stepDetailsQuery.data?.stepDetails.documents ?? []
      const details = stepDetailsQuery.data
        ?.stepDetails as CompanyOwnershipStepType['stepDetails']

      const beneficialOwnersIds =
        details?.beneficialOwners?.map((owner) => owner.id) ?? []

      if (!config?.name) {
        return
      }

      try {
        await mutateAsync({
          stepName: OnboardingStepName.COMPANY_OWNERSHIP,
          stepDetails: {
            beneficialOwnersIds,
            documents,
          },
        })

        const currentStepIndex = steps.findIndex(
          (step) => step.name === config.name,
        )

        const nextStep = steps[currentStepIndex + 1]

        await queryClient.refetchQueries({
          queryKey: [queryKeys.getOnboardingState],
        })

        queryClient.invalidateQueries({
          queryKey: [queryKeys.getOnboardingStepDetails, config.name],
        })

        navigate(generatePath(ONBOARDING_STEP, { step: nextStep.name }))
      } catch (error) {
        if (error instanceof Error) {
          notifyError(error)
        }
      }
    }, [
      config?.name,
      navigate,
      notifyError,
      stepDetailsQuery.data?.stepDetails,
      steps,
      mutateAsync,
    ])

  const getFilesByField = useCallback(
    (fieldName: string) => {
      return (stepDetailsQuery.data?.stepDetails.documents ?? []).filter(
        (doc) => doc.documentType === fieldName,
      )
    },
    [stepDetailsQuery.data?.stepDetails.documents],
  )

  const hasAllRequiredDocs = useMemo(() => {
    if (!config?.fields) {
      return false
    }

    const requiredDocuments = config.documents.filter((doc) => doc.required)
    return requiredDocuments.every((doc) => getFilesByField(doc.type).length)
  }, [config?.documents, config?.fields, getFilesByField])

  const getPreviousStep = () => {
    const currentStepIndex = steps.findIndex(
      (step) => step.name === config?.name,
    )

    return steps[currentStepIndex - 1]
  }

  if (stepDetailsQuery.isPending) {
    return <OnboardingLoader />
  }

  return (
    <>
      <GoBackButton
        className="hidden md:left-80 md:flex"
        to={generatePath(ONBOARDING_STEP, {
          step: getPreviousStep()?.name ?? '',
        })}
      />

      <SlideInScreen>
        <Typography variant="h3" text="center">
          <FormattedMessage
            defaultMessage="Ownership"
            id="onboarding.companyOwnership.title"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            defaultMessage="Who are the main beneficiaries of the company? Share the supporting capitalization table"
            id="onboarding.companyOwnership.subtitle"
          />
        </Typography>

        <div className="p-6" />

        <Form {...form}>
          <form
            id={COMPANY_OWNERSHIP_FORM_ID}
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <Widget
              title={
                <FormattedMessage
                  defaultMessage="Ownership details"
                  id="onboarding.companyOwnership.ownershipDetails"
                />
              }
            >
              <div className="flex flex-col">
                <Typography>
                  <FormattedMessage
                    id="onboarding.companyOwnership.beneficialOwners"
                    defaultMessage="Beneficial owners and controlling persons"
                  />
                </Typography>

                <div className="p-1" />

                <Typography className="text-neutral-gray-600">
                  <FormattedMessage
                    id="onboarding.companyOwnership.beneficialOwners.description"
                    defaultMessage="Add all shareholders that directly or indirectly own at least 25% of the company. If no one reaches that threshold, identify at least one of the company’s controlling persons (for example, the CEO, Chairman, COO, CFO, etc.)"
                  />
                </Typography>

                {owners.length > 0 ? (
                  <>
                    <div className="p-2" />
                    <div className="flex flex-col gap-2">
                      {owners.map((owner) => (
                        <BeneficialOwner
                          key={owner.id}
                          config={config}
                          owner={owner}
                        />
                      ))}
                    </div>
                  </>
                ) : null}

                <div className="p-2" />

                <Button
                  leftIcon={<Plus className="size-5" />}
                  size="md"
                  onClick={() => {
                    navigate(generatePath(ONBOARDING_CREATE_OWNER))
                  }}
                  type="button"
                  variant="secondary"
                >
                  <FormattedMessage
                    id="onboarding.companyOwnership.addNew"
                    defaultMessage="Add new"
                  />
                </Button>
              </div>
            </Widget>

            <div className="p-4" />

            <Widget
              title={
                <FormattedMessage
                  defaultMessage="Supporting documentation"
                  id="onboarding.companyFormation.supportingDocuments"
                />
              }
              variant="form"
            >
              <FormField
                control={form.control}
                name="SHARE_REGISTRY"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <OnboardingDocumentField
                        isLoadingFiles={stepDetailsQuery.isPending}
                        title={intl.formatMessage({
                          id: 'onboarding.companyOwnership.shareRegistry',
                          defaultMessage:
                            'Official share registry or capitalization table',
                        })}
                        description={intl.formatMessage({
                          id: 'onboarding.companyOwnership.shareRegistry.description',
                          defaultMessage:
                            'Submit an official document that shows the company’s owners and their stakes. In case there is another company which owns 25% or more, we require such company’s share registry as well',
                        })}
                        step={config?.name ?? ''}
                        files={getFilesByField(field.name)}
                        name={field.name}
                        onBlur={field.onBlur}
                        onChange={field.onChange}
                        onDrop={(files) => {
                          form.setValue(field.name, files)
                        }}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </Widget>
          </form>
        </Form>

        <StickyContainer>
          <Button
            width="full"
            disabled={
              owners.length === 0 ||
              !hasAllRequiredDocs ||
              isPending ||
              isSuccess
            }
            loading={isPending || isSuccess}
            form={COMPANY_OWNERSHIP_FORM_ID}
            onClick={form.handleSubmit(onSubmit)}
            type="submit"
          >
            <FormattedMessage
              defaultMessage="Save & Continue"
              id="action.saveAndContinue"
            />
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
