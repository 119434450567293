import { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { formatAmount } from '@/lib/money'
import { OTPDialog } from '@/shared/components'

import { Task, TaskType } from '../types'

type Props = {
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  isPending: boolean
  isError: boolean
  tasks: Task[]
  onContinue: (otp?: string) => void
}
export const ApproveOTPDialog = ({
  isPending,
  isError,
  isOpen,
  onOpenChange,
  onContinue,
  tasks,
}: Props) => {
  const intl = useIntl()

  const multiple = useMemo(() => tasks.length > 1, [tasks.length])

  const title = useMemo(() => {
    if (tasks.length === 0) {
      return ''
    }

    if (multiple) {
      return intl.formatMessage(
        {
          id: 'tasks.multiple.approve.dialog.title',
          defaultMessage:
            'Approve {count, plural, =0 {} one {# selected request} other {# selected requests}}',
        },
        { count: tasks.length },
      )
    }

    const [task] = tasks

    switch (task.type) {
      case TaskType.ADD_BENEFICIARY:
        return intl.formatMessage(
          {
            id: 'tasks.single.addBeneficiary.approve.dialog.title',
            defaultMessage: 'Approve request to add {recipient} as recipient',
          },
          {
            recipient: task.taskDetails.beneficiaryName,
          },
        )

      case TaskType.EXECUTE_WITHDRAWAL:
        return intl.formatMessage(
          {
            id: 'tasks.single.executeWithdrawal.approve.dialog.title',
            defaultMessage: 'Approve {amount} transfer to {recipient}',
          },
          {
            recipient: task.taskDetails.beneficiaryName,
            amount: formatAmount({
              amount: task.taskDetails.localAmount,
              currency: task.taskDetails.localCurrency,
            }),
          },
        )
      case TaskType.EXECUTE_BULK_PAYMENT:
        return intl.formatMessage(
          {
            id: 'tasks.single.executeBulkPayment.approve.dialog.title',
            defaultMessage: 'Approve {amount} bulk payment',
          },
          {
            amount: formatAmount({
              amount: task.taskDetails.totalAmount,
              currency: task.taskDetails.currency,
            }),
          },
        )

      default:
        return ''
    }
  }, [intl, multiple, tasks])

  const description = useMemo(() => {
    if (tasks.length === 0) {
      return ''
    }

    if (multiple) {
      return intl.formatMessage({
        id: 'tasks.multiple.approve.dialog.subtitle',
        defaultMessage:
          'We sent you an email with a verification code. Enter it to approve the requests',
      })
    }

    const [task] = tasks

    switch (task.type) {
      case TaskType.ADD_BENEFICIARY:
        return intl.formatMessage({
          id: 'tasks.single.addBeneficiary.approve.dialog.subtitle',
          defaultMessage:
            'We have sent you an email with a verification code. Enter it to approve the request to add a new recipient',
        })

      case TaskType.EXECUTE_WITHDRAWAL:
        return intl.formatMessage({
          id: 'tasks.single.executeWithdrawal.approve.dialog.subtitle',
          defaultMessage:
            'We have sent you an email with a verification code. Enter it to approve the transfer',
        })

      case TaskType.EXECUTE_BULK_PAYMENT:
        return intl.formatMessage({
          id: 'tasks.single.executeBulkPayment.approve.dialog.subtitle',
          defaultMessage:
            'We have sent you an email with a verification code. Enter it to approve the bulk payment',
        })

      default:
        return ''
    }
  }, [intl, multiple, tasks])

  return (
    <OTPDialog
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      isPending={isPending}
      isError={isError}
      title={title}
      description={description}
      action={
        multiple ? (
          <FormattedMessage
            id="action.approveAll"
            defaultMessage="Approve all"
          />
        ) : (
          <FormattedMessage id="action.approve" defaultMessage="Approve" />
        )
      }
      onContinue={onContinue}
    />
  )
}
