import { UseFormReturn } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'

import { getCountryISO3byISO2 } from '@/lib/country'
import { CountryCombobox, Widget } from '@/shared/components'
import {
  AnimatedFormLabel,
  FormControl,
  FormField,
  FormItem,
  Input,
} from '@/shared/ui'

import { BusinessAddressStepSchema } from '../steps/BusinessAddressStep'
import { OnboardingDocument } from '../types'

import { OnboardingDocumentField } from './OnboardingDocumentField'

type Props = {
  form: UseFormReturn<BusinessAddressStepSchema>
  getFilesByField: (fieldName: string) => OnboardingDocument[]
  isPending: boolean
  stepName: string
}

export const AddressFields = ({
  form,
  getFilesByField,
  isPending,
  stepName,
}: Props) => {
  const intl = useIntl()

  return (
    <>
      <Widget
        title={
          <FormattedMessage
            defaultMessage="Address details"
            id="onboarding.companyFormation.addressDetails"
          />
        }
        variant="form"
      >
        <div className="grid grid-cols-2 gap-3">
          <FormField
            control={form.control}
            name="ADDRESS_STREET"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    autoComplete="address-line1"
                    placeholder={intl.formatMessage({
                      id: 'label.street',
                      defaultMessage: 'Street',
                    })}
                    {...field}
                  />
                </FormControl>
                <AnimatedFormLabel>
                  <FormattedMessage defaultMessage="Street" id="label.street" />
                </AnimatedFormLabel>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="ADDRESS_STREET_NUMBER"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    autoComplete="address-line2"
                    placeholder={intl.formatMessage({
                      id: 'label.streetNumber',
                      defaultMessage: 'Street number',
                    })}
                    {...field}
                  />
                </FormControl>
                <AnimatedFormLabel>
                  <FormattedMessage
                    defaultMessage="Street number"
                    id="label.streetNumber"
                  />
                </AnimatedFormLabel>
              </FormItem>
            )}
          />
        </div>

        <FormField
          control={form.control}
          name="ADDRESS_DISTRICT"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  placeholder={intl.formatMessage({
                    id: 'label.neighborhoodOrDistrict',
                    defaultMessage: 'Neighborhood or district',
                  })}
                  {...field}
                />
              </FormControl>
              <AnimatedFormLabel>
                <FormattedMessage
                  defaultMessage="Neighborhood or district"
                  id="label.neighborhoodOrDistrict"
                />
              </AnimatedFormLabel>
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="ADDRESS_CITY"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  autoComplete="address-level2"
                  placeholder={intl.formatMessage({
                    id: 'label.city',
                    defaultMessage: 'City',
                  })}
                  {...field}
                />
              </FormControl>
              <AnimatedFormLabel>
                <FormattedMessage defaultMessage="City" id="label.city" />
              </AnimatedFormLabel>
            </FormItem>
          )}
        />

        <div className="grid grid-cols-2 gap-3">
          <FormField
            control={form.control}
            name="ADDRESS_STATE"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    autoComplete="address-level1"
                    placeholder={intl.formatMessage({
                      id: 'label.stateOrProvince',
                      defaultMessage: 'State or province',
                    })}
                    {...field}
                  />
                </FormControl>
                <AnimatedFormLabel>
                  <FormattedMessage
                    defaultMessage="State or province"
                    id="label.stateOrProvince"
                  />
                </AnimatedFormLabel>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="ADDRESS_POST_CODE"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    autoComplete="postal-code"
                    placeholder={intl.formatMessage({
                      id: 'label.postCode',
                      defaultMessage: 'Post code',
                    })}
                    {...field}
                  />
                </FormControl>
                <AnimatedFormLabel>
                  <FormattedMessage
                    defaultMessage="Post code"
                    id="label.postCode"
                  />
                </AnimatedFormLabel>
              </FormItem>
            )}
          />
        </div>

        <FormField
          control={form.control}
          name="ADDRESS_COUNTRY"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <CountryCombobox
                  placeholder={intl.formatMessage({
                    id: 'label.country',
                    defaultMessage: 'Country',
                  })}
                  onSelect={(value) => {
                    field.onChange(value.valueAsCode)
                  }}
                  value={getCountryISO3byISO2(field.value)}
                />
              </FormControl>
            </FormItem>
          )}
        />
      </Widget>

      <div className="p-4" />

      <Widget
        title={
          <FormattedMessage
            defaultMessage="Supporting documentation"
            id="onboarding.companyFormation.supportingDocuments"
          />
        }
        variant="form"
      >
        <FormField
          control={form.control}
          name="PROOF_OF_ADDRESS"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <OnboardingDocumentField
                  isLoadingFiles={isPending}
                  title={intl.formatMessage({
                    id: 'onboarding.businessAddress.proofOfAddress',
                    defaultMessage: 'Proof of address',
                  })}
                  description={intl.formatMessage({
                    id: 'onboarding.businessAddress.proofOfAddress.description',
                    defaultMessage: `Provide a utilities bill or bank statement that shows your business address, no older than 3 months`,
                  })}
                  step={stepName}
                  files={getFilesByField(field.name)}
                  name={field.name}
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  onDrop={(files) => {
                    form.setValue(field.name, files)
                  }}
                />
              </FormControl>
            </FormItem>
          )}
        />
      </Widget>
    </>
  )
}
