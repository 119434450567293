import { Currency } from '@/constants/currency'
import { api } from '@/lib/api'

export type TransferMoneyRequest = {
  requestId: string
  toWallet: string
  fromWallet: string
  amount: string
  currency: Currency
}

export function transferMoney(data: TransferMoneyRequest) {
  return api.post<void>(`/v1/me/internal-swaps`, data)
}
