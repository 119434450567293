import { RouteObject } from 'react-router-dom'

import {
  ADD_BALANCE_ROUTE,
  SEND_ROUTE,
  TRANSFER_ROUTE,
} from '@/constants/paths'

import { AddBalance } from './AddBalance'
import { Send } from './Send'
import { TransferMoney } from './TransferMoney'

export const moveMoneyRoutes: RouteObject[] = [
  {
    path: SEND_ROUTE,
    element: <Send />,
  },
  {
    path: ADD_BALANCE_ROUTE,
    element: <AddBalance />,
  },
  {
    path: TRANSFER_ROUTE,
    element: <TransferMoney />,
  },
]
