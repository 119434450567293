import { useCallback } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import { z } from 'zod'

import { SIGNUP_ROUTE } from '@/constants/paths'
import { useErrorToast } from '@/hooks/useErrorToast'
import { cn } from '@/lib/utils'
import {
  AnimatedFormLabel,
  Button,
  buttonVariants,
  Form,
  FormControl,
  FormField,
  FormItem,
  Input,
  SlideInScreen,
  Typography,
} from '@/shared/ui'

import { recoverPassword } from '../api'

const FORGET_PASSWORD_EMAIL_FORM_ID = 'forget-password-email-form'

const DEFAULT_VALUES: ForgetPasswordEmailSchema = {
  email: '',
}

const forgetPasswordEmailSchema = z.object({
  email: z
    .string()
    .min(1, {
      message: 'validation.email.required',
    })
    .email({
      message: 'validation.email.invalid',
    }),
})

export type ForgetPasswordEmailSchema = z.infer<
  typeof forgetPasswordEmailSchema
>

type Props = {
  onContinue: () => void
}

export const ForgetPasswordEmailForm = ({ onContinue }: Props) => {
  const intl = useIntl()
  const notifyError = useErrorToast()
  const { boot, showNewMessage } = useIntercom()
  const { mutateAsync, isPending } = useMutation({
    mutationFn: recoverPassword,
  })

  const form = useForm<ForgetPasswordEmailSchema>({
    mode: 'onChange',
    resolver: zodResolver(forgetPasswordEmailSchema),
    defaultValues: DEFAULT_VALUES,
  })

  const onSubmit: SubmitHandler<ForgetPasswordEmailSchema> = useCallback(
    async (data) => {
      try {
        await mutateAsync(data)

        onContinue()
      } catch (error) {
        if (error instanceof Error) {
          notifyError(error)
        }
      }
    },
    [mutateAsync, onContinue, notifyError],
  )

  return (
    <SlideInScreen>
      <Typography text="center" variant="h3">
        <FormattedMessage
          defaultMessage="Forgot your password?"
          id="auth.forgotPassword"
        />
      </Typography>

      <div className="p-2" />

      <Typography text="center">
        <FormattedMessage
          id="auth.forgotPassword.sendLink"
          defaultMessage="We'll send you a link via email to create a new password"
        />
      </Typography>

      <div className="p-6" />

      <Form {...form}>
        <form
          id={FORGET_PASSWORD_EMAIL_FORM_ID}
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    autoComplete="email"
                    placeholder={intl.formatMessage({
                      defaultMessage: 'Email',
                      id: 'label.email',
                    })}
                    {...field}
                  />
                </FormControl>
                <AnimatedFormLabel>
                  <FormattedMessage defaultMessage="Email" id="label.email" />
                </AnimatedFormLabel>
              </FormItem>
            )}
          />
        </form>
      </Form>

      <div className="p-8" />

      <div className="mt-auto flex flex-col gap-3">
        <Button
          width="full"
          form={FORGET_PASSWORD_EMAIL_FORM_ID}
          disabled={isPending || !form.formState.isValid}
          loading={isPending}
          type="submit"
        >
          <FormattedMessage defaultMessage="Send" id="action.send" />
        </Button>

        <div className="flex flex-col gap-2 lg:items-center">
          <Typography className="flex flex-wrap items-center justify-center gap-1">
            <FormattedMessage
              defaultMessage="Can't access your email?"
              id="auth.cantAccessEmail"
            />{' '}
            <Button
              type="button"
              variant="link"
              size="inline"
              onClick={() => {
                boot({
                  customAttributes: {
                    user_type: 'BUSINESS',
                  },
                })
                showNewMessage()
              }}
            >
              <FormattedMessage
                defaultMessage="Chat to support"
                id="action.chatSupport"
              />
            </Button>
          </Typography>
          <Typography className="flex flex-wrap justify-center gap-1">
            <FormattedMessage
              defaultMessage="New to DolarApp?"
              id="auth.newToDolarApp"
            />{' '}
            <Link
              className={cn(
                buttonVariants({ variant: 'link', size: 'inline' }),
              )}
              to={SIGNUP_ROUTE}
            >
              <FormattedMessage
                defaultMessage="Create your free account"
                id="auth.createAccount"
              />
            </Link>
          </Typography>
        </div>
      </div>

      <div className="p-6" />
    </SlideInScreen>
  )
}
