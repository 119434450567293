import { forwardRef, Ref, type SVGProps } from 'react'

const SvgHeadset = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      stroke={props.color || `currentColor`}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M14.581 19.404a1.077 1.077 0 0 1-.905 1.217L12 20.984a1.008 1.008 0 1 1-.423-1.952l1.675-.362a1.08 1.08 0 0 1 1.329.734M7 15.992a2 2 0 0 1-2-2v-3a2 2 0 0 1 4 0v3a2 2 0 0 1-2 2M17 15.992a2 2 0 0 1-2-2v-3a2 2 0 0 1 4 0v3a2 2 0 0 1-2 2"
      clipRule="evenodd"
    />
    <path
      fill={props.color || `currentColor`}
      d="M18.25 11a.75.75 0 0 0 1.5 0zm-14 0a.75.75 0 0 0 1.5 0zm15.5 3.002a.75.75 0 0 0-1.5-.004zm-6.239 5.893a.75.75 0 0 0 .328 1.464zM19.75 11V9h-1.5v2zm0-2A7.75 7.75 0 0 0 12 1.25v1.5A6.25 6.25 0 0 1 18.25 9zM12 1.25A7.75 7.75 0 0 0 4.25 9h1.5A6.25 6.25 0 0 1 12 2.75zM4.25 9v2h1.5V9zm14 4.998a6.06 6.06 0 0 1-4.739 5.897l.328 1.464a7.56 7.56 0 0 0 5.911-7.357z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgHeadset)
export default ForwardRef
