import { useMemo } from 'react'
import { useMutation } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'
import { toast } from 'sonner'

import { queryKeys } from '@/constants/queryKeys'
import { useErrorToast } from '@/hooks/useErrorToast'
import { formatAmount } from '@/lib/money'
import { queryClient } from '@/lib/queryClient'
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/shared/ui'

import { declineTask } from '../api'
import { Task, TaskType } from '../types'

type Props = {
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  tasks: Task[]
  onContinue: (id: string[]) => void
}

export const DeclineTasksDialog = ({
  onContinue,
  isOpen,
  tasks,
  onOpenChange,
}: Props) => {
  const notifyError = useErrorToast()
  const intl = useIntl()

  const { mutateAsync, isPending, isSuccess } = useMutation({
    mutationFn: declineTask,
  })

  const multiple = useMemo(() => tasks.length > 1, [tasks.length])

  const title = useMemo(() => {
    if (tasks.length === 0) {
      return ''
    }

    if (multiple) {
      return intl.formatMessage(
        {
          id: 'tasks.multiple.decline.dialog.title',
          defaultMessage:
            'Decline {count, plural, =0 {} one {# selected request} other {# selected requests}}',
        },
        { count: tasks.length },
      )
    }

    const [task] = tasks

    switch (task.type) {
      case TaskType.ADD_BENEFICIARY:
        return intl.formatMessage(
          {
            id: 'tasks.single.addBeneficiary.decline.dialog.title',
            defaultMessage: 'Decline request to add {recipient} as recipient',
          },
          {
            recipient: task.taskDetails.beneficiaryName,
          },
        )

      case TaskType.EXECUTE_WITHDRAWAL:
        return intl.formatMessage(
          {
            id: 'tasks.single.executeWithdrawal.decline.dialog.title',
            defaultMessage: 'Decline {amount} transfer to {recipient}?',
          },
          {
            recipient: task.taskDetails.beneficiaryName,
            amount: formatAmount({
              amount: task.taskDetails.localAmount,
              currency: task.taskDetails.localCurrency,
            }),
          },
        )

      case TaskType.EXECUTE_BULK_PAYMENT:
        return intl.formatMessage(
          {
            id: 'tasks.single.executeBulkPayment.decline.dialog.title',
            defaultMessage: 'Decline {amount} bulk payment?',
          },
          {
            amount: formatAmount({
              amount: task.taskDetails.totalAmount,
              currency: task.taskDetails.currency,
            }),
          },
        )

      default:
        return ''
    }
  }, [intl, multiple, tasks])

  const description = useMemo(() => {
    if (tasks.length === 0) {
      return ''
    }

    if (multiple) {
      return intl.formatMessage({
        id: 'tasks.multiple.decline.dialog.subtitle',
        defaultMessage:
          'Are you sure you want to decline these requests? They will disappear and your team will need to create new ones',
      })
    }

    const [task] = tasks

    switch (task.type) {
      case TaskType.ADD_BENEFICIARY:
        return intl.formatMessage(
          {
            id: 'tasks.single.addBeneficiary.decline.dialog.subtitle',
            defaultMessage:
              'Are you sure you want to decline the request to add a new recipient from {name}?',
          },
          { name: task.createdByFirstName },
        )

      case TaskType.EXECUTE_WITHDRAWAL:
        return intl.formatMessage(
          {
            id: 'tasks.single.executeWithdrawal.decline.dialog.subtitle',
            defaultMessage:
              'Are you sure you want to decline the transfer request from {name}?',
          },
          { name: task.createdByFirstName },
        )

      case TaskType.EXECUTE_BULK_PAYMENT:
        return intl.formatMessage(
          {
            id: 'tasks.single.executeBulkPayment.decline.dialog.subtitle',
            defaultMessage:
              'Are you sure you want to decline the bulk payment request from {name}?',
          },
          { name: task.createdByFirstName },
        )

      default:
        return ''
    }
  }, [intl, multiple, tasks])

  const onDeclineTask = async () => {
    if (multiple) {
      const responses = await Promise.allSettled(
        tasks.map(async (task) => {
          const { data } = await mutateAsync({
            id: task.id,
          })

          return data
        }),
      )

      responses.forEach((response) => {
        if (response.status === 'rejected') {
          notifyError(response.reason)
        }
      })

      const successfulTasks = responses.filter(
        (response) => response.status === 'fulfilled',
      )

      toast.success(
        intl.formatMessage(
          {
            id: 'tasks.multiple.decline.dialog.success',
            defaultMessage:
              '{count, plural, =0 {} one {# task declined successfully} other {# tasks declined successfully}}',
          },
          { count: successfulTasks.length },
        ),
      )

      queryClient.refetchQueries({
        queryKey: [queryKeys.getAdminTasks],
      })

      onContinue(successfulTasks.map((response) => response.value.id))

      return
    }

    const [task] = tasks

    try {
      const response = await mutateAsync({
        id: task.id,
      })

      toast.success(
        intl.formatMessage({
          id: 'tasks.single.decline.dialog.success',
          defaultMessage: 'Task declined successfully',
        }),
      )

      queryClient.refetchQueries({
        queryKey: [queryKeys.getAdminTasks],
      })

      onContinue([response.data.id])
    } catch (error) {
      if (error instanceof Error) {
        notifyError(error)
      }
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{description}</DialogDescription>
        </DialogHeader>

        <div className="p-4" />

        <Button
          loading={isPending || isSuccess}
          disabled={isPending || isSuccess}
          width="full"
          onClick={onDeclineTask}
        >
          {multiple ? (
            <FormattedMessage
              id="action.declineAll"
              defaultMessage="Decline all"
            />
          ) : (
            <FormattedMessage id="action.decline" defaultMessage="Decline" />
          )}
        </Button>
      </DialogContent>
    </Dialog>
  )
}
