import { cn } from '@/lib/utils'

import { Typography } from '../ui'

export type StatusVariant =
  | 'active'
  | 'failed'
  | 'warning'
  | 'neutral'
  | 'info'
  | 'neutral-dark'

type Props = {
  variant: StatusVariant
  title: string | React.ReactNode
}

const bgColorByVariant: Record<Props['variant'], string> = {
  active: 'bg-primary-light',
  failed: 'bg-primary-error/15',
  info: 'bg-primary-info/10',
  neutral: 'bg-neutral-gray-100',
  'neutral-dark': 'bg-neutral-gray-200',
  warning: 'bg-primary-warning',
}

const textColorByVariant: Record<Props['variant'], string> = {
  active: 'text-primary-dark',
  failed: 'text-primary-error',
  info: 'text-primary-info',
  neutral: 'text-neutral-gray-800',
  'neutral-dark': 'text-neutral-gray-800',
  warning: 'text-primary-warning',
}

const dotColorByVariant: Record<Props['variant'], string> = {
  active: 'bg-primary-dark',
  failed: 'bg-primary-error',
  info: 'bg-primary-info',
  neutral: 'bg-neutral-gray-800',
  'neutral-dark': 'bg-neutral-gray-800',
  warning: 'bg-primary-warning',
}

export const StatusBadge = ({ variant, title }: Props) => {
  return (
    <div
      className={cn(
        'flex h-[22px] items-center gap-1.5 rounded-xl px-2',
        `${bgColorByVariant[variant]}`,
      )}
    >
      <span
        className={cn('size-1.5 rounded-full', dotColorByVariant[variant])}
      />

      <Typography
        className={cn(textColorByVariant[variant])}
        bold
        variant="body-small"
      >
        {title}
      </Typography>
    </div>
  )
}
