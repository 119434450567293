import { useMemo } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { useIntl } from 'react-intl'

import { isSmallScreen, isTabletScreen } from '@/constants/breakpoints'
import { useMediaQuery } from '@/hooks/useMediaQuery'
import { DataTableColumnHeader } from '@/shared/ui'

import { OnboardingMember } from '../../types'

import { IdentityActionCell } from './IdentityActionCell'
import { KeyIndividualCell } from './KeyIndividualCell'
import { RoleCell } from './RoleCell'
import { TaskCell } from './TaskCell'
import { VerificationStateCell } from './VerificationStateCell'

export const useIdentityColumns = (): ColumnDef<OnboardingMember>[] => {
  const isMobile = useMediaQuery(isSmallScreen)
  const isTablet = useMediaQuery(isTabletScreen)
  const intl = useIntl()

  const mobileColumns: ColumnDef<OnboardingMember>[] = useMemo(
    () => [
      {
        accessorKey: 'firstName',
        cell: ({ row }) => <KeyIndividualCell member={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.keyIndividual',
              defaultMessage: 'Key individual',
            })}
          />
        ),
      },

      {
        id: 'action',
        cell: ({ row }) => <IdentityActionCell member={row.original} />,
      },
    ],
    [intl],
  )

  const tabletColumns: ColumnDef<OnboardingMember>[] = useMemo(
    () => [
      {
        accessorKey: 'firstName',
        cell: ({ row }) => <KeyIndividualCell member={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.keyIndividual',
              defaultMessage: 'Key individual',
            })}
          />
        ),
      },
      {
        accessorKey: 'role',
        cell: ({ row }) => <RoleCell member={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.role',
              defaultMessage: 'Role',
            })}
          />
        ),
      },
      {
        id: 'status',
        cell: ({ row }) => <VerificationStateCell member={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.status',
              defaultMessage: 'Status',
            })}
          />
        ),
      },

      {
        id: 'action',
        cell: ({ row }) => <IdentityActionCell member={row.original} />,
      },
    ],
    [intl],
  )

  const desktopColumns: ColumnDef<OnboardingMember>[] = useMemo(
    () => [
      {
        accessorKey: 'firstName',
        cell: ({ row }) => <KeyIndividualCell member={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.keyIndividual',
              defaultMessage: 'Key individual',
            })}
          />
        ),
        size: 220,
      },
      {
        id: 'role',
        accessorFn: (row) => row.roles.join(', '),
        cell: ({ row }) => <RoleCell member={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.role',
              defaultMessage: 'Role',
            })}
          />
        ),
      },
      {
        id: 'task',
        cell: ({ row }) => <TaskCell member={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.task',
              defaultMessage: 'Task',
            })}
          />
        ),
        size: 290,
      },
      {
        accessorKey: 'status',
        cell: ({ row }) => <VerificationStateCell member={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.status',
              defaultMessage: 'Status',
            })}
          />
        ),
      },
      {
        id: 'action',
        cell: ({ row }) => <IdentityActionCell member={row.original} />,
      },
    ],
    [intl],
  )

  return isMobile ? mobileColumns : isTablet ? tabletColumns : desktopColumns
}
