import { forwardRef, Ref, type SVGProps } from 'react'

const SvgCircle = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 20 20"
    role="img"
    ref={ref}
    {...props}
  >
    <circle
      cx={10}
      cy={10}
      r={9.167}
      fill="#fff"
      stroke="#D4D4D4"
      strokeWidth={1.667}
    />
  </svg>
)
const ForwardRef = forwardRef(SvgCircle)
export default ForwardRef
