import { FormattedMessage } from 'react-intl'

import { cn } from '@/lib/utils'
import { Typography } from '@/shared/ui'

import { OnboardingMember } from '../../types'

type Props = {
  member: OnboardingMember
}
export const RoleCell = ({ member }: Props) => {
  return (
    <div className="flex flex-col items-start justify-center gap-1">
      {member.roles.map((role) => (
        <div
          key={role}
          className={cn(
            'flex h-[22px] items-center gap-1.5 rounded-xl bg-neutral-gray-100 px-2',
          )}
        >
          <Typography bold variant="body-small">
            <FormattedMessage
              id="individual.role.label"
              defaultMessage="{role, select, LEGAL_REPRESENTATIVE {Legal rep.} BENEFICIAL_OWNER {Owner} other {}}"
              values={{ role }}
            />
          </Typography>
        </div>
      ))}
    </div>
  )
}
