import { forwardRef, Ref, type SVGProps } from 'react'

const SvgLink = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill={props.color || `currentColor`}
      d="M14.045 9.955a.75.75 0 0 0-1.06 1.06zm-.53 5.074-.53-.53zm-3.03 3.03.53.53zm-4.544-4.544.53.53zm2.045-.985a.75.75 0 1 0-1.06-1.06zm1.969 1.515a.75.75 0 1 0 1.06-1.06zm.53-5.074.53.53zm3.03-3.03.53.53zm4.544 0-.53.53zm0 4.544.53.53zm-2.045.985a.75.75 0 1 0 1.06 1.06zm-3.03-.455c.463.462.722 1.089.722 1.742h1.5c0-1.051-.417-2.059-1.16-2.802zm.722 1.742c0 .653-.26 1.28-.721 1.742l1.06 1.06a3.96 3.96 0 0 0 1.161-2.802zm-.721 1.742-3.03 3.03 1.06 1.06 3.03-3.03zm-3.03 3.03a2.463 2.463 0 0 1-3.484 0l-1.06 1.06a3.963 3.963 0 0 0 5.604 0zm-3.484 0a2.463 2.463 0 0 1 0-3.484l-1.06-1.06a3.963 3.963 0 0 0 0 5.604zm0-3.484 1.515-1.515-1.06-1.06-1.515 1.515zm4.544-1.06a2.46 2.46 0 0 1-.721-1.742h-1.5c0 1.051.418 2.06 1.16 2.802zm-.721-1.742c0-.653.26-1.28.721-1.742l-1.06-1.06a3.96 3.96 0 0 0-1.161 2.802zm.721-1.742 3.03-3.03-1.06-1.06-3.03 3.03zm3.03-3.03a2.463 2.463 0 0 1 3.484 0l1.06-1.06a3.963 3.963 0 0 0-5.604 0zm3.484 0a2.463 2.463 0 0 1 0 3.484l1.06 1.06a3.963 3.963 0 0 0 0-5.604zm0 3.484-1.515 1.515 1.06 1.06 1.515-1.515z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgLink)
export default ForwardRef
