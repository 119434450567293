import { forwardRef, Ref, type SVGProps } from 'react'

const SvgUsers = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <g fill="#E9E9E9" fillRule="evenodd" clipRule="evenodd">
      <path
        stroke="#E9E9E9"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M14.375 7.375a2.375 2.375 0 1 1-4.75 0 2.375 2.375 0 0 1 4.75 0M16.75 15.775c0 1.8-2.127 3.267-4.75 3.267s-4.75-1.463-4.75-3.267 2.127-3.266 4.75-3.266 4.75 1.462 4.75 3.266M19.4 9.553a1.425 1.425 0 1 1-1.425-1.4c.78-.007 1.418.62 1.425 1.4M4.6 9.553a1.425 1.425 0 1 0 1.425-1.4 1.413 1.413 0 0 0-1.425 1.4"
      />
      <path d="M18.094 18.488c2.456.15 4.637-1.068 4.906-2.739-.27-1.67-2.45-2.886-4.906-2.737M5.906 18.489C3.45 18.638 1.27 17.42 1 15.749c.27-1.67 2.45-2.886 4.906-2.737" />
    </g>
  </svg>
)
const ForwardRef = forwardRef(SvgUsers)
export default ForwardRef
