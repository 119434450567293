import { Document } from '@/shared/icons/solid'
import { Typography } from '@/shared/ui'

import { OnboardingDocument } from '../types'

const HASH_LENGTH = 33

export function useDocumentsList(
  documents: OnboardingDocument[] ,
  keys: Record<string, string>,
) {
  const groupDocumentsBySameType = documents.reduce(
    (acc, document) => {
      if (!acc[document.documentType]) {
        acc[document.documentType] = []
      }

      acc[document.documentType].push(document)

      return acc
    },
    {} as Record<string, OnboardingDocument[]>,
  )

  const documentsDetails = Object.entries(groupDocumentsBySameType).map(
    ([key, documents]) => {
      const documentKey = keys[key]

      return {
        key: documentKey,
        value: (
          <div className="flex flex-col gap-2">
            {documents.map((document, index) => (
              <div key={index} className="flex items-center justify-end gap-2">
                <Document className="h-4 w-4" />
                <Typography>
                  {document.fileName.substring(HASH_LENGTH)}
                </Typography>
              </div>
            ))}
          </div>
        ),
      }
    },
  )

  return documentsDetails
}
