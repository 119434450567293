import { motion } from 'framer-motion'

import { cn } from '@/lib/utils'

import { slideFromLeftAnimation } from '.'

type Props = {
  children: React.ReactNode
  className?: string
}

export const SlideInScreen = ({ children, className }: Props) => {
  return (
    <motion.div
      variants={slideFromLeftAnimation}
      className={cn(
        'mx-auto flex h-[calc(100vh-7rem)] w-full max-w-[600px] flex-col md:min-w-[36rem]',
        className,
      )}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      {children}
    </motion.div>
  )
}
