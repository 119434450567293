import { useEffect, useState } from 'react'
import { useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { AnimatePresence, motion } from 'framer-motion'
import { FormattedMessage, useIntl } from 'react-intl'

import { getBusinessIdentity, getBusinessUser, getIdentity } from '@/api'
import { getBusinessPaymentLimits } from '@/api/getBusinessPaymentLimits'
import { isSmallScreen } from '@/constants/breakpoints'
import { countryCodeByLocale, flagByLocale } from '@/constants/countries'
import { AVAILABLE_LANGUAGES, Locale } from '@/constants/locales'
import { navMessages } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { useBusinessRole } from '@/hooks/useBusinessRole'
import { useLogout } from '@/hooks/useLogout'
import { useMediaQuery } from '@/hooks/useMediaQuery'
import { getFlagUrl } from '@/lib/images'
import { getFirstLetter, getFullName } from '@/lib/typography'
import { useLocale } from '@/providers/LocaleProvider'
import { BusinessIdentity, BusinessUser, Identity } from '@/types/business'
import { PaymentLimit } from '@/types/limits'

import { Check, ChevronDown, Logout, SettingsAdjust } from '../icons/outline'
import { User } from '../icons/solid'
import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  Typography,
} from '../ui'

import { AccountLimitsSidebar } from './AccountLimitsSidebar'
import { TeamDetailsSidebar } from './TeamDetailsSidebar'

type Props = {
  closeSidebarCallback?: () => void
  expanded: boolean
  onInternalSidebarOpen?: (open: boolean) => void
  onOpenChange: (open: boolean) => void
  open: boolean
}

export const ProfileDropdown = ({
  closeSidebarCallback,
  expanded,
  onInternalSidebarOpen,
  onOpenChange,
  open,
}: Props) => {
  const intl = useIntl()
  const { locale, changeLocale } = useLocale()
  const { logout } = useLogout()
  const isMobile = useMediaQuery(isSmallScreen)
  const [showSidebar, setShowSidebar] = useState<'profile' | 'settings'>()

  const { isAdmin } = useBusinessRole()

  const [openTooltip, setOpenTooltip] = useState(false)

  const getUserLocale = (lang: string) => {
    return AVAILABLE_LANGUAGES.find((l) => l.code === lang)?.lang
  }

  const [
    userQuery,
    businessQuery,
    businessUserQuery,
    businessPaymentLimitsQuery,
  ] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getIdentity],
        queryFn: getIdentity,
        select: (data: AxiosResponse<Identity>) => data.data,
      },
      {
        queryKey: [queryKeys.getBusinessIdentity],
        queryFn: getBusinessIdentity,
        select: (data: AxiosResponse<BusinessIdentity>) => data.data,
      },
      {
        queryKey: [queryKeys.getBusinessUser],
        queryFn: getBusinessUser,
        select: (data: AxiosResponse<BusinessUser>) => data.data,
        enabled: !isAdmin,
      },
      {
        queryKey: [queryKeys.getBusinessPaymentLimits],
        queryFn: getBusinessPaymentLimits,
        select: (data: AxiosResponse<PaymentLimit>) => data.data,
        enabled: isAdmin && !!closeSidebarCallback,
      },
    ],
  })

  useEffect(() => {
    setOpenTooltip(false)
  }, [open])

  return (
    <>
      <Popover open={open} onOpenChange={onOpenChange}>
        <PopoverTrigger asChild>
          <Button
            variant="ghost"
            className="group flex h-9 items-center justify-between rounded-xl p-0 font-normal focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0 md:w-full md:px-2 md:py-3"
          >
            <div className="flex items-center gap-3">
              <Typography
                bold
                className="flex w-6 shrink-0 items-center justify-center text-2xl capitalize text-white"
              >
                {getFirstLetter(businessQuery.data?.legalName)}
              </Typography>

              <AnimatePresence mode="wait">
                {expanded && (
                  <motion.span
                    initial={{ opacity: 0, x: -30 }}
                    animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
                    exit={{ opacity: 0, x: -30 }}
                    className="flex flex-col"
                  >
                    <Typography variant="body-small" className="text-white">
                      {businessQuery.data?.legalName}
                    </Typography>
                    <Typography
                      variant="body-small"
                      className="text-neutral-gray-400"
                    >
                      {getFullName(userQuery.data)}
                    </Typography>
                  </motion.span>
                )}
              </AnimatePresence>
            </div>

            <AnimatePresence mode="wait">
              {expanded && (
                <motion.span
                  initial={{ opacity: 0, x: -30 }}
                  animate={{ opacity: 1, x: 0, transition: { delay: 0.2 } }}
                  exit={{ opacity: 0, x: -30 }}
                  className="flex size-6"
                >
                  <ChevronDown
                    aria-label="Open profile dropdown"
                    className="size-6 shrink-0 text-white transition-all group-hover:-translate-y-0.5"
                  />
                </motion.span>
              )}
            </AnimatePresence>
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-52 p-1">
          {closeSidebarCallback && !isAdmin ? (
            <Button
              variant="ghost"
              className="w-full cursor-pointer justify-start gap-2 font-normal focus-visible:ring-transparent"
              onClick={() => {
                setShowSidebar('profile')
                setOpenTooltip(false)

                closeSidebarCallback?.()
                onInternalSidebarOpen?.(true)
              }}
            >
              <User className="size-5" />
              <FormattedMessage {...navMessages.myProfile} />
            </Button>
          ) : null}
          {businessPaymentLimitsQuery.data?.periodTransferLimit &&
          closeSidebarCallback ? (
            <Button
              variant="ghost"
              className="w-full cursor-pointer justify-start gap-2 font-normal focus-visible:ring-transparent"
              onClick={() => {
                setShowSidebar('settings')
                setOpenTooltip(false)

                closeSidebarCallback?.()
                onInternalSidebarOpen?.(true)
              }}
            >
              <SettingsAdjust className="size-5" />
              <FormattedMessage {...navMessages.transactionsLimit} />
            </Button>
          ) : null}
          <Button
            variant="ghost"
            className="w-full cursor-pointer justify-start gap-2 font-normal focus-visible:ring-transparent"
            onClick={logout}
          >
            <Logout className="size-5" />
            <FormattedMessage {...navMessages.logout} />
          </Button>
          <Tooltip
            open={openTooltip}
            onOpenChange={
              isMobile ? undefined : () => setOpenTooltip((v) => !v)
            }
            delayDuration={0}
          >
            <TooltipTrigger
              onClick={isMobile ? () => setOpenTooltip((o) => !o) : undefined}
              asChild
            >
              <Button
                className="w-full justify-start gap-2.5 font-normal focus-visible:ring-transparent"
                variant="ghost"
              >
                <img
                  src={getFlagUrl(flagByLocale[locale])}
                  alt={intl.formatMessage(
                    {
                      id: `country.flag.alt`,
                      defaultMessage:
                        '{country, select, MEX {Mexican flag} USA {American flag} other {}}',
                    },
                    { country: countryCodeByLocale[locale] },
                  )}
                  className="ml-0.5 size-4"
                />

                <span>
                  <Typography>{getUserLocale(locale)}</Typography>
                </span>
              </Button>
            </TooltipTrigger>
            <TooltipContent align="start" side="right">
              {Object.values(Locale).map((lang) => (
                <Button
                  disabled={lang === locale}
                  key={lang}
                  variant="ghost"
                  className="w-full cursor-pointer justify-start gap-2.5 font-normal focus-visible:ring-transparent"
                  onClick={() => changeLocale(lang)}
                >
                  <span className="flex items-center gap-2">
                    <img
                      src={getFlagUrl(flagByLocale[lang])}
                      alt={intl.formatMessage(
                        {
                          id: `country.flag.alt`,
                          defaultMessage:
                            '{country, select, MEX {Mexican flag} USA {American flag} other {}}',
                        },
                        { country: countryCodeByLocale[lang] },
                      )}
                      className="size-4"
                    />
                    <Typography>
                      {AVAILABLE_LANGUAGES.find((l) => l.code === lang)?.lang}
                    </Typography>
                  </span>

                  {lang === locale && (
                    <Check className="size-4 shrink-0 text-neutral-gray-600" />
                  )}
                </Button>
              ))}
            </TooltipContent>
          </Tooltip>
        </PopoverContent>
      </Popover>

      <TeamDetailsSidebar
        member={businessUserQuery.data}
        isOpen={showSidebar === 'profile'}
        onOpenChange={() => {
          setShowSidebar(undefined)
          setOpenTooltip(false)

          closeSidebarCallback?.()
          onInternalSidebarOpen?.(false)
        }}
      />

      {businessPaymentLimitsQuery.data?.periodTransferLimit ? (
        <AccountLimitsSidebar
          isOpen={showSidebar === 'settings'}
          onOpenChange={() => {
            setShowSidebar(undefined)
            setOpenTooltip(false)

            closeSidebarCallback?.()
            onInternalSidebarOpen?.(false)
          }}
          limits={businessPaymentLimitsQuery.data}
        />
      ) : null}
    </>
  )
}
