import { defineMessage, FormattedMessage, MessageDescriptor } from 'react-intl'
import { generatePath } from 'react-router-dom'

import { ONBOARDING_STEP } from '@/constants/paths'
import { GoBackButton } from '@/shared/components'
import { ChevronRight } from '@/shared/icons/outline'
import { Card, SlideInScreen, Typography } from '@/shared/ui'

import {
  LegalRepresentativeType,
  OnboardingStep,
  OnboardingStepConfig,
} from '../../types'
import { getPreviousStep } from '../../utils/getPreviousStep'

type Representative = {
  title: MessageDescriptor
  description: MessageDescriptor
  type: LegalRepresentativeType
}

const REPRESENTATIVES: Representative[] = [
  {
    title: defineMessage({
      id: 'legalRepresentative.representativeType.user.title',
      defaultMessage: 'I am the legal representative ',
    }),
    description: defineMessage({
      id: 'legalRepresentative.representativeType.user.description',
      defaultMessage:
        'I will use my previously entered data and validate identity taking a selfie with my ID',
    }),
    type: LegalRepresentativeType.USER,
  },
  {
    title: defineMessage({
      id: 'legalRepresentative.representativeType.other.title',
      defaultMessage: `I am filling the legal representative's data`,
    }),
    description: defineMessage({
      id: 'legalRepresentative.representativeType.other.description',
      defaultMessage:
        'I have authorization to use their data and will send them a link to validate identity and intention to open this account',
    }),
    type: LegalRepresentativeType.OTHER,
  },
]

type Props = {
  steps: OnboardingStep[]
  config?: OnboardingStepConfig
  onSelect: (representativeType: LegalRepresentativeType) => void
}

export const SelectLegalRepresentative = ({
  steps,
  config,
  onSelect,
}: Props) => {
  return (
    <>
      <GoBackButton
        className="hidden md:left-80 md:flex"
        to={generatePath(ONBOARDING_STEP, {
          step: getPreviousStep(steps, config),
        })}
      />

      <SlideInScreen>
        <Typography variant="h3" text="center">
          <FormattedMessage
            defaultMessage="Legal representative"
            id="onboarding.legalRepresentative.title"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            defaultMessage="We need to validate the legal representative's information. If that is you, indicate it to do it faster"
            id="onboarding.legalRepresentative.description"
          />
        </Typography>

        <div className="p-6" />

        <div className="flex flex-col gap-4">
          {REPRESENTATIVES.map((representative) => (
            <Card
              key={representative.title.id}
              size="medium"
              className="flex w-full items-center justify-between"
              asChild
              scalable
            >
              <button
                onClick={() => {
                  onSelect(representative.type)
                }}
              >
                <div className="flex items-center gap-4">
                  <div className="flex flex-col gap-1">
                    <Typography bold>
                      <FormattedMessage {...representative.title} />
                    </Typography>

                    <Typography className="text-neutral-gray-600">
                      <FormattedMessage {...representative.description} />
                    </Typography>
                  </div>
                </div>
                <ChevronRight className="size-7 shrink-0 text-neutral-gray-600" />
              </button>
            </Card>
          ))}
        </div>
      </SlideInScreen>
    </>
  )
}
