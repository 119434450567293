import { DisplayableType } from '@/features/Transactions/types'

import { getBulkTransaction } from './getBulkTransaction'
import { getTransaction } from './getTransaction'

type Params = {
  type?: DisplayableType | null
  id?: string | null
}

export function getTransactionByType({ type, id }: Params) {
  if (!id) {
    throw new Error('Transactions: missing id from search query')
  }

  switch (type) {
    case DisplayableType.SINGLE:
      return getTransaction({ id })

    case DisplayableType.BULK:
      return getBulkTransaction({ id })

    default:
      console.error('Transactions: Incorrect or missing search query', type)
      return getTransaction({ id })
  }
}
