import { useEffect, useState } from 'react'

import { useSearchParamsValue } from './useSearchParamsValue'

type Props = {
  key: string
}

export const useSidebar = ({ key }: Props) => {
  const [query] = useSearchParamsValue([key])

  const [showDetails, setShowDetails] = useState(!!query)

  useEffect(() => {
    if (!query) {
      setShowDetails(false)
    } else {
      setShowDetails(true)
    }
  }, [query])

  return [showDetails, setShowDetails] as const
}
