import { useEffect } from 'react'
import { useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { useDropzone } from 'react-dropzone'
import { FormattedMessage, useIntl } from 'react-intl'
import { toast } from 'sonner'

import { queryKeys } from '@/constants/queryKeys'
import { useErrorToast } from '@/hooks/useErrorToast'
import { queryClient } from '@/lib/queryClient'
import { cn } from '@/lib/utils'
import { DragAndDropContainer } from '@/shared/components'
import { Plus } from '@/shared/icons/outline'
import { Button, Card, Label, Typography } from '@/shared/ui'

import { parseCSV } from '../api'

import { UploadedCSV } from './UploadedCSV'

function typeValidator(file: File) {
  if (['text/csv'].includes(file.type)) {
    return null
  }

  return {
    code: 'invalid-type',
    message: 'validation.file.wrongFormat',
  }
}

type Props = {
  files: string[]
  bulkPaymentId?: string | null
  name: string
  isLoadingFiles: boolean
  onDrop: (files: File[]) => void
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  title: string
  description?: string
}

export const UploadCSVFileField = ({
  files,
  bulkPaymentId,
  description,
  isLoadingFiles,
  onBlur,
  onChange,
  onDrop,
  name,
  title,
}: Props) => {
  const intl = useIntl()

  const notifyError = useErrorToast()

  const { mutateAsync, isPending } = useMutation({
    mutationFn: parseCSV,
  })

  const { getRootProps, getInputProps, isDragActive, fileRejections, open } =
    useDropzone({
      multiple: true,
      validator: typeValidator,
      disabled: isLoadingFiles,
      onDrop: async (acceptedFiles) => {
        if (!acceptedFiles.length) {
          return
        }

        if (!bulkPaymentId) {
          toast.error(
            intl.formatMessage({
              defaultMessage: 'Bulk payment ID is missing',
              id: 'bulkPayment.uploadFile.missingPaymentId',
            }),
          )

          return
        }

        const files = await Promise.allSettled(
          acceptedFiles.map(async (file) => {
            const { data } = await mutateAsync({
              file,
              id: bulkPaymentId,
            })

            return data
          }),
        )

        files.map((file) => {
          if (file.status === 'fulfilled') {
            queryClient.setQueryData<AxiosResponse<{ fileName: string }[]>>(
              [queryKeys.getCSVFiles, bulkPaymentId],
              (prev) => {
                if (!prev) {
                  return prev
                }

                return {
                  ...prev,
                  data: [
                    ...prev.data,
                    {
                      fileName: file.value.fileName,
                    },
                  ],
                }
              },
            )
          } else {
            notifyError(file.reason)
          }
        })

        onDrop(acceptedFiles)
      },
    })

  const deleteFile = (fileName: string) => {
    queryClient.setQueryData<AxiosResponse<{ fileName: string }[]>>(
      [queryKeys.getCSVFiles, bulkPaymentId],
      (prev) => {
        if (!prev) {
          return prev
        }

        return {
          ...prev,
          data: [...prev.data.filter((file) => file.fileName !== fileName)],
        }
      },
    )
  }

  useEffect(() => {
    if (fileRejections.length) {
      fileRejections.forEach(({ file }) => {
        toast.error(
          intl.formatMessage(
            {
              defaultMessage: `Can't add file {name}. Only CSV files are allowed`,
              id: 'validation.file.onlyCSV',
            },
            { name: file.name },
          ),
        )
      })
    }
  }, [fileRejections, intl])

  return (
    <Card size="upload">
      <div className="flex flex-col gap-2">
        <Typography bold>{title}</Typography>
        {description ? (
          <Typography className="text-neutral-gray-600">
            {description}
          </Typography>
        ) : null}
      </div>

      <div className="p-2" />
      <div className={cn('flex w-full gap-6')} {...getRootProps()}>
        <input
          {...getInputProps({
            id: name,
            name,
            onChange,
            onBlur,
          })}
        />

        <Label className="sr-only" htmlFor={name}>
          <FormattedMessage
            id="uploadFileField.dragOrClick"
            defaultMessage="Drag and drop or click to upload"
          />
        </Label>

        {files.length ? (
          <div className="flex w-full flex-col justify-center gap-2">
            {files.map((file) => (
              <UploadedCSV
                key={file}
                file={file}
                bulkPaymentId={bulkPaymentId}
                onDelete={deleteFile}
              />
            ))}

            <div className="p-1" />

            <Button
              disabled={isPending}
              onClick={open}
              leftIcon={<Plus className="size-5" />}
              loaderPosition="left"
              loading={isPending}
              type="button"
              variant="secondary"
            >
              <FormattedMessage
                id="uploadFile.field.addAnother"
                defaultMessage="Add another file"
              />
            </Button>
          </div>
        ) : (
          <DragAndDropContainer
            files={files}
            isDragActive={isDragActive}
            isPending={isPending || isLoadingFiles}
          />
        )}
      </div>
    </Card>
  )
}
