import { useQuery } from '@tanstack/react-query'
import { FormattedMessage } from 'react-intl'

import { queryKeys } from '@/constants/queryKeys'
import { getRecipients } from '@/features/Recipients/api'
import { SingleTransaction } from '@/features/Transactions/types'
import { parseAdditionalDetails } from '@/lib/utils'

import { Typography } from '../../ui'
import { TransactionIcon } from '../TransactionIcon'

type Props = {
  transaction: SingleTransaction
}

export const SingleTransactionCell = ({ transaction }: Props) => {
  const recipientId = parseAdditionalDetails<{ beneficiaryId?: string }>(
    transaction.additionalDetails,
  )?.beneficiaryId

  const { data } = useQuery({
    queryKey: [queryKeys.getRecipients],
    queryFn: () => getRecipients(),
    select: (data) =>
      data.data.find((recipient) => recipient.id === recipientId),
    enabled: !!recipientId,
  })

  return (
    <div className="flex items-center gap-3">
      <TransactionIcon
        additionalDetails={transaction.additionalDetails}
        recipientType={data?.entityType}
        transactionType={transaction.transactionType}
        transactionStatus={transaction.transactionStatus}
      />

      <div className="flex items-center gap-1">
        <Typography className="line-clamp-1">
          <FormattedMessage
            id="transaction.typeLabel"
            defaultMessage="{type, select, DEPOSIT {From:} WITHDRAW {To:} INTERNAL_SWAP_DEPOSIT {From:} INTERNAL_SWAP_WITHDRAW {To:} other {}}"
            values={{ type: transaction.transactionType }}
          />{' '}
          {transaction.name}
        </Typography>
      </div>
    </div>
  )
}
