import {
  FocusEvent,
  FormEvent,
  forwardRef,
  Fragment,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { cva, VariantProps } from 'class-variance-authority'
import { FormattedMessage } from 'react-intl'

import { cn } from '@/lib/utils'

import { Input, Label } from '.'

type InputOrNull = HTMLInputElement | null

const inputOTPVariants = cva(
  'flex h-20 w-16 p-0 text-center text-2xl font-bold ring-offset-white [appearance:textfield] focus:outline-none md:text-2.5xl [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none',
  {
    variants: {
      variant: {
        default: 'h-12 md:h-20 w-10 md:w-16',
        small: 'h-12 w-10 text-lg',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
)

const DEFAULT_LENGTH = 6

interface Props {
  shouldReset?: boolean
  length?: number
  onChange?: (value: string) => void
}

export const InputOTP = forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement> &
    Props &
    VariantProps<typeof inputOTPVariants>
>(
  (
    {
      className,
      disabled,
      length = DEFAULT_LENGTH,
      onChange,
      shouldReset,
      variant,
    },
    _,
  ) => {
    const [code, setCode] = useState<string[]>(Array(length).fill(''))

    const update = useCallback((index: number, val: string) => {
      return setCode((prevState) => {
        const slice = prevState.slice()
        slice[index] = val

        return slice
      })
    }, [])

    useEffect(() => {
      if (shouldReset) {
        setCode(Array(length).fill(''))
      }
    }, [length, shouldReset])

    useEffect(() => {
      onChange?.(code.join(''))
    }, [code, onChange])

    const inputRef = useRef<HTMLDivElement>(null)

    function handleKeyDown(evt: KeyboardEvent<HTMLInputElement>) {
      const index = parseInt(evt.currentTarget.dataset.index as string)
      const form = inputRef.current
      if (isNaN(index) || form === null) return

      const prevIndex = index - 1
      const nextIndex = index + 1
      const prevInput: InputOrNull = form.querySelector(`.input-${prevIndex}`)
      const nextInput: InputOrNull = form.querySelector(`.input-${nextIndex}`)

      switch (evt.key) {
        case 'Backspace':
          if (code[index]) update(index, '')
          else if (prevInput) prevInput.select()
          break
        case 'ArrowRight':
          evt.preventDefault()
          if (nextInput) nextInput.focus()
          break
        case 'ArrowLeft':
          evt.preventDefault()
          if (prevInput) prevInput.focus()
      }
    }

    function handleChange(evt: FormEvent<HTMLInputElement>) {
      const value = evt.currentTarget.value
      const index = parseInt(evt.currentTarget.dataset.index as string)
      const form = inputRef.current
      if (isNaN(index) || form === null) return

      let nextIndex = index + 1
      let nextInput: InputOrNull = form.querySelector(`.input-${nextIndex}`)

      update(index, value[0] || '')
      if (value.length === 1) nextInput?.focus()
      else if (index < length - 1) {
        const split = value.slice(index + 1, length).split('')
        split.forEach((val) => {
          update(nextIndex, val)
          nextInput?.focus()
          nextIndex++
          nextInput = form.querySelector(`.input-${nextIndex}`)
        })
      }
    }

    function handleFocus(evt: FocusEvent<HTMLInputElement>) {
      evt.currentTarget.select()
    }

    return (
      <div ref={inputRef} className="flex w-full justify-around gap-3">
        {code.map((value, i) => (
          <Fragment key={i}>
            <Label htmlFor={`otp-code-${i}`} className="sr-only">
              <FormattedMessage
                id="input.otp.enterDigit"
                defaultMessage="{count, select, 1 {Enter first digit} 2 {Enter second digit} 3 {Enter third digit} 4 {Enter fourth digit} 5 {Enter fifth digit} 6 {Enter sixth digit} other {Enter digit}}"
                values={{ count: i + 1 }}
              />
            </Label>
            <Input
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={i === 0}
              autoComplete="one-time-code"
              id={`otp-code-${i}`}
              value={value}
              maxLength={1}
              type="number"
              className={cn(
                `input-${i}`,
                inputOTPVariants({ variant }),
                className,
              )}
              data-index={i}
              pattern="[0-9]*"
              inputMode="decimal"
              disabled={disabled}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              onFocus={handleFocus}
            />
          </Fragment>
        ))}
      </div>
    )
  },
)

InputOTP.displayName = 'InputOTP'
