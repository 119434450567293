import { useEffect, useMemo, useState } from 'react'
import { AnimatePresence } from 'framer-motion'
import { useLocation } from 'react-router-dom'

import { Currency } from '@/constants/currency'
import { DASHBOARD_ROUTE } from '@/constants/paths'
import { useSearchParamsValue } from '@/hooks/useSearchParamsValue'
import { ProgressButton } from '@/shared/components'
import { FullScreen } from '@/shared/ui'

import { AmountScreen } from './components/AmountScreen'
import { ReviewScreen } from './components/ReviewScreen'
import { SelectRecipientScreen } from './components/SelectRecipientScreen'

enum Step {
  Recipient = 'recipient',
  Amount = 'amount',
  Review = 'review',
}

export type RequestData = {
  amount: string
  requestId: string
  walletId: string
  currency: Currency
  currencyOut: Currency
  beneficiaryId: string
}

type State =
  | { type: Step.Recipient }
  | { type: Step.Amount; recipient: string; amount?: string; walletId?: string }
  | { type: Step.Review; data: RequestData }

export const Send = () => {
  const [recipient] = useSearchParamsValue(['recipient'])
  const location = useLocation()

  const [activeStep, setActiveStep] = useState<State>(
    recipient ? { type: Step.Amount, recipient } : { type: Step.Recipient },
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [activeStep])

  const progressByStep = useMemo(() => {
    switch (activeStep.type) {
      case Step.Recipient:
        return 25
      case Step.Amount:
        return 50
      case Step.Review:
        return 75
    }
  }, [activeStep.type])

  return (
    <FullScreen>
      <ProgressButton
        progress={progressByStep}
        to={location.state?.from ?? DASHBOARD_ROUTE}
      />

      <AnimatePresence mode="wait">
        {activeStep.type === Step.Recipient && (
          <SelectRecipientScreen
            onSelect={(recipient) =>
              setActiveStep({
                type: Step.Amount,
                recipient,
              })
            }
          />
        )}

        {activeStep.type === Step.Amount && (
          <AmountScreen
            recipient={activeStep.recipient}
            onBack={() => {
              setActiveStep({
                type: Step.Recipient,
              })
            }}
            onContinue={(data) => {
              setActiveStep({
                type: Step.Review,
                data,
              })
            }}
          />
        )}

        {activeStep.type === Step.Review && (
          <ReviewScreen
            onBack={() => {
              setActiveStep({
                type: Step.Amount,
                recipient: activeStep.data.beneficiaryId,
              })
            }}
            {...activeStep.data}
          />
        )}
      </AnimatePresence>
    </FullScreen>
  )
}
