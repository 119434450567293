import * as React from 'react'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@/lib/utils'

const badgeVariants = cva(
  'inline-flex items-center rounded-xl border h-[22px] px-2 text-xs uppercase transition-colors whitespace-nowrap focus:outline-none focus:ring-1 focus:ring-ring focus:ring-offset-2',
  {
    variants: {
      variant: {
        default:
          'border-transparent bg-primary/20 text-primary-dark hover:bg-primary/20',
        destructive:
          'border-transparent bg-primary-error/10 text-primary-error hover:bg-primary-error/20',
        neutral:
          'border-transparent bg-neutral-gray-100 text-neutral-gray-800 hover:bg-neutral-gray-200',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  )
}

export { Badge }
