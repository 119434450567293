import { Wallet } from '@/features/Accounts/types'
import { formatCurrency, formatMoney } from '@/lib/money'

import { Typography } from '../ui'

import { AccountIcon } from './AccountIcon'

type Props = {
  accountIconVariant?: 'large' | 'middle'
  balance?: boolean
  boldText?: boolean
  wallet: Wallet
}

export const WalletItem = ({
  accountIconVariant = 'middle',
  balance = true,
  boldText = false,
  wallet,
}: Props) => {
  return (
    <div className="flex items-center gap-2">
      <AccountIcon variant={accountIconVariant} id={wallet.id} />

      <div className="flex gap-1">
        <Typography className="text-neutral-gray-900" bold={boldText}>
          {wallet.label}
        </Typography>

        {balance ? (
          <>
            <Typography className="text-neutral-gray-900" bold={boldText}>
              •
            </Typography>
            <Typography className="text-neutral-gray-900" bold={boldText}>
              {formatMoney(wallet.balance)} {formatCurrency(wallet.currency)}
            </Typography>
          </>
        ) : null}
      </div>
    </div>
  )
}
