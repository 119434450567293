import { useCallback, useMemo, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { Big } from 'big.js'
import { useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { z } from 'zod'

import { getRatesWithFees, RatesWithFeesResponse } from '@/api'
import { Currency } from '@/constants/currency'
import { TASKS_ROUTE, TRANSACTIONS_ROUTE } from '@/constants/paths'
import { ACH_FEE, PaymentMethod, WIRE_FEE } from '@/constants/payments'
import { queryKeys } from '@/constants/queryKeys'
import { getSingleRecipient } from '@/features/Recipients/api'
import { Recipient } from '@/features/Recipients/types'
import { DisplayableType } from '@/features/Transactions/types'
import { useCheckUserPaymentLimit } from '@/hooks/useCheckUserPaymentLimit'
import { useErrorToast } from '@/hooks/useErrorToast'
import { formatDate } from '@/lib/date'
import { hasOTPRequiredError } from '@/lib/error'
import {
  formatAmount,
  formatCurrency,
  formatMoney,
  formatRate,
} from '@/lib/money'
import { queryClient } from '@/lib/queryClient'
import { removeEmptyFormFields } from '@/lib/utils'
import {
  GoBackButton,
  OptionalTag,
  OTPDialog,
  Widget,
} from '@/shared/components'
import {
  AnimatedFormLabel,
  Button,
  Details,
  Form,
  FormControl,
  FormField,
  FormItem,
  Input,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'
import { CountryCode } from '@/types/country'

import { sendMoney, SendMoneyRequest } from '../api'

import { ReviewPaymentDetails } from './ReviewPaymentDetails'

const REVIEW_FORM_ID = 'review-form-id'

const reviewSchema = z.object({
  amount: z.string(),
  walletId: z.string(),
  beneficiaryId: z.string(),
  currency: z.nativeEnum(Currency),
  requestId: z.string(),
  paymentMessage: z.string().optional(),
  email: z
    .string()
    .email({ message: 'validation.email.invalid' })
    .optional()
    .or(z.literal('')),
})

export type ReviewSchema = z.infer<typeof reviewSchema>

type Props = {
  onBack: () => void
  currencyOut: Currency
} & SendMoneyRequest

function isUSDc(currency: Currency) {
  return currency === Currency.USDC
}

export const ReviewScreen = ({
  amount,
  requestId,
  walletId,
  currency,
  currencyOut,
  beneficiaryId,
  onBack,
}: Props) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const notifyError = useErrorToast()
  const [isDialogOpen, setDialogOpen] = useState(false)

  const isOverLimit = useCheckUserPaymentLimit()

  const [recipientQuery, ratesQuery] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getSingleRecipient, beneficiaryId],
        queryFn: () => getSingleRecipient({ id: beneficiaryId }),
        select: (data: AxiosResponse<Recipient>) => data.data,
        enabled: !!beneficiaryId,
      },
      {
        queryKey: [queryKeys.getFXRates, currencyOut, currency],
        queryFn: () =>
          getRatesWithFees({
            from: Currency.USDC,
            to: isUSDc(currency) ? currencyOut : currency,
          }),
        select: (data: AxiosResponse<RatesWithFeesResponse>) => data.data,
        enabled: !!currency,
        refetchInterval: 10 * 1000,
      },
    ],
  })

  const { mutateAsync, isPending, isError } = useMutation({
    mutationFn: sendMoney,
  })

  const form = useForm({
    mode: 'onChange',
    resolver: zodResolver(reviewSchema),
    defaultValues: {
      amount,
      walletId,
      beneficiaryId,
      currency,
      requestId,
      paymentMessage: '',
      email: '',
    },
  })

  const isUSRecipient = recipientQuery.data?.country === CountryCode.US

  const onSubmit = useCallback(
    async (data: SendMoneyRequest, otp?: string) => {
      removeEmptyFormFields(data)

      try {
        const response = await mutateAsync({
          ...data,
          otp,
        })

        const { amount, currency } = form.getValues()

        const payAmount = isUSDc(currency)
          ? amount
          : Big(amount)
              .div(ratesQuery.data?.fxRate ?? 0)
              .toFixed(2)

        if (isOverLimit(payAmount)) {
          setDialogOpen(false)

          await queryClient.invalidateQueries({
            queryKey: [queryKeys.getUserTasks],
          })

          toast.success(
            intl.formatMessage({
              id: 'transfer.task.submitted',
              defaultMessage: 'Send request submitted and pending approval',
            }),
          )

          navigate(TASKS_ROUTE)

          return
        }

        setDialogOpen(false)

        await queryClient.invalidateQueries({
          queryKey: [queryKeys.getTransactions],
        })

        toast.success(
          intl.formatMessage({
            id: 'send.paymentSent',
            defaultMessage: 'Payment sent successfully',
          }),
        )

        const queryParams = new URLSearchParams()

        if (response.data.transactionId) {
          queryParams.set('tx', response.data.transactionId)
          queryParams.set('type', DisplayableType.SINGLE)

          navigate(`${TRANSACTIONS_ROUTE}?${queryParams.toString()}`)
          return
        }

        navigate(TRANSACTIONS_ROUTE)
      } catch (error) {
        if (error instanceof Error) {
          if (hasOTPRequiredError(error)) {
            setDialogOpen(true)
            return
          }

          notifyError(error)
        }
      }
    },
    [
      form,
      intl,
      isOverLimit,
      navigate,
      notifyError,
      ratesQuery.data?.fxRate,
      mutateAsync,
    ],
  )

  const payValue = useMemo(() => {
    if (!ratesQuery.data?.fxRate) {
      return 0
    }

    return isUSDc(currency)
      ? amount
      : Big(amount).div(ratesQuery.data.fxRate).toFixed(2)
  }, [amount, currency, ratesQuery.data?.fxRate])

  const sendValue = useMemo(() => {
    if (!ratesQuery.data?.fxRate) {
      return 0
    }

    return isUSDc(currency) && !isUSRecipient
      ? Big(amount).times(ratesQuery.data.fxRate).toFixed(2)
      : amount
  }, [amount, currency, isUSRecipient, ratesQuery.data?.fxRate])

  const sendCurrency = useMemo(() => {
    return isUSDc(currency) && !isUSRecipient
      ? formatCurrency(currencyOut)
      : formatCurrency(currency)
  }, [currency, currencyOut, isUSRecipient])

  const exchangeRate = useMemo(() => {
    if (isUSRecipient) {
      return `1 ${formatCurrency(Currency.USDC)} = 1 ${formatCurrency(Currency.USD)}`
    }

    return `1 ${formatCurrency(Currency.USDC)} = ${formatRate(ratesQuery.data?.fxRate)}
    ${
      isUSDc(currency) ? formatCurrency(currencyOut) : formatCurrency(currency)
    }`
  }, [currency, currencyOut, isUSRecipient, ratesQuery.data?.fxRate])

  const recipientCurrency = useMemo(() => {
    if (isUSRecipient) {
      return Currency.USD
    }

    return isUSDc(currency)
      ? formatCurrency(currencyOut)
      : formatCurrency(currency)
  }, [currency, currencyOut, isUSRecipient])

  const paymentType = useMemo(() => {
    if (!recipientQuery.data) {
      return undefined
    }

    return recipientQuery.data.country === CountryCode.US
      ? recipientQuery.data.localInformation.paymentMethod
      : undefined
  }, [recipientQuery.data])

  const showPaymentMessage = useMemo(
    () =>
      !isUSRecipient || (isUSRecipient && paymentType === PaymentMethod.WIRE),
    [isUSRecipient, paymentType],
  )

  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            defaultMessage="Confirm payment"
            id="send.reviewScreen.title"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            id="send.reviewScreen.subtitle"
            defaultMessage="Review your payment carefully before sending"
          />
        </Typography>

        <div className="p-6" />

        <Form {...form}>
          <form
            id={REVIEW_FORM_ID}
            className="w-full"
            onSubmit={form.handleSubmit((formData) => onSubmit(formData))}
          >
            <Widget
              title={
                <FormattedMessage
                  id="label.recipientSummary"
                  defaultMessage="Recipient summary"
                />
              }
            >
              <Details>
                <Details.Label>
                  <FormattedMessage defaultMessage="Name" id="label.name" />
                </Details.Label>
                {recipientQuery.isPending ? (
                  <Details.Skeleton />
                ) : (
                  <Details.Value>{recipientQuery.data?.nickname}</Details.Value>
                )}
              </Details>
              {recipientQuery.data?.email && (
                <Details>
                  <Details.Label>
                    <FormattedMessage
                      defaultMessage="Contact email"
                      id="label.contactEmail"
                    />
                  </Details.Label>
                  {recipientQuery.isPending ? (
                    <Details.Skeleton />
                  ) : (
                    <Details.Value>{recipientQuery.data?.email}</Details.Value>
                  )}
                </Details>
              )}

              <ReviewPaymentDetails recipient={recipientQuery.data} />

              {recipientQuery.data?.bankName ? (
                <Details>
                  <Details.Label>
                    <FormattedMessage
                      defaultMessage="Recipient bank"
                      id="label.recipientBank"
                    />
                  </Details.Label>
                  {recipientQuery.isPending ? (
                    <Details.Skeleton />
                  ) : (
                    <Details.Value>
                      {recipientQuery.data?.bankName}
                    </Details.Value>
                  )}
                </Details>
              ) : null}
            </Widget>

            <div className="p-3" />

            <Widget
              title={
                <FormattedMessage
                  id="label.paymentDetails"
                  defaultMessage="Payment details"
                />
              }
            >
              <Details>
                <Details.Label>
                  <FormattedMessage
                    defaultMessage="You pay"
                    id="send.reviewScreen.youPay"
                  />
                </Details.Label>
                {ratesQuery.isPending ? (
                  <Details.Skeleton />
                ) : (
                  <Details.Value>
                    {formatMoney(payValue)} {formatCurrency(Currency.USDC)}
                  </Details.Value>
                )}
              </Details>

              {isUSRecipient ? (
                <Details>
                  <Details.Label>
                    <FormattedMessage defaultMessage="Fee" id="label.fee" />
                  </Details.Label>
                  {ratesQuery.isPending ? (
                    <Details.Skeleton />
                  ) : (
                    <div className="flex gap-1 text-right">
                      <Typography className="line-through">
                        {formatAmount({
                          amount:
                            paymentType === PaymentMethod.WIRE
                              ? WIRE_FEE
                              : ACH_FEE,
                          currency: Currency.USDC,
                        })}
                      </Typography>
                      <Typography bold className="uppercase text-primary">
                        <FormattedMessage
                          defaultMessage="Free"
                          id="label.free"
                        />
                      </Typography>
                    </div>
                  )}
                </Details>
              ) : null}

              {!recipientQuery.isPending ? (
                <Details>
                  <Details.Label>
                    <FormattedMessage
                      defaultMessage="Exchange rate"
                      id="label.exchangeRate"
                    />
                  </Details.Label>
                  {ratesQuery.isPending ? (
                    <Details.Skeleton />
                  ) : (
                    <Details.Value>{exchangeRate}</Details.Value>
                  )}
                </Details>
              ) : null}

              <Details>
                <Details.Label>
                  <FormattedMessage
                    defaultMessage="Recipient gets"
                    id="send.reviewScreen.recipientGets"
                  />
                </Details.Label>
                {ratesQuery.isPending ? (
                  <Details.Skeleton />
                ) : (
                  <Details.Value>
                    {formatMoney(sendValue)} {formatCurrency(recipientCurrency)}
                  </Details.Value>
                )}
              </Details>
            </Widget>

            <div className="p-3" />

            <Widget
              title={
                <FormattedMessage
                  id="label.additionalInformation"
                  defaultMessage="Additional information"
                />
              }
            >
              <Details>
                <Details.Label>
                  <FormattedMessage defaultMessage="Date" id="label.date" />
                </Details.Label>
                <Details.Value>
                  {formatDate(new Date(), 'd MMM. yyyy, HH:mm')}
                </Details.Value>
              </Details>

              <Details>
                <Details.Label>
                  <FormattedMessage defaultMessage="Status" id="label.status" />
                </Details.Label>
                <Details.Value>
                  <FormattedMessage
                    defaultMessage="Preview"
                    id="label.preview"
                  />
                </Details.Value>
              </Details>
            </Widget>

            <div className="p-3" />
            {showPaymentMessage && (
              <FormField
                control={form.control}
                name="paymentMessage"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        placeholder={intl.formatMessage({
                          defaultMessage: 'Add a payment memo',
                          id: 'send.reviewScreen.paymentMemo',
                        })}
                        {...field}
                      />
                    </FormControl>
                    <AnimatedFormLabel>
                      <FormattedMessage
                        defaultMessage="Add a payment memo"
                        id="send.reviewScreen.paymentMemo"
                      />
                    </AnimatedFormLabel>

                    {field.value === '' && <OptionalTag />}
                  </FormItem>
                )}
              />
            )}
          </form>
        </Form>

        <StickyContainer>
          <Button
            width="full"
            form={REVIEW_FORM_ID}
            disabled={isPending}
            loading={isPending}
            onClick={form.handleSubmit((formData) => onSubmit(formData))}
            type="submit"
          >
            {isOverLimit(payValue) ? (
              <FormattedMessage
                defaultMessage="Send request for approval"
                id="action.sendRequestForApproval"
              />
            ) : (
              <FormattedMessage
                defaultMessage="Send {value} {currency}"
                id="send.reviewScreen.sendAction"
                values={{
                  value: formatMoney(sendValue),
                  currency: formatCurrency(sendCurrency),
                }}
              />
            )}
          </Button>
        </StickyContainer>
      </SlideInScreen>

      <OTPDialog
        isOpen={isDialogOpen}
        onOpenChange={(open) => setDialogOpen(open)}
        isPending={isPending}
        isError={isError}
        onContinue={(otp) => {
          onSubmit(form.getValues(), otp)
        }}
      />
    </>
  )
}
