import { useEffect, useMemo, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useQuery } from '@tanstack/react-query'
import { Big } from 'big.js'
import { useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { z } from 'zod'

import { getFXRates } from '@/api'
import { Currency } from '@/constants/currency'
import { queryKeys } from '@/constants/queryKeys'
import { formatCurrency, formatRate } from '@/lib/money'
import { CurrencyFlag } from '@/shared/components'
import { ArrowDown, ArrowTrendUp } from '@/shared/icons/outline'
import {
  AnimatedFormLabel,
  Button,
  Card,
  Form,
  FormControl,
  FormField,
  FormItem,
  MoneyInput,
  Sheet,
  SheetContent,
  Skeleton,
  Typography,
} from '@/shared/ui'
import { CountryCode } from '@/types/country'

const exchangeSchema = z.object({
  amountIn: z.string(),
  amountOut: z.string(),
  currencyIn: z.nativeEnum(Currency),
  currencyOut: z.nativeEnum(Currency),
})

type ExchangeSchema = z.infer<typeof exchangeSchema>

type Props = {
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
}

export const CalculateRateSidebar = ({ isOpen, onOpenChange }: Props) => {
  const [swapRates, setSwapRates] = useState(false)
  const intl = useIntl()

  const form = useForm<ExchangeSchema>({
    mode: 'onChange',
    resolver: zodResolver(exchangeSchema),
    defaultValues: {
      currencyIn: Currency.MXN,
      currencyOut: Currency.USDC,
      amountIn: '',
      amountOut: '',
    },
  })

  const ratesQuery = useQuery({
    queryKey: [queryKeys.getFXRates],
    queryFn: () =>
      getFXRates({
        from: Currency.USDC,
        to: Currency.MXN,
      }),
    select: (data) => data.data,
    refetchInterval: 10 * 1000,
  })

  const currentRate = useMemo(() => {
    if (ratesQuery.data) {
      const { askRate, bidRate } = ratesQuery.data

      return swapRates ? bidRate : askRate
    }

    return 1
  }, [ratesQuery.data, swapRates])

  useEffect(() => {
    const amountIn = form.getValues('amountIn')

    if (ratesQuery.data && amountIn !== '') {
      if (swapRates) {
        form.setValue(
          'amountOut',
          new Big(amountIn).times(currentRate).toFixed(2),
        )

        return
      }

      form.setValue('amountOut', new Big(amountIn).div(currentRate).toFixed(2))
    }
  }, [currentRate, form, ratesQuery.data, swapRates])

  return (
    <Sheet open={isOpen} onOpenChange={onOpenChange}>
      <SheetContent>
        <Typography variant="h3">
          <FormattedMessage
            id="label.exchangeCalculator"
            defaultMessage="Exchange calculator"
          />
        </Typography>

        <div className="p-1" />

        <div className="flex w-full items-center gap-1">
          <ArrowTrendUp />
          <div className="flex items-center gap-1">
            <Typography>1 {formatCurrency(Currency.USDC)} =</Typography>
            {ratesQuery.isPending ? (
              <Skeleton className="h-6 w-24 rounded-md" />
            ) : (
              <Typography>
                {formatRate(currentRate)} {formatCurrency(Currency.MXN)}
              </Typography>
            )}
          </div>
        </div>

        <div className="p-4" />

        <Form {...form}>
          <form className="w-full">
            <FormField
              control={form.control}
              name="amountIn"
              render={({ field }) => (
                <FormItem>
                  <Card size="input" className="flex">
                    <CurrencyFlag currency={form.getValues('currencyIn')} />

                    <FormControl>
                      <MoneyInput
                        currency={Currency.USD}
                        placeholder={intl.formatMessage({
                          id: 'label.sell',
                          defaultMessage: 'Sell',
                        })}
                        className="text-right"
                        value={field.value}
                        onChange={(value) => {
                          field.onChange(value)

                          const amountOut =
                            value !== ''
                              ? swapRates
                                ? Big(value).times(currentRate).toFixed(2)
                                : Big(value).div(currentRate).toFixed(2)
                              : ''

                          form.setValue('amountOut', amountOut)
                          form.trigger('amountOut')
                        }}
                      />
                    </FormControl>
                    <AnimatedFormLabel align="end">
                      <FormattedMessage id="label.sell" defaultMessage="Sell" />
                    </AnimatedFormLabel>
                  </Card>
                </FormItem>
              )}
            />

            <Button
              variant="ghost"
              type="button"
              size="inline"
              className="relative z-10 mx-auto -mb-5 -mt-5 flex h-14 w-14 items-center justify-center rounded-full border-[8px] border-neutral-gray-100 bg-white transition-all duration-500 hover:rotate-180"
              aria-label={intl.formatMessage({
                id: 'label.swapCurrencies',
                defaultMessage: 'Swap currencies',
              })}
              onClick={() => {
                setSwapRates((r) => !r)

                const currencyIn = form.getValues('currencyIn')
                const currencyOut = form.getValues('currencyOut')

                form.setValue('currencyIn', currencyOut)
                form.setValue('currencyOut', currencyIn)

                const amountOut = form.getValues('amountOut')

                form.setValue('amountIn', amountOut)
              }}
            >
              <ArrowDown className="size-5" />
            </Button>

            <FormField
              control={form.control}
              name="amountOut"
              render={({ field }) => (
                <FormItem>
                  <Card size="input" className="flex">
                    <CurrencyFlag
                      countryCode={
                        form.getValues('currencyOut') === Currency.USDC
                          ? CountryCode.US
                          : CountryCode.MX
                      }
                      currency={form.getValues('currencyOut')}
                    />

                    <FormControl>
                      <MoneyInput
                        currency={Currency.USD}
                        placeholder={intl.formatMessage({
                          id: 'label.buy',
                          defaultMessage: 'Buy',
                        })}
                        className="text-right"
                        value={field.value}
                        onChange={(value) => {
                          field.onChange(value)

                          form.trigger('amountOut')

                          const amountIn =
                            value !== ''
                              ? swapRates
                                ? Big(value).div(currentRate).toFixed(2)
                                : Big(value).times(currentRate).toFixed(2)
                              : ''

                          form.setValue('amountIn', amountIn)
                        }}
                      />
                    </FormControl>

                    <AnimatedFormLabel align="end">
                      <FormattedMessage id="label.buy" defaultMessage="Buy" />
                    </AnimatedFormLabel>
                  </Card>
                </FormItem>
              )}
            />
          </form>
        </Form>
      </SheetContent>
    </Sheet>
  )
}
