import { Check } from '@/shared/icons/outline'
import { MotionDiv, Spinner, Typography } from '@/shared/ui'

type Props = {
  name?: string
  isPending?: boolean
}

export const BankName = ({ name, isPending }: Props) => {
  return (
    <div className="absolute right-0 top-4 flex items-center pr-4">
      {isPending ? (
        <Spinner className="size-5 fill-primary text-primary-light" />
      ) : name ? (
        <MotionDiv className="flex items-center gap-2">
          <Typography className="text-neutral-gray-600">{name}</Typography>
          <span className="flex size-4 shrink-0 items-center justify-center rounded-full bg-primary/50 text-white">
            <Check className="size-3" />
          </span>
        </MotionDiv>
      ) : null}
    </div>
  )
}
