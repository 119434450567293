import { useQuery } from '@tanstack/react-query'

import { queryKeys } from '@/constants/queryKeys'
import { AccountType, Wallet } from '@/features/Accounts/types'
import { CountryBankDetails } from '@/shared/components'
import { Sheet, SheetContent, Skeleton, Typography } from '@/shared/ui'

import { getAccountDetails } from '../api'

type Props = {
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  wallet?: Wallet
}

export const AccountDetailsSidebar = ({
  isOpen,
  onOpenChange,
  wallet,
}: Props) => {
  const { data } = useQuery({
    queryKey: [queryKeys.getAccountDetails, wallet?.id],
    queryFn: () => getAccountDetails({ id: wallet?.id ?? '' }),
    select: (data) =>
      data?.data.bankAccounts.sort((a) =>
        a.type === AccountType.ACH ? -1 : 1,
      ),
    enabled: !!wallet?.id,
  })

  return (
    <Sheet open={isOpen} onOpenChange={onOpenChange}>
      <SheetContent>
        {wallet ? (
          <div className="flex w-full items-center justify-between">
            <Typography variant="h3">{wallet.label}</Typography>
          </div>
        ) : (
          <Skeleton className="h-[33px] w-36" />
        )}

        <div className="p-4" />

        {data ? (
          <CountryBankDetails accountBanks={data} walletId={wallet?.id} />
        ) : (
          <>
            <CountryBankDetails.Skeleton rows={6} />
            <div className="p-3" />
            <CountryBankDetails.Skeleton />
          </>
        )}

        <div className="p-6" />
      </SheetContent>
    </Sheet>
  )
}
