import { Contractor } from '@/features/Contractors/types'
import { CountryName } from '@/shared/components'

type Props = {
  contractor: Contractor
}

export const CitizenshipCell = ({ contractor }: Props) => {
  return (
    <div className="flex items-center">
      <CountryName countryCode={contractor.countryOfCitizenship} />
    </div>
  )
}
