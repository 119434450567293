import { FormattedMessage } from 'react-intl'
import { useSearchParams } from 'react-router-dom'

import { TransactionStatus } from '@/features/Transactions/types'
import { handleURLSearchParams } from '@/lib/utils'

import { Check } from '../../icons/outline'
import { Filter } from '../../icons/solid'
import {
  Button,
  DropdownMenuCheckboxItem,
  DropdownMenuGroup,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from '../../ui'

import { STATUS_FILTER_NAME } from './data'

export const StateFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  function handleSearchParams(value: string) {
    return (
      setSearchParams((params) =>
        handleURLSearchParams(params, STATUS_FILTER_NAME, value),
      ),
      { preventScrollReset: true }
    )
  }

  return (
    <DropdownMenuGroup>
      <DropdownMenuSub>
        <DropdownMenuSubTrigger asChild>
          <Button
            className="h-10 w-full justify-between font-normal hover:text-neutral-gray-900 focus-visible:ring-transparent"
            variant="ghost"
          >
            <span className="flex items-center gap-2">
              <Check className="size-4" />
              <FormattedMessage defaultMessage="Status" id="label.status" />
            </span>
            {searchParams.get(STATUS_FILTER_NAME) && (
              <Filter className="size-4" />
            )}
          </Button>
        </DropdownMenuSubTrigger>
        <DropdownMenuPortal>
          <DropdownMenuSubContent className="w-52">
            <DropdownMenuCheckboxItem
              onSelect={(event) => event.preventDefault()}
              checked={searchParams.get(STATUS_FILTER_NAME) === null}
              onCheckedChange={() =>
                setSearchParams(
                  (params) => {
                    params.delete(STATUS_FILTER_NAME)
                    return params
                  },
                  { preventScrollReset: true },
                )
              }
            >
              <FormattedMessage
                id="label.selectAll"
                defaultMessage="Select all"
              />
            </DropdownMenuCheckboxItem>

            {Object.values(TransactionStatus).map((status) => (
              <DropdownMenuCheckboxItem
                key={status}
                onSelect={(event) => event.preventDefault()}
                checked={searchParams.get(STATUS_FILTER_NAME)?.includes(status)}
                onCheckedChange={() => handleSearchParams(status)}
              >
                <FormattedMessage
                  id="transaction.status"
                  defaultMessage="{status, select, FAILED {Failed} BLOCKED {Blocked} REVERTED {Reverted} PENDING {Pending} COMPLETED {Completed} other {}}"
                  values={{ status }}
                />
              </DropdownMenuCheckboxItem>
            ))}
          </DropdownMenuSubContent>
        </DropdownMenuPortal>
      </DropdownMenuSub>
    </DropdownMenuGroup>
  )
}
