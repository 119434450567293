import { FormattedMessage } from 'react-intl'

import { teamPermissionsMessages } from '@/constants/messages'
import { TeamMember, TeamState } from '@/features/Team/types'
import { useBusinessRole } from '@/hooks/useBusinessRole'
import { cn } from '@/lib/utils'
import { Check, X } from '@/shared/icons/outline'
import { Skeleton, Typography } from '@/shared/ui'
import { Permission } from '@/types/permissions'

import { Widget } from '../Widget'

type Props = {
  member?: TeamMember
}

export const PermissionsList = ({ member }: Props) => {
  const isBlockedOrTerminated =
    member?.state === TeamState.BLOCKED ||
    member?.state === TeamState.TERMINATED
  const { isAdmin } = useBusinessRole()

  return (
    <Widget
      title={
        <FormattedMessage id="label.permissions" defaultMessage="Permissions" />
      }
      className={cn(
        'flex flex-col gap-4',
        isBlockedOrTerminated && 'pointer-events-none bg-neutral-gray-200',
      )}
    >
      {member
        ? Object.values(Permission).map((permission) => {
            const hasActivePermission = member.permissions.includes(permission)

            if (!isAdmin && !hasActivePermission) {
              return null
            }

            return (
              <div
                key={permission}
                className="flex items-center justify-between"
              >
                <div className="flex flex-col">
                  <Typography>
                    <FormattedMessage
                      {...teamPermissionsMessages[permission].title}
                    />
                  </Typography>
                  <Typography className="text-neutral-gray-600">
                    <FormattedMessage
                      {...teamPermissionsMessages[permission].description}
                    />
                  </Typography>
                </div>

                <div
                  className={cn(
                    'flex size-5 shrink-0 items-center justify-center rounded-full bg-primary text-white',
                    {
                      'bg-neutral-gray-300': !hasActivePermission,
                    },
                  )}
                >
                  {hasActivePermission ? (
                    <Check className="size-4" />
                  ) : (
                    <X className="size-4" />
                  )}
                </div>
              </div>
            )
          })
        : Array.from({ length: 5 }).map((_, index) => {
            return (
              <div key={index} className="flex items-center justify-between">
                <div className="flex flex-col gap-1">
                  <Skeleton className="h-[21px] w-36" />
                  <Skeleton className="h-[21px] w-48" />
                </div>
                <Skeleton className="size-5 rounded-full" />
              </div>
            )
          })}
    </Widget>
  )
}
