import { api } from '@/lib/api'
import { Role } from '@/types/roles'

import { TeamMember } from '../types'

type EditTeamMemberRequest = {
  id: string
  role: Role
  firstName: string
  lastName: string
  secondLastName?: string
  userTitle?: string
  otp?: string
}

export function editTeamMemberDetails(data: EditTeamMemberRequest) {
  const { id, ...rest } = data

  return api.put<TeamMember>(`/v1/me/business-users/${id}`, rest)
}
