import { Navigate, RouteObject } from 'react-router-dom'

import {
  ACCEPT_INVITE_ROUTE,
  FORGOT_PASSWORD_ROUTE,
  ROOT_ROUTE,
  SIGNIN_ROUTE,
  SIGNUP_ROUTE,
} from '@/constants/paths'

import { AcceptInvite } from './AcceptInvite'
import { ForgetPassword } from './ForgetPassword'
import { Layout } from './Layout'
import { SignIn } from './SignIn'
import { SignUp } from './SignUp'

export const loginRoutes: RouteObject[] = [
  {
    path: ROOT_ROUTE,
    element: <Navigate to={SIGNIN_ROUTE} replace />,
  },
  {
    element: <Layout />,
    children: [
      {
        path: SIGNIN_ROUTE,
        element: <SignIn />,
      },
      {
        path: SIGNUP_ROUTE,
        element: <SignUp />,
      },
      {
        path: FORGOT_PASSWORD_ROUTE,
        element: <ForgetPassword />,
      },
      {
        path: ACCEPT_INVITE_ROUTE,
        element: <AcceptInvite />,
      },
    ],
  },
]
