import { UseFormReturn } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'

import { OptionalTag, Widget } from '@/shared/components'
import {
  AnimatedFormLabel,
  FormControl,
  FormField,
  FormItem,
  Input,
} from '@/shared/ui'

type IndividualDetailsFieldsFormPart = {
  firstName: string
  lastName: string
  secondLastName?: string
  nickname: string
}

type Props<T extends IndividualDetailsFieldsFormPart> = {
  form: T extends IndividualDetailsFieldsFormPart ? UseFormReturn<T> : never
  layout?: 'create' | 'edit'
}

export const IndividualFields = <T extends IndividualDetailsFieldsFormPart>({
  layout,
  form,
}: Props<T>) => {
  const intl = useIntl()

  return (
    <Widget
      title={
        <FormattedMessage
          id="recipient.recipientDetails"
          defaultMessage="Recipient details"
        />
      }
      variant="form"
    >
      <FormField
        control={form.control}
        name="firstName"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Input
                disabled={layout === 'edit'}
                autoComplete="given-name"
                placeholder={intl.formatMessage({
                  defaultMessage: 'First name',
                  id: 'label.firstName',
                })}
                {...field}
              />
            </FormControl>
            <AnimatedFormLabel>
              <FormattedMessage
                defaultMessage="First name"
                id="label.firstName"
              />
            </AnimatedFormLabel>
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="lastName"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Input
                disabled={layout === 'edit'}
                autoComplete="family-name"
                placeholder={intl.formatMessage({
                  defaultMessage: 'Last name',
                  id: 'label.lastName',
                })}
                {...field}
              />
            </FormControl>
            <AnimatedFormLabel>
              <FormattedMessage
                defaultMessage="Last name"
                id="label.lastName"
              />
            </AnimatedFormLabel>
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="secondLastName"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Input
                disabled={layout === 'edit'}
                autoComplete="additional-name"
                placeholder={intl.formatMessage({
                  defaultMessage: 'Second last name',
                  id: 'label.secondLastName',
                })}
                {...field}
              />
            </FormControl>
            <AnimatedFormLabel>
              <FormattedMessage
                defaultMessage="Second last name"
                id="label.secondLastName"
              />
            </AnimatedFormLabel>

            {field.value === '' && <OptionalTag />}
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="nickname"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Input
                placeholder={intl.formatMessage({
                  id: 'label.recipientNickname',
                  defaultMessage: 'Recipient nickname',
                })}
                {...field}
              />
            </FormControl>
            <AnimatedFormLabel>
              <FormattedMessage
                id="label.recipientNickname"
                defaultMessage="Recipient nickname"
              />
            </AnimatedFormLabel>
          </FormItem>
        )}
      />
    </Widget>
  )
}
