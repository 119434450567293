import { api } from '@/lib/api'

import { Recipient } from '../types'

export function getRecipients(params: Record<string, string> = {}) {
  const searchParams = new URLSearchParams(params)

  const searchQuery = searchParams.size > 0 ? '?' + searchParams.toString() : ''

  return api.get<Recipient[]>(`/v1/me/beneficiaries/search${searchQuery}`)
}
