import { UseFormReturn } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'

import { Widget } from '@/shared/components'
import {
  AnimatedFormLabel,
  FormControl,
  FormField,
  FormItem,
  Input,
} from '@/shared/ui'

type BusinessDetailsFieldsFormPart = {
  legalName: string
  nickname: string
}

type Props<T extends BusinessDetailsFieldsFormPart> = {
  form: T extends BusinessDetailsFieldsFormPart ? UseFormReturn<T> : never
  layout?: 'create' | 'edit'
}

export const BusinessFields = <T extends BusinessDetailsFieldsFormPart>({
  form,
  layout,
}: Props<T>) => {
  const intl = useIntl()

  return (
    <Widget
      title={
        <FormattedMessage
          id="recipient.recipientDetails"
          defaultMessage="Recipient details"
        />
      }
      variant="form"
    >
      <FormField
        control={form.control}
        name="legalName"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Input
                disabled={layout === 'edit'}
                placeholder={intl.formatMessage({
                  id: 'label.legalName',
                  defaultMessage: 'Legal Name',
                })}
                {...field}
              />
            </FormControl>
            <AnimatedFormLabel>
              <FormattedMessage
                id="label.legalName"
                defaultMessage="Legal Name"
              />
            </AnimatedFormLabel>
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="nickname"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Input
                placeholder={intl.formatMessage({
                  id: 'label.recipientNickname',
                  defaultMessage: 'Recipient nickname',
                })}
                {...field}
              />
            </FormControl>
            <AnimatedFormLabel>
              <FormattedMessage
                id="label.recipientNickname"
                defaultMessage="Recipient nickname"
              />
            </AnimatedFormLabel>
          </FormItem>
        )}
      />
    </Widget>
  )
}
