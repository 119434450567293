import { useIntl } from 'react-intl'

import { getOnboardingAddress } from '@/lib/address'
import { Typography } from '@/shared/ui'

import { useDocumentsList } from '../../hooks/useDocumentsList'
import { BusinessAddressStep, OnboardingStepName } from '../../types'
import { StepDetails } from '../StepDetails'

type Props = {
  stepDetails?: BusinessAddressStep['stepDetails']
}

export const BusinessAddressReview = ({ stepDetails }: Props) => {
  const intl = useIntl()

  const documentKeys: Record<string, string> = {
    PROOF_OF_ADDRESS: intl.formatMessage({
      id: 'onboarding.step.businessAddress.proofOfAddress',
      defaultMessage: 'Proof of address',
    }),
  }

  const documentsDetails = useDocumentsList(
    stepDetails?.documents ?? [],
    documentKeys,
  )

  if (!stepDetails) {
    return null
  }

  const { documents, ...fields } = stepDetails

  const mappedKeys: Record<keyof typeof fields, string | undefined> = {
    addressStreet: intl.formatMessage({
      id: 'label.address',
      defaultMessage: 'Address',
    }),
    addressStreetNumber: undefined,
    addressDistrict: undefined,
    addressCity: undefined,
    addressState: undefined,
    addressPostCode: undefined,
    addressCountry: undefined,
  }

  const mappedValues: Record<keyof typeof fields, React.ReactNode> = {
    addressStreet: (
      <Typography className="whitespace-break-spaces text-right">
        {getOnboardingAddress(fields, intl)}
      </Typography>
    ),
    addressStreetNumber: undefined,
    addressDistrict: undefined,
    addressCity: undefined,
    addressState: undefined,
    addressPostCode: undefined,
    addressCountry: undefined,
  }

  const details = Object.entries(fields).map(([key]) => {
    return {
      key: mappedKeys[key as keyof typeof mappedKeys],
      value: mappedValues[key as keyof typeof mappedValues],
    }
  })

  return (
    <StepDetails
      step={OnboardingStepName.BUSINESS_ADDRESS}
      title={intl.formatMessage({
        id: 'onboarding.step.businessAddress',
        defaultMessage: 'Business address',
      })}
      details={[...details, ...documentsDetails]}
    />
  )
}
