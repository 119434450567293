import { forwardRef, Ref, type SVGProps } from 'react'

const SvgPencil = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill={props.color || `currentColor`}
      d="M12.794 7.959a.75.75 0 1 0-1.17-.938zm-6.483 6.89.568.49.017-.021zm-.171.418-.749-.05v.015zM6 18.29l-.75-.035a1 1 0 0 0 .004.112zm.787.687.025.75a1 1 0 0 0 .148-.02zm3-.711.173.73.012-.003zm.385-.244.579.477.006-.008zm6.625-7.063a.75.75 0 0 0-1.17-.938zm-5.17-3.938a.75.75 0 0 0 1.17.938zM13.8 5.51l.585.469.029-.038zm1.629-.32.48-.577a1 1 0 0 0-.075-.055zm2.237 1.862.53-.53-.05-.047zm.337.82.75.005zm-.346.818-.525-.536a1 1 0 0 0-.06.066zm-2.03 1.33a.75.75 0 1 0 1.17.94zM12.954 7.38a.75.75 0 1 0-1.484.222zm3.359 3.854a.75.75 0 1 0-.202-1.486zm-4.69-4.212L5.727 14.38l1.17.938 5.898-7.36zm-5.88 7.338c-.207.24-.33.542-.351.859l1.496.098a.04.04 0 0 1-.009.022zm-.352.873-.14 3.023 1.498.07.14-3.023zm-.137 3.135a1.516 1.516 0 0 0 1.558 1.36l-.05-1.5h-.005l-.006-.004-.003-.005-.002-.005zm1.706 1.34 3-.712-.346-1.459-3 .711zm3.012-.714c.305-.078.578-.25.779-.494l-1.158-.954a.02.02 0 0 1 .01-.006zm.785-.502 6.04-7.532-1.17-.938-6.04 7.532zm2.04-10.532 1.588-1.98-1.17-.938-1.588 1.98zm1.617-2.018a.45.45 0 0 1 .61-.12l.81-1.263a1.95 1.95 0 0 0-2.647.52zm.535-.175 2.237 1.862.96-1.153-2.237-1.862zm2.186 1.816c.076.076.118.179.118.287l1.5.008a1.9 1.9 0 0 0-.556-1.355zm.118.287c0 .107-.044.21-.121.285l1.05 1.071c.363-.355.568-.84.57-1.348zm-.18.351-1.446 1.8 1.17.94 1.445-1.8zM11.47 7.6a4.31 4.31 0 0 0 4.843 3.633l-.202-1.486a2.81 2.81 0 0 1-3.157-2.368z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgPencil)
export default ForwardRef
