import { api } from '@/lib/api'

export type CreatePasswordResponse = {
  refreshToken: string
}

export type CreatePasswordRequest = {
  signupId: string
  email: string
  password: string
  verificationCode: string
}

export function createPassword(data: CreatePasswordRequest) {
  return api.post<CreatePasswordResponse>('/v1/verification', data)
}
