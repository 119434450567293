import { ColumnDef } from '@tanstack/react-table'
import { FormattedMessage } from 'react-intl'

import { DataTable } from '@/shared/ui'

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
  isLoading: boolean
  onRowClick?: (row: TData) => void
  loaderOptions?: {
    rows: number
  }
}

export const TransactionsTable = <TData, TValue>({
  columns,
  data,
  isLoading,
  loaderOptions,
  onRowClick,
}: DataTableProps<TData, TValue>) => {
  return (
    <DataTable
      columns={columns}
      data={data}
      isLoading={isLoading}
      onRowClick={onRowClick}
      loaderOptions={loaderOptions}
      emptyState={{
        title: (
          <FormattedMessage
            id="transactions.empty.search"
            defaultMessage="No transactions found"
          />
        ),
        description: (
          <FormattedMessage
            id="transactions.empty.search.description"
            defaultMessage="There were no transactions that matched your search criteria"
          />
        ),
      }}
    />
  )
}
