import { api } from '@/lib/api'

export type AcceptInvitationRequest = {
  identityId: string
  businessId: string
  otp: string
  rawPassword: string
}

export type AcceptInvitationResponse = {
  refreshToken: string
}

export function acceptInvitation(data: AcceptInvitationRequest) {
  return api.post<AcceptInvitationResponse>(
    '/v1/invitation-verifications',
    data,
  )
}
