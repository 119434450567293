import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps'
import { FormattedMessage } from 'react-intl'

import { useDebounce } from '@/hooks/useDebounce'
import {
  GooglePlacesParsedAddress,
  mapGooglePlacesAddress,
} from '@/lib/address'
import { useLocale } from '@/providers/LocaleProvider'
import {
  AnimatedFormLabel,
  Button,
  Command,
  CommandList,
  FormControl,
  FormItem,
  Input,
  Skeleton,
  Typography,
} from '@/shared/ui'
import { ISO2CountryCode } from '@/types/country'

const DEBOUNCE_DELAY = 500

type Props = {
  placeholder: string
  onManualClick: () => void
  onGoogleAddressSelected: (address: GooglePlacesParsedAddress) => void
  restrictedCountries?: ISO2CountryCode[]
} & React.InputHTMLAttributes<HTMLInputElement>

export const AddressAutocompleteField = ({
  onChange,
  value,
  placeholder,
  onManualClick,
  onGoogleAddressSelected,
  restrictedCountries = [],
  ...inputProps
}: Props) => {
  const map = useMap()
  const { locale } = useLocale()
  const places = useMapsLibrary('places')

  const [sessionToken, setSessionToken] =
    useState<google.maps.places.AutocompleteSessionToken>()

  const [autocompleteService, setAutocompleteService] =
    useState<google.maps.places.AutocompleteService | null>(null)

  const [placesService, setPlacesService] =
    useState<google.maps.places.PlacesService | null>(null)

  const [predictionResults, setPredictionResults] = useState<
    Array<google.maps.places.AutocompletePrediction>
  >([])

  const [isPlacePredictionsLoading, setIsPlacePredictionsLoading] =
    useState(false)

  useEffect(() => {
    if (!places) return

    setAutocompleteService(new places.AutocompleteService())
    setPlacesService(new places.PlacesService(document.createElement('div')))
    setSessionToken(new places.AutocompleteSessionToken())

    return () => setAutocompleteService(null)
  }, [map, places])

  const fetchPredictions = useCallback(
    async (inputValue: string) => {
      if (!autocompleteService || !inputValue) {
        setPredictionResults([])
        setIsPlacePredictionsLoading(false)
        return
      }

      const response = await autocompleteService.getPlacePredictions({
        input: inputValue,
        sessionToken,
        componentRestrictions: { country: restrictedCountries },
      })

      setPredictionResults(response.predictions)

      setIsPlacePredictionsLoading(false)
    },
    [autocompleteService, restrictedCountries, sessionToken],
  )

  const handleSuggestionClick = useCallback(
    (placeId: string) => {
      if (!places) return

      const detailRequestOptions = {
        placeId,
        fields: ['address_components'],
        sessionToken,
        locale,
      }

      const detailsRequestCallback = (
        placeDetails: google.maps.places.PlaceResult | null,
      ) => {
        setPredictionResults([])
        setSessionToken(new places.AutocompleteSessionToken())

        if (!placeDetails?.address_components) {
          return
        }

        const address = mapGooglePlacesAddress(placeDetails.address_components)

        onGoogleAddressSelected(address)
      }

      placesService?.getDetails(detailRequestOptions, detailsRequestCallback)
    },
    [locale, onGoogleAddressSelected, places, placesService, sessionToken],
  )

  const debouncedFetchPredictions = useDebounce(
    fetchPredictions,
    DEBOUNCE_DELAY,
  )

  const onInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsPlacePredictionsLoading(true)
      onChange?.(event)
      debouncedFetchPredictions(event.target.value)
    },
    [debouncedFetchPredictions, onChange],
  )

  return (
    <FormItem>
      <Command>
        <FormControl>
          <Input
            {...inputProps}
            placeholder={placeholder}
            onChange={onInputChange}
          />
        </FormControl>
        <AnimatedFormLabel>{placeholder}</AnimatedFormLabel>
        {value && value !== '' && (
          <CommandList className="z-50 px-2 pb-2">
            <Button
              variant="ghost"
              onClick={onManualClick}
              type="button"
              className="font-bold text-primary"
            >
              <FormattedMessage
                defaultMessage="Enter address manually"
                id="action.enterAddressManually"
              />
            </Button>

            {isPlacePredictionsLoading ? (
              <div className="flex flex-col gap-3">
                <Skeleton className="h-4 w-1/2" />
                <Skeleton className="h-4 w-1/2" />
                <Skeleton className="h-4 w-1/2" />
              </div>
            ) : predictionResults.length > 0 ? (
              predictionResults?.map((prediction) => {
                const text = [
                  {
                    text: prediction.structured_formatting.main_text + ', ',
                  },
                  {
                    text: prediction.structured_formatting.secondary_text,
                    className: 'text-neutral-gray-600',
                  },
                ]

                return (
                  <Button
                    type="button"
                    variant="ghost"
                    key={prediction.description}
                    onClick={() => handleSuggestionClick(prediction.place_id)}
                    className="font-normal"
                  >
                    {
                      <Typography>
                        {text.map((text, index) => (
                          <span key={index} className={text.className}>
                            {text.text}
                          </span>
                        ))}
                      </Typography>
                    }
                  </Button>
                )
              })
            ) : (
              <div className="flex px-2">
                <Typography>
                  <FormattedMessage
                    defaultMessage="No results found"
                    id="message.noResultsFound"
                  />
                </Typography>
              </div>
            )}
          </CommandList>
        )}
      </Command>
    </FormItem>
  )
}
