import { FormattedMessage } from 'react-intl'

import { Typography } from '@/shared/ui'

import { TeamMember } from '../../types'

type Props = {
  member: TeamMember
}

export const TeamRoleCell = ({ member }: Props) => {
  return (
    <div className="flex items-center">
      <Typography>
        <FormattedMessage
          id="team.role.label"
          defaultMessage="{role, select, ADMIN {Admin} PAYMENT_OPS {Payment Ops} CARD_USER {Card User} READ_ONLY {Read Only} other {}}"
          values={{ role: member.role }}
        />
      </Typography>
    </div>
  )
}
