import { useCallback, useEffect, useMemo, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery } from '@tanstack/react-query'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { generatePath, useNavigate } from 'react-router-dom'
import { z } from 'zod'

import { googlePlacesFields } from '@/constants/google'
import { ONBOARDING_STEP } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { useErrorToast } from '@/hooks/useErrorToast'
import { GooglePlacesParsedAddress } from '@/lib/address'
import { getCountryISO3byISO2, isCountryInISO3Format } from '@/lib/country'
import { queryClient } from '@/lib/queryClient'
import { AddressAutocompleteField, GoBackButton } from '@/shared/components'
import {
  Button,
  Form,
  FormField,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'

import { getOnboardingStepDetails, submitOnboardingStep } from '../api'
import { AddressFields } from '../components/AddressFields'
import { OnboardingLoader } from '../components/OnboardingLoader'
import { onboardingAddressFieldsOrder } from '../constants'
import {
  BusinessAddressStep as BusinessAddressStepType,
  OnboardingStep,
  OnboardingStepConfig,
  OnboardingStepName,
} from '../types'
import { getPreviousStep } from '../utils/getPreviousStep'

const businessAddressStepSchema = z.object({
  GOOGLE_PLACES_ADDRESS: z.string(),
  ADDRESS_STREET: z.string(),
  ADDRESS_STREET_NUMBER: z.string(),
  ADDRESS_DISTRICT: z.string(),
  ADDRESS_CITY: z.string(),
  ADDRESS_STATE: z.string(),
  ADDRESS_POST_CODE: z.string(),
  ADDRESS_COUNTRY: z.string(),

  PROOF_OF_ADDRESS: z.any(),
})

export type BusinessAddressStepSchema = z.infer<
  typeof businessAddressStepSchema
>

const BUSINESS_ADDRESS_FORM_ID = 'business-address-form-id'

type Props = {
  config?: OnboardingStepConfig
  steps: OnboardingStep[]
}

export const BusinessAddressStep = ({ config, steps }: Props) => {
  const notifyError = useErrorToast()
  const [inputView, setInputView] = useState<'manual' | 'auto'>('auto')
  const navigate = useNavigate()

  const intl = useIntl()

  const { mutateAsync, isPending, isSuccess } = useMutation({
    mutationFn: submitOnboardingStep,
  })

  const stepDetailsQuery = useQuery({
    queryKey: [queryKeys.getOnboardingStepDetails, config?.name],
    queryFn: () => getOnboardingStepDetails(config?.name ?? ''),
    select: (data) => data.data,
    enabled: !!config?.name,
  })

  const stepDetails = useMemo(
    () =>
      stepDetailsQuery.data
        ?.stepDetails as BusinessAddressStepType['stepDetails'],
    [stepDetailsQuery.data],
  )

  const form = useForm<BusinessAddressStepSchema>({
    mode: 'onChange',
    resolver: zodResolver(businessAddressStepSchema),
    values: {
      GOOGLE_PLACES_ADDRESS: '',
      ADDRESS_STREET: stepDetails?.addressStreet ?? '',
      ADDRESS_STREET_NUMBER: stepDetails?.addressStreetNumber ?? '',
      ADDRESS_DISTRICT: stepDetails?.addressDistrict ?? '',
      ADDRESS_CITY: stepDetails?.addressCity ?? '',
      ADDRESS_STATE: stepDetails?.addressState ?? '',
      ADDRESS_POST_CODE: stepDetails?.addressPostCode ?? '',
      ADDRESS_COUNTRY: stepDetails?.addressCountry ?? '',

      PROOF_OF_ADDRESS: [new File([], '')],
    },
  })

  const onSubmit: SubmitHandler<BusinessAddressStepSchema> = async (data) => {
    const documents = stepDetailsQuery.data?.stepDetails.documents ?? []

    if (!config?.name) {
      return
    }

    const {
      ADDRESS_STREET,
      ADDRESS_STREET_NUMBER,
      ADDRESS_DISTRICT,
      ADDRESS_CITY,
      ADDRESS_STATE,
      ADDRESS_POST_CODE,
      ADDRESS_COUNTRY,
    } = data

    try {
      await mutateAsync({
        stepName: OnboardingStepName.BUSINESS_ADDRESS,
        stepDetails: {
          addressStreet: ADDRESS_STREET,
          addressStreetNumber: ADDRESS_STREET_NUMBER,
          addressDistrict: ADDRESS_DISTRICT,
          addressCity: ADDRESS_CITY,
          addressState: ADDRESS_STATE,
          addressPostCode: ADDRESS_POST_CODE,
          addressCountry: getCountryISO3byISO2(ADDRESS_COUNTRY),
          documents,
        },
      })

      const currentStepIndex = steps.findIndex(
        (step) => step.name === config.name,
      )

      const nextStep = steps[currentStepIndex + 1]

      await queryClient.refetchQueries({
        queryKey: [queryKeys.getOnboardingState],
      })

      await queryClient.invalidateQueries({
        queryKey: [queryKeys.getOnboardingStepDetails, config.name],
      })

      navigate(generatePath(ONBOARDING_STEP, { step: nextStep.name }))
    } catch (error) {
      if (error instanceof Error) {
        notifyError(error)
      }
    }
  }

  const getFilesByField = useCallback(
    (fieldName: string) => {
      return (stepDetailsQuery.data?.stepDetails?.documents ?? []).filter(
        (doc) => doc.documentType === fieldName,
      )
    },
    [stepDetailsQuery.data?.stepDetails?.documents],
  )

  const fields = form.watch()

  useEffect(() => {
    if (stepDetails?.addressStreet) {
      setInputView('manual')
    }
  }, [stepDetails?.addressStreet])

  const hasAllRequiredFields = useMemo(() => {
    if (!config?.fields) {
      return false
    }

    const requiredFields = config.fields.filter((field) => field.required)
    const requiredDocuments = config.documents.filter((doc) => doc.required)

    return (
      requiredFields.every(
        (field) => fields[field.name as keyof typeof fields],
      ) &&
      requiredDocuments.every((doc) => getFilesByField(doc.type).length) &&
      isCountryInISO3Format(fields.ADDRESS_COUNTRY)
    )
  }, [config?.documents, config?.fields, fields, getFilesByField])

  if (stepDetailsQuery.isPending) {
    return <OnboardingLoader />
  }

  return (
    <>
      <GoBackButton
        className="hidden md:left-80 md:flex"
        to={generatePath(ONBOARDING_STEP, {
          step: getPreviousStep(steps, config),
        })}
      />

      <SlideInScreen>
        <Typography variant="h3" text="center">
          <FormattedMessage
            defaultMessage="Business address"
            id="onboarding.businessAddress.title"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            defaultMessage="Where is the company's main place of business?"
            id="onboarding.businessAddress.subtitle"
          />
        </Typography>

        <div className="p-6" />

        <Form {...form}>
          <form
            id={BUSINESS_ADDRESS_FORM_ID}
            onSubmit={form.handleSubmit(onSubmit)}
          >
            {inputView === 'auto' ? (
              <FormField
                control={form.control}
                name="GOOGLE_PLACES_ADDRESS"
                render={({ field }) => (
                  <AddressAutocompleteField
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    name={field.name}
                    disabled={field.disabled}
                    placeholder={intl.formatMessage({
                      id: 'label.businessAddress',
                      defaultMessage: 'Business address',
                    })}
                    onManualClick={() => setInputView('manual')}
                    onGoogleAddressSelected={(address) => {
                      onboardingAddressFieldsOrder.forEach((field, index) => {
                        form.setValue(
                          field,
                          address[
                            googlePlacesFields[
                              index
                            ] as keyof GooglePlacesParsedAddress
                          ],
                        )
                      })

                      setInputView('manual')
                    }}
                  />
                )}
              />
            ) : (
              <AddressFields
                form={form}
                isPending={stepDetailsQuery.isPending}
                stepName={config?.name ?? ''}
                getFilesByField={getFilesByField}
              />
            )}
          </form>
        </Form>

        <StickyContainer>
          <Button
            width="full"
            loading={isPending || isSuccess}
            disabled={!hasAllRequiredFields || isPending || isSuccess}
            form={BUSINESS_ADDRESS_FORM_ID}
            onClick={form.handleSubmit(onSubmit)}
            type="submit"
          >
            <FormattedMessage
              defaultMessage="Save & Continue"
              id="action.saveAndContinue"
            />
          </Button>

          {inputView === 'manual' && (
            <>
              <div className="p-1" />
              <Button
                type="button"
                onClick={() => {
                  setInputView('auto')
                  form.setValue('GOOGLE_PLACES_ADDRESS', '')
                }}
                width="full"
                className="text-center font-semibold text-primary"
                variant="ghost"
              >
                <FormattedMessage
                  defaultMessage="Search for my address"
                  id="onboarding.businessAddress.searchForAddress"
                />
              </Button>
            </>
          )}
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
