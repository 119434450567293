import { api } from '@/lib/api'
import {
  ACCOUNTS_FILTER_NAME,
  IDENTITY_FILTER_NAME,
  STATUS_FILTER_NAME,
} from '@/shared/components'

import { Card } from '../types'

type SearchParams = {
  [STATUS_FILTER_NAME]?: string
  [ACCOUNTS_FILTER_NAME]?: string
  [IDENTITY_FILTER_NAME]?: string
}

export function getAllCards(params: SearchParams = {}) {
  const searchParams = new URLSearchParams(params)

  const searchQuery = searchParams.size > 0 ? '?' + searchParams.toString() : ''

  return api.get<Card[]>(`/v1/me/cards/search${searchQuery}`)
}
