import { StatusVariant } from '@/shared/components'

import { ContractorState } from '../types'

export const badgeVariantByContractorState: Record<
  ContractorState,
  StatusVariant
> = {
  [ContractorState.ACTION_NEEDED]: 'warning',
  [ContractorState.WAITING_FOR_CONTRACTOR]: 'neutral',
  [ContractorState.ACTIVE]: 'active',
}
