import { useIntl } from 'react-intl'

import { useCountryCodes } from '@/hooks/useCountryCodes'
import { getCountryNameByISO3 } from '@/lib/country'

import { Typography } from '../ui'

export const CountryName = ({
  countryCode,
}: {
  countryCode?: string | null
}) => {
  const { data: codes } = useCountryCodes()
  const intl = useIntl()

  const country = codes?.find((code) => code.isoCode === countryCode)

  return (
    <div className="flex gap-1">
      <Typography>
        {country
          ? `${country?.flag} ${getCountryNameByISO3(country.isoCode, intl.locale)}`
          : '-'}
      </Typography>
    </div>
  )
}
