import { FormattedMessage, useIntl } from 'react-intl'

import { ContractorDetails } from '@/features/Contractors/types'
import { formatAmount } from '@/lib/money'
import { intersperse } from '@/lib/typography'
import { Widget } from '@/shared/components'
import { Document } from '@/shared/icons/solid'
import { Skeleton, Typography } from '@/shared/ui'

import { ContractorStateBadge } from '../../../components/ContractorStateBadge'

type Props = {
  contractor?: ContractorDetails
}

export const ContractsSection = ({ contractor }: Props) => {
  const intl = useIntl()

  const [latestContract] = contractor?.contracts ?? []

  if (!latestContract) {
    return (
      <Widget title={<Skeleton className="h-[21px] w-36" />}>
        <div className="flex items-center justify-between">
          <Skeleton className="h-[39px] w-36" />

          <Skeleton className="h-[21px] w-36" />
        </div>
      </Widget>
    )
  }

  return (
    <Widget
      title={
        <FormattedMessage defaultMessage="Contracts" id="label.contracts" />
      }
    >
      <div className="flex items-center justify-between">
        <div className="flex gap-2">
          <div className="flex size-9 items-center justify-center rounded-lg bg-neutral-gray-100">
            <Document className="size-5 text-neutral-gray-900" />
          </div>

          <div className="flex flex-col">
            <Typography bold>
              <FormattedMessage
                id="contract.state.label"
                defaultMessage="{state, select, FIXED_RATE {Fixed rate} PAY_AS_YOU_GO {Pay-as-you-go} other {}}"
                values={{ state: latestContract.paymentType }}
              />
            </Typography>
            <Typography variant="body-small" className="text-neutral-gray-600">
              {intersperse([
                latestContract.contractorRole,
                formatAmount(latestContract.paymentDetails),
                intl.formatMessage(
                  {
                    id: 'contractor.paymentFrequency.label',
                    defaultMessage:
                      '{paymentFrequency, select, WEEKLY {Weekly} BIWEEKLY {Biweekly} SEMI_MONTHLY {Semi-monthly} MONTHLY {Monthly} other {}}',
                  },
                  {
                    paymentFrequency: latestContract.paymentDetails.frequency,
                  },
                ),
              ])}
            </Typography>
          </div>
        </div>

        {contractor ? <ContractorStateBadge state={contractor?.state} /> : null}
      </div>
    </Widget>
  )
}
