import { forwardRef, Ref, type SVGProps } from 'react'

const SvgFilter = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill={props.color || `currentColor`}
      d="M18.523 4.226a58.7 58.7 0 0 0-13.046 0 1.373 1.373 0 0 0-.915 2.229l3.769 4.659A7.5 7.5 0 0 1 10 15.83v3.142a.75.75 0 0 0 .306.605l2.771 2.032a.58.58 0 0 0 .923-.468v-5.311a7.5 7.5 0 0 1 1.67-4.717l3.768-4.66a1.373 1.373 0 0 0-.915-2.228"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgFilter)
export default ForwardRef
