import { Currency } from '@/constants/currency'
import { Permission } from '@/types/permissions'
import { Role } from '@/types/roles'

import {
  InvitedTeamMember,
  LimitPeriodType,
  MovementPermission,
  TeamMember,
  TeamMemberPaymentLimit,
  TeamMemberTransferPermission,
  TeamState,
} from '../../types'

export const INVITED_TEAM_MEMBER_MOCK_RESPONSE: InvitedTeamMember = {
  id: '0ceaf1d2-20b6-4de0-8e74-ad514f0df81c',
  businessId: 'c002df21-b54c-4ace-8f54-52b4d3a2a2bb',
  identityId: 'baa98df2-0831-4ac9-954f-346de5b66f41',
  permissions: [Permission.READ_ACCESS],
  state: TeamState.INVITED,
  role: Role.READ_ONLY,
}

export const MOCKED_TEAM_MEMBERS: TeamMember[] = [
  {
    id: 'ab9890c1-8ed5-4f52-a1fd-1039ba5ddce4',
    businessId: 'c002df21-b54c-4ace-8f54-52b4d3a2a2bb',
    identityId: '00283379-242d-4cae-8e12-2d8fd831d9fa',
    email: 'maks@dolarapp.com',
    userTitle: undefined,
    firstName: 'Joe',
    lastName: 'Rogan',
    secondLastName: '',
    state: TeamState.ACTIVE,
    role: Role.ADMIN,

    permissions: [
      Permission.TEAM_MANAGEMENT,
      Permission.CARDS_MANAGEMENT,
      Permission.CARDS_ACCESS,
      Permission.TRANSFERS,
      Permission.READ_ACCESS,
      Permission.REQUEST_APPROVALS,
    ],
  },
  {
    id: '063993e9-93aa-4d56-a13e-5a2cb2855dbb',
    businessId: 'c002df21-b54c-4ace-8f54-52b4d3a2a2bb',
    identityId: '97092bab-95be-4484-98fa-c5012babca60',
    email: 'maks.admin@dolarapp.com',
    userTitle: 'Chief Admin Officer',
    firstName: 'Mark',
    lastName: 'Zuckerberg',
    secondLastName: '',
    state: TeamState.ACTIVE,
    role: Role.ADMIN,
    permissions: [
      Permission.TEAM_MANAGEMENT,
      Permission.CARDS_MANAGEMENT,
      Permission.CARDS_ACCESS,
      Permission.TRANSFERS,
      Permission.READ_ACCESS,
      Permission.REQUEST_APPROVALS,
    ],
  },
  {
    id: '6d089c92-0f42-48d0-b6a3-78e6e45e70af',
    businessId: 'c002df21-b54c-4ace-8f54-52b4d3a2a2bb',
    identityId: 'd890b160-aea3-4ad5-a1d2-79c15c19befc',
    email: 'maks.readonly@dolarapp.com',
    userTitle: 'Read Only ',
    firstName: 'Elon',
    lastName: 'Musk',
    secondLastName: '',
    state: TeamState.INVITED,
    role: Role.READ_ONLY,
    permissions: [Permission.READ_ACCESS],
  },
]

export const MOCKED_TEAM_MEMBER_TRANSFER_PERMISSION: TeamMemberTransferPermission =
  {
    businessId: 'c002df21-b54c-4ace-8f54-52b4d3a2a2bb',
    businessIdentityId: '00283379-242d-4cae-8e12-2d8fd831d9fa',
    businessUserId: 'ab9890c1-8ed5-4f52-a1fd-1039ba5ddce4',
    type: MovementPermission.NO_APPROVAL_REQUIRED,
    limitCurrency: Currency.USDC,
    limitPeriodType: LimitPeriodType.MONTHLY,
    periodTransferLimitAmount: 6000.0,
    singleTransferLimitAmount: 1000.0,
    periodStartDate: '2024-08-01T05:00:00',
    periodResetDate: '2024-09-01T05:00:00',
  }

export const MOCKED_PAYMENT_LIMIT: TeamMemberPaymentLimit = {
  businessUserId: 'b89ff7c0-c562-49ae-aafc-b862ed65bb2b',
  type: MovementPermission.NO_APPROVAL_REQUIRED,
  periodTransferLimit: {
    limitType: LimitPeriodType.MONTHLY,
    limitAmount: 200,
    usedAmount: 3,
    limitCurrency: Currency.USDC,
    periodStartDate: '2024-08-23T05:00:00',
    periodResetDate: '2024-08-24T05:00:00',
  },
  singleTransferLimit: {
    limitAmount: 50,
    limitCurrency: Currency.USDC,
  },
}
