import { forwardRef, Ref, type SVGProps } from 'react'

const SvgExchange = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill={props.color || `currentColor`}
      d="M7.97 12.53a.75.75 0 0 0 1.06-1.06zM5.53 7.97a.75.75 0 0 0-1.06 1.06zM5 7.75a.75.75 0 0 0 0 1.5zm14 1.5a.75.75 0 0 0 0-1.5zM4.47 7.97a.75.75 0 0 0 1.06 1.06zm4.56-2.44a.75.75 0 0 0-1.06-1.06zm7 5.94a.75.75 0 1 0-1.06 1.06zm2.44 4.56a.75.75 0 1 0 1.06-1.06zm.53.22a.75.75 0 0 0 0-1.5zm-14-1.5a.75.75 0 0 0 0 1.5zm14.53 1.28a.75.75 0 1 0-1.06-1.06zm-4.56 2.44a.75.75 0 1 0 1.06 1.06zm-5.94-7-3.5-3.5-1.06 1.06 3.5 3.5zM5 9.25h14v-1.5H5zm.53-.22 3.5-3.5-1.06-1.06-3.5 3.5zm9.44 3.5 3.5 3.5 1.06-1.06-3.5-3.5zM19 14.75H5v1.5h14zm-.53.22-3.5 3.5 1.06 1.06 3.5-3.5z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgExchange)
export default ForwardRef
