import { useEffect, useMemo } from 'react'
import * as Sentry from '@sentry/react'
import { FormattedMessage } from 'react-intl'
import { isRouteErrorResponse, Link, useRouteError } from 'react-router-dom'

import { ROOT_ROUTE } from './constants/paths'
import { useLogout } from './hooks/useLogout'
import { InfoTriangle } from './shared/icons/outline'
import { Button, FullScreen, Typography } from './shared/ui'

export const PageError = () => {
  const error = useRouteError()
  const { logout } = useLogout()

  const errorMessage = useMemo(() => {
    let errorMessage: string

    if (isRouteErrorResponse(error)) {
      errorMessage = error.data.message || error.statusText
    } else if (error instanceof Error) {
      errorMessage = error.message
    } else if (typeof error === 'string') {
      errorMessage = error
    } else {
      console.error(error)
      errorMessage = 'Unknown error'
    }

    return errorMessage
  }, [error])

  useEffect(() => {
    if (errorMessage) {
      Sentry.captureException(errorMessage)
    }
  }, [errorMessage])

  return (
    <FullScreen>
      <div className="max-w-xl text-center">
        <InfoTriangle className="mx-auto h-36 w-36" />
        <Typography text="center" variant="h3">
          <FormattedMessage
            id="page.error.generic"
            defaultMessage="Something went wrong"
          />
        </Typography>

        <div className="p-3" />

        <Typography variant="button" text="center" bold>
          <FormattedMessage
            id="page.error.generic.description"
            defaultMessage="Error: {message}"
            values={{ message: errorMessage }}
          />
        </Typography>

        <div className="p-3" />

        <Typography text="center">
          <FormattedMessage
            id="page.error.generic.refresh"
            defaultMessage="Please try to refresh the page. If the error persists, please contact
            customer support"
          />
        </Typography>

        <div className="p-3" />

        <div className="flex justify-center gap-3">
          <Button asChild>
            <Link to={ROOT_ROUTE}>
              <FormattedMessage id="action.goBack" defaultMessage="Go back" />
            </Link>
          </Button>
          <Button variant="tertiary" onClick={logout}>
            <FormattedMessage id="nav.logout" defaultMessage="Log out" />
          </Button>
        </div>
      </div>
    </FullScreen>
  )
}
