import { useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { UseFormReturn } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'

import { Currency } from '@/constants/currency'
import { queryKeys } from '@/constants/queryKeys'
import { Account, getAccount } from '@/features/Accounts'
import { getTeamMembers } from '@/features/Team'
import { TeamMember, TeamState } from '@/features/Team/types'
import { useBusinessUser } from '@/hooks/useBusinessUser'
import { getFullName } from '@/lib/typography'
import { PaymentLimitType } from '@/types/limits'
import { Role } from '@/types/roles'

import {
  AnimatedFormLabel,
  Card,
  FormControl,
  FormField,
  FormItem,
  Input,
  MoneyInput,
  Skeleton,
} from '../ui'

import { AccountSelect } from './AccountSelect'
import { CurrencyFlag } from './CurrencyFlag'
import { LimitTypeSelect } from './LimitTypeSelect'
import { UserSelect } from './UserSelect'

type Props = {
  isPending?: boolean
  fullName?: string
  form: UseFormReturn<{
    nickname: string
    walletId: string
    requestId: string
    identityId: string
    limitAmount: string
    limitType: PaymentLimitType
  }>
  onSelectCardholder?: (cardholder: string) => void
}

export const CardFields = ({
  isPending,
  fullName,
  form,
  onSelectCardholder,
}: Props) => {
  const intl = useIntl()

  const businessUser = useBusinessUser()

  const [getTeamMembersQuery, walletsQuery] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getTeamMembers],
        queryFn: () => getTeamMembers(),
        select: (data: AxiosResponse<TeamMember[]>) =>
          data.data
            .filter((member) => member.role !== Role.READ_ONLY)
            .filter((member) =>
              [TeamState.ACTIVE, TeamState.INVITED].includes(member.state),
            ),
        enabled: !fullName,
      },
      {
        queryKey: [queryKeys.getAccount],
        queryFn: getAccount,
        select: (data: AxiosResponse<Account>) => data?.data.wallets,
      },
    ],
  })

  return (
    <div className="flex flex-col gap-4">
      <FormField
        control={form.control}
        name="identityId"
        render={({ field }) => {
          const user = getTeamMembersQuery.data?.find(
            (member) => member.identityId === field.value,
          )

          if (getTeamMembersQuery.isFetching || isPending) {
            return <Skeleton className="h-[54px] w-full" />
          }

          if (fullName) {
            return (
              <FormItem className="relative">
                <Input
                  className="bg-neutral-gray-200"
                  placeholder={intl.formatMessage({
                    id: 'label.cardholder',
                    defaultMessage: 'Cardholder',
                  })}
                  value={fullName}
                  disabled
                />

                <AnimatedFormLabel>
                  <FormattedMessage
                    id="label.cardholder"
                    defaultMessage="Cardholder"
                  />
                </AnimatedFormLabel>
              </FormItem>
            )
          }

          return (
            <FormItem>
              <UserSelect
                businessUser={businessUser}
                user={user}
                value={field.value}
                users={getTeamMembersQuery.data}
                showLabel
                label={intl.formatMessage({
                  id: 'label.cardholder',
                  defaultMessage: 'Cardholder',
                })}
                onChange={(value) => {
                  const selectedUser = getTeamMembersQuery.data?.find(
                    (member) => member.identityId === value,
                  )

                  if (selectedUser) {
                    onSelectCardholder?.(getFullName(selectedUser))
                  }

                  field.onChange(value)
                }}
              />
            </FormItem>
          )
        }}
      />

      <FormField
        control={form.control}
        name="nickname"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Input
                placeholder={intl.formatMessage({
                  id: 'card.field.nickname',
                  defaultMessage: 'Card nickname (i.e. Travel expenses)',
                })}
                {...field}
              />
            </FormControl>
            <AnimatedFormLabel>
              <FormattedMessage
                id="card.field.nickname"
                defaultMessage="Card nickname (i.e. Travel expenses)"
              />
            </AnimatedFormLabel>
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="walletId"
        render={({ field }) => {
          return (
            <AccountSelect
              value={field.value}
              accounts={walletsQuery.data ?? []}
              showLabel
              label={intl.formatMessage({
                id: 'card.field.sourceAccount',
                defaultMessage: 'Source account',
              })}
              onChange={field.onChange}
            />
          )
        }}
      />

      <FormField
        control={form.control}
        name="limitType"
        render={({ field }) => (
          <FormItem>
            <LimitTypeSelect value={field.value} onChange={field.onChange} />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="limitAmount"
        render={({ field }) => (
          <FormItem>
            <Card size="input" className="flex">
              <CurrencyFlag />
              <FormControl>
                <MoneyInput
                  currency={Currency.USD}
                  placeholder={intl.formatMessage({
                    defaultMessage: 'Limit amount',
                    id: 'label.limitAmount',
                  })}
                  className="text-right"
                  value={field.value}
                  onChange={field.onChange}
                />
              </FormControl>

              <AnimatedFormLabel align="end">
                <FormattedMessage
                  defaultMessage="Limit amount"
                  id="label.limitAmount"
                />
              </AnimatedFormLabel>
            </Card>
          </FormItem>
        )}
      />
    </div>
  )
}
