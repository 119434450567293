import { cn } from '@/lib/utils'

type Props = {
  className?: string
}

export const LogoIcon = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 51 38"
      fill="none"
      className={cn('w-10 h-10', className)}
    >
      <path
        d="M50.8762 1.35892C50.8762 0.575734 50.6442 0.0883789 49.4956 0.0883789C40.3359 0.0883789 30.9499 2.19994 26.2536 4.21763V7.73721C30.27 5.94329 36.1017 4.50777 42.1975 3.82983C42.3834 5.00866 42.9007 6.10946 43.6884 7.00238C44.8993 8.38955 46.6627 8.78778 47.5793 8.89871C47.5793 13.54 47.5878 19.0564 47.5925 23.4293C46.7438 23.5156 45.1756 23.8873 43.712 25.373C42.1588 26.9508 41.9853 29.0367 41.9843 29.8787C30.8613 31.6954 25.1692 35.0547 24.393 34.2223C23.2708 35.324 22.1071 36.4874 22.7541 37.3038C24.5156 39.5282 29.6316 33.8354 49.6257 32.3221C50.5622 32.251 50.9016 31.9429 50.9016 31.0895C50.9016 31.0857 50.8762 8.25396 50.8762 1.35892Z"
        fill="#22D081"
      />
      <path
        d="M25.8928 0.970886C24.9215 -0.0901084 18.8851 -1.73233 0.877001 5.53155C0.405485 5.72118 0 6.21992 0 6.77554C0 6.77554 0.0179121 27.0473 0.0179121 35.3513C0.0179121 36.4663 0.763842 36.7185 1.75497 36.4037C7.47162 34.5861 15.0121 31.478 20.8551 31.1215C24.2255 30.9158 25.6297 31.1756 25.9767 31.6999C25.9822 31.7084 25.9903 31.7148 25.9998 31.7182C26.0092 31.7216 26.0195 31.7218 26.0291 31.7188C26.0387 31.7158 26.0471 31.7098 26.053 31.7016C26.0588 31.6934 26.0618 31.6835 26.0616 31.6734V29.1835C26.0616 27.9575 23.8266 27.6427 20.4232 27.9281C16.7332 28.2363 12.2688 29.4385 9.12194 30.4123C8.99383 29.7404 8.71622 29.1064 8.30991 28.5576C7.9036 28.0089 7.37912 27.5596 6.77569 27.2435C5.71896 26.6662 4.50959 26.4342 3.31569 26.5798C3.31569 21.8618 3.3091 16.6763 3.30627 12.7926C4.25402 12.4133 5.94675 11.56 6.92939 10.0657C7.65238 8.94535 8.08278 7.65985 8.1808 6.32801C12.7969 4.73035 18.2099 3.19907 22.0669 3.19907C25.3468 3.19907 25.9183 3.79357 26.253 4.21455C26.253 3.11468 26.3945 1.51892 25.8928 0.970886Z"
        fill="#22D081"
      />
      <path
        d="M26.2655 10.7001C26.2655 10.7001 26.2202 9.61444 26.2193 8.30503C26.2193 7.04682 25.1216 6.61066 24.2464 6.55851C20.1216 6.31104 19.0758 11.3136 19.1946 15.3357C19.3021 18.9776 20.4262 20.9829 22.4302 22.4734C24.4586 23.982 26.0901 23.2377 26.2023 27.9728C29.7509 27.9519 32.6866 23.5449 32.6451 18.5632C32.6121 14.0651 29.8613 10.6944 26.2655 10.7001Z"
        fill="#22D081"
      />
    </svg>
  )
}
