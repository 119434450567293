import { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import { Card, Typography } from '@/shared/ui'

import { CSVTransaction } from '../types'

import { CSVFailedTransactionRow } from './CSVFailedTransactionRow'

type Props = {
  transactions: CSVTransaction[]
  onSelect?: (idx: string[]) => void
  failed?: boolean
}

export const CSVFailedTransactionsList = ({ transactions }: Props) => {
  if (transactions.length === 0) {
    return (
      <Card size="medium">
        <FormattedMessage
          id="bulkPayments.selectTransactionsTab.invalid.noTransactions"
          defaultMessage="All recipients were imported successfully!"
        />
      </Card>
    )
  }

  return (
    <div className="flex flex-col">
      <div className="flex justify-between px-6">
        <Typography className="text-neutral-gray-600">
          <FormattedMessage
            id="bulkPayments.selectTransactionsTab.total"
            defaultMessage="Recipients • {count} total"
            values={{ count: transactions.length }}
          />
        </Typography>
        <Typography className="text-neutral-gray-600">
          <FormattedMessage id="label.error" defaultMessage="Error" />
        </Typography>
      </div>
      <div className="p-1" />

      <Card>
        {transactions.map((transaction, index) => (
          <Fragment key={transaction.id}>
            <CSVFailedTransactionRow transaction={transaction} />
            {index === transactions.length - 1 ? null : (
              <div className="border-b border-neutral-gray-300" />
            )}
          </Fragment>
        ))}
      </Card>
    </div>
  )
}
