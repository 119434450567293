import { useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { useIntl } from 'react-intl'
import { generatePath, Link } from 'react-router-dom'

import { ONBOARDING_CREATE_OWNER } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { queryClient } from '@/lib/queryClient'
import { X } from '@/shared/icons/outline'
import { User } from '@/shared/icons/solid'
import { Button, Spinner, Typography } from '@/shared/ui'

import { deleteBeneficialOwner } from '../api'
import {
  CompanyOwner,
  CompanyOwnershipStep,
  OnboardingStepConfig,
} from '../types'

type Props = {
  config?: OnboardingStepConfig
  owner: CompanyOwner
}

export const BeneficialOwner = ({ config, owner }: Props) => {
  const intl = useIntl()

  const { mutateAsync, isPending } = useMutation({
    mutationFn: deleteBeneficialOwner,
  })

  return (
    <div className="flex w-full items-center justify-between rounded-lg bg-neutral-gray-100 p-2">
      <Button
        variant="ghost"
        size="inline"
        type="button"
        key={`${owner.firstName}-${owner.lastName}`}
        className="w-[90%] justify-start"
        asChild
      >
        <Link to={generatePath(ONBOARDING_CREATE_OWNER, { ownerId: owner.id })}>
          <span className="flex items-center gap-2">
            <User className="h-4 w-4" />
            <Typography>{`${owner.firstName} ${owner.lastName}`}</Typography>
          </span>
        </Link>
      </Button>
      <Button
        type="button"
        size="inline"
        aria-label={intl.formatMessage({
          defaultMessage: 'Delete owner',
          id: 'action.deleteOwner',
        })}
        onClick={async (e) => {
          e.stopPropagation()

          await mutateAsync({ ownerId: owner.id })

          queryClient.setQueryData<AxiosResponse<CompanyOwnershipStep>>(
            [queryKeys.getOnboardingStepDetails, config?.name],
            (prev) => {
              if (!prev) {
                return prev
              }

              return {
                ...prev,
                data: {
                  ...prev.data,
                  stepDetails: {
                    ...prev.data.stepDetails,
                    beneficialOwners:
                      prev.data.stepDetails.beneficialOwners?.filter(
                        (o) => o.id !== owner.id,
                      ),
                  },
                },
              }
            },
          )
        }}
        variant="ghost"
      >
        {isPending ? <Spinner className="size-5" /> : <X className="size-4" />}
      </Button>
    </div>
  )
}
