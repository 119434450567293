import { FormattedMessage } from 'react-intl'

import { getImage } from '@/lib/images'
import { cn } from '@/lib/utils'
import { Cloud } from '@/shared/icons/solid'
import { Typography } from '@/shared/ui'

import { Card, CardState } from '../../types'

type Props = {
  isFrozen: boolean
  card?: Card
}

export const CardFront = ({ isFrozen, card }: Props) => {
  return (
    <div
      className={cn(
        'absolute h-full w-full rounded-xl bg-flip-card-front bg-cover p-5 text-white shadow-card transition-all backface-hidden',
        {
          'bg-flip-card-frozen': isFrozen,
          'bg-flip-card-terminated': card?.state === CardState.TERMINATED,
        },
      )}
    >
      {card?.type === 'VIRTUAL' ? (
        <div className="flex items-center justify-end gap-1">
          <Cloud className="size-4" />

          <Typography className="uppercase" bold>
            <FormattedMessage id="label.virtual" defaultMessage="Virtual" />
          </Typography>
        </div>
      ) : (
        <div className="flex items-center justify-end gap-1">
          <img
            src={getImage({
              name: 'card-chip',
              category: 'cards',
            })}
            alt=""
            aria-hidden
            className="w-4"
          />

          <Typography className="uppercase" bold>
            <FormattedMessage id="label.physical" defaultMessage="Physical" />
          </Typography>
        </div>
      )}

      <div className="flex h-[calc(100%-21px)] flex-col justify-end">
        <Typography
          className={cn(
            'flex items-center gap-2 font-messina-variable font-semibold tracking-widest',
            {
              'text-neutral-gray-800': isFrozen,
            },
          )}
        >
          **** **** **** {card?.lastFourDigits}
        </Typography>
      </div>
    </div>
  )
}
