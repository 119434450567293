import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { FormattedMessage } from 'react-intl'

import { getIdentity } from '@/api'
import { queryKeys } from '@/constants/queryKeys'
import { getFullName } from '@/lib/typography'
import { Badge, Typography } from '@/shared/ui'

import { OnboardingMember } from '../../types'

type Props = {
  member: OnboardingMember
}

export const KeyIndividualCell = ({ member }: Props) => {
  const userQuery = useQuery({
    queryKey: [queryKeys.getIdentity],
    queryFn: getIdentity,
    select: (data) => data.data,
  })

  const isUser = useMemo(
    () => member.email === userQuery.data?.email,
    [member.email, userQuery.data?.email],
  )

  return (
    <div className="flex flex-col justify-center">
      <div className="flex gap-1">
        <Typography bold>{getFullName(member)}</Typography>
        {isUser && (
          <Badge className="capitalize" variant="neutral">
            <Typography bold variant="body-small">
              <FormattedMessage id="label.you" defaultMessage="You" />
            </Typography>
          </Badge>
        )}
      </div>
      <Typography className="text-neutral-gray-600">{member.email}</Typography>
    </div>
  )
}
