import { formatDate } from 'date-fns'

import { Contractor } from '@/features/Contractors/types'
import { Typography } from '@/shared/ui'

type Props = {
  contractor: Contractor
}

export const LastPaymentDateCell = ({ contractor }: Props) => {
  return (
    <div className="flex items-center">
      <Typography>
        {contractor.lastPaymentDate
          ? formatDate(contractor.lastPaymentDate, 'dd MMM. yyyy')
          : '-'}
      </Typography>
    </div>
  )
}
