import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'
import { toast } from 'sonner'

import { useErrorToast } from '@/hooks/useErrorToast'
import { getFullName } from '@/lib/typography'
import { ChevronRight } from '@/shared/icons/outline'
import { Button, Card, SlideInScreen, Spinner, Typography } from '@/shared/ui'

import { sendIdentityValidationEmail } from '../api/sendIdentityValidationEmail'
import { OnboardingMember } from '../types'

import { CopyIdentityLinkDialog } from './CopyIdentityLinkDialog'

type Props = {
  link: string
  member?: OnboardingMember
}

export const UploadDocsScreen = ({ link, member }: Props) => {
  const [openDialog, setOpenDialog] = useState(false)
  const intl = useIntl()

  const notifyError = useErrorToast()

  const { mutateAsync, isPending } = useMutation({
    mutationFn: sendIdentityValidationEmail,
  })

  const onSendEmail = async () => {
    if (!member?.email) {
      return
    }

    try {
      await mutateAsync({
        email: member?.email,
      })

      toast.success(
        intl.formatMessage({
          defaultMessage: 'Email sent successfully',
          id: 'onboarding.identityValidationScreen.emailSent',
        }),
      )
    } catch (error) {
      if (error instanceof Error) {
        notifyError(error)
      }
    }
  }

  return (
    <SlideInScreen className="h-screen">
      <Typography variant="h3" text="center">
        <FormattedMessage
          defaultMessage="{fullName} ID Validation"
          id="onboarding.identityValidationScreen.upload.title"
          values={{
            fullName: getFullName(member),
          }}
        />
      </Typography>

      <div className="p-2" />

      <Typography text="center">
        <FormattedMessage
          defaultMessage="Choose how to validate {name}'s document. You can also copy the link to share manually"
          id="onboarding.identityValidationScreen.upload.subtitle"
          values={{
            name: member?.firstName,
          }}
        />
      </Typography>

      <div className="p-6" />

      <div className="flex flex-col items-center gap-3">
        <Card
          size="medium"
          className="flex w-full items-center justify-between"
          asChild
          scalable
        >
          <a href={link} target="_blank" rel="noreferrer noopener">
            <div className="flex flex-col gap-1">
              <div className="flex gap-2">
                <Typography bold>
                  <FormattedMessage
                    id="onboarding.identityValidationScreen.upload.document.title"
                    defaultMessage="I have {name}'s identity documents"
                    values={{
                      name: member?.firstName,
                    }}
                  />
                </Typography>
              </div>
              <Typography
                variant="body-small"
                className="text-neutral-gray-600"
              >
                <FormattedMessage
                  id="onboarding.identityValidationScreen.upload.document.description"
                  defaultMessage="Upload {name}'s ID documents for them"
                  values={{
                    name: member?.firstName,
                  }}
                />
              </Typography>
            </div>

            <ChevronRight className="size-5 shrink-0 text-neutral-gray-600" />
          </a>
        </Card>

        <Card
          size="medium"
          className="flex w-full items-center justify-between"
          asChild
          scalable
        >
          <Button
            disabled={isPending}
            onClick={onSendEmail}
            size="inline"
            variant="ghost"
          >
            <div className="flex flex-col gap-1">
              <div className="flex gap-2">
                <Typography bold className="whitespace-break-spaces">
                  <FormattedMessage
                    id="onboarding.identityValidationScreen.upload.email.title"
                    defaultMessage="I will send an invite to {name} so they can upload them"
                    values={{ name: member?.firstName }}
                  />
                </Typography>
              </div>
              <Typography
                variant="body-small"
                className="whitespace-break-spaces text-neutral-gray-600"
              >
                <FormattedMessage
                  id="onboarding.identityValidationScreen.upload.email.description"
                  defaultMessage="You both get an email explaining how to upload his ID. Mail to: {email}"
                  values={{ email: member?.email }}
                />
              </Typography>
            </div>

            {isPending ? (
              <Spinner className="size-5" />
            ) : (
              <ChevronRight className="size-5 shrink-0 text-neutral-gray-600" />
            )}
          </Button>
        </Card>

        <Card
          size="medium"
          className="flex w-full items-center justify-between"
          asChild
          scalable
        >
          <Button
            onClick={() => setOpenDialog(true)}
            size="inline"
            variant="ghost"
          >
            <div className="flex flex-col gap-1">
              <div className="flex gap-2">
                <Typography bold className="whitespace-break-spaces">
                  <FormattedMessage
                    id="onboarding.identityValidationScreen.upload.share.title"
                    defaultMessage="I will share a link with {name} so they can upload them"
                    values={{ name: member?.firstName }}
                  />
                </Typography>
              </div>
              <Typography
                variant="body-small"
                className="whitespace-break-spaces text-neutral-gray-600"
              >
                <FormattedMessage
                  id="onboarding.identityValidationScreen.share.email.description"
                  defaultMessage="Click here to copy the link and share it with {name} however its most convenient for you"
                  values={{ name: member?.firstName }}
                />
              </Typography>
            </div>

            <ChevronRight className="size-5 shrink-0 text-neutral-gray-600" />
          </Button>
        </Card>
      </div>

      <CopyIdentityLinkDialog
        member={member}
        link={link}
        isOpen={openDialog}
        onOpenChange={(open) => setOpenDialog(open)}
      />
    </SlideInScreen>
  )
}
