import { useMemo, useState } from 'react'
import { useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { AnimatePresence } from 'framer-motion'

import { queryKeys } from '@/constants/queryKeys'

import { getOnboardingStepDetails } from '../api'
import {
  LegalRepresentativeForm,
  SelectLegalRepresentative,
} from '../components/LegalRepresentativeSteps'
import { LegalRepresentativeAddress } from '../components/LegalRepresentativeSteps/LegalRepresentativeAddress'
import { OnboardingLoader } from '../components/OnboardingLoader'
import {
  LegalRepresentativeStep as LegalRepresentativeStepType,
  LegalRepresentativeType,
  OnboardingStep,
  OnboardingStepConfig,
  OnboardingStepDetails,
} from '../types'

type Step = { type: 'select' } | { type: 'address' } | { type: 'info' }

type Props = {
  config?: OnboardingStepConfig
  steps: OnboardingStep[]
}

export const LegalRepresentativeStep = ({ config, steps }: Props) => {
  const [activeStep, setActiveStep] = useState<Step>({ type: 'select' })
  const [legalRepresentativeDetails, setLegalRepresentativeDetails] =
    useState<LegalRepresentativeStepType['stepDetails']>()
  const [legalType, setLegalType] = useState<LegalRepresentativeType>()

  const [stepDetailsQuery] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getOnboardingStepDetails, config?.name],
        queryFn: () => getOnboardingStepDetails(config?.name ?? ''),
        select: (data: AxiosResponse<OnboardingStepDetails>) => data.data,
        enabled: !!config?.name,
      },
    ],
  })

  const stepDetails = useMemo(
    () =>
      stepDetailsQuery.data
        ?.stepDetails as LegalRepresentativeStepType['stepDetails'],
    [stepDetailsQuery.data],
  )

  if (stepDetailsQuery.isPending) {
    return <OnboardingLoader />
  }

  return (
    <>
      <AnimatePresence mode="wait">
        {activeStep.type === 'select' && (
          <SelectLegalRepresentative
            config={config}
            steps={steps}
            onSelect={(type) => {
              setLegalType(type)
              setActiveStep({ type: 'info' })
            }}
          />
        )}

        {activeStep.type === 'info' && (
          <LegalRepresentativeForm
            stepDetails={stepDetails}
            isPending={stepDetailsQuery.isPending}
            config={config}
            steps={steps}
            representativeType={legalType}
            existingData={legalRepresentativeDetails}
            onContinue={(data) => {
              setLegalRepresentativeDetails(data)
              setActiveStep({ type: 'address' })
            }}
            onBack={() => {
              setActiveStep({ type: 'select' })
            }}
          />
        )}

        {activeStep.type === 'address' && (
          <LegalRepresentativeAddress
            stepDetails={stepDetails}
            config={config}
            steps={steps}
            legalRepresentativeDetails={legalRepresentativeDetails}
            onBack={() => setActiveStep({ type: 'info' })}
          />
        )}
      </AnimatePresence>
    </>
  )
}
