import { forwardRef, Ref, type SVGProps } from 'react'

const SvgEarth = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill={props.color || `currentColor`}
      d="M12 21a8.8 8.8 0 0 1-3.51-.709 9.1 9.1 0 0 1-2.857-1.924 9.1 9.1 0 0 1-1.924-2.857A8.8 8.8 0 0 1 3 12q0-1.867.709-3.51a9.1 9.1 0 0 1 1.924-2.857A9.1 9.1 0 0 1 8.49 3.709 8.8 8.8 0 0 1 12 3q1.867 0 3.51.709a9.1 9.1 0 0 1 2.858 1.924A9.1 9.1 0 0 1 20.29 8.49 8.8 8.8 0 0 1 21 12a8.8 8.8 0 0 1-.709 3.51 9.1 9.1 0 0 1-1.924 2.858 9.1 9.1 0 0 1-2.857 1.923A8.8 8.8 0 0 1 12 21m-.9-1.845V17.4q-.743 0-1.271-.529A1.73 1.73 0 0 1 9.3 15.6v-.9l-4.32-4.32q-.067.405-.124.81T4.8 12q0 2.723 1.789 4.77t4.511 2.385m6.21-2.295q.45-.495.81-1.069.36-.573.596-1.192.237-.62.36-1.271.124-.653.124-1.328a7.06 7.06 0 0 0-1.226-4.027A6.93 6.93 0 0 0 14.7 5.34v.36q0 .743-.529 1.271A1.73 1.73 0 0 1 12.9 7.5h-1.8v1.8a.87.87 0 0 1-.259.641.87.87 0 0 1-.641.259H8.4V12h5.4q.383 0 .641.259a.87.87 0 0 1 .259.641v2.7h.9q.585 0 1.057.349.474.348.653.911"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgEarth)
export default ForwardRef
