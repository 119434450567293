import { UseFormReturn } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'

import {
  CountryCombobox,
  PaymentMethodSelect,
  Widget,
} from '@/shared/components'
import {
  AnimatedFormLabel,
  FormControl,
  FormField,
  FormItem,
  Input,
} from '@/shared/ui'
import { CountryCode } from '@/types/country'

import { useRecipientBankName } from '../hooks/useRecipientBankName'
import { isValidRoutingNumber } from '../utils'

import { BankName } from './BankName'
import { StateSelectOrInput } from './StateSelectOrInput'

type USPaymentFieldsFormPart = {
  paymentMethod: string
  accountNumber: string
  routingNumber: string
  addressStreet: string
  addressCity: string
  addressState: string
  addressZipCode: string
  addressCountry: string
}

type Props<T extends USPaymentFieldsFormPart> = {
  layout?: 'create' | 'edit'
  form: T extends USPaymentFieldsFormPart ? UseFormReturn<T> : never
}

export const USPaymentFields = <T extends USPaymentFieldsFormPart>({
  layout,
  form,
}: Props<T>) => {
  const intl = useIntl()

  const { name, isPending } = useRecipientBankName({
    country: CountryCode.US,
    bankReference: form.watch('routingNumber'),
    enabled:
      layout === 'create' && isValidRoutingNumber(form.watch('routingNumber')),
  })

  return (
    <Widget
      title={
        <FormattedMessage
          id="recipient.paymentDetails"
          defaultMessage="Payment details"
        />
      }
      variant="form"
    >
      <FormField
        control={form.control}
        name="paymentMethod"
        render={({ field }) => (
          <PaymentMethodSelect
            disabled={layout === 'edit'}
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />

      <FormField
        control={form.control}
        name="accountNumber"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Input
                disabled={layout === 'edit'}
                placeholder={intl.formatMessage({
                  id: 'label.accountNumber',
                  defaultMessage: 'Account number',
                })}
                {...field}
              />
            </FormControl>
            <AnimatedFormLabel>
              <FormattedMessage
                id="label.accountNumber"
                defaultMessage="Account number"
              />
            </AnimatedFormLabel>
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="routingNumber"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Input
                disabled={layout === 'edit'}
                placeholder={intl.formatMessage({
                  id: 'label.routingNumber',
                  defaultMessage: 'ABA / Routing number',
                })}
                {...field}
              />
            </FormControl>
            <AnimatedFormLabel>
              <FormattedMessage
                id="label.routingNumber"
                defaultMessage="ABA / Routing number"
              />
            </AnimatedFormLabel>

            <BankName name={name} isPending={isPending} />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="addressStreet"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Input
                autoComplete="street-address"
                placeholder={intl.formatMessage({
                  id: 'label.streetNameAndNumber',
                  defaultMessage: 'Street name and number',
                })}
                {...field}
              />
            </FormControl>
            <AnimatedFormLabel>
              <FormattedMessage
                id="label.streetNameAndNumber"
                defaultMessage="Street name and number"
              />
            </AnimatedFormLabel>
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="addressCity"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Input
                autoComplete="address-level2"
                placeholder={intl.formatMessage({
                  id: 'label.city',
                  defaultMessage: 'City',
                })}
                {...field}
              />
            </FormControl>
            <AnimatedFormLabel>
              <FormattedMessage id="label.city" defaultMessage="City" />
            </AnimatedFormLabel>
          </FormItem>
        )}
      />

      <div className="grid grid-cols-2 gap-3">
        <FormField
          control={form.control}
          name="addressState"
          render={({ field }) => (
            <FormItem>
              <StateSelectOrInput
                value={field.value}
                onChange={field.onChange}
                variant={
                  form.watch('addressCountry') === CountryCode.US
                    ? 'select'
                    : 'input'
                }
              />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="addressZipCode"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <Input
                  autoComplete="postal-code"
                  placeholder={intl.formatMessage({
                    id: 'label.postCode',
                    defaultMessage: 'Post code',
                  })}
                  {...field}
                />
              </FormControl>
              <AnimatedFormLabel>
                <FormattedMessage
                  id="label.postCode"
                  defaultMessage="Post code"
                />
              </AnimatedFormLabel>
            </FormItem>
          )}
        />
      </div>

      <FormField
        control={form.control}
        name="addressCountry"
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <CountryCombobox
                placeholder={intl.formatMessage({
                  id: 'label.country',
                  defaultMessage: 'Country',
                })}
                onSelect={(value) => {
                  field.onChange(value.valueAsCode)

                  if (form.getValues('addressState') !== '') {
                    form.setValue('addressState', '')
                  }
                }}
                value={field.value}
              />
            </FormControl>
          </FormItem>
        )}
      />
    </Widget>
  )
}
