import { maskValue } from '@/lib/typography'
import { Typography } from '@/shared/ui'
import { CountryCode } from '@/types/country'

import { Recipient } from '../../types'

type Props = {
  recipient: Recipient
}

export const AccountCell = ({ recipient }: Props) => {
  switch (recipient.country) {
    case CountryCode.MX:
      return (
        <div className="flex items-center">
          <Typography>
            {maskValue(recipient.localInformation.clabe, 6)}
          </Typography>
        </div>
      )

    case CountryCode.US:
      return (
        <div className="flex items-center">
          <Typography>
            {maskValue(recipient.localInformation.accountNumber, 6)}
          </Typography>
        </div>
      )

    default:
      return null
  }
}
