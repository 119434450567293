import { useIntl } from 'react-intl'

import { User } from '@/shared/icons/solid'

import { IdentityValidationStep, OnboardingStepName } from '../../types'
import { StepDetails } from '../StepDetails'
import { VerificationBadge } from '../VerificationBadge'

type Props = {
  stepDetails?: IdentityValidationStep['stepDetails']
}

export const IdentityValidationReview = ({ stepDetails }: Props) => {
  const intl = useIntl()

  if (!stepDetails) {
    return null
  }

  const { members } = stepDetails

  if (!members) {
    return null
  }

  const mappedKeys: Record<string, React.ReactNode> = {
    members: (
      <span className="flex flex-col items-start justify-start gap-2">
        {members?.map((member, index) => (
          <span
            key={index}
            className="flex items-center justify-start gap-2 text-neutral-gray-900"
          >
            <User className="size-5" />
            <span>
              {member.firstName} {member.lastName} {member.secondLastName}
            </span>
          </span>
        ))}
      </span>
    ),
  }

  const mappedValues: Record<string, React.ReactNode> = {
    members: (
      <div className="flex flex-col justify-end gap-2">
        {members.map((member) => (
          <VerificationBadge key={member.email} status={member.state} />
        ))}
      </div>
    ),
  }

  const details = [
    {
      key: mappedKeys['members'],
      value: mappedValues['members'],
    },
  ]

  return (
    <StepDetails
      step={OnboardingStepName.IDENTITY_VALIDATION}
      title={intl.formatMessage({
        id: 'onboarding.step.identityValidation',
        defaultMessage: 'Identity validation',
      })}
      details={[...details]}
    />
  )
}
