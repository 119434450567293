import { defineMessages } from 'react-intl'

import { Icon } from '@/types/global'

import { DefineMessages } from './messages'

export const BUSINESS_DEV_URL = 'https://business.dolarapp.dev'
export const BUSINESS_PROD_URL = 'https://business.dolarapp.com'

export const ROOT_ROUTE = '/'

export const SIGNIN_ROUTE = '/sign-in'
export const SIGNUP_ROUTE = '/sign-up'
export const FORGOT_PASSWORD_ROUTE = '/forgot-password'
export const ACCEPT_INVITE_ROUTE = '/accept-invite'

export const ONBOARDING_ROUTE = '/onboarding'
export const ONBOARDING_START_ROUTE = '/onboarding/start'
export const ONBOARDING_COMPLETED_ROUTE = '/onboarding/completed'
export const ONBOARDING_CREATE_OWNER = '/onboarding/create-owner/:ownerId?'
export const ONBOARDING_VALIDATE_IDENTITY =
  '/onboarding/validate-identity/:type'
export const ONBOARDING_STEP = '/onboarding/:step'

export const SIGNING_IN_ROUTE = '/signing-in'
export const LOGGING_OUT_ROUTE = '/logging-out'

export const DASHBOARD_ROUTE = '/dashboard'
export const RECIPIENTS_ROUTE = '/dashboard/recipients'
export const TEAM_ROUTE = '/dashboard/team'
export const TRANSACTIONS_ROUTE = '/dashboard/transactions'

export const CARDS_ROUTE = '/dashboard/cards'
export const CARDS_TRANSACTIONS_ROUTE = '/dashboard/cards/:id/transactions'

export const CONTRACTORS_ROUTE = '/dashboard/contractors'
export const CONTRACTORS_DETAILS_ROUTE = '/dashboard/contractors/:id'

export const CONTRACTORS_OVERVIEW_ROUTE = '/dashboard/contractors/overview'
export const CONTRACTORS_ALL_ROUTE = '/dashboard/contractors/all'
export const CONTRACTORS_HISTORICAL_PAYMENTS_ROUTE =
  '/dashboard/contractors/historical-payments'

export const ACCOUNTS_ROUTE = '/dashboard/accounts'
export const SINGLE_ACCOUNT_ROUTE = '/dashboard/accounts/:id'
export const ACCOUNT_TRANSACTIONS_ROUTE = '/dashboard/accounts/:id/transactions'
export const CASHBACK_TRANSACTIONS_ROUTE =
  '/dashboard/cashback/:id/transactions'
export const ADD_RECIPIENTS_ROUTE = '/recipients/add'

export const TASKS_ROUTE = '/dashboard/tasks'

export const CREATE_ACCOUNT_ROUTE = '/account/create'

export const ADD_BALANCE_ROUTE = '/add-balance'
export const TRANSFER_ROUTE = '/transfer'
export const SEND_ROUTE = '/send'

export const BULK_PAYMENT_ROUTE = '/bulk-payment'
export const BULK_PAYMENT_TRANSACTIONS_ROUTE =
  '/dashboard/bulk-payment/:id/transactions'

export const CREATE_CARD_ROUTE = '/create-card'
export const ADD_TEAM_MEMBER_ROUTE = '/add-new-member'
export const REVIEW_TASK_ROUTE = '/review-task/:id'

export type NavigationLink = {
  key: NavKeys
  icon: Icon
  path: string
  enabled: boolean
  showDot?: boolean
}

export type NavKeys =
  | 'home'
  | 'accounts'
  | 'recipients'
  | 'cards'
  | 'transactions'
  | 'settings'
  | 'signOut'
  | 'logout'
  | 'support'
  | 'team'
  | 'tasks'
  | 'myProfile'
  | 'transactionsLimit'
  | 'contractors'

export const navMessages: DefineMessages<NavKeys> = defineMessages({
  home: {
    id: 'nav.home',
    defaultMessage: 'Home',
  },
  accounts: {
    id: 'dashboard.accounts.title',
    defaultMessage: 'My accounts',
  },
  recipients: {
    id: 'nav.recipients',
    defaultMessage: 'Recipients',
  },
  cards: {
    id: 'nav.cards',
    defaultMessage: 'Cards',
  },
  transactions: {
    id: 'nav.transactions',
    defaultMessage: 'Transactions',
  },
  settings: {
    id: 'nav.settings',
    defaultMessage: 'Settings',
  },
  signOut: {
    id: 'nav.signOut',
    defaultMessage: 'Sign out',
  },
  logout: {
    id: 'nav.logout',
    defaultMessage: 'Log out',
  },
  support: {
    id: 'nav.chatWithSupport',
    defaultMessage: 'Chat with support',
  },
  team: {
    id: 'nav.team',
    defaultMessage: 'Team',
  },
  myProfile: {
    id: 'nav.myProfile',
    defaultMessage: 'My profile',
  },
  tasks: {
    id: 'nav.tasks',
    defaultMessage: 'Tasks',
  },
  transactionsLimit: {
    id: 'nav.accountLimits',
    defaultMessage: 'Account limits',
  },
  contractors: {
    id: 'nav.contractors',
    defaultMessage: 'Contractors',
  },
})
