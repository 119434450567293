import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { z } from 'zod'

import { ONBOARDING_ROUTE, SIGNIN_ROUTE } from '@/constants/paths'
import { useErrorToast } from '@/hooks/useErrorToast'
import { useAuth } from '@/providers/AuthProvider'
import { Button, Form, SlideInScreen, Typography } from '@/shared/ui'

import { createPassword } from '../api'
import { passwordValidation } from '../validations'

import { PasswordFields } from './PasswordFields'

const PASSWORD_FORM_ID = 'password-form'

export type PasswordSchema = z.infer<typeof passwordValidation>

type Props = {
  email: string | null
  signupId: string | null
  verificationCode: string | null
}

export const PasswordForm = ({ email, verificationCode, signupId }: Props) => {
  const navigate = useNavigate()
  const intl = useIntl()
  const notifyError = useErrorToast()
  const { updateRefreshToken } = useAuth()

  const form = useForm<PasswordSchema>({
    mode: 'onChange',
    resolver: zodResolver(passwordValidation),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  })

  const { mutateAsync, isPending } = useMutation({
    mutationFn: createPassword,
  })

  const onSubmit: SubmitHandler<PasswordSchema> = async (data) => {
    if (!signupId || !email || !verificationCode) {
      toast.error(
        intl.formatMessage({
          defaultMessage:
            'Required data is missing. Please ensure you used correct link',
          id: 'auth.error.missingData',
        }),
      )

      return
    }

    try {
      const response = await mutateAsync({
        ...data,
        signupId,
        email,
        verificationCode,
      })

      updateRefreshToken(response.data.refreshToken)

      navigate(ONBOARDING_ROUTE, { replace: true })
    } catch (error: unknown) {
      if (error instanceof Error) {
        notifyError(error)
      }
    }
  }

  return (
    <SlideInScreen>
      <Typography text="center" variant="h3">
        <FormattedMessage
          defaultMessage="Choose a password"
          id="signUp.choosePassword"
        />
      </Typography>

      <div className="p-6" />

      <Form {...form}>
        <form id={PASSWORD_FORM_ID} onSubmit={form.handleSubmit(onSubmit)}>
          <PasswordFields form={form} />
        </form>
      </Form>

      <div className="p-8" />

      <div className="mt-auto flex flex-col gap-3">
        <Button
          width="full"
          form={PASSWORD_FORM_ID}
          disabled={!form.formState.isValid || isPending}
          type="submit"
          loading={isPending}
        >
          <FormattedMessage
            defaultMessage="Save & Continue"
            id="action.saveAndContinue"
          />
        </Button>

        <div className="flex flex-col items-center gap-3">
          <Typography>
            <FormattedMessage
              defaultMessage="Already have an account?"
              id="auth.alreadyHaveAccount"
            />{' '}
            <Link
              className="text-primary transition-colors hover:text-primary-dark"
              to={SIGNIN_ROUTE}
            >
              <FormattedMessage defaultMessage="Sign in" id="action.signIn" />
            </Link>
          </Typography>
        </div>
      </div>

      <div className="p-6" />
    </SlideInScreen>
  )
}
