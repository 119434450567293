import { api } from '@/lib/api'

import { TeamMember } from '../types'

type DeleteTeamMemberRequest = {
  id: string
  otp?: string
}

export function deleteTeamMember({ id, otp }: DeleteTeamMemberRequest) {
  return api.post<TeamMember>(`/v1/me/business-users/${id}/terminate`, { otp })
}
