import { useEffect, useState } from 'react'
import { CommandList } from 'cmdk'
import { FormattedMessage, useIntl } from 'react-intl'

import { sourceOfFundsMessages } from '@/constants/messages'
import { intersperse } from '@/lib/typography'
import { cn } from '@/lib/utils'
import { ChevronDown, X } from '@/shared/icons/outline'
import {
  Button,
  Checkbox,
  Command,
  CommandGroup,
  CommandItem,
  FormControl,
  FormItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Typography,
} from '@/shared/ui'

type Props = {
  sources: string[]
  onSelect: (values: string[]) => void
  placeholder?: string
  value?: string[]
}

export const SourceOfFundsMultiSelectField = ({
  value,
  sources,
  onSelect,
  placeholder,
}: Props) => {
  const intl = useIntl()
  const [open, setOpen] = useState(false)

  const [selectedValues, setSelectedValues] = useState<string[]>([])

  const toggleOption = (value: string) => {
    const newSelectedValues = selectedValues.includes(value)
      ? selectedValues.filter((v) => v !== value)
      : [...selectedValues, value]
    setSelectedValues(newSelectedValues)
    onSelect(newSelectedValues)
  }

  useEffect(() => {
    if (value?.length) {
      setSelectedValues(value)
    }
  }, [value])

  const handleClear = () => {
    setSelectedValues([])
    onSelect([])
  }

  return (
    <FormItem className="flex flex-col">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <FormControl>
            <Button
              width="full"
              type="button"
              variant="outline"
              role="combobox"
              className={cn(
                'h-[54px] justify-between rounded-lg px-4 font-normal hover:bg-white',
              )}
            >
              {selectedValues?.length ? (
                <div className="flex w-full flex-col">
                  <Typography
                    variant="body-tiny"
                    className="text-neutral-gray-600"
                  >
                    {placeholder}
                  </Typography>
                  <div className="flex items-center justify-between">
                    <div className="flex flex-wrap items-center gap-2">
                      <div className="flex">
                        <Typography className="line-clamp-1 whitespace-break-spaces">
                          {intersperse(
                            selectedValues.map((value) => {
                              const selectedOption = sources.find(
                                (source) => source === value,
                              )

                              if (!selectedOption) {
                                return null
                              }

                              return (
                                <FormattedMessage
                                  key={value}
                                  {...sourceOfFundsMessages[selectedOption]}
                                />
                              )
                            }),
                            '; ',
                          )}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <Typography className="text-neutral-gray-600">
                  {placeholder}
                </Typography>
              )}

              {selectedValues.length > 0 ? (
                <X
                  aria-label={intl.formatMessage({
                    defaultMessage: 'Clear selection',
                    id: 'action.clearSelection',
                  })}
                  className="size-5 shrink-0 text-neutral-gray-600"
                  onClick={(event) => {
                    event.stopPropagation()
                    handleClear()
                  }}
                />
              ) : (
                <ChevronDown className="shrink-0 text-neutral-gray-600" />
              )}
            </Button>
          </FormControl>
        </PopoverTrigger>
        <PopoverContent align="start" className="w-full p-0 md:w-[600px]">
          <Command shouldFilter={false}>
            <CommandList>
              <CommandGroup>
                {sources.map((industry) => (
                  <CommandItem
                    className="flex h-[54px] gap-2"
                    value={industry}
                    key={industry}
                    onSelect={() => {
                      toggleOption(industry)
                    }}
                  >
                    <Checkbox
                      onChange={(e) => e.preventDefault()}
                      checked={selectedValues.includes(industry)}
                    />
                    <Typography className="whitespace-break-spaces">
                      <FormattedMessage {...sourceOfFundsMessages[industry]} />
                    </Typography>
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </FormItem>
  )
}
