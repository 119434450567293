import * as React from 'react'

import { cn } from '@/lib/utils'

const Table = React.forwardRef<
  HTMLTableElement,
  React.HTMLAttributes<HTMLTableElement> & {
    className?: string
  }
>(({ className, ...props }, ref) => (
  <div className="relative w-full">
    <table
      ref={ref}
      className={cn('caption-left w-full text-sm', className)}
      {...props}
    />
  </div>
))

Table.displayName = 'Table'

const TableHeader = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement> & {
    className?: string
  }
>(({ className, ...props }, ref) => (
  <thead
    ref={ref}
    className={cn('border-b border-neutral-gray-200', className)}
    {...props}
  />
))

TableHeader.displayName = 'TableHeader'

const TableBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement> & {
    className?: string
  }
>(({ className, ...props }, ref) => (
  <tbody ref={ref} className={cn(className)} {...props} />
))

TableBody.displayName = 'TableBody'

const TableFooter = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLAttributes<HTMLTableSectionElement> & {
    className?: string
  }
>(({ className, ...props }, ref) => (
  <tfoot
    ref={ref}
    className={cn(
      'border-t bg-neutral-gray-100/50 font-medium [&>tr]:last:border-b-0',
      className,
    )}
    {...props}
  />
))

TableFooter.displayName = 'TableFooter'

const TableRow = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement> & {
    className?: string
  }
>(({ className, ...props }, ref) => (
  <tr
    ref={ref}
    className={cn(
      'rounded-2xl border-b border-neutral-gray-200 outline-none last:border-none  focus:scale-[1.01] focus:transform focus:transition-transform data-[row=clickable]:cursor-pointer data-[state=empty]:bg-transparent data-[row=clickable]:transition-transform data-[row=clickable]:hover:scale-[1.01] data-[row=clickable]:hover:transform first:[&>td]:first:rounded-tl-xl last:[&>td]:last:rounded-br-xl first:[&>td]:last:rounded-bl-xl last:[&>td]:first:rounded-tr-xl',
      className,
    )}
    {...props}
  />
))

TableRow.displayName = 'TableRow'

const TableHead = React.forwardRef<
  HTMLTableCellElement,
  React.ThHTMLAttributes<HTMLTableCellElement> & {
    className?: string
  }
>(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={cn(
      'font-sm p-0 py-2 text-left font-bold first:pl-6 last:pr-6 last:text-right [&:has([role=checkbox])]:pr-0',
      className,
    )}
    {...props}
  />
))

TableHead.displayName = 'TableHead'

const TableCell = React.forwardRef<
  HTMLTableCellElement,
  React.TdHTMLAttributes<HTMLTableCellElement> & {
    className?: string
  }
>(({ className, ...props }, ref) => (
  <td
    ref={ref}
    className={cn(
      'p-0 align-middle first:pl-6 last:pr-6 [&:has([role=checkbox])]:pr-0 [&>div]:h-[80px]',
      className,
    )}
    {...props}
  />
))

TableCell.displayName = 'TableCell'

const TableCaption = React.forwardRef<
  HTMLTableCaptionElement,
  React.HTMLAttributes<HTMLTableCaptionElement> & {
    className?: string
  }
>(({ className, ...props }, ref) => (
  <caption
    ref={ref}
    className={cn('mt-4 text-sm text-neutral-gray-600 ', className)}
    {...props}
  />
))

TableCaption.displayName = 'TableCaption'

export {
  Table,
  TableHeader,
  TableBody,
  TableFooter,
  TableHead,
  TableRow,
  TableCell,
  TableCaption,
}
