import { RouteObject } from 'react-router-dom'

import { REVIEW_TASK_ROUTE, TASKS_ROUTE } from '@/constants/paths'

import { ReviewTask } from './ReviewTask'
import { Tasks } from './Tasks'

export const tasksRoutes: RouteObject[] = [
  {
    path: TASKS_ROUTE,
    element: <Tasks />,
  },
]

export const reviewTasksRoutes: RouteObject[] = [
  {
    path: REVIEW_TASK_ROUTE,
    element: <ReviewTask />,
  },
]
