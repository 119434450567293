import { api } from '@/lib/api'

type SendIdentityValidationEmailRequest = {
  email: string
}

export function sendIdentityValidationEmail({
  email,
}: SendIdentityValidationEmailRequest) {
  return api.post<void>(`/v1/onboardings/validation-invites`, { email })
}
