import { api } from '@/lib/api'

import { OnboardingDocument } from '../types'

type UploadDocumentParams = {
  file: File
  field: string
  step: string
}

export function uploadOnboardingDocument({
  file,
  field,
  step,
}: UploadDocumentParams) {
  const formData = new FormData()

  formData.append('file', file)

  return api.post<OnboardingDocument>(
    `/v1/onboarding-documents/step/${step}/document/${field}`,
    formData,
  )
}
