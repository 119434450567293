import { forwardRef, Ref, type SVGProps } from 'react'

const SvgPlaylist = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      d="M9 6.75a.75.75 0 0 0 0 1.5zm10 1.5a.75.75 0 0 0 0-1.5zM5 6.75a.75.75 0 0 0 0 1.5zm1 1.5a.75.75 0 0 0 0-1.5zm-1 3.5a.75.75 0 0 0 0 1.5zm1 1.5a.75.75 0 0 0 0-1.5zm-1 3.5a.75.75 0 0 0 0 1.5zm1 1.5a.75.75 0 0 0 0-1.5zm3-6.5a.75.75 0 0 0 0 1.5zm10 1.5a.75.75 0 0 0 0-1.5zm-10 3.5a.75.75 0 0 0 0 1.5zm10 1.5a.75.75 0 0 0 0-1.5zm-10-10h10v-1.5H9zm-4 0h1v-1.5H5zm0 5h1v-1.5H5zm0 5h1v-1.5H5zm4-5h10v-1.5H9zm0 5h10v-1.5H9z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgPlaylist)
export default ForwardRef
