import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'
import { useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { useParams } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'

import { getIdentity } from '@/api'
import { queryKeys } from '@/constants/queryKeys'
import { cn } from '@/lib/utils'
import { LogoIcon, ProfileDropdown } from '@/shared/components'
import { Skeleton } from '@/shared/ui'
import { BusinessState, Identity } from '@/types/business'

import { getOnboardingState } from '../api'
import { OnboardingState, OnboardingStateEnum } from '../types'

import { OnboardingSidebarStep } from './OnboardingSidebarStep'

export const OnboardingSidebar = () => {
  const params = useParams<{ step?: string }>()
  const { boot } = useIntercom()
  const [openPopover, setOpenPopover] = useState(false)

  const [userQuery, onboardingStateQuery] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getIdentity],
        queryFn: getIdentity,
        select: (data: AxiosResponse<Identity>) => data.data,
      },
      {
        queryKey: [queryKeys.getOnboardingState],
        queryFn: getOnboardingState,
        select: (data: AxiosResponse<OnboardingState>) => data.data,
      },
    ],
  })

  useEffect(() => {
    if (userQuery.data) {
      boot({
        email: userQuery.data?.email,
        name: `${userQuery.data?.firstName} ${userQuery.data?.lastName}`,
        customAttributes: {
          user_type: 'BUSINESS',
          user_state: BusinessState.ONBOARDING,
        },
      })

      Sentry.setUser({
        email: userQuery.data?.email,
        name: `${userQuery.data?.firstName} ${userQuery.data?.lastName}`,
        id: userQuery.data?.id,
      })
    }
  }, [boot, userQuery.data])

  return (
    <nav
      className={cn(
        'fixed z-50 min-h-screen w-12 bg-neutral-gray-1000 px-3 py-6 transition-all duration-300 md:w-60',
      )}
    >
      <div className="hide-scrollbar flex h-screen flex-col overflow-y-auto overflow-x-hidden">
        <ProfileDropdown
          open={openPopover}
          onOpenChange={setOpenPopover}
          expanded
        />

        <div className="p-6" />

        <div className="flex flex-col gap-3 md:px-2">
          {onboardingStateQuery.isPending ? (
            <>
              <Skeleton className="h-6 w-full bg-neutral-gray-800" />
              <Skeleton className="h-6 w-full bg-neutral-gray-800" />
              <Skeleton className="h-6 w-full bg-neutral-gray-800" />
            </>
          ) : (
            onboardingStateQuery.data?.steps.map((step, index) => {
              const previousStepState =
                onboardingStateQuery.data?.steps[index - 1]?.state

              return (
                <OnboardingSidebarStep
                  currentNotStartedStep={
                    step.state === OnboardingStateEnum.NOT_STARTED &&
                    previousStepState === OnboardingStateEnum.IN_PROGRESS
                  }
                  active={step.name === params.step}
                  key={step.id}
                  step={step}
                />
              )
            })
          )}
        </div>

        <div className="mb-16 mt-auto flex w-full pl-0.5 md:pl-2">
          <LogoIcon aria-label="DolarApp Business logo" className="size-5" />
        </div>
      </div>
    </nav>
  )
}
