import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import { ROOT_ROUTE } from './constants/paths'
import { useLogout } from './hooks/useLogout'
import { InfoTriangle } from './shared/icons/outline'
import { Button, FullScreen, Typography } from './shared/ui'

export const NotFound = () => {
  const { logout } = useLogout()
  return (
    <FullScreen>
      <div className="text-center">
        <InfoTriangle className="mx-auto h-36 w-36" />
        <Typography text="center" variant="h1">
          404
        </Typography>

        <div className="p-3" />

        <Typography className="py-3 text-xl">
          <FormattedMessage
            id="page.notFound"
            defaultMessage="Sorry, this page doesn't exist"
          />
        </Typography>

        <div className="p-3" />

        <div className="flex justify-center gap-3">
          <Button asChild>
            <Link to={ROOT_ROUTE}>
              <FormattedMessage id="action.goBack" defaultMessage="Go back" />
            </Link>
          </Button>
          <Button variant="tertiary" onClick={logout}>
            <FormattedMessage id="nav.logout" defaultMessage="Log out" />
          </Button>
        </div>
      </div>
    </FullScreen>
  )
}
