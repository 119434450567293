import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva, VariantProps } from 'class-variance-authority'

import { cn } from '@/lib/utils'

const cardVariants = cva('rounded-xl bg-white transition-all', {
  variants: {
    variant: {
      default: '',
      interactive: 'hover:bg-neutral-gray-200/10',
      transparent: 'bg-transparent',
    },
    size: {
      default: 'px-6 py-1',
      small: 'p-3',
      upload: 'px-4 py-3',
      input: 'p-0 px-4',
      medium: 'p-4',
      widget: 'px-6 py-5',
      large: 'px-6 py-8',
      none: 'p-0',
    },
    scalable: {
      true: 'md:hover:scale-105 md:hover:transform',
    },
  },
  defaultVariants: {
    variant: 'default',
    size: 'default',
    scalable: false,
  },
})

const Card = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    asChild?: boolean
    className?: string
  } & VariantProps<typeof cardVariants>
>(({ asChild = false, className, scalable, variant, size, ...props }, ref) => {
  const Comp = asChild ? Slot : 'div'

  return (
    <Comp
      className={cn(
        cardVariants({
          variant,
          className,
          size,
          scalable,
        }),
      )}
      ref={ref}
      {...props}
    />
  )
})

Card.displayName = 'Card'

export { Card }
