import { EntityType, Recipient } from '@/features/Recipients/types'
import {
  failedTransactionStatuses,
  iconByRecipientType,
  internalTransactionTypes,
} from '@/features/Transactions'
import {
  SingleTransaction,
  TransactionType,
} from '@/features/Transactions/types'

import { ArrowLeftRight, CircleCancel, Dolarapp } from '../icons/outline'
import { InfoCircle } from '../icons/solid'

import { CardTransactionIcon } from './CardTransactionIcon'

const IconComponent = ({
  additionalDetails,
  recipientType = EntityType.INDIVIDUAL,
  transactionType,
  transactionStatus,
}: Props) => {
  const isInternal = internalTransactionTypes.includes(transactionType)
  const isFailed = transactionStatus
    ? failedTransactionStatuses.includes(transactionStatus)
    : false
  if (isFailed) {
    return (
      <>
        <CircleCancel className="text-neutral-gray-900" />

        <InfoCircle className="absolute -bottom-1 -right-1 size-3 text-primary-error" />
      </>
    )
  }

  if (isInternal) {
    return <ArrowLeftRight className="text-neutral-gray-900" />
  }

  switch (transactionType) {
    case TransactionType.REWARD:
    case TransactionType.FEE:
    case TransactionType.CASHBACK:
      return <Dolarapp className="size-4 text-neutral-gray-900" />

    case TransactionType.CARD_PAYMENT: {
      return <CardTransactionIcon additionalDetails={additionalDetails} />
    }

    default: {
      const Icon = iconByRecipientType[recipientType]

      return <Icon className="text-neutral-gray-900" />
    }
  }
}

type Props = {
  additionalDetails?: SingleTransaction['additionalDetails']
  recipientType?: Recipient['entityType']
  transactionType: TransactionType
  transactionStatus?: SingleTransaction['transactionStatus']
}

export const TransactionIcon = ({
  additionalDetails,
  recipientType = EntityType.INDIVIDUAL,
  transactionType,
  transactionStatus,
}: Props) => {
  return (
    <div className="relative flex h-6 w-6 shrink-0 items-center justify-center rounded-md bg-neutral-gray-100 p-0.5">
      <IconComponent
        additionalDetails={additionalDetails}
        recipientType={recipientType}
        transactionType={transactionType}
        transactionStatus={transactionStatus}
      />
    </div>
  )
}
