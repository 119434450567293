import * as React from 'react'
import * as TabsPrimitive from '@radix-ui/react-tabs'
import { cva, VariantProps } from 'class-variance-authority'

import { cn } from '@/lib/utils'

const tabListVariants = cva('flex h-8 w-full p-0.5', {
  variants: {
    variant: {
      fill: 'rounded-xl bg-neutral-gray-200',
      outline: 'border-b-2 border-neutral-gray-300 pb-0',
      navigation: 'border-b border-neutral-gray-300 pb-0',
    },
  },
  defaultVariants: {
    variant: 'fill',
  },
})

const tabTriggerVariants = cva(
  'inline-flex w-full items-center justify-center whitespace-nowrap bg-transparent px-3 md:px-4 text-sm font-medium ring-offset-white duration-100 transition-all focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50 data-[state=active]:font-bold relative',
  {
    variants: {
      variant: {
        fill: 'rounded-lg bg-transparent data-[state=active]:bg-white h-7',
        outline:
          'data-[state=active]:after:content-[""] data-[state=active]:after:absolute data-[state=active]:after:w-full data-[state=active]:after:left-0 data-[state=active]:after:border-b-2 data-[state=active]:after:border-neutral-gray-900 data-[state=active]:after:-bottom-0.5 p-2 h-7',
        navigation:
          'font-bold data-[state=active]:after:content-[""] data-[state=active]:after:absolute data-[state=active]:after:w-full data-[state=active]:text-neutral-gray-900 text-neutral-gray-600 data-[state=active]:after:left-0 data-[state=active]:after:border-b-2 data-[state=active]:after:border-neutral-gray-900 data-[state=active]:after:-bottom-0.5 pb-4 md:px-1',
      },
      size: {
        full: 'w-full',
        fit: 'min-w-[128px] w-fit',
        inline: 'w-fit',
      },
    },
    defaultVariants: {
      variant: 'fill',
      size: 'full',
    },
  },
)

const Tabs = TabsPrimitive.Root

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List> & {
    className?: string
  } & VariantProps<typeof tabListVariants>
>(({ className, variant, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn(tabListVariants({ variant }), className)}
    {...props}
  />
))
TabsList.displayName = TabsPrimitive.List.displayName

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger> & {
    className?: string
  } & VariantProps<typeof tabTriggerVariants>
>(({ className, variant, size, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(tabTriggerVariants({ variant, size }), className)}
    {...props}
  />
))
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content> & {
    className?: string
  }
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      'ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-gray-600 focus-visible:ring-offset-2',
      className,
    )}
    {...props}
  />
))
TabsContent.displayName = TabsPrimitive.Content.displayName

export { Tabs, TabsList, TabsTrigger, TabsContent }
