import { forwardRef, Ref, type SVGProps } from 'react'

const SvgDesktop = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill={props.color || `currentColor`}
      fillRule="evenodd"
      d="m12 3.75 7.274.205a2.584 2.584 0 0 1 2.494 2.29 37.4 37.4 0 0 1 0 8.51 2.584 2.584 0 0 1-2.494 2.29l-6.024.17v2.035H17a.75.75 0 1 1 0 1.5H7a.75.75 0 0 1 0-1.5h3.75v-2.035l-6.024-.17a2.584 2.584 0 0 1-2.494-2.29 37.4 37.4 0 0 1 0-8.51 2.584 2.584 0 0 1 2.494-2.29zm-7 10a.75.75 0 0 0 0 1.5h14a.75.75 0 1 0 0-1.5z"
      clipRule="evenodd"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgDesktop)
export default ForwardRef
