import { api } from '@/lib/api'

type GetPinRequest = {
  id: string
  otp?: string
}

export async function getCardPin(data: GetPinRequest) {
  const { id, otp } = data
  return api.post<{ pin: string }>(`/v1/me/physical-cards/${id}/pin`, { otp })
}
