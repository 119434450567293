import { sub } from 'date-fns'
import { defineMessages } from 'react-intl'

import { DefineMessages } from '@/constants/messages'
import { formatDate } from '@/lib/date'

type DateKeys = 'sevenDays' | 'lastMonth' | 'lastThreeMonths' | 'lastSixMonths'

export const dateMessages: DefineMessages<DateKeys> = defineMessages({
  sevenDays: {
    id: 'dateFilter.sevenDays',
    defaultMessage: 'Last 7 days',
  },
  lastMonth: {
    id: 'dateFilter.lastMonth',
    defaultMessage: 'Last month',
  },
  lastThreeMonths: {
    id: 'dateFilter.lastThreeMonths',
    defaultMessage: 'Last 3 months',
  },
  lastSixMonths: {
    id: 'dateFilter.lastSixMonths',
    defaultMessage: 'Last 6 months',
  },
})

export const dateFilterOptions: {
  value: {
    fromDate: string
    toDate: string
  }
  key: DateKeys
}[] = [
  {
    value: {
      fromDate: formatDate(sub(new Date(), { days: 7 }), 'dd/MM/yy'),
      toDate: formatDate(new Date(), 'dd/MM/yy'),
    },
    key: 'sevenDays',
  },
  {
    value: {
      fromDate: formatDate(sub(new Date(), { months: 1 }), 'dd/MM/yy'),
      toDate: formatDate(new Date(), 'dd/MM/yy'),
    },
    key: 'lastMonth',
  },
  {
    value: {
      fromDate: formatDate(sub(new Date(), { months: 3 }), 'dd/MM/yy'),
      toDate: formatDate(new Date(), 'dd/MM/yy'),
    },
    key: 'lastThreeMonths',
  },
  {
    value: {
      fromDate: formatDate(sub(new Date(), { months: 6 }), 'dd/MM/yy'),
      toDate: formatDate(new Date(), 'dd/MM/yy'),
    },
    key: 'lastSixMonths',
  },
]

export const ACCOUNTS_FILTER_NAME = 'walletIds'
export const TYPE_FILTER_NAME = 'types'
export const CURRENCY_FILTER_NAME = 'currencies'
export const STATUS_FILTER_NAME = 'states'
export const DATE_FROM_FILTER_NAME = 'fromDate'
export const DATE_TO_FILTER_NAME = 'toDate'
export const CARDS_ID_FILTER_NAME = 'cardIds'
export const SEARCH_FILTER_NAME = 'keyword'
export const TEAM_ROLE_FILTER_NAME = 'roles'
export const IDENTITY_FILTER_NAME = 'identityIds'
export const BUSINESS_USER_ID_FILTER_NAME = 'businessUserIds'

export const ALL_FILTERS = [
  ACCOUNTS_FILTER_NAME,
  TYPE_FILTER_NAME,
  CURRENCY_FILTER_NAME,
  STATUS_FILTER_NAME,
  DATE_FROM_FILTER_NAME,
  DATE_TO_FILTER_NAME,
  TEAM_ROLE_FILTER_NAME,
  IDENTITY_FILTER_NAME,
]
