import { formatDate } from 'date-fns'

import { Contractor } from '@/features/Contractors/types'
import { Typography } from '@/shared/ui'

type Props = {
  contractor: Contractor
}

export const ContractorStartDateCell = ({ contractor }: Props) => {
  return (
    <div className="flex items-center">
      <Typography>
        {formatDate(contractor.startDate, 'dd MMM. yyyy')}
      </Typography>
    </div>
  )
}
