import { forwardRef, Ref, type SVGProps } from 'react'

const SvgStatements = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 22 22"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill={props.color || `currentColor`}
      d="M12.35 8.975a.675.675 0 0 0-.675-.675h-5.4a.675.675 0 1 0 0 1.35h5.4a.675.675 0 0 0 .675-.675M11.45 11.675a.675.675 0 0 0-.675-.675h-4.5a.675.675 0 1 0 0 1.35h4.5a.675.675 0 0 0 .675-.675M11.675 13.7a.675.675 0 1 1 0 1.35h-5.4a.675.675 0 1 1 0-1.35z"
    />
    <path
      fill={props.color || `currentColor`}
      fillRule="evenodd"
      d="M5.6 19.775h11.7a2.475 2.475 0 0 0 2.475-2.475v-4.95a.675.675 0 0 0-.675-.675h-2.925V4.65c0-1.281-1.448-2.026-2.49-1.282l-.158.113c-.702.502-1.654.5-2.36-.004a3.39 3.39 0 0 0-3.934 0 2.03 2.03 0 0 1-2.36.004l-.158-.113c-1.042-.744-2.49 0-2.49 1.282V16.4A3.375 3.375 0 0 0 5.6 19.775m2.417-15.2a2.04 2.04 0 0 1 2.366 0 3.38 3.38 0 0 0 3.929.003l.157-.112a.225.225 0 0 1 .356.183V17.3c0 .405.097.788.27 1.125H5.6A2.025 2.025 0 0 1 3.575 16.4V4.65c0-.183.207-.29.356-.183l.157.112a3.38 3.38 0 0 0 3.93-.004M16.175 17.3v-4.275h2.25V17.3a1.125 1.125 0 0 1-2.25 0"
      clipRule="evenodd"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgStatements)
export default ForwardRef
