import { RouteObject } from 'react-router-dom'

import {
  CARDS_ROUTE,
  CARDS_TRANSACTIONS_ROUTE,
  CREATE_CARD_ROUTE,
} from '@/constants/paths'

import { Cards } from './Cards'
import { CardTransactions } from './CardTransactions'
import { CreateCard } from './CreateCard'

export const cardsRoutes: RouteObject[] = [
  {
    path: CARDS_ROUTE,
    element: <Cards />,
  },
]

export const createCardRoutes: RouteObject[] = [
  {
    path: CREATE_CARD_ROUTE,
    element: <CreateCard />,
  },
]

export const cardTransactionsRoutes: RouteObject[] = [
  {
    path: CARDS_TRANSACTIONS_ROUTE,
    element: <CardTransactions />,
  },
]
