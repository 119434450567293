import { useMemo, useState } from 'react'
import { AnimatePresence } from 'framer-motion'

import { useSearchParamsValue } from '@/hooks/useSearchParamsValue'
import { ProgressButton } from '@/shared/components'

import { CredentialsForm } from './components/CredentialsForm'
import { VerificationForm } from './components/VerificationForm'

enum Step {
  Credentials = 'credentials',
  Verify = 'verify',
}

type StepState =
  | { type: Step.Credentials }
  | {
      type: Step.Verify
      identityId?: string
      email?: string
      password?: string
    }

export const SignIn = () => {
  const [id, otp, from] = useSearchParamsValue(['id', 'otp', 'from'])

  const [activeStep, setActiveStep] = useState<StepState>(() =>
    id && otp ? { type: Step.Verify } : { type: Step.Credentials },
  )

  const progressByStep = useMemo(() => {
    switch (activeStep.type) {
      case Step.Credentials:
        return 50
      case Step.Verify:
        return 100
    }
  }, [activeStep.type])

  return (
    <>
      {from ? (
        <ProgressButton
          className="hidden md:flex"
          progress={progressByStep}
          to={from}
        />
      ) : null}

      <AnimatePresence mode="wait">
        {activeStep.type === Step.Credentials ? (
          <CredentialsForm
            onContinue={(data) =>
              setActiveStep({
                type: Step.Verify,
                ...data,
              })
            }
          />
        ) : null}

        {activeStep.type === Step.Verify ? (
          <VerificationForm
            email={activeStep.email}
            password={activeStep.password}
            identityId={activeStep.identityId ?? id}
          />
        ) : null}
      </AnimatePresence>
    </>
  )
}
