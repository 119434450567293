import { forwardRef, Ref, type SVGProps } from 'react'

const SvgCoin = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      stroke={props.color || `currentColor`}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M19 12a7 7 0 1 1-14 0 7 7 0 0 1 14 0"
      clipRule="evenodd"
    />
    <path
      fill={props.color || `currentColor`}
      d="M12 14.821a.75.75 0 0 0 0 1.5zM10.5 11l.523-.538a1 1 0 0 0-.094-.077zm-.5-1 .75.026V10zm2-.821a.75.75 0 0 0 0-1.5zm-.03 7.142a.75.75 0 0 0 .06-1.498zm-1.227-3.034a.75.75 0 1 0-1.486-.2zm2.007 2.284a.75.75 0 0 0-1.5 0zM11.25 17a.75.75 0 0 0 1.5 0zm.708-9.32a.75.75 0 1 0 .084 1.498zm1.56 1.366-.554.507zm-.26 1.458a.75.75 0 1 0 1.484.214zM11.25 8.429a.75.75 0 0 0 1.5 0zM12.75 7a.75.75 0 0 0-1.5 0zM12 16.321c.424 0 1.062-.096 1.623-.417.597-.342 1.127-.963 1.127-1.923h-1.5c0 .312-.137.486-.373.621a1.9 1.9 0 0 1-.877.219zm2.75-2.34c0-.588-.185-1.068-.519-1.454-.31-.357-.714-.594-1.074-.78-.78-.403-1.49-.658-2.134-1.285l-1.046 1.076c.855.83 1.895 1.233 2.491 1.541.328.17.517.3.629.43.088.101.153.228.153.472zm-3.821-3.596a.42.42 0 0 1-.18-.36l-1.499-.05a1.92 1.92 0 0 0 .821 1.64zM10.75 10c0-.261.057-.404.105-.482a.52.52 0 0 1 .226-.193c.246-.121.587-.146.919-.146v-1.5c-.335 0-.994.01-1.582.3a2 2 0 0 0-.836.746c-.222.358-.332.786-.332 1.275zm1.28 4.823a1.36 1.36 0 0 1-.982-.484l-1.15.964a2.86 2.86 0 0 0 2.071 1.018zm-.982-.484a1.36 1.36 0 0 1-.305-1.052l-1.486-.2a2.86 2.86 0 0 0 .641 2.216zm.202 1.232V17h1.5v-1.429zm.792-6.393c.348-.02.687.118.922.375l1.106-1.014a2.66 2.66 0 0 0-2.112-.859zm.922.375c.236.257.343.606.294.95l1.484.215a2.66 2.66 0 0 0-.672-2.179zm-.214-1.124V7h-1.5v1.429z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgCoin)
export default ForwardRef
