import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import { generatePath, useNavigate } from 'react-router-dom'

import {
  ONBOARDING_COMPLETED_ROUTE,
  ONBOARDING_START_ROUTE,
  ONBOARDING_STEP,
} from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'

import { getOnboardingState } from './api'
import { useBusinessStateCheck } from './hooks/useBusinessStateCheck'
import { OnboardingStateEnum } from './types'

export const Onboarding = () => {
  const navigate = useNavigate()

  useBusinessStateCheck()

  const onboardingStateQuery = useQuery({
    queryKey: [queryKeys.getOnboardingState],
    queryFn: getOnboardingState,
    select: (data) => data.data,
  })

  useEffect(() => {
    if (!onboardingStateQuery.data) {
      return
    }

    if (onboardingStateQuery.data.state === OnboardingStateEnum.NOT_STARTED) {
      navigate(`${ONBOARDING_START_ROUTE}`, { replace: true })
      return
    }

    const hasAnyCompletedOrVerificationStep =
      onboardingStateQuery.data.steps.some(
        (step) =>
          step.state === OnboardingStateEnum.VERIFICATION ||
          step.state === OnboardingStateEnum.COMPLETED,
      )

    if (hasAnyCompletedOrVerificationStep) {
      navigate(generatePath(ONBOARDING_COMPLETED_ROUTE, { replace: true }))

      return
    }

    const notStartedStep = onboardingStateQuery.data.steps.find(
      (step) => step.state === OnboardingStateEnum.NOT_STARTED,
    )

    const inProgressSteps = onboardingStateQuery.data.steps.filter(
      (step) => step.state === OnboardingStateEnum.IN_PROGRESS,
    )

    const [lastInProgressStep] = inProgressSteps.slice(-1)

    if (notStartedStep) {
      navigate(
        generatePath(ONBOARDING_STEP, {
          step: notStartedStep.name,
        }),
        { replace: true },
      )
      return
    }

    if (lastInProgressStep) {
      navigate(
        generatePath(ONBOARDING_STEP, { step: lastInProgressStep.name }),
        { replace: true },
      )

      return
    }

    const [firstStep] = onboardingStateQuery.data.steps

    navigate(generatePath(ONBOARDING_STEP, { step: firstStep.name }), {
      replace: true,
    })
  }, [navigate, onboardingStateQuery.data])

  return null
}
