import { useIntl } from 'react-intl'

import { flagByCurrency } from '@/constants/countries'
import { Currency } from '@/constants/currency'
import { getFlagUrl } from '@/lib/images'
import { formatCurrency } from '@/lib/money'
import { CountryCode } from '@/types/country'

import { Typography } from '../ui'

type Props = {
  currency?: Currency
  countryCode?: CountryCode
  flagCode?: 'us' | 'mx'
}

export const CurrencyFlag = ({
  currency = Currency.USDC,
  countryCode = CountryCode.US,
}: Props) => {
  const intl = useIntl()

  return (
    <div className="flex shrink-0 items-center gap-3">
      <img
        src={getFlagUrl(flagByCurrency[currency])}
        className="size-4"
        alt={intl.formatMessage(
          {
            id: 'country.flag.alt',
            defaultMessage:
              '{country, select, MEX {Mexican flag} USA {American flag} other {}}',
          },
          { country: countryCode },
        )}
      />
      <Typography>{formatCurrency(currency)}</Typography>
    </div>
  )
}
