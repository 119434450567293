import { api } from '@/lib/api'

import { TeamMember } from '../types'

type BlockTeamMemberRequest = {
  id: string
  otp?: string
}

export function blockTeamMember({ id, otp }: BlockTeamMemberRequest) {
  return api.post<TeamMember>(`/v1/me/business-users/${id}/block`, { otp })
}
