import { forwardRef, Ref, type SVGProps } from 'react'

const SvgSetting = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      stroke={props.color || `currentColor`}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M11.3 19a.75.75 0 0 1-.739-.627.81.81 0 0 0-.549-.623 6 6 0 0 1-.676-.28.8.8 0 0 0-.816.052.736.736 0 0 1-.95-.077l-.992-.992a.77.77 0 0 1-.082-.995.84.84 0 0 0 .064-.837 6 6 0 0 1-.226-.576.87.87 0 0 0-.667-.6.79.79 0 0 1-.667-.776v-1.241a.91.91 0 0 1 .76-.9 1 1 0 0 0 .74-.607q.057-.134.12-.264a1 1 0 0 0-.061-1.024.906.906 0 0 1 .094-1.169l.729-.729c.36-.36.927-.406 1.34-.11l.023.016a1.1 1.1 0 0 0 1.044.1c.366-.132.636-.447.711-.829l.01-.034c.082-.506.52-.878 1.032-.878h.88c.526 0 .976.38 1.062.9l.016.07c.07.366.327.67.676.8a1.07 1.07 0 0 0 1.026-.1l.05-.036a1.066 1.066 0 0 1 1.377.111l.671.672c.336.338.379.87.1 1.257-.218.324-.246.74-.073 1.089l.043.1c.14.341.443.589.806.658a.98.98 0 0 1 .824.966V12.6a.866.866 0 0 1-.724.854.95.95 0 0 0-.729.648 6 6 0 0 1-.156.4.92.92 0 0 0 .078.9.84.84 0 0 1-.088 1.083l-.931.932a.77.77 0 0 1-.992.081.84.84 0 0 0-.86-.05 6 6 0 0 1-.6.257.84.84 0 0 0-.552.641.77.77 0 0 1-.758.654z"
      clipRule="evenodd"
    />
    <path
      stroke={props.color || `currentColor`}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M14.333 12a2.333 2.333 0 1 1-4.666 0 2.333 2.333 0 0 1 4.666 0"
      clipRule="evenodd"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgSetting)
export default ForwardRef
