import { useEffect, useState } from 'react'
import { AnimatePresence } from 'framer-motion'

import { useSearchParamsValue } from '@/hooks/useSearchParamsValue'

import { ForgetPasswordEmailForm } from './components/ForgetPasswordEmailForm'
import { ForgetPasswordForm } from './components/ForgetPasswordForm'
import { ForgetPasswordVerifyForm } from './components/ForgetPasswordVerifyForm'

enum Step {
  Email = 'email',
  Verify = 'verify',
  Password = 'password',
}

type StepState =
  | { type: Step.Email }
  | { type: Step.Verify }
  | { type: Step.Password; identityId: string; otp: string }

export const ForgetPassword = () => {
  const [identityId, otp] = useSearchParamsValue(['id', 'otp'])

  const [activeStep, setActiveStep] = useState<StepState>(() =>
    identityId && otp
      ? { type: Step.Password, identityId, otp }
      : { type: Step.Email },
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [activeStep])

  return (
    <AnimatePresence mode="wait">
      {activeStep.type === Step.Email ? (
        <ForgetPasswordEmailForm
          onContinue={() => setActiveStep({ type: Step.Verify })}
        />
      ) : null}
      {activeStep.type === Step.Verify ? <ForgetPasswordVerifyForm /> : null}
      {activeStep.type === Step.Password ? (
        <ForgetPasswordForm
          identityId={activeStep.identityId}
          otp={activeStep.otp}
        />
      ) : null}
    </AnimatePresence>
  )
}
