import * as React from 'react'

import { cn } from '@/lib/utils'

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  hasError?: boolean
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, hasError, ...props }, ref) => {
    return (
      <textarea
        className={cn(
          'peer flex min-h-[108px] w-full rounded-lg bg-white px-4 py-3 pt-5 text-sm ring-offset-white placeholder:text-neutral-500 placeholder:text-transparent focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-neutral-gray-200',
          {
            'ring-1 ring-primary-error ring-offset-1': hasError,
          },
          className,
        )}
        ref={ref}
        {...props}
      />
    )
  },
)

Textarea.displayName = 'Textarea'

export { Textarea }
