import { api } from '@/lib/api'

type RemoveFileParams = {
  id: string
  file: File
}

type FileResponse = {
  fileName: string
}

export function parseCSV({ id, file }: RemoveFileParams) {
  const formData = new FormData()

  formData.append('file', file)

  return api.post<FileResponse>(`/v1/me/bulk-payment-files/${id}`, formData)
}
