import { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link, To } from 'react-router-dom'

import { SingleTransaction } from '@/features/Transactions/types'
import { ChevronRight } from '@/shared/icons/outline'
import { Button, Card, Typography } from '@/shared/ui'

import { TransactionShortListRow } from './TransactionShortListRow'

type Props = {
  transactions: SingleTransaction[]
  limit?: number
  path: To
}

export const TransactionsShortList = ({
  transactions,
  limit = 5,
  path,
}: Props) => {
  const slicedTransactions = transactions.slice(0, limit)

  return (
    <>
      <div className="flex justify-between px-4">
        <Typography bold>
          <FormattedMessage
            id="label.transaction"
            defaultMessage="Transaction"
          />
        </Typography>
        <Typography bold>
          <FormattedMessage id="label.amount" defaultMessage="Amount" />
        </Typography>
      </div>

      <div className="p-1" />

      <Card className="flex flex-col gap-3" size="medium">
        {slicedTransactions.map((tx, index) => (
          <Fragment key={tx.id}>
            <TransactionShortListRow transaction={tx} />

            {index !== slicedTransactions.length - 1 && (
              <div className="border-t border-neutral-gray-200" />
            )}
          </Fragment>
        ))}

        <Button
          rightIcon={<ChevronRight className="size-5" />}
          size="md"
          variant="secondary"
          asChild
        >
          <Link to={path}>
            <FormattedMessage id="action.seeAll" defaultMessage="See all" />
          </Link>
        </Button>
      </Card>
    </>
  )
}
