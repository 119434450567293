import { useEffect } from 'react'
import { useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { useDropzone } from 'react-dropzone'
import { FormattedMessage, useIntl } from 'react-intl'
import { toast } from 'sonner'

import { queryKeys } from '@/constants/queryKeys'
import { useErrorToast } from '@/hooks/useErrorToast'
import { queryClient } from '@/lib/queryClient'
import { cn } from '@/lib/utils'
import { DragAndDropContainer } from '@/shared/components'
import { Plus } from '@/shared/icons/outline'
import { Button, Card, Label, Typography } from '@/shared/ui'

import { uploadOnboardingDocument } from '../api'
import { OnboardingDocument, OnboardingStepDetails } from '../types'

import { UploadedOnboardingDocument } from './UploadedOnboardingDocument'

function typeValidator(file: File) {
  if (
    ['image/jpeg', 'image/png', 'application/pdf', 'image/jpg'].includes(
      file.type,
    )
  ) {
    return null
  }

  return {
    code: 'invalid-type',
    message: 'Invalid file type',
  }
}

type Props = {
  description?: string
  descriptionComponent?: React.ReactNode
  files: OnboardingDocument[]
  isLoadingFiles: boolean
  name: string
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onDrop: (files: File[]) => void
  step: string
  title: string
}

export const OnboardingDocumentField = ({
  description,
  descriptionComponent,
  files,
  isLoadingFiles,
  name,
  onBlur,
  onChange,
  onDrop,
  step,
  title,
}: Props) => {
  const notifyError = useErrorToast()
  const intl = useIntl()

  const { mutateAsync, isPending } = useMutation({
    mutationFn: uploadOnboardingDocument,
  })

  const { getRootProps, getInputProps, isDragActive, fileRejections, open } =
    useDropzone({
      multiple: true,
      validator: typeValidator,
      accept: {
        'image/jpeg': [],
        'image/png': [],
        'application/pdf': [],
        'image/jpg': [],
      },
      disabled: isLoadingFiles,
      onDrop: async (acceptedFiles) => {
        if (!acceptedFiles.length) {
          return
        }

        const files = await Promise.allSettled(
          acceptedFiles.map(async (file) => {
            const { data } = await mutateAsync({ file, field: name, step })

            return data
          }),
        )

        files.map((file) => {
          if (file.status === 'fulfilled') {
            queryClient.setQueryData<AxiosResponse<OnboardingStepDetails>>(
              [queryKeys.getOnboardingStepDetails, step],
              (prev) => {
                if (!prev) {
                  return prev
                }

                return {
                  ...prev,
                  data: {
                    ...prev.data,
                    stepDetails: {
                      ...prev.data.stepDetails,
                      documents: [
                        ...(prev.data.stepDetails.documents ?? []),
                        file.value,
                      ],
                    },
                  },
                }
              },
            )
          } else {
            notifyError(file.reason)
          }
        })

        onDrop(acceptedFiles)
      },
    })

  const deleteFile = (fileName: string) => {
    queryClient.setQueryData<AxiosResponse<OnboardingStepDetails>>(
      [queryKeys.getOnboardingStepDetails, step],
      (prev) => {
        if (!prev) {
          return prev
        }

        return {
          ...prev,
          data: {
            ...prev.data,
            stepDetails: {
              ...prev.data.stepDetails,
              documents: prev.data.stepDetails.documents.filter((file) => {
                return file.documentType !== name || file.fileName !== fileName
              }),
            },
          },
        }
      },
    )
  }

  useEffect(() => {
    if (fileRejections.length) {
      fileRejections.forEach(({ file }) => {
        toast.error(
          intl.formatMessage(
            {
              defaultMessage: 'This file format is not supported',
              id: 'validation.file.wrongFormat',
            },
            { name: file.name },
          ),
        )
      })
    }
  }, [fileRejections, intl])

  return (
    <Card size="upload">
      <div className="flex flex-col gap-2">
        <Typography>{title}</Typography>
        {descriptionComponent ? (
          descriptionComponent
        ) : description ? (
          <Typography className="text-neutral-gray-600">
            {description}
          </Typography>
        ) : null}
      </div>

      <div className="p-2" />
      <div className={cn('flex w-full gap-6')} {...getRootProps()}>
        <input
          {...getInputProps({
            id: name,
            name: name,
            onChange,
            onBlur,
          })}
        />

        <Label className="sr-only" htmlFor={name}>
          <FormattedMessage
            id="uploadFileField.dragOrClick"
            defaultMessage="Drag and drop or click to upload"
          />
        </Label>

        {files.length ? (
          <div className="flex w-full flex-col justify-center gap-2">
            {files.map((file) => (
              <UploadedOnboardingDocument
                key={file.fileName}
                fileName={file.fileName}
                step={step}
                name={name}
                onDelete={deleteFile}
              />
            ))}

            <div className="p-1" />

            <Button
              leftIcon={<Plus className="size-5" />}
              loading={isPending}
              disabled={isPending}
              size="md"
              onClick={open}
              type="button"
              variant="secondary"
            >
              <FormattedMessage
                id="uploadFile.field.addAnother"
                defaultMessage="Add another file"
              />
            </Button>
          </div>
        ) : (
          <DragAndDropContainer
            files={files.map((file) => file.fileName)}
            isDragActive={isDragActive}
            isPending={isPending || isLoadingFiles}
          />
        )}
      </div>
    </Card>
  )
}
