import { api } from '@/lib/api'

export type AuthenticateResponse = {
  identityId: string
}

export type AuthenticateRequest = {
  email: string
  password: string
}

export function authenticate(data: AuthenticateRequest) {
  return api.post<AuthenticateResponse>('/v1/authentication', data)
}
