import * as React from 'react'
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'

import { cn } from '@/lib/utils'

import { Check } from '../icons/outline'

const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root> & {
    className?: string
  }
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={cn('grid gap-2', className)}
      {...props}
      ref={ref}
    />
  )
})
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName

const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item> & {
    className?: string
    checked?: boolean
  }
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Item
      ref={ref}
      className={cn(
        'size-5 rounded-full border-2 border-neutral-gray-300 text-neutral-gray-900 ring-offset-white focus:outline-none focus-visible:ring-1 focus-visible:ring-neutral-gray-900 focus-visible:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:border-none',
        className,
      )}
      {...props}
    >
      <RadioGroupPrimitive.RadioGroupIndicator className="flex items-center justify-center">
        <div
          className={cn(
            'flex size-5 items-center justify-center rounded-full bg-primary text-white',
          )}
        >
          <Check className="size-4" />
        </div>
      </RadioGroupPrimitive.RadioGroupIndicator>
    </RadioGroupPrimitive.Item>
  )
})
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName

export { RadioGroup, RadioGroupItem }
