import { api } from '@/lib/api'
import { PaymentLimitType } from '@/types/limits'

import { Card } from '../types'

type CreateVirtualCardRequest = {
  requestId: string
  walletId: string
  identityId: string
  nickname: string
  cardLimit: {
    limitAmount: string
    limitType: PaymentLimitType
  }
}

export function createVirtualCard(data: CreateVirtualCardRequest) {
  return api.post<Card>(`/v1/me/virtual-cards`, data)
}
