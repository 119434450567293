import { Currency } from '@/constants/currency'
import { api } from '@/lib/api'
import { ChartFXRate, Period } from '@/types/recharts'

type ChartRatesRequest = {
  currency: Currency
  period: Period
}

export type PriceChartRatesResponse = {
  baseCurrency: Currency
  endDate: string
  startDate: string
  localCurrency: Currency
  period: Period
  fxRates: ChartFXRate[]
}

export function getPriceChartRates({ currency, period }: ChartRatesRequest) {
  return api.get<PriceChartRatesResponse>(
    `/v1/price-chart?currency=${currency}&period=${period}`,
  )
}
