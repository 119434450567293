import { FormattedMessage } from 'react-intl'

import { Typography } from '@/shared/ui'

export const SourceOfFundsDescription = () => {
  return (
    <div className="flex flex-col text-neutral-gray-600">
      <Typography>
        <FormattedMessage
          id="onboarding.companyActivity.sourceOfFunds.provideDocument"
          defaultMessage="Provide a valid document that demonstrates source of funds from the above:"
        />
      </Typography>

      <div className="p-0.5" />

      <div className="grid grid-cols-2">
        <div className="flex flex-col">
          <ul className="list-disc pl-4 text-neutral-gray-600">
            <li>
              <Typography>
                <FormattedMessage
                  id="onboarding.companyActivity.sourceOfFunds.document.accountStatements"
                  defaultMessage="Most recent bank account statement"
                />
              </Typography>
            </li>
            <li>
              <Typography>
                <FormattedMessage
                  id="onboarding.companyActivity.sourceOfFunds.document.financialStatements"
                  defaultMessage="Audited or certified financial statements"
                />
              </Typography>
            </li>
            <li>
              <Typography>
                <FormattedMessage
                  id="onboarding.companyActivity.sourceOfFunds.document.invoices"
                  defaultMessage="Invoices and contracts with key clients"
                />
              </Typography>
            </li>
            <li>
              <Typography>
                <FormattedMessage
                  id="onboarding.companyActivity.sourceOfFunds.document.receipts"
                  defaultMessage="Payment receipts for major transactions"
                />
              </Typography>
            </li>
          </ul>
        </div>

        <div className="flex flex-col">
          <ul className="list-disc pl-4 text-neutral-gray-600">
            <li>
              <Typography>
                <FormattedMessage
                  id="onboarding.companyActivity.sourceOfFunds.document.taxFillings"
                  defaultMessage="Tax filings and returns"
                />
              </Typography>
            </li>
            <li>
              <Typography>
                <FormattedMessage
                  id="onboarding.companyActivity.sourceOfFunds.document.investments"
                  defaultMessage="Proof of sales of assets or investments"
                />
              </Typography>
            </li>
            <li>
              <Typography>
                <FormattedMessage
                  id="onboarding.companyActivity.sourceOfFunds.document.contribution"
                  defaultMessage="Proof of capital contribution or loan"
                />
              </Typography>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
