import { forwardRef, Ref, type SVGProps } from 'react'

const SvgBank = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill={props.color || `currentColor`}
      fillRule="evenodd"
      d="M11.415 2.395a2 2 0 0 1 1.17 0l2.986.918a16.7 16.7 0 0 1 4.39 2.089c1.054.705.555 2.348-.713 2.348H4.752c-1.268 0-1.767-1.643-.714-2.348a16.7 16.7 0 0 1 4.391-2.09zm.73 1.434a.5.5 0 0 0-.29 0l-2.985.918A15.2 15.2 0 0 0 5.5 6.25h13a15.2 15.2 0 0 0-3.37-1.503z"
      clipRule="evenodd"
    />
    <path
      fill={props.color || `currentColor`}
      d="M4.25 21a.75.75 0 0 1 .75-.75h14a.75.75 0 0 1 0 1.5H5a.75.75 0 0 1-.75-.75M6.25 17a.75.75 0 0 0 1.5 0v-6a.75.75 0 0 0-1.5 0zM12 17.75a.75.75 0 0 1-.75-.75v-6a.75.75 0 0 1 1.5 0v6a.75.75 0 0 1-.75.75M16.25 17a.75.75 0 0 0 1.5 0v-6a.75.75 0 0 0-1.5 0z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgBank)
export default ForwardRef
