import { api } from '@/lib/api'
import {
  BUSINESS_USER_ID_FILTER_NAME,
  TYPE_FILTER_NAME,
} from '@/shared/components'

import { Task } from '../types'

type SearchParams = {
  [TYPE_FILTER_NAME]?: string
  [BUSINESS_USER_ID_FILTER_NAME]?: string
}

export function getAdminTasks(params: SearchParams = {}) {
  const searchParams = new URLSearchParams(params)
  const searchQuery = searchParams.size > 0 ? '?' + searchParams.toString() : ''

  return api.get<Task[]>(`/v1/me/business-tasks/search${searchQuery}`)
}
