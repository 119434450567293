import { CardCategory, CardState } from '@/features/Cards/types'
import { StatusVariant } from '@/shared/components'
import {
  Bag,
  BusFront,
  Cart,
  ChartTrendUp,
  CreditCard,
  LightbulbOn,
  MapMark,
  MedicalKit,
  Receipt,
  Soda,
  Tv,
} from '@/shared/icons/outline'
import { Icon } from '@/types/global'

export const badgeVariantByCardState: Record<CardState, StatusVariant> = {
  [CardState.ACTIVE]: 'active',
  [CardState.CREATED]: 'neutral',
  [CardState.BLOCKED]: 'neutral',
  [CardState.TERMINATED]: 'failed',
}

export const iconByCardCategory: Record<CardCategory, Icon> = {
  [CardCategory.GROCERIES]: Cart,
  [CardCategory.SHOPPING]: Bag,
  [CardCategory.RESTAURANTS]: Soda,
  [CardCategory.TRANSPORT]: BusFront,
  [CardCategory.TRAVEL]: MapMark,
  [CardCategory.ENTERTAINMENT]: Tv,
  [CardCategory.UTILITIES]: LightbulbOn,
  [CardCategory.HEALTH]: MedicalKit,
  [CardCategory.SERVICES]: Receipt,
  [CardCategory.FINANCE_AND_INVESTMENTS]: ChartTrendUp,
  [CardCategory.OTHER]: CreditCard,
}

export const sizeByCardCategory: Record<CardCategory, string> = {
  [CardCategory.GROCERIES]: 'size-4',
  [CardCategory.SHOPPING]: 'size-4',
  [CardCategory.RESTAURANTS]: 'size-4',
  [CardCategory.TRANSPORT]: 'size-4',
  [CardCategory.TRAVEL]: 'size-4',
  [CardCategory.ENTERTAINMENT]: 'size-3.5',
  [CardCategory.UTILITIES]: 'size-4',
  [CardCategory.HEALTH]: 'size-4',
  [CardCategory.SERVICES]: 'size-3.5',
  [CardCategory.FINANCE_AND_INVESTMENTS]: 'size-4.5',
  [CardCategory.OTHER]: 'size-4',
}
