import { useCallback, useMemo } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { generatePath, useNavigate } from 'react-router-dom'

import { ONBOARDING_COMPLETED_ROUTE, ONBOARDING_STEP } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { useErrorToast } from '@/hooks/useErrorToast'
import { useKeyPress } from '@/hooks/useKeyPress'
import { queryClient } from '@/lib/queryClient'
import { GoBackButton } from '@/shared/components'
import {
  Button,
  Form,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'

import { getOnboardingStepDetails, submitOnboardingStep } from '../api'
import { OnboardingLoader } from '../components/OnboardingLoader'
import { ReviewSteps } from '../components/ReviewSteps'
import {
  OnboardingMemberState,
  OnboardingStep,
  OnboardingStepConfig,
  OnboardingStepName,
  ReviewAndSubmitStep as ReviewAndSubmitStepType,
} from '../types'

const REVIEW_AND_SUBMIT_FORM_ID = 'review-and-submit-form-id'

type Props = {
  config?: OnboardingStepConfig
  steps: OnboardingStep[]
}

export const ReviewAndSubmitStep = ({ config, steps }: Props) => {
  const notifyError = useErrorToast()
  const navigate = useNavigate()

  const { mutateAsync, isPending, isSuccess } = useMutation({
    mutationFn: submitOnboardingStep,
  })

  const form = useForm({
    mode: 'onChange',
  })

  const stepDetailsQuery = useQuery({
    queryKey: [queryKeys.getOnboardingStepDetails, config?.name],
    queryFn: () => getOnboardingStepDetails(config?.name ?? ''),
    select: (data) => data.data,
    enabled: !!config?.name,
    staleTime: 0,
  })

  const hasSomeIdentitiesNotVerified = useMemo(() => {
    if (!stepDetailsQuery.data) {
      return false
    }

    const details = stepDetailsQuery.data
      .stepDetails as ReviewAndSubmitStepType['stepDetails']

    const identities = details.reviewElements?.find(
      (step) => step.stepName === OnboardingStepName.IDENTITY_VALIDATION,
    )?.stepDetails.members

    return identities?.some(
      (identity) => identity.state !== OnboardingMemberState.COMPLETED,
    )
  }, [stepDetailsQuery.data])

  const onSubmit = useCallback(async () => {
    if (!config?.name) {
      return
    }

    try {
      await mutateAsync({
        stepName: OnboardingStepName.REVIEW_AND_SUBMIT,
        stepDetails: {},
      })

      await queryClient.refetchQueries({
        queryKey: [queryKeys.getOnboardingState],
      })

      navigate(generatePath(ONBOARDING_COMPLETED_ROUTE))
    } catch (error) {
      if (error instanceof Error) {
        notifyError(error)
      }
    }
  }, [config?.name, navigate, notifyError, mutateAsync])

  useKeyPress('Enter', form.handleSubmit(onSubmit))

  const getPreviousStep = () => {
    const currentStepIndex = steps.findIndex(
      (step) => step.name === config?.name,
    )

    return steps[currentStepIndex - 1]
  }

  if (stepDetailsQuery.isPending) {
    return <OnboardingLoader />
  }

  return (
    <>
      <GoBackButton
        className="hidden md:left-80 md:flex"
        to={generatePath(ONBOARDING_STEP, {
          step: getPreviousStep()?.name ?? '',
        })}
      />

      <SlideInScreen>
        <Typography variant="h3" text="center">
          <FormattedMessage
            defaultMessage="Review and submit"
            id="onboarding.reviewAndSubmit.title"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            defaultMessage="Review your application, edit any of the previous steps and hit submit when you are ready!"
            id="onboarding.reviewAndSubmit.subtitle"
          />
        </Typography>

        <div className="p-6" />

        <Form {...form}>
          <form
            id={REVIEW_AND_SUBMIT_FORM_ID}
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <div className="flex flex-col gap-6">
              <ReviewSteps details={stepDetailsQuery.data} />
            </div>
          </form>
        </Form>

        <StickyContainer>
          <Button
            width="full"
            disabled={isPending || isSuccess || hasSomeIdentitiesNotVerified}
            loading={isPending || isSuccess}
            form={REVIEW_AND_SUBMIT_FORM_ID}
            onClick={form.handleSubmit(onSubmit)}
            type="submit"
          >
            <FormattedMessage defaultMessage="Submit" id="action.submit" />
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
