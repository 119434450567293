import { Currency } from '@/constants/currency'
import { PaymentMethod } from '@/constants/payments'
import { EntityType } from '@/features/Recipients/types'
import {
  ExecuteWithdrawalTask,
  Task,
  TaskEntityType,
  TaskState,
  TaskType,
  WithdrawalTaskDetails,
} from '@/features/Tasks'
import { CountryCode } from '@/types/country'

export const MOCKED_TASKS: Task[] = [
  {
    id: 'd4ad7a53-1010-4b03-bd71-ea1ec10bfdd7',
    businessId: 'c002df21-b54c-4ace-8f54-52b4d3a2a2bb',
    businessUserId: 'b89ff7c0-c562-49ae-aafc-b862ed65bb2b',
    businessIdentityId: 'fdc3dfcd-efe3-4434-ab36-94e4bac66f6d',
    createdByFirstName: 'Maks',
    createdByLastName: 'Ops',
    createdBySecondLastName: null,
    state: TaskState.PENDING,
    type: TaskType.ADD_BENEFICIARY,
    entityType: TaskEntityType.BENEFICIARY,
    entityId: '858ceb25-a01e-46d4-8547-86ab4da368f0',
    taskDetails: {
      beneficiaryName: 'Task Inc',
      beneficiaryCountry: CountryCode.US,
      beneficiaryEntityType: EntityType.BUSINESS,
      beneficiaryAccountNumber: '19475164',
      beneficiaryPaymentMethod: PaymentMethod.ACH,
      beneficiaryRoutingNumber: '733062425',
    },
    completedByBusinessUserId: null,
    completedOn: null,
    createdOn: '2024-09-02T13:25:42.302934',
  },
  {
    id: 'a046c9dc-f581-4f36-82a6-19ee5510a87f',
    businessId: 'c002df21-b54c-4ace-8f54-52b4d3a2a2bb',
    businessUserId: 'b89ff7c0-c562-49ae-aafc-b862ed65bb2b',
    businessIdentityId: 'fdc3dfcd-efe3-4434-ab36-94e4bac66f6d',
    createdByFirstName: 'Maks',
    createdByLastName: 'Ops',
    createdBySecondLastName: null,
    state: TaskState.PENDING,
    type: TaskType.EXECUTE_WITHDRAWAL,
    entityType: TaskEntityType.WITHDRAWAL,
    entityId: 'e27531d8-8c31-491e-83c7-2f7b4d71d0e1',
    taskDetails: {
      localAmount: '202.00',
      walletLabel: 'Main account',
      beneficiaryId: '78311a1f-466d-4610-822d-41d4738b4846',
      localCurrency: 'USD',
      beneficiaryName: 'Test Inc',
      beneficiaryEntityType: 'INDIVIDUAL',
    },
    completedByBusinessUserId: null,
    completedOn: null,
    createdOn: '2024-10-07T11:40:27.459563',
  } as ExecuteWithdrawalTask,
]

export const MOCK_WITHDRAWAL_TASK_DETAILS: WithdrawalTaskDetails = {
  id: 'e27531d8-8c31-491e-83c7-2f7b4d71d0e1',
  businessId: 'c002df21-b54c-4ace-8f54-52b4d3a2a2bb',
  businessUserId: 'b89ff7c0-c562-49ae-aafc-b862ed65bb2b',
  walletId: '0ceaf1d2-20b6-4de0-8e74-ad514f0df81c',
  walletLabel: 'Main account',
  beneficiaryId: '78311a1f-466d-4610-822d-41d4738b4846',
  localAmount: '202',
  localCurrency: Currency.USD,
  baseAmount: '202',
  baseCurrency: Currency.USDC,
  fxRate: '1',
  additionalDetails: {
    paymentMessage: 'hello there',
  },
  type: 'INTERNAL',
  createdOn: '2024-10-07T11:40:27.365887',
}
