import { FormattedMessage } from 'react-intl'

import { badgeVariantByContractorState } from '@/features/Contractors/constants'
import { Contractor } from '@/features/Contractors/types'
import { StatusBadge } from '@/shared/components'

type Props = {
  state: Contractor['state']
}

export const ContractorStateBadge = ({ state }: Props) => {
  return (
    <StatusBadge
      variant={badgeVariantByContractorState[state]}
      title={
        <FormattedMessage
          id="contractor.state.label"
          defaultMessage="{state, select, ACTION_NEEDED {Action needed} WAITING_FOR_CONTRACTOR {Waiting for contractor} other {}}"
          values={{ state }}
        />
      }
    />
  )
}
