import { useIntl } from 'react-intl'

import { Eye, EyeOff } from '../icons/outline'
import { Button } from '../ui'

type Props = {
  show: boolean
  onClick: () => void
}

export const EyeOnOffButton = ({ show, onClick }: Props) => {
  const intl = useIntl()

  return (
    <Button
      variant="ghost"
      size="inline"
      onClick={onClick}
      type="button"
      className="absolute right-4 top-5 text-neutral-gray-400"
      aria-label={
        show
          ? intl.formatMessage({
              defaultMessage: 'Show password',
              id: 'action.showPassword',
            })
          : intl.formatMessage({
              defaultMessage: 'Hide password',
              id: 'action.hidePassword',
            })
      }
    >
      {show ? <Eye className="size-4" /> : <EyeOff className="size-4" />}
    </Button>
  )
}
