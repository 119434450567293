import { RouteObject } from 'react-router-dom'

import {
  ONBOARDING_COMPLETED_ROUTE,
  ONBOARDING_CREATE_OWNER,
  ONBOARDING_ROUTE,
  ONBOARDING_START_ROUTE,
  ONBOARDING_STEP,
  ONBOARDING_VALIDATE_IDENTITY,
} from '@/constants/paths'

import { CreateOwner } from './CreateOwner'
import { Layout } from './Layout'
import { Onboarding } from './Onboarding'
import { OnboardingComplete } from './OnboardingComplete'
import { OnboardingStart } from './OnboardingStart'
import { OnboardingSteps } from './OnboardingSteps'
import { ValidateIdentityScreen } from './ValidateIdentityScreen'

export const onboardingRoutes: RouteObject[] = [
  {
    element: <Layout />,
    children: [
      {
        path: ONBOARDING_ROUTE,
        element: <Onboarding />,
      },
      {
        path: ONBOARDING_START_ROUTE,
        element: <OnboardingStart />,
      },
      {
        path: ONBOARDING_STEP,
        element: <OnboardingSteps />,
      },
      {
        path: ONBOARDING_COMPLETED_ROUTE,
        element: <OnboardingComplete />,
      },
    ],
  },
  {
    path: ONBOARDING_CREATE_OWNER,
    element: <CreateOwner />,
  },
  {
    path: ONBOARDING_VALIDATE_IDENTITY,
    element: <ValidateIdentityScreen />,
  },
]
