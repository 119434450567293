import messagesEN from '../assets/locales/en.json'
import messagesES from '../assets/locales/es.json'

export function loadMessages(locale: string) {
  switch (locale) {
    case 'es':
      return messagesES

    case 'en':
      return messagesEN

    default:
      return messagesEN
  }
}

export function getUserLocale() {
  const locale = navigator.language

  const [lang] = locale.split('-')

  if (lang === 'en' || lang === 'es') {
    return lang
  }

  return 'en'
}
