import { forwardRef, Ref, type SVGProps } from 'react'

const SvgLightbulbOn = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill="#5c5c77"
      d="M13.167 18.086h-.75v.034zm-.83.914v.75l.038-.001zm-1.673 0-.038.749.038.001zm-.83-.914.749.034v-.034zm0-1.775h.75a.75.75 0 0 0-.492-.704zm-3.115-5.803-.73-.174zm5.396-3.776-.087.745zm4.385 4.914.75.002v-.004zm-3.333 4.665-.246-.709a.75.75 0 0 0-.504.709zm-.75 1.81a.125.125 0 0 1-.118.13l.076 1.498a1.625 1.625 0 0 0 1.541-1.697zm-.08.129h-1.673v1.5h1.673zm-1.635 0a.125.125 0 0 1-.119-.13l-1.498-.068a1.625 1.625 0 0 0 1.541 1.697zm-.118-.164v-1.775h-1.5v1.775zm-.492-2.48a4.21 4.21 0 0 1-2.644-4.924l-1.459-.348a5.71 5.71 0 0 0 3.586 6.681zm-2.644-4.924a4.21 4.21 0 0 1 4.58-3.205l.173-1.49a5.71 5.71 0 0 0-6.212 4.347zm4.58-3.205a4.21 4.21 0 0 1 3.722 4.17l1.5-.003A5.71 5.71 0 0 0 12.2 5.987zm3.722 4.167a4.2 4.2 0 0 1-2.829 3.958l.491 1.418a5.7 5.7 0 0 0 3.838-5.372zm-3.333 4.667v1.775h1.5v-1.775z"
    />
    <path
      fill="#5c5c77"
      d="M13.167 17.061a.75.75 0 0 0 0-1.5zm-3.334-1.5a.75.75 0 0 0 0 1.5zM21 12.396a.75.75 0 1 0 0-1.5zm-2-1.5a.75.75 0 0 0 0 1.5zm-15 1.5a.75.75 0 1 0 0-1.5zm-2-1.5a.75.75 0 0 0 0 1.5zm16.745-5.362a.75.75 0 0 0-1.054-1.068zm-2.472.332a.75.75 0 0 0 1.054 1.068zm-9.545 11.56a.75.75 0 1 0-1.056-1.066zm-2.47.334a.75.75 0 1 0 1.056 1.066zm13.435 1.066a.75.75 0 1 0 1.056-1.066zm-.358-2.466a.75.75 0 0 0-1.056 1.066zM5.673 6.934a.75.75 0 1 0 1.054-1.068zM5.31 4.466a.75.75 0 0 0-1.054 1.068zm7.858 11.095H9.833v1.5h3.334zM21 10.896h-2v1.5h2zm-17 0H2v1.5h2zm13.691-6.43-1.418 1.4 1.054 1.068 1.418-1.4zM5.672 16.36l-1.414 1.4 1.056 1.066 1.414-1.4zm13.077 1.4-1.414-1.4-1.056 1.066 1.414 1.4zM6.727 5.866l-1.418-1.4-1.054 1.068 1.418 1.4z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgLightbulbOn)
export default ForwardRef
