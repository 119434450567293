import { HTMLAttributes } from 'react'
import { motion, MotionProps } from 'framer-motion'

import { cn } from '@/lib/utils'

type Props = {
  children: React.ReactNode
  className?: string
} & MotionProps &
  HTMLAttributes<HTMLDivElement>

export const MotionDiv = ({
  children,
  className,
  ...rest
}: Props & MotionProps) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 10 }}
      transition={{ duration: 0.2 }}
      {...rest}
      className={cn('', className)}
    >
      {children}
    </motion.div>
  )
}
