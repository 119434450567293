import { forwardRef, Ref, type SVGProps } from 'react'

const SvgBuilding = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 20 20"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill={props.color || `currentColor`}
      d="M9.292 4.27H3.745a.93.93 0 0 0-.653.27.92.92 0 0 0-.27.648v10.496a.26.26 0 0 0 .264.262h2.52v-2.269h1.826v2.269h2.52a.264.264 0 0 0 .263-.262V5.188a.91.91 0 0 0-.57-.848.9.9 0 0 0-.353-.07M5.8 12.249H4.35v-1.441H5.8zm0-2.514H4.35V8.293H5.8zm0-2.514H4.35V5.778H5.8zm2.894 5.028h-1.45v-1.441h1.45zm0-2.514h-1.45V8.293h1.45zm0-2.514h-1.45V5.778h1.45zM16.415 7.45h-4.444a.93.93 0 0 0-.652.268.92.92 0 0 0-.27.649v7.319a.26.26 0 0 0 .263.262h1.968v-2.269h1.826v2.269h1.968a.265.265 0 0 0 .264-.262v-7.32a.92.92 0 0 0-.27-.648.93.93 0 0 0-.653-.269m-2.943 5.307h-1.45v-1.442h1.45zm0-2.514h-1.45V8.8h1.45zm2.894 2.514h-1.45v-1.442h1.45zm0-2.514h-1.45V8.8h1.45z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgBuilding)
export default ForwardRef
