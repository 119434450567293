import { api } from '@/lib/api'

import { BulkPaymentQuote } from '../types'

type QuoteBulkPaymentRequest = {
  bulkPaymentId: string
  data: {
    walletId: string
    selectedPayments: string[]
  }
}

export function quoteBulkPayment({
  bulkPaymentId,
  data,
}: QuoteBulkPaymentRequest) {
  return api.post<BulkPaymentQuote>(
    `/v1/me/bulk-payments/${bulkPaymentId}/quote`,
    data,
  )
}
