import { useCallback, useState } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { z } from 'zod'

import { FORGOT_PASSWORD_ROUTE, SIGNUP_ROUTE } from '@/constants/paths'
import { useErrorToast } from '@/hooks/useErrorToast'
import { cn } from '@/lib/utils'
import { EyeOnOffButton } from '@/shared/components'
import {
  AnimatedFormLabel,
  Button,
  buttonVariants,
  Form,
  FormControl,
  FormField,
  FormItem,
  Input,
  SlideInScreen,
  Typography,
} from '@/shared/ui'

import { authenticate } from '../api'

const SIGN_IN_FORM_ID = 'sign-in-form'

const DEFAULT_VALUES: SignInSchema = {
  email: '',
  password: '',
}

const signInSchema = z.object({
  password: z.string().min(1, {
    message: 'validation.password.required',
  }),
  email: z
    .string()
    .min(1, {
      message: 'validation.email.required',
    })
    .email({
      message: 'validation.email.invalid',
    }),
})

export type SignInSchema = z.infer<typeof signInSchema>

type FormData = {
  email: string
  password: string
  identityId: string
}

type Props = {
  onContinue: (data: FormData) => void
}

export const CredentialsForm = ({ onContinue }: Props) => {
  const notifyError = useErrorToast()
  const [showPassword, setShowPassword] = useState(false)
  const intl = useIntl()

  const { mutateAsync, isPending } = useMutation({
    mutationFn: authenticate,
  })

  const form = useForm<SignInSchema>({
    mode: 'onChange',
    resolver: zodResolver(signInSchema),
    defaultValues: DEFAULT_VALUES,
  })

  const onSubmit: SubmitHandler<SignInSchema> = useCallback(
    async (data) => {
      try {
        const response = await mutateAsync({
          ...data,
          email: data.email.toLowerCase(),
        })

        onContinue({
          identityId: response.data.identityId,
          email: data.email,
          password: data.password,
        })
      } catch (error: unknown) {
        if (error instanceof Error) {
          notifyError(error)
        }
      }
    },
    [mutateAsync, notifyError, onContinue],
  )

  return (
    <SlideInScreen>
      <Typography text="center" variant="h3">
        <FormattedMessage defaultMessage="Welcome back" id="signIn.welcome" />
      </Typography>

      <div className="p-6" />

      <Form {...form}>
        <form id={SIGN_IN_FORM_ID} onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex w-full flex-col gap-3">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      autoComplete="email"
                      placeholder={intl.formatMessage({
                        defaultMessage: 'Email',
                        id: 'label.email',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage defaultMessage="Email" id="label.email" />
                  </AnimatedFormLabel>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      autoComplete="current-password"
                      type={showPassword ? 'text' : 'password'}
                      placeholder={intl.formatMessage({
                        defaultMessage: 'Password',
                        id: 'label.password',
                      })}
                      {...field}
                    />
                  </FormControl>
                  <AnimatedFormLabel>
                    <FormattedMessage
                      defaultMessage="Password"
                      id="label.password"
                    />
                  </AnimatedFormLabel>

                  <EyeOnOffButton
                    show={showPassword}
                    onClick={() => setShowPassword((prev) => !prev)}
                  />
                </FormItem>
              )}
            />
          </div>
        </form>
      </Form>

      <div className="p-8" />

      <div className="mt-auto flex flex-col gap-3">
        <Button
          width="full"
          loading={isPending}
          form={SIGN_IN_FORM_ID}
          onClick={form.handleSubmit(onSubmit)}
          disabled={isPending || !form.formState.isValid}
          type="submit"
        >
          <FormattedMessage defaultMessage="Sign in" id="action.signIn" />
        </Button>

        <div className="flex flex-col gap-3 lg:items-center">
          <Typography className="flex flex-wrap justify-center gap-1">
            <FormattedMessage
              defaultMessage="Forgot your password?"
              id="auth.forgotPassword"
            />
            <Link
              className={cn(
                buttonVariants({ variant: 'link', size: 'inline' }),
              )}
              to={FORGOT_PASSWORD_ROUTE}
            >
              <FormattedMessage
                defaultMessage="Click here to reset your password"
                id="auth.resetPassword"
              />
            </Link>
          </Typography>
          <Typography className="flex flex-wrap justify-center gap-1">
            <FormattedMessage
              defaultMessage="New to DolarApp?"
              id="auth.newToDolarApp"
            />
            <Link
              className={cn(
                buttonVariants({ variant: 'link', size: 'inline' }),
              )}
              to={SIGNUP_ROUTE}
            >
              <FormattedMessage
                defaultMessage="Create your free account"
                id="auth.createAccount"
              />
            </Link>
          </Typography>
        </div>
      </div>

      <div className="p-6" />
    </SlideInScreen>
  )
}
