import { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useIntl } from 'react-intl'
import { useSearchParams } from 'react-router-dom'

import { queryKeys } from '@/constants/queryKeys'
import { getAccount } from '@/features/Accounts/api'
import { Spinner } from '@/shared/ui'

import { ACCOUNTS_FILTER_NAME } from './data'
import { FilterBadge } from './FilterBadge'

export const AccountsBadge = () => {
  const intl = useIntl()
  const [searchParams, setSearchParams] = useSearchParams()
  const accountQuery = useQuery({
    queryKey: [queryKeys.getAccount],
    queryFn: getAccount,
    select: (data) => data.data.wallets,
  })

  const selectedAccounts = useMemo(
    () =>
      searchParams
        .get(ACCOUNTS_FILTER_NAME)
        ?.split(',')
        .map((value) => {
          const account = accountQuery.data?.find(
            (account) => account.id === value,
          )

          return account?.label
        })
        .join(', '),
    [accountQuery.data, searchParams],
  )

  if (!searchParams.get(ACCOUNTS_FILTER_NAME)) {
    return null
  }

  return (
    <FilterBadge
      onClick={() => {
        setSearchParams(
          (params) => {
            params.delete(ACCOUNTS_FILTER_NAME)

            return params
          },
          { preventScrollReset: true },
        )
      }}
      name={intl.formatMessage({
        id: 'label.accounts',
        defaultMessage: 'Accounts',
      })}
    >
      {accountQuery.isPending ? (
        <Spinner className="size-4" />
      ) : (
        selectedAccounts
      )}
    </FilterBadge>
  )
}
