import { FormattedMessage } from 'react-intl'

import { formatCurrency, formatMoney } from '@/lib/money'
import { Badge, Checkbox, Typography } from '@/shared/ui'

import { CSVTransaction } from '../types'
import { getName } from '../utils'

import { CSVTransactionDetailsRow } from './CSVTransactionDetailsRow'

type Props = {
  transaction: CSVTransaction
  selectedTransactions?: string[]
  onSelect?: (ids: string[]) => void
}

export const CSVTransactionRow = ({
  onSelect,
  selectedTransactions,
  transaction,
}: Props) => {
  const handleSelect = () => {
    if (!onSelect) return
    const ids = selectedTransactions?.includes(transaction.id)
      ? selectedTransactions.filter((id) => id !== transaction.id)
      : [...(selectedTransactions ?? []), transaction.id]

    onSelect(ids)
  }

  return (
    <div
      role="button"
      tabIndex={0}
      className="flex cursor-pointer items-center justify-between transition-transform hover:scale-[1.01] focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-neutral-gray-600 focus-visible:ring-offset-2"
      onClick={handleSelect}
      onKeyDown={(e) => {
        if (e.key === ' ') {
          handleSelect()
        }
      }}
    >
      <div className="flex items-center gap-4">
        {onSelect ? (
          <Checkbox
            tabIndex={-1}
            checked={selectedTransactions?.includes(transaction.id)}
          />
        ) : null}

        <div className="flex flex-col gap-1 py-2">
          <div className="flex items-center gap-2">
            <Typography bold>
              {getName(transaction.paymentDetails) ?? (
                <span className="inline-flex h-5" />
              )}
            </Typography>
            {!transaction.beneficiaryId && (
              <Badge>
                <FormattedMessage id="label.new" defaultMessage="New" />
              </Badge>
            )}
          </div>
          <CSVTransactionDetailsRow transaction={transaction} />
        </div>
      </div>

      <div className="flex flex-col gap-1">
        <Typography text="right" bold>
          {formatMoney(transaction.paymentAmount)}{' '}
          {formatCurrency(transaction.paymentCurrency)}
        </Typography>
        <Typography text="right" className="text-neutral-gray-600">
          {formatMoney(transaction.baseAmount)}{' '}
          {formatCurrency(transaction.baseCurrency)}
        </Typography>
      </div>
    </div>
  )
}
