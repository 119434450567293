import { useMutation, useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { FormattedMessage, useIntl } from 'react-intl'
import { generatePath, Link, useParams } from 'react-router-dom'

import { ACCOUNTS_ROUTE, SINGLE_ACCOUNT_ROUTE } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { Account } from '@/features/Accounts/types'
import { Transaction } from '@/features/Transactions/types'
import { useErrorToast } from '@/hooks/useErrorToast'
import { useSearchInput } from '@/hooks/useSearchInput'
import { useTransactionUtils } from '@/hooks/useTransactionUtils'
import { slugify } from '@/lib/typography'
import { downloadFile } from '@/lib/utils'
import {
  ACCOUNTS_FILTER_NAME,
  AccountsBadge,
  ActiveFilters,
  CurrencyBadge,
  CurrencyFilter,
  DateBadge,
  DateFilter,
  EmptyTransactionsCard,
  FiltersWidget,
  MoveMoneyWidget,
  StateBadge,
  StateFilter,
  TransactionDetailsSidebar,
  TransactionsTable,
  TypeBadge,
  TypeFilter,
} from '@/shared/components'
import { DownloadFile } from '@/shared/icons/outline'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
  Button,
  SearchInput,
  Skeleton,
  Typography,
} from '@/shared/ui'

import {
  getTransactionByType,
  getTransactions,
  getTransactionsCSV,
} from '../Transactions/api'

import { getAccount } from './api'
import { useAccountsTransactionColumns } from './components/useAccountTransactionsColumns'

export const AccountTransactions = () => {
  const intl = useIntl()
  const columns = useAccountsTransactionColumns()
  const { id: walletId } = useParams<{ id: string }>()
  const [search, setSearch, handleSearchQuery] = useSearchInput()
  const notifyError = useErrorToast()

  const {
    closeSidebar,
    openSidebar,
    params,
    isSidebarOpen,
    transactionIdFromQuery,
    transactionTypeFromQuery,
  } = useTransactionUtils()

  const [allAccountTransactions, singleTransaction, accountQuery] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getTransactions, walletId, params],
        queryFn: () =>
          getTransactions({
            ...params,
            [ACCOUNTS_FILTER_NAME]: walletId,
          }),
        select: (data: AxiosResponse<Transaction[]>) => data?.data,
        staleTime: 0,
      },
      {
        queryKey: [
          queryKeys.getTransaction,
          transactionIdFromQuery,
          transactionTypeFromQuery,
        ],
        queryFn: () =>
          getTransactionByType({
            type: transactionTypeFromQuery,
            id: transactionIdFromQuery,
          }),
        select: (data: AxiosResponse<Transaction>) => data?.data,
        enabled: !!transactionIdFromQuery,
      },
      {
        queryKey: [queryKeys.getAccount],
        queryFn: getAccount,
        select: (data: AxiosResponse<Account>) =>
          data?.data.wallets.find((wallet) => wallet.id === walletId),
      },
    ],
  })

  const { mutateAsync, isPending } = useMutation({
    mutationFn: getTransactionsCSV,
  })

  const downloadCSV = async () => {
    try {
      const response = await mutateAsync({
        ...params,
        [ACCOUNTS_FILTER_NAME]: walletId,
      })

      downloadFile(
        response.data,
        intl.formatMessage(
          {
            id: 'account.transactions.statement.csv',
            defaultMessage: 'transactions-{account}.csv',
          },
          { account: slugify(accountQuery.data?.label) },
        ),
      )
    } catch (error) {
      if (error instanceof Error) {
        notifyError(error)
      }
    }
  }

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full flex-wrap justify-between gap-6">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbLink asChild>
              <Button variant="ghost" size="inline" asChild>
                <Link to={ACCOUNTS_ROUTE}>
                  <FormattedMessage
                    id="dashboard.accounts.title"
                    defaultMessage="My accounts"
                  />
                </Link>
              </Button>
            </BreadcrumbLink>

            <BreadcrumbSeparator />

            <BreadcrumbLink asChild>
              <Button variant="ghost" size="inline" asChild>
                <Link
                  to={generatePath(SINGLE_ACCOUNT_ROUTE, {
                    id: walletId ?? '',
                  })}
                >
                  {accountQuery.data?.label ?? (
                    <Skeleton className="h-6 w-36" />
                  )}
                </Link>
              </Button>
            </BreadcrumbLink>

            <BreadcrumbSeparator />

            <BreadcrumbItem>
              <Typography>
                <FormattedMessage
                  id="label.transactions"
                  defaultMessage="Transactions"
                />
              </Typography>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>

        <MoveMoneyWidget />
      </div>

      <div className="p-4" />

      <div className="flex flex-wrap gap-3 md:flex-nowrap">
        <SearchInput
          value={search}
          onChange={(value) => {
            setSearch(value)
            handleSearchQuery(value)
          }}
        />

        <FiltersWidget>
          <TypeFilter />
          <DateFilter />
          <StateFilter />
          <CurrencyFilter />
        </FiltersWidget>

        <Button
          size="md"
          loading={isPending}
          loaderPosition="left"
          leftIcon={<DownloadFile className="size-5" />}
          onClick={downloadCSV}
          disabled={allAccountTransactions.data?.length === 0 || isPending}
          variant="tertiary"
        >
          <FormattedMessage
            id="action.exportCSV"
            defaultMessage="Export .csv"
          />
        </Button>
      </div>

      <div className="p-4" />

      <ActiveFilters>
        <TypeBadge />
        <AccountsBadge />
        <DateBadge />
        <StateBadge />
        <CurrencyBadge />
      </ActiveFilters>

      {allAccountTransactions.data?.length === 0 &&
      Object.keys(params).length === 0 ? (
        <EmptyTransactionsCard />
      ) : (
        <TransactionsTable
          isLoading={allAccountTransactions.isPending}
          onRowClick={openSidebar}
          columns={columns}
          data={allAccountTransactions.data ?? []}
          loaderOptions={{ rows: 5 }}
        />
      )}

      <TransactionDetailsSidebar
        transaction={singleTransaction.data}
        isOpen={isSidebarOpen}
        onOpenChange={closeSidebar}
      />
    </div>
  )
}
