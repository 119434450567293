import { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import {
  CONTRACTORS_ALL_ROUTE,
  CONTRACTORS_HISTORICAL_PAYMENTS_ROUTE,
  CONTRACTORS_OVERVIEW_ROUTE,
} from '@/constants/paths'
import { MoveMoneyWidget } from '@/shared/components'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  Typography,
} from '@/shared/ui'

enum Tab {
  OVERVIEW = 'overview',
  ALL_CONTRACTORS = 'all-contractors',
  HISTORICAL_PAYMENTS = 'historical-payments',
}

export const Contractors = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const defaultTab = useMemo(() => {
    if (pathname.includes(CONTRACTORS_OVERVIEW_ROUTE)) {
      return Tab.OVERVIEW
    }

    if (pathname.includes(CONTRACTORS_HISTORICAL_PAYMENTS_ROUTE)) {
      return Tab.HISTORICAL_PAYMENTS
    }

    if (pathname.includes(CONTRACTORS_ALL_ROUTE)) {
      return Tab.ALL_CONTRACTORS
    }

    return Tab.OVERVIEW
  }, [pathname])

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full items-start justify-between">
        <div className="flex flex-col gap-2">
          <Typography variant="h3">
            <FormattedMessage
              id="dashboard.contractors.title"
              defaultMessage="Contractors"
            />
          </Typography>
          <Typography className="text-neutral-gray-600">
            <FormattedMessage
              id="dashboard.contractors.subtitle"
              defaultMessage="Pay and manage your company contractors"
            />
          </Typography>
        </div>

        <MoveMoneyWidget />
      </div>

      <div className="p-4" />

      <Tabs
        onValueChange={(tab) => {
          switch (tab) {
            case Tab.OVERVIEW:
              navigate(CONTRACTORS_OVERVIEW_ROUTE)
              break
            case Tab.ALL_CONTRACTORS:
              navigate(CONTRACTORS_ALL_ROUTE)
              break
            case Tab.HISTORICAL_PAYMENTS:
              navigate(CONTRACTORS_HISTORICAL_PAYMENTS_ROUTE)
              break
          }
        }}
        defaultValue={defaultTab}
      >
        <TabsList variant="navigation" className="mb-8 gap-4">
          <TabsTrigger size="inline" variant="navigation" value={Tab.OVERVIEW}>
            <FormattedMessage
              id="contractors.tabs.overview"
              defaultMessage="Overview"
            />
          </TabsTrigger>
          <TabsTrigger
            size="inline"
            variant="navigation"
            value={Tab.ALL_CONTRACTORS}
          >
            <FormattedMessage
              id="contractors.tabs.allContractors"
              defaultMessage="All Contractors"
            />
          </TabsTrigger>
          <TabsTrigger
            size="inline"
            variant="navigation"
            value={Tab.HISTORICAL_PAYMENTS}
          >
            <FormattedMessage
              id="contractors.tabs.historicalPayments"
              defaultMessage="Historical Payments"
            />
          </TabsTrigger>
        </TabsList>

        <TabsContent value={Tab.OVERVIEW}>
          <Outlet />
        </TabsContent>
        <TabsContent value={Tab.ALL_CONTRACTORS}>
          <Outlet />
        </TabsContent>
        <TabsContent value={Tab.HISTORICAL_PAYMENTS}>
          <Outlet />
        </TabsContent>
      </Tabs>
    </div>
  )
}
