import { Fragment, useMemo } from 'react'
import { defineMessage, FormattedMessage, MessageDescriptor } from 'react-intl'

import {
  Bank,
  Desktop,
  Document,
  IdCard,
  Location,
  User,
} from '@/shared/icons/solid'
import { Card, Typography } from '@/shared/ui'
import { Icon } from '@/types/global'

import { OnboardingStep, OnboardingStepName } from '../types'

type DocumentItem = {
  stepName: OnboardingStepName
  title: MessageDescriptor
  icon: Icon
  items: {
    title: MessageDescriptor
    subItems?: {
      title: MessageDescriptor
    }[]
  }[]
}

const DOCUMENTS_LIST: DocumentItem[] = [
  {
    stepName: OnboardingStepName.COMPANY_ACTIVITY,
    icon: Desktop,
    title: defineMessage({
      id: 'onboarding.docs.companyActivity',
      defaultMessage: 'Company activity',
    }),
    items: [
      {
        title: defineMessage({
          id: 'onboarding.docs.companyActivityIndustry',
          defaultMessage: "Company's industry / sector of activity",
        }),
      },
      {
        title: defineMessage({
          id: 'onboarding.docs.companyActivityVolume',
          defaultMessage: "Company's monthly transaction volume",
        }),
      },
      {
        title: defineMessage({
          id: 'onboarding.docs.companyActivityFunds',
          defaultMessage: 'Source of funds',
        }),
      },
    ],
  },
  {
    stepName: OnboardingStepName.COMPANY_FORMATION,
    icon: Document,
    title: defineMessage({
      id: 'onboarding.docs.companyFormation',
      defaultMessage: 'Company formation',
    }),
    items: [
      {
        title: defineMessage({
          id: 'onboarding.docs.countryOfIncorporationAndDate',
          defaultMessage: 'Country and date of incorporation',
        }),
      },
      {
        title: defineMessage({
          id: 'onboarding.docs.taxIdAndCertificate',
          defaultMessage:
            'Tax identification number and certificate of issuance',
        }),
      },
      {
        title: defineMessage({
          id: 'onboarding.docs.certOfIncorporation',
          defaultMessage: 'Certificate of incorporation and bylaws',
        }),
      },
    ],
  },
  {
    stepName: OnboardingStepName.BUSINESS_ADDRESS,
    icon: Location,
    title: defineMessage({
      id: 'onboarding.docs.businessAddress',
      defaultMessage: 'Business address',
    }),
    items: [
      {
        title: defineMessage({
          id: 'onboarding.docs.fullBusinessAddress',
          defaultMessage: 'Full business address',
        }),
      },

      {
        title: defineMessage({
          id: 'onboarding.docs.proofOfAddress',
          defaultMessage: 'Proof of address',
        }),
      },
    ],
  },
  {
    stepName: OnboardingStepName.LEGAL_REPRESENTATIVE,
    icon: User,
    title: defineMessage({
      id: 'onboarding.docs.legalRepresentative',
      defaultMessage: 'Legal representative',
    }),
    items: [
      {
        title: defineMessage({
          id: 'onboarding.docs.nameAndLastName',
          defaultMessage: 'Name and last name',
        }),
      },
      {
        title: defineMessage({
          id: 'onboarding.docs.workEmailAndPhone',
          defaultMessage: 'Work email and phone number',
        }),
      },
      {
        title: defineMessage({
          id: 'onboarding.docs.countryAndLegalTaxId',
          defaultMessage:
            'Country of tax residence and tax identification number',
        }),
      },
    ],
  },
  {
    stepName: OnboardingStepName.COMPANY_OWNERSHIP,
    icon: Bank,
    title: defineMessage({
      id: 'onboarding.docs.ownership',
      defaultMessage: 'Ownership',
    }),
    items: [
      {
        title: defineMessage({
          id: 'onboarding.docs.identifyOwners',
          defaultMessage:
            'Identify all individuals with at least 25% ownership and submit:',
        }),
        subItems: [
          {
            title: defineMessage({
              id: 'onboarding.docs.ownersNameAndLastName',
              defaultMessage: 'Name and last name',
            }),
          },
          {
            title: defineMessage({
              id: 'onboarding.docs.ownersWorkEmailAndPhone',
              defaultMessage: 'Email and phone number',
            }),
          },
          {
            title: defineMessage({
              id: 'onboarding.docs.countryAndTaxId',
              defaultMessage:
                'Country of tax residence and tax identification number',
            }),
          },
        ],
      },
      {
        title: defineMessage({
          id: 'onboarding.docs.officialRegistry',
          defaultMessage: 'Official share registry',
        }),
      },
    ],
  },
  {
    stepName: OnboardingStepName.IDENTITY_VALIDATION,
    icon: IdCard,
    title: defineMessage({
      id: 'onboarding.docs.identityValidation',
      defaultMessage: 'Identity validation',
    }),
    items: [
      {
        title: defineMessage({
          id: 'onboarding.docs.identityValidation.legalRepresentative',
          defaultMessage:
            'Legal representative: Take a selfie with their valid legal ID',
        }),
      },
      {
        title: defineMessage({
          id: 'onboarding.docs.identityValidation.owner',
          defaultMessage:
            'Beneficial owners: Upload a picture of their valid legal ID',
        }),
      },
    ],
  },
]

type Props = {
  steps?: OnboardingStep[]
}

export const DocumentsList = ({ steps }: Props) => {
  const stepNames = useMemo(
    () => steps?.map((step) => step.name) ?? [],
    [steps],
  )

  const orderListByStepNames = useMemo(() => {
    return DOCUMENTS_LIST.sort((a, b) => {
      const stepA = stepNames.indexOf(a.stepName)
      const stepB = stepNames.indexOf(b.stepName)

      if (stepA === -1) return 1
      if (stepB === -1) return -1

      return stepA - stepB
    })
  }, [stepNames])

  return (
    <Card className="flex flex-col gap-4" size="small">
      {orderListByStepNames.map(({ title, items, icon: Icon }, index) => (
        <div key={`${title.id}${index}`} className="flex flex-col gap-1">
          <div className="flex items-center gap-2">
            <Icon className="size-4" />

            <Typography>
              <FormattedMessage {...title} />
            </Typography>
          </div>

          <ul className="list-disc pl-[50px] text-neutral-gray-600">
            {items.map(({ title, subItems }, index) => (
              <Fragment key={`${title.id}${index}`}>
                <li>
                  <Typography>
                    <FormattedMessage {...title} />
                  </Typography>
                </li>
                {subItems?.length ? (
                  <ul className="list-disc pl-4 text-neutral-gray-600">
                    {subItems.map(({ title }, index) => (
                      <li key={`${title.id}${index}`}>
                        <Typography>
                          <FormattedMessage {...title} />
                        </Typography>
                      </li>
                    ))}
                  </ul>
                ) : null}
              </Fragment>
            ))}
          </ul>
        </div>
      ))}
    </Card>
  )
}
