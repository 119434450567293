import { forwardRef, Ref, type SVGProps } from 'react'

const SvgHeadset = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill={props.color || `currentColor`}
      d="M4.285 8.344A2.75 2.75 0 0 0 2.25 11v3A2.75 2.75 0 0 0 5 16.75h2.5a.75.75 0 0 0 .75-.75V9a.75.75 0 0 0-.75-.75H5.815c.429-2.47 2.944-4.5 6.185-4.5s5.756 2.03 6.185 4.5H16.5a.75.75 0 0 0-.75.75v7c0 .414.336.75.75.75h1.663A3.25 3.25 0 0 1 15 19.25h-1.145a2 2 0 1 0 0 1.5H15c2.4 0 4.384-1.78 4.705-4.091A2.75 2.75 0 0 0 21.75 14v-3a2.75 2.75 0 0 0-2.035-2.656C19.333 4.84 15.926 2.25 12 2.25S4.667 4.84 4.285 8.344"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgHeadset)
export default ForwardRef
