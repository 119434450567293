import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import { CREATE_CARD_ROUTE } from '@/constants/paths'
import { getImage } from '@/lib/images'
import { Plus } from '@/shared/icons/outline'
import { Button, Card, MotionDiv, Typography } from '@/shared/ui'

export const ContractorsOverview = () => {
  return (
    <MotionDiv>
      <Card className="flex w-full flex-wrap items-center justify-between gap-3 px-6 py-8 pr-6 md:flex-nowrap md:gap-0">
        <div className="flex flex-wrap gap-8">
          <img
            className="w-12 object-contain"
            src={getImage({
              name: 'bell',
              category: 'contractors',
            })}
            alt=""
            aria-hidden={true}
          />
          <div className="flex flex-col justify-center gap-1">
            <Typography bold>
              <FormattedMessage
                id="contractors.paymentCycles.empty"
                defaultMessage="You don't have upcoming Payment cycles!"
              />
            </Typography>
            <Typography>
              <FormattedMessage
                id="contractors.paymentCycles.empty.subtitle"
                defaultMessage="Set up and manage your contractors first and check again"
              />
            </Typography>
          </div>
        </div>

        <Button leftIcon={<Plus className="size-5" />} asChild>
          <Link to={CREATE_CARD_ROUTE}>
            <FormattedMessage
              id="action.setUpContractors"
              defaultMessage="Set up contractors"
            />
          </Link>
        </Button>
      </Card>
    </MotionDiv>
  )
}
