import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'
import { toast } from 'sonner'

import { queryKeys } from '@/constants/queryKeys'
import { useErrorToast } from '@/hooks/useErrorToast'
import { hasOTPRequiredError } from '@/lib/error'
import { queryClient } from '@/lib/queryClient'
import { DeleteDialog, OTPDialog } from '@/shared/components'
import { Button } from '@/shared/ui'

import { deleteRecipient } from '../api'
import { CreatedRecipientWithId } from '../types'
import { getRecipientTitle } from '../utils'

type Props = {
  onDelete: () => void
  recipient?: CreatedRecipientWithId
}

export const DeleteRecipientAction = ({ onDelete, recipient }: Props) => {
  const intl = useIntl()
  const notifyError = useErrorToast()

  const [isDeleting, setIsDeleting] = useState(false)
  const [showDeleteOTPDialog, setShowDeleteOTPDialog] = useState(false)

  const { mutateAsync, isError, isPending, isSuccess } = useMutation({
    mutationFn: deleteRecipient,
  })

  const onDeleteCard = async (otp?: string) => {
    if (!recipient?.id) {
      toast.error('Recipient ID not found')
      return
    }

    try {
      await mutateAsync({ id: recipient.id, otp })

      await queryClient.refetchQueries({
        queryKey: [queryKeys.getSingleRecipient, recipient.id],
      })

      await queryClient.refetchQueries({
        queryKey: [queryKeys.getRecipients],
      })
      toast.success(
        intl.formatMessage(
          {
            id: 'recipient.delete.success',
            defaultMessage: '{name} has been successfully deleted',
          },
          {
            name: recipient.nickname,
          },
        ),
      )

      onDelete()
    } catch (error) {
      if (error instanceof Error) {
        if (hasOTPRequiredError(error)) {
          setShowDeleteOTPDialog(true)
          setIsDeleting(false)
          return
        }
        notifyError(error)
      }
    }
  }

  return (
    <>
      <Button
        width="full"
        onClick={() => setIsDeleting(true)}
        type="button"
        variant="ghost"
        className="text-primary-error"
      >
        <FormattedMessage
          defaultMessage="Delete recipient"
          id="action.deleteRecipient"
        />
      </Button>

      <DeleteDialog
        isOpen={isDeleting}
        onOpenChange={setIsDeleting}
        isPending={isPending}
        title={
          <FormattedMessage
            defaultMessage="Delete recipient?"
            id="recipient.delete.dialog.title"
          />
        }
        description={
          <FormattedMessage
            defaultMessage="Are you sure you want to delete {recipient}?"
            id="recipient.delete.dialog.description"
            values={{
              recipient: recipient?.nickname,
            }}
          />
        }
        descriptionAdditional={
          <FormattedMessage
            defaultMessage="The recipient will be deleted permanently"
            id="recipient.delete.dialog.deletedPermanently"
          />
        }
        onDelete={() => {
          onDeleteCard()
        }}
      />

      <OTPDialog
        isOpen={showDeleteOTPDialog}
        onOpenChange={setShowDeleteOTPDialog}
        isPending={isPending || isSuccess}
        isError={isError}
        title={
          <FormattedMessage
            id="card.deleteCard.dialog.title"
            defaultMessage="Enter verification code to confirm deletion"
          />
        }
        description={
          <FormattedMessage
            id="recipient.delete.dialog.otp.description"
            defaultMessage="We have emailed you with a verification code. Enter it to permanently terminate {recipient}"
            values={{
              recipient: getRecipientTitle(recipient),
            }}
          />
        }
        onContinue={(otp) => {
          onDeleteCard(otp)
        }}
      />
    </>
  )
}
