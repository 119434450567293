import { api } from '@/lib/api'

import { IdentityValidation } from '../types'

type StartValidationParams = {
  email: string
}

export function startIdentityValidation({ email }: StartValidationParams) {
  return api.post<IdentityValidation>(`/v1/onboardings/validations`, { email })
}
