import { forwardRef, Ref, type SVGProps } from 'react'

const SvgInvoice = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 20 21"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill={props.color || `currentColor`}
      d="M11.25 8.625A.625.625 0 0 0 10.625 8h-5a.625.625 0 1 0 0 1.25h5c.345 0 .625-.28.625-.625M10.417 11.125a.625.625 0 0 0-.625-.625H5.625a.625.625 0 1 0 0 1.25h4.167c.345 0 .625-.28.625-.625M10.625 13a.625.625 0 1 1 0 1.25h-5a.625.625 0 1 1 0-1.25z"
    />
    <path
      fill={props.color || `currentColor`}
      fillRule="evenodd"
      d="M5 18.625h10.833a2.29 2.29 0 0 0 2.292-2.292V11.75a.625.625 0 0 0-.625-.625h-2.708V4.619c0-1.186-1.341-1.876-2.306-1.186l-.146.104a1.88 1.88 0 0 1-2.185-.004 3.14 3.14 0 0 0-3.643 0 1.88 1.88 0 0 1-2.185.004l-.146-.104c-.965-.69-2.306 0-2.306 1.186V15.5c0 1.726 1.4 3.125 3.125 3.125M7.238 4.55a1.89 1.89 0 0 1 2.19 0c1.085.775 2.55.781 3.638.004l.146-.104c.138-.099.33 0 .33.17v11.713c0 .375.09.73.25 1.042H5A1.875 1.875 0 0 1 3.125 15.5V4.62c0-.17.192-.269.33-.17l.145.104a3.13 3.13 0 0 0 3.638-.004m7.554 11.783v-3.958h2.083v3.958a1.042 1.042 0 0 1-2.083 0"
      clipRule="evenodd"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgInvoice)
export default ForwardRef
