import { CountryCode } from '@/types/country'

import { Contractor, ContractorPaymentType, ContractorState } from '../../types'

export const MOCKED_CONTRACTORS: Contractor[] = [
  {
    id: '64e055a8-800a-4dc4-9713-cad62493dcdd',
    firstName: 'Test',
    lastName: 'Contract',
    secondLastName: 'Junior',
    email: 'test@dolarapp.com',
    contractorRole: 'Software Engineer',
    countryOfCitizenship: CountryCode.MX,
    paymentType: ContractorPaymentType.FIXED_RATE,
    startDate: '2025-09-06',
    lastPaymentDate: null,
    state: ContractorState.WAITING_FOR_CONTRACTOR,
  },
]
