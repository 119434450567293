import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { z } from 'zod'

import { SIGNING_IN_ROUTE } from '@/constants/paths'
import { useErrorToast } from '@/hooks/useErrorToast'
import { useSearchParamsValue } from '@/hooks/useSearchParamsValue'
import { useAuth } from '@/providers/AuthProvider'
import {
  Button,
  Form,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'

import { acceptInvitation } from './api'
import { PasswordFields } from './components/PasswordFields'
import { passwordValidation } from './validations'

const PASSWORD_FORM_ID = 'password-form'

export type PasswordSchema = z.infer<typeof passwordValidation>

export const AcceptInvite = () => {
  const { updateRefreshToken } = useAuth()
  const notifyError = useErrorToast()
  const navigate = useNavigate()
  const intl = useIntl()

  const [identityId, businessId, otp] = useSearchParamsValue([
    'identity',
    'business',
    'otp',
  ])

  const form = useForm<PasswordSchema>({
    mode: 'onChange',
    resolver: zodResolver(passwordValidation),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  })

  const { mutateAsync, isPending } = useMutation({
    mutationFn: acceptInvitation,
  })

  const onSubmit: SubmitHandler<PasswordSchema> = async (data) => {
    if (!identityId || !businessId || !otp) {
      toast.error(
        intl.formatMessage({
          defaultMessage:
            'Required data is missing. Please ensure you used correct link',
          id: 'auth.error.missingData',
        }),
      )

      return
    }

    try {
      const response = await mutateAsync({
        rawPassword: data.password,
        identityId,
        businessId,
        otp,
      })

      updateRefreshToken(response.data.refreshToken)

      navigate(SIGNING_IN_ROUTE, { replace: true })
    } catch (error: unknown) {
      if (error instanceof Error) {
        notifyError(error)
      }
    }
  }

  return (
    <SlideInScreen>
      <Typography text="center" variant="h3">
        <FormattedMessage
          defaultMessage="Choose your password and get started!"
          id="choosePassword.title"
        />
      </Typography>

      <div className="p-2" />

      <Typography text="center">
        <FormattedMessage
          defaultMessage="This will be your password to log into the account. Make sure it is secure and don't share it with anyone"
          id="choosePassword.subtitle"
        />
      </Typography>

      <div className="p-6" />

      <Form {...form}>
        <form id={PASSWORD_FORM_ID} onSubmit={form.handleSubmit(onSubmit)}>
          <PasswordFields form={form} />
        </form>
      </Form>

      <StickyContainer>
        <Button
          width="full"
          form={PASSWORD_FORM_ID}
          disabled={isPending || !form.formState.isValid}
          loading={isPending}
          type="submit"
        >
          <FormattedMessage
            defaultMessage="Create password"
            id="action.createPassword"
          />
        </Button>
      </StickyContainer>
    </SlideInScreen>
  )
}
