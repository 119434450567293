import { useIntl } from 'react-intl'

import { countryCodeToCountryData } from '@/constants/countries'
import { getCountryNameByISO3 } from '@/lib/country'
import { getFlagUrl } from '@/lib/images'
import { CountryCode } from '@/types/country'

import { Typography } from '../ui'

type Props = {
  country: CountryCode
}

export const CountryWithFlag = ({ country }: Props) => {
  const intl = useIntl()

  return (
    <div className="flex items-center gap-3">
      <img
        src={getFlagUrl(countryCodeToCountryData[country].flagCode)}
        className="size-3"
        alt={intl.formatMessage(
          {
            id: `country.flag.alt`,
            defaultMessage:
              '{country, select, MEX {Mexican flag} USA {American flag} other {}}',
          },
          { country },
        )}
      />

      <Typography>{getCountryNameByISO3(country, intl.locale)}</Typography>
    </div>
  )
}
