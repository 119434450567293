import { api } from '@/lib/api'
import { TYPE_FILTER_NAME } from '@/shared/components'

import { Task } from '../types'

type SearchParams = {
  [TYPE_FILTER_NAME]?: string
}

export function getUserTasks(params: SearchParams = {}) {
  const searchParams = new URLSearchParams(params)
  const searchQuery = searchParams.size > 0 ? '?' + searchParams.toString() : ''

  return api.get<Task[]>(`/v1/my/business-tasks/search${searchQuery}`)
}
