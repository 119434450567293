import { FormattedMessage } from 'react-intl'

import { StatusBadge } from '@/shared/components'

import { badgeVariantByVerificationStatus } from '../constants'

export const VerificationBadge = ({ status }: { status: string }) => {
  return (
    <StatusBadge
      variant={badgeVariantByVerificationStatus[status]}
      title={
        <FormattedMessage
          id="verification.status.label"
          defaultMessage="{status, select, NOT_STARTED {Not started} IN_PROGRESS {In progress} COMPLETED {Completed} REJECTED {Rejected} other {}}"
          values={{ status: status }}
        />
      }
    />
  )
}
