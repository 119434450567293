import { Suspense } from 'react'
import {
  createBrowserRouter,
  RouteObject,
  RouterProvider,
} from 'react-router-dom'

import { ROOT_ROUTE } from '@/constants/paths'
import { loginRoutes } from '@/features/Auth'
import { useAuth } from '@/providers/AuthProvider'
import { Loader } from '@/shared/components'

import { NotFound } from '../NotFound'
import { PageError } from '../PageError'

import { AuthChecker } from './AuthChecker'
import { dashboardRoutes } from './Dashboard'

const authenticatedRoutes: RouteObject[] = [
  {
    path: ROOT_ROUTE,
    element: <AuthChecker />,
    children: [...dashboardRoutes],
    errorElement: <PageError />,
  },
]

export const AppRouter = () => {
  const { accessToken } = useAuth()

  const routes = createBrowserRouter([
    ...(accessToken ? [] : loginRoutes),
    ...authenticatedRoutes,
    {
      path: '*',
      element: <NotFound />,
    },
  ])

  return (
    <Suspense fallback={<Loader />}>
      <RouterProvider router={routes} />
    </Suspense>
  )
}
