import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'
import { useIntl } from 'react-intl'
import { toast } from 'sonner'

import { UploadedFile } from '@/shared/components'

import { deleteOnboardingDocument } from '../api'

const HASH_LENGTH = 33

type Props = {
  fileName: string
  onDelete: (fileName: string) => void
  name: string
  step: string
}

export const UploadedOnboardingDocument = ({
  fileName,
  onDelete,
  name,
  step,
}: Props) => {
  const intl = useIntl()
  const { mutateAsync, isPending } = useMutation({
    mutationFn: deleteOnboardingDocument,
  })

  const deleteDocument = useCallback(async () => {
    try {
      await mutateAsync({
        step,
        document: name,
        file: fileName,
      })

      onDelete(fileName)
    } catch (error) {
      console.error(error)

      toast.error(
        intl.formatMessage({
          defaultMessage: 'Failed to delete file',
          id: 'bulkPayment.uploadFile.errorDeletingFile',
        }),
      )
    }
  }, [mutateAsync, fileName, intl, name, onDelete, step])

  return (
    <UploadedFile
      file={fileName.substring(HASH_LENGTH)}
      onClick={deleteDocument}
      isPending={isPending}
    />
  )
}
