import {
  OnboardingStepDetails,
  OnboardingStepName,
  ReviewAndSubmitStep,
} from '../types'

import { BusinessAddressReview } from './ReviewSteps/BusinessAddressReview'
import { CompanyActivityReview } from './ReviewSteps/CompanyActivityReview'
import { CompanyFormationReview } from './ReviewSteps/CompanyFormationReview'
import { CompanyOwnershipReview } from './ReviewSteps/CompanyOwnershipReview'
import { IdentityValidationReview } from './ReviewSteps/IdentityValidationReview'
import { LegalRepresentativeReview } from './ReviewSteps/LegalRepresentativeReview'

const ReviewStep = ({ details }: Props) => {
  switch (details?.stepName) {
    case OnboardingStepName.COMPANY_FORMATION:
      return <CompanyFormationReview stepDetails={details.stepDetails} />

    case OnboardingStepName.BUSINESS_ADDRESS:
      return <BusinessAddressReview stepDetails={details.stepDetails} />

    case OnboardingStepName.LEGAL_REPRESENTATIVE:
      return <LegalRepresentativeReview stepDetails={details.stepDetails} />

    case OnboardingStepName.COMPANY_ACTIVITY:
      return <CompanyActivityReview stepDetails={details.stepDetails} />

    case OnboardingStepName.COMPANY_OWNERSHIP:
      return <CompanyOwnershipReview stepDetails={details.stepDetails} />

    case OnboardingStepName.IDENTITY_VALIDATION:
      return <IdentityValidationReview stepDetails={details.stepDetails} />

    default:
      return null
  }
}

type Props = {
  details?: OnboardingStepDetails
}

export const ReviewSteps = ({ details }: Props) => {
  const stepDetails = details?.stepDetails as ReviewAndSubmitStep['stepDetails']

  const steps = stepDetails.reviewElements

  return (
    <>
      {steps?.map((step, index) => <ReviewStep key={index} details={step} />)}
    </>
  )
}
export { CompanyFormationReview }
