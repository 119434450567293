import { forwardRef, Ref, type SVGProps } from 'react'

const SvgCopy = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      stroke={props.color || `currentColor`}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M8.446 5h4.308A3.465 3.465 0 0 1 16.2 8.484v4.232a3.465 3.465 0 0 1-3.446 3.484H8.446A3.465 3.465 0 0 1 5 12.716V8.485A3.465 3.465 0 0 1 8.446 5"
      clipRule="evenodd"
    />
    <path
      stroke={props.color || `currentColor`}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9.667 19h4.77A4.616 4.616 0 0 0 19 14.333V9.666"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgCopy)
export default ForwardRef
