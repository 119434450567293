import { forwardRef, Ref, type SVGProps } from 'react'

const SvgCloud = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 18 18"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill="#fff"
      d="M9.699 3.531a4.05 4.05 0 0 0-3.414 1.865 4.407 4.407 0 1 0-1.13 8.667h8.738a3.358 3.358 0 1 0-.144-6.714A4.057 4.057 0 0 0 9.7 3.531"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgCloud)
export default ForwardRef
