import { FormattedMessage } from 'react-intl'

import { Currency } from '@/constants/currency'
import { formatAmount } from '@/lib/money'
import { Widget } from '@/shared/components'
import { Details } from '@/shared/ui'

import { MoneyMovementSchema } from './AddTeamMemberSteps'

type Props = {
  moneyMovement?: MoneyMovementSchema
}

export const MoneyMovementReviewSection = ({ moneyMovement }: Props) => {
  if (!moneyMovement) {
    return null
  }

  return (
    <Widget
      title={
        <FormattedMessage
          id="teamMember.create.reviewStep.moneyMovementPermissions"
          defaultMessage="Money movement permissions"
        />
      }
    >
      <Details>
        <Details.Label>
          <FormattedMessage
            defaultMessage="Money movement"
            id="teamMember.create.review.moneyMovement.title"
          />
        </Details.Label>

        <Details.Value>
          <FormattedMessage
            defaultMessage="{movementPermission, select, REQUIRE_APPROVAL_ABOVE_THE_LIMIT {One admin approval above a limit} ALWAYS_REQUIRE_APPROVAL {One admin approval} NO_APPROVAL_REQUIRED {No approval required} other {}}"
            id="teamMember.create.review.moneyMovement.subtitle"
            values={{
              movementPermission: moneyMovement.movementPermission,
            }}
          />
        </Details.Value>
      </Details>
      {moneyMovement.limitPeriodType ? (
        <Details>
          <Details.Label>
            <FormattedMessage
              defaultMessage="Limit type"
              id="teamMember.create.review.moneyMovement.limitType"
            />
          </Details.Label>

          <Details.Value>
            <FormattedMessage
              id="card.limit.duration"
              defaultMessage="{limitType, select, DAILY {Daily} WEEKLY {Weekly} MONTHLY {Monthly} YEARLY {Yearly} other {}}"
              values={{
                limitType: moneyMovement.limitPeriodType,
              }}
            />
          </Details.Value>
        </Details>
      ) : null}
      {moneyMovement.periodTransferLimitAmount ? (
        <Details>
          <Details.Label>
            <FormattedMessage
              defaultMessage="Limit amount"
              id="teamMember.create.review.moneyMovement.limitAmount"
            />
          </Details.Label>

          <Details.Value>
            {formatAmount({
              amount: moneyMovement.periodTransferLimitAmount,
              currency: Currency.USDC,
            })}
          </Details.Value>
        </Details>
      ) : null}
      {moneyMovement.singleTransferLimitAmount ? (
        <Details>
          <Details.Label>
            <FormattedMessage
              defaultMessage="Single transaction limit"
              id="teamMember.create.review.moneyMovement.singleLimit"
            />
          </Details.Label>

          <Details.Value>
            {formatAmount({
              amount: moneyMovement.singleTransferLimitAmount,
              currency: Currency.USDC,
            })}
          </Details.Value>
        </Details>
      ) : null}
    </Widget>
  )
}
