import { cn } from '@/lib/utils'

type Props = {
  children: React.ReactNode
  className?: string
  noTopPadding?: boolean
}

export const StickyContainer = ({
  children,
  className,
  noTopPadding,
}: Props) => {
  return (
    <>
      {noTopPadding ? null : <div className="p-8" />}

      <div
        className={cn(
          'sticky bottom-0 mt-auto w-full bg-gradient-to-b from-transparent from-5% to-neutral-gray-100 to-30% py-12',
          className,
        )}
      >
        {children}
      </div>
    </>
  )
}
