import { FormattedMessage } from 'react-intl'
import { Link, useLocation } from 'react-router-dom'

import { ADD_RECIPIENTS_ROUTE } from '@/constants/paths'
import { getImage } from '@/lib/images'
import { WithPermissions } from '@/shared/components'
import { Plus } from '@/shared/icons/outline'
import { Button, Card, Typography } from '@/shared/ui'
import { Permission } from '@/types/permissions'

export const EmptyRecipients = () => {
  const location = useLocation()

  return (
    <Card
      className="flex w-full flex-wrap items-center justify-between gap-3 md:flex-nowrap md:gap-0"
      size="large"
    >
      <div className="flex flex-wrap items-center gap-8">
        <img
          className="w-24 object-contain"
          src={getImage({ name: 'empty-card' })}
          alt=""
          aria-hidden
        />
        <div className="flex flex-col gap-1">
          <Typography bold>
            <FormattedMessage
              id="recipients.empty"
              defaultMessage="You don't have any recipients yet!"
            />
          </Typography>
          <Typography>
            <FormattedMessage
              id="recipients.empty.addFirstRecipient"
              defaultMessage="Add first recipient to get started"
            />
          </Typography>
        </div>
      </div>

      <WithPermissions permissions={[Permission.TRANSFERS]}>
        <Button leftIcon={<Plus className="size-5" />} asChild>
          <Link to={ADD_RECIPIENTS_ROUTE} state={{ from: location }}>
            <FormattedMessage
              id="action.addRecipient"
              defaultMessage="Add recipient"
            />
          </Link>
        </Button>
      </WithPermissions>
    </Card>
  )
}
