import { useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { useCountryCodes } from '@/hooks/useCountryCodes'
import { cn } from '@/lib/utils'

import { Check, ChevronDown } from '../icons/outline'
import {
  Button,
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  FormControl,
  FormItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  Typography,
} from '../ui'

type Props = {
  disabled?: boolean
  value?: string
  onSelect: (value: string) => void
}

export const CountryCodeField = ({ disabled, value, onSelect }: Props) => {
  const intl = useIntl()
  const [open, setOpen] = useState(false)

  const countriesQuery = useCountryCodes()

  const selectedCountry = useMemo(
    () =>
      countriesQuery.data?.find((c) => c.internationalPhonePrefix === value),
    [countriesQuery.data, value],
  )

  return (
    <FormItem className="flex flex-col">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <FormControl>
            <Button
              disabled={countriesQuery.isPending || disabled}
              type="button"
              variant="outline"
              role="combobox"
              className={cn(
                'h-[54px] w-28 min-w-0 justify-between rounded-lg font-normal hover:bg-white disabled:bg-neutral-gray-200 disabled:opacity-100',
              )}
            >
              {countriesQuery.isPending ? (
                <Spinner className="size-5" />
              ) : (
                <Typography className="flex items-center gap-2 pl-2">
                  <span className="scale-125">{selectedCountry?.flag}</span>
                  {selectedCountry?.internationalPhonePrefix}
                </Typography>
              )}

              <ChevronDown className="size-6 shrink-0 text-neutral-gray-600" />
            </Button>
          </FormControl>
        </PopoverTrigger>
        <PopoverContent className="w-[250px] p-0">
          <Command
            filter={(value, search) => {
              const searchValue = search.toLowerCase().trim()

              const [code, country] = value.split('-')

              if (
                country.includes(searchValue.trim().toLowerCase()) ||
                code.includes(searchValue.trim().toLowerCase())
              ) {
                return 1
              }

              return 0
            }}
          >
            <CommandList>
              <CommandInput
                placeholder={intl.formatMessage({
                  id: 'placeholder.searchCountryCode',
                  defaultMessage: 'Search country code',
                })}
              />
              <CommandEmpty>
                <FormattedMessage
                  defaultMessage="No phone code found"
                  id="placeholder.search.noCountries"
                />
              </CommandEmpty>
              <CommandGroup>
                {countriesQuery.data?.map((country) => (
                  <CommandItem
                    disabled={country.internationalPhonePrefix === value}
                    value={`${country.internationalPhonePrefix}-${country.name}`}
                    key={country.isoCode}
                    onSelect={() => {
                      onSelect(country.internationalPhonePrefix)
                      setOpen((open) => !open)
                    }}
                  >
                    <Typography>
                      {country.flag} {country.internationalPhonePrefix}{' '}
                      {country.name}
                    </Typography>

                    <Check
                      className={cn(
                        'ml-2 h-4 w-4',
                        country.internationalPhonePrefix === value
                          ? 'opacity-100'
                          : 'opacity-0',
                      )}
                    />
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </FormItem>
  )
}
