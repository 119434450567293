import * as React from 'react'
import { VariantProps } from 'class-variance-authority'

import { cn } from '@/lib/utils'

import { inputVariants } from './inputVariants'

const Input = React.forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement> &
    VariantProps<typeof inputVariants>
>(({ inputSize, variant, className, type, ...props }, ref) => {
  return (
    <input
      type={type}
      className={cn(
        'base-input-autofill',
        inputVariants({ inputSize, variant }),
        className,
      )}
      ref={ref}
      {...props}
    />
  )
})

Input.displayName = 'Input'

export { Input }
