import { useMemo } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { useIntl } from 'react-intl'

import { isSmallScreen, isTabletScreen } from '@/constants/breakpoints'
import { Transaction } from '@/features/Transactions/types'
import { useMediaQuery } from '@/hooks/useMediaQuery'
import { DataTableColumnHeader } from '@/shared/ui'

import { AmountCell } from './AmountCell'
import { CurrencyCell } from './CurrencyCell'
import { DateCell } from './DateCell'
import { MethodCell } from './MethodCell'
import { StateCell } from './StateCell'
import { TransactionCell } from './TransactionCell'
import { WalletCell } from './WalletCell'

export const useTransactionColumns = (): ColumnDef<Transaction>[] => {
  const isMobile = useMediaQuery(isSmallScreen)
  const isTablet = useMediaQuery(isTabletScreen)
  const intl = useIntl()

  const mobileColumns: ColumnDef<Transaction>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        cell: ({ row }) => <TransactionCell transaction={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.description',
              defaultMessage: 'Description',
            })}
          />
        ),
      },
      {
        accessorKey: 'baseAmount',
        cell: ({ row }) => <AmountCell transaction={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.amount',
              defaultMessage: 'Amount',
            })}
          />
        ),
      },
    ],
    [intl],
  )

  const tabletColumns: ColumnDef<Transaction>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        cell: ({ row }) => <TransactionCell transaction={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.description',
              defaultMessage: 'Description',
            })}
          />
        ),
        size: 200,
      },
      {
        accessorKey: 'walletId',
        cell: ({ row }) => <WalletCell transaction={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.account',
              defaultMessage: 'Account',
            })}
          />
        ),
      },
      {
        accessorKey: 'createdOn',
        cell: ({ row }) => <DateCell transaction={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.date',
              defaultMessage: 'Date',
            })}
          />
        ),
      },
      {
        accessorKey: 'transactionStatus',
        cell: ({ row }) => <StateCell transaction={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.status',
              defaultMessage: 'Status',
            })}
          />
        ),
      },
      {
        accessorKey: 'baseAmount',
        cell: ({ row }) => <AmountCell transaction={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.amount',
              defaultMessage: 'Amount',
            })}
          />
        ),
      },
    ],
    [intl],
  )

  const desktopColumns: ColumnDef<Transaction>[] = useMemo(
    () => [
      {
        accessorKey: 'name',
        cell: ({ row }) => <TransactionCell transaction={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.description',
              defaultMessage: 'Description',
            })}
          />
        ),
        size: 300,
      },
      {
        accessorKey: 'walletId',
        cell: ({ row }) => <WalletCell transaction={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.account',
              defaultMessage: 'Account',
            })}
          />
        ),
        size: 160,
      },
      {
        accessorKey: 'transactionType',
        cell: ({ row }) => <MethodCell transaction={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.method',
              defaultMessage: 'Method',
            })}
          />
        ),
        size: 130,
      },
      {
        accessorKey: 'createdOn',
        cell: ({ row }) => <DateCell transaction={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.date',
              defaultMessage: 'Date',
            })}
          />
        ),
        size: 140,
      },
      {
        accessorKey: 'transactionStatus',
        cell: ({ row }) => <StateCell transaction={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.status',
              defaultMessage: 'Status',
            })}
          />
        ),
        size: 120,
      },
      {
        accessorKey: 'localCurrency',
        cell: ({ row }) => <CurrencyCell transaction={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.currency',
              defaultMessage: 'Currency',
            })}
          />
        ),
        size: 100,
      },
      {
        accessorKey: 'baseAmount',
        cell: ({ row }) => <AmountCell transaction={row.original} />,
        header: ({ column }) => (
          <DataTableColumnHeader
            column={column}
            title={intl.formatMessage({
              id: 'label.amount',
              defaultMessage: 'Amount',
            })}
          />
        ),
        size: 120,
      },
    ],
    [intl],
  )

  return isMobile ? mobileColumns : isTablet ? tabletColumns : desktopColumns
}
