import { Fragment } from 'react'

import { Recipient } from '@/features/Recipients/types'
import { getRecipientAccountNumber } from '@/lib/recipient'
import { maskValue } from '@/lib/typography'
import { CountryWithFlag } from '@/shared/components'
import { Button, Card, Skeleton, Typography } from '@/shared/ui'

type Props = {
  recipient: Recipient
  onSelect: (id: string) => void
}

export const RecipientCard = ({ recipient, onSelect }: Props) => {
  return (
    <Card
      size="small"
      key={recipient.id}
      asChild
      variant="transparent"
      scalable
      className="grid w-full grid-cols-3 items-center justify-items-start"
      onClick={() => onSelect(recipient.id)}
    >
      <Button
        size="inline"
        variant="ghost"
        onClick={() => onSelect(recipient.id)}
      >
        <Typography
          title={recipient.nickname}
          className="max-w-40 overflow-hidden text-ellipsis whitespace-nowrap"
        >
          {recipient.nickname}
        </Typography>

        {recipient.localInformation ? (
          <Typography>
            {maskValue(getRecipientAccountNumber(recipient))}
          </Typography>
        ) : null}

        <CountryWithFlag country={recipient.country} />
      </Button>
    </Card>
  )
}

const RecipientCardSkeleton = () => {
  return Array.from({ length: 5 }).map((_, i) => (
    <Fragment key={i}>
      <Card
        size="small"
        variant="transparent"
        className="grid w-full grid-cols-3 items-center justify-items-start"
      >
        <Skeleton className="h-[22px] w-32" />
        <Skeleton className="h-[22px] w-32" />
        <Skeleton className="h-[22px] w-32" />
      </Card>
      {i !== 4 && <div className="border-b bg-neutral-gray-200" />}
    </Fragment>
  ))
}

RecipientCard.Skeleton = RecipientCardSkeleton
