import { useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  generatePath,
  Link,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'

import { CONTRACTORS_DETAILS_ROUTE, CREATE_CARD_ROUTE } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { useSearchInput } from '@/hooks/useSearchInput'
import { FiltersWidget, TasksTypeFilter } from '@/shared/components'
import { CloudDownload, Plus } from '@/shared/icons/outline'
import { Button, DataTable, MotionDiv, SearchInput } from '@/shared/ui'

import { getContractors } from '../../api'
import { Contractor } from '../../types'

import { EmptyContractors } from './components/EmptyContractors'
import { useContractorsColumns } from './components/useContractorsColumns'

export const AllContractors = () => {
  const intl = useIntl()
  const columns = useContractorsColumns()
  const [search, setSearch, handleSearchQuery] = useSearchInput()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const params = Object.fromEntries([...searchParams])

  const [contractorsQuery] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getContractors, params],
        queryFn: () => getContractors(params),
        select: (data: AxiosResponse<Contractor[]>) => data?.data,
      },
    ],
  })

  return (
    <MotionDiv>
      <div className="flex flex-wrap gap-3 md:flex-nowrap">
        <SearchInput
          value={search}
          onChange={(value) => {
            setSearch(value)
            handleSearchQuery(value)
          }}
        />
        <FiltersWidget>
          <TasksTypeFilter />
        </FiltersWidget>

        <Button
          size="md"
          leftIcon={<CloudDownload className="size-5" />}
          loaderPosition="left"
          variant="tertiary"
        >
          <FormattedMessage
            id="action.importCSV"
            defaultMessage="Import .csv"
          />
        </Button>

        <Button
          leftIcon={<Plus className="size-5" />}
          asChild
          variant="tertiary"
        >
          <Link to={CREATE_CARD_ROUTE}>
            <FormattedMessage
              id="action.newContractor"
              defaultMessage="New contractor"
            />
          </Link>
        </Button>
      </div>

      <div className="p-3" />

      {contractorsQuery.data?.length === 0 &&
      Object.keys(params).length === 0 ? (
        <EmptyContractors />
      ) : (
        <DataTable
          emptyState={{
            title: intl.formatMessage({
              id: 'contractors.search.notFound',
              defaultMessage: 'No contractors found',
            }),
            description: intl.formatMessage({
              id: 'contractors.search.notFound.description',
              defaultMessage:
                'There were no contractors that matched your search criteria',
            }),
          }}
          loaderOptions={{ rows: 1 }}
          isLoading={contractorsQuery.isPending}
          columns={columns}
          data={contractorsQuery.data ?? []}
          onRowClick={(contractor) => {
            navigate(
              generatePath(CONTRACTORS_DETAILS_ROUTE, { id: contractor.id }),
            )
          }}
        />
      )}
    </MotionDiv>
  )
}
