import { RouteObject } from 'react-router-dom'

import {
  CASHBACK_TRANSACTIONS_ROUTE,
  TRANSACTIONS_ROUTE,
} from '@/constants/paths'

import { CashbackTransactions } from './CashbackTransactions'
import { Transactions } from './Transactions'

export const transactionsRoutes: RouteObject[] = [
  {
    path: TRANSACTIONS_ROUTE,
    element: <Transactions />,
  },
]

export const cashbackTransactionsRoutes: RouteObject[] = [
  {
    path: CASHBACK_TRANSACTIONS_ROUTE,
    element: <CashbackTransactions />,
  },
]
