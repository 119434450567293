import { FormattedMessage } from 'react-intl'

import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  Typography,
} from '@/shared/ui'

type Props = {
  description: React.ReactNode
  descriptionAdditional?: React.ReactNode
  isOpen: boolean
  isPending: boolean
  onDelete: () => void
  onOpenChange: (isOpen: boolean) => void
  title: React.ReactNode
}

export const DeleteDialog = ({
  description,
  descriptionAdditional,
  isOpen,
  isPending,
  onDelete,
  onOpenChange,
  title,
}: Props) => {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>

          <div className="flex flex-col items-center">
            <Typography text="center">{description}</Typography>

            {descriptionAdditional ? (
              <Typography>{descriptionAdditional}</Typography>
            ) : null}
          </div>
        </DialogHeader>

        <div className="p-4" />

        <div className="grid grid-cols-2 gap-3">
          <Button
            disabled={isPending}
            width="full"
            onClick={() => onOpenChange(false)}
            variant="tertiary"
          >
            <FormattedMessage id="action.cancel" defaultMessage="Cancel" />
          </Button>
          <Button
            disabled={isPending}
            loading={isPending}
            width="full"
            onClick={onDelete}
          >
            <FormattedMessage id="action.delete" defaultMessage="Delete" />
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
