import { useIntl } from 'react-intl'

import { SlideInScreen, Spinner } from '@/shared/ui'

export const OnboardingLoader = () => {
  const intl = useIntl()

  return (
    <SlideInScreen className="flex items-center justify-center">
      <Spinner
        aria-label={intl.formatMessage({
          defaultMessage: 'Loading',
          id: 'label.loading',
        })}
        className="size-16"
      />
    </SlideInScreen>
  )
}
