import { useMemo, useState } from 'react'
import { useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { FormattedMessage } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'

import { TASKS_ROUTE } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { useBusinessRole } from '@/hooks/useBusinessRole'
import { formatDate } from '@/lib/date'
import { getFullName } from '@/lib/typography'
import { Loader, ProgressButton, Widget } from '@/shared/components'
import {
  Button,
  Details,
  FullScreen,
  SlideInScreen,
  StickyContainer,
} from '@/shared/ui'

import {
  getAdminTask,
  getBulkPaymentTaskDetails,
  getUserTask,
  getWithdrawalTaskDetails,
} from './api'
import { AddRecipientTaskReview } from './components/AddRecipientTaskReview'
import { ApproveOTPDialog } from './components/ApproveOTPDialog'
import { BulkPaymentTaskReview } from './components/BulkPaymentTaskReview'
import { CancelTasksDialog } from './components/CancelTaskDialog'
import { DeclineTasksDialog } from './components/DeclineTasksDialog'
import { WithdrawalTaskReview } from './components/WithdrawalTaskReview'
import { useApproveTask } from './hooks/useApproveTask'
import {
  BulkPaymentTaskDetails,
  Task,
  TaskType,
  WithdrawalTaskDetails,
} from './types'

export const ReviewTask = () => {
  const { id: taskId } = useParams<{ id: string }>()
  const { isAdmin, isPaymentOps } = useBusinessRole()
  const navigate = useNavigate()

  const [showApproveDialog, setShowApproveDialog] = useState(false)
  const [showDeclineDialog, setShowDeclineDialog] = useState(false)
  const [showCancelDialog, setShowCancelDialog] = useState(false)

  const { approveTask, isError, isPending } = useApproveTask()

  const [adminTask, userTask] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getAdminTask, taskId],
        queryFn: () => getAdminTask({ id: taskId ?? '' }),
        select: (data: AxiosResponse<Task>) => data.data,
        enabled: !!taskId && isAdmin,
      },
      {
        queryKey: [queryKeys.getUserTask, taskId],
        queryFn: () => getUserTask({ id: taskId ?? '' }),
        select: (data: AxiosResponse<Task>) => data.data,
        enabled: !!taskId && !isAdmin,
      },
    ],
  })

  const task = useMemo(
    () => adminTask.data ?? userTask.data,
    [adminTask.data, userTask.data],
  )

  const [withdrawalTaskQuery, bulkPaymentTaskQuery] = useQueries({
    queries: [
      {
        queryKey: [
          queryKeys.getWithdrawalTaskDetails,
          task?.id,
          task?.entityId,
        ],
        queryFn: () =>
          getWithdrawalTaskDetails({ withdrawalId: task?.entityId ?? '' }),
        select: (data: AxiosResponse<WithdrawalTaskDetails>) => data.data,
        enabled: !!task?.entityId && task.type === TaskType.EXECUTE_WITHDRAWAL,
      },
      {
        queryKey: [
          queryKeys.getBulkPaymentTaskDetails,
          task?.id,
          task?.entityId,
        ],
        queryFn: () =>
          getBulkPaymentTaskDetails({ bulkPaymentId: task?.entityId ?? '' }),
        select: (data: AxiosResponse<BulkPaymentTaskDetails>) => data.data,
        enabled:
          !!task?.entityId && task.type === TaskType.EXECUTE_BULK_PAYMENT,
      },
    ],
  })

  const onContinue = () => {
    navigate(TASKS_ROUTE)
  }

  if (
    adminTask.isFetching ||
    userTask.isFetching ||
    withdrawalTaskQuery.isFetching ||
    bulkPaymentTaskQuery.isFetching
  ) {
    return <Loader />
  }

  return (
    <>
      <FullScreen>
        <ProgressButton progress={75} to={TASKS_ROUTE} />

        <SlideInScreen>
          {(() => {
            switch (task?.type) {
              case TaskType.ADD_BENEFICIARY:
                return <AddRecipientTaskReview task={task} />

              case TaskType.EXECUTE_WITHDRAWAL:
                if (!withdrawalTaskQuery.data) {
                  return null
                }

                return (
                  <WithdrawalTaskReview
                    task={task}
                    taskDetails={withdrawalTaskQuery.data}
                  />
                )

              case TaskType.EXECUTE_BULK_PAYMENT:
                if (!bulkPaymentTaskQuery.data) {
                  return null
                }

                return (
                  <BulkPaymentTaskReview
                    task={task}
                    taskDetails={bulkPaymentTaskQuery.data}
                  />
                )

              default:
                return null
            }
          })()}

          {isAdmin && (
            <div className="flex flex-col pt-4">
              <Widget
                title={
                  <FormattedMessage
                    id="tasks.review.requestDetails"
                    defaultMessage="Request details"
                  />
                }
              >
                <Details>
                  <Details.Label>
                    <FormattedMessage
                      id="task.review.requestedBy"
                      defaultMessage="Requested by"
                    />
                  </Details.Label>
                  <Details.Value>
                    {getFullName({
                      firstName: task?.createdByFirstName,
                      lastName: task?.createdByLastName,
                      secondLastName: task?.createdBySecondLastName,
                    })}
                  </Details.Value>
                </Details>
                {task ? (
                  <Details>
                    <Details.Label>
                      <FormattedMessage
                        id="task.review.requestedDate"
                        defaultMessage="Requested date"
                      />
                    </Details.Label>
                    <Details.Value>
                      {formatDate(task.createdOn, 'd MMM. yyyy, HH:mm')}
                    </Details.Value>
                  </Details>
                ) : null}
              </Widget>
            </div>
          )}

          {isAdmin && (
            <StickyContainer className="grid grid-cols-2 gap-3">
              <Button
                onClick={() => {
                  setShowDeclineDialog(true)
                }}
                width="full"
                variant="tertiary"
              >
                <FormattedMessage
                  id="action.decline"
                  defaultMessage="Decline"
                />
              </Button>
              <Button
                onClick={() => {
                  approveTask({ id: taskId ?? '', onSuccess: onContinue })
                  setShowApproveDialog(true)
                }}
                width="full"
              >
                <FormattedMessage
                  id="action.approve"
                  defaultMessage="Approve"
                />
              </Button>
            </StickyContainer>
          )}

          {isPaymentOps && (
            <StickyContainer>
              <Button
                onClick={() => {
                  setShowCancelDialog(true)
                }}
                width="full"
              >
                <FormattedMessage id="action.cancel" defaultMessage="Cancel" />
              </Button>
            </StickyContainer>
          )}
        </SlideInScreen>
      </FullScreen>

      <ApproveOTPDialog
        tasks={task ? [task] : []}
        isOpen={showApproveDialog}
        onOpenChange={setShowApproveDialog}
        isPending={isPending}
        isError={isError}
        onContinue={(otp) =>
          approveTask({ id: taskId ?? '', otp, onSuccess: onContinue })
        }
      />

      <DeclineTasksDialog
        isOpen={showDeclineDialog}
        onOpenChange={setShowDeclineDialog}
        tasks={task ? [task] : []}
        onContinue={onContinue}
      />

      <CancelTasksDialog
        tasks={task ? [task] : []}
        isOpen={showCancelDialog}
        onOpenChange={setShowCancelDialog}
        onContinue={onContinue}
      />
    </>
  )
}
