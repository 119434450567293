import { http, HttpResponse } from 'msw'

import { BASE_API_DEV } from '@/constants/urls'

import { MOCKED_CONTRACTORS } from './contractors.mocks'

export const contractorsHandlers = [
  http.get(`${BASE_API_DEV}/v1/me/contractors/search`, async () => {
    return HttpResponse.json(MOCKED_CONTRACTORS)
  }),

  http.get(`${BASE_API_DEV}/v1/me/contractors/:id`, async () => {
    return HttpResponse.json(MOCKED_CONTRACTORS[0])
  }),
]
