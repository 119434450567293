import {
  addDays,
  eachDayOfInterval,
  parse,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns'

import { Period } from '@/types/recharts'

import { formatDate } from './date'

export function parseTopAxisValueByPeriod(value: number, period: Period) {
  switch (period) {
    case Period.Day:
      return formatDate(new Date(value), 'dd/MM')
    case Period.Week:
      return formatDate(new Date(value), 'dd/MM')
    case Period.Month:
      return formatDate(new Date(value), 'LLL')
    case Period.SixMonths:
    case Period.Year:
    case Period.FiveYears:
      return formatDate(new Date(value), 'MMM')

    default:
      return formatDate(new Date(value), 'dd/MM')
  }
}

export function parseBottomAxisValueByPeriod(value: number, period: Period) {
  switch (period) {
    case Period.Day:
      return formatDate(new Date(value), 'HH:mm')
    case Period.Week:
      return formatDate(new Date(value), 'EEE')
    case Period.Month:
      return formatDate(new Date(value), 'dd')
    case Period.SixMonths:
    case Period.Year:
    case Period.FiveYears:
      return formatDate(new Date(value), 'yyyy')

    default:
      return formatDate(new Date(value), 'dd/MM')
  }
}

export function getTicksByPeriod(period: Period) {
  const today = new Date()

  const currentTime = `${today.getHours()}:${today.getMinutes()}`

  const getTicks = () => {
    switch (period) {
      case Period.Day:
        return [
          parse('00:00', 'HH:mm', today).getTime(),
          parse('04:00', 'HH:mm', today).getTime(),
          parse('08:00', 'HH:mm', today).getTime(),
          parse('12:00', 'HH:mm', today).getTime(),
          parse('16:00', 'HH:mm', today).getTime(),
          parse('20:00', 'HH:mm', today).getTime(),
          addDays(parse('00:00', 'HH:mm', today), 1).getTime(),
        ]

      case Period.Week:
        return eachDayOfInterval({
          start: subWeeks(today, 1),
          end: today,
        }).map((date) => parse(currentTime, 'HH:mm', date).getTime())

      case Period.Month: {
        return [
          parse(currentTime, 'HH:mm', today).getTime(),
          parse(currentTime, 'HH:mm', subDays(today, 6)).getTime(),
          parse(currentTime, 'HH:mm', subDays(today, 6 * 2)).getTime(),
          parse(currentTime, 'HH:mm', subDays(today, 6 * 3)).getTime(),
          parse(currentTime, 'HH:mm', subDays(today, 6 * 4)).getTime(),
          parse(currentTime, 'HH:mm', subMonths(today, 1)).getTime(),
        ].reverse()
      }

      case Period.SixMonths:
        return [
          parse(currentTime, 'HH:mm', today).getTime(),
          parse(currentTime, 'HH:mm', subMonths(today, 1)).getTime(),
          parse(currentTime, 'HH:mm', subMonths(today, 2)).getTime(),
          parse(currentTime, 'HH:mm', subMonths(today, 3)).getTime(),
          parse(currentTime, 'HH:mm', subMonths(today, 4)).getTime(),
          parse(currentTime, 'HH:mm', subMonths(today, 5)).getTime(),
          parse(currentTime, 'HH:mm', subMonths(today, 6)).getTime(),
        ].reverse()

      case Period.Year:
        return [
          parse(currentTime, 'HH:mm', today).getTime(),
          parse(currentTime, 'HH:mm', subMonths(today, 2)).getTime(),
          parse(currentTime, 'HH:mm', subMonths(today, 4)).getTime(),
          parse(currentTime, 'HH:mm', subMonths(today, 6)).getTime(),
          parse(currentTime, 'HH:mm', subMonths(today, 8)).getTime(),
          parse(currentTime, 'HH:mm', subMonths(today, 10)).getTime(),
          parse(currentTime, 'HH:mm', subMonths(today, 12)).getTime(),
        ].reverse()

      case Period.FiveYears:
        return [
          parse(currentTime, 'HH:mm', today).getTime(),
          parse(currentTime, 'HH:mm', subYears(today, 1)).getTime(),
          parse(currentTime, 'HH:mm', subYears(today, 2)).getTime(),
          parse(currentTime, 'HH:mm', subYears(today, 3)).getTime(),
          parse(currentTime, 'HH:mm', subYears(today, 4)).getTime(),
          parse(currentTime, 'HH:mm', subYears(today, 5)).getTime(),
        ].reverse()

      default:
        return [parse('00:00', 'HH:mm', today).getTime()]
    }
  }

  const offsetMinutes = today.getTimezoneOffset()

  function getOffsetAmount() {
    switch (period) {
      case Period.Day:
      case Period.Week:
        return offsetMinutes * 60 * 1000

      default:
        return 0
    }
  }

  const offsetAmount = getOffsetAmount()

  const ticks = getTicks().map((tick) => tick + offsetAmount)

  return ticks
}

export function getMobileIntervalByPeriod(period: Period) {
  switch (period) {
    case Period.Day:
      return 1
    case Period.Week:
      return 1
    case Period.Month:
      return 0
    case Period.SixMonths:
    case Period.Year:
      return 1
    case Period.FiveYears:
      return 0

    default:
      return 1
  }
}
