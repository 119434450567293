import { useEffect, useMemo, useState } from 'react'
import { AnimatePresence } from 'framer-motion'

import { useSearchParamsValue } from '@/hooks/useSearchParamsValue'
import { ProgressButton } from '@/shared/components'

import { CompanyStep } from './CompanyStep'
import { UserStep, UserStepSchema } from './UserStep'
import { VerifyEmail } from './VerifyEmail'

enum FormStep {
  About = 'about',
  Company = 'company',
  Verify = 'verify',
}

type FormState =
  | { type: FormStep.About }
  | { type: FormStep.Company; data: UserStepSchema }
  | { type: FormStep.Verify; signupId: string; email: string }

export const UserForm = () => {
  const [from] = useSearchParamsValue(['from'])

  const [activeStep, setActiveStep] = useState<FormState>({
    type: FormStep.About,
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [activeStep])

  const progressByStep = useMemo(() => {
    switch (activeStep.type) {
      case FormStep.About:
        return 25
      case FormStep.Company:
        return 50
      case FormStep.Verify:
        return 75
    }
  }, [activeStep.type])

  return (
    <>
      {from ? (
        <ProgressButton
          className="hidden md:flex"
          progress={progressByStep}
          to={from}
        />
      ) : null}

      <AnimatePresence mode="wait">
        {activeStep.type === FormStep.About ? (
          <UserStep
            onContinue={(data) =>
              setActiveStep({ type: FormStep.Company, data })
            }
          />
        ) : null}

        {activeStep.type === FormStep.Company ? (
          <CompanyStep
            userData={activeStep.data}
            onContinue={({ signupId, email }) => {
              setActiveStep({ type: FormStep.Verify, signupId, email })
            }}
          />
        ) : null}

        {activeStep.type === FormStep.Verify ? (
          <VerifyEmail id={activeStep.signupId} email={activeStep.email} />
        ) : null}
      </AnimatePresence>
    </>
  )
}
