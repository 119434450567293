import { useCallback, useEffect, useMemo, useState } from 'react'
import { jwtDecode } from 'jwt-decode'

import { sessionStorageKeys } from '@/constants/keys'

import { AuthContext } from './AuthContext'

type Props = {
  children: React.ReactNode
}

export const AuthProvider = ({ children }: Props) => {
  const [, setRefreshToken] = useState<string | null>(
    sessionStorage.getItem(sessionStorageKeys.refreshToken),
  )

  const [accessToken, setAccessToken] = useState<string | null>(() =>
    sessionStorage.getItem(sessionStorageKeys.accessToken),
  )

  useEffect(() => {
    const accessTokenFromStorage = sessionStorage.getItem(
      sessionStorageKeys.accessToken,
    )

    if (accessTokenFromStorage) {
      setAccessToken(accessTokenFromStorage)
    }
  }, [accessToken])

  const setAuthToken = useCallback((token: string | null) => {
    if (!token) {
      setAccessToken(null)
      sessionStorage.removeItem(sessionStorageKeys.accessToken)
      return
    }

    setAccessToken(token)
  }, [])

  const updateRefreshToken = useCallback((token: string | null) => {
    if (!token) {
      setRefreshToken(null)
      sessionStorage.removeItem(sessionStorageKeys.refreshToken)
      return
    }

    sessionStorage.setItem(sessionStorageKeys.refreshToken, token)

    // we set token thru interceptor, so we double set it here
    const accessTokenFromStorage = sessionStorage.getItem(
      sessionStorageKeys.accessToken,
    )

    if (accessTokenFromStorage) {
      setAccessToken(accessTokenFromStorage)
    }

    setRefreshToken(token)
  }, [])

  useEffect(() => {
    const intervalId = setInterval(() => {
      const tokenFromStorage = sessionStorage.getItem(
        sessionStorageKeys.accessToken,
      )

      const decodedToken = tokenFromStorage
        ? jwtDecode(tokenFromStorage)
        : undefined

      const tokenExpiryDate = decodedToken?.exp ? decodedToken.exp * 1000 : 0

      if (tokenExpiryDate < Date.now()) {
        updateRefreshToken(null)
        setAuthToken(null)
      }
    }, 1000)

    return () => clearInterval(intervalId)
  }, [updateRefreshToken, setAuthToken])

  const contextValue = useMemo(
    () => ({
      accessToken,
      setAuthToken,
      updateRefreshToken,
    }),
    [accessToken, setAuthToken, updateRefreshToken],
  )

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  )
}
