import { api } from '@/lib/api'

type DeleteDocumentParams = {
  step: string
  document: string
  file: string
}

export function deleteOnboardingDocument({
  step,
  document,
  file,
}: DeleteDocumentParams) {
  return api.delete<void>(
    `/v1/onboarding-documents/step/${step}/document/${document}/name/${file}`,
  )
}
