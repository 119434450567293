import { forwardRef, Ref, type SVGProps } from 'react'

const SvgLightning = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill={props.color || `currentColor`}
      fillRule="evenodd"
      d="M9.94 2.512a20.8 20.8 0 0 1 4.692 0l1.596.182a.75.75 0 0 1 .62 1.003l-2.203 6.016H17a.75.75 0 0 1 .702 1.014l-.19.507a35.8 35.8 0 0 1-5.535 9.745l-.39.49A.75.75 0 0 1 10.25 21v-7.152H8.429a.75.75 0 0 1-.747-.684l-.123-1.382a40 40 0 0 1 0-7.027l.123-1.382a.75.75 0 0 1 .662-.68zm4.523 1.49a19.3 19.3 0 0 0-4.354 0l-.987.113-.069.772c-.2 2.25-.2 4.513 0 6.763l.062.698H11a.75.75 0 0 1 .75.75v5.704a34.3 34.3 0 0 0 4.163-7.589h-2.341a.75.75 0 0 1-.705-1.007l2.244-6.13z"
      clipRule="evenodd"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgLightning)
export default ForwardRef
