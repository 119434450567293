import { api } from '@/lib/api'
import { CountryCode } from '@/types/country'

export function getRecipientBank({
  country,
  bankReference,
}: {
  country: CountryCode
  bankReference: string
}) {
  return api.get<{ name: string }>(
    `/v1/me/bank-info/${country}/references/${bankReference} `,
  )
}
