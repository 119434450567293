import { formatDate } from '@/lib/date'
import { Typography } from '@/shared/ui'

import { Task } from '../types'

type Props = {
  task: Task
}

export const SubmittedOnCell = ({ task }: Props) => {
  return (
    <div className="flex items-center">
      <Typography className="whitespace-nowrap">
        {formatDate(task.createdOn, 'dd MMM. yyyy')}
      </Typography>
    </div>
  )
}
