import { FormattedMessage } from 'react-intl'

import { Details } from '@/shared/ui'
import { CountryCode } from '@/types/country'

import { Recipient } from '../types'

type Props = {
  recipient?: Recipient
}

export const PaymentDetails = ({ recipient }: Props) => {
  switch (recipient?.country) {
    case CountryCode.MX:
      return (
        <Details>
          <Details.Label>
            <FormattedMessage id="label.clabe" defaultMessage="CLABE" />
          </Details.Label>
          <Details.Value masked>
            {recipient.localInformation.clabe}
          </Details.Value>
        </Details>
      )

    case CountryCode.US:
      return (
        <>
          <Details>
            <Details.Label>
              <FormattedMessage
                id="label.paymentMethod"
                defaultMessage="Payment method"
              />
            </Details.Label>
            <Details.Value>
              <FormattedMessage
                id="paymentMethod.label"
                defaultMessage="{method, select, ACH {ACH} WIRE {Wire} other {}}"
                values={{ method: recipient.localInformation.paymentMethod }}
              />
            </Details.Value>
          </Details>
          <Details>
            <Details.Label>
              <FormattedMessage
                id="label.routingNumber"
                defaultMessage="ABA / Routing number"
              />
            </Details.Label>
            <Details.Value>
              {recipient.localInformation.routingNumber}
            </Details.Value>
          </Details>
          <Details>
            <Details.Label>
              <FormattedMessage
                id="label.accountNumber"
                defaultMessage="Account number"
              />
            </Details.Label>
            <Details.Value masked>
              {recipient.localInformation.accountNumber}
            </Details.Value>
          </Details>
        </>
      )

    default:
      return (
        <>
          <div className="flex justify-between">
            <Details.Skeleton />
            <Details.Skeleton />
          </div>
          <div className="flex justify-between">
            <Details.Skeleton />
            <Details.Skeleton />
          </div>
        </>
      )
  }
}
