import { forwardRef, Ref, type SVGProps } from 'react'

const SvgCard = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 22 21"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill="#E9E9E9"
      d="M0 5.25c0-.696.29-1.364.805-1.856a2.82 2.82 0 0 1 1.945-.769h16.5c.73 0 1.429.277 1.945.769.515.492.805 1.16.805 1.856v10.5c0 .696-.29 1.364-.805 1.856a2.82 2.82 0 0 1-1.945.769H2.75c-.73 0-1.429-.277-1.945-.769A2.57 2.57 0 0 1 0 15.75zm2.75-1.312c-.365 0-.714.138-.972.384s-.403.58-.403.928v1.313h19.25V5.25c0-.348-.145-.682-.403-.928a1.4 1.4 0 0 0-.972-.385zm17.875 5.25H1.375v6.562c0 .348.145.682.403.928s.607.384.972.384h16.5c.365 0 .714-.138.972-.384s.403-.58.403-.928z"
    />
    <path
      fill="#E9E9E9"
      d="M2.75 13.125c0-.348.145-.682.403-.928a1.4 1.4 0 0 1 .972-.384H5.5c.365 0 .714.138.972.384s.403.58.403.928v1.313c0 .348-.145.681-.403.928a1.4 1.4 0 0 1-.972.384H4.125a1.4 1.4 0 0 1-.972-.384 1.28 1.28 0 0 1-.403-.928z"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgCard)
export default ForwardRef
