import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'
import { useIntl } from 'react-intl'
import { toast } from 'sonner'

import { UploadedFile } from '@/shared/components'

import { removeFile } from '../api'

type Props = {
  bulkPaymentId?: string | null
  file: string
  onDelete: (fileName: string) => void
}

export const UploadedCSV = ({ bulkPaymentId, file, onDelete }: Props) => {
  const intl = useIntl()
  const { mutateAsync, isPending } = useMutation({
    mutationFn: removeFile,
  })

  const deleteDocument = useCallback(
    async (file: string) => {
      if (!bulkPaymentId) {
        toast.error(
          intl.formatMessage({
            id: 'bulkPayment.uploadFile.missingPaymentId',
            defaultMessage: 'Bulk payment ID is missing',
          }),
        )

        return
      }

      try {
        await mutateAsync({
          id: bulkPaymentId,
          fileName: file,
        })

        onDelete(file)
      } catch (error) {
        console.error(error)

        toast.error(
          intl.formatMessage({
            defaultMessage: 'Failed to delete file',
            id: 'bulkPayment.uploadFile.errorDeletingFile',
          }),
        )
      }
    },
    [mutateAsync, intl, onDelete, bulkPaymentId],
  )

  return (
    <UploadedFile file={file} onClick={deleteDocument} isPending={isPending} />
  )
}
