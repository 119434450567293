import { FormattedMessage } from 'react-intl'
import { Link, useLocation, useParams } from 'react-router-dom'

import { ADD_BALANCE_ROUTE } from '@/constants/paths'
import { getImage } from '@/lib/images'
import { Button, Card, Typography } from '@/shared/ui'
import { Permission } from '@/types/permissions'

import { Plus } from '../icons/outline'

import { WithPermissions } from './WithPermissions'

export const EmptyTransactionsCard = () => {
  const location = useLocation()
  const params = useParams<{ id: string }>()

  return (
    <Card
      className="flex w-full flex-wrap items-center justify-between gap-3 md:flex-nowrap md:gap-0"
      size="large"
    >
      <div className="flex flex-wrap items-center gap-8">
        <img
          className="w-24 object-contain"
          src={getImage({ name: 'empty-card' })}
          alt=""
          aria-hidden
        />
        <div className="flex flex-col gap-1">
          <Typography bold>
            <FormattedMessage
              id="transactions.empty"
              defaultMessage=" You don’t have any transactions yet!"
            />
          </Typography>
          <Typography>
            <FormattedMessage
              id="transactions.empty.addBalance"
              defaultMessage="Add balance to get started"
            />
          </Typography>
        </div>
      </div>
      <WithPermissions permissions={[Permission.TRANSFERS]}>
        <Button leftIcon={<Plus className="size-5" />} asChild>
          <Link
            to={`${ADD_BALANCE_ROUTE}${params.id ? `?to=${params.id}` : ''}`}
            state={{ from: location }}
          >
            <FormattedMessage
              id="action.addBalance"
              defaultMessage="Add balance"
            />
          </Link>
        </Button>
      </WithPermissions>
    </Card>
  )
}
