import { zodResolver } from '@hookform/resolvers/zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { z } from 'zod'

import { getCodeByCountry } from '@/lib/country'
import { Button, Form, StickyContainer } from '@/shared/ui'

import { CreatedRecipientWithId, IndividualUSRecipient } from '../types'

import { ContactDetailsFields } from './ContactDetailsFields'
import { DeleteRecipientAction } from './DeleteRecipientAction'
import { IndividualFields } from './IndividualFields'
import { SharedRecipientFormProps } from './RecipientForm'
import { USPaymentFields } from './USPaymentFields'
import { individualValidation, paymentValidation } from './validations'

const createUSIndividualSchema = individualValidation.and(paymentValidation)

const US_INDIVIDUAL_FORM_ID = 'us-individual-form'

export type CreateUSIndividualSchema = z.infer<typeof createUSIndividualSchema>

type Props = {
  onContinue: (values: CreateUSIndividualSchema) => void
  recipient?: IndividualUSRecipient
} & SharedRecipientFormProps

export const USIndividualForm = ({
  layout,
  country,
  onDelete,
  onContinue,
  isPending,
  recipient,
}: Props) => {
  const form = useForm<CreateUSIndividualSchema>({
    mode: 'onChange',
    resolver: zodResolver(createUSIndividualSchema),
    values: {
      nickname: recipient?.nickname ?? '',
      firstName: recipient?.firstName ?? '',
      lastName: recipient?.lastName ?? '',
      secondLastName: recipient?.secondLastName ?? '',
      email: recipient?.email ?? '',
      localPhoneNumber:
        recipient?.internationalPhonePrefix && recipient?.localPhoneNumber
          ? recipient.internationalPhonePrefix + recipient.localPhoneNumber
          : '',
      internationalPhonePrefix: recipient?.internationalPhonePrefix
        ? recipient.internationalPhonePrefix
        : getCodeByCountry(country),

      paymentMethod: recipient?.localInformation.paymentMethod ?? '',
      accountNumber: recipient?.localInformation.accountNumber ?? '',
      routingNumber: recipient?.localInformation.routingNumber ?? '',

      addressStreet: recipient?.addressStreet ?? '',
      addressCity: recipient?.addressCity ?? '',
      addressState: recipient?.addressState ?? '',
      addressZipCode: recipient?.addressZipCode ?? '',
      addressCountry: recipient?.addressCountry ?? '',
    },
  })

  const onSubmit: SubmitHandler<CreateUSIndividualSchema> = (values) => {
    const { internationalPhonePrefix, localPhoneNumber, ...rest } = values

    const parsedPhoneNumber =
      localPhoneNumber?.replace(`${internationalPhonePrefix}`, '') ?? ''

    onContinue({
      ...rest,
      localPhoneNumber: parsedPhoneNumber,
      internationalPhonePrefix,
    })
  }

  return (
    <>
      <Form {...form}>
        <form
          id={US_INDIVIDUAL_FORM_ID}
          className="w-full"
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <div className="flex flex-col gap-8">
            <IndividualFields layout={layout} form={form} />

            <ContactDetailsFields form={form} />

            <USPaymentFields layout={layout} form={form} />
          </div>
        </form>
      </Form>

      <StickyContainer className="flex flex-col gap-3">
        <Button
          width="full"
          form={US_INDIVIDUAL_FORM_ID}
          onClick={form.handleSubmit(onSubmit)}
          disabled={!form.formState.isValid || isPending}
          loading={isPending}
          type="submit"
        >
          {layout === 'edit' ? (
            <FormattedMessage
              defaultMessage="Save changes"
              id="action.saveChanges"
            />
          ) : (
            <FormattedMessage
              defaultMessage="Save & Continue"
              id="action.saveAndContinue"
            />
          )}
        </Button>

        {onDelete ? (
          <DeleteRecipientAction
            onDelete={onDelete}
            recipient={recipient as CreatedRecipientWithId}
          />
        ) : null}
      </StickyContainer>
    </>
  )
}
