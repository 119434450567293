import { forwardRef, Ref, type SVGProps } from 'react'

const SvgCircleCancel = (
  props: SVGProps<SVGSVGElement>,
  ref: Ref<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 24 24"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      stroke={props.color || `currentColor`}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M5 12a7 7 0 1 1 14 .001 7 7 0 0 1-14 0"
      clipRule="evenodd"
    />
    <path
      stroke={props.color || `currentColor`}
      strokeLinecap="round"
      strokeWidth={1.5}
      d="m16.95 7.05-9.9 9.9"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgCircleCancel)
export default ForwardRef
