import { useCallback, useEffect, useRef } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { z } from 'zod'

import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  InputOTP,
} from '../ui'

const verificationSchema = z.object({
  otp: z
    .string()
    .min(1, {
      message: 'validation.otp.required',
    })
    .length(6, {
      message: 'validation.otp.length',
    }),
})

export type VerificationSchema = z.infer<typeof verificationSchema>

type Props = {
  action?: string | React.ReactNode
  description?: string | React.ReactNode
  isError?: boolean
  isOpen: boolean
  isPending: boolean
  onContinue: (otp: string) => void
  onOpenChange: (isOpen: boolean) => void
  title?: string | React.ReactNode
}

export const OTPDialog = ({
  action,
  description,
  isError,
  isOpen,
  isPending,
  onContinue,
  onOpenChange,
  title,
}: Props) => {
  const isSubmittingRef = useRef(false)

  const form = useForm<VerificationSchema>({
    mode: 'onChange',
    resolver: zodResolver(verificationSchema),
    defaultValues: { otp: '' },
  })

  const onSubmit: SubmitHandler<VerificationSchema> = useCallback(
    (data) => {
      onContinue(data.otp)

      isSubmittingRef.current = true
    },
    [onContinue],
  )

  useEffect(() => {
    if (isSubmittingRef.current) {
      return
    }

    const subscription = form.watch(() => form.handleSubmit(onSubmit)())

    return () => subscription.unsubscribe()
  }, [form, onSubmit])

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>
            {title ?? (
              <FormattedMessage
                defaultMessage="Enter verification code"
                id="otpDialog.enterVerificationCode"
              />
            )}
          </DialogTitle>
          <DialogDescription>
            {description ?? (
              <FormattedMessage
                defaultMessage="We have sent you an email with a verification code. Enter it to continue"
                id="otpDialog.enterVerificationCode.emailSent"
              />
            )}
          </DialogDescription>
        </DialogHeader>

        <div className="p-4" />

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="otp"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <InputOTP
                      shouldReset={isError}
                      variant="small"
                      disabled={isPending}
                      {...field}
                    />
                  </FormControl>
                  <FormLabel className="sr-only">
                    <FormattedMessage
                      defaultMessage="Verification code"
                      id="label.verificationCode"
                    />
                  </FormLabel>
                </FormItem>
              )}
            />

            <div className="p-3" />

            <Button
              loading={isPending}
              disabled={isPending || !form.formState.isValid}
              width="full"
              type="submit"
            >
              {action ?? (
                <FormattedMessage id="action.send" defaultMessage="Send" />
              )}
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
