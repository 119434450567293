import { api } from '@/lib/api'

import { Card } from '../types'

type ActivateCardRequest = {
  id: string
}

export function activateCard(data: ActivateCardRequest) {
  const { id } = data
  return api.post<Card>(`/v1/me/physical-cards/${id}/activate`)
}
