import { FormattedMessage, useIntl } from 'react-intl'

import {
  activityTypesMessages,
  sourceOfFundsMessages,
} from '@/constants/messages'
import { intersperse } from '@/lib/typography'
import { Typography } from '@/shared/ui'

import { useDocumentsList } from '../../hooks/useDocumentsList'
import { CompanyActivityStep, OnboardingStepName } from '../../types'
import { StepDetails } from '../StepDetails'

type Props = {
  stepDetails?: CompanyActivityStep['stepDetails']
}

export const CompanyActivityReview = ({ stepDetails }: Props) => {
  const intl = useIntl()

  const documentKeys: Record<string, string> = {
    SOURCE_OF_FUNDS: intl.formatMessage({
      id: 'onboarding.step.companyActivity.sourceOfFunds',
      defaultMessage: 'Source of funds',
    }),
  }

  const documentsDetails = useDocumentsList(
    stepDetails?.documents ?? [],
    documentKeys,
  )

  if (!stepDetails) {
    return null
  }

  const { documents, ...fields } = stepDetails

  const mappedKeys: Record<keyof typeof fields, string | undefined> = {
    activityType: intl.formatMessage({
      id: 'onboarding.step.companyActivity.industry',
      defaultMessage: 'Industry',
    }),
    sourcesOfFunds: intl.formatMessage({
      id: 'onboarding.step.companyActivity.sourceOfFunds',
      defaultMessage: 'Source of funds',
    }),
    expectedVolume: intl.formatMessage({
      id: 'onboarding.step.companyActivity.expectedVolume',
      defaultMessage: 'Expected monthly volume',
    }),
    website: intl.formatMessage({
      id: 'onboarding.step.companyActivity.website',
      defaultMessage: 'Company website or social network',
    }),
    description: intl.formatMessage({
      id: 'onboarding.step.companyActivity.businessDescription',
      defaultMessage: 'Business description',
    }),
    operatesInHighRiskActivities: intl.formatMessage({
      id: 'onboarding.step.companyActivity.highRiskActivities',
      defaultMessage: 'High-risk activity',
    }),
    operatesInProhibitedActivities: intl.formatMessage({
      id: 'onboarding.step.companyActivity.prohibitedActivities',
      defaultMessage: 'Prohibited activity',
    }),
    operatesInProhibitedCountries: intl.formatMessage({
      id: 'onboarding.step.companyActivity.prohibitedCountries',
      defaultMessage: 'Prohibited country',
    }),
  }

  const mappedValues: Record<keyof typeof fields, React.ReactNode> = {
    activityType: (
      <Typography>
        <FormattedMessage
          {...activityTypesMessages[fields.activityType as string]}
        />
      </Typography>
    ),
    sourcesOfFunds: (
      <Typography className="line-clamp-1 whitespace-break-spaces">
        {fields.sourcesOfFunds
          ? intersperse(
              fields.sourcesOfFunds?.map((value) => {
                return (
                  <FormattedMessage
                    key={value}
                    {...sourceOfFundsMessages[value]}
                  />
                )
              }),
              '; ',
            )
          : null}
      </Typography>
    ),
    expectedVolume: (
      <Typography>
        <FormattedMessage
          id="monthlyTransactionVolume.label"
          defaultMessage="{method, select, LESS_THAN_10K {Up to $10,000.00 USD} BETWEEN_10K_50K {Between $10,000.00 USD and $50,000.00 USD} BETWEEN_50K_100K {Between $50,000.00 USD and $100,000.00 USD} BETWEEN_100K_250K {Between $100,000.00 USD and $250,000.00 USD} BETWEEN_250K_500K {Between $250,000.00 USD and $500,000.00 USD} MORE_THAN_500K {More than $500,000.00 USD} other {}}"
          values={{ method: fields.expectedVolume }}
        />
      </Typography>
    ),
    website: <Typography>{fields.website}</Typography>,
    description: (
      <Typography title={fields.description} className="max-w-96 text-right">
        {fields.description}
      </Typography>
    ),
    operatesInHighRiskActivities: (
      <Typography>
        {fields.operatesInHighRiskActivities ? (
          <FormattedMessage id="label.yes" defaultMessage="Yes" />
        ) : (
          <FormattedMessage id="label.no" defaultMessage="No" />
        )}
      </Typography>
    ),
    operatesInProhibitedActivities: (
      <Typography>
        {fields.operatesInProhibitedActivities ? (
          <FormattedMessage id="label.yes" defaultMessage="Yes" />
        ) : (
          <FormattedMessage id="label.no" defaultMessage="No" />
        )}
      </Typography>
    ),
    operatesInProhibitedCountries: (
      <Typography>
        {fields.operatesInProhibitedCountries ? (
          <FormattedMessage id="label.yes" defaultMessage="Yes" />
        ) : (
          <FormattedMessage id="label.no" defaultMessage="No" />
        )}
      </Typography>
    ),
  }

  const details = Object.entries(fields).map(([key]) => {
    return {
      key: mappedKeys[key as keyof typeof mappedKeys],
      value: mappedValues[key as keyof typeof mappedValues],
    }
  })

  return (
    <StepDetails
      step={OnboardingStepName.COMPANY_ACTIVITY}
      title={intl.formatMessage({
        id: 'onboarding.step.companyActivity',
        defaultMessage: 'Company activity',
      })}
      details={[...details, ...documentsDetails]}
    />
  )
}
