import { FormattedMessage } from 'react-intl'

import { BulkTransaction } from '@/features/Transactions/types'
import { cn } from '@/lib/utils'

import { Users } from '../../icons/outline'
import { Typography } from '../../ui'

type Props = {
  transaction: BulkTransaction
}

export const BulkTransactionCell = ({ transaction }: Props) => {
  return (
    <div className="flex items-center gap-3">
      <div className="flex size-6 shrink-0 items-center justify-center rounded-md bg-neutral-gray-100 p-0.5">
        <Users />
      </div>

      <div className="flex items-center gap-1">
        <Typography className={cn('line-clamp-1')}>
          {transaction.bulkTransactionLabel}{' '}
          <FormattedMessage
            id="bulkTransaction.label.totalRecipients"
            defaultMessage="[{count} recipients]"
            values={{
              count:
                (transaction.transactions.length ?? 0) +
                (transaction.failedTransactions.length ?? 0),
            }}
          />
        </Typography>
      </div>
    </div>
  )
}
