import { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSearchParams } from 'react-router-dom'

import { CardState } from '@/features/Cards'
import { handleURLSearchParams } from '@/lib/utils'

import { Check } from '../../icons/outline'
import { Filter } from '../../icons/solid'
import {
  Button,
  DropdownMenuCheckboxItem,
  DropdownMenuGroup,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from '../../ui'

import { STATUS_FILTER_NAME } from './data'

export const CardStateFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const handleSearchParams = useCallback(
    (value: string) => {
      return (
        setSearchParams((params) =>
          handleURLSearchParams(params, STATUS_FILTER_NAME, value),
        ),
        { preventScrollReset: true }
      )
    },
    [setSearchParams],
  )

  return (
    <DropdownMenuGroup>
      <DropdownMenuSub>
        <DropdownMenuSubTrigger asChild>
          <Button
            className="h-10 w-full justify-between font-normal hover:text-neutral-gray-900 focus-visible:ring-transparent"
            variant="ghost"
          >
            <span className="flex items-center gap-2">
              <Check className="size-4" />
              <FormattedMessage defaultMessage="Status" id="label.status" />
            </span>
            {searchParams.get(STATUS_FILTER_NAME) && (
              <Filter className="size-4" />
            )}
          </Button>
        </DropdownMenuSubTrigger>
        <DropdownMenuPortal>
          <DropdownMenuSubContent className="w-52">
            {Object.values(CardState).map((state) => (
              <DropdownMenuCheckboxItem
                key={state}
                onSelect={(event) => event.preventDefault()}
                checked={searchParams.get(STATUS_FILTER_NAME)?.includes(state)}
                onCheckedChange={() => handleSearchParams(state)}
              >
                <FormattedMessage
                  id="card.state.label"
                  defaultMessage="{state, select, ACTIVE {Active} CREATED {Inactive} BLOCKED {Frozen} TERMINATED {Terminated} other {}}"
                  values={{ state }}
                />
              </DropdownMenuCheckboxItem>
            ))}
          </DropdownMenuSubContent>
        </DropdownMenuPortal>
      </DropdownMenuSub>
    </DropdownMenuGroup>
  )
}
