import { useQuery } from '@tanstack/react-query'

import { queryKeys } from '@/constants/queryKeys'
import { getSingleRecipient } from '@/features/Recipients/api'
import { failedTransactionStatuses } from '@/features/Transactions'
import { SingleTransaction } from '@/features/Transactions/types'
import { formatCurrency, formatMoney } from '@/lib/money'
import { cn, parseAdditionalDetails } from '@/lib/utils'
import { Typography } from '@/shared/ui'

import { TransactionIcon } from '../TransactionIcon'

export const TransactionShortListRow = ({
  transaction,
}: {
  transaction: SingleTransaction
}) => {
  const recipientId = parseAdditionalDetails<{ beneficiaryId?: string }>(
    transaction.additionalDetails,
  )?.beneficiaryId

  const recipientQuery = useQuery({
    queryKey: [queryKeys.getSingleRecipient, recipientId],
    queryFn: () => getSingleRecipient({ id: recipientId ?? '' }),
    select: (data) => data.data,
    enabled: !!recipientId,
  })

  const isFailed = failedTransactionStatuses.includes(
    transaction.transactionStatus,
  )

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-3">
        <TransactionIcon
          additionalDetails={transaction.additionalDetails}
          recipientType={recipientQuery.data?.entityType}
          transactionType={transaction.transactionType}
          transactionStatus={transaction.transactionStatus}
        />
        <Typography>{transaction.name}</Typography>
      </div>
      <div className="flex flex-col gap-1">
        <Typography text="right" className={cn('', isFailed && 'line-through')}>
          {formatMoney(transaction.baseAmount)}{' '}
          {formatCurrency(transaction.baseCurrency)}
        </Typography>
        <Typography
          text="right"
          className={cn('text-neutral-gray-400', isFailed && 'line-through')}
        >
          {formatMoney(transaction.localAmount)}{' '}
          {formatCurrency(transaction.localCurrency)}
        </Typography>
      </div>
    </div>
  )
}
