import { FormattedMessage } from 'react-intl'

import { useCopyToClipboard } from '@/hooks/useCopyToClipboard'
import { Link } from '@/shared/icons/outline'
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  Typography,
} from '@/shared/ui'

import { OnboardingMember } from '../types'

type Props = {
  member?: OnboardingMember
  link: string
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
}

export const CopyIdentityLinkDialog = ({
  member,
  link,
  isOpen,
  onOpenChange,
}: Props) => {
  const copyTextToClipboard = useCopyToClipboard()

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>
            <FormattedMessage
              defaultMessage="ID validation link"
              id="onboarding.identityValidationScreen.dialog.title"
            />
          </DialogTitle>
          <DialogDescription>
            <FormattedMessage
              defaultMessage="Share the link below with {name} manually so they can upload his identity documents. Remember they will need to have a valid legal ID!"
              id="onboarding.copyIdentityLink.dialog.subtitle"
              values={{
                name: member?.firstName,
              }}
            />
          </DialogDescription>
        </DialogHeader>

        <div className="p-4" />

        <div className="flex h-10 justify-center gap-3">
          <Card
            size="upload"
            className="flex max-w-56 items-center bg-neutral-gray-200 p-2"
          >
            <Typography className="truncate">{link}</Typography>
          </Card>
          <Button
            leftIcon={<Link className="size-5" />}
            size="md"
            onClick={() => copyTextToClipboard(link)}
          >
            <FormattedMessage id="action.copyLink" defaultMessage="Copy link" />
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}
