import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'
import { useIntl } from 'react-intl'
import { toast } from 'sonner'

import { queryKeys } from '@/constants/queryKeys'
import { useErrorToast } from '@/hooks/useErrorToast'
import { hasOTPRequiredError } from '@/lib/error'
import { queryClient } from '@/lib/queryClient'

import { approveTask } from '../api'

type CallbackProps = {
  id: string
  otp?: string
  onSuccess?: (id: string) => void
}

export function useApproveTask() {
  const notifyError = useErrorToast()
  const intl = useIntl()

  const { mutateAsync, isError, isPending, isSuccess } = useMutation({
    mutationFn: approveTask,
  })

  return {
    approveTask: useCallback(
      async ({ id, otp, onSuccess }: CallbackProps) => {
        try {
          const response = await mutateAsync({
            id,
            otp,
          })

          toast.success(
            intl.formatMessage({
              id: 'tasks.single.approve.dialog.success',
              defaultMessage: 'Task approved successfully',
            }),
          )

          queryClient.refetchQueries({
            queryKey: [queryKeys.getAdminTasks],
          })

          onSuccess?.(response.data.id)
        } catch (error) {
          if (error instanceof Error) {
            if (hasOTPRequiredError(error)) {
              return
            }

            notifyError(error)
          }
        }
      },
      [mutateAsync, intl, notifyError],
    ),
    isPending,
    isError,
    isSuccess,
  }
}
