import { api } from '@/lib/api'

import { TaskState } from '../types'

export function declineTask({ id }: { id: string }) {
  return api.post<{
    id: string
    state: TaskState.DECLINED
  }>(`/v1/me/business-tasks/${id}/decline`)
}
