import { FormattedMessage, useIntl } from 'react-intl'

import { STATES } from '@/constants/countries'
import {
  AnimatedFormLabel,
  FormControl,
  Input,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Typography,
} from '@/shared/ui'

type Props = {
  value: string
  onChange: (value: string) => void
  variant: 'select' | 'input'
}

export const StateSelectOrInput = ({ value, onChange, variant }: Props) => {
  const intl = useIntl()

  if (variant === 'select') {
    return (
      <Select onValueChange={onChange} defaultValue={value}>
        <FormControl>
          <SelectTrigger>
            {value ? (
              <div className="flex flex-col items-start">
                <Typography variant="body-tiny">
                  <FormattedMessage id="label.state" defaultMessage="State" />
                </Typography>
                <Typography className="text-neutral-gray-900">
                  <FormattedMessage
                    id="label.state.name"
                    defaultMessage="{state, select, AL {Alabama} AK {Alaska} AZ {Arizona} AR {Arkansas} CA {California} CO {Colorado} CT {Connecticut} DE {Delaware} FL {Florida} GA {Georgia} HI {Hawaii} ID {Idaho} IL {Illinois} IN {Indiana} IA {Iowa} KS {Kansas} KY {Kentucky} LA {Louisiana} ME {Maine} MD {Maryland} MA {Massachusetts} MI {Michigan} MN {Minnesota} MS {Mississippi} MO {Missouri} MT {Montana} NE {Nebraska} NV {Nevada} NH {New Hampshire} NJ {New Jersey} NM {New Mexico} NY {New York} NC {North Carolina} ND {North Dakota} OH {Ohio} OK {Oklahoma} OR {Oregon} PA {Pennsylvania} RI {Rhode Island} SC {South Carolina} SD {South Dakota} TN {Tennessee} TX {Texas} UT {Utah} VT {Vermont} VA {Virginia} WA {Washington} WV {West Virginia} WI {Wisconsin} WY {Wyoming} other {}}"
                    values={{
                      state: value,
                    }}
                  />
                </Typography>
              </div>
            ) : (
              <SelectValue
                placeholder={
                  <FormattedMessage id="label.state" defaultMessage="State" />
                }
              />
            )}
          </SelectTrigger>
        </FormControl>
        <SelectContent>
          {STATES.map(({ value: state }) => (
            <SelectItem disabled={value === state} key={state} value={state}>
              <Typography>
                <FormattedMessage
                  id="label.state.name"
                  defaultMessage="{state, select, AL {Alabama} AK {Alaska} AZ {Arizona} AR {Arkansas} CA {California} CO {Colorado} CT {Connecticut} DE {Delaware} FL {Florida} GA {Georgia} HI {Hawaii} ID {Idaho} IL {Illinois} IN {Indiana} IA {Iowa} KS {Kansas} KY {Kentucky} LA {Louisiana} ME {Maine} MD {Maryland} MA {Massachusetts} MI {Michigan} MN {Minnesota} MS {Mississippi} MO {Missouri} MT {Montana} NE {Nebraska} NV {Nevada} NH {New Hampshire} NJ {New Jersey} NM {New Mexico} NY {New York} NC {North Carolina} ND {North Dakota} OH {Ohio} OK {Oklahoma} OR {Oregon} PA {Pennsylvania} RI {Rhode Island} SC {South Carolina} SD {South Dakota} TN {Tennessee} TX {Texas} UT {Utah} VT {Vermont} VA {Virginia} WA {Washington} WV {West Virginia} WI {Wisconsin} WY {Wyoming} other {}}"
                  values={{ state }}
                />
              </Typography>
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    )
  }

  if (variant === 'input') {
    return (
      <>
        <FormControl>
          <Input
            autoComplete="address-level1"
            placeholder={intl.formatMessage({
              id: 'label.postCode',
              defaultMessage: 'Post code',
            })}
            onChange={(e) => onChange(e.target.value)}
            value={value}
          />
        </FormControl>
        <AnimatedFormLabel>
          <FormattedMessage id="label.state" defaultMessage="State" />
        </AnimatedFormLabel>
      </>
    )
  }
}
