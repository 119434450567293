import { forwardRef, Ref, type SVGProps } from 'react'

const SvgIdCard = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || 24}
    height={props.height || 24}
    fill="none"
    viewBox="0 0 20 16"
    role="img"
    ref={ref}
    {...props}
  >
    <path
      stroke={props.color || `currentColor`}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M1 3v10a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H3a2 2 0 0 0-2 2"
      clipRule="evenodd"
    />
    <path
      stroke={props.color || `currentColor`}
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M13 6h3M13 9h3"
    />
    <path
      stroke={props.color || `currentColor`}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M9 6a2 2 0 1 1-4 0 2 2 0 0 1 4 0"
      clipRule="evenodd"
    />
    <path
      stroke={props.color || `currentColor`}
      strokeLinecap="round"
      strokeWidth={1.5}
      d="M3 12c2.783-3.137 6.052-2.151 8 0"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgIdCard)
export default ForwardRef
