import { api } from '@/lib/api'

type SecuredCardDetailsResponse = {
  encryptedCardDetails: string
  encryptedKey: string
  iv: string
}

export async function getEncryptedCardDetails({ id }: { id: string }) {
  return api.get<SecuredCardDetailsResponse>(`/v1/me/cards/${id}/details`)
}
