import { useQuery } from '@tanstack/react-query'
import { FormattedMessage } from 'react-intl'

import { getIdentity } from '@/api'
import { queryKeys } from '@/constants/queryKeys'
import { AccountBalance, MoveMoneyWidget } from '@/shared/components'
import { Skeleton, Typography } from '@/shared/ui'

import { FXRatesWidget } from './components/FXRatesWidget'
import { TransactionsWidget } from './components/TransactionsWidget'

export const Home = () => {
  const userQuery = useQuery({
    queryKey: [queryKeys.getIdentity],
    queryFn: getIdentity,
    select: (data) => data.data,
  })

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full items-start justify-between">
        <div className="flex flex-col gap-2">
          <Typography variant="h3">
            <FormattedMessage id="dashboard.home.title" defaultMessage="Home" />
          </Typography>
          {userQuery.isPending ? (
            <Skeleton className="h-[21px] w-24" />
          ) : (
            <Typography className="text-neutral-gray-600">
              <FormattedMessage
                id="dashboard.home.subtitle"
                defaultMessage="Welcome, {name}!"
                values={{
                  name: userQuery.data?.firstName,
                }}
              />
            </Typography>
          )}
        </div>

        <MoveMoneyWidget />
      </div>

      <div className="p-6" />

      <AccountBalance />

      <div className="p-6" />

      <TransactionsWidget />

      <div className="p-4" />

      <FXRatesWidget />

      <div className="p-12" />
    </div>
  )
}
