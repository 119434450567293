import { useMemo } from 'react'
import { useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { formatDate, isSameYear } from 'date-fns'
import { FormattedMessage } from 'react-intl'
import { Link, useParams, useSearchParams } from 'react-router-dom'

import { TRANSACTIONS_ROUTE } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import {
  CashbackTransaction,
  SingleTransaction,
} from '@/features/Transactions/types'
import { useSearchInput } from '@/hooks/useSearchInput'
import { useTransactionUtils } from '@/hooks/useTransactionUtils'
import { intersperse } from '@/lib/typography'
import { parseAdditionalDetails } from '@/lib/utils'
import {
  AccountsBadge,
  AccountsFilter,
  ActiveFilters,
  CurrencyBadge,
  CurrencyFilter,
  DateBadge,
  DateFilter,
  EmptyTransactionsCard,
  FiltersWidget,
  MoveMoneyWidget,
  TransactionDetailsSidebar,
  TransactionsTable,
} from '@/shared/components'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
  Button,
  SearchInput,
  Skeleton,
  Typography,
} from '@/shared/ui'

import { getCashbackTransactions, getTransaction } from '../Transactions/api'

import { useCashbackTransactionColumns } from './components/useCashbackTransactionsColumns'

export const CashbackTransactions = () => {
  const columns = useCashbackTransactionColumns()
  const [search, setSearch, handleSearchQuery] = useSearchInput()
  const [, setSearchParams] = useSearchParams()
  const { id: transactionId } = useParams<{ id: string }>()

  const { closeSidebar, params, isSidebarOpen, transactionIdFromQuery } =
    useTransactionUtils()

  const [cashbackTransactions, singleTransaction, cashbackTransaction] =
    useQueries({
      queries: [
        {
          queryKey: [queryKeys.getCashbackTransactions, transactionId, params],
          select: (data: AxiosResponse<CashbackTransaction>) =>
            data.data.eligibleTransactions,
          queryFn: () =>
            getCashbackTransactions({
              id: transactionId ?? '',
              params,
            }),
        },
        {
          queryKey: [queryKeys.getTransaction, transactionIdFromQuery],
          queryFn: () => getTransaction({ id: transactionIdFromQuery ?? '' }),
          select: (data: AxiosResponse<SingleTransaction>) => data?.data,
          enabled: !!transactionIdFromQuery,
        },
        {
          queryKey: [queryKeys.getTransaction, transactionId],
          queryFn: () => getTransaction({ id: transactionId ?? '' }),
          select: (data: AxiosResponse<SingleTransaction>) => data?.data,
          enabled: !!transactionId,
        },
      ],
    })

  const cashbackPeriod = useMemo(() => {
    if (!cashbackTransaction.data) {
      return undefined
    }

    const parsedAdditionalDetails = parseAdditionalDetails<{
      cashbackStartDate?: string
      cashbackEndDate?: string
    }>(cashbackTransaction.data.additionalDetails)

    return intersperse(
      [
        formatDate(
          parsedAdditionalDetails?.cashbackStartDate ?? '',
          isSameYear(
            parsedAdditionalDetails?.cashbackStartDate ?? '',
            parsedAdditionalDetails?.cashbackEndDate ?? '',
          )
            ? 'dd MMM.'
            : 'dd MMM. yyyy',
        ),
        formatDate(
          parsedAdditionalDetails?.cashbackEndDate ?? '',
          'dd MMM. yyyy',
        ),
      ],
      ' - ',
    )
  }, [cashbackTransaction.data])

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full flex-wrap justify-between gap-6">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbLink asChild>
              <Button variant="ghost" size="inline" asChild>
                <Link to={TRANSACTIONS_ROUTE}>
                  <FormattedMessage
                    id="label.transactions"
                    defaultMessage="Transactions"
                  />
                </Link>
              </Button>
            </BreadcrumbLink>

            <BreadcrumbSeparator />

            <BreadcrumbItem>
              {cashbackTransaction.isPending ? (
                <Skeleton className="h-6 w-36" />
              ) : (
                <Typography className="line-clamp-1">
                  <FormattedMessage
                    id="label.cashback"
                    defaultMessage="Cashback"
                  />{' '}
                  {cashbackPeriod}
                </Typography>
              )}
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>

        <MoveMoneyWidget />
      </div>

      <div className="p-4" />

      <div className="flex flex-wrap gap-3 md:flex-nowrap">
        <SearchInput
          value={search}
          onChange={(value) => {
            setSearch(value)
            handleSearchQuery(value)
          }}
        />

        <FiltersWidget>
          <AccountsFilter />
          <DateFilter />
          <CurrencyFilter />
        </FiltersWidget>
      </div>

      <div className="p-4" />

      <ActiveFilters>
        <AccountsBadge />
        <DateBadge />
        <CurrencyBadge />
      </ActiveFilters>

      {cashbackTransactions.data?.length === 0 &&
      Object.keys(params).length === 0 ? (
        <EmptyTransactionsCard />
      ) : (
        <TransactionsTable
          isLoading={cashbackTransactions.isPending}
          onRowClick={(tx) =>
            setSearchParams((params) => {
              params.set('tx', tx.id)

              return params
            })
          }
          columns={columns}
          data={cashbackTransactions.data ?? []}
          loaderOptions={{ rows: 5 }}
        />
      )}

      <TransactionDetailsSidebar
        transaction={singleTransaction.data}
        isOpen={isSidebarOpen}
        onOpenChange={closeSidebar}
      />
    </div>
  )
}
