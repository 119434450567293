import { useQuery } from '@tanstack/react-query'

import { getBusinessUser } from '@/api'
import { queryKeys } from '@/constants/queryKeys'

export function useBusinessUser() {
  const businessUserQuery = useQuery({
    queryKey: [queryKeys.getBusinessUser],
    queryFn: getBusinessUser,
    select: (data) => data.data,
  })

  return businessUserQuery.data
}
