import { api } from '@/lib/api'

import { TransactionSearchParams } from '../types'
import { handleTransactionQueryParams } from '../utils'

export function getTransactionsCSV(params: TransactionSearchParams) {
  const searchQuery = handleTransactionQueryParams(params)

  return api.get<BlobPart>(`/v1/transaction-statements${searchQuery}`, {
    responseType: 'blob',
  })
}
