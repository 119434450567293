import { useMutation, useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link, useParams } from 'react-router-dom'

import { CARDS_ROUTE } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { SingleTransaction, Transaction } from '@/features/Transactions/types'
import { useErrorToast } from '@/hooks/useErrorToast'
import { useSearchInput } from '@/hooks/useSearchInput'
import { useTransactionUtils } from '@/hooks/useTransactionUtils'
import { getCardTitle } from '@/lib/card'
import { slugify } from '@/lib/typography'
import { downloadFile } from '@/lib/utils'
import {
  AccountsBadge,
  AccountsFilter,
  ActiveFilters,
  CARDS_ID_FILTER_NAME,
  CurrencyBadge,
  CurrencyFilter,
  DateBadge,
  DateFilter,
  EmptyTransactionsCard,
  FiltersWidget,
  MoveMoneyWidget,
  StateBadge,
  StateFilter,
  TransactionDetailsSidebar,
  TransactionsTable,
} from '@/shared/components'
import { DownloadFile } from '@/shared/icons/outline'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
  Button,
  SearchInput,
  Skeleton,
  Typography,
} from '@/shared/ui'

import {
  getTransaction,
  getTransactions,
  getTransactionsCSV,
} from '../Transactions/api'

import { getSingleCard } from './api'
import { useCardTransactionColumns } from './components/useCardTransactionsColumn'
import { Card } from './types'

export const CardTransactions = () => {
  const intl = useIntl()
  const columns = useCardTransactionColumns()
  const [search, setSearch, handleSearchQuery] = useSearchInput()
  const { id: cardId } = useParams<{ id: string }>()
  const notifyError = useErrorToast()

  const {
    closeSidebar,
    openSidebar,
    params,
    isSidebarOpen,
    transactionIdFromQuery,
  } = useTransactionUtils()

  const [allTransactions, singleTransaction, singleCard] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getTransactions, cardId, params],
        queryFn: () =>
          getTransactions({ ...params, [CARDS_ID_FILTER_NAME]: cardId }),
        staleTime: 0,
        select: (data: AxiosResponse<Transaction[]>) =>
          data?.data as SingleTransaction[],
        enabled: !!cardId,
      },
      {
        queryKey: [queryKeys.getTransaction, transactionIdFromQuery],
        queryFn: () => getTransaction({ id: transactionIdFromQuery ?? '' }),
        select: (data: AxiosResponse<SingleTransaction>) => data?.data,
        enabled: !!transactionIdFromQuery,
      },
      {
        queryKey: [queryKeys.getSingleCard, cardId],
        queryFn: () => getSingleCard({ id: cardId ?? '' }),
        enabled: !!cardId,
        select: (data: AxiosResponse<Card>) => data?.data,
      },
    ],
  })

  const { mutateAsync, isPending } = useMutation({
    mutationFn: getTransactionsCSV,
  })

  const downloadCSV = async () => {
    try {
      const response = await mutateAsync({
        ...params,
        [CARDS_ID_FILTER_NAME]: cardId,
      })

      downloadFile(
        response.data,
        intl.formatMessage(
          {
            id: 'cards.transactions.statement.csv',
            defaultMessage: 'transactions-{card}.csv',
          },
          { card: slugify(singleCard.data?.nickname) },
        ),
      )
    } catch (error) {
      if (error instanceof Error) {
        notifyError(error)
      }
    }
  }

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full flex-wrap justify-between gap-6">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbLink asChild>
              <Button variant="ghost" size="inline" asChild>
                <Link to={CARDS_ROUTE}>
                  <FormattedMessage id="label.cards" defaultMessage="Cards" />
                </Link>
              </Button>
            </BreadcrumbLink>

            <BreadcrumbSeparator />

            <BreadcrumbLink asChild>
              <Button variant="ghost" size="inline" asChild>
                {singleCard.isPending ? (
                  <Skeleton className="h-6 w-36" />
                ) : (
                  <Link to={CARDS_ROUTE}>{getCardTitle(singleCard.data)}</Link>
                )}
              </Button>
            </BreadcrumbLink>

            <BreadcrumbSeparator />

            <BreadcrumbItem>
              <Typography>
                <FormattedMessage
                  id="label.transactions"
                  defaultMessage="Transactions"
                />
              </Typography>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
        <MoveMoneyWidget />
      </div>

      <div className="p-4" />

      <div className="flex flex-wrap gap-3 md:flex-nowrap">
        <SearchInput
          value={search}
          onChange={(value) => {
            setSearch(value)
            handleSearchQuery(value)
          }}
        />

        <FiltersWidget>
          <AccountsFilter />
          <DateFilter />
          <StateFilter />
          <CurrencyFilter />
        </FiltersWidget>

        <Button
          size="md"
          loading={isPending}
          loaderPosition="left"
          leftIcon={<DownloadFile className="size-5" />}
          onClick={downloadCSV}
          disabled={allTransactions.data?.length === 0 || isPending}
          variant="tertiary"
        >
          <FormattedMessage
            id="action.exportCSV"
            defaultMessage="Export .csv"
          />
        </Button>
      </div>

      <div className="p-4" />

      <ActiveFilters>
        <AccountsBadge />
        <DateBadge />
        <StateBadge />
        <CurrencyBadge />
      </ActiveFilters>

      {allTransactions.data?.length === 0 &&
      Object.keys(params).length === 0 ? (
        <EmptyTransactionsCard />
      ) : (
        <TransactionsTable
          isLoading={allTransactions.isPending}
          onRowClick={openSidebar}
          columns={columns}
          data={allTransactions.data ?? []}
          loaderOptions={{ rows: 5 }}
        />
      )}

      <TransactionDetailsSidebar
        transaction={singleTransaction.data}
        isOpen={isSidebarOpen}
        onOpenChange={closeSidebar}
      />
    </div>
  )
}
