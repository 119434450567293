import { http, HttpResponse } from 'msw'

import { BASE_API_DEV } from '@/constants/urls'

import {
  INVITED_TEAM_MEMBER_MOCK_RESPONSE,
  MOCKED_PAYMENT_LIMIT,
  MOCKED_TEAM_MEMBER_TRANSFER_PERMISSION,
  MOCKED_TEAM_MEMBERS,
} from './teamMembers.mock'

export const teamHandlers = [
  http.post(`${BASE_API_DEV}/v1/me/business-user-invitations`, async () => {
    return HttpResponse.json(INVITED_TEAM_MEMBER_MOCK_RESPONSE)
  }),
  http.get(`${BASE_API_DEV}/v1/me/business-users/search`, async () => {
    return HttpResponse.json(MOCKED_TEAM_MEMBERS)
  }),

  http.get(`${BASE_API_DEV}/v1/me/business-users/:id`, async ({ params }) => {
    const { id } = params

    const user = MOCKED_TEAM_MEMBERS.find((user) => user.id === id)

    if (!user) {
      return HttpResponse.json(MOCKED_TEAM_MEMBERS[0])
    }

    return HttpResponse.json(user)
  }),

  http.post(
    `${BASE_API_DEV}/v1/me/business-users/:id/transfer-permissions`,
    async () => {
      return HttpResponse.json(MOCKED_TEAM_MEMBER_TRANSFER_PERMISSION)
    },
  ),

  http.get(
    `${BASE_API_DEV}/v1/me/business-users/:id/payment-limits`,
    async () => {
      return HttpResponse.json(MOCKED_PAYMENT_LIMIT)
    },
  ),

  http.get(`${BASE_API_DEV}/v1/my/business-users/payment-limits`, async () => {
    return HttpResponse.json(MOCKED_PAYMENT_LIMIT)
  }),

  http.put(`${BASE_API_DEV}/v1/me/business-users/:id`, async () => {
    return HttpResponse.json(MOCKED_TEAM_MEMBERS[0])
  }),

  http.post(`${BASE_API_DEV}/v1/me/business-users/:id/block`, async () => {
    return HttpResponse.json(MOCKED_TEAM_MEMBERS[0])
  }),

  http.post(`${BASE_API_DEV}/v1/me/business-users/:id/unblock`, async () => {
    return HttpResponse.json(MOCKED_TEAM_MEMBERS[0])
  }),

  http.post(`${BASE_API_DEV}/v1/me/business-users/:id/terminate`, async () => {
    return HttpResponse.json(MOCKED_TEAM_MEMBERS[0])
  }),
]
