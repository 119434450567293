import { OnboardingStep, OnboardingStepConfig } from '../types'

export function getPreviousStep(
  steps: OnboardingStep[],
  config?: OnboardingStepConfig,
) {
  const currentStepIndex = steps.findIndex((step) => step.name === config?.name)

  return steps[currentStepIndex - 1].name
}
