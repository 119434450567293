import { useQuery } from '@tanstack/react-query'

import { getBusinessUser } from '@/api'
import { queryKeys } from '@/constants/queryKeys'
import { Role } from '@/types/roles'

export function useBusinessRole() {
  const businessUserQuery = useQuery({
    queryKey: [queryKeys.getBusinessUser],
    queryFn: getBusinessUser,
    select: (data) => data.data,
  })

  return {
    isPending: businessUserQuery.isPending,
    role: businessUserQuery.data?.role,
    isAdmin: businessUserQuery.data?.role === Role.ADMIN,
    isPaymentOps: businessUserQuery.data?.role === Role.PAYMENT_OPS,
    isCardUser: businessUserQuery.data?.role === Role.CARD_USER,
    isReadOnly: businessUserQuery.data?.role === Role.READ_ONLY,
  }
}
