import { FormattedMessage } from 'react-intl'

import { StatusBadge, StatusVariant } from '@/shared/components'

import { TeamMember, TeamState } from '../../types'

const badgeVariantByTeamState: Record<TeamState, StatusVariant> = {
  [TeamState.ACTIVE]: 'active',
  [TeamState.INVITED]: 'neutral',
  [TeamState.BLOCKED]: 'neutral',
  [TeamState.TERMINATED]: 'failed',
}

type Props = {
  member: TeamMember
}

export const TeamStateCell = ({ member }: Props) => {
  return (
    <div className="flex items-center">
      <StatusBadge
        variant={badgeVariantByTeamState[member.state]}
        title={
          <FormattedMessage
            id="team.state.label"
            defaultMessage="{state, select, ACTIVE {Active} INVITED {Invited} BLOCKED {Blocked} TERMINATED {Terminated} other {}}"
            values={{ state: member.state }}
          />
        }
      />
    </div>
  )
}
