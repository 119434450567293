import { iconByRecipientType } from '@/features/Transactions'
import { Typography } from '@/shared/ui'

import { Recipient } from '../../types'

type Props = {
  recipient: Recipient
}

export const RecipientNicknameCell = ({ recipient }: Props) => {
  const Icon = iconByRecipientType[recipient.entityType]

  return (
    <div className="flex items-center gap-2">
      <div className="relative flex size-6 shrink-0 items-center justify-center rounded-md bg-neutral-gray-100 p-0.5">
        <Icon />
      </div>
      <Typography>{recipient.nickname}</Typography>
    </div>
  )
}
