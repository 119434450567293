import { api } from '@/lib/api'

import { WithdrawalTaskDetails } from '../types'

export function getWithdrawalTaskDetails({
  withdrawalId,
}: {
  withdrawalId: string
}) {
  return api.get<WithdrawalTaskDetails>(
    `/v1/me/withdrawal-quotes/${withdrawalId}/approval`,
  )
}
