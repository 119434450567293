import { FormattedMessage } from 'react-intl'

import { PaperPlain } from '@/shared/icons/solid'
import { SlideInScreen, Typography } from '@/shared/ui'

export const OnboardingComplete = () => {
  return (
    <SlideInScreen className="-mb-12 -mt-12 flex h-screen flex-col items-center justify-center md:-mb-24 md:-mt-24">
      <PaperPlain className="h-32 w-32" />
      <Typography text="center" variant="h3">
        <FormattedMessage
          id="onboarding.completed.title"
          defaultMessage="Application submitted successfully!"
        />
      </Typography>
      <div className="p-2" />
      <Typography text="center">
        <FormattedMessage
          id="onboarding.completed.subtitle"
          defaultMessage="We are reviewing your application. We will send you an email when your account is approved or if we need further information"
        />
      </Typography>
    </SlideInScreen>
  )
}
