import { forwardRef, Ref, type SVGProps } from 'react'

const SvgIdCard = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="4.7 5.8 15.6 12.4"
    width={props.width || 24}
    height={props.height || 24}
    role="img"
    ref={ref}
    {...props}
  >
    <path
      fill="#000"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.6}
      d="M5 8v8c0 1.049.85 1.9 1.9 1.9h11.2A1.9 1.9 0 0 0 20 16V8a1.9 1.9 0 0 0-1.9-1.9H6.9A1.9 1.9 0 0 0 5 8"
    />
    <path
      fill="#000"
      fillRule="evenodd"
      d="M14.9 10.399h2.4z"
      clipRule="evenodd"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeWidth={0.6}
      d="M14.9 10.399h2.4"
    />
    <path
      fill="#000"
      fillRule="evenodd"
      d="M14.9 12.8h2.4z"
      clipRule="evenodd"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeWidth={0.6}
      d="M14.9 12.8h2.4"
    />
    <path
      fill="#000"
      fillRule="evenodd"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.6}
      d="M12 10.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"
      clipRule="evenodd"
    />
    <path
      fill="#000"
      fillRule="evenodd"
      d="M8 15c1.74-1.569 3.783-1.075 5 0z"
      clipRule="evenodd"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeWidth={0.6}
      d="M8 15c1.74-1.569 3.783-1.075 5 0"
    />
  </svg>
)
const ForwardRef = forwardRef(SvgIdCard)
export default ForwardRef
