import { FormattedMessage, useIntl } from 'react-intl'

import { useCountryCodes } from '@/hooks/useCountryCodes'
import { CountryCode } from '@/types/country'

import {
  FormControl,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Typography,
} from '../ui'

const EXCLUDED_COUNTRIES = [CountryCode.CL, CountryCode.PT]

type Props = {
  onChange: (value: CountryCode) => void
  value: string
}

export const CountrySelect = ({ value, onChange }: Props) => {
  const intl = useIntl()

  const phoneCodesQuery = useCountryCodes()

  return (
    <Select onValueChange={onChange} defaultValue={value}>
      <FormControl>
        <SelectTrigger>
          {value ? (
            <div className="flex flex-col items-start">
              <Typography variant="body-tiny">
                <FormattedMessage
                  id="label.countryOfIncorporation"
                  defaultMessage="Country of incorporation"
                />
              </Typography>
              <Typography className="text-neutral-gray-900">
                <FormattedMessage
                  id="country.name"
                  defaultMessage="{country, select, MEX {Mexico} USA {United States} ARG {Argentina} BRA {Brazil} COL {Colombia} CHL {Chile} URY {Uruguay} ESP {Spain} PRT {Portugal} other {}}"
                  values={{ country: value }}
                />
              </Typography>
            </div>
          ) : (
            <SelectValue
              placeholder={intl.formatMessage({
                id: 'label.countryOfIncorporation',
                defaultMessage: 'Country of incorporation',
              })}
            />
          )}
        </SelectTrigger>
      </FormControl>
      <SelectContent>
        {Object.values(CountryCode)
          .filter((country) => !EXCLUDED_COUNTRIES.includes(country))
          .map((country) => {
            const countryFlag = phoneCodesQuery.data?.find(
              (c) => c.isoCode === country,
            )?.flag

            return (
              <SelectItem
                disabled={country === value}
                className="flex h-[54px] items-center gap-3"
                key={country}
                value={country}
              >
                {countryFlag}
                <Typography>
                  <FormattedMessage
                    id="country.name"
                    defaultMessage="{country, select, MEX {Mexico} USA {United States} ARG {Argentina} BRA {Brazil} COL {Colombia} CHL {Chile} URY {Uruguay} ESP {Spain} PRT {Portugal} other {}}"
                    values={{ country }}
                  />
                </Typography>
              </SelectItem>
            )
          })}
      </SelectContent>
    </Select>
  )
}
