import { Currency } from '@/constants/currency'
import { Account } from '@/features/Accounts/types'
import { api } from '@/lib/api'

export type CreateAccountRequest = {
  accountId: string
  currency: Currency
  label: string
}

export function createAccount(data: CreateAccountRequest) {
  const { accountId, ...rest } = data
  return api.post<Account>(`/v1/me/account/${accountId}/wallets`, rest)
}
