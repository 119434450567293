import { api } from '@/lib/api'

import { TaskState } from '../types'

type ApproveTaskResponse = {
  id: string
  state: TaskState.APPROVED
}

export function approveMultipleTasks({
  businessTaskIds,
  otp,
}: {
  businessTaskIds: string[]
  otp?: string
}) {
  return api.post<ApproveTaskResponse[]>(`/v1/me/business-tasks/bulk-approve`, {
    businessTaskIds,
    otp,
  })
}
