import { useCallback, useMemo } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { isValidPhoneNumber } from 'react-phone-number-input/input'
import { generatePath } from 'react-router-dom'
import { z } from 'zod'

import { getIdentity } from '@/api'
import { ONBOARDING_STEP } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { removeEmptyFormFields } from '@/lib/utils'
import {
  CountryCodeField,
  GoBackButton,
  OptionalTag,
  Widget,
} from '@/shared/components'
import {
  AnimatedFormLabel,
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  Input,
  PhoneNumberInput,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'
import { Identity } from '@/types/business'
import { CountryCode } from '@/types/country'

import {
  CompanyOwner,
  OnboardingStepConfig,
  OnboardingStepName,
  OwnerType,
} from '../../types'
import { CountryTaxResidenceCombobox } from '../CountryTaxResidenceCombobox'

const createOwnerSchema = z.object({
  FIRST_NAME: z.string(),
  LAST_NAME: z.string(),
  SECOND_LAST_NAME: z.string(),
  EMAIL: z.string().email({
    message: 'validation.email.invalid',
  }),
  PHONE_PREFIX: z.string(),
  PHONE_NUMBER: z.string().refine(isValidPhoneNumber, {
    message: 'validation.phoneNumber.invalid',
  }),
  TAX_ID: z.string(),
  TAX_RESIDENCE_COUNTRY: z.string(),
})

export type CreateOwnerSchema = z.infer<typeof createOwnerSchema>

const CREATE_OWNER_FORM_ID = 'create-owner-form-id'

export type OwnerDetails = {
  firstName: string
  lastName: string
  secondLastName: string
  email: string
  internationalPhonePrefix: string
  localPhoneNumber: string
  taxId: string
  taxResidenceCountry: CountryCode
}

type Props = {
  config?: OnboardingStepConfig
  data?: Omit<CompanyOwner, 'id'>
  onBack: () => void
  onContinue: (data: OwnerDetails) => void
  owner?: CompanyOwner
  ownerType?: OwnerType
  user?: Identity
}

export const OwnerDetailsStep = ({
  config,
  data,
  onBack,
  onContinue,
  owner,
  ownerType,
}: Props) => {
  const intl = useIntl()

  const isUserOwner = useMemo(
    () => ownerType === OwnerType.USER_OWNER,
    [ownerType],
  )

  const userQuery = useQuery({
    queryKey: [queryKeys.getIdentity],
    queryFn: getIdentity,
    select: (data: AxiosResponse<Identity>) => data.data,
    enabled: isUserOwner,
  })

  const form = useForm<CreateOwnerSchema>({
    mode: 'onChange',
    resolver: zodResolver(createOwnerSchema),
    values: {
      FIRST_NAME:
        isUserOwner && userQuery.data
          ? userQuery.data.firstName
          : data?.firstName
            ? data.firstName
            : owner?.firstName
              ? owner.firstName
              : '',
      LAST_NAME:
        isUserOwner && userQuery.data
          ? userQuery.data.lastName
          : data?.lastName
            ? data.lastName
            : owner?.lastName
              ? owner.lastName
              : '',
      SECOND_LAST_NAME:
        isUserOwner && userQuery.data?.secondLastName
          ? userQuery.data.secondLastName
          : data?.secondLastName
            ? data.secondLastName
            : owner?.secondLastName
              ? owner.secondLastName
              : '',
      EMAIL:
        isUserOwner && userQuery.data
          ? userQuery.data.email
          : data?.email
            ? data.email
            : owner?.email
              ? owner.email
              : '',
      PHONE_PREFIX:
        isUserOwner && userQuery.data?.phoneNumber
          ? userQuery.data.phoneNumber.internationalPhonePrefix
          : data?.internationalPhonePrefix
            ? data.internationalPhonePrefix
            : owner?.internationalPhonePrefix
              ? owner.internationalPhonePrefix
              : '+52',
      PHONE_NUMBER:
        isUserOwner && userQuery.data?.phoneNumber
          ? userQuery.data.phoneNumber.internationalPhonePrefix +
            userQuery.data.phoneNumber.localPhoneNumber
          : data?.localPhoneNumber
            ? data.internationalPhonePrefix + data.localPhoneNumber
            : owner?.localPhoneNumber
              ? owner.internationalPhonePrefix + owner.localPhoneNumber
              : '',
      TAX_ID: data?.taxId ?? owner?.taxId ?? '',
      TAX_RESIDENCE_COUNTRY:
        data?.taxResidenceCountry ?? owner?.taxResidenceCountry ?? '',
    },
  })

  const onSubmit: SubmitHandler<CreateOwnerSchema> = useCallback(
    (data) => {
      removeEmptyFormFields(data)

      const {
        FIRST_NAME,
        LAST_NAME,
        SECOND_LAST_NAME,
        EMAIL,
        PHONE_PREFIX,
        PHONE_NUMBER,
        TAX_ID,
        TAX_RESIDENCE_COUNTRY,
      } = data

      const requestData = {
        firstName: FIRST_NAME,
        lastName: LAST_NAME,
        secondLastName: SECOND_LAST_NAME,
        email: EMAIL,
        internationalPhonePrefix: PHONE_PREFIX,
        localPhoneNumber: PHONE_NUMBER.replace(PHONE_PREFIX, ''),
        taxId: TAX_ID,
        taxResidenceCountry: TAX_RESIDENCE_COUNTRY as CountryCode,
      }

      onContinue(requestData)
    },
    [onContinue],
  )

  const fields = form.watch()

  const hasAllRequiredFields = useMemo(() => {
    if (!config?.fields) {
      return false
    }

    const stepFields = [
      'FIRST_NAME',
      'LAST_NAME',
      'EMAIL',
      'PHONE_PREFIX',
      'PHONE_NUMBER',
      'TAX_ID',
      'TAX_RESIDENCE_COUNTRY',
    ]

    const filteredRequiredFields = config.fields.filter(
      (field) => stepFields.includes(field.name) && field.required,
    )

    return filteredRequiredFields.every(
      (field) => fields[field.name as keyof typeof fields],
    )
  }, [config?.fields, fields])

  return (
    <>
      <GoBackButton
        to={
          owner
            ? generatePath(ONBOARDING_STEP, {
                step: OnboardingStepName.COMPANY_OWNERSHIP,
              })
            : undefined
        }
        onClick={owner ? undefined : onBack}
      />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            id="onboarding.addNewOwner"
            defaultMessage="Add a new beneficial owner"
          />
        </Typography>

        <div className="p-2" />

        <Typography text="center">
          <FormattedMessage
            id="onboarding.addNewOwner.subtitle"
            defaultMessage="Enter their personal details to start"
          />
        </Typography>

        <div className="p-6" />

        <Form {...form}>
          <form id={CREATE_OWNER_FORM_ID} className="w-full">
            <Widget
              title={
                <FormattedMessage
                  defaultMessage="Personal details"
                  id="onboarding.companyOwnership.personalDetails"
                />
              }
              variant="form"
            >
              <FormField
                control={form.control}
                name="FIRST_NAME"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        disabled={isUserOwner}
                        autoComplete="given-name"
                        placeholder={intl.formatMessage({
                          defaultMessage: 'First name',
                          id: 'label.firstName',
                        })}
                        {...field}
                      />
                    </FormControl>
                    <AnimatedFormLabel>
                      <FormattedMessage
                        defaultMessage="First name"
                        id="label.firstName"
                      />
                    </AnimatedFormLabel>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="LAST_NAME"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        disabled={isUserOwner}
                        autoComplete="family-name"
                        placeholder={intl.formatMessage({
                          defaultMessage: 'Last name',
                          id: 'label.lastName',
                        })}
                        {...field}
                      />
                    </FormControl>
                    <AnimatedFormLabel>
                      <FormattedMessage
                        defaultMessage="Last name"
                        id="label.lastName"
                      />
                    </AnimatedFormLabel>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="SECOND_LAST_NAME"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        disabled={isUserOwner}
                        autoComplete="additional-name"
                        placeholder={intl.formatMessage({
                          defaultMessage: 'Second last name',
                          id: 'label.secondLastName',
                        })}
                        {...field}
                      />
                    </FormControl>
                    <AnimatedFormLabel>
                      <FormattedMessage
                        defaultMessage="Second last name"
                        id="label.secondLastName"
                      />
                    </AnimatedFormLabel>

                    {field.value === '' && <OptionalTag />}
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="EMAIL"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        disabled={isUserOwner}
                        autoComplete="email"
                        placeholder={intl.formatMessage({
                          defaultMessage: 'Work email',
                          id: 'label.workEmail',
                        })}
                        {...field}
                      />
                    </FormControl>
                    <AnimatedFormLabel>
                      <FormattedMessage
                        defaultMessage="Work email"
                        id="label.workEmail"
                      />
                    </AnimatedFormLabel>
                  </FormItem>
                )}
              />

              <div className="flex items-stretch gap-3">
                <FormField
                  control={form.control}
                  name="PHONE_PREFIX"
                  render={({ field }) => {
                    return (
                      <CountryCodeField
                        disabled={isUserOwner}
                        value={field.value}
                        onSelect={(value) =>
                          form.setValue('PHONE_PREFIX', value)
                        }
                      />
                    )
                  }}
                />

                <FormField
                  control={form.control}
                  name="PHONE_NUMBER"
                  render={({ field }) => (
                    <FormItem className="relative flex-1">
                      <FormControl>
                        <PhoneNumberInput
                          disabled={isUserOwner}
                          phonePrefix={fields.PHONE_PREFIX}
                          placeholder={intl.formatMessage({
                            defaultMessage: 'Phone number',
                            id: 'label.phoneNumber',
                          })}
                          {...field}
                        />
                      </FormControl>
                      <AnimatedFormLabel>
                        <FormattedMessage
                          defaultMessage="Phone number"
                          id="label.phoneNumber"
                        />
                      </AnimatedFormLabel>
                    </FormItem>
                  )}
                />
              </div>

              <FormField
                control={form.control}
                name="TAX_RESIDENCE_COUNTRY"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <CountryTaxResidenceCombobox
                        placeholder={intl.formatMessage({
                          id: 'label.countryOfTaxResidence',
                          defaultMessage: 'Country of tax residence',
                        })}
                        onSelect={(value) => {
                          field.onChange(value.valueAsCode)
                        }}
                        value={field.value}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="TAX_ID"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        placeholder={intl.formatMessage({
                          defaultMessage: 'Tax identification number',
                          id: 'label.taxIdentificationNumber',
                        })}
                        {...field}
                      />
                    </FormControl>
                    <AnimatedFormLabel>
                      <FormattedMessage
                        defaultMessage="Tax identification number"
                        id="label.taxIdentificationNumber"
                      />
                    </AnimatedFormLabel>
                  </FormItem>
                )}
              />
            </Widget>
          </form>
        </Form>

        <StickyContainer>
          <Button
            width="full"
            disabled={!hasAllRequiredFields}
            form={CREATE_OWNER_FORM_ID}
            onClick={form.handleSubmit(onSubmit)}
            type="submit"
          >
            <FormattedMessage
              defaultMessage="Save & Continue"
              id="action.saveAndContinue"
            />
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
