import { AVAILABLE_LANGUAGES, Locale } from '@/constants/locales'
import { getFlagUrl } from '@/lib/images'
import { useLocale } from '@/providers/LocaleProvider'

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  Typography,
} from '../ui'

export const LanguageSelector = () => {
  const { locale, changeLocale } = useLocale()

  return (
    <Select onValueChange={changeLocale} defaultValue={locale}>
      <SelectTrigger className="h-auto bg-transparent px-0">
        <div className="flex items-center gap-2">
          <img
            src={getFlagUrl(locale === Locale.EN ? 'us' : 'mx')}
            alt=""
            className="size-4"
          />
          <Typography>
            {AVAILABLE_LANGUAGES.find((lang) => lang.code === locale)?.lang}
          </Typography>
        </div>
      </SelectTrigger>

      <SelectContent>
        {Object.values(Locale).map((l) => (
          <SelectItem
            disabled={l === locale}
            className="flex items-center gap-3"
            key={l}
            value={l}
          >
            <img
              src={getFlagUrl(l === Locale.EN ? 'us' : 'mx')}
              alt=""
              className="h-4 w-4"
            />
            <Typography>
              {AVAILABLE_LANGUAGES.find((lang) => lang.code === l)?.lang}
            </Typography>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
