import * as React from 'react'
import { useMemo } from 'react'
import PhoneInput, {
  getCountries,
  getCountryCallingCode,
  Value,
} from 'react-phone-number-input/input'

import { Input } from '.'

interface PhoneNumberInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  placeholder?: string
  phonePrefix: string
  onChange?: (value: string) => void
}

export const PhoneNumberInput = React.forwardRef<
  HTMLInputElement,
  PhoneNumberInputProps
>(({ placeholder, phonePrefix, className, ...props }, ref) => {
  const country = useMemo(() => {
    return getCountries().find(
      (c) => `+${getCountryCallingCode(c)}` === phonePrefix,
    )
  }, [phonePrefix])

  return (
    <PhoneInput
      {...props}
      value={props.value as Value}
      onChange={props.onChange as (value: Value) => void}
      country={country}
      international={false}
      inputComponent={Input}
      placeholder={placeholder}
      ref={ref}
    />
  )
})

PhoneNumberInput.displayName = 'PhoneNumberInput'
