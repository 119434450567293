export type Country = {
  code: CountryCode
  flagCode: string
}

export enum ISO2CountryCode {
  AR = 'AR',
  BR = 'BR',
  CL = 'CL',
  CO = 'CO',
  ES = 'ES',
  MX = 'MX',
  PT = 'PT',
  US = 'US',
  UY = 'UY',
}

export enum CountryCode {
  AR = 'ARG', // Argentina
  BR = 'BRA', // Brazil
  CL = 'CHL', // Chile
  CO = 'COL', // Colombia
  ES = 'ESP', // Spain
  MX = 'MEX', // Mexico
  PT = 'PRT', // Portugal
  US = 'USA', // United States
  UY = 'URY', // Uruguay
}

export enum CountryTaxId {
  AR = 'CUIT', // Argentina
  BR = 'CNPJ', // Brazil
  CL = 'RUT_CHL', // Chile
  CO = 'NIT', // Colombia
  ES = 'CIF_ESP', // Spain
  MX = 'RFC', // Mexico
  PT = 'NIPC', // Portugal
  US = 'EIN', // United States
  UY = 'RUT_URY', // Uruguay
}

export type CountryData = {
  name: string
  nameToShow: string
  flag: string
  internationalPhonePrefix: string
  isoCode: string
}
