import { getFirstLetters, getFullName } from '@/lib/typography'
import { Typography } from '@/shared/ui'

import { Task } from '../../types'

type Props = {
  task: Task
}

export const RequestedByCell = ({ task }: Props) => {
  return (
    <div className="flex items-center gap-2">
      <div className="relative flex size-6 shrink-0 items-center justify-center rounded-full bg-neutral-gray-800 text-white">
        <Typography bold variant="body-small">
          {getFirstLetters(
            getFullName({
              firstName: task.createdByFirstName,
              lastName: task.createdByLastName,
              secondLastName: task.createdBySecondLastName,
            }),
          )}
        </Typography>
      </div>
      <Typography>{task.createdByFirstName}</Typography>
    </div>
  )
}
